import React, {
  FC,
  useState,
  useEffect,
  useRef,
  LegacyRef,
  FocusEvent
} from 'react';

import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';

import { Tag, Input } from 'antd';

import styles from './index.module.css';
import cx from 'classnames';
import { Severity, User, Tag as TagType, Column } from '../types';
import { Shortcuts } from './shortcuts';
import { getCaretCoordinates } from './shortcuts/getCaretCoordinates';
import { getShortcuts } from './shortcuts/getShortcuts';

const mentionRegEx: RegExp = /@\[([^\[]+)\]\(([\d\)]+)\)/g;

const strings = translations[getLangKey()];
const DESCRIPTION_CHARACTER_LIMIT = 2000;
const DESCRIPTION_LINE_HEIGHT = 17;

type Props = {
  submitTask: () => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  fullHeight: boolean;
  assignees: number[];
  onAssigneesChange: (assignees: number[]) => void;
  canEditAssignees: boolean;
  assignableUsers: User[];
  severity: Severity;
  onSeverityChange: (severity: Severity) => void;
  canEditSeverity: boolean;
  status?: number;
  onStatusChange: (columnId: number) => void;
  columns: Column[];
  canEditStatus: boolean;
  tags: TagType[];
  onTagsChange: (tags: TagType[]) => void;
  availableTags: TagType[];
  canEditTags: boolean;
  createTagsViaNewTask: boolean;
  setCaretPosition: (position: number) => void;
  closePopoverWhenTypingContinues: () => void;
  textAreaRef: HTMLTextAreaElement | null;
  setTextAreaRef: (ref: HTMLTextAreaElement) => void;
};

const Description: FC<Props> = props => {
  const {
    submitTask,
    description,
    onDescriptionChange,
    fullHeight,
    assignableUsers,
    onAssigneesChange,
    assignees,
    canEditAssignees,
    severity,
    onSeverityChange,
    canEditSeverity,
    status,
    onStatusChange,
    canEditStatus,
    columns,
    tags,
    onTagsChange,
    availableTags,
    canEditTags,
    createTagsViaNewTask,
    setCaretPosition,
    closePopoverWhenTypingContinues,
    textAreaRef,
    setTextAreaRef
  } = props;
  const descriptionOuterRef: LegacyRef<HTMLDivElement | null> = useRef(null);
  const [wrongShortcutWarning, setWrongShortcutWarning] = useState<boolean>(
    false
  );
  const [seenShortcutWarning, setSeenShortcutWarning] = useState<boolean>(
    false
  );
  const [mentionDropdownPosition, setMentionDropdownPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const submitKey = () =>
    navigator.platform.indexOf('Mac') > -1 ? 'control' : 'ctrl';

  const setValue = (description: string) => {
    onDescriptionChange(description.substring(0, DESCRIPTION_CHARACTER_LIMIT));
  };

  const handleOnChange = (event: any) => {
    closePopoverWhenTypingContinues();
    setValue(event.target.value);
  };

  const isEnter = (e: KeyboardEvent): boolean => e.keyCode === 13;
  const isCtrlPlusEnterOnWindows = (e: KeyboardEvent): boolean =>
    e.keyCode === 10;

  const submitShortcut = (e: KeyboardEvent) =>
    (isCtrlPlusEnterOnWindows(e) || isEnter(e)) && (e.ctrlKey || e.metaKey);

  const submitShortcutRetired = (e: KeyboardEvent) =>
    (isCtrlPlusEnterOnWindows(e) || isEnter(e)) && e.shiftKey;

  const updateMentionDropdownPosition = (event?: any) => {
    if (!textAreaRef && !event) return;
    const element = event?.target || textAreaRef;
    const { selectionStart, scrollTop, scrollLeft } = element;

    const coords = getCaretCoordinates(element, selectionStart);
    const top = coords.top + DESCRIPTION_LINE_HEIGHT - scrollTop;
    const left = coords.left + scrollLeft;

    setMentionDropdownPosition({ top, left });
  };

  const handleKeyUp = (event: any) => {
    if (submitShortcut(event)) {
      event.preventDefault();
    }
    updateMentionDropdownPosition(event);
  };

  const handleKeyDown = (event: any) => {
    if (submitShortcutRetired(event) && !seenShortcutWarning) {
      setSeenShortcutWarning(true);
      setWrongShortcutWarning(true);
      setTimeout(() => setWrongShortcutWarning(false), 3000);
    }
    if (submitShortcut(event)) {
      event.preventDefault();
      submitTask();
    }
  };

  const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    const { selectionStart } = event.target;
    setCaretPosition(selectionStart);

    const { value } = event.target;
    let formattedValue = value;
    assignableUsers
      .filter(({ id }) => assignees.includes(id))
      .forEach(user => {
        const mentionPattern = new RegExp(`@${user.name}`, 'g');
        const formattedMention = `@[${user.name}](${user.id})`;
        formattedValue = formattedValue.replace(
          mentionPattern,
          formattedMention
        );
      });

    setValue(formattedValue);
  };

  const showMentionInput = !description;

  useEffect(() => {
    if (descriptionOuterRef) {
      const textArea = descriptionOuterRef?.current?.querySelector(
        '#create-task-description-input'
      ) as HTMLTextAreaElement;
      if (textArea) setTextAreaRef(textArea);
    }
  }, [descriptionOuterRef]);

  const focusTextArea = () => {
    if (textAreaRef && textAreaRef !== window.document.activeElement) {
      textAreaRef.focus();
    }
  };

  useEffect(() => {
    if (showMentionInput) focusTextArea();
  }, [showMentionInput]);

  useEffect(() => {
    updateMentionDropdownPosition();
  }, [description]);

  useEffect(() => {
    focusTextArea();
  }, [textAreaRef]);

  const shortcuts = getShortcuts({
    assignees,
    canEditAssignees,
    assignableUsers,
    onAssigneesChange,
    status,
    canEditStatus,
    onStatusChange,
    columns,
    tags,
    canEditTags,
    onTagsChange,
    availableTags,
    severity,
    canEditSeverity,
    onSeverityChange
  }).filter(shortcut => shortcut.permission);

  return (
    <div ref={descriptionOuterRef} className={styles.descriptionOuter}>
      <>
        <div className={cx(styles.descriptionInner)}>
          <Input.TextArea
            maxLength={DESCRIPTION_CHARACTER_LIMIT}
            autoSize={{ minRows: fullHeight ? 8 : 5, maxRows: 18 }}
            value={description.replace(mentionRegEx, '@$1')}
            placeholder={strings.descriptionPlaceholder}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onClick={updateMentionDropdownPosition}
            onScroll={updateMentionDropdownPosition}
            className={styles.description}
            id="create-task-description-input"
          />
          <Shortcuts
            textAreaRef={textAreaRef}
            handleKeyDown={handleKeyDown}
            showMentionInput={showMentionInput}
            setValue={setValue}
            mentionDropdownPosition={mentionDropdownPosition}
            updateMentionDropdownPosition={updateMentionDropdownPosition}
            shortcuts={shortcuts}
            description={description}
            createTagsViaNewTask={createTagsViaNewTask}
            handleAddNewTag={(newTag: TagType) =>
              onTagsChange([...tags, newTag])
            }
          />
        </div>
        <div className={styles.descriptionFooter}>
          <div
            className={cx(styles.count, {
              [styles.countForMentions]: !description
            })}
          >
            {`${description.length} / ${DESCRIPTION_CHARACTER_LIMIT}`}
          </div>
          <div
            className={cx(styles.submissionShortcut, {
              [styles.shortcutVisible]: wrongShortcutWarning
            })}
          >
            <Tag>{submitKey()} + enter</Tag>
            {strings.toSubmit}
          </div>
        </div>
      </>
    </div>
  );
};

export default Description;
