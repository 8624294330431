import React, { useState, useRef, MutableRefObject } from 'react';
import { Modal, Button } from 'antd';
import cx from 'classnames';
import styles from './index.module.css';
import GreenTick from './images/ic-green-tick.svg';
import WebhookSettings from './webhookSettings';
import MicrosoftTeamsSettings from './microsoftTeamsSettings';
import TeamworkSettings from './teamworkSettings';
import ParagonSettings from './paragonSettings';
import HarvestSettings from './harvestSettings';
import { editIntegration, createIntegration } from 'utils/integrations';
import { IntegrationSettingsProps } from '../new_project_settings/types';
import track from 'utils/analytics';

type VisibleModal = 'webhooks' | 'harvest' | 'teams' | 'teamwork';

const IntegrationSettings: React.FC<IntegrationSettingsProps> = (
  props: IntegrationSettingsProps
) => {
  const containerRef: MutableRefObject<any> = useRef(null);
  const [visibleModal, setVisibleModal] = useState<VisibleModal | null>(null);

  const getContainer = document.getElementById('settingsBoard');

  const hideModal = () => {
    setVisibleModal(null);
  };

  const switchModal = (switchTo: VisibleModal | null) =>
    setVisibleModal(switchTo);

  const handleHarvestClick = () => {
    const harvestEnabled = !!props.harvestIntegration?.id;

    if (harvestEnabled) {
      editIntegration(
        props.project.organizationId,
        props.harvestIntegration.id
      );
    } else {
      createIntegration(props.project.organizationId, 'harvest');
      track('Integration connected', {
        integration: 'harvest',
        project_id: props.project?.id
      });
    }
  };

  const integrationConnectedState = (name: string) => {
    return props.legacyIntegrations[name] || null;
  };

  const connectButton = (isConnected: boolean, handleConnect: Function) => {
    return isConnected ? (
      <div className={styles.connected}>
        <Button
          type="text"
          className={styles.connectedButton}
          icon={<GreenTick />}
        >
          Connected
        </Button>
      </div>
    ) : (
      <div>
        <Button type="primary" onClick={() => handleConnect()}>
          Connect
        </Button>
      </div>
    );
  };

  const modalComplexHeader = (
    showConnectButton?: boolean,
    connectProps?: { isConnected: boolean; connectHandler: Function }
  ) => {
    return (
      <div className={styles.header}>
        <div
          className={cx(
            styles.headerContainer,
            showConnectButton ? styles.hasConnectButton : ''
          )}
        >
          <div>
            <span className={styles.headerLogoContainer}>
              <span
                className={cx(styles.headerLogo, styles[`${visibleModal}Logo`])}
              />
            </span>
            <span className={styles.headerTitle}>
              <span className={styles.integrationTitle}>{visibleModal}</span>
              <span>
                <a
                  className={styles.backLink}
                  onClick={() => switchModal(null)}
                >
                  &lt; Back
                </a>
              </span>
            </span>
          </div>
          {showConnectButton && connectProps
            ? connectButton(
                connectProps.isConnected,
                connectProps.connectHandler
              )
            : null}
        </div>
        <span className={styles.headerBackground} />
      </div>
    );
  };

  const modalTitle = () => {
    if (
      visibleModal === 'webhooks' ||
      visibleModal === 'teams' ||
      visibleModal === 'teamwork'
    ) {
      return modalComplexHeader();
    } else if (visibleModal === 'harvest') {
      return modalComplexHeader(true, {
        isConnected: !!props.harvestIntegration.id,
        connectHandler: handleHarvestClick
      });
    }

    return null;
  };

  return (
    <div className={styles.integrations}>
      <div ref={containerRef} className={styles.container}>
        <div className={styles.container} />
        {props.hasParagonLoaded && (
          <ParagonSettings
            project={props.project}
            zapier={props.zapier}
            legacyIntegrations={props.legacyIntegrations}
            paragonProjectId={props.paragonProjectId}
            premiumAllowed={props.premiumAllowed}
            paragonAllowed={props.paragonAllowed}
            editingAllowed={props.editingAllowed}
            msTeamsEnabled={props.msTeamsEnabled}
            teamworkEnabled={props.teamworkEnabled}
            bugherdUrl={props.bugherdUrl}
            ownerEmail={props.ownerEmail}
            paragonEnabled={props.paragonEnabled}
            harvestIntegration={props.harvestIntegration}
            trialUser={props.trialUser}
            switchModal={switchModal}
            connectors={props.connectors}
            currentUser={props.currentUser}
          />
        )}
        <Modal
          open={!!visibleModal}
          className={styles.modalContainer}
          footer={null}
          title={modalTitle()}
          getContainer={getContainer}
          onCancel={hideModal}
        >
          {visibleModal === 'webhooks' && (
            <WebhookSettings
              projectId={props.project.id}
              webhooks={props.project.webhooks}
              webhooksPath={props.webhooksPath}
              container={containerRef.current}
              apiKey={props.apiKey}
            />
          )}

          {visibleModal === 'harvest' && (
            <HarvestSettings
              harvestIntegration={props.harvestIntegration}
              handleHarvestClick={handleHarvestClick}
            />
          )}

          {visibleModal === 'teams' && (
            <MicrosoftTeamsSettings
              projectId={props.project.id}
              organizationId={props.project.organizationId}
              integration={integrationConnectedState('team')}
            />
          )}

          {visibleModal === 'teamwork' && (
            <TeamworkSettings
              projectId={props.project.id}
              organizationId={props.project.organizationId}
              integration={integrationConnectedState('teamwork')}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default IntegrationSettings;
