import React from 'react';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Button } from 'antd';

import styles from './index.module.css';

// @ts-ignore
const strings = translations[getLangKey()];

export default () => (
  <div className={styles.modalContent}>
    <p className={styles.whyMessage}>{strings.unavailable}</p>
    <p className={styles.whyMoreInfo}>
      {strings.screenshotService}{' '}
      <a
        className={styles.whyMoreInfoLink}
        href="http://bugherdstatus.com"
        target="_blank"
      >
        bugherdstatus.com
      </a>
    </p>
    <Button className={styles.whyButton} size="large" type="primary">
      <a
        className={styles.buttonAnchor}
        href="http://bugherdstatus.com"
        target="_blank"
      >
        {strings.checkStatus}
      </a>
    </Button>
  </div>
);
