import { Alert, Button, Space } from 'antd';
import React from 'react';
import styles from './index.module.css';

export default () => {
  const isReviewApp = process.env.REVIEW_APP;
  const isStaging = process.env.HEROKU_APP_NAME === 'bugherd-test';
  const isProduction = process.env.HEROKU_APP_NAME === 'bugherd-prod';
  const isNewUi = process.env.HEROKU_APP_NAME === 'bugherd-ui-v2';
  const isLocalDev = !isReviewApp && !isStaging && !isProduction && !isNewUi;

  const reviewApp = () => (
    <>
      <Space direction="horizontal" className={styles.horizontal}>
        <Button
          type="primary"
          className={styles.button}
          href={`/extension/chrome/bugherd-extension-${process.env.HEROKU_APP_NAME}.zip`}
          target="_blank"
        >
          Download Chrome extension for {`${process.env.HEROKU_APP_NAME}`}
        </Button>
      </Space>
      <Space direction="horizontal" className={styles.horizontal}>
        <Button
          type="primary"
          className={styles.button}
          href={`/extension/firefox/bugherd-extension-${process.env.HEROKU_APP_NAME}.zip`}
          target="_blank"
        >
          Download Firefox extension for {`${process.env.HEROKU_APP_NAME}`}
        </Button>
      </Space>
    </>
  );

  const getButtons = () => {
    return (
      <Space className={styles.buttons} direction="vertical">
        {isReviewApp && reviewApp()}
        <Space direction="horizontal" className={styles.horizontal}>
          <Button
            type="primary"
            className={styles.button}
            href="/extension/chrome/bugherd-extension-staging.zip?nocache=1"
            target="_blank"
          >
            Download Chrome extension for staging
          </Button>
        </Space>
        <Space direction="horizontal" className={styles.horizontal}>
          <Button
            type="primary"
            className={styles.button}
            href="/extension/chrome/bugherd-extension-production.zip?nocache=1"
            target="_blank"
          >
            Download Chrome extension for production
          </Button>
        </Space>
        <Space direction="horizontal" className={styles.horizontal}>
          <Button
            type="primary"
            className={styles.button}
            href="/extension/firefox/bugherd-extension-staging.zip?nocache=1"
            target="_blank"
          >
            Download Firefox extension for staging
          </Button>
        </Space>
        <Space direction="horizontal" className={styles.horizontal}>
          <Button
            type="primary"
            className={styles.button}
            href="/extension/firefox/bugherd-extension-production.zip?nocache=1"
            target="_blank"
          >
            Download Firefox extension for production
          </Button>
        </Space>
        <Space direction="horizontal" className={styles.horizontal}>
          <Button
            type="primary"
            className={styles.button}
            href="/extension/firefox/source.zip?nocache=1"
            target="_blank"
          >
            Download source code for Firefox Review
          </Button>
        </Space>
      </Space>
    );
  };

  if (isLocalDev) {
    return (
      <div className={styles.container}>
        Follow the instructions in /workspaces/extensions to build the extension
        for development
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Alert
          type="warning"
          message="This page is only viewable to BugHerd Admins"
        />

        {getButtons()}
        <p>
          If you don't know how to install an extension outside of the Chrome
          Web Store, follow{' '}
          <a
            href="https://webkul.com/blog/how-to-install-the-unpacked-extension-in-chrome/"
            target="_blank"
          >
            this guide
          </a>
          .
        </p>
      </div>
    );
  }
};
