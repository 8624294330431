import basecamp from '../../../../assets/images/icons/integrations/ic-integration-basecamp.png';
import github from '../../../../assets/images/icons/integrations/ic-integration-github.png';
import slack from '../../../../assets/images/icons/integrations/ic-integration-slack.png';
import trello from '../../../../assets/images/icons/integrations/ic-integration-trello.png';
import zapier from '../../../../assets/images/icons/integrations/ic-integration-zapier.png';
import asana from '../../../../assets/images/icons/integrations/ic-integration-asana.png';
import azuredevops from '../../../../assets/images/icons/integrations/ic-integration-azuredevops.png';
import customproductive from '../../../../assets/images/icons/integrations/ic-integration-productiveio.png';
import clickup from '../../../../assets/images/icons/integrations/ic-integration-clickup.png';
import jira from '../../../../assets/images/icons/integrations/ic-integration-jira.png';
import mondaycom from '../../../../assets/images/icons/integrations/ic-integration-monday.png';
import webhooks from '../../../../assets/images/icons/integrations/ic-integration-webhooks.png';
import harvest from '../../../../assets/images/icons/integrations/ic-integration-harvest.png';
import teamwork from '../../../../assets/images/icons/integrations/ic-integration-teamwork.png';

export const integrationsInfo = {
  basecamp: {
    articleId: 33886,
    icon: basecamp,
    description:
      'Create To-do list items for each bug in a nominated To-do list and keep them in sync.',
    label: 'Basecamp'
  },
  github: {
    articleId: 33875,
    icon: github,
    description:
      'Keep your bug reports in sync by creating GitHub issues in your repository.',
    label: 'GitHub'
  },
  slack: {
    articleId: 84823,
    icon: slack,
    description:
      'Send a variety of feedback and bug reports directly to the Slack channel of your choice.',
    label: 'Slack'
  },
  trello: {
    link:
      'https://www.loom.com/share/8a0e1da5a4e2440c86f4781cdc1a198b?sid=4a1976b9-8de1-4243-b467-2874c96bdaa6',
    icon: trello,
    video: true,
    description:
      'Send bugs and website feedback reported in BugHerd directly to Trello.',
    label: 'Trello'
  },
  zapier: {
    articleId: 33883,
    icon: zapier,
    description:
      'Connect with more than 1500+ tools and services you already use (including JIRA, Trello and Asana) via Zapier.',
    label: 'Zapier'
  },
  asana: {
    link:
      'https://www.loom.com/share/ad0cbd7f487c4128925f937bd98f2105?sid=36f930d0-e0c0-497a-9c0c-12e701c70154',
    icon: asana,
    video: true,
    description:
      'Track bugs and report website feedback in Asana by integrating with BugHerd.',
    label: 'Asana'
  },
  azuredevops: {
    link: '',
    icon: azuredevops,
    description: 'Description goes here, keep it brief.',
    label: 'Azure DevOps'
  },
  customproductive: {
    link: 'https://youtu.be/cT5_XanbrSo',
    icon: customproductive,
    video: true,
    description:
      'Send bugs and website feedback reported in BugHerd directly to Productive.',
    label: 'Productive'
  },
  clickup: {
    link:
      'https://www.loom.com/share/630878df52ff4cf0ba2e37131b9c4784?sid=84d911f9-b58f-4b8c-8c74-25a90cda24de',
    icon: clickup,
    video: true,
    description:
      'Track bugs and report website feedback in ClickUp by integrating with BugHerd.',
    label: 'ClickUp'
  },
  jira: {
    link:
      'https://www.loom.com/share/52407f1ba8284af0ab478e31985a8e3a?sid=3dd07b71-d59c-46f1-8c73-5cec7ecc4ef6',
    icon: jira,
    video: true,
    description:
      'Send bugs and feedback tasks seamlessly from BugHerd into Jira.',
    label: 'Jira'
  },
  mondaycom: {
    link:
      'https://www.loom.com/share/11eb1231f2bd4872ae0b2ccd423c7c6f?sid=5408c7dc-c2ff-4d86-873c-e7b997437f1a',
    icon: mondaycom,
    video: true,
    description:
      'Track bugs and report website feedback in Monday.com by integrating with BugHerd.',
    label: 'Monday.com'
  },
  linear: {
    link: 'https://youtu.be/hp1GAZDhVRw',
    video: true,
    description:
      'Send bugs and website feedback reported in BugHerd directly to Linear.',
    label: 'Linear'
  },
  webhooks: {
    link: 'https://www.bugherd.com/api_v2#api_webhook_list',
    icon: webhooks,
    description: 'Create your own bespoke integration using webhooks.',
    label: 'Webhooks'
  },
  harvest: {
    articleId: 84964,
    icon: harvest,
    description:
      'Track the time you spend on tasks in all your BugHerd projects and allocate it to Client Projects in Harvest.',
    label: 'Harvest'
  },
  teams: {
    link:
      'https://www.loom.com/share/a7a6bd4f5bbc4c39afb48d1003d6d864?sid=d922d08a-712b-4c23-97d4-2ec6d53129b3',
    video: true,
    description:
      'Send a variety of feedback and bug reports directly to the MS Teams channel of your choice.',
    label: 'MS Teams'
  },
  teamwork: {
    link: '',
    icon: teamwork,
    description:
      'Send feedback and bugs reported in BugHerd directly to Teamwork.',
    label: 'Teamwork'
  }
};
