import React from 'react';
import { GetItemArgs, RightNavMobileItemsArgs } from './types';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import {
  LockKeyhole,
  FileEdit,
  UserCircle,
  FolderPlus,
  HelpCircle,
  Home,
  LogOut
} from 'lucide-react';

const strings = translations[getLangKey()];

const FOLDER_SIZE = 20;

export const rightNavMobileItems = ({
  admin,
  isActiveOrganization,
  setIsModalOpen,
  openElevio,
  id,
  amountOfTrialDays,
  homeUrl,
  isMobileView
}: RightNavMobileItemsArgs): GetItemArgs[] => [
  {
    href: '/admin',
    text: strings.toAdmin,
    key: 'admin',
    icon: <LockKeyhole size={FOLDER_SIZE} />,
    hidden: !admin || isMobileView
  },
  {
    href: '/settings/profile',
    text: strings.editProfile,
    key: 'profile',
    icon: <FileEdit size={FOLDER_SIZE} />
  },
  {
    href: `/users/${id}/start_trial`,
    text: strings.startAccount,
    key: 'trial',
    icon: <UserCircle size={FOLDER_SIZE} />,
    anchorProps: {
      'data-confirm': strings.startYourOwn(amountOfTrialDays),
      rel: 'nofollow',
      'data-method': 'post'
    },
    hidden: isActiveOrganization
  },
  {
    href: `/users/${id}/start_trial`,
    text: strings.newOrg,
    key: 'new_org',
    icon: <FolderPlus size={FOLDER_SIZE} />,
    anchorProps: {
      'data-confirm': strings.createOrg,
      rel: 'nofollow',
      'data-method': 'post'
    },
    hidden: isActiveOrganization
  },
  {
    onClick: openElevio,
    text: strings.needHelp,
    key: 'elevio',
    icon: <HelpCircle size={FOLDER_SIZE} />
  },
  {
    href: homeUrl,
    text: strings.goHome,
    key: 'go_home',
    icon: <Home size={FOLDER_SIZE} />
  },
  {
    type: 'divider',
    key: 'divider-6',
    hidden: isMobileView
  },
  {
    href: '/users/sessions',
    onClick: () => {
      //eslint-disable-next-line
      window?.posthog?.reset(true);
    },
    text: strings.logOut,
    key: 'logout',
    icon: <LogOut size={FOLDER_SIZE} />,
    anchorProps: {
      rel: 'nofollow',
      'data-method': 'delete'
    }
  }
];
