import React, { FC } from 'react';
import styles from './index.module.css';
import cx from 'classnames';
import {
  MapPin,
  CaseSensitive,
  FileSearch,
  CalendarSearch,
  CalendarClock,
  SortAsc,
  SortDesc
} from 'lucide-react';
import { MenuProps } from 'antd';

type SortKey =
  | 'name'
  | 'createdAt'
  | 'updatedAt'
  | 'fileType'
  | 'openTasksCount';

export type SortBy = {
  key: SortKey;
  ascending: boolean;
};

type Props = {
  sortBy: SortBy | null;
  setSortBy: (sortBy: SortBy) => void;
};

const assetSortMenuItems = ({ sortBy, setSortBy }): MenuProps['items'] => {
  const getClassName = (key: string) =>
    cx(styles.menuItem, {
      [styles.activeMenuItem]: sortBy?.key === key
    });

  const menuItems = [
    {
      key: 'name',
      icon: <CaseSensitive className={styles.menuIcon} />,
      label: 'File name'
    },
    {
      key: 'createdAt',
      icon: <CalendarSearch className={styles.menuIcon} />,
      label: 'Date created'
    },
    {
      key: 'updatedAt',
      icon: <CalendarClock className={styles.menuIcon} />,
      label: 'Last updated'
    },
    {
      key: 'fileType',
      icon: <FileSearch className={styles.menuIcon} />,
      label: 'File type'
    },
    {
      key: 'openTasksCount',
      icon: <MapPin className={styles.menuIcon} />,
      label: 'Total open tasks'
    }
  ];

  const handleMenuClick = (key: SortKey) =>
    setSortBy({ key, ascending: sortBy?.key !== key || !sortBy?.ascending });

  const getSortIcon = (key: string) =>
    sortBy?.key === key ? (
      sortBy.ascending ? (
        <SortAsc className={styles.sortIcon} />
      ) : (
        <SortDesc className={styles.sortIcon} />
      )
    ) : null;

  return menuItems.map(({ key, icon, label }) => ({
    key,
    icon,
    onClick: () => handleMenuClick(key as SortKey),
    className: getClassName(key),
    label: (
      <div className={styles.menuInner}>
        {label}
        {getSortIcon(key)}
      </div>
    )
  }));
};

export default assetSortMenuItems;
