import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Settings, Info as InfoIcon } from 'lucide-react';
import integrationData from './integrations';
import * as translations from './strings';
import { getLangKey } from '../../../models/Language';
import { Button, Tooltip } from 'antd';
import _debounce from 'lodash/debounce';
import { post } from '../../../utils/fetch';
import cx from 'classnames';
import LinkTask from './LinkTask';
import Connected from './Connected';
import Error from './Error';
import { Integration } from '../../../utils/getParagonIntegrations';
import HarvestIframe from './HarvestIframe';

type Props = {
  name: string;
  integration?: Integration;
  projectName?: string;
  link?: string;
  container: HTMLDivElement;
  projectId?: number;
  isAdminView?: boolean;
  bugherdUrl: string;
  apiDomain?: string;
  taskId: number;
  error?: {
    code: number;
    message: string;
    created_at: string;
  };
  isSmallView?: boolean;
  hasQueryWorkflow?: boolean;
  loading?: boolean;
  showHelp?: boolean;
  harvestEmbedUrl?: string;
  projectIntegrationId?: number;
  integrationId?: number;
  organizationId: number;
  settings: {};
  remoteTaskId?: string;
};

const strings = translations[getLangKey()];

export type Parent = { id: string; name: string };

const Card: FC<Props> = ({
  name,
  integration,
  projectName,
  container,
  projectId,
  isAdminView,
  bugherdUrl,
  apiDomain,
  taskId,
  error,
  isSmallView,
  hasQueryWorkflow,
  loading,
  showHelp,
  harvestEmbedUrl,
  projectIntegrationId,
  integrationId,
  organizationId,
  settings,
  remoteTaskId
}) => {
  const connected: boolean = !!(integration?.id || projectName || remoteTaskId);

  const {
    label,
    connectedText,
    viewIn,
    linkText,
    createText,
    link,
    helpLink,
    helpId
  } = integrationData[name] || integrationData['generic'];
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [taskToLink, setTaskToLink] = useState<string>('');
  const [showTryAgain, setShowTryAgain] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [parentTasks, setParentTasks] = useState<Parent[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [queryError, setQueryError] = useState<string>('');
  const [createError, setCreateError] = useState<string>('');

  useEffect(() => {
    if (integration) {
      setConnecting(false);
    }
  }, [integration]);

  const baseEndpoint: string = `${apiDomain ||
    bugherdUrl}/projects/${projectId}`;

  type QueryResponse = {
    statusCode: number;
    results: Parent[];
  };

  const queryTasks = (search: string) => {
    if (search && (!taskToLink || taskToLink !== search)) {
      setSearching(true);
      setQueryError('');

      const body: { search: string; integration: string; is_key?: boolean } = {
        search,
        integration: name
      };

      if (name === 'jira' && search.match(/^[A-Za-z\d]+-\d+$/)?.length) {
        body.is_key = true;
      }

      const filterTasks = (previousTasks, results) => {
        return results.filter(
          result => !previousTasks.find(task => task.id === result.id)
        );
      };

      post(`${baseEndpoint}/query_paragon_tasks`, body)
        .then((res: QueryResponse) => {
          setParentTasks(previousTasks =>
            previousTasks.concat(filterTasks(previousTasks, res))
          );
          setSearching(false);
        })
        .catch(err => {
          setQueryError(err.message || err);
          console.error(err);
        });
    }
  };

  const debounceFn = useCallback(_debounce(queryTasks, 1500), []);

  const handleCreateIntegrationTask = () => {
    setSending(true);
    setCreateError('');
    setShowTryAgain(false);
    const requestBody: {
      parent?: { id: string; name: string };
      integration: string;
    } = {
      integration: name
    };
    if (taskToLink) requestBody.parent = { id: taskToLink, name };
    if (name === 'teamwork') {
      post(
        `${apiDomain ||
          bugherdUrl}/organizations/${organizationId}/integrations/${integrationId}/project_integrations/manually_create_task`,
        {
          task_id: taskId,
          project_integration_id: projectIntegrationId
        }
      );
      setSending(false);
      setConnecting(true);
      setTaskToLink('');
    } else {
      post(`${baseEndpoint}/tasks/${taskId}/send_to_paragon`, requestBody)
        .then(res => {
          setSending(false);
          setConnecting(true);
          setTaskToLink('');
        })
        .catch(err => {
          setSending(false);
          setConnecting(false);
          setCreateError(err.message || err);
          console.error(err);
        });
    }
    if (showSearch) setShowSearch(false);
  };

  const handleCancel = () => {
    setSearch('');
    setShowSearch(false);
    setTaskToLink('');
    setShowDropdown(false);
    setSearching(false);
  };

  const handleShowSettings = () => {
    if (isAdminView) {
      // eslint-disable-next-line no-unused-expressions
      showHelp
        ? undefined
        : // @ts-ignore
          window?.integration_settings_props?.setShow(true);
    } else if (showHelp) {
      // eslint-disable-next-line no-unused-expressions
      helpLink && window.open(helpLink, '_blank');
    } else {
      window.open(
        `${bugherdUrl}/projects/${projectId}/kanban?open=integration_settings`,
        '_blank'
      );
    }
  };

  const showErrorState =
    !sending && !showTryAgain && (!!error?.message || !!createError);

  const teamworkTaskLink = () => {
    return `https://${settings.siteName}.teamwork.com/app/tasks/${remoteTaskId}`;
  };

  const connectedLink: string =
    integration?.url ||
    (projectName && link ? link(projectName) : '') ||
    (name === 'teamwork' && remoteTaskId ? teamworkTaskLink() : '');

  const getBottomContent = () => {
    if (loading) return strings.loadingIntegration;

    if (name === 'harvest' && harvestEmbedUrl) {
      return <HarvestIframe url={harvestEmbedUrl} />;
    }
    if (connected) {
      return (
        <Connected
          name={name}
          taskId={taskId}
          isSmallView={!!isSmallView}
          connectedText={connectedText}
          buttonText={viewIn || integration?.id || projectName}
          href={connectedLink}
          projectId={projectId}
        />
      );
    } else if (showErrorState) {
      return (
        <Error
          isSmallView={!!isSmallView}
          error={error}
          createError={createError}
          onTryAgain={() => setShowTryAgain(true)}
          container={container}
        />
      );
    }

    return (
      <LinkTask
        container={container}
        searching={searching}
        search={search}
        handleOnChange={(searchValue: string) => {
          setSearch(searchValue);
          debounceFn(searchValue);
        }}
        handleOnSelect={(parentName: string) => {
          setSearch(parentName);
          const taskId = parentTasks.find(task => task.name === parentName)?.id;
          if (taskId) setTaskToLink(taskId);
        }}
        parentTasks={parentTasks.filter(
          ({ id, name }) =>
            id.toLowerCase().includes(search.toLowerCase()) ||
            name.toLowerCase().includes(search.toLowerCase())
        )}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        handleCreateIntegrationTask={handleCreateIntegrationTask}
        linkingDisabled={!taskToLink}
        handleCancel={handleCancel}
        linkText={linkText}
        isSmallView={!!isSmallView}
        createText={createText}
        showSearch={showSearch}
        hasQueryWorkflow={!!hasQueryWorkflow}
        setShowSearch={setShowSearch}
        sending={sending}
        createError={createError}
        queryError={queryError}
        connecting={connecting}
      />
    );
  };

  const elevioConfig =
    isAdminView && showHelp && helpId
      ? {
          'data-elevio-style': 'nothing',
          'data-elevio-article': helpId
        }
      : {};

  const configButton = (
    <Tooltip
      getPopupContainer={() => container}
      overlayClassName={styles.tooltip}
      title={showHelp ? strings.info : strings.setupHere}
      trigger={['hover']}
    >
      <Button
        className={styles.settingsButton}
        type="text"
        icon={
          showHelp ? <InfoIcon /> : <Settings className={styles.settingsIcon} />
        }
        onClick={handleShowSettings}
        {...elevioConfig}
      />
    </Tooltip>
  );

  return (
    <div className={styles.cardOuter}>
      <div className={styles.cardTop}>
        <div className={styles.integration}>
          <span
            className={cx(
              styles.integrationLogo,
              styles[`${name.replace(/\./g, '')}Logo`]
            )}
          />
          <span className={styles.integrationLabel}>{label}</span>
        </div>
        {configButton}
      </div>
      <div
        className={cx(styles.cardBottom, {
          [styles.noPadding]: name === 'harvest'
        })}
      >
        {getBottomContent()}
      </div>
    </div>
  );
};

export default Card;
