import { string, shape, func, object } from 'prop-types';

export const antdForm = shape({
  getFieldDecorator: func.isRequired,
  resetFields: func.isRequired,
  validateFields: func.isRequired,
  setFieldsValue: func.isRequired
});

export const matchType = shape({
  params: object
});

export const historyType = shape({
  location: object
});

export const userType = shape({
  email: string.isRequired
});

export const locationType = shape({ search: string.isRequired });
