import * as React from 'react';
import { Avatar as AntAvatar } from 'antd';
import cx from 'classnames';
import styles from './index.module.css';

interface AvatarWrapperProps {
  size: 'small' | 'large' | 'default';
  color?: string;
  src?: string;
  className?: string;
}

const AvatarWrapper: React.FC<AvatarWrapperProps> = props => {
  const { children, size, src, className } = props;
  const color = props.color ? props.color : 'default';

  return (
    <AntAvatar
      className={cx(styles[size], styles[color + 'Avatar'], className)}
      size={size}
      src={src}
    >
      {children}
    </AntAvatar>
  );
};

export default AvatarWrapper;
