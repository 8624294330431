import React from 'react';
import PropTypes from 'prop-types';
import browserResources from 'jsComponents/onboarding/extension_config.json';
import track from 'jsUtilities/analytics';
import styles from './index.module.css';

const Alternatives = props => {
  const { setBrowser, currentBrowser, hasBrowserData } = props;

  const otherBrowsers = browserResources.filter(
    browser => browser.name !== currentBrowser
  );

  const alternativeBrowserOnClick = browser => {
    track('Onboarding switch browser extension link clicked', { browser });
    setBrowser(browser.name);
  };

  if (hasBrowserData) {
    return (
      <div>
        <p className={styles.text}>
          Also available for{' '}
          {otherBrowsers.map((browser, index) => (
            <span key={browser.name}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  alternativeBrowserOnClick(browser);
                }}
              >
                <b>
                  {browser.display.toLowerCase() === 'edge'
                    ? `Microsoft ${browser.display}`
                    : browser.display}
                </b>
              </a>
              {index < otherBrowsers.length - 1 && ', '}
            </span>
          ))}
          .
        </p>
      </div>
    );
  }

  return null;
};

Alternatives.propTypes = {
  setBrowser: PropTypes.func,
  currentBrowser: PropTypes.string,
  javascriptInstalled: PropTypes.bool,
  ownerEmail: PropTypes.string,
  hasBrowserData: PropTypes.bool
};

Alternatives.defaultProps = {
  ownerEmail: null
};

export default Alternatives;
