import * as React from 'react';

import { newProjectPath } from 'app/routes';
import * as translations from './strings';
import { getLangKey } from 'models/Language';

import { REDIRECT_DELAY_MS } from './types';
import styles from './index.module.css';

type Props = {
  organizationId: string;
};

const RedirectToCreateProject = ({ organizationId }: Props) => {
  const strings = translations[getLangKey()];

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = newProjectPath({
        organizationId: organizationId
      });
    }, REDIRECT_DELAY_MS);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={styles.emptyWrapper}>
      <h2>{strings.accountContainsNoProjects}</h2>
      <h2>{strings.redirectToCreateNewProject}</h2>
    </div>
  );
};

export default RedirectToCreateProject;
