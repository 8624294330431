import { gql, useQuery } from '@apollo/client';

type Vars = {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: { name: string };
};

type CurrentUser = {
  id: string;
  organization: {
    users: { id: string; name: string }[];
    usersCount: number;
  };
};

type Data = {
  currentUser: CurrentUser;
};

const GET_ORGANIZATION_USERS = gql`
  query getOrganizationUsers(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $filter: UserFilter
  ) {
    currentUser {
      __typename
      id
      organization(organizationId: $organizationId) {
        usersCount
        users(offset: $offset, limit: $limit, filter: $filter) {
          id
          name
        }
      }
    }
  }
`;

export const useGetOrganizationUsers = ({
  organizationId,
  offset,
  limit,
  filter
}: {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: {
    name: string;
  };
}) =>
  useQuery<Data, Vars>(GET_ORGANIZATION_USERS, {
    variables: {
      organizationId,
      offset,
      limit,
      filter
    }
  });
