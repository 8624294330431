import React from 'react';
import styles from './index.module.css';
import BugHerdOnDesktop from './images/bugherd_on_desktop.png';
import HelpArticleButton from 'utils/HelpArticleButton';
import { get } from 'appJS/utils/fetch';
import { message } from 'antd';

export default () => {
  const sendEmail = async () => {
    try {
      const response = await get('/resend_mobile_login_link');
      if (response.ok) message.success('Email sent');
    } catch (error) {
      console.error('Error resending email:', error);
    }
  };

  sendEmail();

  const handleResendEmail = async (e: any) => {
    e.preventDefault();
    try {
      const response = await get('/resend_mobile_login_link');
      if (response.ok) message.success('Email sent');
    } catch (error) {
      console.error('Error resending email:', error);
      message.error('Error resending email');
    }
  };

  return (
    <div className={styles.container}>
      <h2>You need to finish setting BugHerd up on a desktop</h2>
      <img src={BugHerdOnDesktop} alt="BugHerd on desktop" />
      <p>
        📥 <b>BugHerd is best experienced on a desktop.</b> An email with a
        login link and instructions is on its way to you now.
        <span className={styles.blueWrapper}>
          <HelpArticleButton articleId="84883" />
        </span>
      </p>
      <p>
        Didn’t get the email?{' '}
        <a href="#" onClick={handleResendEmail}>
          {' '}
          Resend the login link{' '}
        </a>
      </p>
    </div>
  );
};
