import api from 'jsApi/CommandApi';
import {
  NewStateUpdateBulkRequestArgs,
  newArchiveBulkRequest,
  newStateUpdateBulkRequest
} from 'jsModels/BulkRequest';
import track from 'appJS/utils/analytics';
import { getCurrentProjectId, getCurrentUserId } from 'jsModels/Organization';
interface ArchiveBulkRequest {
  columnId: number;
  taskIds: number[];
}
export const processArchiveBulkRequest = async (
  bulkRequest: ArchiveBulkRequest
): Promise<void> => {
  track('Bulk task action triggered', {
    user_id: getCurrentUserId(),
    project_id: getCurrentProjectId(),
    actions: 'archived'
  });
  const payload = newArchiveBulkRequest(bulkRequest);
  await api.createBulkRequest(payload);
};

type BulkRequest = {
  id: number;
};

export const processStateUpdateBulkRequest = async (
  bulkRequest: NewStateUpdateBulkRequestArgs
): Promise<BulkRequest> => {
  track('Bulk task action triggered', {
    user_id: getCurrentUserId(),
    project_id: getCurrentProjectId(),
    actions: bulkRequest.actionsPerformed.join(', ')
  });
  const payload = newStateUpdateBulkRequest(bulkRequest);
  const response = await api.createBulkRequest(payload);
  return response.data;
};
