import React, { FC, useState } from 'react';
import { Button, Modal, message } from 'antd';
import { getLangKey } from 'models/Language';

import * as translations from './strings';

import styles from './styles.module.css';
import ArrowUp from './images/ic-upgrade-circle.svg';

const strings = translations[getLangKey()];

type Props = {
  organizationId: string;
};

const PremiumIntegrationsPromo: FC<Props> = ({ organizationId }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpgradeConfirm = async () => {
    try {
      setLoading(true);
      let response = await fetch(
        `/organizations/${organizationId}/apply_integration_addon?adjustment=add`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(strings.upgradeError);
      }
      message.success({
        content: strings.upgradeSuccess,
        duration: 4,
        onClose: () => {
          window.location.reload();
        }
      });
    } catch (err) {
      message.error(err.message, 4);
    }
    setLoading(false);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <div className={styles.upgradeContainer}>
        <div className={styles.upgradeTextContainer}>
          <h3>
            <ArrowUp className={styles.upgradeArrow} />
            {strings.addonName}
          </h3>
          <p>{strings.addonPromoContent}</p>
          <Button
            type="primary"
            className={styles.upgradeButton}
            onClick={() => setOpenConfirmModal(true)}
          >
            {strings.addonPromoBtn}
          </Button>
        </div>
        <div className={styles.upgradeImageContainer}>
          <div className={styles.integrationsUpgradeImage} />
        </div>
      </div>
      <Modal
        open={openConfirmModal}
        title={strings.addModalTitle}
        okText={strings.addModalBtn}
        onOk={() => handleUpgradeConfirm()}
        onCancel={() => setOpenConfirmModal(false)}
        confirmLoading={loading}
      >
        <p>{strings.addModalContent}</p>
      </Modal>
    </>
  );
};

const PremiumIntegrationsItem: FC<Props> = ({ organizationId }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDowngradeConfirm = async () => {
    try {
      setLoading(true);
      let response = await fetch(
        `/organizations/${organizationId}/apply_integration_addon?adjustment=remove`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error(strings.downgradeError);
      }
      message.success({
        content: strings.downgradeSuccess,
        duration: 4,
        onClose: () => {
          window.location.reload();
        }
      });
    } catch (err) {
      message.error(err.message, 4);
    }
    setLoading(false);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <div className={styles.groupingHorizontal}>
        <span>{strings.addonName}</span>
        <Button
          type="link"
          onClick={() => setOpenConfirmModal(true)}
          className={styles.removeAddonBtn}
        >
          {strings.removeBtn}
        </Button>
      </div>
      <Modal
        open={openConfirmModal}
        title={strings.removeModalTitle}
        okText={strings.removeModalBtn}
        onOk={() => handleDowngradeConfirm()}
        onCancel={() => setOpenConfirmModal(false)}
        confirmLoading={loading}
      >
        <p>{strings.removeModalContent}</p>
      </Modal>
    </>
  );
};

export { PremiumIntegrationsPromo, PremiumIntegrationsItem };
