import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Input, Select, message } from 'antd';
import User from '../user';
import sortUsers from '../../utils/sortUsers';
import { X as Remove, Search } from 'lucide-react';
import styles from './index.module.css';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
const Option = Select.Option;

export default class UserList extends React.Component {
  static propTypes = {
    authenticityToken: PropTypes.string,
    container: PropTypes.func,
    endpoints: PropTypes.object,
    project: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    success: PropTypes.object,
    users: PropTypes.array,
    onRemovedUser: PropTypes.func,
    collaboratorsEnabled: PropTypes.bool,
    hasAdminRights: PropTypes.bool,
    onUpdateUser: PropTypes.func,
    user: PropTypes.object,
    projects: PropTypes.array,
    canChangeOwner: PropTypes.bool
  };

  static defaultProps = {
    project: false,
    hasAdminRights: false,
    onUpdateUser: () => {}
  };

  componentWillUnmount() {
    message.destroy();
  }

  UNSAFE_componentWillMount() {
    message.config({
      getContainer: this.props.container
    });
  }

  state = {
    filter: 'all',
    searchTerm: null
  };

  roles = () => {
    const roles = [
      { value: 'guest', display: 'Guest', permission: 0 },
      { value: 'billing', display: 'Billing', permission: 3 },
      { value: 'member', display: 'Member', permission: 1 },
      { value: 'manager', display: 'Manager', permission: 2 },
      { value: 'billing_manager', display: 'Billing & Manager', permission: 4 }
    ];

    if (this.props.collaboratorsEnabled) {
      roles.push({
        value: 'collaborator',
        display: 'Collaborator',
        permission: 0
      });
    }

    if (this.props.hasAdminRights && this.props.canChangeOwner) {
      roles.unshift({ value: 'owner', display: 'Owner', permission: 4 });
    }

    return roles;
  };

  searchAndFilterOnChange = (value, event) => {
    if (value) {
      this.setState({ filter: value });
    } else {
      this.setState({ searchTerm: event.target.value });
    }
  };

  filterBySearchTerm = (_member, query) => {
    const displayName = _member.display_name.toLowerCase();
    const email = _member.email.toLowerCase();

    return (
      displayName.includes(query.toLowerCase()) ||
      email.includes(query.toLowerCase())
    );
  };

  getUserList = () => {
    const { searchTerm, filter } = this.state;
    const { users } = this.props;

    let filtered;

    if (filter !== 'all') {
      filtered = users.filter(member => {
        return member.role === filter || (member.owner && filter === 'owner');
      });
      if (searchTerm) {
        filtered = filtered.filter(filteredMember => {
          return this.filterBySearchTerm(filteredMember, searchTerm);
        });
      }
    } else if (searchTerm) {
      filtered = users.filter(member => {
        return this.filterBySearchTerm(member, searchTerm);
      });
    } else {
      filtered = users;
    }

    return sortUsers(filtered);
  };

  getEmptyMessage = filter => {
    const { searchTerm } = this.state;
    if (filter !== 'all' && !searchTerm) {
      return 'This Organization does not currently have any users with that role.';
    } else if (filter !== 'all') {
      return 'Your query does not match any users with that role.';
    } else {
      return 'No users found.';
    }
  };

  render() {
    const {
      container,
      project,
      authenticityToken,
      onRemovedUser,
      success,
      endpoints,
      responseError,
      user,
      projects
    } = this.props;

    const { searchTerm, filter } = this.state;
    const userList = this.getUserList();

    return (
      <div className={styles.userListContainer}>
        {!project && (
          <div className={styles.filterBox}>
            <Input
              className={styles.searchBar}
              placeholder="Search..."
              prefix={<Search className={styles.searchIcon} />}
              value={searchTerm}
              onChange={event => this.searchAndFilterOnChange(null, event)}
              ref={node => (this.searchInput = node)}
              suffix={
                searchTerm && (
                  <Remove
                    className={styles.closeIcon}
                    onClick={() =>
                      this.setState({
                        searchTerm: null
                      })
                    }
                  />
                )
              }
            />
            <div className={styles.filterContainer}>
              <label htmlFor="filterMembers" className={styles.filterLabel}>
                Filter by role: <HelpArticleButton articleId="84839" />
              </label>
              <Select
                id="filterMembers"
                className={styles.filterMembers}
                getPopupContainer={container}
                defaultValue="all"
                onChange={value => this.searchAndFilterOnChange(value, null)}
                style={{ width: '126px', marginRight: '2px' }}
                dropdownMatchSelectWidth={false}
              >
                <Option value="all">All</Option>
                {this.roles()
                  .filter(role => project || role.value !== 'guest')
                  .map(role => (
                    <Option value={role.value} key={role.value}>
                      {role.display}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
        )}
        {userList.length ? (
          userList.map((member, index) => (
            <User
              key={member.id}
              member={member}
              endpoints={endpoints}
              container={container}
              authenticityToken={authenticityToken}
              onRemovedUser={onRemovedUser}
              success={success}
              project={project}
              projects={projects}
              roles={this.roles()}
              onUpdateUser={this.props.onUpdateUser}
              searchTerm={this.state.searchTerm}
              user={user}
              lastUser={userList.length - 1 === index}
            />
          ))
        ) : (
          <div className={styles.alertBox}>
            <Alert
              type="info"
              showIcon
              message={this.getEmptyMessage(filter)}
            />
          </div>
        )}
      </div>
    );
  }
}
