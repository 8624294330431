import React from 'react';
import PropTypes from 'prop-types';
import { message, Modal, Input } from 'antd';
import attachListenerOnMultipleNodes from '../../utils/domListeners';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import styles from './index.module.css';
import { trackPage } from 'utils/analytics';

export default class CommitHook extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    bitBucket: PropTypes.string.isRequired,
    github: PropTypes.string.isRequired,
    gitlab: PropTypes.string.isRequired,
    project_id: PropTypes.number.isRequired,
    organization_id: PropTypes.number.isRequired
  };

  componentDidMount = () => {
    attachListenerOnMultipleNodes("[data-trigger='commit_hook']", element =>
      element.addEventListener('click', () => {
        const { organization_id, project_id } = this.props;
        this.setState({
          show: true
        });
        trackPage('Setup commit hook popup', {
          organization_id,
          project_id
        });
      })
    );
  };

  componentWillUnmount() {
    message.destroy();
  }

  state = {
    show: this.props.show
  };

  handleCopy = (label, value) => {
    navigator.clipboard.writeText(`${value}`);
    message.success(`${label} key copied to clipboard!`);
  };

  handleCancel = () => {
    this.setState({
      show: false
    });
  };

  render() {
    return (
      <Modal
        className={styles.container}
        getContainer={() => document.getElementById('commit_hook_container')}
        open={this.state.show}
        footer={null}
        title={<h2 className={styles.header}>Set up a commit hook</h2>}
        onCancel={this.handleCancel}
      >
        <p className={styles.about}>
          You can configure your Version Control System with a hook and when you
          reference a BugHerd task, the commit message is added as a comment.{' '}
          <HelpArticleButton articleId="33869" />
        </p>
        <div className={styles.middleBlock}>
          <h3 className={styles.subHeader}>GitHub:</h3>
          <label htmlFor="github" className={styles.label}>
            Copy and paste the URL below in to your GitHub project under
            Settings {'>'} Webhooks.
          </label>
          <div className={styles.urlInputBox}>
            <Input
              id="github"
              className={styles.urlInput}
              readOnly
              value={this.props.github}
            />
            <span
              className={styles.copy}
              onClick={() => this.handleCopy('GitHub', this.props.github)}
            >
              Copy
            </span>
          </div>
        </div>
        <div className={styles.middleBlock}>
          <h3 className={styles.subHeader}>GitLab:</h3>
          <label htmlFor="gitlab" className={styles.label}>
            Copy and paste the URL below in to your GitLab project under
            Settings {'>'} Integrations.
          </label>
          <div className={styles.urlInputBox}>
            <Input
              id="gitlab"
              className={styles.urlInput}
              readOnly
              value={this.props.gitlab}
            />
            <span
              className={styles.copy}
              onClick={() => this.handleCopy('GitLab', this.props.gitlab)}
            >
              Copy
            </span>
          </div>
        </div>
        <div className={styles.endBlock}>
          <h3 className={styles.subHeader}>Bitbucket:</h3>
          <label htmlFor="bitbucket" className={styles.label}>
            Copy and paste the URL below in to your Bitbucket project under
            Settings {'>'} Integrations {'>'} Webhooks.
          </label>
          <div className={styles.urlInputBox}>
            <Input
              id="bitbucket"
              className={styles.urlInput}
              value={this.props.bitBucket}
              readOnly
            />
            <span
              className={styles.copy}
              onClick={() => this.handleCopy('BitBucket', this.props.bitBucket)}
            >
              Copy
            </span>
          </div>
        </div>
      </Modal>
    );
  }
}
