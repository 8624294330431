import React from 'react';
import cx from 'classnames';
import uploadToS3 from 'jsUtilities/uploadToS3';
import { getFileInfo } from 'utils/fileListOperations';
import { DragListener } from 'jsUtilities/uploadDragListeners';

import {
  Edit,
  Trash2 as Trash,
  X as Close,
  Upload as UploadIcon
} from 'lucide-react';

import Loader from 'components/loader';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import { Upload, Button, message } from 'antd';

import styles from './index.module.css';

interface LogoUploadProps {
  endpoint: string;
  show: boolean;
  logo?: string | Record<string, unknown>;
  setShow: (show: boolean) => void;
  getAndSaveCustomLogos: (value: string) => void;
  defaultLogo?: React.ReactElement;
  backgroundColour?: string;
  usedWhere?: string;
  logoNumber?: number;
  org: {
    id: number;
    in_trial: boolean;
  };
}

export default class LogoUpload extends React.Component<LogoUploadProps> {
  constructor(props) {
    super(props);
    this.dragRef = React.createRef();
  }

  componentDidMount() {
    this.dragListener = new DragListener({
      container: this.dragRef.current,
      preventDropContainer: window,
      onDrag: this.showDragZone,
      onDragEnd: this.hideDragZone,
      onDrop: this.handleOnChange
    });
    this.dragListener.listen();
  }

  componentWillUnmount() {
    this.dragListener.unListen();
  }

  state = {
    fileList: [],
    isUploading: false,
    tempDimensions: null
  };

  showDragZone = () => {
    const { setShow, show } = this.props;
    if (!show) {
      setShow(true);
    }
  };

  hideDragZone = () => {
    const { setShow, show } = this.props;
    if (show) {
      setShow(false);
    }
  };

  isValidLogo = file => {
    const type = file.type.split('/')[1];
    const { size } = file;
    const { tempDimensions } = this.state;
    let isValid = false;

    const isValidType = type === 'jpg' || type === 'jpeg' || type === 'png';
    const hasValidSize = size < 100000;
    const hasValidDimensions =
      tempDimensions.height >= 20 && tempDimensions.width >= 30;

    const fileTypeError = (
      <span className={styles.errorMessage}>
        Sorry that file type is not accepted. Please upload either a{' '}
        <strong>.png</strong> , <strong>.jpeg</strong> type file.
      </span>
    );

    const fileSizeError = (
      <span className={styles.errorMessage}>
        Sorry, the file (<strong>{file.name}</strong>) is too large. Maximum
        file size is <strong>100kb</strong>.
      </span>
    );

    const logoDimensionsError = (
      <span className={styles.errorMessage}>
        Sorry, the image <strong>width</strong> and <strong>height</strong> must
        be a minimum of <strong>30</strong> and <strong>20</strong> pixels
        respectively. Please try again.
      </span>
    );

    if (!isValidType) {
      message.error(fileTypeError);
    } else if (!hasValidSize) {
      message.error(fileSizeError);
    } else if (!hasValidDimensions) {
      message.error(logoDimensionsError);
      this.setState({ tempDimensions: null });
    } else {
      isValid = true;
    }

    return isValid;
  };

  handleOnChange = file => {
    const { setShow, endpoint } = this.props;
    const _file = getFileInfo(file);

    setShow(false);

    this.storeLogoDimensions(_file, () => {
      if (this.isValidLogo(_file)) {
        const uploadParameters = {
          file: _file,
          remove: this.removeFile,
          endpoint: endpoint,
          additionalQueryParams: { custom_logo: true, filename: _file.name },
          feedback: message.error,
          onStart: this.setUploadingStatus,
          onProgress: this.onProgress,
          onError: this.onError,
          onComplete: this.onComplete
        };
        uploadToS3(uploadParameters);
        this.setState({
          fileList: [_file]
        });
      } else {
        return;
      }
    });
  };

  setUploadingStatus = () => {
    const { fileList } = this.state;
    const isUploading = fileList.length && fileList[0].status === 'uploading';
    this.setState({
      isUploading
    });
  };

  onError = event => {
    const { readyState, status } = event.currentTarget;
    if (readyState === 4) {
      if (status !== 204) {
        message.error('Something went wrong, please try again.');
        this.removeFile();
        this.setUploadingStatus();
      }
    }
  };

  onProgress = ({ loaded, total, lengthComputable }) => {
    if (lengthComputable) {
      let percent = Math.round((loaded / total) * 100);
      const status = percent !== 100 ? 'uploading' : 'done';
      this.updateFile({ percent, status });
    }
  };

  storeLogoDimensions = (file, callback) => {
    const img = new Image();
    img.onload = () => {
      this.setState(
        {
          tempDimensions: {
            width: img.naturalWidth,
            height: img.naturalHeight
          }
        },
        callback
      );
    };
    img.src = this.getLogoBlob(file.originFileObj);
  };

  onComplete = (file, S3URL, key) => {
    this.updateFile({ status: 'done', url: S3URL + key });
    this.setUploadingStatus();
    this.saveCustomLogo(file.url);
  };

  saveCustomLogo = value => {
    const { getAndSaveCustomLogos } = this.props;
    getAndSaveCustomLogos(value);
  };

  removeLogo = () => {
    this.saveCustomLogo(null);
    this.hideDragZone();
  };

  updateFile = data => {
    const { fileList } = this.state;
    const file = fileList[0];
    Object.assign(file, data);
    this.setState({
      fileList: [file]
    });
  };

  removeFile = () => {
    this.setState({
      fileList: []
    });
  };

  getLogoBlob = value => URL.createObjectURL(value);

  getLogo = () => {
    const { isUploading, fileList } = this.state;
    const { logo, defaultLogo } = this.props;
    if (isUploading) {
      return <Loader />;
    } else if (!logo) {
      return defaultLogo;
    } else {
      const source = fileList.length
        ? this.getLogoBlob(fileList[0].originFileObj)
        : logo;
      return <img src={source} className={styles.customLogo} />;
    }
  };

  getClassName = name => {
    const { backgroundColour } = this.props;
    let className = backgroundColour.toLowerCase() + name;
    return styles[className];
  };

  render() {
    const { show, backgroundColour, logoNumber, logo, usedWhere } = this.props;
    const { fileList } = this.state;

    return (
      <div className={this.getClassName('LogoContainer')}>
        <h4 className={styles.uploadHeader}>
          {!show && 'Logo ' + logoNumber + ': '}
          {backgroundColour} background {!show ? 'use' : 'logo'}
        </h4>
        <div ref={this.dragRef} className={this.getClassName('DemoOuter')}>
          {show && (
            <div className={styles.overlay}>
              <div className={styles.iconBoxLarge}>
                <UploadIcon className={styles.uploadIcon} />
              </div>
              <div className={styles.dragAndDrop}>
                <p className={styles.instruction}>
                  Drag & drop to upload a file
                </p>
                <p className={styles.validFiles}>
                  Valid file types include: .png .jpg
                </p>
                <Upload
                  name={backgroundColour.toLowerCase()}
                  fileList={fileList}
                  onChange={event =>
                    this.handleOnChange(event.file.originFileObj)
                  }
                  showUploadList={false}
                  customRequest={() => {}}
                >
                  <Button className={styles.fileBrowser} type="secondary">
                    Click for file browser
                  </Button>
                </Upload>
              </div>
            </div>
          )}
          <div className={styles.demoBox}>
            <div className={styles.logoBox}>{this.getLogo()}</div>
            <Button
              type="secondary"
              className={styles.uploadButton}
              onClick={this.showDragZone}
            >
              <Edit className={styles.icon} />
              Change
            </Button>
          </div>
          <p className={styles.usedWhere}>
            {usedWhere}
            <br />(
            <HelpArticleButton articleId="84853">
              see examples here
            </HelpArticleButton>
            )
          </p>
          {show && (
            <div
              className={cx({
                [styles.actionsBox]: true,
                [styles.noLogoToRemove]: !logo
              })}
            >
              {logo && (
                <Button
                  type="secondary"
                  className={styles.removeLogo}
                  onClick={this.removeLogo}
                >
                  <Trash className={styles.icon} />
                  Remove
                </Button>
              )}
              <Button
                type="secondary"
                className={styles.cancel}
                onClick={this.hideDragZone}
              >
                <Close className={styles.icon} />
                Cancel
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
