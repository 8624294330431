import * as TaskGraphql from './TaskGraphql';

export const STATUS_ARCHIVE = 5;
export const STATUS_ACTIVE = 2;

// Eventually, this needs to the Task type that pusher data conforms to as well
export interface Task {
  data: any;
  id: string;
  description?: string;
  localTaskId?: string;
  projectId?: string;
  statusName?: string;
  dueAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
  visible?: boolean;
  status: 'feedback' | 'kanban' | 'archive';
  site?: string;
  url?: string;
  assetId?: string;
}

export { TaskGraphql };
