import { post, put } from 'utils/fetch';
import { MemberRole } from 'models/MemberRole';
import { UpdateProjectUrl } from 'models/ProjectData';

export const inviteUsers = ({
  projectId,
  emailAddresses,
  slient,
  role
}: {
  projectId: number;
  slient: boolean;
  emailAddresses: string[];
  role: MemberRole;
}) =>
  post(`/projects/${projectId}/invite_users`, {
    email_addresses: emailAddresses.join(','),
    send_email: slient ? '' : '1',
    role
  });

export const setupProjectUrl = ({
  projectId,
  updateProjectData
}: {
  projectId: number;
  updateProjectData: UpdateProjectUrl;
}) => put(`/projects/${projectId}`, updateProjectData);

export const validateJsInstalled = (projectId: number) =>
  post(`/projects/${projectId}/validate_site_works_with_bugherd`, {});

type Project = {
  name: string;
  devurl: string;
  permission: 'guests_see_guests' | 'guests_see_self';
  allow_guests_change_task_status?: boolean;
};

interface ProjectData {
  organizationId: number;
  project: Project;
}

export const createProject = (projectData: ProjectData) =>
  post(`/organizations/${projectData.organizationId}/projects`, projectData);

export const onboardingCreateExampleTask = (projectId: number) =>
  post(`/projects/${projectId}/create_onboarding_example_task`, {});
