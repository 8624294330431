import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'antd';
import { ArrowRight } from 'lucide-react';

import styles from './index.module.css';
import guidedTourIntro from './assets/guided_tour_intro.png';

export const BeforeWeBegin = () => {
  document.title = 'My Project | BugHerd';

  return (
    <div className={styles.container}>
      <div className={styles.flexContainerWrapper}>
        <div className={styles.contentWrapper}>
          <h2>
            Let’s see how BugHerd works on websites with a quick guided
            walkthrough
          </h2>
          <p>
            💡 <strong>Heads up:</strong> To fully unlock BugHerd's features on
            your own websites, you'll need to get the BugHerd's browser
            extension.
          </p>
          <p>
            You won’t need it for this guided walkthrough –{' '}
            <em>we’ll get you set up properly later!</em>
          </p>
          <Link to="/website-feedback" type="button">
            <Button type="primary">
              Begin guided walkthrough
              <ArrowRight size={20} />
            </Button>
          </Link>
          <br />
          <br />
          <Link to="/try-bugherd-on" type="button">
            <Button type="text" className={styles.backButton}>
              Back
            </Button>
          </Link>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            placeholder
            loading={'eager'}
            src={guidedTourIntro}
            alt={'Helper image showing bugherd being installed'}
            preview={false}
          />
        </div>
      </div>
    </div>
  );
};
