import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import GreenTick from '../../../../views/integration_settings/images/ic-green-tick.svg';
import { Dropdown } from 'antd';
import { ExternalLink, Trash, X as Close } from 'lucide-react';
import cx from 'classnames';
import { put } from 'appJS/utils/fetch';

type Props = {
  name: string;
  taskId: number;
  projectId?: number;
  isSmallView: boolean;
  connectedText?: string;
  buttonText?: string;
  href: string;
};

const strings = translations[getLangKey()];

const Connected: FC<Props> = ({
  name,
  taskId,
  projectId,
  isSmallView,
  connectedText,
  buttonText,
  href
}) => {
  const isParagonIntegration = [
    'jira',
    'trello',
    'asana',
    'linear',
    'clickup',
    'productive'
  ].includes(name.toLowerCase());
  const handleRemove = () => {
    put(`/projects/${projectId}/tasks/${taskId}`, { remove_integration: name });
  };

  const items = [
    {
      label: <span>{strings.confirmRemove}</span>,
      icon: <Trash className={styles.trashIcon} />,
      key: '0',
      onClick: () => handleRemove()
    }
  ];

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const removeLink = () => {
    const handleVisibleChange = (visible: boolean) => {
      setIsMenuOpen(visible);
    };

    return (
      <div id="dropdown-container">
        <Dropdown
          trigger={['click']}
          menu={{ items }}
          onOpenChange={handleVisibleChange}
          open={isMenuOpen}
          getPopupContainer={() =>
            document.getElementById('dropdown-container')
          }
        >
          <span>
            {strings.removeLink}
            <Close className={styles.close} />
          </span>
        </Dropdown>
      </div>
    );
  };

  return (
    <div className={styles.cardContent}>
      <div
        className={cx(styles.connectedContainer, {
          [styles.smallConnected]: !!isSmallView
        })}
      >
        <GreenTick className={styles.greenTickIcon} />
        {!href ? (
          <span className={styles.connectedText}>{strings.taskSent}</span>
        ) : (
          [
            <span className={styles.connectedText}>{connectedText}</span>,
            <a
              title={href}
              className={styles.connectedButton}
              href={href}
              target="_blank"
            >
              {buttonText}
              {!isSmallView && <ExternalLink className={styles.linkIcon} />}
            </a>
          ]
        )}
      </div>
      {isParagonIntegration && removeLink()}
    </div>
  );
};

export default Connected;
