export const en = {
  anonymous: 'Anonymous',
  projectLabel: 'Project',
  emptyUserTasksTitle:
    'There are currently no tasks assigned to you or created by you.',
  emptyUserTasksHelp:
    'Here you will see all the tasks assigned to you and created by you, across the projects you have access to.',
  taskLabel: 'Task',
  statusLabel: 'Status',
  dueDateLabel: 'Due date',
  reportedLabel: 'Report date',
  assignedToLabel: 'Assigned to',
  userTasksHeader: 'My tasks',
  refreshPageMessage: 'Some of your tasks have been updated.',
  refreshPageErrorMessage:
    'Errors occurred when retrieving data. Please refresh the page.',
  relativeToday: 'today',
  relativeTomorrow: 'tomorrow',
  relativeYesterday: 'yesterday',
  dateFormat: 'd MMM yyyy',
  myTaskInfoHelpArticleId: '84874',
  myTasksInfoIcon:
    'All Tasks assigned to you or created by you, across the projects you have access to.',
  commentLabel: 'Comments for me',
  emptyUserCommentsTitle: 'There are currently no comments for you.',
  emptyUserCommentsHelp:
    'Here you will see all the comments you are mentioned in and comments of the tasks you are assigned to.',
  memberOnly: 'Members only',
  emptyNoSearchTitle: 'No results found',
  emptyNoSearchHelp: 'Try broadening your search',
  emptyCommentsTitle: "There's no more comments",
  emptyCommentsHelp: 'Try going back to the previous page.',
  emptyPageTasksTitle: "There's no more tasks",
  emptyPageTasksHelp:
    'Try going back to the previous page or changing your filter settings.',
  searchPlacholder: 'Search for comments...',
  updatedLabel: 'Last updated',
  reportedByLabel: 'Reported by',
  commentsForMeInfoIcon:
    'All comments you are mentioned in and comments of the tasks you are assigned to.',
  dashboard: 'Dashboard'
};

export type Translations = typeof en;
