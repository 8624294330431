export const en = {
  add: 'Add',
  save: 'Save',
  cancel: 'Cancel',
  enter: 'enter',
  toSubmit: 'to submit',
  discardChanges: 'Discard your changes?',
  deleteComment: 'Delete your comment?',
  delete: 'Delete',
  addSomeContent:
    'You cannot create an empty comment, please add some content to the field above.'
};

export type Translations = typeof en;
