import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Description from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/Description';
import ReportedBy from '../../../../clients/sidebar/views/SidebarPanel/Detailbar/ReportedBy';
import styles from './index.module.css';
import { accessTo } from 'appJS/models/Access';

export default class DetailsHead extends React.Component {
  static propTypes = {
    createdAt: PropTypes.string,
    setProperty: PropTypes.func.isRequired,
    url: PropTypes.string,
    canEditDescription: PropTypes.bool,
    taskId: PropTypes.number.isRequired,
    localTaskId: PropTypes.number.isRequired,
    column: PropTypes.shape({
      status_id: PropTypes.number,
      id: PropTypes.number,
      name: PropTypes.string
    }).isRequired,
    priorityId: PropTypes.number.isRequired,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        updated_at: PropTypes.string
      })
    ),
    description: PropTypes.string,
    fullstorySessionUrl: PropTypes.string,
    destinations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        status_id: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        position: PropTypes.number
      })
    ),
    creator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        email: PropTypes.string,
        display: PropTypes.string
      })
    ]),
    closeDetailBar: PropTypes.func,
    toggleExpandedView: PropTypes.func,
    deleteTask: PropTypes.func,
    canDeleteTask: PropTypes.bool,
    moveToProject: PropTypes.func,
    canMoveToProject: PropTypes.bool,
    editStatus: PropTypes.bool,
    markAsStatus: PropTypes.func,
    toggleFullScreenView: PropTypes.func,
    container: PropTypes.instanceOf(Element),
    projectId: PropTypes.number,
    secretId: PropTypes.string.isRequired,
    bugherdUrl: PropTypes.string.isRequired,
    isAdminView: PropTypes.bool.isRequired,
    className: PropTypes.string,
    showTaskActions: PropTypes.bool,
    taskActions: PropTypes.func
  };

  static defaultProps = {
    isPlaceholder: false
  };

  componentWillUnmount() {
    this.isSmallDetailsView.removeListener(this.setSmallDetailsView);
  }

  UNSAFE_componentWillMount() {
    this.isSmallDetailsView = window.matchMedia(
      '(min-width: 951px) and (max-width: 1359px)'
    );
    this.setSmallDetailsView(this.isSmallDetailsView);
    this.isSmallDetailsView.addListener(this.setSmallDetailsView);
  }

  updateTaskDescription = description =>
    this.props.setProperty({ description });
  updateTaskStatus = column => this.props.markAsStatus(column);

  setSmallDetailsView = evt => {
    this.setState({
      isSmallDetailsView: evt.matches
    });
  };

  render() {
    const {
      taskId,
      description,
      projects,
      destinations,
      container,
      createdAt,
      moveToProject,
      canMoveToProject,
      deleteTask,
      canDeleteTask,
      canEditDescription,
      column,
      projectId,
      secretId,
      bugherdUrl,
      isAdminView,
      editStatus,
      creator,
      showTaskActions,
      taskActions
    } = this.props;

    const { isSmallDetailsView } = this.state;
    const { updateTaskDescription, updateTaskStatus } = this;

    const reportedProps =
      typeof creator === 'object' ? creator : { display: creator };

    return (
      <div
        className={cx(
          styles.outerContainer,
          styles.container,
          this.props.className,
          { [styles.hideTaskActions]: !showTaskActions }
        )}
      >
        <div
          className={cx(styles.descriptionContainer, {
            [styles.isExpandedView]: !showTaskActions
          })}
        >
          <Description
            {...{
              taskId,
              description,
              canEditDescription,
              updateDescription: updateTaskDescription,
              isExpandedView: !showTaskActions
            }}
            assignGuestsAllowed={accessTo('assign_guests_allowed')}
            assignGuests={accessTo('assign_guests')}
          />
        </div>
        {showTaskActions && (
          <>
            {taskActions()}
            <ReportedBy
              {...{
                createdAt,
                ...reportedProps
              }}
            />
          </>
        )}
      </div>
    );
  }
}
