import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import styles from './styles.module.css';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';

type Props = {
  container: HTMLDivElement;
  projectId: number;
  isGuest: boolean;
};

const strings = translations[getLangKey()];

export const ArchivedBanner: FC<Props> = ({
  projectId,
  container,
  isGuest
}) => {
  const backToProjectLink = isGuest
    ? `/projects/${projectId}/`
    : `/projects/${projectId}/assets`;

  return (
    <Modal
      open
      footer={null}
      title={null}
      closable={false}
      width="90%"
      className={styles.modal}
      getContainer={container}
    >
      <div className={styles.contentInner}>
        <h2 className={styles.loginHeader}>{strings.archivedHeader}</h2>
        <Button type="primary" href={backToProjectLink}>
          {strings.backToProject}
        </Button>
      </div>
    </Modal>
  );
};
