import React, { FC, useState } from 'react';
import { Select, Tag } from 'antd';
import styles from '../index.module.css';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../javascript/models/Language';
import { User as UserIcon } from 'lucide-react';
import Avatar from '../../../../../../shared/Avatar';
import cx from 'classnames';
import * as Types from '../../types';
import { upgradeOption } from '../../../../../../../redesign/javascript/utils/getAssignableUsers';

type Props = {
  assignees: number[];
  onAssigneesChange: (assignees: number[]) => void;
  container: Element;
  inputSize: 'large' | 'middle';
  assignableUsers?: Types.User[];
  assignGuestsAllowed: boolean;
  billing: boolean;
  apiDomain: string;
  isAdminView?: boolean;
};

const strings = translations[getLangKey()];

const Assignees: FC<Props> = props => {
  const {
    assignees,
    onAssigneesChange,
    container,
    inputSize,
    assignableUsers,
    assignGuestsAllowed,
    billing,
    apiDomain,
    isAdminView
  } = props;
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleAssigneeFocus = () => {
    if (!assignees.length) {
      setShowPlaceholder(!showPlaceholder);
    }
  };

  const removeAssignee = (id: number) => {
    const updatedAssignees = assignees.filter(
      (assignee: number) => assignee !== id
    );
    onAssigneesChange(updatedAssignees);
  };

  const getAssigneeTags = (props: any) => (
    <Tag
      icon={<UserIcon className={styles.tagIcon} />}
      closable
      className={styles.tag}
      onClose={() => removeAssignee(props.value)}
    >
      {props.label.props.children[1].props.title}
    </Tag>
  );

  const handleAssigneesChange = (assigned: number[]) => {
    onAssigneesChange(assigned.filter((id: number) => id > 0));
  };

  const optionsToIterate = assignableUsers || [];
  const optionsLength = optionsToIterate.length;

  let options = (assignableUsers || []).map(
    (user: Types.User, index: number) => ({
      className: cx(styles.assigneeOption, {
        [styles.lastOption]: index === optionsLength - 1,
        [styles.withUpgrade]: !assignGuestsAllowed
      }),
      value: user.id,
      label: (
        <>
          <Avatar member={user} size="small" />
          <span title={user.name} className={styles.optionText}>
            {user.name}
          </span>
        </>
      )
    })
  );

  if (!assignGuestsAllowed) {
    options = [
      ...options,
      upgradeOption({
        domain: apiDomain,
        styles: styles,
        upgradeText: strings.assignUpgrade,
        value: 0,
        billing,
        isAdminView
      })
    ];
  }

  return (
    <Select
      mode="multiple"
      className={cx(styles.select, styles.selectAssignees, {
        [styles.hasValue]: assignees.length
      })}
      placeholder={
        showPlaceholder ? (
          <span className={styles.placeHolder}>{strings.assignees}</span>
        ) : null
      }
      suffixIcon={null}
      onChange={handleAssigneesChange}
      getPopupContainer={() => container as HTMLElement}
      value={assignees}
      popupMatchSelectWidth={false}
      onFocus={handleAssigneeFocus}
      onBlur={handleAssigneeFocus}
      tagRender={getAssigneeTags}
      popupClassName={styles.popup}
      size={inputSize}
      open={dropdownOpen}
      onSelect={() => {
        if (dropdownOpen) setDropdownOpen(!dropdownOpen);
      }}
      filterOption={(inputValue, option) => {
        return !!option?.label.props.children[1].props.children
          ?.toLocaleString()
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase());
      }}
      onDropdownVisibleChange={setDropdownOpen}
      options={options}
    />
  );
};

export default Assignees;
