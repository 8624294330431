import React, { FC } from 'react';
import styles from './index.module.css';
import cx from 'classnames';

type Project = {
  name: string;
};

type Props = {
  project: Project;
};
const AssetsGuestHeader: FC<Props> = ({ project }) => {
  return (
    <div
      className={cx(styles.deliverablesWrapper, styles.guestProjectHomeWrapper)}
    >
      <h2 title={project?.name}>{project?.name} </h2>
    </div>
  );
};

export default AssetsGuestHeader;
