import React from 'react';
import { Modal, Space, Button, message } from 'antd';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import styles from './styles.module.css';
import { User } from 'lucide-react';
import { Counter } from './Counter';
import useCounterState from './useCounterState';
import Alerts from './Alerts';
import { getRecommendedMax } from './getUpgradePlan';
import { Plan } from '../types';
import { useMutation } from '@apollo/client';
import { SET_MEMBER_LICENCE } from './query';

export { getRecommendedMax };
import AdditionalMemberPrice from '../AdditionalMemberPrice';
import { showUpdateMemberMessage } from './UpdateMembersMessaging';

interface SetMemberLicenseInput {
  organizationId: string;
  userCount: number;
  duration: string;
}

type Props = {
  organizationId: string;
  showModal: boolean;
  currentPlan: Plan;
  additionalMembers: number;
  container: HTMLElement;
  numMembers: number;
  onClose: () => void;
  onSuccess: () => void;
};

const getAdditionalMembersConsumned = ({
  numMembers,
  planMaximum
}: {
  numMembers: number;
  planMaximum: number;
}) => {
  if (numMembers > planMaximum) return numMembers - planMaximum;
  return 0;
};

const AdditionalMembers: React.FC<Props> = ({
  organizationId,
  showModal,
  currentPlan,
  additionalMembers,
  container,
  numMembers,
  onClose,
  onSuccess
}) => {
  const strings = translations[getLangKey()];

  let { memberCount, setMemberCount, counterState } = useCounterState({
    previousCount: additionalMembers,
    seatsOccupied: getAdditionalMembersConsumned({
      numMembers,
      planMaximum: currentPlan.maxMembers
    }),
    recommendMax: getRecommendedMax(currentPlan.name)
  });

  const [setMemberLicense, { loading }] = useMutation<
    { setMemberLicences: { totalCurrentUsers: number } },
    SetMemberLicenseInput
  >(SET_MEMBER_LICENCE, {
    onCompleted: data => {
      showUpdateMemberMessage({
        previousAdditionalMemberCount: additionalMembers,
        currentAdditionalMemberCount: data.setMemberLicences.totalCurrentUsers
      });
      onSuccess();
    },
    onError: errorMessage => {
      message.error(strings.updateError(errorMessage.message));
      onClose();
    }
  });

  return (
    <>
      <Modal
        open={showModal}
        width={600}
        centered
        getContainer={() => container}
        onCancel={() => {
          onClose();
        }}
        footer={null}
        destroyOnClose
      >
        <h2 className={styles.title}>{strings.title}</h2>
        <Space direction={'horizontal'} />
        <p className={styles.para}>
          {strings.para({
            planName: currentPlan.name,
            price: AdditionalMemberPrice[currentPlan.interval],
            interval:
              currentPlan.interval == 'Monthly' ? strings.month : strings.year
          })}
        </p>
        <p className={styles.para}>{strings.label}</p>
        <div className={styles.counterContainer}>
          <User size={18} />
          <Counter
            currentCount={memberCount}
            min={0}
            OnChange={(newCount: number) => {
              setMemberCount(newCount);
            }}
          />
          <span className={styles.additionalMembersLabel}>
            {strings.additionalMembers}
          </span>
        </div>
        {memberCount !== additionalMembers && (
          <Alerts
            organizationId={organizationId}
            subscriptionState={counterState}
            newMemberCount={memberCount}
            currentMemberCount={additionalMembers}
            currentPlan={currentPlan}
          />
        )}
        <div className={styles.actions}>
          <Button
            className={styles.button}
            type="primary"
            disabled={
              counterState === 'seatsOccupied' ||
              memberCount === additionalMembers
            }
            loading={loading}
            onClick={() => {
              setMemberLicense({
                variables: {
                  organizationId,
                  userCount: memberCount,
                  duration: currentPlan.interval
                }
              });
            }}
          >
            {strings.confirmButton}
          </Button>
          <Button className={styles.ghost} onClick={() => onClose()}>
            {strings.cancelButton}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalMembers;
