export const en = {
  allArchived: 'All tasks archived',
  createBulkRequestFailure: 'Something went wrong',
  performanceArchive: 'This can improve performance',
  goToArchive: "Didn't mean to do it?",
  archivingNow: 'Archiving all tasks',
  archivingMoment: 'This might take a while.',
  archiveAll: 'Archive all tasks?',
  toArchive: 'Undo it here',
  reallyArchive: 'Really?',
  yes: 'Yes'
};
