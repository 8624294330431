import React, { FC } from 'react';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import track from '../../../../../../javascript/utils/analytics';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  url: string;
  isSidebar: boolean;
  orgId: number;
  hasAccess: boolean;
  canAccessBilling: boolean;
};

// @ts-ignore
const strings = translations[getLangKey()];

const Upgrade: FC<Props> = ({
  url,
  isSidebar,
  orgId,
  hasAccess,
  canAccessBilling
}) => {
  const planLink: string = `${
    isSidebar ? url : ''
  }/organizations/${orgId}/subscription`;

  const extensionLink: string = `${isSidebar ? url : ''}/extensions`;

  const buttonLink: string = `${
    isSidebar ? url : ''
  }/organizations/${orgId}/subscription`;

  const target = isSidebar ? '_blank' : '_self';

  return hasAccess ? (
    <div className={styles.modalContent}>
      <p className={styles.whyMessage}>{strings.differentPlan}</p>
      <p>{strings.goodNews}</p>
    </div>
  ) : (
    <div className={styles.modalContent}>
      <p className={styles.whyMessage}>
        {strings.toCaptureScreenshots(canAccessBilling)}
        <a className={styles.whyMessageLink} href={planLink} target={target}>
          {strings.upgradeToOurPremium}
        </a>
        .
      </p>
      <p className={styles.whyMoreInfo}>
        {canAccessBilling ? strings.usersInstall : strings.alternatively}
        <a
          className={styles.whyMoreInfoLink}
          href={extensionLink}
          target={target}
        >
          {strings.bugHerdExtension}
        </a>
        .
      </p>
      {canAccessBilling && (
        <Button
          className={styles.whyButton}
          size="large"
          type="primary"
          onClick={() => {
            track('Upgrade via screenshot', {
              organization: orgId
            });
          }}
        >
          <a className={styles.buttonAnchor} href={buttonLink} target={target}>
            {strings.upgradeToPremium}
          </a>
        </Button>
      )}
    </div>
  );
};

export default Upgrade;
