import React, { FC, useState, useEffect } from 'react';
import { ShortcutOption } from './option';
import styles from './styles.module.css';
import { Shortcut, Trigger } from './getShortcuts';
import { Tag as TagType } from '../../types';
import { getPreAndPostMention } from './getPreAndPostMention';

type Props = {
  textAreaRef: HTMLTextAreaElement | null;
  showMentionInput: boolean;
  setValue: (description: string) => void;
  mentionDropdownPosition: { top: number; left: number };
  updateMentionDropdownPosition: (event?: any) => void;
  shortcuts: Shortcut[];
  description: string;
  createTagsViaNewTask: boolean;
  handleAddNewTag: (newTag: TagType) => void;
};

export const Shortcuts: FC<Props> = ({
  textAreaRef,
  showMentionInput,
  setValue,
  mentionDropdownPosition: { top, left },
  updateMentionDropdownPosition,
  shortcuts,
  description,
  createTagsViaNewTask,
  handleAddNewTag
}) => {
  const [selected, setSelected] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [mention, setMention] = useState<string>('');
  const [triggerIndex, setTriggerIndex] = useState<number | undefined>();
  const [trigger, setTrigger] = useState<Trigger | string>('');

  const triggers = shortcuts.map(shortcut => shortcut.trigger);
  const triggersRegexString = `(${triggers.join('|')})`.replace('$', '\\$');
  const shortcut = shortcuts.find(shortcut => shortcut.trigger === trigger);
  const { data, afterSelect, optionProps, itemKey } = shortcut || {};
  const isTagTrigger = trigger === '#';
  const hasMention = query.length;
  const showNewTag = isTagTrigger && hasMention && createTagsViaNewTask;

  let options = (data || []).filter(
    item =>
      !query ||
      item[itemKey || 'name'].toLowerCase().includes(query.toLowerCase())
  );

  if (showNewTag)
    options = [
      ...options,
      { id: parseFloat(Math.random().toFixed(4)), display: query }
    ];

  const closeMenu = () => {
    setTrigger('');
    setMention('');
    setVisible(false);
    setTriggerIndex(undefined);
    setQuery('');
  };

  const onInput = () => {
    if (!textAreaRef) return;
    const { value, selectionStart } = textAreaRef;
    let triggerIdx = -1;
    let newTrigger: string = '';
    let newMention: string = '';
    const positionIndex = selectionStart;

    const textBeforeCaret = value.slice(0, positionIndex);
    const mentionMatch = textBeforeCaret.match(
      new RegExp(`(\\s|^|\\n)(${triggersRegexString}\\w*\\s?\\w*\\s?\\w*$)`)
    );

    if (mentionMatch) {
      newTrigger = mentionMatch[3];
      if (newTrigger) setTrigger(newTrigger);
      newMention = mentionMatch[2];
      triggerIdx = textBeforeCaret.lastIndexOf(newMention);
      if (newMention.length > 1) setMention(newMention);
    }

    const maybeTrigger = textBeforeCaret[triggerIdx];
    const keystrokeTriggered = maybeTrigger === newTrigger;

    if (!keystrokeTriggered) {
      if (visible) closeMenu();
      return;
    }

    setQuery(newMention.slice(1));
    setTriggerIndex(triggerIdx);
    setSelected(0);
    setVisible(true);
  };

  const onSelect = (index: number) => {
    if (!textAreaRef || triggerIndex === undefined) return;
    const { preMention, postMention } = getPreAndPostMention({
      index: triggerIndex,
      mention,
      description,
      trigger
    });
    const selectedOption = options[index];

    let newValue = preMention + postMention;
    let caretPosition = preMention.length;

    const isAtMention = trigger === '@';
    if (isAtMention) {
      newValue =
        preMention +
        `@[${selectedOption.name}](${selectedOption.id}) ` +
        postMention;
      caretPosition = preMention.length + selectedOption.name.length + 2;
    }

    setValue(newValue);
    textAreaRef.setSelectionRange(caretPosition, caretPosition);

    if (afterSelect) {
      if (isTagTrigger && selectedOption.id < 1) {
        handleAddNewTag(selectedOption);
      } else {
        afterSelect(selectedOption.id);
      }
    }
    closeMenu();
    textAreaRef.focus();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    let keyCaught = false;
    if (triggerIndex !== undefined) {
      switch (event.key) {
        case 'ArrowDown':
          if (visible) {
            setSelected(Math.min(selected + 1, options.length - 1));
            keyCaught = true;
          }
          break;
        case 'ArrowUp':
          if (visible) {
            setSelected(Math.max(selected - 1, 0));
            keyCaught = true;
          }
          break;
        case 'Enter':
        case 'Tab':
          if (visible) {
            onSelect(selected);
            keyCaught = true;
          }
          break;
        case 'Escape':
          if (visible) {
            closeMenu();
            keyCaught = true;
          }
      }
    }
    if (keyCaught) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  };

  const handleCloseMenuOnMouseUp = event => {
    closeMenu();
    updateMentionDropdownPosition(event);
  };

  const addListeners = () => {
    if (!textAreaRef) return;
    textAreaRef.addEventListener('input', onInput);
    textAreaRef.addEventListener('keydown', onKeyDown);
    textAreaRef.addEventListener('mouseup', handleCloseMenuOnMouseUp);
  };

  const removeListeners = () => {
    if (!textAreaRef) return;
    textAreaRef.removeEventListener('input', onInput);
    textAreaRef.removeEventListener('keydown', onKeyDown);
    textAreaRef.removeEventListener('mouseup', handleCloseMenuOnMouseUp);
  };

  useEffect(() => {
    addListeners();
    return removeListeners;
  });

  useEffect(() => {
    if (!showMentionInput && visible) closeMenu();
  }, [showMentionInput]);

  if (!visible || !options.length) return null;

  return (
    <div className={styles.mentionsContainer}>
      <div
        className={styles.dropdown}
        style={{ top: `${top}px`, left: `${left}px` }}
      >
        {options.map((item, index) => {
          const { icon, value, className, active } = optionProps?.(item) || {};
          const newClassName = className || '';
          return (
            <ShortcutOption
              key={item.id}
              index={index}
              item={item}
              selected={selected}
              active={active}
              onSelect={onSelect}
              icon={icon}
              newClassName={newClassName}
              value={value || ''}
              setSelected={setSelected}
            />
          );
        })}
      </div>
    </div>
  );
};
