import React, { ReactNode } from 'react';
import LogoUpload from './logo_upload';
import { Alert, message } from 'antd';
import DefaultForDarkBackground from '../../../../../../assets/images/icons/bugherd-extended-logo.svg';
import DefaultForLightBackground from '../../../../../../assets/images/bugherd-word-logo.svg';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import styles from './index.module.css';
import * as translations from './strings';

import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

interface CustomLogoProps {
  upgradeEndpoint: string;
  organization: {
    id: number;
    in_trial: boolean;
  };
  owner: {
    display_name: string;
    name: string;
    email: string;
    id: number;
  };
  accessTo: {
    billing: boolean;
    customLogosAllowed: boolean;
  };
  logoLightBackground?: string;
  logoDarkBackground?: string;
  getAndSaveCustomLogos: (key: string, value: string) => void;
  endpoint: string;
  children?: ReactNode;
}

interface CustomLogoState {
  showUploadLight: boolean;
  showUploadDark: boolean;
}

export default class CustomLogo extends React.Component<
  CustomLogoProps,
  CustomLogoState
> {
  state = {
    showUploadLight: false,
    showUploadDark: false
  };

  setStateInParent = obj => {
    this.setState(obj);
  };

  getOwnerDisplayInfo = () => {
    const { owner } = this.props;
    if (owner.display_name === owner.email) {
      return owner.display_name;
    } else {
      return owner.display_name + ' (' + owner.email + ')';
    }
  };

  copyOwnerToClipboard = () => {
    const { email } = this.props.owner;
    navigator.clipboard.writeText(email);
    const messageContent = (
      <span className={styles.infoMessage}>
        {strings.ownersEmailAddress} <strong>{email}</strong>{' '}
        {strings.hasBeenCopied}
      </span>
    );
    message.info(messageContent);
  };

  render() {
    const { showUploadLight, showUploadDark } = this.state;
    const {
      logoLightBackground,
      logoDarkBackground,
      getAndSaveCustomLogos,
      accessTo,
      organization,
      endpoint,
      upgradeEndpoint
    } = this.props;

    const { customLogosAllowed, billing } = accessTo;

    return (
      <div className={styles.container}>
        <h3 className={styles.logosHeader}>{strings.customLogo}</h3>

        <p>
          {strings.replaceLogo}
          <HelpArticleButton articleId="84853" />
        </p>
        {organization.in_trial && (
          <Alert
            type="warning"
            showIcon
            className={styles.alert}
            message={
              <p className={styles.alertMessage}>
                <strong>{strings.justSoYouKnow}</strong>
                {strings.onlyAvailable}
                <strong>{strings.premium}</strong>
                {strings.planAndAbove} {strings.goCrazy}
              </p>
            }
          />
        )}
        {!customLogosAllowed ? (
          <Alert
            type="info"
            showIcon
            className={styles.alert}
            message={
              <p className={styles.alertMessage}>
                {strings.customizingLogoNotAvailable}
                {billing ? (
                  <a href={upgradeEndpoint} className={styles.upgradeLink}>
                    {strings.upgradeYourPlan}
                  </a>
                ) : (
                  <span className={styles.contact}>
                    {strings.contactTheAccount}
                    <strong
                      title={this.getOwnerDisplayInfo()}
                      className={styles.owner}
                      onClick={() => this.copyOwnerToClipboard()}
                    >
                      {strings.owner}
                    </strong>
                  </span>
                )}
                .
              </p>
            }
          />
        ) : (
          <div className={styles.logoInputsContainer}>
            <LogoUpload
              org={organization}
              show={showUploadLight}
              logo={logoLightBackground}
              setShow={show => this.setState({ showUploadLight: show })}
              getAndSaveCustomLogos={getAndSaveCustomLogos.bind(
                null,
                'customLogoLightBackground'
              )}
              defaultLogo={<DefaultForLightBackground />}
              backgroundColour="Light"
              usedWhere="Appears in emails sent from BugHerd and other places within the application."
              logoNumber={1}
              endpoint={endpoint}
            />
            <LogoUpload
              org={organization}
              show={showUploadDark}
              logo={logoDarkBackground}
              setShow={show => this.setState({ showUploadDark: show })}
              getAndSaveCustomLogos={getAndSaveCustomLogos.bind(
                null,
                'customLogoDarkBackground'
              )}
              defaultLogo={<DefaultForDarkBackground />}
              backgroundColour="Dark"
              usedWhere="Appears on BugHerd Sidebar, toggle, and the application navigation menu."
              logoNumber={2}
              endpoint={endpoint}
            />
          </div>
        )}
      </div>
    );
  }
}
