import React, {
  FC,
  useState,
  useRef,
  MutableRefObject,
  useEffect
} from 'react';
import { Input, Tag as AntTag, AutoComplete } from 'antd';
import { Plus, Tag as TagIcon } from 'lucide-react';
import { Tag } from '../../../../models/Project';
import DetailsLabel from '../DetailsLabel';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';

// @ts-ignore
import styles from './styles.module.css';
import cx from 'classnames';
import Loader from '../../../../components/Loader';

type Props = {
  onUpdateTags: (tagIds: (number | null)[]) => void;
  onCreateTag: (newTagName: string) => void;
  onRemoveTag: (id: number | null) => void;
  tags: Tag[];
  tagIds: (number | null)[];
  allTags: Tag[];
  canEditTags: boolean;
};

const strings = translations[getLangKey()];

const Tags: FC<Props> = ({
  onUpdateTags,
  onCreateTag,
  onRemoveTag,
  tags,
  canEditTags,
  allTags,
  tagIds
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [newTag, setNewTag] = useState<string>('');
  const inputRef: MutableRefObject<null | Input> = useRef(null);

  const getTag = (_tags: Tag[], _value: string): Tag | undefined =>
    _tags.find(({ name }: Tag) => name === _value);

  const hasId = (id: number | null): boolean => tagIds.includes(id);

  useEffect(() => {
    if (!newTag) return;
    const tag = getTag(tags, newTag);
    if (tag && tag.id && tag.id > 0) setNewTag('');
  }, [allTags, tags]);

  const getOptions = () =>
    allTags
      .filter(({ name, id, active }: Tag) => {
        const _name = name.toLowerCase();
        const _value = value.toLowerCase();
        return !_value
          ? active && !hasId(id)
          : (active && _name.slice(0, _value.length) === _value) ||
              _name.includes(_value);
      })
      .map((tag: Tag) => ({
        value: tag.name,
        label: (
          <div key={tag.id} className={styles.optionTag}>
            <TagIcon className={styles.tagIcon} />
            <span className={styles.tagName}>{tag.name}</span>
          </div>
        )
      }));

  const getTags = () => {
    const tagsClone = tags.slice();
    if (newTag && !getTag(tags, newTag)) {
      tagsClone.push({
        id: -1,
        name: newTag,
        active: true
      });
    }
    return tagIds.length
      ? tagsClone.map((tag: Tag) => (
          <span className={styles.tagHolder} key={tag.id}>
            <AntTag
              className={styles.tag}
              closable={tag.id === -1 ? false : canEditTags}
              onClose={() => onRemoveTag(tag.id)}
            >
              <span className={styles.tagContent} title={tag.name}>
                {tag.id === -1 ? (
                  <Loader useDarkStyles className={styles.loaderIcon} />
                ) : (
                  <TagIcon className={styles.tagIcon} />
                )}
                <span className={styles.innerTag}>{tag.name}</span>
              </span>
            </AntTag>
          </span>
        ))
      : null;
  };

  const blurInput = () => {
    setShowInput(false);
    setValue('');
  };

  const handleOnBlur = async () => {
    if (value && !getTag(tags, value)) {
      onCreateTag(value);
      setNewTag(value);
    }
    blurInput();
  };

  const handleOnKeyDown = (event: any) => {
    const escapeCode: number = 27;
    const enterCode: number = 13;
    if (event?.keyCode === escapeCode) {
      blurInput();
    } else if (event?.keyCode === enterCode) {
      handleOnBlur();
    }
  };

  const handleOnSelect = (_value: string) => {
    const updatedTags = tagIds.slice();
    const addedTag = getTag(allTags, _value);
    if (addedTag) {
      updatedTags.push(addedTag.id);
      onUpdateTags(updatedTags);
    }
    setValue('');
  };

  const handleOnAdd = () => {
    if (newTag && !getTag(tags, newTag)) return;
    setShowInput(true);
    setTimeout(() => {
      setShowDropdown(true);
      inputRef?.current?.focus();
    }, 300);
  };

  return (
    <div className={styles.tagsOuter}>
      <DetailsLabel label={strings.tags} />
      <div className={styles.tagsInner}>
        <div className={styles.tagsBox}>
          <div className={styles.tagsWrapper}>{getTags()}</div>
          {canEditTags && (
            <div className={styles.inputBox}>
              {showInput ? (
                <AutoComplete
                  className={styles.tagsAutoComplete}
                  getPopupContainer={trigger => trigger.parentElement}
                  options={getOptions()}
                  value={value}
                  onChange={setValue}
                  onBlur={() => handleOnBlur()}
                  dropdownMatchSelectWidth
                  onSelect={handleOnSelect}
                  onDropdownVisibleChange={setShowDropdown}
                  open={showDropdown}
                >
                  <Input
                    ref={inputRef}
                    className={styles.tagsInput}
                    type="text"
                    onKeyDown={handleOnKeyDown}
                    placeholder={strings.newTag}
                  />
                </AutoComplete>
              ) : (
                <AntTag
                  onClick={handleOnAdd}
                  className={cx(styles.tag, styles.addTag)}
                >
                  <Plus className={styles.addTagIcon} /> {strings.newTag}
                </AntTag>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tags;
