import React, { FC, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Loader from 'appJS/../clients/sidebar/components/Loader';
import { Dropdown, Menu, InputNumber } from 'antd';
import cx from 'classnames';
import styles from './styles.module.css';

type CurrentPage = number | ((currentPage: number) => number);

type Props = {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (currentPage: CurrentPage) => void;
  container: HTMLDivElement;
  show: boolean;
};

const AssetPagination: FC<Props> = ({
  currentPage,
  setCurrentPage,
  totalPages,
  container,
  show
}) => {
  if (!show) return null;

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const noPreviousPage = currentPage === 1;
  const noNextPage = currentPage === totalPages;

  const handlePreviousPage = () => {
    if (noPreviousPage) return;
    setCurrentPage(previousValue => previousValue - 1);
  };

  const handleNextPage = () => {
    if (noNextPage) return;
    setCurrentPage(previousValue => previousValue + 1);
  };

  return (
    <div className={styles.paginationContainer}>
      <ChevronLeft
        className={cx(styles.paginationIcon, {
          [styles.disabledIcon]: currentPage === 1
        })}
        onClick={handlePreviousPage}
      />
      <Dropdown
        className={styles.pageMenu}
        trigger={['click']}
        getPopupContainer={() => container}
        onOpenChange={setShowMenu}
        open={showMenu}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName={styles.overlay}
        overlay={
          <Menu>
            {Array.from(Array(totalPages).keys()).map(pageNumber => (
              <Menu.Item
                key={pageNumber + 1}
                className={cx(styles.menuItem, {
                  [styles.active]: pageNumber + 1 === currentPage
                })}
                onClick={() => {
                  setCurrentPage(pageNumber + 1);
                  setShowMenu(false);
                }}
              >
                {pageNumber + 1}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <InputNumber
          size="small"
          className={cx(styles.paginationInput, {
            [styles.widerInput]: currentPage > 99
          })}
          value={currentPage}
          max={totalPages || currentPage}
          min={1}
          // @ts-expect-error
          onChange={setCurrentPage}
          controls={false}
        />
      </Dropdown>
      /
      <span className={styles.total}>
        {totalPages || <Loader useDarkStyles className={styles.loaderIcon} />}
      </span>
      <ChevronRight
        className={cx(styles.paginationIcon, {
          [styles.disabledIcon]: currentPage === totalPages
        })}
        onClick={handleNextPage}
      />
    </div>
  );
};

export default AssetPagination;
