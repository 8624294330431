import React from 'react';
import { Input, Button } from 'antd';
import styles from './styles.module.css';
import { ChevronUp, ChevronDown } from 'lucide-react';

type Props = {
  currentCount: number;
  max?: number;
  min: number;
  OnChange: (newCount: number) => void;
};

export const Counter: React.FC<Props> = ({
  currentCount,
  min,
  max,
  OnChange
}) => {
  return (
    <>
      <div className={styles.counterInput}>
        <Input
          className={styles.count}
          value={currentCount}
          onChange={e => {
            const newValue = Number(e.currentTarget.value);
            if (!isNaN(newValue)) {
              OnChange(newValue);
            }
          }}
        />{' '}
        <div className={styles.counter}>
          <Button
            onClick={() => OnChange(currentCount + 1)}
            className={styles.counterUpButton}
            size="small"
            disabled={!!max && currentCount === max}
            icon={<ChevronUp size={15} />}
          />
          <Button
            onClick={() => OnChange(currentCount - 1)}
            disabled={currentCount === min}
            className={styles.counterDownButton}
            size="small"
            icon={<ChevronDown size={15} />}
          />
        </div>
      </div>
    </>
  );
};
