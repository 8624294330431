import { CategoryIcon, CategoryName } from './types';
import {
  Clock,
  Smile,
  Leaf,
  Lollipop,
  Bike,
  Sailboat,
  Lightbulb,
  Pi,
  Flag,
  Search
} from 'lucide-react';

export const categoryIcons: CategoryIcon[] = [
  { icon: Search, category: CategoryName.SEARCH },
  { icon: Clock, category: CategoryName.FREQUENT },
  { icon: Smile, category: CategoryName.PEOPLE },
  { icon: Leaf, category: CategoryName.NATURE },
  { icon: Lollipop, category: CategoryName.FOODS },
  { icon: Bike, category: CategoryName.ACTIVITY },
  { icon: Sailboat, category: CategoryName.PLACES },
  { icon: Lightbulb, category: CategoryName.OBJECTS },
  { icon: Pi, category: CategoryName.SYMBOLS },
  { icon: Flag, category: CategoryName.FLAGS }
];
