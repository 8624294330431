import { getBugherd } from '../models/Application';

const delay = time => {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
};

const REDRAW_DELAY = 200;

const setBugherdElementsVisibility = visibility => {
  const parentPage = getBugherd().parentPage;
  let parentBody = parentPage.$body;
  let frame = parentPage.$frame;
  let fader = parentBody.children('.bhFader');

  if (frame) {
    frame.css('visibility', visibility);
  }
  if (fader) {
    fader.css('visibility', visibility);
  }

  if (parentBody) {
    const isBugVisible = parentBody.find('bug:visible');
    if (isBugVisible) {
      isBugVisible.css('visibility', visibility);
    }
    return parentBody;
  }
  return null;
};

export const afterScreenshot = () => {
  setBugherdElementsVisibility('');
};

export const hideBeforeScreenshot = () => {
  const parentBody = setBugherdElementsVisibility('hidden');
  if (parentBody) {
    parentBody.redraw();
  } else {
    console.log('parentbody is null, nothing to hide');
  }
  // TODO: reject if hide fails
  return delay(REDRAW_DELAY);
};
