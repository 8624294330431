import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './index.module.css';

const ArchivedListHeader = props => {
  const {
    showModal,
    undoBulkArchive,
    singleRequest,
    bulkRequest: { id, createdAt, userFullName },
    getDateString,
    loading
  } = props;

  if (!singleRequest) {
    return (
      <div className={styles.headerOuter}>
        <p>Bulk archiving of tasks occurred</p>
        <span className={styles.buttonWrapper}>
          (
          <Button
            className={styles.viewDetails}
            type="secondary"
            onClick={showModal}
          >
            View details
          </Button>
          )
        </span>
      </div>
    );
  }

  return (
    <div className={styles.headerOuter}>
      <p className={styles.singleRequest}>
        Bulk archive of tasks by {userFullName} at {getDateString(createdAt)}
      </p>
      <Button
        type="secondary"
        className={styles.restoreTasks}
        onClick={() => undoBulkArchive(id)}
        loading={loading}
      >
        Restore Tasks
      </Button>
    </div>
  );
};

ArchivedListHeader.propTypes = {
  showModal: PropTypes.func,
  undoBulkArchive: PropTypes.func,
  singleRequest: PropTypes.bool.isRequired,
  bulkRequest: PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    userFullName: PropTypes.string
  }),
  getDateString: PropTypes.func,
  loading: PropTypes.bool
};

export default ArchivedListHeader;
