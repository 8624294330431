import {
  LeftNavMobileItemsArgs,
  RightNavMobileItemsArgs,
  RightNavItemsArgs
} from './types';
import { getItem } from './getItem';
import { rightNavMobileItems } from './RightNavMobileItems';
import { leftNavMobileItems } from './LeftNavMobileItems';
import { rightNavItems } from './RightNavItems';

export const rightNavMobile = (args: RightNavMobileItemsArgs) =>
  rightNavMobileItems(args)
    .filter(item => !item.hidden)
    .map(getItem);

export const leftNavMobile = (args: LeftNavMobileItemsArgs) =>
  leftNavMobileItems(args)
    .filter(item => !item.hidden)
    .map(getItem);

export const rightNav = (args: RightNavItemsArgs) =>
  rightNavItems(args)
    .filter(item => !item.hidden)
    .map(getItem);
