import { Application, getApplication, PROJECT_TAGS } from './Application';
import { MemberRole } from './MemberRole';
import { Task } from './Task';
import { getCurrentProjectId } from './Organization';
import { ProjectSite as Site } from '../../clients/shared/utils/taskLocation/types';
import { DesignAsset } from 'appClients/design_assets/types';

export enum GuestPermission {
  SeeGuests = 'guests_see_guests',
  SeeSelf = 'guests_see_self'
}

export interface ProjectSite {
  id: string;
  url: string;
  coverUrl?: string;
  updatedAt: string;
  siteVariants: Site[];
  projectId: string;
}

export type Column = {
  columnId: string;
  position: number;
  name: string;
  statusId: null | '5';
  isComplete: boolean;
};

export interface Project {
  id: string;
  name: string;
  devurl: string | null;
  isActive: boolean;
  coverScreenshot: string | null;
  pusherChannelName: string;
  coverUpdateRequestedAt: string | null;
  hasCompletedChecklist: boolean;
  updatedAt: string;
  openTasksCount: string;
  triageTasksCount: string;
  toggleVerticalPosition: number;
  tasks?: Task[];
  columns: Column[];
  numberOfUnreadComments?: number;
  numberOfUnreadCommentsMentioned?: number;
  organizationId: string;
  api_key?: string | number | undefined;
  javascriptInstalled?: boolean;
  siteErrors?: string[];
  lastSiteCheckAt?: string;
  widgetCode?: string;
  assets: DesignAsset[];
  projectFolderId: string | null;
  projectFolderName: string | null;
}

// Backbonejs based interface
export const getCurrentProjectInstallVerification = () => {
  const application = getApplication();
  return {
    javascriptInstalled: application
      ? application.get('javascript_verified')
      : null,
    siteErrors: application ? application.get('site_errors') : null,
    lastSiteCheckAt: application ? application.get('last_site_check_at') : null
  };
};

type Tag = any;
type ProjectTags = {
  projectId: number;
  // eslint-disable-next-line no-undef
  tagsById: Record<number, Tag>;
  tagIds: number[];
};

const setCurrentProjectTagsFromTagsCollection = ({
  projectId,
  application
}: {
  projectId: number;
  application: Application;
}): ProjectTags | null => {
  const tagsCollection: Tag[] = application.tasksCollection.getTagsCollection();

  if (!tagsCollection) {
    application.set(PROJECT_TAGS, null);

    return null;
  }

  const projectTags: ProjectTags = {
    projectId: projectId,
    tagsById: {},
    tagIds: tagsCollection.map(tag => tag.get('id'))
  };

  tagsCollection.forEach(tag => (projectTags.tagsById[tag.get('id')] = tag));
  application.set(PROJECT_TAGS, projectTags);

  return projectTags;
};

// eslint-disable-next-line no-undef
export const getCurrentProjectTags = (): Record<number, Tag> | null => {
  const projectId = getCurrentProjectId();

  if (!projectId) {
    return null;
  }

  const application = getApplication();

  const tags = application.get(PROJECT_TAGS);

  if (tags && tags.projectId === projectId && tags.tagsById) {
    return tags.tagsById;
  }

  const projectTags = setCurrentProjectTagsFromTagsCollection({
    application,
    projectId
  });

  return projectTags?.tagsById || null;
};

export const setCurrentProjectTags = (tags: Tag[]) => {
  const projectId = getCurrentProjectId();

  if (!projectId) {
    return;
  }

  const projectTags: ProjectTags = {
    projectId: projectId,
    tagsById: {},
    tagIds: tags.map(tag => tag.id)
  };

  const application = getApplication();

  application.tasksCollection.setTagsCollection(tags);

  const tagsCollection = application.tasksCollection.getTagsCollection();

  projectTags.tagIds.forEach(id => {
    projectTags.tagsById[id] = tagsCollection.get(id);
  });

  application.set(PROJECT_TAGS, projectTags);
};

export const addNewProjectTag = ({ tagId }: { tagId: number }) => {
  if (!tagId) {
    return;
  }
  const application = getApplication();
  if (!application) {
    return;
  }

  const projectTags = application.get(PROJECT_TAGS);
  if (!projectTags || !projectTags.tagIds || !projectTags.tagsById) {
    return;
  }

  const tagsCollection = application.tasksCollection.getTagsCollection();

  projectTags.tagIds.push(tagId);
  projectTags.tagsById[tagId] = tagsCollection.get(tagId);
};

type UserFilters = {
  is_disabled: boolean;
  account_confirmed: boolean;
  is_on_project?: boolean;
};

const notDisabledAndConfirmed: UserFilters = {
  is_disabled: false,
  account_confirmed: true
};

const notDisabledAndConfirmedAndIsOnProject: UserFilters = {
  ...notDisabledAndConfirmed,
  is_on_project: true
};

type User = {
  id: number;
  name: string;
  email: string;
  role: MemberRole;
  avatarUrl: string;
};

export const getProjectMembers = (): User[] => {
  const application = getApplication();
  return application
    ? application.usersCollection
        .hasRole('member')
        .where(notDisabledAndConfirmedAndIsOnProject)
        .map((user: any) => user.toJSON())
    : [];
};

export const getProjectUsers = (): User[] => {
  const application = getApplication();
  return application
    ? application.usersCollection
        .where(notDisabledAndConfirmedAndIsOnProject)
        .map((user: any) => user.toJSON())
    : [];
};

export const getAllUsers = (): User[] => {
  const application = getApplication();
  return application
    ? application.usersCollection.map((user: any) => user.toJSON())
    : [];
};

export const getCurrentProjectUrl = (): string => {
  const application = getApplication();
  return application ? application.get('projectUrl') : 'null';
};

export const getCurrentProjectName = (): string => {
  const application = getApplication();
  return application ? application.get('name') : 'null';
};
