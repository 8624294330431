import PropTypes from 'prop-types';

import { generatePosition } from './generatePosition';
import { initializeCoordinates } from './initializeCoordinates';

const Props = {
  pageOffset: PropTypes.object.isRequired,
  windowWidth: PropTypes.number.isRequired,
  windowHeight: PropTypes.number.isRequired,
  scrollX: PropTypes.number.isRequired,
  scrollY: PropTypes.number.isRequired,
  documentWidth: PropTypes.number.isRequired,
  documentHeight: PropTypes.number.isRequired,
  bodyWidth: PropTypes.number.isRequired,
  bodyHeight: PropTypes.number.isRequired,
  visualViewport: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired
};

export const generateCoordinates = ({
  pageOffset,
  windowWidth,
  windowHeight,
  scrollX,
  scrollY,
  documentWidth,
  documentHeight,
  bodyWidth,
  bodyHeight,
  visualViewport,
  target
}) => {
  // Prepare
  const coordinates = initializeCoordinates({
    pageOffset,
    windowWidth,
    windowHeight,
    scrollX,
    scrollY,
    documentWidth,
    documentHeight,
    bodyWidth,
    bodyHeight,
    visualViewport,
    target
  });

  // Flag
  coordinates.flagX = coordinates.targetX + coordinates.flagRelativeX;
  coordinates.flagY = coordinates.targetY + coordinates.flagRelativeY;

  if (visualViewport) {
    coordinates.visualViewport.width = Math.round(
      visualViewport.width * visualViewport.scale
    );
    coordinates.visualViewport.height = Math.round(
      visualViewport.height * visualViewport.scale
    );

    coordinates.visualViewport.pinX =
      (coordinates.flagX - visualViewport.offsetLeft) * visualViewport.scale;
    coordinates.visualViewport.pinY =
      (coordinates.flagY - visualViewport.offsetTop) * visualViewport.scale;
  }

  // Screenshot Window
  coordinates.screenshotWindowX = coordinates.scrollX;
  coordinates.screenshotWindowY = coordinates.scrollY;
  coordinates.screenshotWindowFlagX =
    coordinates.flagX - coordinates.screenshotWindowX;
  coordinates.screenshotWindowFlagY =
    coordinates.flagY - coordinates.screenshotWindowY;

  // Make Screenshot Window size adjust to only the size we need to
  coordinates.screenshotWindowWidth = coordinates.windowWidth;

  coordinates.screenshotWindowHeight = coordinates.windowHeight;

  // Screenshot Target
  const screenshotTargetPosition = generatePosition({
    pageWidth: coordinates.pageWidth,
    pageHeight: coordinates.pageHeight,
    flagX: coordinates.flagX,
    flagY: coordinates.flagY,
    width: coordinates.screenshotTargetWidth,
    height: coordinates.screenshotTargetHeight,
    padding: 0
  });

  coordinates.screenshotTargetX = screenshotTargetPosition.x;
  coordinates.screenshotTargetY = screenshotTargetPosition.y;
  coordinates.screenshotTargetFlagX =
    coordinates.flagX - coordinates.screenshotTargetX;
  coordinates.screenshotTargetFlagY =
    coordinates.flagY - coordinates.screenshotTargetY;

  // Return
  return coordinates;
};

generateCoordinates.propTypes = Props;
