import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import { Button, Dropdown, MenuProps } from 'antd';
import { ExternalLink } from 'lucide-react';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';

type Props = {
  projectSites: {
    url: string;
    id: number;
  }[];
  container: HTMLDivElement;
  isMobileView: boolean;
  isReviewApp: boolean;
  isAdminUser: boolean;
  apiKey: string;
};

const strings = translations[getLangKey()];

export const OpenWebsite: FC<Props> = ({
  projectSites,
  container,
  isMobileView,
  isReviewApp,
  apiKey,
  isAdminUser
}) => {
  const [sitesMenuOpen, setSitesMenuOpen] = useState<boolean>(false);

  const items: MenuProps['items'] = projectSites.map(site => ({
    label: (
      <a href={site.url} target="_blank">
        {site.url}
      </a>
    ),
    key: site.id
  }));

  const OpenWebsiteButton = props => (
    <Button
      type="link"
      className={styles.openWebsite}
      icon={<ExternalLink className={styles.websiteIcon} />}
      title={strings.openWebsite}
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        window.open(projectSites[0]?.url, '_blank');
      }}
      {...props}
    >
      {!isMobileView && strings.openWebsite}
    </Button>
  );

  if (isReviewApp || isAdminUser) {
    items.push({
      label: (
        <a href={`/test/widget?apikey=${apiKey}`} target="_blank">
          test project sidebar
        </a>
      ),
      key: 'test'
    });
  }

  if (projectSites.length > 1 || isAdminUser) {
    return (
      <Dropdown
        className={styles.sitesDropdown}
        placement="bottomRight"
        menu={{ items }}
        trigger={['hover']}
        getPopupContainer={() => container}
        open={sitesMenuOpen}
        onOpenChange={setSitesMenuOpen}
      >
        <OpenWebsiteButton />
      </Dropdown>
    );
  } else if (projectSites.length === 1) {
    return <OpenWebsiteButton />;
  }
  return null;
};
