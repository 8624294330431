import * as React from 'react';
import cx from 'classnames';
import { Table, Tooltip, Divider, Button } from 'antd';

import Error from 'components/Error';
import { getLangKey } from 'models/Language';
import HelpArticleButton from 'utils/HelpArticleButton';

import EmptyState from '../EmptyState';
import Loader from '../Loader';
import {
  onUserTasksTableRow,
  getUserTasksTableColumnsConfig
} from './userTasksTableConfig';
import FilterTask from '../FilterTask';
import { useState } from './userTasksState';
import styles from '../index.module.css';
import * as translations from '../strings';
import { UserTasksProps } from '../types';

const getPopupContainer = () =>
  document.getElementById('ReactApp') as HTMLElement;

const UserTasks = ({
  organization,
  onPusherUpdate,
  refresh
}: UserTasksProps) => {
  const strings = translations[getLangKey()];
  const organizationId = organization.id;

  const {
    pageNumber,
    pusherUpdated,
    userTasks,
    userTasksPageSize,
    handleTableChange,
    handlePagination,
    userTaskFilter: filter,
    setUserTaskFilter,
    getUserTasks: { data, loading, error, refetch }
  } = useState({ organizationId });

  if (refresh) refetch();

  React.useEffect(() => {
    if (pusherUpdated) onPusherUpdate();
  }, [pusherUpdated]);

  if (loading && userTasks.length === 0) return <Loader />;
  if (error) return <Error error={error} />;
  if (!data && userTasks.length === 0) return <p>Not found</p>;

  const columns = getUserTasksTableColumnsConfig(strings);

  const emptyUserTasks = data && data.currentUser.userTasks.length === 0;
  const showFilterPanel: boolean = !emptyUserTasks || !!filter;

  /* can only guarantee there's no more tasks if the current page has less than the page size */
  const hasMoreTasks =
    data && data.currentUser.userTasks.length >= userTasksPageSize;

  const hasTasksButNoneOnThisPage = pageNumber > 1 && emptyUserTasks;

  const renderEmptyState = () => {
    if (hasTasksButNoneOnThisPage) {
      return (
        <EmptyState
          title={strings.emptyPageTasksTitle}
          help={strings.emptyPageTasksHelp}
          container="Task"
        />
      );
    }

    if (filter) {
      return (
        <EmptyState
          title={strings.emptyNoSearchTitle}
          help={strings.emptyNoSearchHelp}
          container="Task"
        />
      );
    }

    return (
      <EmptyState
        title={strings.emptyUserTasksTitle}
        help={strings.emptyUserTasksHelp}
        container="Task"
      />
    );
  };

  return (
    <>
      <h2 className={styles.myDashboard}>{strings.dashboard}</h2>
      <h4 className={styles.myTasksHeader}>
        {strings.userTasksHeader}
        <Tooltip
          title={<p>{strings.myTasksInfoIcon}</p>}
          getPopupContainer={getPopupContainer}
          placement="rightBottom"
        >
          <span className={styles.helpIconOuter}>
            <HelpArticleButton articleId={strings.myTaskInfoHelpArticleId} />
          </span>
        </Tooltip>
      </h4>
      <Divider />
      <div></div>
      {showFilterPanel && (
        <FilterTask
          className={styles.filterMargin}
          onFilter={setUserTaskFilter}
          defaultFilter={filter}
          organization={organization}
        />
      )}
      {emptyUserTasks ? (
        <div className={styles.emptyUserTasks}>{renderEmptyState()}</div>
      ) : (
        <Table
          className={cx(styles.table)}
          rowKey="id"
          onRow={onUserTasksTableRow}
          dataSource={userTasks}
          pagination={false}
          columns={columns}
          onChange={handleTableChange}
          showSorterTooltip={false}
        />
      )}

      <div className={styles.pagination}>
        {pageNumber !== 1 && (
          <Button
            type="primary"
            onClick={() => handlePagination(pageNumber - 1)}
          >
            Previous
          </Button>
        )}{' '}
        {hasMoreTasks && (
          <Button
            type="primary"
            onClick={() => handlePagination(pageNumber + 1)}
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default UserTasks;
