import React, { FC, ReactNode } from 'react';
import {
  ArrowUpDown,
  ArrowRightLeft,
  RotateCwSquare,
  RotateCcwSquare,
  ZoomIn,
  ZoomOut,
  Download
} from 'lucide-react';
import { PreviewToolbarProps } from './PreviewToolbar';

type ToolbarAction = {
  title: string | ((isMac: boolean) => ReactNode);
  icon: FC;
  onClick?: (() => Promise<void>) | (() => void);
  show?: boolean;
  disabled?: boolean;
};

export const toolbarActions = ({
  actions: {
    onFlipY,
    onFlipX,
    onRotateLeft,
    onRotateRight,
    onZoomIn,
    onZoomOut
  },
  scale,
  isImage
}: {
  actions: PreviewToolbarProps['actions'];
  scale: number;
  isImage: boolean;
}): ToolbarAction[] => [
  {
    title: (isMac: boolean) => (
      <>
        Use <code>{isMac ? 'option' : 'alt'} + click</code> to download
      </>
    ),
    icon: Download,
    show: true
  },
  {
    title: 'Flip top to bottom',
    icon: ArrowUpDown,
    onClick: onFlipY,
    show: isImage
  },
  {
    title: 'Flip left to right',
    icon: ArrowRightLeft,
    onClick: onFlipX,
    show: isImage
  },
  {
    title: 'Rotate Left 90°',
    icon: RotateCcwSquare,
    onClick: onRotateLeft,
    show: isImage
  },
  {
    title: 'Rotate Right 90°',
    icon: RotateCwSquare,
    onClick: onRotateRight,
    show: isImage
  },
  {
    title: 'Zoom in',
    icon: ZoomIn,
    onClick: onZoomIn,
    disabled: scale === 50,
    show: isImage
  },
  {
    title: 'Zoom out',
    icon: ZoomOut,
    onClick: onZoomOut,
    show: isImage,
    disabled: scale === 1
  }
];
