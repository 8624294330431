import React, { FC, ReactEventHandler, ReactNode } from 'react';
import {
  useDesignAssetsState,
  BETA_USER_SURVEY_FEEDBACK_ID,
  PLACEHOLDER_SURVEY_FEEDBACK_ID
} from '../providers/DesignAssets';

type AssetsTabProps = {
  AssetNavItem?: ReactNode;
};

const AssetsTab: FC<AssetsTabProps> = ({ AssetNavItem }) => {
  const { setSurveyId, setShowAssets, setIsModalOpen } = useDesignAssetsState();
  const onClick: ReactEventHandler = event => {
    event.preventDefault();
    //@ts-expect-error
    window.bugherd.applicationView.showAssets();
    setSurveyId(BETA_USER_SURVEY_FEEDBACK_ID);
    setShowAssets(true);
  };
  return <div onClick={onClick}>{AssetNavItem}</div>;
};

export default AssetsTab;
