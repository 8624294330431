export const defaultMostUsedEmojis = [
  'joy',
  'heart',
  'rolling_on_the_floor_laughing',
  '+1',
  'pray',
  'sob',
  'tada',
  'fire',
  'clap',
  'ok_hand',
  'muscle',
  'sparkles',
  '100',
  'balloon',
  'rage',
  'bouquet',
  'see_no_evil',
  'crossed_fingers',
  'raised_hands',
  'skull',
  'grimacing',
  'unamused',
  'moneybag',
  'gift',
  'hankey',
  'white_check_mark',
  'wave',
  'wink',
  'thinking_face',
  'sunglasses',
  'eyes',
  'relieved',
  'sleeping',
  'trophy',
  'heart_hands',
  'pinched_fingers'
];
