import React, {
  FC,
  useState,
  useEffect,
  useRef,
  MutableRefObject
} from 'react';
import Image from '../../../../../../clients/sidebar/views/CreateTask/create_task/annotate_screenshot/image';
import { Modal, Radio } from 'antd';
import cx from 'classnames';
import * as translations from './strings';
import { getLangKey } from '../../../../../javascript/models/Language';

import styles from './index.module.css';
import Loader from '../../Loader';
// @ts-ignore
const strings = translations[getLangKey()];

const viewOptions = [
  { label: strings.shrinkToFit, value: 'shrink' },
  { label: strings.actual, value: 'actual' },
  { label: strings.annotate, value: 'annotate' }
];

type Props = {
  pinUrl: string;
  url: string;
  toggleScreenshot: (isOpen: boolean, modalName: string) => void;
  show: boolean;
  viewport: {
    pinX: number;
    pinY: number;
    viewportWidth: number;
    viewportHeight: number;
  };
  onSaveAnnotation: (dataUri: any) => void;
  onCancelAnnotation: () => void;
  annotating: boolean;
  setAnnotating: (annotating: boolean) => void;
  container: HTMLElement;
  gotoTask?: () => void;
  taskUrls: {
    relativeUrl?: string;
  };
  isAdminView: boolean;
};

const ScreenshotModal: FC<Props> = props => {
  const {
    pinUrl,
    url,
    toggleScreenshot,
    show,
    viewport: { pinX, pinY, viewportWidth, viewportHeight },
    onSaveAnnotation,
    onCancelAnnotation,
    annotating,
    setAnnotating,
    container,
    gotoTask,
    taskUrls: { relativeUrl },
    isAdminView
  } = props;

  const canvasContainer: MutableRefObject<any> = useRef();

  const [view, setView] = useState<string>('shrink');
  const [blob, setBlob] = useState<string>();
  const [loadingBlob, setLoadingBlob] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();

  const calculateContainer = () => {
    if (canvasContainer && canvasContainer.current) {
      const { width, height } = canvasContainer.current.getBoundingClientRect();
      if (width !== containerWidth || height !== containerHeight) {
        setContainerWidth(width);
        setContainerHeight(height);
      }
    }
  };

  const getBlob = (_url: string = url) => {
    if (!_url) {
      return;
    }

    setLoadingBlob(true);

    let contentType: string | null = '';
    fetch(_url, {
      // @ts-ignore
      responseType: 'arraybuffer',
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then(r => {
        contentType = r.headers.get('content-type');
        return r.blob();
      })
      .then(blob => {
        new Response(blob).arrayBuffer().then(buffer => {
          let binary = '';
          const bytes = new Uint8Array(buffer);

          for (var i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          setBlob('data:' + contentType + ';base64,' + window.btoa(binary));
        });
      })
      .catch(e => {
        setBlob('');
        console.error(e);
      })
      .finally(() => {
        setLoadingBlob(false);
      });
  };

  useEffect(() => {
    calculateContainer();
  });

  useEffect(() => {
    if (annotating && !blob && url && !loadingBlob) {
      getBlob(url);
    }
  }, [annotating, blob, url, loadingBlob]);

  const getModalBugPinStyle: {
    backgroundImage?: string;
    left?: string;
    top?: string;
  } =
    pinY && pinX && pinX <= viewportWidth && pinY <= viewportHeight
      ? {
          backgroundImage: `url(${pinUrl})`,
          left: `${(pinX / viewportWidth) * 100}%`,
          top: `${(pinY / viewportHeight) * 100}%`
        }
      : {};

  const toggleAnnotation = (_annotating: boolean) => {
    setAnnotating(_annotating);
    if (_annotating) setView('shrink');
  };

  const renderViewToggle = annotating ? null : (
    <div className={styles.viewOptions}>
      <Radio.Group
        buttonStyle="solid"
        value={view}
        onChange={event =>
          event.target.value === 'annotate'
            ? toggleAnnotation(true)
            : setView(event.target.value)
        }
        options={viewOptions}
        optionType="button"
      />
    </div>
  );

  const handleSave = (dataUri: any) => {
    toggleAnnotation(false);
    onSaveAnnotation(dataUri);
    toggleScreenshot(show, 'showScreenshot');
  };

  const handleCancel = () => {
    toggleAnnotation(false);
    onCancelAnnotation();
  };

  const handleModalCancel = () => {
    toggleScreenshot(show, 'showScreenshot');
    handleCancel();
  };

  return (
    <Modal
      open={show}
      className={cx(styles.screenshotModal, styles[view])}
      footer={null}
      getContainer={container}
      onCancel={handleModalCancel}
      width="100%"
      destroyOnClose
      // @ts-ignore
      height="100%"
      wrapClassName={cx(styles.modalWrap, {
        [styles.adminModalWrap]: isAdminView
      })}
    >
      <div
        className={styles.modalScreenshotContainer}
        onClick={() => toggleScreenshot(show, 'showScreenshot')}
      >
        <div
          className={styles.pinContainer}
          onClick={event => event.stopPropagation()}
          ref={canvasContainer}
        >
          <img className={styles.modalImage} src={url} alt="task_screenshot" />
          {blob && annotating && (
            <div className={styles.imageCanvas}>
              <Image
                {...props}
                blob={blob}
                onSave={handleSave}
                onCancel={handleCancel}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                toolsFixed
                container={container}
              />
            </div>
          )}
          {loadingBlob && <Loader className={styles.loading} useDarkStyles />}
          <div
            className={cx(styles.modalBugPinContainer, {
              [styles.inAppPin]: isAdminView && !!relativeUrl
            })}
            style={getModalBugPinStyle}
            title={
              isAdminView && !!relativeUrl
                ? `View task at ${relativeUrl}`
                : undefined
            }
            onClick={isAdminView ? gotoTask : () => {}}
          />
        </div>
      </div>
      {renderViewToggle}
    </Modal>
  );
};

export default ScreenshotModal;
