import React from 'react';
import { Button } from 'antd';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';

import styles from './index.module.css';

type Props = {
  error: string;
  toggleWhy: (isOpen: boolean, modalName: string) => void;
  show: boolean;
};

// @ts-ignore
const strings = translations[getLangKey()];

export default ({ error, toggleWhy, show }: Props) => (
  <div className={styles.modalContent}>
    <p className={styles.whyMessage}>{strings.followingError}</p>
    <p className={styles.whyMoreInfo}>{`HTTP Error code: ${error}`}</p>
    <Button
      className={styles.whyButton}
      onClick={() => toggleWhy(show, 'showWhy')}
      size="large"
      type="primary"
    >
      {strings.ok}
    </Button>
  </div>
);
