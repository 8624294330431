export const en = {
  errorApi: 'Something went wrong, please try again',
  errorEnablingTag: 'Server error when unarchiving Tag. Please try again.',
  errorDisablingTag: 'Server error when archiving Tag. Please try again',
  successEnablingTag: 'Tag was successfully unarchived.',
  successDisablingTag: 'Tag was successfully archived.',
  archivedDescription:
    'These tags will no longer appear in the tags dropdown or the tag progress list.',
  archivedSubHeader: 'Archived Tags',
  emptyHeader: 'You don’t have any tags yet',
  emptyContent: 'Tags can be used to help you organise your tasks',
  edit: 'Edit',
  update: 'Update',
  cancel: 'Cancel',
  disable: 'Archive',
  enable: 'Unarchive',
  title: 'Edit Tags',
  preventCreatingTags:
    'Prevent users from creating new tags while creating a task'
};
