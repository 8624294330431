import React, { FC } from 'react';
import cx from 'classnames';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  taskId: number;
  isSidebar: boolean;
  screenshotKey: string;
};

// @ts-ignore
const strings = translations[getLangKey()];

const Generic: FC<Props> = ({ taskId, isSidebar, screenshotKey }) => {
  const buttonProps = isSidebar
    ? {
        ['data-elevio-module']: '3475',
        ['data-elevio-style']: 'nothing',
        ['data-elevio-unique-id']: '1',
        className: cx({
          [styles.whyButton]: true,
          _elevio_nothing: true,
          ctxNothing: true
        }),
        children: strings.contactSupport
      }
    : {
        children: (
          <a href="mailto:support@bugherd.com" className={styles.anchorButton}>
            {strings.contactSupport}
          </a>
        ),
        className: styles.whyButton
      };

  return (
    <div className={styles.modalContent}>
      <p className={styles.whyMessage}>
        {strings.errorOccurred}{' '}
        {!screenshotKey && (
          <a
            href="https://support.bugherd.com/hc/en-us/articles/360000172236-Why-didn-t-my-screenshot-work-"
            target="_blank"
          >
            {strings.readMore}
          </a>
        )}
      </p>
      {screenshotKey && (
        <p className={styles.whyMoreInfo}>
          {strings.forHelp}{' '}
          <a
            href="mailto:support@bugherd.com"
            className={styles.whyMoreInfoLink}
          >
            support@bugherd.com
          </a>
          {strings.andMention(taskId)}
        </p>
      )}
      {screenshotKey && <Button type="primary" size="large" {...buttonProps} />}
    </div>
  );
};

export default Generic;
