import React, { useState } from 'react';
import { Input, Switch, Button, Alert, Form } from 'antd';
import styles from './security.module.css';
import { useMutation } from '@apollo/client';
import { UPDATE_PROJECT } from '../../queries';
interface Props {
  project: {
    id: string;
    basicAuthUsername: string;
    basicAuthPassword: string;
    organizationId: string;
  };
  onCancel?: () => void;
  onSave?: () => void;
  accessTo: {
    mobileScreenshots: boolean;
  };
  refetch?: () => void;
}
const Security: React.FC<Props> = (props: Props) => {
  const {
    project,
    project: { basicAuthUsername, basicAuthPassword },
    accessTo,
    refetch
  } = props;

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const [basicAuthentication, setBasicAuthentication] = useState(
    !!(basicAuthUsername || basicAuthPassword)
  );

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      setSaving(false);
      if (refetch) {
        refetch();
      } else {
        window.location.reload();
      }
    },
    onError: () => {
      setSaving(false);
    }
  });

  const submit = () => {
    setSaving(true);

    const { getFieldValue } = form;
    const body = {
      basicAuthUsername: basicAuthentication
        ? getFieldValue('basicAuthUsername')
        : null,
      basicAuthPassword: basicAuthentication
        ? getFieldValue('basicAuthPassword')
        : null
    };

    updateProject({
      variables: {
        projectId: project.id,
        ...body
      }
    });
  };

  const handleCancel = () => {
    props.onCancel();
    // This is because antd animates the modal, so if you reset NOW, the user sees.
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  const { switchEnabled, switchDisabled } = styles;
  const authLabelClass = basicAuthentication ? switchEnabled : switchDisabled;

  return (
    <div className={styles.security}>
      <Form form={form} onFinish={submit} layout="vertical" hideRequiredMark>
        <h3 className={styles.basicHeader}>Basic Authentication</h3>
        <p className={styles.basicText}>
          To capture screen shots behind Basic Authentication, add your
          credentials below.
        </p>
        <Form.Item
          label={
            <span className={authLabelClass}>
              {basicAuthentication ? 'Enabled' : 'Disabled'}
            </span>
          }
          className={styles.switchWrapper}
        >
          <Switch
            className={styles.switch}
            disabled={!accessTo.mobileScreenshots}
            checked={basicAuthentication}
            onChange={checked => {
              setBasicAuthentication(checked);
              if (!checked) {
                form.setFields([
                  { name: 'basicAuthUsername', value: '' },
                  { name: 'basicAuthPassword', value: '' }
                ]);
              }
            }}
          />
        </Form.Item>
        {!accessTo.mobileScreenshots && (
          <Alert
            className={styles.alert}
            type="info"
            showIcon
            closable={false}
            message={
              <p className={styles.alertMessage}>
                This screenshot feature is not available on your current plan.
                On the Premium (25 users) and above plans BugHerd will capture
                accurate screenshots, on desktop and mobile, on Safari, Chrome,
                Edge (IE), and Firefox, without the need for browser extensions.
                <a
                  href={`/organizations/${project.organizationId}/subscription`}
                  className={styles.upgradeAnchor}
                >{` Upgrade here`}</a>
                .
              </p>
            }
          />
        )}
        <Form.Item
          style={{
            display: !basicAuthentication ? 'none' : 'block'
          }}
          label={<span className={styles.basicInputLabel}>Username:</span>}
          name="basicAuthUsername"
          initialValue={basicAuthUsername}
        >
          <Input name="project[basic_auth_username]" />
        </Form.Item>
        <Form.Item
          style={{
            display: !basicAuthentication ? 'none' : 'block'
          }}
          label={<span className={styles.basicInputLabel}>Password:</span>}
          name="basicAuthPassword"
          initialValue={basicAuthPassword}
          rules={[
            {
              required: basicAuthentication,
              message: `Password is required`
            },
            () => ({
              validator(rule, value) {
                if (!value.includes('#') || !value) {
                  return Promise.resolve();
                }

                return Promise.reject('Password cannot contain a #');
              }
            })
          ]}
        >
          <Input.Password name="project[basic_auth_password]" />
        </Form.Item>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            loading={saving}
          >
            Save
          </Button>
          {props.onCancel && (
            <Button className={styles.ghost} onClick={handleCancel}>
              Cancel
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Security;
