import { properties } from './properties';

const isFirefox =
  typeof window !== 'undefined' && window['mozInnerScreenX'] != null;

export const getCaretCoordinates = (
  element: HTMLTextAreaElement,
  position: number
) => {
  const div: HTMLDivElement = document.createElement('div');
  document.body.appendChild(div);

  const style = div.style;
  const computed = getComputedStyle(element);

  style.whiteSpace = 'pre-wrap';
  style.wordWrap = 'break-word';
  style.position = 'absolute';
  style.visibility = 'hidden';

  properties.forEach(prop => {
    style[prop] = computed[prop];
  });

  if (!isFirefox) {
    style.overflow = 'hidden';
  } else if (element.scrollHeight > parseInt(computed.height, 10)) {
    style.overflowY = 'scroll';
  }

  const elementText = element.value;

  // Replace newline characters with <br> tags
  const textBeforeCaret = elementText
    .substring(0, position)
    .replace(/\n/g, '<br>');
  const textAfterCaret = elementText.substring(position).replace(/\n/g, '<br>');

  div.innerHTML = textBeforeCaret;
  const span: HTMLSpanElement = document.createElement('span');
  span.innerHTML = textAfterCaret;
  div.appendChild(span);

  const coordinates = {
    top: span.offsetTop + parseInt(computed['borderTopWidth'], 10),
    left: span.offsetLeft + parseInt(computed['borderLeftWidth'], 10),
    height: span.offsetHeight
  };

  div.remove();

  return coordinates;
};
