import { useState, useEffect } from 'react';
import {
  FilterValues,
  SortAttributeValues,
  sortAttributeKeys,
  SortAttributeKey,
  FeedbackView,
  filterKeys,
  OrderValues,
  orders,
  Order,
  FilterKey
} from './types';
import { getApplication } from 'appJS/models/Application';

const useFilterAndSort = () => {
  const app = getApplication();

  const getFeedbackFilter = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const feedback = searchParams.get('feedback');
    if (searchParams.size && feedback) {
      return FilterValues[feedback];
    }
    return 0;
  };

  const [feedbackView, setFeedbackView] = useState<FeedbackView>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [sortAttribute, setSortAttribute] = useState<SortAttributeValues>();
  const [feedbackOrder, setFeedbackOrder] = useState<OrderValues>();
  const [feedbackFilter, setFeedbackFilter] = useState<FilterValues>(
    getFeedbackFilter()
  );

  useEffect(() => {
    // @ts-expect-error
    window.setFeedbackView = setFeedbackView;
    // @ts-expect-error
    window.setFeedbackFilter = setFeedbackFilter;
  }, []);

  const getSortAttributeValue = () => {
    const currentAttribute: SortAttributeKey = app?.get?.(
      `${feedbackView}_sortAttribute`
    );
    return SortAttributeValues[currentAttribute];
  };

  const getOrder = (): OrderValues =>
    app?.get?.(`${feedbackView}_sortAscending`) ? 0 : 1;

  useEffect(() => {
    if (feedbackView) {
      setSortAttribute(getSortAttributeValue());
      setFeedbackOrder(getOrder());
    }
  }, [feedbackView]);

  const onOrderChange = (orderKey: Order) => {
    // eslint-disable-next-line no-unused-expressions
    app?.get?.('setOrder')?.();
    setFeedbackOrder(OrderValues[orderKey]);
  };
  const onSortingChange = (sortKey: SortAttributeKey) => {
    // eslint-disable-next-line no-unused-expressions
    app?.get?.('setSort')?.(sortKey);
    setSortAttribute(SortAttributeValues[sortKey]);
    if (sortKey === 'priority_id' && getOrder() === 0) {
      onOrderChange('descending');
    }
  };

  const onFilterChange = (filterKey: FilterKey) => {
    const assetFacet = document.getElementById('feedback_assets');
    const websitesFacet = document.getElementById('feedback_websites');

    const filterValue = FilterValues[filterKey];
    // Check that the filter value is valid and that it is a feedback value
    if (filterValue && [0, 1, 2].includes(filterValue)) {
      if (feedbackFilter) {
        if (feedbackFilter === FilterValues.assets) {
          // eslint-disable-next-line no-unused-expressions
          assetFacet?.click();
        } else {
          // eslint-disable-next-line no-unused-expressions
          websitesFacet?.click();
        }
      }
      // @ts-expect-error
      window.bugherd.application.get('addFacet')('feedback', filterKey, true);
    } else if (filterValue && [3].includes(filterValue)) {
      // @ts-expect-error
      const email = window.bugherd.application.get('currentUser').attributes
        .email;

      // @ts-expect-error
      window.bugherd.application.get('addFacet')('mentioned', email, true);
    } else if (filterValue && [4].includes(filterValue)) {
      // @ts-expect-error
      window.bugherd.application.get('addFacet')('unread', filterKey, true);
    } else {
      // eslint-disable-next-line no-unused-expressions
      assetFacet?.click();
      // eslint-disable-next-line no-unused-expressions
      websitesFacet?.click();
    }

    setFeedbackFilter(filterValue);
  };

  const handleMenuClick = ({ key }) => {
    if (filterKeys.includes(key)) {
      onFilterChange(key);
    } else if (sortAttributeKeys.includes(key)) {
      onSortingChange(key);
    } else if (orders.includes(key)) {
      onOrderChange(key);
    }
  };

  return {
    onClick: handleMenuClick,
    onOpenChange: setIsMenuOpen,
    open: isMenuOpen,
    feedbackView,
    feedbackFilter,
    sortAttribute,
    feedbackOrder
  };
};

export default useFilterAndSort;
