import React, { FC, ReactNode } from 'react';
import styles from './styles.module.css';
import { Button } from 'antd';
import { Plus } from 'lucide-react';
import cx from 'classnames';

type Props = {
  onClick?: (event?: any) => void;
  className?: string;
  icon?: ReactNode;
  title?: string;
  btnText?: string;
  dataCypressTrigger?: string;
};

export const AddButton: FC<Props> = ({
  onClick,
  className,
  icon,
  title,
  btnText,
  dataCypressTrigger
}) => (
  <Button
    type="primary"
    id="add-task-button"
    data-cypress-trigger={dataCypressTrigger}
    className={cx(styles.addButton, { [className || '']: className })}
    icon={icon || <Plus className={styles.plusIcon} />}
    onClick={onClick}
    title={title}
  >
    {btnText ? <span> {btnText}</span> : null}
  </Button>
);
