export const en = {
  accountAlreadyExists: 'An account with that email already exists. ',
  logInHere: 'Log in here',
  somethingWentWrong: 'Something went wrong. Please try again.',
  captchaExpired: 'Captcha expired',
  recaptchaRequired: 'ReCaptcha is required to proceed.',
  freeTrialToday: 'Start your free trial today.',
  firstNameErrorMessage: 'Please enter your first name',
  lastNameErrorMessage: 'Please enter your last name',
  emailErrorMessage: 'Please enter your email',
  organizationNameErrorMessage: 'Please enter your organization',
  organizationTypeErrorMessage: 'Please select your organization type',
  passwordErrorMessage: 'Please enter your password',
  max20Characters: 'Maximum 20 characters',
  phoneNumberErrorMessage:
    'Please check your phone number. Invalid character detected',
  tickToSubscribe:
    'Tick to subscribe and get BugHerd updates (no spam, we promise)',
  bySigningUp: 'By signing up you confirm that you have read and agree to our ',
  termsOfService: 'terms of service ',
  and: 'and ',
  privacyPolicy: 'privacy policy',
  startYourFreeTrial: 'Start your free trial',
  alreadyHaveAnAccount: 'Already have an account? ',
  countryCodeError: 'Please select a country code',
  signUpWithGoogle: 'Continue with Google',
  signUpWithMicrosoft: 'Continue with Microsoft',
  thereWasAnError: 'There was an error, please try again.',
  or: 'or',
  signUpWithEmail: 'Sign up with email',
  joinExistingProject: 'Join an existing project? ',
  backToWebsite: 'Back to website'
};

export type Translations = typeof en;
