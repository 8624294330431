import { SCREENSHOT_UPLOAD_ERROR } from '../models/ScreenshotCaptureError';
import {
  updateTaskWithScreenshotError,
  updateTaskWithScreenshotKey
} from '../api/TaskUpdateApi';
import PropTypes from 'prop-types';
import uploadToS3 from './uploadToS3';
import { getBugsnag } from '../models/Application';

export const uploadScreenshot = ({
  uploadMetaData,
  dataURI,
  dataRecording,
  taskId,
  upload
}) => {
  const filename = () => {
    if (dataRecording && dataRecording.type) {
      if (dataRecording.type.includes('webm')) {
        return 'recording.webm';
      }
      return 'recording.mp4';
    }
    return 'screenshot.jpg';
  };

  const uploadAssetPathName = uploadMetaData.key + '/' + filename();

  return new Promise((resolve, reject) => {
    const { onStart, onProgress, onComplete } = upload;
    const onUploadStart = () => {
      if (onStart) onStart();
    };

    const onUploadError = event => {
      updateTaskWithScreenshotError(taskId, SCREENSHOT_UPLOAD_ERROR);
      const bugsnag = getBugsnag();
      if (bugsnag) {
        const errorMessage =
          'Error uploading or updating tasks screenshot with failure: ' + event;
        bugsnag.notify(errorMessage);
      }
      reject(event);
    };

    const onUploadProgress = event => {
      if (onProgress) {
        onProgress(event.loaded, event.total, event.lengthComputable);
      }
    };

    const onUploadComplete = async (updateTaskURL, uploadAssetPathName) => {
      try {
        await updateTaskWithScreenshotKey(updateTaskURL, uploadAssetPathName);
        if (onComplete) onComplete();
        resolve();
      } catch (error) {
        onUploadError(error);
      }
    };

    try {
      uploadToS3({
        uploadMetaData,
        uploadAssetPathName,
        dataRecording,
        dataURI,
        onUploadStart,
        onUploadError,
        onUploadComplete,
        onUploadProgress
      });
    } catch (error) {
      onUploadError(error);
    }
  });
};

uploadScreenshot.proptypes = {
  uploadMetaData: PropTypes.shape({
    success: PropTypes.string.isRequired,
    S3URL: PropTypes.string.isRequired,
    policy: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired
  }),
  dataURI: PropTypes.object,
  dataRecording: PropTypes.object,
  taskId: PropTypes.number.isRequired,
  upload: {
    onProgress: PropTypes.func,
    onStart: PropTypes.func
  }
};

uploadScreenshot.defaultProps = {
  upload: {
    onProgress: null,
    onStart: null
  }
};
