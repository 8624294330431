import { getCurrentUserEmail } from 'appJS/models/Organization';
import { ChecklistEnum } from './model';
import { trackPage, trackFacebook, trackGA } from 'utils/analytics';

const trackHandles = {
  [ChecklistEnum.SETUP_PROJECT]: 'Onboarding Step 1',
  [ChecklistEnum.INSTALL_BH]: 'Onboarding Step 2',
  [ChecklistEnum.WEBSITE]: 'Onboarding Step 3',
  [ChecklistEnum.PERSONALIZE]: 'Onboarding Step 4 (personalize)'
};

export const trackStep = (step: ChecklistEnum, email?: String) => {
  trackPage(trackHandles[step]);

  if (step === ChecklistEnum.WEBSITE) {
    trackFacebook('Widget Installed');
    trackGA({
      event: 'widget installed',
      email: email || getCurrentUserEmail()
    });
  }
};
