import React, { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { ChevronUp } from 'lucide-react';
import styles from './styles.module.css';

type Props = {
  setSelectedFolder: (selectedFolder: string) => void;
  setCurrentStep: (currentStep: number) => void;
  projectFolders: {
    id: string;
    name: string;
    subfolders: {
      id: string;
      name: string;
    }[];
  }[];
  selectedFolder: string;
  container: HTMLDivElement;
};

export const MoveToFolderDropdown: FC<Props> = ({
  setSelectedFolder,
  setCurrentStep,
  projectFolders,
  selectedFolder,
  container
}) => {
  // @ts-expect-error
  const menuItems = projectFolders.reduce((acc, { id, name, subfolders }) => {
    const folders = [{ key: id, label: name, className: styles.folder }];
    if (subfolders.length)
      folders.push(
        ...subfolders.map(({ id, name }) => ({
          key: id,
          label: `- ${name}`,
          className: styles.subfolder
        }))
      );
    return [...acc, ...folders];
  }, []);

  const selectedProjectFolderName = () => {
    const selectedProjectFolder = projectFolders.find(
      ({ id, subfolders }) =>
        id === selectedFolder ||
        subfolders.find(subfolder => subfolder.id === selectedFolder)
    );
    return (selectedProjectFolder?.id === selectedFolder
      ? selectedProjectFolder
      : selectedProjectFolder?.subfolders.find(
          subfolder => subfolder.id === selectedFolder
        )
    )?.name;
  };

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={styles.overlay}
      className={styles.moveToFolderDropdown}
      getPopupContainer={() => container}
      placement="top"
      menu={{
        // @ts-expect-error
        items: menuItems,
        selectable: true,
        onSelect: ({ key }) => {
          setSelectedFolder(key);
          setCurrentStep(2);
        }
      }}
    >
      <Button
        icon={<ChevronUp className={styles.chevronIcon} />}
        type="primary"
        className={styles.moveToFolderButton}
      >
        Move to {selectedFolder ? selectedProjectFolderName() : 'Folder'}
      </Button>
    </Dropdown>
  );
};
