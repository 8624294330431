import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { Search, ArrowDownWideNarrow } from 'lucide-react';
import { Input, Dropdown, Button } from 'antd';
import { useDesignAssetsState } from '../providers/DesignAssets';
import assetSortMenuItems from './AssetSortMenuItems';

const SearchAndFilter: FC = () => {
  const {
    container,
    sortBy,
    setSortBy,
    search,
    setSearch
  } = useDesignAssetsState();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <div className={styles.assetsWrapper}>
      <Input
        className={styles.assetsSearch}
        prefix={<Search className={styles.searchIcon} />}
        placeholder="Search"
        value={search}
        onChange={event => setSearch(event.target.value)}
        maxLength={200}
      />
      <Dropdown
        className={styles.assetsSortDropdown}
        trigger={['click']}
        getPopupContainer={() => container}
        onOpenChange={(visible: boolean) => setIsMenuOpen(visible)}
        open={isMenuOpen}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName={styles.filterOverlay}
        menu={{ items: assetSortMenuItems({ sortBy, setSortBy }) }}
      >
        <Button
          type="text"
          style={{ borderColor: 'transparent' }}
          icon={<ArrowDownWideNarrow className={styles.filterIcon} />}
          className={styles.button}
        />
      </Dropdown>
    </div>
  );
};

export default SearchAndFilter;
