import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import { toolbarActions } from './ToolbarActions';
import UAParser from 'ua-parser-js';

const parser = new UAParser(navigator.userAgent);

export type PreviewToolbarProps = {
  transform: {
    scale: number;
  };
  actions: {
    onFlipY: () => void;
    onFlipX: () => void;
    onRotateLeft: () => void;
    onRotateRight: () => void;
    onZoomIn: () => void;
    onZoomOut: () => void;
  };
  isImage: boolean;
  url: string;
};

export const PreviewToolbar: FC<PreviewToolbarProps> = ({
  transform: { scale },
  actions,
  isImage,
  url
}) => {
  const os = parser.getOS().name + ' ' + parser.getOS().version;
  const isMac: boolean = os.toLowerCase().includes('mac');
  const [isAltPressed, setIsAltPressed] = useState<boolean>(false);

  const handleAltPress = (e: KeyboardEvent) => {
    setIsAltPressed(e.altKey);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleAltPress);
    document.addEventListener('keyup', handleAltPress);
    return () => {
      document.removeEventListener('keydown', handleAltPress);
      document.removeEventListener('keyup', handleAltPress);
    };
  }, []);

  return (
    <div className={styles.toolbar}>
      {toolbarActions({ actions, scale, isImage })
        .filter(({ show }) => show)
        .map(({ title, icon: Icon, onClick, disabled }) => {
          const isDownload = typeof title !== 'string';
          return (
            <div
              className={cx(styles.toolbarIconContainer, {
                [styles.disabled]: disabled || (isDownload && !isAltPressed)
              })}
            >
              <div className={styles.toolbarIconTooltip}>
                {isDownload ? title(isMac) : title}
              </div>
              {typeof title !== 'string' ? (
                <a href={url} className={styles.downloadAnchor}>
                  <Icon
                    // @ts-expect-error
                    className={styles.toolbarIcon}
                  />
                </a>
              ) : (
                <Icon
                  // @ts-expect-error
                  className={styles.toolbarIcon}
                  onClick={onClick}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};
