import { useMutation } from '@apollo/client';

import { ProjectSite, GuestPermission } from 'models/Project';
import { message as succesMessage } from 'antd';
import useProjectSitesMutation from './useProjectSitesMutation';
import { UPDATE_PROJECT } from '../../../queries';
import { Project, ProjectFormField } from '../types';
import { updateExtension } from 'appJS/utils/updateExtension';

interface UseProjectMutation {
  (args: {
    project: Project;
    projectSites: ProjectSite[];
    message: { error: (error: string) => void };
    getFieldValue: (field: string) => string;
    isFieldTouched: (field: string) => boolean;
    refetch: () => void;
  }): [() => void, { updateProjectLoading: boolean }];
}

const useProjectMutation: UseProjectMutation = ({
  project,
  projectSites,
  message,
  getFieldValue,
  isFieldTouched,
  refetch
}) => {
  succesMessage.config({
    top: 100,
    duration: 5,
    maxCount: 1
  });

  const [updateProject, { loading: updateProjectLoading }] = useMutation(
    UPDATE_PROJECT,
    {
      onCompleted: () => {
        refetch();
        updateExtension();
        succesMessage.success(`Successfully updated!`);
      },
      onError: error => {
        if (error.message) {
          message.error(error.message);
        }
      }
    }
  );

  const submitProject = () => {
    const guestPermission = (() => {
      if (isFieldTouched(ProjectFormField.AllowGuestPermission)) {
        return getFieldValue(ProjectFormField.AllowGuestPermission)
          ? GuestPermission.SeeGuests
          : GuestPermission.SeeSelf;
      } else {
        return null;
      }
    })();

    const body = {
      name: isFieldTouched(ProjectFormField.Name)
        ? getFieldValue(ProjectFormField.Name)
        : undefined,
      allowEmailTasks: isFieldTouched(ProjectFormField.AllowEmailTasks)
        ? getFieldValue(ProjectFormField.AllowEmailTasks)
        : undefined,
      allowEmailComments: isFieldTouched(ProjectFormField.AllowEmailComments)
        ? getFieldValue(ProjectFormField.AllowEmailComments)
        : undefined,
      guestPermission: guestPermission ? guestPermission : undefined,
      ignoredQuerystrings: isFieldTouched(ProjectFormField.IgnoreList)
        ? getFieldValue(ProjectFormField.IgnoreList).split('\n')
        : undefined
    };

    updateProject({
      variables: { projectId: project.id, ...body }
    });
  };

  const [saveProjectSites] = useProjectSitesMutation({
    project,
    projectSites,
    message,
    onCompleted: submitProject
  });

  return [saveProjectSites, { updateProjectLoading }];
};

export default useProjectMutation;
