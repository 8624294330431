import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { userType, locationType } from '../../../utils/propTypes';
import Form from './form';
import CreateUserForm from './new_user_form';
import styles from '../welcome/style.module.css';
import sharedStyles from '../../../components/onboarding/shared.module.css';
import queryString from 'qs';
import { getLangKey } from 'appJS/models/Application';
import * as translations from './strings';

const strings = translations[getLangKey()];

export default class extends React.Component {
  static propTypes = {
    user: userType,
    endpoints: PropTypes.shape({
      saveUser: PropTypes.string.isRequired,
      acceptInvite: PropTypes.string
    }).isRequired,
    authenticityToken: PropTypes.string.isRequired,
    location: locationType,
    organizationMember: PropTypes.shape({
      role: PropTypes.string.isRequired
    }).isRequired,
    project: PropTypes.shape({
      devurl: PropTypes.string,
      name: PropTypes.string
    }),
    organization: PropTypes.shape({
      name: PropTypes.string
    }),
    container: PropTypes.object.isRequired,
    ssoEnabled: PropTypes.bool,
    newsletter: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    const { user, organizationMember, project } = this.props;

    if (user && user.name && user.name !== user.email) {
      if (organizationMember.role === 'guest') {
        if (project && project.devurl) {
          window.location = `${project.devurl}`;
          return;
        }
      }

      window.location = '/organizations';
    }
  }

  state = {
    showVideo: false
  };

  handleConfusedClick = event => {
    event.preventDefault();

    this.setState({
      showVideo: true
    });
  };

  renderWelcomeMessage = () => {
    if (this.props.project) {
      return strings.youveBeenInvitedProject(this.props.project.name);
    }
    return strings.youveBeenInvitedTeam(this.props.organization.name);
  };

  render() {
    const query = queryString.parse(this.props.location.search.substring(1));

    return (
      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <h1>{strings.welcomeToBugherd}</h1>
          <p className="intro">
            {this.renderWelcomeMessage()}
            <br />
            {strings.toGetStarted}
          </p>
        </div>
        <div className={styles.help}>
          {strings.waitWhats}
          <a
            href="https://bugherd.com"
            target="_blank"
            onClick={this.handleConfusedClick}
          >
            {strings.bugherd}
          </a>
          ?
        </div>
        {this.props.user ? (
          <Form
            {...this.props}
            user={this.props.user}
            endpoint={this.props.endpoints.saveUser}
            authenticityToken={this.props.authenticityToken}
            validationToken={query.validation_token}
          />
        ) : (
          <CreateUserForm
            {...this.props}
            endpoint={this.props.endpoints.acceptInvite}
            authenticityToken={this.props.authenticityToken}
          />
        )}
        <Modal
          title="What is BugHerd?"
          open={this.state.showVideo}
          onCancel={() => this.setState({ showVideo: false })}
          getContainer={() => this.props.container}
          footer={false}
          width={680}
        >
          <iframe
            src={`https://player.vimeo.com/video/${
              this.props.organizationMember == null ||
              this.props.organizationMember.role === 'guest'
                ? 329271950
                : 329272793
            }`}
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
            ref={this.vimeoRef}
            className={styles.video}
          />
        </Modal>
      </div>
    );
  }
}
