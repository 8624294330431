export const en = {
  toCapture: 'To capture screenshots, ',
  upgradePlan: 'upgrade your plan',
  contactOwner: 'contact the account owner',
  install: 'install the ',
  installTheBugherd: ' to install the BugHerd ',
  browserExtension: 'browser extension',
  or: ' or ',
  askUsers: 'ask your users ',
  onlyAvailable:
    'Capturing screenshots without a browser extension is only available on a Premium plan or higher.',
  toEnable: 'To enable screenshots, '
};

export type Type = typeof en;
