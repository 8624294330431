import React from 'react';

import NavItem from '../nav_item';
import SimpleBar from 'simplebar-react';
import cx from 'classnames';
import 'simplebar/dist/simplebar.min.css';

import PropTypes from 'prop-types';

import styles from './index.module.css';

export default class SubFilterList extends React.Component {
  static propTypes = {
    topFilter: PropTypes.string,
    subFilter: PropTypes.array,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.shape({
          myCount: PropTypes.number,
          allCount: PropTypes.number
        }),
        active: PropTypes.bool,
        backbone_filter: PropTypes.array
      })
    ),
    onClick: PropTypes.func,
    checkActiveSubFilter: PropTypes.func.isRequired,
    isOpen: PropTypes.bool
  };

  isActiveSubFilter = filter => {
    const { subFilter, checkActiveSubFilter } = this.props;
    return checkActiveSubFilter(filter, subFilter);
  };

  render() {
    const { filters, topFilter, onClick } = this.props;

    return (
      <div
        className={cx({
          [styles.subFilterListBox]: true,
          [styles.active]: this.props.isOpen
        })}
      >
        <div className={styles.subFilterList}>
          <SimpleBar
            forceVisible="y"
            className={styles.scrollbarContainer}
            autoHide={false}
          >
            {filters.map((filter, index) => {
              return (
                <NavItem
                  type="sub"
                  key={index + filter.name}
                  name={filter.name}
                  onClick={() => onClick(filter.backbone_filter)}
                  isSelected={this.isActiveSubFilter(filter)}
                  count={filter.count[topFilter + 'Count']}
                />
              );
            })}
          </SimpleBar>
        </div>
      </div>
    );
  }
}
