import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import { TagsProps } from '../types';
import * as translations from '../users/strings';
import { getLangKey } from '../../../../models/Language';
import { Tag } from 'lucide-react';
import { Collapse, Button, Badge, Tooltip } from 'antd';
import { getCollapsedState, setCollapsedState } from '../../utils';
import camelCaseKeys from 'camelcase-keys';

const strings = translations[getLangKey()];

const Tags: FC<TagsProps> = ({ tags, setView, title }) => {
  const [collapsed, setCollapsed] = useState<boolean>(
    getCollapsedState()[title] ?? true
  );
  const activeKey = collapsed ? '' : '1';

  useEffect(() => {
    setCollapsedState(title, collapsed);
  }, [collapsed, title]);

  const handleClickTag = tag => {
    // @ts-expect-error
    window.bugherd.application.get('addFacet')('tag', tag.name);
  };

  const handleClickTags = event => {
    event.preventDefault();
    event.stopPropagation();
    setView('tags');
  };

  const tagsLabel = (
    <div className={styles.tagsHeader}>
      <span>Tags</span>
      <Button
        icon={<Tag className={styles.navIcon} />}
        type="text"
        onClick={e => handleClickTags(e)}
        className={styles.tagsButton}
        size="small"
        shape="round"
      />
    </div>
  );

  const tagsList = (
    <div className={styles.tagsContainer}>
      {tags.length > 0 ? (
        camelCaseKeys(tags)
          .filter(tag => tag.active)
          .map(tag => {
            const tagCount = `${tag.inactiveCount}/${tag.taskCount}`;
            const allTasksComplete = tag.inactiveCount === tag.taskCount;
            return (
              <Button
                type="text"
                onClick={() => handleClickTag(tag)}
                className={styles.tagContainer}
              >
                <div className={styles.tag}>
                  <span className={styles.tagName} title={tag.name}>
                    # {tag.name}
                  </span>
                  <Tooltip title={strings.taskCount} trigger={['hover']}>
                    <Badge
                      count={tagCount}
                      showZero
                      color={allTasksComplete ? '#D9E7F4' : '#A8C1D9'}
                      className={styles.tagCount}
                    />
                  </Tooltip>
                </div>
              </Button>
            );
          })
      ) : (
        <span className={styles.emptyTagsMessage}>No active tags</span>
      )}
    </div>
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: tagsLabel,
      children: tagsList,
      className: styles.collapseItem
    }
  ];

  return (
    <Collapse
      defaultActiveKey={[activeKey]}
      ghost
      items={items}
      size="small"
      className={styles.collapseContainer}
      onChange={() => setCollapsed(!collapsed)}
    />
  );
};

export default Tags;
