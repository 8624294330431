import { Project } from 'models/Project';
import { OrganizationUser } from 'models/User';
import { Organization } from 'models/Organization';
import { isMobile } from 'react-device-detect';

export type UserTasksProps = {
  organization: Organization;
  onPusherUpdate: () => void;
  refresh: boolean;
};

export const USER_TASKS_PAGE_SIZE = isMobile ? 5 : 25;
export const USER_COMMENTS_PAGE_SIZE = 4;

export interface UserTask {
  id: string;
  description: string;
  localTaskId: string;
  statusName: string;
  dueAt: string;
  createdAt: string;
  project: Project;
  assignedUsers: OrganizationUser[];
  priorityId: string;
  requester: Requester;
}

type Requester = {
  name?: string;
  email?: string;
};

export interface UserComment {
  id: string;
  user: OrganizationUser | null;
  text: string;
  createdAt: string;
  updatedAt: string;
  localTaskId: string;
  mentionIds: number;
  isPrivate: boolean;
  project: Project;
}

interface ClassNames {
  column: ClassNameType;
  title: ClassNameType;
}

type ClassNameType = {
  project: string;
  task: string;
  status: string;
  dueDate: string;
  reported: string;
  reportedBy: string;
  updatedAt: string;
  assignedTo: string;
};

export const classNames: ClassNames = {
  column: {
    project: 'projectNameColumn',
    task: 'descriptionColumn',
    status: 'statusNameColumn',
    dueDate: 'dueAtColumn',
    reported: 'createdAtColumn',
    reportedBy: 'reportedByColumn',
    updatedAt: 'updatedAtColumn',
    assignedTo: 'assignedUsersColumn'
  },
  title: {
    project: 'projectNameTitle',
    task: 'descriptionTitle',
    status: 'statusNameTitle',
    dueDate: 'dueAtTitle',
    reported: 'createdAtTitle',
    reportedBy: 'reportedByTitle',
    updatedAt: 'updatedAtTitle',
    assignedTo: 'assignedUsersTitle'
  }
};

export enum OrderBy {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface UserTaskFilter {
  projectId?: string[];
  requesterId?: string[];
  description?: string;
  priorityId?: string[];
  status?: string[];
  dueAtStart?: string;
  dueAtEnd?: string;
  createdAtStart?: string;
  createdAtEnd?: string;
  updatedAtStart?: string;
  updatedAtEnd?: string;
}

export type UserTaskOrder = Record<string, OrderBy>;