import React, { FC, ReactNode } from 'react';
import AddComment from './AddComment';
import CommentStream from './CommentStream';
import { Comment } from '../../../../../models/Comment';
import { User } from '../../../../../models/User';
import styles from './styles.module.css';
import { CommentsProvider } from '../../../../../../providers/Comments';
import cx from 'classnames';
import useCommentPreference from '../../../../hooks/useCommentPreference';
import { UpdateComment } from '../../../../../../shared/updateComment';
import { Assignee } from '..';

type Props = {
  container: ReactNode;
  commentsLoading: boolean;
  comments: Comment[];
  currentUserId: number;
  users: User[];
  usersNotOnProject: User[];
  taskId: number;
  canEditComment: boolean;
  canViewPrivateComments: boolean;
  canCreatePrivateComments: boolean;
  canViewCommitComment: boolean;
  billingRights: boolean;
  owner: User;
  bugherdUrl: string;
  organizationId: number;
  guestVisible: boolean;
  updateTaskById?: (taskId: number, attributes: any) => void;
  isAdminView?: boolean;
  isSmallView?: boolean;
  isNewSidebar?: boolean;
  createComment: (commentBody: any) => void;
  deleteComment: (commentId: number) => void;
  updateComment: UpdateComment;
  onPlanUpgradeClick?: () => void;
  projectId: number;
  apiDomain: string;
  assignGuestsAllowed: boolean;
  assignGuests: boolean;
  assignees: Assignee[];
};

const Comments: FC<Props> = ({
  container,
  commentsLoading,
  comments,
  currentUserId,
  users,
  usersNotOnProject,
  taskId,
  canEditComment,
  canViewPrivateComments,
  canCreatePrivateComments,
  canViewCommitComment,
  billingRights,
  owner,
  bugherdUrl,
  organizationId,
  guestVisible,
  isAdminView,
  isSmallView,
  isNewSidebar,
  createComment,
  updateComment,
  deleteComment,
  onPlanUpgradeClick,
  projectId,
  apiDomain,
  assignGuestsAllowed,
  assignGuests,
  assignees
}) => {
  const {
    handlePrivateToggle,
    isPrivate,
    showUpgrade,
    setShowUpgrade
  } = useCommentPreference({
    isNewSidebar,
    projectId,
    canCreatePrivateComments,
    organizationId,
    userId: currentUserId
  });

  return (
    <CommentsProvider
      currentUserId={currentUserId}
      users={users}
      usersNotOnProject={usersNotOnProject}
      getContainer={() => container}
      isAdminView={isAdminView}
      createComment={createComment}
      deleteComment={deleteComment}
      updateComment={updateComment}
      canViewPrivateComments={canViewPrivateComments}
      canCreatePrivateComments={canCreatePrivateComments}
      canViewCommitComment={canViewCommitComment}
      billingRights={billingRights}
      owner={owner}
      bugherdUrl={bugherdUrl}
      organizationId={organizationId}
      guestVisible={guestVisible}
      taskId={taskId}
      isSmallView={isSmallView}
      isNewSidebar={isNewSidebar}
      commentsLoading={commentsLoading}
      canEditComment={canEditComment}
      comments={comments}
      onPlanUpgradeClick={onPlanUpgradeClick}
      projectId={projectId}
      apiDomain={apiDomain}
      assignGuestsAllowed={assignGuestsAllowed}
      assignGuests={assignGuests}
      assignees={assignees}
    >
      <div
        className={cx(styles.container, 'commentsOuter', {
          [styles.adminContainer]: isAdminView
        })}
      >
        <AddComment
          handlePrivateToggle={handlePrivateToggle}
          isPrivate={isPrivate}
          showUpgrade={showUpgrade}
          setShowUpgrade={setShowUpgrade}
        />
        <CommentStream />
      </div>
    </CommentsProvider>
  );
};

export default Comments;
