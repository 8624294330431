import * as React from 'react';
import { format } from 'date-fns';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
import styles from './index.module.css';

const strings = translations[getLangKey()];

const formatDate = (date: string) => {
  const statementDate: Date = new Date(date);
  return format(statementDate, strings.dateFormat);
};

export const statementId = (id: string) => (
  <span>{id}</span>
);

export const statementDate = (issued_at: string) => (
  <span>{issued_at ? formatDate(issued_at) : 'N/A'}</span>
);

export const statementStatus = (settled_at: string) => {
  const status = !settled_at ? 'Un-paid' : 'Paid';
  return(
    <span className={status == 'Un-paid' ? styles.unpaid : styles.paid }>{status}</span>
  );
};

export const statementTotal = (total_in_cents: number) => (
  <span>{"US$" + (total_in_cents/100).toFixed(2)}</span>
);

export const statementDownloadLink = (id: string, organizationId: string, statement: Object) => (
  <a href={`/organizations/${organizationId}/statement/${id}?number=${statement["number"]}`}>
    Download
  </a>
);
