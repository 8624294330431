import React, { useState } from 'react';
import { Form, Button, Input, message as antmessage } from 'antd';
import { Link } from 'react-router-dom';
import { put } from 'utils/fetch';

import styles from './index.module.css';

export default ({ authenticityToken }: { authenticityToken: string }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    setLoading(true);

    const { password, confirm_password } = values;
    const endpoint = window.location.pathname.replace(/\/edit/g, '');

    put(endpoint, {
      authenticity_token: authenticityToken,
      user: {
        password,
        confirm_password
      }
    })
      .then(body => {
        if (body.error && body.error.message) {
          antmessage.error('Something went wrong, please try again.', 5);

          throw Error(body.error.message);
        } else {
          antmessage.success('Password sucessfully changed.', 5);

          window.location.href = '/users/sign_in';
        }
      })
      .catch(error => {
        setLoading(false);
        if (error === 'HTTP error! status: 500') {
          antmessage.error('Something went wrong, please try again.', 5);
        } else if (error === 'HTTP error! status: 403') {
          antmessage.error(
            <div>
              This password reset link has expired. Click{' '}
              <Link to="/users/passwords/new">here</Link> to reset your password
            </div>,
            5
          );
        } else {
          antmessage.error(error, 5);
        }
      });
  };

  return (
    <div>
      <h1 className={styles.title}>Reset password</h1>

      <Form
        className={styles.form}
        name="resetPassword"
        onFinish={onFinish}
        layout="vertical"
        requiredMark={false}
      >
        <div className={styles.resetContainer}>
          <Form.Item
            label="New password"
            name="password"
            rules={[{ required: true, message: 'Password is required' }]}
          >
            <div>
              <Input
                size="large"
                type="password"
                name="user[password]"
                autoFocus
                className={styles.input}
              />
            </div>
          </Form.Item>
          <Form.Item
            label="Confirm new password"
            name="confirm_password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                }
              })
            ]}
          >
            <div>
              <Input
                size="large"
                type="password"
                name="user[password_confirmation]"
                className={styles.input}
              />
            </div>
          </Form.Item>
        </div>

        <div className={styles.center}>
          <Form.Item noStyle>
            <div>
              <Button
                className={styles.submit}
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Reset password
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
