import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import BulkSeverity, { Priority } from './bulk_severity';
import BulkStatus, { Status } from './bulk_status';
import BulkDueDate from './bulk_due_date';
import { Button } from 'antd';
import { X as Close } from 'lucide-react';
import cx from 'classnames';
import { useProjectState } from '../../../../../clients/providers/Project';
import BulkMultiple, { Assignee, TagType } from './bulk_mutliple';
import {
  BulkRequestActions,
  NewStateUpdateBulkRequestArgs,
  UpdateState
} from 'appJS/models/BulkRequest';
import { processStateUpdateBulkRequest } from '../createBulkRequest';
import { MAX_NUMBER_OF_TASKS } from './../Cards';
import StatusBar from './status_bar';
import camelcaseKeys from 'camelcase-keys';
import { getSortedAssignableUsers } from 'appJS/utils/getAssignableUsers';

export type BulkRequest = {
  id: number;
  completedAt: string | null;
  createdAt: string;
  details: {
    objects?: number[];
    success?: number[];
    targetState: UpdateState;
  };
  objectAction: string;
  relatedBulkRequestId: number;
};

type Props = {
  selectedTasks: string[];
  getContainer: () => HTMLDivElement;
  onClose: () => void;
  bulkActions: BulkRequest[];
  permissions: {
    [key: string]: boolean;
  };
};

export type LastUpdate = {
  targetState: UpdateState;
  taskIds: number[];
  id?: number;
  actionsPerformed: BulkRequestActions[];
};

const BulkActionsBar: FC<Props> = ({
  selectedTasks,
  getContainer,
  onClose,
  bulkActions,
  permissions: {
    editTaskStatus,
    editTaskAssignees,
    editTaskDueDate,
    editTaskTags,
    editTaskSeverity,
    billingRights
  }
}) => {
  const { project, loading } = useProjectState();
  const [status, setStatus] = useState<Status | undefined>();
  const [severity, setSeverity] = useState<Priority | undefined>();
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const [tags, setTags] = useState<TagType[]>([]);
  const [dueAt, setDueAt] = useState<string>('');
  const [lastBulkUpdate, setLastBulkUpdate] = useState<LastUpdate | null>(null);

  bulkActions = bulkActions?.map(action =>
    camelcaseKeys(action, { deep: true })
  );

  const handleClear = () => {
    setStatus(undefined);
    setSeverity(undefined);
    setAssignees([]);
    setTags([]);
    setDueAt('');
  };

  useEffect(() => {
    if (!selectedTasks.length) {
      handleClear();
    }
  }, [selectedTasks]);

  const showClearAndUpdate: boolean = !!(
    status ||
    severity ||
    assignees.length ||
    tags.length ||
    dueAt
  );

  const handleUpdate = () => {
    const targetState: UpdateState = {};
    const actionsPerformed: BulkRequestActions[] = [];

    const taskIds: number[] = selectedTasks.map(taskId => Number(taskId));

    if (status) {
      targetState.statusId = status.statusId;
      targetState.columnId = status.columnId;
      actionsPerformed.push('status_changed');
    }

    if (severity) {
      targetState.priorityId = severity.id;
      actionsPerformed.push('severity_updated');
    }

    if (tags.length) {
      targetState.tagIds = tags.map(({ id }) => id);
      actionsPerformed.push('tagged');
    }
    if (assignees.length) {
      targetState.assigneeIds = assignees.map(({ id }) => id);
      actionsPerformed.push('assigned');
    }

    if (dueAt) {
      targetState.dueAt = dueAt;
      actionsPerformed.push('due_date_updated');
    }

    setLastBulkUpdate({
      targetState,
      taskIds,
      actionsPerformed
    });
  };

  useEffect(() => {
    if (lastBulkUpdate && !lastBulkUpdate.id) {
      processStateUpdateBulkRequest(
        lastBulkUpdate as NewStateUpdateBulkRequestArgs
      ).then(({ id }) => {
        setLastBulkUpdate({ ...lastBulkUpdate, id });
        handleClear();
      });
    }
  }, [lastBulkUpdate]);

  const handleClose = () => {
    handleClear();
    setLastBulkUpdate(null);
    onClose();
  };

  const showToolbar: boolean = !loading && project && selectedTasks.length > 1;

  if (!showToolbar) return null;

  const limitReached: boolean = selectedTasks.length >= MAX_NUMBER_OF_TASKS;

  const getStatuses = () =>
    project.columns
      .filter(({ id }) => id !== 6)
      .map(({ columnId, name, statusId }) => ({
        columnId,
        name,
        statusId
      })) as Status[];

  const assignableUsers = getSortedAssignableUsers(
    project.users.filter(
      ({ member, accountConfirmed, role }) =>
        accountConfirmed && (member || role === 'guest')
    ) || [],
    project.assignGuests && project.organization.assignGuestsAllowed
  );

  const getActiveTags = () => project.tags.filter(({ active }) => active);

  const hasSameState = (targetState: UpdateState): boolean => {
    if (!lastBulkUpdate) return false;
    const targetKeys = Object.keys(targetState);
    const lastUpdateKeys = Object.keys(lastBulkUpdate.targetState);
    const matchingKeys =
      JSON.stringify(targetKeys.sort()) ===
      JSON.stringify(lastUpdateKeys.sort());
    if (!matchingKeys) return false;
    for (let key of targetKeys) {
      if (targetState[key] !== lastBulkUpdate.targetState[key]) return false;
    }
    return true;
  };

  const lastUpdateAction = bulkActions?.find(
    ({ id, details: { targetState, objects, success } }) => {
      return (
        id === lastBulkUpdate?.id ||
        (JSON.stringify((objects || success)?.sort()) ===
          JSON.stringify(lastBulkUpdate?.taskIds.sort()) &&
          hasSameState(targetState))
      );
    }
  );

  const updating: boolean = !!(
    lastUpdateAction && !lastUpdateAction.completedAt
  );

  return (
    <div className={styles.container}>
      <StatusBar
        bulkActions={bulkActions}
        lastUpdateAction={lastUpdateAction}
        updating={updating}
        onUndoComplete={handleClear}
        setLastBulkUpdate={setLastBulkUpdate}
      />
      <div className={styles.innerContainer}>
        <div className={styles.tasksSelected}>
          <span className={styles.count}>
            {selectedTasks.length} tasks selected
          </span>
          {limitReached && (
            <span className={styles.limitReached}>&nbsp; (limit reached)</span>
          )}
        </div>
        <div className={styles.closeContainer} onClick={handleClose}>
          <Close className={styles.closeIcon} />
        </div>
        <div className={styles.bulkButtons}>
          {editTaskStatus && (
            <BulkStatus
              onChange={setStatus}
              statuses={getStatuses()}
              status={status}
              getContainer={getContainer}
            />
          )}
          {editTaskAssignees && (
            <BulkMultiple
              values={assignees}
              options={assignableUsers}
              onChange={setAssignees}
              getContainer={getContainer}
              guestsAssignedToTasksAllowed={
                project?.organization.assignGuestsAllowed
              }
              billingRights={billingRights}
              isAssignees
            />
          )}
          {editTaskTags && (
            <BulkMultiple
              values={tags}
              options={getActiveTags() as TagType[]}
              onChange={setTags}
              getContainer={getContainer}
            />
          )}
          {editTaskSeverity && (
            <BulkSeverity
              onChange={setSeverity}
              priorities={project.priorities}
              severity={severity}
              getContainer={getContainer}
            />
          )}
          {editTaskDueDate && (
            <BulkDueDate
              onChange={setDueAt}
              dueAt={dueAt}
              getContainer={getContainer}
            />
          )}
        </div>
        <div
          className={cx(styles.clearAndUpdate, {
            [styles.hideClearAndUpdate]: !showClearAndUpdate,
            [styles.updating]: updating
          })}
        >
          <Button
            type="text"
            onClick={handleClear}
            className={cx(styles.clearButton, {
              [styles.hideClear]: !showClearAndUpdate
            })}
          >
            Clear
          </Button>
          <Button
            type="primary"
            className={cx(styles.updateButton, {
              [styles.hideUpdate]: !showClearAndUpdate
            })}
            onClick={handleUpdate}
            disabled={updating}
            loading={updating}
          >
            Update tasks
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BulkActionsBar;
