import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, Radio, Button } from 'antd';
import { format } from 'date-fns';
import * as translations from './strings';
import { HelpModuleButton } from 'utils/HelpArticleButton';
import { default as PlanView } from './plan';
import { CurrentPlan } from './CurrentPlan';
import styles from './index.module.css';
import Error from 'components/Error';
import Loader from 'components/loader';
import { useSubscriptionQuery, Plan } from './query';
import AdditionalMembers from './additionalMembers';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ContainerContext from '../containerContext';
import CustomPlan from './plan/CustomPlan';
import { PlanInterval } from './types';
import { sortBy } from 'lodash-es';
import { PremiumIntegrationsPromo } from './addonPremiumIntegrations';

const strings: translations.Translations = translations.en;

const SubscriptionState: React.FC<{
  subscription: {
    paymentExtension: string;
    renewalDate: string;
    state: string;
    price: number;
    addonPremiumIntegrations: boolean;
  };
  organization: {
    id: string;
    experiments: string[];
    validPartnerCoupon: boolean;
    activePartnerCouponDescription: string;
  };
}> = ({
  organization,
  subscription: { state, paymentExtension, renewalDate, price }
}) => {
  const updatePaymentDetailsUrl = `/organizations/${organization.id}/update_payment_method`;
  const message = (() => {
    switch (state) {
      case 'payment_extension':
        return strings.paymentExtension(
          format(new Date(paymentExtension), 'LLLL do yyyy')
        );
      case 'canceled':
        return strings.canceled;
      case 'cancelling':
        return strings.cancelling(
          format(new Date(renewalDate), 'LLLL do yyyy')
        );
      case 'overdue':
        return (
          <span>
            {strings.overdue}
            {', '}
            <a href={updatePaymentDetailsUrl}>{strings.updatePaymentDetails}</a>
            {' or '}
            <a href="mailto:support@bugherd.com">{strings.contactUs}</a>
          </span>
        );
      case 'expired':
        return (
          <span>
            {strings.expired}
            {', '}
            <a href={updatePaymentDetailsUrl}>{strings.updatePaymentDetails}</a>
            {' or '}
            <a href="mailto:support@bugherd.com">{strings.contactUs}</a>
          </span>
        );
      case 'paused':
        return strings.currentPlanIsPaused(price);
      default:
        return null;
    }
  })();
  if (message != null) {
    return <Alert message={message} type="warning" />;
  } else {
    return null;
  }
};

function useSearchParamQuery() {
  let location = useLocation();
  return queryString.parse(location.search);
}

const Billing = (props: { organizationId: string }) => {
  let query = useSearchParamQuery();
  const container = React.useContext(ContainerContext) as HTMLElement;

  const [timeFrame, setTimeframe] = useState<PlanInterval>('Monthly');
  const [showAddMember, setShowAddMember] = useState<boolean>(
    !!query?.showAdditionalMember
  );

  const { data, loading, error, refetch } = useSubscriptionQuery(
    props.organizationId
  );

  useEffect(() => {
    const subscription = data?.currentUser.organization.subscription;
    if (subscription?.__typename === 'SubscriptionPlan') {
      setTimeframe(subscription.interval);
    }
  }, [data]);

  if (loading && !data) return <Loader />;
  if (error || !data) {
    return <Error error={error} />;
  }

  const {
    organization,
    organization: {
      pausePlan,
      subscription,
      validPartnerCoupon,
      activePartnerCouponDescription
    }
  } = data.currentUser;

  let availablePlans = organization.availablePlans;

  return (
    <Loader spinning={loading}>
      <div>
        {validPartnerCoupon && subscription.state != 'trialing' && (
          <Alert
            message={strings.currentPlan(activePartnerCouponDescription)}
            type="info"
            className={styles.activeCoupon}
          />
        )}
        {subscription.__typename === 'SubscriptionPlan' ? (
          <SubscriptionState
            subscription={{
              state: subscription.state,
              renewalDate: subscription.renewalDate,
              paymentExtension: subscription.paymentExtension,
              price: subscription.price,
              addonPremiumIntegrations: subscription.addonPremiumIntegrations
            }}
            organization={organization}
          />
        ) : subscription.state === 'trialing' ? (
          <Alert
            message={strings.trailing(
              format(new Date(subscription.trialExpiryAt), 'LLLL do yyyy')
            )}
            type="warning"
          />
        ) : (
          <Alert message={strings.trialExpired} type="warning" />
        )}

        <div className={styles.contentBlock}>
          {subscription.__typename === 'SubscriptionPlan' && (
            <div className={styles.hero}>
              {subscription.plan && (
                <CurrentPlan
                  showAdditionalMembers={() => setShowAddMember(true)}
                  subscription={subscription}
                  organization={organization}
                  pausePlan={pausePlan}
                  plan={subscription.plan}
                />
              )}
            </div>
          )}
          <p className={styles.planCallout}>{strings.planCallout}</p>
          <div className={styles.radioContainer}>
            <Radio.Group
              onChange={evt => setTimeframe(evt.target.value)}
              value={timeFrame}
            >
              <Radio.Button value="Monthly">Pay monthly</Radio.Button>
              <Radio.Button value="Yearly">Pay annually</Radio.Button>
            </Radio.Group>
          </div>
          <p className={styles.havingTrouble}>
            <br />
            {strings.havingTrouble}{' '}
            <HelpModuleButton moduleId={'3475'}>
              {strings.getInTouch}
            </HelpModuleButton>
          </p>
          {subscription.__typename === 'SubscriptionPlan' &&
            (subscription.plan.name.includes('Standard') ||
              subscription.plan.name.includes('Studio')) &&
            !subscription.addonPremiumIntegrations && (
              <PremiumIntegrationsPromo organizationId={organization.id} />
            )}
          {subscription.__typename === 'SubscriptionPlan' && (
            <div className={styles.moreUsers}>
              <div className={styles.innerLeft}>
                <AdditionalMembers
                  organizationId={organization.id}
                  additionalMembers={subscription.additionalMembers}
                  currentPlan={subscription.plan}
                  container={container}
                  showModal={showAddMember}
                  onClose={() => {
                    setShowAddMember(false);
                  }}
                  onSuccess={() => {
                    setShowAddMember(false);
                    refetch();
                  }}
                  numMembers={organization.numMembers}
                />
                <h3>{strings.additionalUsersHeader}</h3>
                <p>
                  {strings.additionalUsersBody}{' '}
                  <Button
                    className={styles.addMembersButton}
                    type="link"
                    onClick={() => {
                      setShowAddMember(true);
                    }}
                  >
                    {' '}
                    <span className={styles.addMembers}>
                      {strings.addMembers}
                    </span>
                  </Button>
                </p>
              </div>
              <div className={styles.innerRight} />
            </div>
          )}
          <div className={styles.plans}>
            {sortBy(availablePlans, 'price')
              .filter((plan: Plan) => timeFrame === plan.interval)
              .map((plan: Plan) => (
                <PlanView
                  key={plan.handle}
                  plan={plan}
                  organization={organization}
                  subscription={organization.subscription}
                />
              ))}
            <CustomPlan key={'customplan'} />
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default Billing;
