import { get } from './fetch';
const urlify = require('urlify');
const queryString = require('qs');

type AttachmentType = 'screenshot' | 'recording';

type S3Response = {
  S3URL: string;
  key: string;
  policy: string;
  signature: string;
  success?: string;
};

const _urlify = urlify.create({
  addEToUmlauts: true,
  szToSs: true,
  spaces: '_',
  nonPrintable: '_',
  trim: true
});

export default uploadParameters => {
  const upload = async () => {
    const {
      file,
      remove,
      endpoint,
      additionalQueryParams,
      feedback,
      onStart,
      onProgress,
      onError,
      onComplete,
      isContentType = true
    } = uploadParameters;
    const { name } = file;

    const getUrl = () => {
      const queryParams = '?' + queryString.stringify(additionalQueryParams);
      return endpoint + queryParams;
    };

    const sendToS3 = s3Arguments => {
      const { formData, S3URL, file, key, callbackUrl, is_figma } = s3Arguments;
      const request = new XMLHttpRequest();

      request.open('POST', S3URL, true);
      request.upload.addEventListener('loadstart', () => onStart(request));
      request.upload.addEventListener('progress', event =>
        onProgress(event, file)
      );

      request.addEventListener('abort', event => {
        onError(event, file);
      });

      request.upload.addEventListener('error', event => onError(event, file));
      request.addEventListener('load', event => {
        const { readyState, status } = event.currentTarget;
        if (readyState === 4) {
          if (status !== 204) {
            onError(event, file);
          } else {
            onComplete(file, S3URL, key, callbackUrl, is_figma);
          }
        }
      });

      request.send(formData);
    };

    const getFormData = data => {
      let requestData = {
        AWSAccessKeyId: 'AKIAIK2PYU6R3QKHBZDA',
        acl: 'public-read'
      };
      // ContentType check to handle for avatar S3 upload.
      if (isContentType) {
        requestData = {
          ...requestData,
          'Content-Type': data.file.type || 'application/octet-stream'
        };
      }
      const _data = Object.assign(requestData, data);
      const formData = new FormData();
      Object.keys(_data).forEach(key => {
        formData.append(key, _data[key]);
      });
      return formData;
    };

    let getSlot;
    let slotResponse;

    try {
      getSlot = await fetch(getUrl(), {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json'
        }
      });
      if (!getSlot.ok) {
        throw Error(getSlot.statusText);
      }
      slotResponse = await getSlot.json();
    } catch (err) {
      feedback('Something went wrong, please try again.');
      remove(file);
    }

    const { key, policy, signature, S3URL, success, is_figma } = slotResponse;
    const parts = name.split('.');
    const ext = parts.pop();
    const _key = key + '/' + _urlify(parts.join('.')) + '.' + ext;
    const data = {
      key: _key,
      policy,
      signature,
      file: file.originFileObj
    };

    const s3Arguments = {
      formData: getFormData(data),
      S3URL,
      file,
      key: _key,
      callbackUrl: success,
      is_figma
    };

    await sendToS3(s3Arguments);
  };
  return upload();
};

export const requestNewAttachmentSlot = (
  filename: string,
  type: AttachmentType,
  apiDomain: string,
  projectId: number
): Promise<S3Response> =>
  get(
    `${apiDomain}/projects/${projectId}/new_task_attachment?filename=${filename}&${type}=true`
  );
