import React, {
  FC,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  UIEvent,
  KeyboardEventHandler
} from 'react';
import { getLangKey } from '../../../../models/Language';
import * as translations from './strings';
import AlertPopover from './AlertPopover';
import SitePopover from './SitePopover';
import PathPopover from './PathPopover';
import { Sites } from '../../../../../shared/utils/taskLocation/types';
import { Button, Input } from 'antd';
import cx from 'classnames';
import styles from './styles.module.css';
import { XCircle, MapPin, Edit } from 'lucide-react';
import DetailsLabel from '../DetailsLabel';
import createProjectSite, {
  isValidSite,
  matchesProjectUrls
} from './createProjectSite';

type Props = {
  gotoTask?: (event: UIEvent) => void;
  taskPath: string;
  absoluteUrl: string;
  container: HTMLDivElement;
  canEditTaskUrl: boolean;
  updateSite: (site: string) => void;
  updateUrl: (url: string) => void;
  taskId: number;
  taskHost: string;
  canManageProject: boolean;
  isAdminView?: boolean;
  sites: Sites;
  devurl: string;
  projectDomains: string | string[];
  pageUrl: string;
  projectId: number;
  bugherdUrl: string;
  projectSiteId?: number;
};

const strings = translations[getLangKey()];

type Show = 'path' | 'site';

const TaskLocation: FC<Props> = ({
  gotoTask,
  taskPath,
  absoluteUrl,
  container,
  canEditTaskUrl,
  updateSite,
  updateUrl,
  taskHost,
  canManageProject,
  isAdminView,
  sites,
  devurl,
  projectDomains,
  taskId,
  pageUrl,
  projectId,
  bugherdUrl,
  projectSiteId
}) => {
  useEffect(() => {
    setPath(taskPath);
    setSite(taskHost);
  }, [taskId]);

  const [show, setShow] = useState<Show | null>(null);
  const [path, setPath] = useState<string>(taskPath);
  const [site, setSite] = useState<string>(taskHost);
  const inputRef: MutableRefObject<Input | null> = useRef(null);

  const _sites = (): { variants: Sites }[] =>
    createProjectSite(devurl, projectDomains);
  const pageUrlSites = (): { variants: Sites }[] => createProjectSite(pageUrl);

  const isValidPath = (): boolean =>
    !!path && path[0] === '/' && !path.includes(' ');

  const saveChanges = () => {
    if (show) {
      if (show === 'path') {
        if (isValidPath()) {
          updateUrl(path);
          setShow(null);
        }
      } else if (isValidSite(site)) {
        updateSite(site);
        setShow(null);
      }
    }
  };

  const handleOnChange = (event: any) =>
    show === 'path' ? setPath(event.target.value) : setSite(event.target.value);

  const clear = () => {
    if (show) {
      if (show === 'path') {
        setPath('/');
      } else {
        const taskUrl: URL = new URL(taskHost);
        setSite(taskUrl.protocol + '//');
      }
      inputRef?.current?.focus();
    }
  };

  const hide = () => setShow(null);

  const onCancel = () => {
    if (show === 'path' && path !== taskPath) {
      setPath(taskPath);
    } else if (show === 'site' && site !== taskHost) {
      setSite(taskHost);
    }
    hide();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    const escapeKey: number = 27;
    if (event.keyCode === escapeKey) {
      hide();
    }
  };

  const hasNoSites: boolean = !devurl && !sites?.length;

  const _gotoTask = (event: UIEvent) => {
    event.preventDefault();
    // @ts-ignore
    const pin = window.BUGHERD_REACT_APP_SIDEBAR_MOUNT?.getRootNode().querySelector(
      `#pin_${taskId}`
    );
    if (!matchesProjectUrls(pageUrlSites(), taskHost, taskPath)) {
      window.location.href = `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/view`;
    } else if (pin) {
      pin.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
        inline: 'center'
      });
    } else {
      window.location.href = `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/view`;
    }
  };

  const handleLocationClick = (event: UIEvent) => {
    if (gotoTask) {
      gotoTask(event);
    } else {
      _gotoTask(event);
    }
  };

  return (
    <div
      className={cx(styles.formItem, styles.locationItem, {
        [styles.flexedContainer]: show !== 'site'
      })}
    >
      <DetailsLabel label={strings.location} />
      {!show ? (
        <>
          {!hasNoSites &&
            !matchesProjectUrls(
              sites && sites.length ? sites : _sites(),
              taskHost,
              taskPath
            ) &&
            canEditTaskUrl && (
              <AlertPopover
                container={container}
                taskHost={taskHost}
                showSite={() => setShow('site')}
                hideSite={hide}
                isAdminView={isAdminView}
                canManageProject={canManageProject}
              />
            )}
          <div className={styles.locationLinkOuter}>
            <a
              onClick={handleLocationClick}
              title={absoluteUrl}
              className={styles.locationLink}
            >
              <div className={styles.locationInner}>
                <MapPin className={styles.pinIcon} />
                <span className={styles.taskPath}>{taskPath}</span>
              </div>
            </a>
            {canEditTaskUrl && !hasNoSites && (
              <Edit
                className={styles.locationEditIcon}
                onClick={() => setShow('path')}
                color="#00a1cb"
              />
            )}
          </div>
        </>
      ) : (
        <div className={styles.locationInputOuter}>
          <Input
            ref={inputRef}
            className={styles.locationInput}
            onPressEnter={saveChanges}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            suffix={
              <XCircle className={styles.locationSuffix} onClick={clear} />
            }
            autoFocus
            value={show === 'path' ? path : site}
          />
          <div className={styles.inputActions}>
            {show === 'path' ? (
              <PathPopover
                container={container}
                isValidPath={isValidPath()}
                save={saveChanges}
              />
            ) : (
              <SitePopover
                container={container}
                isValidSite={isValidSite(site)}
                save={saveChanges}
              />
            )}
            <Button
              type="text"
              size="small"
              onClick={onCancel}
              className={styles.cancelEditLocation}
            >
              {strings.cancelButton}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskLocation;
