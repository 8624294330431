import React, { FC } from 'react';
import { Modal } from 'antd';
import { FigmaURL } from './FigmaURL';
import { FigmaUpload } from './FigmaUpload';
import styles from './index.module.css';
import cx from 'classnames';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { useFigmaUploadState } from '../providers/FigmaUpload';

export const FigmaModal: FC = () => {
  const {
    setIsFigmaModalOpen,
    isFigmaModalOpen,
    container
  } = useDesignAssetsState();
  const {
    showUpload,
    nodes,
    setNodes,
    setShowUpload,
    setFileKey
  } = useFigmaUploadState();

  return (
    <Modal
      // @ts-expect-error
      open={isFigmaModalOpen}
      getContainer={container}
      closable={false}
      destroyOnClose
      onCancel={() => {
        setIsFigmaModalOpen(false);
        setNodes([]);
        setShowUpload(false);
        setFileKey('');
      }}
      footer={null}
      centered
      className={cx(styles.modal, {
        [styles.figmaModal]: !showUpload,
        [styles.figmaUploadModal]: showUpload
      })}
    >
      {!!nodes.length && showUpload ? <FigmaUpload /> : <FigmaURL />}
    </Modal>
  );
};
