import * as React from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';

import { getLangKey } from 'models/Language';
import { setupProjectUrl } from 'api/ProjectApi';
import { getAccount } from 'api/AccountApi';
import { AccountType } from 'models/Account';
import { updateExtension } from 'utils/updateExtension';
import OnbProjectUrlInput, { DNSWarn } from 'components/onbProjectUrlInput';
import Hint from 'components/Hint';
import { stripUrlProtocol } from 'utils/projectSettingsOperations';
import * as translations from './strings';
import styles from './index.module.css';
import cx from 'classnames';

interface Props extends FormComponentProps {
  currentProjectName: string;
  currentProjectUrl: string;
  projectId: number;
  onStepSuccess: (projectName: string, projectUrl: string) => void;
}

type ProjectValidation = {
  projectUrl: { errors: Array<{ field: string; message: string }> };
  projectName: { errors: Array<{ field: string; message: string }> };
};

const ProjectSetup: React.FC<Props> = ({
  currentProjectName,
  currentProjectUrl,
  projectId,
  onStepSuccess,
  form
}: Props) => {
  const strings = translations[getLangKey()];
  const [projectName, setProjectName] = React.useState<string>(
    currentProjectName
  );

  const [projectUrl, setProjectUrl] = React.useState<string>(currentProjectUrl);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<string>('');
  const { getFieldDecorator } = form;

  React.useEffect(() => {
    if (currentProjectUrl === '' || currentProjectUrl === null) {
      getAccount().then((accountData: { data: AccountType }) => {
        if (accountData.data && accountData.data.suggestedProjectDomain) {
          setProjectUrl(accountData.data.suggestedProjectDomain);
        }
      });
    }
  }, []);

  const submitProjectData = async () => {
    setUpdating(true);
    const stripUrl = (url: string) => {
      const parsedURL = new URL(url);
      parsedURL.pathname = '/';
      parsedURL.search = '';
      parsedURL.hash = '';
      return parsedURL.toString();
    };
    try {
      await setupProjectUrl({
        projectId: projectId,
        updateProjectData: {
          name: projectName,
          devurl: stripUrl(projectUrl),
          toggle_vertical_position: 90
        }
      });
      setUpdating(false);
      updateExtension();
      form.setFieldsValue({
        projectUrl: stripUrlProtocol(stripUrl(projectUrl)),
        projectName: projectName
      });
      onStepSuccess(projectName, stripUrl(projectUrl));
    } catch (e) {
      setUpdating(false);
      if (e instanceof Error) setUpdateError(e.message);
    }
  };

  const submitValidated = async (validationProps: ProjectValidation) => {
    if (
      validationProps &&
      validationProps.projectUrl &&
      validationProps.projectUrl.errors &&
      validationProps.projectUrl.errors[0].message !== DNSWarn
    ) {
      return;
    }
    if (
      validationProps &&
      validationProps.projectName &&
      validationProps.projectName.errors
    ) {
      return;
    }
    submitProjectData();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.validateFields(submitValidated);
  };

  const getContainer = () => document.getElementById('empty_state_container');

  return (
    <div className={cx(styles.stepContainer, styles.projSetupOuter)}>
      <Form
        onSubmit={handleSubmit}
        className={styles.setupProjectFormContainer}
        hideRequiredMark
        layout="vertical"
      >
        <Form.Item
          label={
            <span className={styles.formLabel}>{strings.projectNameLabel}</span>
          }
          className={styles.urlInput}
        >
          {getFieldDecorator('projectName', {
            initialValue: projectName,
            rules: [
              {
                required: true,
                message: strings.emptyValidationMessage
              }
            ]
          })(
            <Input
              className={styles.projectName}
              placeholder="My Amazing Project"
              onChange={event => {
                setProjectName(event.target.value);
              }}
            />
          )}
        </Form.Item>
        <div>
          <OnbProjectUrlInput
            parentForm={form}
            labelText={strings.projectUrlLabel}
            placeholder={'demo.bugherd.com'}
            currentUrl={projectUrl || ''}
            setterCallback={setProjectUrl}
            container={getContainer}
          />
          <p className={styles.createProjMessage}>{strings.message}</p>
        </div>
        <Form.Item>
          <Button
            className={styles.continue}
            type="primary"
            htmlType="submit"
            loading={updating}
          >
            {strings.createProject}
          </Button>
          <p className={styles.JsError}>{updateError}</p>
        </Form.Item>
      </Form>

      <Hint
        message={strings.hintMessageProjectSetup}
        description={strings.hintDescriptionProjectSetup}
      />
    </div>
  );
};

export default Form.create<Props>()(ProjectSetup);
