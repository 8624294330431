import * as React from 'react';
import classNames from 'classnames';

import { ChecklistEnum, OnboardingSteps } from './model';
import HelpVideo from 'components/HelpVideo';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import styles from './index.module.css';

const strings = translations[getLangKey()];

const RightPanel = ({
  step,

  showEmbedJavascript
}: {
  step: ChecklistEnum;
  organizationId: number;
  userId: number;
  showEmbedJavascript: boolean;
  experiments: string[];
}) => {
  const videoKey = OnboardingSteps[step].helpVideoKey;
  const hasHelpVideo: boolean = step !== ChecklistEnum.PERSONALIZE;

  const TalkToUs: React.FC = () => (
    <span className={styles.talkToUsOuter}>
      {strings.helpMessagePartOne}
      <a data-elevio-module="3475" data-elevio-style="nothing">
        {strings.helpMessagePartTwo}
      </a>
    </span>
  );

  const getContentForRightPanel = () => {
    if (videoKey && hasHelpVideo) {
      return (
        <div className={styles.guideOuter}>
          <div className={styles.guideInfo}>
            <h4 className={styles.guideHeader}>{strings.guideHeader}</h4>
            <h3 className={styles.guideMessage}>
              {OnboardingSteps[step].guideMessage}
            </h3>
            <span className={styles.videoLength}>
              ({OnboardingSteps[step].videoLength})
            </span>
          </div>
          <HelpVideo videoKey={videoKey} />
          <TalkToUs />
        </div>
      );
    }
    return <TalkToUs />;
  };

  return (
    <div
      className={classNames({
        [styles.rightPanelInner]: true,
        [styles.setupProjectRightPanel]: step === ChecklistEnum.SETUP_PROJECT,
        [styles.installExtensionRightPanel]:
          step === ChecklistEnum.INSTALL_BH && !showEmbedJavascript,
        [styles.installJavascriptRightPanel]:
          step === ChecklistEnum.INSTALL_BH && showEmbedJavascript,
        [styles.inviteRightPanel]: step === ChecklistEnum.PERSONALIZE,
        [styles.websiteRightPanel]: step === ChecklistEnum.WEBSITE
      })}
    >
      {getContentForRightPanel()}
    </div>
  );
};
export default RightPanel;
