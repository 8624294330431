import React, { FC } from 'react';
// @ts-ignore
import styles from './styles.module.css';

type Props = {
  label: string;
  icon?: JSX.Element;
};

const DetailsLabel: FC<Props> = ({ label, icon }) => (
  <span className={styles.detailsLabel}>
    {icon} {label}
  </span>
);

export default DetailsLabel;
