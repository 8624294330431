import * as React from 'react';
import { Button } from 'antd';
import {
  CreditCardOutlined as CreditCard,
  PauseCircleOutlined as PauseCircle,
  RetweetOutlined as Reactivate,
  CloseOutlined as Close
} from '@ant-design/icons';

import styles from '../../index.module.css';
import * as translations from './strings';

export function PlanActions({
  organization: { id },
  pausePlan,
  state
}: {
  state: string;
  organization: { id: string };
  pausePlan: { id: string; price: number };
}) {
  const updatePaymentMethodUrl = `/organizations/${id}/update_payment_method`;
  const cancelPlanUrl = `/organizations/${id}/confirm_cancel`;
  const reactivateUrl = `/organizations/${id}/confirm_reactivate_plan`;
  const reactivatePlanUrl = (planId: string) =>
    `/organizations/${id}/confirm_reactivate_plan?plan_id=${planId}`;

  const migratePlanUrl = (planId: string) =>
    `/organizations/${id}/migrate?plan_id=${planId}`;

  const onPause = () => {
    window.location.href = migratePlanUrl(pausePlan.id);
  };
  const onCancel = () => {
    window.location.href = cancelPlanUrl;
  };
  const onReactivate = () => {
    window.location.href = reactivateUrl;
  };

  const strings: translations.Translations = translations.en;

  const states: Record<string, string[]> = {
    active: ['updatePayment', 'pause', 'cancel'],
    pastDue: ['updatePayment', 'pause', 'cancel'],
    canceled: ['updatePayment', 'pause', 'reactivate'],
    cancelling: ['updatePayment', 'pause', 'reactivate'],
    paused: ['updatePayment', 'cancel'],
    trialing: ['updatePayment', 'pause'],
    trialExpired: ['updatePayment', 'pause']
  };

  const buttons: Record<string, React.ReactElement> = {
    updatePayment: (
      <Button type="link" href={updatePaymentMethodUrl} icon={<CreditCard />}>
        {strings.updatePaymentDetails}
      </Button>
    ),
    pause: (
      <Button
        type="link"
        icon={<PauseCircle />}
        href={
          state === 'canceled'
            ? reactivatePlanUrl(pausePlan.id)
            : migratePlanUrl(pausePlan.id)
        }
        onClick={onPause}
      >
        {strings.pauseSubscription(pausePlan.price)}
      </Button>
    ),
    cancel: (
      <Button
        type="link"
        icon={<Close />}
        href={cancelPlanUrl}
        onClick={onCancel}
      >
        {strings.cancelSubscription}
      </Button>
    ),
    reactivate: (
      <Button
        type="link"
        icon={<Reactivate />}
        href={reactivateUrl}
        onClick={onReactivate}
      >
        {strings.reactivateSubscription}
      </Button>
    )
  };

  return (
    <div className={styles.planActions}>
      <ul>
        {states[state]?.map(button => (
          <li key={button} className={styles.planAction}>
            {buttons[button]}
          </li>
        ))}
      </ul>
    </div>
  );
}
