import React from 'react';
import { GetItemArgs, RightNavItemsArgs } from './types';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import {
  LockKeyhole,
  FileEdit,
  UserCircle,
  FolderPlus,
  HelpCircle,
  Home,
  LogOut,
  Palette
} from 'lucide-react';

const strings = translations[getLangKey()];
const FOLDER_SIZE = 20;

export const rightNavItems = ({
  setIsModalOpen,
  adminUrl,
  admin,
  isGuest,
  isMember,
  daysLeftInTrial,
  amountOfTrialDays,
  id,
  isActiveOrganization,
  openElevio,
  homeUrl,
  toggleDarkMode,
  hasDarkModeExperiment
}: RightNavItemsArgs): GetItemArgs[] => [
  {
    label: <a href="/settings/profile">{strings.editProfile}</a>,
    key: 'profile',
    icon: <FileEdit size={FOLDER_SIZE} />
  },
  {
    label: <a href={adminUrl}>To admin</a>,
    key: 'admin',
    icon: <LockKeyhole size={FOLDER_SIZE} />,
    hidden: !admin
  },
  {
    label: (
      <a onClick={() => toggleDarkMode()} href="#">
        Toggle dark mode
      </a>
    ),
    key: 'dark_theme',
    icon: <Palette size={FOLDER_SIZE} />,
    hidden: !hasDarkModeExperiment
  },
  {
    label: (
      <a
        data-confirm={`This will start your own ${amountOfTrialDays} trial of BugHerd right now. Are you ready to proceed?`}
        rel="nofollow"
        data-method="post"
        href={`/users/${id}/start_trial`}
      >
        {strings.startAccount}
      </a>
    ),
    key: 'trial',
    icon: <UserCircle size={FOLDER_SIZE} />,
    hidden: !((isGuest || isMember) && !daysLeftInTrial)
  },
  {
    label: (
      <a
        data-confirm="This will create a new BugHerd organization subscription. Are you ready to proceed?"
        rel="nofollow"
        data-method="post"
        href={`/users/${id}/start_trial`}
      >
        {strings.newOrg}
      </a>
    ),
    key: 'new_org',
    icon: <FolderPlus size={FOLDER_SIZE} />,
    hidden: isActiveOrganization
  },
  {
    label: (
      <a onClick={openElevio} id="need-help" href="">
        {strings.needHelp}
      </a>
    ),
    key: 'elevio-help',
    icon: <HelpCircle size={FOLDER_SIZE} />
  },
  {
    label: <a href={homeUrl}>{strings.goHome}</a>,
    key: 'go_home',
    icon: <Home size={FOLDER_SIZE} />
  },
  {
    type: 'divider',
    key: 'divider-6'
  },
  {
    label: (
      <a rel="nofollow" data-method="delete" href="/users/sessions">
        {strings.logOut}
      </a>
    ),
    key: 'logout',
    icon: <LogOut size={FOLDER_SIZE} />
  }
];
