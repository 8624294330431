import React, { FC } from 'react';
import { AutoComplete, Input, Button, Tooltip } from 'antd';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { Search, AlertCircle } from 'lucide-react';
import Loader from '../../../../../clients/sidebar/components/Loader';
import styles from './styles.module.css';
import { Parent } from '..';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Emoji from '../../../../components/Emoji';
import cx from 'classnames';

type Props = {
  container: HTMLDivElement;
  searching: boolean;
  search: string;
  handleOnChange: (searchValue: string) => void;
  handleOnSelect: (name: string) => void;
  parentTasks: Parent[];
  showDropdown: boolean;
  setShowDropdown: (showDropdown: boolean) => void;
  handleCreateIntegrationTask: () => void;
  linkingDisabled: boolean;
  handleCancel: () => void;
  linkText?: string;
  isSmallView: boolean;
  createText?: string;
  showSearch: boolean;
  hasQueryWorkflow: boolean;
  setShowSearch: (showSearch: boolean) => void;
  sending: boolean;
  createError: string;
  queryError: string;
  connecting: boolean;
};

const strings = translations[getLangKey()];

const LinkTask: FC<Props> = ({
  container,
  searching,
  search,
  handleOnChange,
  handleOnSelect,
  parentTasks,
  showDropdown,
  setShowDropdown,
  handleCreateIntegrationTask,
  linkingDisabled,
  handleCancel,
  linkText,
  isSmallView,
  createText,
  showSearch,
  hasQueryWorkflow,
  setShowSearch,
  sending,
  createError,
  queryError,
  connecting
}) => {
  const className = (name: string): string =>
    cx(styles[name], { [styles.smallButton]: isSmallView });

  const size: SizeType = isSmallView ? 'small' : 'middle';

  const linkButtonText: string | undefined = linkText
    ?.split(' existing')
    .join('');

  const getPrefixIcon = () => {
    if (queryError)
      return (
        <Tooltip
          title={queryError}
          getPopupContainer={() => container}
          placement="bottomLeft"
          overlayClassName={styles.tooltip}
        >
          <AlertCircle className={styles.alertIcon} />
        </Tooltip>
      );
    return searching ? (
      <Loader useDarkStyles className={styles.loaderIcon} />
    ) : (
      <Search className={styles.searchIcon} />
    );
  };

  return (
    <div className={styles.connectContainer}>
      {showSearch ? (
        <div
          className={cx(styles.searchContainer, {
            [styles.smallSearch]: isSmallView
          })}
        >
          <span className={styles.connectedText}>{linkText}:</span>
          <div className={styles.searchOuter}>
            <AutoComplete
              className={styles.linkTaskAutoComplete}
              getPopupContainer={() => container}
              defaultValue=""
              options={parentTasks.map(({ id, name }) => ({
                value: name,
                label: (
                  <div
                    className={styles.optionContainer}
                    title={`${id}: ${name}`}
                  >
                    <span className={styles.optionId}>{id}</span>
                    <span className={styles.optionName}>{name}</span>
                  </div>
                )
              }))}
              value={search}
              onSearch={handleOnChange}
              dropdownMatchSelectWidth
              onSelect={handleOnSelect}
              onDropdownVisibleChange={setShowDropdown}
              open={showDropdown}
              autoFocus
            >
              <Input
                autoFocus
                className={styles.searchInput}
                type="text"
                placeholder={strings.search}
                prefix={getPrefixIcon()}
                allowClear
              />
            </AutoComplete>
            <div className={styles.searchActions}>
              <Button
                type="primary"
                onClick={handleCreateIntegrationTask}
                disabled={sending || linkingDisabled}
                size={size}
                className={className('linkTaskButton')}
                loading={sending}
              >
                {linkButtonText}
              </Button>
              <Button
                type="default"
                onClick={handleCancel}
                size={size}
                className={className('cancel')}
              >
                {strings.cancel}
              </Button>
            </div>
          </div>
        </div>
      ) : connecting ? (
        <div className={styles.connectingInner}>
          <Emoji symbol="📤" /> {strings.connecting}
        </div>
      ) : (
        <div className={styles.connectedInner}>
          <Button
            type="primary"
            onClick={handleCreateIntegrationTask}
            size={size}
            className={className('createButton')}
            loading={sending}
          >
            {createText}
          </Button>
          {hasQueryWorkflow && (
            <Button
              onClick={() => setShowSearch(true)}
              ghost
              type="default"
              size={size}
              className={className('linkExisting')}
            >
              {linkText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default LinkTask;
