const documentFileTypes = ['json', 'csv', 'txt', 'html', 'htm', 'xml', 'xhtml'];
export const MS_DOC_FILE_TYPES = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

const imageFileTypes = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'svg',
  'webp',
  'ico',
  'apng',
  'avif',
  'heic'
];

const videoFileTypes = [
  'mp4',
  'webm',
  'ogg',
  'avi',
  'flv',
  'mov',
  'wmv',
  'mkv',
  '3gp',
  '3g2',
  'm4v',
  'mpg',
  'mpeg',
  'm2v',
  'm2ts',
  'mts',
  'ts',
  'vob',
  'asf',
  'rm',
  'rmvb',
  'divx',
  'xvid',
  'f4v',
  'h264',
  'h265',
  'hevc',
  'vp8',
  'vp9',
  'av1'
];

export enum FileType {
  Document = 'document',
  Image = 'image',
  Video = 'video',
  Other = 'other'
}

export const getFileType = (extension: string): FileType => {
  if (documentFileTypes.includes(extension)) {
    return FileType.Document;
  } else if (imageFileTypes.includes(extension)) {
    return FileType.Image;
  } else if (videoFileTypes.includes(extension)) {
    return FileType.Video;
  } else {
    return FileType.Other;
  }
};
