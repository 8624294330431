import React from 'react';
import PropTypes from 'prop-types';

import SubFilterList from './subFilter_list';
import NavItem from './nav_item';

import styles from './index.module.css';

export default class SidebarNav extends React.Component {
  static propTypes = {
    topFilter: PropTypes.string,
    subFilter: PropTypes.array,
    isActiveSubFilter: PropTypes.bool,
    clickTopFilter: PropTypes.func,
    clickSubFilter: PropTypes.func,
    accessTo: PropTypes.shape({
      sidebar_view_statuses: PropTypes.bool,
      guests_see_guests: PropTypes.bool,
      guests_see_guests_allowed: PropTypes.bool
    }),
    subFilters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.shape({
          myCount: PropTypes.number,
          allCount: PropTypes.number
        })
      })
    ),
    topFilterProps: PropTypes.shape({
      myTasks: PropTypes.shape({
        filter: PropTypes.string,
        tooltip: PropTypes.string
      }),
      allTasks: PropTypes.shape({
        filter: PropTypes.string,
        tooltip: PropTypes.string
      })
    }),
    checkActiveSubFilter: PropTypes.func,
    handleNavLockedClick: PropTypes.func
  };

  getExpandedAndSelected = filter => {
    const { topFilter, isActiveSubFilter } = this.props;
    const filters = {
      my: ['mine', 'reported'],
      all: ['all', 'all_guest']
    };
    const props = {};
    if (filters[filter].includes(topFilter)) {
      if (isActiveSubFilter) {
        props.isSelected = false;
        props.isChildSelected = true;
      } else {
        props.isSelected = true;
      }
    } else {
      props.isChildSelected = false;
    }

    return props;
  };

  handleTopFilterClick = filter => {
    this.props.clickTopFilter(filter);
  };

  getFilters = value => {
    const {
      topFilterProps,
      subFilters,
      subFilter,
      clickSubFilter,
      checkActiveSubFilter,
      handleNavLockedClick,
      accessTo: {
        sidebar_view_statuses,
        guests_see_guests,
        guests_see_guests_allowed
      }
    } = this.props;

    const name = value + ' tasks';
    const filterProps = topFilterProps[value + 'Tasks'];
    Object.assign(filterProps, this.getExpandedAndSelected(value));

    const filterList = () => (
      <div className={styles.filtersList}>
        <NavItem
          type={value}
          name={name}
          onClick={() => this.handleTopFilterClick(filterProps.filter)}
          {...filterProps}
        />
        <SubFilterList
          filters={subFilters}
          subFilter={subFilter}
          topFilter={value}
          onClick={clickSubFilter}
          checkActiveSubFilter={checkActiveSubFilter}
          isOpen={filterProps.isSelected || filterProps.isChildSelected}
        />
      </div>
    );

    if (
      (value === 'all' && (sidebar_view_statuses || guests_see_guests)) ||
      value === 'my'
    ) {
      return filterList();
    } else if (!guests_see_guests_allowed) {
      return (
        <NavItem
          type="lock"
          name="all tasks"
          showNavLockedPopup={handleNavLockedClick}
          filter="lock"
          tooltip="issues reported by other people"
        />
      );
    }
  };

  render() {
    return (
      <div ref={this.containerRef} className={styles.container}>
        <ul className={styles.topFilterList}>
          {this.getFilters('my')}
          {this.getFilters('all')}
        </ul>
      </div>
    );
  }
}
