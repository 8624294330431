import { ReactNode } from 'react';
import styles from './index.module.css';

export type ActiveTab = 'access' | 'feedback' | 'team';

const tabs: ActiveTab[] = ['access', 'team', 'feedback'];

type ItemProps = {
  key: ActiveTab;
  label: string;
  children: ReactNode;
};

type TabItem = {
  label: string;
  show: boolean;
  children: ReactNode;
};

type TabItemsProps = {
  access: TabItem;
  feedback: TabItem;
  team: TabItem;
};

const item = ({ key, label, children }: ItemProps) => ({
  label,
  key,
  children,
  className: styles.modalTab
});

export const tabItems = (tabItemsProps: TabItemsProps) => {
  return tabs
    .filter(key => tabItemsProps[key].show)
    .map(key => item({ key, ...tabItemsProps[key] }));
};
