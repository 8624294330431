import * as React from 'react';
import { Button, message } from 'antd';

import { getLangKey } from 'models/Language';
import { validateJsInstalled } from 'api/ProjectApi';
import VerificationError from '../../install_bugherd/VerificationError';
import { SiteError } from 'models/siteError';
import * as translations from './strings';
import EmbedCode from 'components/embed_code';
import styles from './index.module.css';

export interface Props {
  widgetCode: string;
  showExtension: () => void;
  siteErrors: SiteError[];
  javascriptInstalled?: boolean;
  projectId: number;
  projectUrl: string;
  lastSiteCheckAt: string;
  isExtensionSupported: boolean;
}

const StatusOk = 'ok';

const InstallJavascript: React.FC<Props> = ({
  widgetCode,
  projectId,
  projectUrl,
  javascriptInstalled,
  siteErrors,
  lastSiteCheckAt,
  showExtension,
  isExtensionSupported
}) => {
  const strings = translations[getLangKey()];
  const [isCheckingSiteForScript, setIsCheckingSiteForScript] = React.useState(
    false
  );
  const [responseError, setResponseError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsCheckingSiteForScript(false);
  }, [lastSiteCheckAt]);

  React.useEffect(() => {
    setIsCheckingSiteForScript(false);
  }, [lastSiteCheckAt]);

  const verifyJavascriptIsInstalled = async () => {
    setIsCheckingSiteForScript(true);
    try {
      const response = await validateJsInstalled(projectId);
      if (response && response.status === StatusOk) {
        message.info(strings.verifyingJsMessage);
      }
    } catch (e) {
      setIsCheckingSiteForScript(false);
      setResponseError(true);
    }
  };

  const hasScriptErrors =
    (siteErrors.filter(x => !x.resolved).length > 0 &&
      !isCheckingSiteForScript) ||
    responseError;

  return (
    <div className={styles.installBhContainer}>
      <div>
        <p className={styles.installJsLabel}>
          {strings.installJsLabel1} <code>{`<head>`}</code>{' '}
          {strings.installJsLabel2}
        </p>
        <EmbedCode widgetCode={widgetCode} />
        <Button
          className={styles.marginTop}
          type="primary"
          loading={isCheckingSiteForScript}
          onClick={verifyJavascriptIsInstalled}
        >
          {javascriptInstalled || hasScriptErrors
            ? strings.verifyAgain
            : strings.verify}
        </Button>
        <div className={styles.marginTop}>
          {javascriptInstalled && !hasScriptErrors && !isCheckingSiteForScript && (
            <>
              <p className={styles.jsSuccess}>{strings.verifySuccess}</p>
              <p className={styles.jsSuccessMessage}>
                {strings.verifySuccessMessage}
              </p>
            </>
          )}
          {hasScriptErrors && (
            <div className={styles.jsError}>
              <VerificationError
                projectId={projectId}
                projectUrl={projectUrl}
                siteErrors={siteErrors}
                responseError={responseError}
              />
            </div>
          )}
        </div>
      </div>
      {isExtensionSupported && (
        <div className={styles.showExtensionOuter}>
          <span>or</span>
          <a onClick={showExtension}>{strings.installExtMessage}</a>
        </div>
      )}
    </div>
  );
};

export default InstallJavascript;
