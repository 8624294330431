import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Input, Button, Alert, Checkbox } from 'antd';
import {
  antdForm,
  historyType,
  matchType,
  locationType
} from '../../../utils/propTypes';

import styles from './form.module.css';
import sharedStyles from '../../../components/onboarding/shared.module.css';
//import track from '../../../utils/analytics';
//import analyticsParams from '../analytics_params';

import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

class CreateUserForm extends React.Component {
  static propTypes = {
    form: antdForm,
    endpoint: PropTypes.string.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    history: historyType,
    match: matchType,
    location: locationType,
    container: PropTypes.object.isRequired,
    ssoEnabled: PropTypes.bool,
    validationToken: PropTypes.string.isRequired
  };

  state = {
    loading: false,
    error: null
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      form: { validateFields }
    } = this.props;

    let validationErrors = false;

    validateFields(errors => {
      if (errors) {
        validationErrors = true;
      }
    });

    if (validationErrors) {
      return;
    }

    this.setState({
      loading: true,
      error: null
    });

    fetch(this.props.endpoint, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        authenticity_token: this.props.authenticityToken,
        user: {
          password_confirmation: this.props.form.getFieldsValue(['password'])
            .password,
          password: this.props.form.getFieldsValue(['password']).password,
          surname: this.props.form.getFieldsValue(['lastName']).lastName,
          name: this.props.form.getFieldsValue(['firstName']).firstName,
          email: this.props.form.getFieldsValue(['email']).email,
          newsletter: this.props.form.getFieldsValue(['newsletter']).newsletter,
          // eslint-disable-next-line new-cap
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (!res.ok && res.status !== 422) {
          throw Error('Something went wrong, please try again.');
        } else {
          return res.json();
        }
      })
      .then(body => {
        if (body.error && body.error.messages) {
          throw Error(body.error.messages[0]);
        } else {
          //track('Onboarding Accept Invite', analyticsParams(this.props));
          window.location = `/accept-invite/extension?project_id=${body.project_id}`;
        }
      })
      .catch(({ message }) => {
        this.setState({
          loading: false,
          error: message
        });
      });
  };

  renderSingleSignOn = () => {
    if (this.props.ssoEnabled) {
      return (
        <div className={styles.textCenter}>
          <div className={styles.ssoDivider}>
            <span className={styles.ssoDividerText}>OR</span>
          </div>
          <a
            href={`/users/sign_in/sso?validation_token=${this.props.validationToken}`}
          >
            <Button className={styles.submit} type="primary">
              Accept invite with SSO
            </Button>
          </a>
        </div>
      );
    }
  };

  render() {
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    return (
      <Form
        layout="vertical"
        hideRequiredMark
        className={styles.form}
        onSubmit={this.handleSubmit}
      >
        {!!this.state.error && (
          <Alert
            message={
              this.state.error.includes('login') ? (
                <p className={styles.emailErrorChild}>
                  That email address already has an account, please{' '}
                  <a href={`/users/sign_in?redirect_to=${window.location}`}>
                    sign in
                  </a>
                </p>
              ) : (
                this.state.error
              )
            }
            type="error"
            showIcon
          />
        )}

        <div className={styles.nameWrapper}>
          <Form.Item label="First name">
            {getFieldDecorator('firstName', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'First name is required'
                }
              ]
            })(
              <Input
                onChange={event =>
                  setFieldsValue({
                    firstName: event.target.value
                  })
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Last name">
            {getFieldDecorator('lastName', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Last name is required'
                }
              ]
            })(
              <Input
                onChange={event =>
                  setFieldsValue({
                    lastName: event.target.value
                  })
                }
              />
            )}
          </Form.Item>
        </div>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Email is required'
              }
            ]
          })(
            <Input
              onChange={event =>
                setFieldsValue({
                  email: event.target.value
                })
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Create a Password">
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Password is required'
              }
            ]
          })(
            <Input
              type="password"
              onChange={event =>
                setFieldsValue({
                  password: event.target.value
                })
              }
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('newsletter', {
            initialValue: false,
            rules: [
              {
                required: false
              }
            ]
          })(
            <Checkbox
              onChange={({ target: { checked } }) =>
                setFieldsValue({
                  newsletter: checked
                })
              }
            >
              <div className={styles.subscribeCopy}>
                {strings.tickToSubscribe}
              </div>
            </Checkbox>
          )}
        </Form.Item>

        <p className={sharedStyles.terms}>
          By signing up you confirm that you have read and agree to our{' '}
          <a href="https://get.bugherd.com/terms" target="_blank">
            terms of service
          </a>{' '}
          and{' '}
          <a href="https://get.bugherd.com/privacy" target="_blank">
            privacy policy
          </a>
          .
        </p>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            loading={this.state.loading}
          >
            Accept Invitation
          </Button>
        </div>
        {this.renderSingleSignOn()}
        <div className={sharedStyles.terms} style={{ marginTop: '1.5em' }}>
          Already have a BugHerd account?{' '}
          <a href={`/users/sign_in?redirect_to=${window.location}`}>sign in</a>{' '}
          to accept this invitation.
        </div>
      </Form>
    );
  }
}

export default Form.create()(CreateUserForm);
