import { Button } from 'antd';
import * as React from 'react';
import { User } from 'lucide-react';
import ArrowUp from './images/ic-upgrade-circle.svg';
import Feature from './Feature';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import { HelpModuleButton } from 'utils/HelpArticleButton';

const strings = translations[getLangKey()];

const customPlanFeatures = [
  strings.dedicatedSuccessManager,
  strings.onboardingAndTraining,
  strings.upltimeSla,
  strings.plusPremium
];

const CustomPlan = () => {
  return (
    <div className={styles.plan}>
      <div className={styles.header}>
        <h2>{strings.customHeader}</h2>
      </div>
      <div className={styles.featuresBox}>
        <ul>
          <li key="user">
            <span>
              <User />
              {strings.customMember}
            </span>
          </li>
          {customPlanFeatures.map((feature: string) => (
            <Feature key={feature} name={feature} />
          ))}
          <li key="numUsers">
            <span>
              <ArrowUp />
              {strings.singleSignOn}
            </span>
          </li>
        </ul>
        <div>
          <Button className={styles.customContactUs}>
            <HelpModuleButton moduleId={'3475'}>
              {strings.customContactUs}{' '}
            </HelpModuleButton>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomPlan;
