import React from 'react';
import { GetItemArgs } from './types';

export const getItem = ({
  href,
  text,
  onClick,
  key,
  icon,
  children,
  anchorProps,
  label,
  type
}: GetItemArgs) => ({
  label: label || (
    <a href={href} onClick={onClick} {...(anchorProps || {})}>
      {text}
    </a>
  ),
  key,
  icon,
  children,
  type
});
