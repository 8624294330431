import { gql, useQuery } from '@apollo/client';

import Config from 'models/Config';
import { CurrentUser } from 'models/User';
import { TaskGraphql } from 'models/Task';

import { UserTask, UserComment, UserTaskOrder, UserTaskFilter } from './types';

interface GetCurrentUserData {
  currentUser: CurrentUser;
}

interface GetCurrentUserVars {
  organizationId: string;
}

const GET_CURRENT_USER = gql`
  query getCurrentUser($organizationId: ID!) {
    currentUser {
      __typename
      id
      name
      role(organizationId: $organizationId)
      organization(organizationId: $organizationId) {
        __typename
        id
        name
        isActive
        priorities {
          id
          name
        }
        statusNames
      }
      accessTo(organizationId: $organizationId) {
        viewUserActivities
        canViewAssets
      }
    }
  }
`;

export const useGetCurrentUserQuery = ({
  organizationId
}: {
  organizationId: string;
}) =>
  useQuery<GetCurrentUserData, GetCurrentUserVars>(GET_CURRENT_USER, {
    variables: {
      organizationId
    }
  });

export interface GetUserTasksData {
  config: Config;
  currentUser: {
    id: string;
    userTasks: UserTask[];
  };
}

export interface GetUserTasksVars {
  organizationId: string;
  offset: number;
  limit: number;
  orderBy?: UserTaskOrder;
  filter?: UserTaskFilter;
}

export const GET_USER_TASKS = gql`
  query getUserTasks(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $orderBy: TaskOrder
    $filter: UserTaskFilter
  ) {
    config {
      pusherApiKey
      pusherChannelAuthEndpoint
    }
    currentUser {
      __typename
      id
      userTasks(
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        filter: $filter
      ) {
        __typename
        ...TaskFields
        requester {
          __typename
          id
          name
          email
        }
        project {
          __typename
          id
          name
          pusherChannelName
        }
        assignedUsers {
          __typename
          id
          name
          avatarUrl
          email
          role
          firstName
          surname
        }
        priorityId
      }
    }
  }
  ${TaskGraphql.fragments.TaskFields}
`;

export interface GetUserCommentsData {
  config: Config;
  currentUser: {
    userComments: UserComment[];
  };
}

export interface GetUserCommentsVars {
  organizationId: string;
  offset: number;
  limit: number;
  filter?: { text: string };
}

export const GET_USER_COMMENTS = gql`
  query getUserComments(
    $organizationId: ID!
    $offset: Int!
    $limit: Int!
    $filter: CommentFilter
  ) {
    config {
      pusherApiKey
      pusherChannelAuthEndpoint
    }
    currentUser {
      __typename
      id
      userComments(
        organizationId: $organizationId
        offset: $offset
        limit: $limit
        filter: $filter
      ) {
        __typename
        id
        createdAt
        updatedAt
        text
        isPrivate
        localTaskId
        project {
          __typename
          id
          name
          pusherChannelName
        }
        user {
          __typename
          id
          name
          avatarUrl
          role
          firstName
          surname
        }
      }
    }
  }
`;
