import { ProjectSite } from 'models/Project';

export interface Project {
  id: string;
  name: string;
  devurl?: string;
  hostnames: string[];
  allowEmailTasks: boolean;
  allowEmailComments: boolean;
  isPublic: boolean;
  organizationId: string;
  apiKey: string;
  guestPermission: string;
  sites: ProjectSite[];
  ignoredQuerystrings: string[];
  allowProjectSummaryEmail: boolean;
  allowTaskDoneEmail: boolean;
}

export type ProjectSiteData = {
  project?: {
    id: string;
    feedbackUrl?: string;
  };
  projectSite?: { id: string };
};

export enum ProjectFormField {
  AllowGuestPermission = 'allowGuestPermission',
  AllowEmailTasks = 'allowEmailTasks',
  AllowEmailComments = 'allowEmailComments',
  Name = 'name',
  IgnoreList = 'ignoredQuerystrings'
}
