import React from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import { ProjectSite } from 'models/Project';
import { stripUrlProtocol } from 'utils/projectSettingsOperations';

import { Project } from '../types';
import updateCacheOnCreateSite from '../callbacks/updateCacheOnCreateSite';
import {
  CreateProjectSiteResponse,
  CreateProjectSiteDetails,
  CREATE_PROJECT_SITE,
  UpdateProjectSiteDetails,
  UpdateProjectSiteResponse,
  UPDATE_PROJECT_SITE
} from '../ProjectSites/mutations';

interface SaveProjectSite {
  (args: {
    project: { id: string; sites: ProjectSite[] };
    projectSite: ProjectSite;
  }): boolean;
}

interface UseProjectSiteMutation {
  (args: {
    onCompleted: ({ projectSite }: { projectSite?: { id: string } }) => void;
    onError: (error: ApolloError) => void;
  }): [SaveProjectSite];
}

const useProjectSiteMutation: UseProjectSiteMutation = ({
  onCompleted,
  onError
}) => {
  const [createProjectSite] = useMutation<
    {
      createProjectSite: CreateProjectSiteResponse;
    },
    { input: CreateProjectSiteDetails }
  >(CREATE_PROJECT_SITE, {
    update: updateCacheOnCreateSite,
    onCompleted: ({ createProjectSite }) => {
      onCompleted({ projectSite: createProjectSite.projectSite });
    },
    onError
  });

  const [updateProjectSite] = useMutation<
    { updateProjectSite: UpdateProjectSiteResponse },
    { input: UpdateProjectSiteDetails }
  >(UPDATE_PROJECT_SITE, {
    onCompleted: ({ updateProjectSite }) => {
      onCompleted({ projectSite: updateProjectSite.projectSite });
    },
    onError
  });

  const saveProjectSite: SaveProjectSite = ({ project, projectSite }) => {
    if (stripUrlProtocol(projectSite.url.trim()) === '') {
      return false;
    }

    if (projectSite.id === '') {
      createProjectSite({
        variables: {
          input: {
            projectId: project.id,
            url: projectSite.url
          }
        }
      });
      return true;
    } else {
      const previousSite = project.sites.find(
        prevSite => prevSite.id === projectSite.id
      );

      if (!previousSite) return false;

      if (previousSite.url !== projectSite.url) {
        updateProjectSite({
          variables: {
            input: {
              projectSiteId: projectSite.id,
              url: projectSite.url
            }
          }
        });
        return true;
      }
    }
    return false;
  };

  return [saveProjectSite];
};

interface UseProjectSitesMutation {
  (args: {
    project: Project;
    projectSites: ProjectSite[];
    message: { error: (message: string) => void };
    onCompleted: () => void;
  }): [() => void];
}

const useProjectSitesMutation: UseProjectSitesMutation = ({
  project,
  projectSites,
  message,
  onCompleted
}) => {
  const [
    submittingProjectSiteIndex,
    setSubmittingProjectSiteIndex
  ] = React.useState<number>();

  const onError = React.useCallback((error: ApolloError) => {
    setSubmittingProjectSiteIndex(undefined); // Stop saving if any left
    if (error.message) {
      message.error(error.message);
    }
  }, []);

  const [createOrUpdateProjectSite] = useProjectSiteMutation({
    onError,
    onCompleted: ({ projectSite }) => {
      if (submittingProjectSiteIndex === undefined) return;

      // Prevent from double creating
      if (projectSite && projectSites[submittingProjectSiteIndex]) {
        projectSites[submittingProjectSiteIndex].id = projectSite.id;
      }

      setSubmittingProjectSiteIndex(submittingProjectSiteIndex + 1);
    }
  });

  const saveProjectSite = (projectSite: ProjectSite) => {
    if (submittingProjectSiteIndex === undefined) return;

    if (createOrUpdateProjectSite({ project, projectSite })) {
      return;
    }

    // If the project site is neither created or updated, move the index
    // to the next project site
    setSubmittingProjectSiteIndex(submittingProjectSiteIndex + 1);
  };

  React.useEffect(() => {
    if (submittingProjectSiteIndex === undefined) return;

    const projectSite = projectSites[submittingProjectSiteIndex];
    if (projectSite) {
      saveProjectSite(projectSite);
    } else {
      setSubmittingProjectSiteIndex(undefined);
      onCompleted();
    }
  }, [submittingProjectSiteIndex]);

  const saveProjectSites = () => setSubmittingProjectSiteIndex(0);

  return [saveProjectSites];
};

export default useProjectSitesMutation;
