import React, { FC } from 'react';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  show: boolean;
  toggleWhy: (isOpen: boolean, modalName: string) => void;
};

// @ts-ignore
const strings = translations[getLangKey()];

const Timeout: FC<Props> = ({ show, toggleWhy }) => (
  <div className={styles.modalContent}>
    <p className={styles.whyMessage}>{strings.noResponse}</p>
    <p className={styles.whyMoreInfo}>{strings.publiclyAvailable}</p>
    <Button
      className={styles.whyButton}
      onClick={() => toggleWhy(show, 'showWhy')}
      size="large"
      type="primary"
    >
      {strings.ok}
    </Button>
  </div>
);

export default Timeout;
