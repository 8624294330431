import { useState } from 'react';

import { getData, setData } from 'models/LocalStorage';

const usePagination = (
  localStorageKey: string,
  defaultSize: number
): {
  pageNumber: number;
  setPageNumber: (page: number) => void;
  setPageSize: (size: number) => void;
  pageSize: number;
  onPaginationChange: (pageNumber: number, pageSize?: number) => void;
} => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, _setPageSize] = useState<number>(
    getData(localStorageKey) || defaultSize
  );

  const setPageSize = (size: number) => {
    _setPageSize(size);
    setData(localStorageKey, size);
  };

  const onPaginationChange = (pageNumber: number, pageSize?: number) => {
    setPageNumber(pageNumber || 1);
    setPageSize(pageSize || defaultSize);
  };

  return {
    pageNumber,
    setPageNumber,
    setPageSize,
    pageSize,
    onPaginationChange
  };
};

export default usePagination;
