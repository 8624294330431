import React, { useState, useEffect } from 'react';
import ArrowUp from './images/ic-upgrade-circle.svg';
import { Form, Input, Button, message as antmessage, Alert } from 'antd';
import { get } from 'utils/fetch';

import styles from './index.module.css';
import { Link } from 'react-router-dom';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
const strings = translations[getLangKey()];

export default ({ authenticityToken }: { authenticityToken: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [form] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');

    if (error) {
      localStorage.removeItem('bugherdSsoOrganizationUrl');
      setErrorMessage(error);
    }
  }, []);

  useEffect(() => {
    if (countdown === undefined) {
      return;
    }

    if (countdown === 0) {
      window.close();
      return;
    }
    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  const onFinish = (values: { organization: string }) => {
    setLoading(true);

    const params = new URLSearchParams(window.location.search);
    const validationToken = params.get('validation_token');
    const projectId = params.get('project_id');
    const urlParams =
      validationToken && projectId
        ? `?validation_token=${validationToken}&project_id=${projectId}`
        : '';

    get(`/users/sign_in/sso/auth/${values.organization}${urlParams}`, {
      utf8: '✓',
      authenticity_token: authenticityToken
    })
      .then(body => {
        if (body.error) {
          setLoading(false);
          form.setFields([
            {
              name: 'organization',
              errors: [strings.organizationNameDoesntExist]
            }
          ]);
        } else {
          localStorage.setItem(
            'bugherdSsoOrganizationUrl',
            values.organization
          );
          window.location.href = body.auth_url;
        }
      })
      .catch(({ message }) => {
        setLoading(false);
        if (message.includes('HTTP error!')) {
          antmessage.error(strings.thereWasAnError, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <Alert
          className={styles.errorMessage}
          message={errorMessage}
          type="error"
          closable
          onClose={() => setErrorMessage(undefined)}
        />
      );
    }
  };

  const renderContent = () => {
    const params = new URLSearchParams(window.location.search);
    const orgUrl = params.get('org_url') || '';

    return (
      <div>
        <h1 className={styles.title}>{strings.logInToYourOrganization}</h1>

        {renderErrorMessage()}

        <Form
          className={styles.form}
          name="login"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          initialValues={{ organization: orgUrl }}
          form={form}
        >
          <Form.Item
            className={styles.orgUrl}
            label=""
            name="organization"
            rules={[
              {
                required: true,
                message: strings.pleaseEnterYourOrganizationsDomain
              }
            ]}
          >
            <Input addonBefore="www.bugherd.com/" placeholder="your-org-url" />
          </Form.Item>
          <div className={styles.center}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const { organization } = getFieldsValue();
                const formIsComplete = !!organization;
                return (
                  <div>
                    <Button
                      className={styles.submit}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      disabled={!formIsComplete}
                      loading={loading}
                    >
                      {strings.continue}
                    </Button>
                  </div>
                );
              }}
            </Form.Item>
            <p>
              <Link to="/sign_in">{strings.back}</Link>
            </p>
            {strings.dontKnowYourOrganizationsUrl}{' '}
            <a data-elevio-article={84899}>{strings.help}</a>
          </div>
        </Form>

        <img
          className={styles.footerImage}
          src={require('./images/footer-image.jpg')}
        />
      </div>
    );
  };

  const renderUpgradeMessaging = () => {
    return (
      <div className={styles.upgradeMessaging}>
        <ArrowUp className={styles.upgradeArrow} />
        <p className={styles.findOutMoreContainer}>
          {strings.beOnAnEnterprisePlan}
          <br />
          <a className={styles.findOutMore} data-elevio-article={84900}>
            {strings.findOutMore}
          </a>
        </p>
      </div>
    );
  };

  return (
    <div>
      {renderContent()}
      {renderUpgradeMessaging()}
    </div>
  );
};
