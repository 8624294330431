import { ReactNode } from 'react';
import { Tag } from '../../../../clients/sidebar/models/Project';
import { ActiveKey } from 'appJS/views/new_project_settings/types';

export type Section = 'design' | 'main' | 'body' | 'footer';

export enum View {
  GettingStarted = 'getting-started',
  Assets = 'assets',
  Triage = 'triage',
  Kanban = 'kanban',
  Archive = 'archive',
  Settings = 'settings',
  Integrations = 'integrations',
  Members = 'members',
  Guests = 'guests',
  Collaborators = 'collaborators',
  Tags = 'tags',
  Website = 'website',
  Help = 'help',
  Home = 'home'
}

export type UserRole =
  | 'guest'
  | 'collaborator'
  | 'member'
  | 'manager'
  | 'billing'
  | 'billing_manager';

export type User = {
  name: string;
  avatar: string;
  email: string;
  display_name: string;
  large_avatar_url: string;
  new_avatar_url: string;
  role: UserRole;
  completed_task_count: number;
  task_count: number;
  first_name?: string;
  surname?: string;
  id: number;
};

export type ProjectNavProps = {
  triageCount: number;
  kanbanCount: number;
  tags: Tag[];
  users: User[];
  collapsed: boolean;
  setView: (view: View | null) => void;
  toggleCollapsed: () => void;
  view: View | null;
  activeKey: ActiveKey;
  setActiveKey: (activeKey: ActiveKey) => void;
  showGettingStartedChecklist: boolean;
  gettingStartedCount: string;
  hasNewOnboarding: boolean;
  canAccess: {
    viewKanbanProject: boolean;
    viewGuestKanban: boolean;
    assignGuests: boolean;
    assignGuestsAllowed: boolean;
  };
  projectId: number;
  organizationId: number;
  showCello: boolean;
};

export type NavItem = {
  label: string;
  key: View | null;
  icon?: ReactNode;
  component?: ReactNode;
  withDivider?: {
    mobile: boolean;
  };
  count?: number | string;
  section: Section;
  className?: string;
  showIndicator?: boolean;
};

export type NavItemArgs = {
  triageCount: number;
  kanbanCount: number;
  gettingStartedCount: string;
  tags?: Tag[];
  users?: User[];
  setView: (view: View | null) => void;
  hasNewOnboarding: boolean;
  canAssignGuests: boolean;
};

export type NavItemProps = {
  item: NavItem;
  active: boolean;
  onClick: (event) => void;
  collapsed: boolean;
  view: View | null;
};

export type TagsProps = {
  tags: Tag[];
  setView: (view: View | null) => void;
  title: string;
};
