import { useState, useEffect } from 'react';
import { get } from '../../javascript/utils/fetch';
import {
  ParagonIntegrations,
  ParagonIntegrationData,
  WorkFlow
} from '../components/Integrations/types';

const useParagonIntegrations = ({
  paragonEnabled,
  projectId,
  apiDomain,
  isTaskInfo,
  hasParagonAccess
}: {
  paragonEnabled: boolean;
  projectId: number;
  apiDomain: string;
  isTaskInfo: boolean;
  hasParagonAccess: boolean;
}) => {
  const [paragonIntegrations, setParagonIntegrations] = useState<
    ParagonIntegrations
  >(null);
  const [paragonIntegrationsData, setParagonIntegrationsData] = useState<
    ParagonIntegrationData[]
  >([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState<boolean>(
    false
  );
  const [loadingIntegrationsData, setLoadingIntegrationsData] = useState<
    boolean
  >(false);
  const [integrationsError, setIntegrationsError] = useState<string>('');

  const handleIntegrationsError = (err: any) => {
    setIntegrationsError(
      'Loading integrations information failed, please refresh to try again'
    );
    console.error(err);
  };

  const projectsEndpoint: string = `${apiDomain}/projects/${projectId}`;

  const getIntegrationsInformation = () => {
    if (paragonEnabled && hasParagonAccess) {
      setLoadingIntegrations(true);
      get(`${projectsEndpoint}/get_paragon_user`)
        .then((res: { integrations: ParagonIntegrations }) => {
          setParagonIntegrations(res.integrations);
          setLoadingIntegrations(false);
        })
        .catch((err: any) => {
          handleIntegrationsError(err);
          setLoadingIntegrations(false);
        });

      setLoadingIntegrationsData(true);
      get(`${projectsEndpoint}/get_paragon_workflows`)
        .then((res: ParagonIntegrationData[]) => {
          setParagonIntegrationsData(
            res.map(
              ({ type, isActive, workflows }: ParagonIntegrationData) => ({
                type,
                isActive,
                workflows: workflows.map(({ id, description }: WorkFlow) => ({
                  id,
                  description
                }))
              })
            )
          );
          setLoadingIntegrationsData(false);
        })
        .catch((err: any) => {
          handleIntegrationsError(err);
          setLoadingIntegrationsData(false);
        });
    }
  };

  useEffect(() => {
    if (
      !isTaskInfo &&
      !(paragonIntegrations && paragonIntegrationsData.length)
    ) {
      getIntegrationsInformation();
    }
  }, [isTaskInfo]);

  useEffect(() => {
    if (integrationsError && paragonIntegrations && paragonIntegrationsData) {
      setIntegrationsError('');
    }
  }, [paragonIntegrations, paragonIntegrationsData]);

  return {
    paragonIntegrations,
    paragonIntegrationsData,
    loadingIntegrationsData,
    loadingIntegrations,
    integrationsError,
    getIntegrationsInformation
  };
};

export default useParagonIntegrations;
