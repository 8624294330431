import * as React from 'react';

import styles from './index.module.css';

type Props = {
  title?: string;
  help?: string;
  container: 'Comment' | 'Task' | 'CommentSearch';
};


const EmptyState: React.FC<Props> = ({ title, help, container}) => (
  <div className={styles.emptyContainer}>
    <div className={styles.empty}>
      {title && <h3 className={styles.emptyHeader}>{title}</h3>}
      {help && <p className={styles.emptyHelp}>{help}</p>}
      {container === 'Task' && <p className={styles.emptyUserTasksImage} />}
      {container === 'Comment' && <p className={styles.emptyUserCommentsImage} />}
    </div>
  </div>
);

export default EmptyState;
