import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { detect } from 'detect-browser';
import {
  ExternalLink as LinkIcon,
  HelpCircle as HelpIcon
} from 'lucide-react';
import browserResources from '../extension_config.json';
import cx from 'classnames';
import styles from './style.module.css';
import sharedStyles from '../shared.module.css';

const browser = detect();
const browserName = browser?.name;

const Installing = props => {
  const browserConfig = () => {
    return browserResources.find(b => b.name === browserName);
  };

  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (props.extensionInstalled) {
      navigate('/extension/success' + location.search);
    }
  });

  const browserCopy = browserConfig().installing;

  return (
    <div className={cx([sharedStyles.container, styles.bgImage])}>
      <div className={sharedStyles.header}>
        <h1>{browserCopy.title}</h1>
        <h2
          className={cx([
            styles.message,
            sharedStyles.info,
            styles.firstMessage
          ])}
        >
          {browserCopy.message}
        </h2>
        <p>
          <b>
            <span className={styles.linkWrapper}>
              <LinkIcon className={styles.linkIcon} />
              <a
                href={browserConfig().installLink}
                className={styles.link}
                target="_blank"
              >
                {browserCopy.linkCopy}
              </a>
            </span>
          </b>
        </p>
        <p>
          <span
            className={styles.linkWrapper}
            data-elevio-article={browserCopy.elevioArticleId}
            data-elevio-style="nothing"
          >
            <HelpIcon className={styles.linkIcon} />
            <a href="#" className={styles.link}>
              Help! How do I install the {browserConfig().display} Extension?
            </a>
          </span>
        </p>
        <hr className={styles.hr} />
        <div>
          <h2>Already installed the extension?</h2>
          <Link to={'/extension/success' + location.search}>
            <Button type="primary">Continue</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

Installing.propTypes = {
  extensionInstalled: PropTypes.bool.isRequired,
  flow: PropTypes.string.isRequired
};

Installing.defaultProps = {
  extensionInstalled: false,
  flow: 'invite'
};

export default Installing;
