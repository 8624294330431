import React, { useState, useEffect, KeyboardEvent } from 'react';
import { Input } from 'antd';
import keyEventToString from 'key-event-to-string';
interface Props {
  disabled: boolean;
  value: string;
  name: string;
  onChange: (value: string) => void;
}
const ShortcutPicker: React.FC<Props> = function(props: Props) {
  const disabled = !!props.disabled;
  const [value, setValue] = useState(props.value);
  const { onChange } = props;
  useEffect(() => {
    setValue(props.value);
  }, [props]);

  const keyDown = (e: KeyboardEvent<Element>) => {
    setValue(keyEventToString({})(e));
    onChange(keyEventToString({})(e));
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Input
      name={props.name}
      onKeyDown={keyDown}
      disabled={disabled}
      value={value}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      allowClear
      placeholder="Enter shortcut"
    />
  );
};

export default ShortcutPicker;
