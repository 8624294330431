import { MutableRefObject } from 'react';

const MENTION_FORMAT_BRACKETS_LENGTH = 4;
type Mention = {
  id: string;
  name: string;
};

export const handleEmojiSelect = ({
  emoji,
  description,
  caretPosition,
  onDescriptionChange,
  setCaretPosition,
  descriptionRef
}: {
  emoji: { native: string };
  description: string;
  caretPosition: number;
  onDescriptionChange: (description: string) => void;
  setCaretPosition: (caretPosition: number) => void;
  descriptionRef: MutableRefObject<HTMLTextAreaElement | null>;
}) => {
  const initialIndex = caretPosition;
  let newIndex = initialIndex;
  const formatted = description.replace(/@\[([^\[]+)\]\(([\d\)]+)\)/g, '@$1');
  let mentions: Mention[] = (
    description.match(/@\[(.*?)\]\((.*?)\)/g) || []
  ).map(mention => ({
    id: mention.match(/\((.*?)\)/)?.[1],
    name: mention.match(/\[(.*?)\]/)?.[1]
  }));

  const isLessThanInitialIndex = (mention: Mention) =>
    formatted.indexOf(mention.name) < initialIndex;
  mentions.forEach(mention => {
    if (isLessThanInitialIndex(mention))
      newIndex = newIndex + MENTION_FORMAT_BRACKETS_LENGTH + mention.id.length;
  });

  const newDescription = `${description.slice(0, newIndex)}${
    emoji.native
  }${description.slice(newIndex)}`;
  onDescriptionChange(newDescription);
  setCaretPosition(newIndex + emoji.native.length);

  if (descriptionRef.current?.click) descriptionRef.current.click();
};
