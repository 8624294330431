import * as React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import { OrganizationUser } from 'models/User';
import { Project } from 'models/Project';
import Avatar from '../../../../clients/shared/Avatar';
import { Space, Tooltip } from 'antd';
import { Eye } from 'lucide-react';
import { kanbanTaskPath } from 'app/routes';
import reactStringReplace from 'react-string-replace';
import { getLangKey } from 'models/Language';
import * as translations from '../strings';
import { toDateTimeString, trimText } from './StringFormating';

type Props = {
  user?: OrganizationUser | null;
  createdAt: string;
  text: string;
  project: Project;
  isMemberOnly: boolean;
  localTaskId: string;
  searchText: string;
};

const showKanbanTask = (projectId: string, localTaskId: string) => {
  window.location.href = kanbanTaskPath({
    projectId: projectId,
    localTaskId: localTaskId
  });
};

const highlightMentionsAndSearch = (
  text: string,
  searchText: string,
  doHighlightMentions: boolean = true
) => {
  const highlightMention = reactStringReplace(
    text,
    /@\[([^\[]+)\]\([\d\)]+\)/g,
    (match, i) => (
      <span className={cx({ [styles.highlightMention]: doHighlightMentions })}>
        {'@' + match}
      </span>
    )
  );

  const highlightSearch = reactStringReplace(
    highlightMention,
    searchText,
    (match, i) => <span className={styles.searchText}>{match}</span>
  );
  return highlightSearch;
};

const getPopupContainer = () =>
  document.getElementById('ReactApp') as HTMLElement;

const projectNameElement = (projectName: string) => (
  <div className={styles.projectName}>
    <Tooltip
      placement="bottomRight"
      title={projectName}
      getPopupContainer={getPopupContainer}
    >
      <span className={styles.backgroundText}>{projectName}</span>
    </Tooltip>
  </div>
);

const showText = (text: string, trimmedText: string, searchText: string) =>
  text.length === trimmedText.length ? (
    <p className={styles.text}>
      {highlightMentionsAndSearch(trimmedText, searchText)}{' '}
    </p>
  ) : (
    <div className={styles.text}>
      <Tooltip
        placement="bottomRight"
        title={highlightMentionsAndSearch(text, searchText, false)}
        getPopupContainer={getPopupContainer}
      >
        <span>{highlightMentionsAndSearch(trimmedText, searchText)}</span>
      </Tooltip>
    </div>
  );

const UserCommentItem: React.FC<Props> = ({
  user,
  createdAt,
  text,
  project,
  isMemberOnly,
  localTaskId,
  searchText
}: Props) => {
  const commentStringCount = 150;

  const strings = translations[getLangKey()];

  const dateTime = new Date(createdAt);
  const trimmedText = trimText(text, commentStringCount);
  const userInfo: any = user
    ? {
        avatar: user.avatarUrl || '',
        name: user.name,
        role: user.role,
        surname: user.surname,
        firstName: user.firstName
      }
    : {
        avatar: '',
        name: strings.anonymous
      };

  return (
    <div
      className={cx(styles.card, {
        [styles.cardBackground]: isMemberOnly
      })}
      onClick={() => showKanbanTask(project.id, localTaskId)}
    >
      <Space direction="vertical">
        <Space className={styles.commenterInfo}>
          <Avatar member={userInfo} size="small" />
          <span>
            {userInfo.name} <span>{' | '}</span> {toDateTimeString(dateTime)}
          </span>
        </Space>

        {showText(text, trimmedText, searchText)}

        {isMemberOnly ? (
          <Space className={styles.footer}>
            {projectNameElement(project.name)}
            <div className={styles.privateCommentFooter}>
              <Eye className={styles.eyeIcon} />
              <span className={styles.visibleTo}>{strings.memberOnly}</span>
            </div>
          </Space>
        ) : (
          projectNameElement(project.name)
        )}
      </Space>
    </div>
  );
};

export default UserCommentItem;
