import React, { FC, useState } from 'react';
import { Modal, Button } from 'antd';
import styles from './styles.module.css';
import { WelcomeContent } from './WelcomeContent';
import { put } from 'appJS/utils/fetch';

type Props = {
  currentUser?: {
    name: string;
    email: string;
    id: string;
  };
  onUpdated: () => void;
  viewedGuestProjectBoard: boolean;
  container: HTMLDivElement;
  loading?: boolean;
};

export const WelcomeModal: FC<Props> = ({
  currentUser,
  onUpdated,
  viewedGuestProjectBoard,
  container,
  loading
}) => {
  const { name, id } = currentUser || {};
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(
    !viewedGuestProjectBoard || !name
  );
  const [userName, setUserName] = useState<string>(name || '');

  const onContinue = () => {
    setShowWelcomeModal(false);
    put(`/users/${id}`, {
      viewed_guest_project_board: true
    });
  };

  return (
    <Modal
      open={showWelcomeModal}
      onCancel={onContinue}
      wrapClassName={styles.welcomeModal}
      closable={false}
      getContainer={container}
      footer={null}
      centered={!!userName}
      maskClosable={!!userName}
      width={!userName ? 485 : 740}
      destroyOnClose
    >
      {!userName ? (
        <WelcomeContent
          currentUser={currentUser}
          loading={!!loading}
          onUpdated={(newName: string) => {
            onUpdated();
            setUserName(newName);
          }}
        />
      ) : (
        <div className={styles.introVideoContainer}>
          <h2 className={styles.introVideoTitle}>
            👋 Welcome to BugHerd, {userName}
          </h2>
          <p className={styles.introVideoText}>
            Watch this 1 minute video to see how BugHerd works.
          </p>
          <iframe
            className={styles.introVideo}
            src="https://player.vimeo.com/video/998510460?h=6b68ca4cd8&title=0&byline=0&portrait=0"
            width="640"
            height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <Button type="primary" onClick={onContinue}>
            Continue
          </Button>
        </div>
      )}
    </Modal>
  );
};
