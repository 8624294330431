import * as React from 'react';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  owner: {
    name: string;
    email: string;
  };
  user: {
    name: string;
  };
};

const EmptyState: React.FC<Props> = ({ owner, user }) => (
  <div className={styles.emptyWrapper}>
    <div className={styles.title}>
      <h1>My Projects</h1>
    </div>
    <h2>
      {`You don't currently have access to any projects. Contact ${owner.name} (
${owner.email}) and ask that they add you to their project.`}
    </h2>
    <a
      href={`mailto:${owner.email}?subject=Please add me to your project on BugHerd&body=Hi ${owner.name},%0D%0A%0D%0AThanks for inviting me to BugHerd. Unfortunately I can't see any projects yet. Can you please give me access to our project?%0D%0A%0D%0AHere's a quick help article on how to do this... https://support.bugherd.com/hc/en-us/articles/360000868696%0D%0A%0D%0AThanks,
%0D%0A${user.name}`}
    >
      <Button type="primary">{`Send ${owner.name} an email`}</Button>
    </a>
  </div>
);

export default EmptyState;
