import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Modal, Tabs } from 'antd';
import attachListenerOnMultipleNodes from 'jsUtilities/domListeners';
import installFooter from './installFooter.png';
import JavascriptTab from './javascript_tab';
import InstructionsTab from './instructions_tab';
import BrowserExtension from './browser_extension';
import WordPressTab from './wordpress_tab';
import { trackPage } from 'utils/analytics';

const { TabPane } = Tabs;

const JAVASCRIPT_TAB = '1';
const SEND_INSTRUCTIONS_TAB = '2';
const WORDPRESS_TAB = '3';
const EXTENSION_TAB = '4';

const InstallBugherd = props => {
  const {
    visibility,
    organizationId,
    projectId,
    bugherdUrl,
    widgetCode,
    container,
    apiKey,
    showExtensionTab
  } = props;
  const projectEndpoint = `${bugherdUrl}/projects/${projectId}`;
  const [visible, setVisible] = useState(visibility);
  const [activeTab, setActiveTab] = useState(JAVASCRIPT_TAB);

  const toggleModal = React.useCallback(() => {
    if (!visible) {
      trackPage('Install project popup', {
        project_id: projectId,
        organization_id: organizationId
      });
    } else {
      window.location.replace(window.location.pathname);
    }
    setVisible(!visible);
  }, [projectId, organizationId, visible]);

  useEffect(() => {
    attachListenerOnMultipleNodes(
      "[data-trigger='install_bugherd']",
      element => {
        element.addEventListener('click', toggleModal);
      }
    );
    return () => {
      attachListenerOnMultipleNodes(
        "[data-trigger='install_bugherd']",
        element => {
          element.removeEventListener('click', toggleModal);
        }
      );
    };
  }, [toggleModal]);

  return (
    <Modal
      top={100}
      className={styles.container}
      open={visible}
      getContainer={() => container}
      footer={null}
      title="Install BugHerd to start capturing website feedback."
      onCancel={toggleModal}
      width={650}
      wrapClassName={styles.modal}
    >
      <div className={styles.modalInner}>
        <Tabs onChange={setActiveTab} activeKey={activeTab} type="card">
          <TabPane tab="Install Javascript code" key={JAVASCRIPT_TAB}>
            <JavascriptTab
              {...{
                widgetCode
              }}
            />
          </TabPane>
          {showExtensionTab && (
            <TabPane tab="Browser Extension" key={EXTENSION_TAB}>
              <BrowserExtension />
            </TabPane>
          )}
          <TabPane tab="WordPress plugin" key={WORDPRESS_TAB}>
            <WordPressTab {...{ apiKey }} />
          </TabPane>
          <TabPane
            tab={
              showExtensionTab
                ? 'Send instructions'
                : 'Send instructions to a team member'
            }
            key={SEND_INSTRUCTIONS_TAB}
          >
            <InstructionsTab
              instructionsEndpoint={`${projectEndpoint}/send_help`}
            />
          </TabPane>
        </Tabs>
        <div className={styles.footer}>
          <img
            className={styles.footerImage}
            src={installFooter}
            alt="installFooter.png"
          />
        </div>
      </div>
    </Modal>
  );
};

InstallBugherd.propTypes = {
  widgetCode: PropTypes.string.isRequired,
  container: PropTypes.instanceOf(Element),
  visibility: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  projectId: PropTypes.number,
  bugherdUrl: PropTypes.string,
  apiKey: PropTypes.string.isRequired,
  showExtensionTab: PropTypes.bool.isRequired
};

export default InstallBugherd;
