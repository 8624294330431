import React, { useState } from 'react';
import cx from 'classnames';
import { getLangKey } from '../../../../javascript/models/Application';
import { Alert, Space, message } from 'antd';
import Bowser from 'bowser';
const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.satisfies({ safari: '>1' });
import { ARCHIVE_STAGE_CONFIRMING, ARCHIVE_STAGE_INITIAL } from './types';

import { Archive } from 'lucide-react';
import { processArchiveBulkRequest } from '../createBulkRequest';
import styles from './index.module.css';
import * as translations from './strings';

const keyCodes = {
  enter: 13
};

interface Props {
  column: {
    id: number;
  };
  goToArchivePage: () => void;
}

const ArchiveAllCard: React.FC<Props> = ({ column, goToArchivePage }) => {
  const [archiveStage, setArchiveStage] = useState(ARCHIVE_STAGE_INITIAL);
  const langKey = getLangKey();
  const strings = translations[langKey];
  const confirming = archiveStage === ARCHIVE_STAGE_CONFIRMING;

  const titleAlert = strings.archiveAll;
  const descriptionAlert = strings.performanceArchive;
  const confirmButton = confirming ? strings.reallyArchive : strings.yes;

  const archiveAllTasks = () => {
    if (archiveStage === ARCHIVE_STAGE_CONFIRMING) {
      const payload: Promise<void> = processArchiveBulkRequest({
        columnId: column.id,
        taskIds: []
      });
      message.info({
        content: (
          <div>
            <b>{strings.archivingNow}</b>
            <br />
            {strings.archivingMoment}{' '}
          </div>
        )
      });
      payload
        .then(() => {
          message.success({
            content: (
              <div>
                {strings.allArchived} <br />
                {strings.goToArchive}{' '}
                <a onClick={goToArchivePage}>{strings.toArchive}</a>
              </div>
            ),
            duration: 5
          });
        })
        .catch(error => {
          console.error(error);
          message.error(strings.createBulkRequestFailure);
        });
    } else {
      setArchiveStage(ARCHIVE_STAGE_CONFIRMING);
    }
  };
  const handleKeyPress = event => {
    if (event.defaultPrevented || event.keyCode !== keyCodes.enter) {
      return;
    }

    event.preventDefault();
    archiveAllTasks();
  };

  return (
    <Alert
      className={cx(styles.card, { [styles.safariCard]: isSafari })}
      type="info"
      action={
        <>
          <p className={styles.alertTitle}>{titleAlert}</p>
          <span>
            <p className={styles.alertDescription}>{descriptionAlert}</p>
          </span>
          <Space className={styles.verticalCenter} direction="vertical">
            <span
              onKeyPress={handleKeyPress}
              tabIndex={0}
              onClick={archiveAllTasks}
              className={styles.alertIcon}
            >
              <span
                className={cx({
                  [styles.alertIconBlue]: true,
                  [styles.alertIconRed]: confirming
                })}
              >
                {confirmButton}
                <Archive className={styles.alertSvg} />
              </span>
            </span>
          </Space>
        </>
      }
    />
  );
};

export default ArchiveAllCard;
