import React, { FC } from 'react';
import styles from './styles.module.css';
import cx from 'classnames';
import { ZoomIn, ZoomOut, Share } from 'lucide-react';
import { Button } from 'antd';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';

import AssetPagination from '../AssetPagination';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

type CurrentPage = number | ((currentPage: number) => number);

type Props = {
  currentPage: number;
  setCurrentPage: (currentPage: CurrentPage) => void;
  totalPages: number;
  container: HTMLDivElement;
  useOriginalSize: boolean;
  onUseOriginalSize: () => void;
  isPdf: boolean;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  onShareClick: () => void;
  fitToPage: boolean;
  onFitToPage: () => void;
};

const Controls: FC<Props> = ({
  currentPage,
  setCurrentPage,
  totalPages,
  container,
  useOriginalSize,
  onUseOriginalSize,
  isPdf,
  handleZoomIn,
  handleZoomOut,
  onShareClick,
  fitToPage,
  onFitToPage
}) => {
  const buttonSize = useMediaQuery('(max-width: 560px)')
    ? 'small'
    : ('default' as SizeType);

  return (
    <div className={styles.controlsContainer}>
      <AssetPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        container={container}
        show={isPdf}
      />
      <Button.Group className={styles.buttonGroup}>
        <Button
          size={buttonSize}
          className={styles.zoomButton}
          icon={<ZoomIn className={styles.zoomIcon} />}
          onClick={handleZoomIn}
        />
        <Button
          size={buttonSize}
          className={styles.zoomButton}
          icon={<ZoomOut className={styles.zoomIcon} />}
          onClick={handleZoomOut}
        />
      </Button.Group>
      <Button
        size={buttonSize}
        className={cx(styles.fitToPage, {
          [styles.activeZoom]: fitToPage
        })}
        onClick={onFitToPage}
      >
        Fit to Page
      </Button>
      <Button
        size={buttonSize}
        className={cx(styles.originalSize, {
          [styles.activeZoom]: useOriginalSize
        })}
        onClick={onUseOriginalSize}
      >
        Original Size
      </Button>
      <Button
        size={buttonSize}
        title="Share this asset URL"
        className={styles.shareButton}
        icon={<Share className={styles.shareIcon} />}
        onClick={onShareClick}
      >
        Share
      </Button>
    </div>
  );
};

export default Controls;
