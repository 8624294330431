const integrationsOptions = {
  All: true,
  'Google Analytics': false,
  'Google Analytics 4': false
};

const trackPage = (name, opts = {}) => {
  // disable page view data from being sent to Google Ads & Analytics
  const integrations = {
    integrations: {
      ...opts.integrations,
      ...integrationsOptions
    }
  };
  if (window.analytics && window.analytics.page) {
    window.analytics.page(name, {}, { ...integrations, ...opts });
  }
};

const trackFacebook = name => {
  const officialFBEvents = ['StartTrial']; // add any of the defautl FB events we use here.
  const interval = setInterval(() => {
    // set interval to try and fire event until window.fbq is defined
    if (window.fbq) {
      if (officialFBEvents.includes(name)) {
        window.fbq('track', name);
      } else {
        window.fbq('trackCustom', name);
      }
      clearInterval(interval);
    }
  }, 100);
};

const trackGA = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export { trackPage, trackFacebook, trackGA };

export default (name, properties = {}, options = {}) => {
  const integrations = {
    integrations: {
      ...options.integrations,
      ...integrationsOptions
    }
  };
  if (window.analytics?.track && window.posthog?.capture) {
    window.analytics.track(name, properties, { ...integrations, ...options });
    window.posthog.capture(name, properties, { ...integrations, ...options });
  }
};

export const ONBOARDING_COMPLETE = 'Onboarding Complete';
export const ONBOARDING_DO_THIS_LATER = 'Onboarding Do this later link clicked';
export const ONBOARDING_USE_EXAMPLE_DATA =
  'Onboarding Use Example Data link clicked';
