import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from '../styles.module.css';

type Props = {
  index: number;
  item: any;
  selected: number;
  active?: boolean;
  onSelect: (index: number) => void;
  icon?: React.ReactNode;
  newClassName: string;
  value: string;
  setSelected: (index: number) => void;
};

export const ShortcutOption: FC<Props> = ({
  index,
  item,
  selected,
  active,
  onSelect,
  icon,
  newClassName,
  value,
  setSelected
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (index === selected && itemRef.current) {
      itemRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [selected]);

  return (
    <div
      key={item.id}
      ref={itemRef}
      onMouseEnter={() => setSelected(index)}
      className={cx(styles.item, styles[newClassName], {
        [styles.selected]: index === selected,
        [styles.active]: active
      })}
      role="option"
      onMouseDown={event => {
        event.preventDefault();
        onSelect(index);
      }}
    >
      {icon}
      <span className={styles.display}>{value}</span>
    </div>
  );
};
