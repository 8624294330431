export const en = {
  additionalInfo: 'ADDITIONAL INFO:',
  showDetails: 'Show details',
  hideDetails: 'Hide details',
  taskLogged: 'Task logged at',
  operatingSystem: 'Operating System',
  browser: 'Browser',
  selector: 'Selector',
  resolution: 'Resolution',
  browserWindow: 'Browser Window',
  colorDepth: 'Color Depth',
  unknown: 'unknown'
};

export type Translations = typeof en;
