import React, { FC } from 'react';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

export const IntroductionScreen: FC = () => {
  return (
    <>
      <div className={styles.introContainerEmpty}>
        <span className={styles.introEmoji}>☝️</span>

        <h2>{strings.whatAreYouLooking}</h2>
      </div>
    </>
  );
};
