import React, { useEffect, useState } from 'react';
import styles from './webhookSettings.module.css';
import { Form, Row, Col, Input, Select, Button, Popover, Tooltip } from 'antd';
import { Trash, X as Close, HelpCircle } from 'lucide-react';
import { post, put, get, deleteItem } from '../../../javascript/utils/fetch';
import track from 'utils/analytics';

const { Option } = Select;

interface Props {
  projectId: string;
  webhooks: any;
  webhooksPath: string;
  container: HTMLElement;
  apiKey: string;
}

const WebhookSettings: React.FC<Props> = (props: Props) => {
  const TASK_CREATE = 'task_create';
  const TASK_UPDATE = 'task_update';
  const TASK_DESTROY = 'task_destroy';
  const COMMENT = 'comment';

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [showNewRow, setShowNewRow] = useState<boolean>(false);
  const [webhooks, setWebhooks] = useState<any>(props.webhooks);
  const [currentlyEditing, setCurrentlyEditing] = useState<string>('');

  const headers = {
    Authorization: `Basic ${btoa(props.apiKey + ':x')}`
  };

  const getWebhooks = async () => {
    const response = await get(props.webhooksPath, { headers: headers });

    return response.webhooks;
  };

  const postWebhook = async (params: any) => {
    const scope =
      params.projectScope === 'this_project' ? props.projectId : null;
    const body = {
      project_id: scope,
      event: params.eventType,
      target_url: params.webhookUrl
    };

    const response = await post(props.webhooksPath, body, { headers: headers });

    return response;
  };

  const deleteWebhook = async (webhookId: any) => {
    const response = await deleteItem(props.webhooksPath + '/' + webhookId, {
      headers: headers
    });

    return response;
  };

  const editWebhook = async (params: any) => {
    const scope =
      params.projectScope === 'this_project' ? props.projectId : null;
    const body = {
      project_id: scope,
      event: params.eventType,
      target_url: params.webhookUrl
    };

    const response = await put(
      props.webhooksPath + '/' + currentlyEditing,
      body,
      { headers: headers }
    );

    return response;
  };

  useEffect(() => {
    getWebhooks().then((results: any) => {
      setWebhooks(results);
    });
  }, []);

  const displayableWebhooksPresent = () => {
    let present = false;

    webhooks.reduce((acc: any, webhook: any) => {
      if (
        webhook.project_id === props.projectId ||
        webhook.project_id === null
      ) {
        present = true;
      }
    }, {});

    return present;
  };

  const handleAddRow = (params: any) => {
    postWebhook(params)
      .then(() => {
        getWebhooks().then(response => setWebhooks(response));
        form.resetFields();
        setShowNewRow(false);
      })
      .then(() => {
        track('Integration connected', {
          integration: 'webhook',
          project_id: props.projectId
        });
      });
  };

  const handleEditRow = (params: any) => {
    editWebhook(params).then(() => {
      getWebhooks().then(response => setWebhooks(response));
      form.resetFields();
      setCurrentlyEditing('');
    });
  };

  const renderWebHookListHeader = () => {
    return (
      <div className={styles.webhookListHeader}>
        <Row>
          <Col span={8}>
            Webhook URL
            <Tooltip
              title={<p>The URL we will POST the notification to.</p>}
              getPopupContainer={() => props.container}
              placement="rightBottom"
              className={styles.headerTooltip}
            >
              <HelpCircle />
            </Tooltip>
          </Col>
          <Col span={6}>
            Event
            <Tooltip
              title={<p>The event you want to be notified about.</p>}
              getPopupContainer={() => props.container}
              placement="rightBottom"
              className={styles.headerTooltip}
            >
              <HelpCircle />
            </Tooltip>
          </Col>
          <Col span={6}>
            Scope
            <Tooltip
              title={
                <p>
                  Get notified when this event occurs on “This project” or “All
                  projects”
                </p>
              }
              getPopupContainer={() => props.container}
              placement="rightBottom"
              className={styles.headerTooltip}
            >
              <HelpCircle />
            </Tooltip>
          </Col>
          <Col span={4} />
        </Row>
      </div>
    );
  };

  const handleDeleteWebhook = (webhookId: any) => {
    deleteWebhook(webhookId).then(() => {
      getWebhooks().then(response => setWebhooks(response));
    });
  };

  const renderWebHookListRow = () => {
    const deletePopoverContent = (webhookId: any) => (
      <div className={styles.deletePopover}>
        <Button
          type="text"
          className={styles.deletePopoverButton}
          icon={<Trash />}
          onClick={() => handleDeleteWebhook(webhookId)}
        >
          Really remove?
        </Button>
      </div>
    );

    return Object.keys(webhooks)
      .sort()
      .map((webhookId, i) => {
        const webhook = webhooks[webhookId];

        if (
          webhook.project_id !== props.projectId &&
          webhook.project_id !== null
        ) {
          return;
        }

        const scope =
          webhook.project_id === props.projectId
            ? 'This Project'
            : 'All Projects';

        if (currentlyEditing === webhook.id) {
          return (
            <Form
              wrapperCol={{ span: 24 }}
              form={editForm}
              name="editForm"
              onFinish={handleEditRow}
              className={styles.webhookEditFormInline}
            >
              <Row className={styles.webhookEditRow}>
                <Col span={8}>
                  <Form.Item
                    name="webhookUrl"
                    rules={[
                      {
                        required: true,
                        message:
                          "Url should begin with 'http://' or 'https://'",
                        pattern: new RegExp(/^https?:\/\//)
                      }
                    ]}
                    style={{ flex: 1 }}
                    initialValue={webhook.target_url}
                  >
                    <Input placeholder="Enter webhook url..." />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="eventType"
                    rules={[{ required: true }]}
                    style={{ flex: 1 }}
                    initialValue={webhook.event}
                  >
                    <Select
                      className={styles.eventSelect}
                      getPopupContainer={() => props.container}
                    >
                      <Option value={TASK_CREATE}>Task Created</Option>
                      <Option value={TASK_UPDATE}>Task Updated</Option>
                      <Option value={TASK_DESTROY}>Task Deleted</Option>
                      <Option value={COMMENT}>Comment Created</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    name="projectScope"
                    rules={[{ required: true }]}
                    style={{ flex: 1 }}
                    initialValue={
                      webhook.project_id ? 'this_project' : 'all_projects'
                    }
                  >
                    <Select getPopupContainer={() => props.container}>
                      <Option value="this_project">This Project</Option>
                      <Option value="all_projects">All Projects</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col className={styles.editControls} span={4}>
                  <Form.Item style={{ flex: 1 }}>
                    <Button
                      shape="circle"
                      icon={<Close />}
                      size="small"
                      className={styles.deleteButton}
                      onClick={() => setCurrentlyEditing('')}
                    />
                    <Button
                      type="primary"
                      shape="round"
                      size="small"
                      htmlType="submit"
                      className={styles.addButton}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          );
        } else {
          return (
            <Row key={webhook.id} className={styles.webhookListRow}>
              <Col span={8}>{webhook.target_url}</Col>
              <Col span={6}>{webhook.event}</Col>
              <Col span={5}>{scope}</Col>
              <Col className={styles.editControls} span={5}>
                <Popover
                  placement="bottomRight"
                  content={deletePopoverContent(webhook.id)}
                  trigger="click"
                  getPopupContainer={() => props.container}
                  className={styles.deletePopoverText}
                >
                  <Button
                    shape="circle"
                    icon={<Close />}
                    size="small"
                    className={styles.deleteButton}
                  />
                </Popover>
                <Button
                  size="small"
                  type="primary"
                  shape="round"
                  className={styles.editButton}
                  onClick={() => setCurrentlyEditing(webhook.id)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          );
        }
      });
  };

  const renderWebHookListInputRow = () => {
    let rowClass: string | null = null;
    if (!displayableWebhooksPresent()) {
      rowClass = styles.noUpperBorder;
    }

    if (showNewRow || !displayableWebhooksPresent()) {
      return (
        <Form
          wrapperCol={{ span: 24 }}
          form={form}
          name="addForm"
          onFinish={handleAddRow}
          className={`${styles.webhookEditForm} ${rowClass}`}
        >
          <Row className={styles.webhookEditRow}>
            <Col span={8}>
              <Form.Item
                name="webhookUrl"
                rules={[
                  {
                    required: true,
                    message: "Url should begin with 'http://' or 'https://'",
                    pattern: new RegExp(/^https?:\/\//)
                  }
                ]}
                style={{ flex: 1 }}
              >
                <Input placeholder="Enter webhook url..." />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name="eventType"
                rules={[{ required: true }]}
                style={{ flex: 1 }}
              >
                <Select
                  className={styles.eventSelect}
                  getPopupContainer={() => props.container}
                >
                  <Option value={TASK_CREATE}>Task Created</Option>
                  <Option value={TASK_UPDATE}>Task Updated</Option>
                  <Option value={TASK_DESTROY}>Task Deleted</Option>
                  <Option value={COMMENT}>Comment Created</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                name="projectScope"
                rules={[{ required: true }]}
                style={{ flex: 1 }}
              >
                <Select getPopupContainer={() => props.container}>
                  <Option value="this_project">This Project</Option>
                  <Option value="all_projects">All Projects</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col className={styles.addControls} span={4}>
              <Form.Item style={{ flex: 1 }}>
                <Button
                  type="primary"
                  shape="round"
                  size="small"
                  htmlType="submit"
                  className={styles.addButton}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }
  };

  const renderWebhookList = () => {
    return (
      <div className={styles.webookListContainer}>
        {renderWebHookListHeader()}
        {renderWebHookListRow()}
        {renderWebHookListInputRow()}
      </div>
    );
  };

  const renderShowNewRowToggle = () => {
    if (displayableWebhooksPresent()) {
      return (
        <p className={styles.addNewWebhook}>
          <a onClick={() => setShowNewRow(prev => !prev)}>
            + Add another webhook
          </a>
        </p>
      );
    }
  };

  return (
    <div className={styles.container}>
      <p>
        Use webhooks to notify other applications when something happens in
        BugHerd. To configure a webhook, simply specify the event(s) you are
        interested in, and the URL you would like to send the notification to.
      </p>

      {renderWebhookList()}
      {renderShowNewRowToggle()}
    </div>
  );
};

export default (props: Props) => <WebhookSettings {...props} />;
