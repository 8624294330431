import * as React from 'react';
import { Alert, Button } from 'antd';

import { getLangKey } from 'models/Language';

import * as translations from './strings';
import styles from './index.module.css';

type Props = {
  show: boolean;
  onRefresh: () => void;
  message: string;
};

const RefreshMessage: React.FC<Props> = ({ show, onRefresh, message }) => {
  const strings = translations[getLangKey()];

  if (!show) {
    return null;
  }

  return (
    <Alert
      className={styles.refreshMessage}
      type="info"
      message={
        <div>
          {message}
          <Button type="link" onClick={onRefresh}>
            {strings.refresh}
          </Button>
        </div>
      }
    />
  );
};

export default RefreshMessage;
