export default function dataURIToBlob(dataURI) {
  var parts = dataURI.split(',');
  var binary = atob(parts[1]);
  var type = parts[0];
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array).buffer], { type: type });
}
