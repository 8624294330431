import React, { FC, useState } from 'react';

import { Button, Input } from 'antd';
import * as translations from './strings';
import { getLangKey } from 'jsModels/Application';

import styles from './tags.module.css';

type Props = {
  tagName: string;
  tagCount: number;
  updateTagName: (tagName: string) => void;
  activeTag: boolean;
  updateTagActive: (tagActive: boolean) => void;
};

const TagListItem: FC<Props> = ({
  tagName,
  tagCount,
  updateTagName,
  activeTag,
  updateTagActive
}) => {
  const strings = translations[getLangKey()];

  const [modifiedName, setModifiedTagName] = useState(tagName);
  const [isEditVisible, setEditVisible] = useState(false);

  return (
    <div key={tagName} className={styles.tagItemContainer}>
      {isEditVisible ? (
        <div className={styles.tagEditForm}>
          <Input
            className={styles.tagEditInput}
            value={modifiedName}
            onChange={event => {
              setModifiedTagName(event.target.value);
            }}
          />
          <div className={styles.tagEditBtns}>
            <Button
              className={styles.tagUpdateBtn}
              type="primary"
              onClick={() => {
                setEditVisible(false);
                updateTagName(modifiedName);
              }}
            >
              {strings.update}
            </Button>
            <Button
              onClick={() => {
                setModifiedTagName(tagName);
                setEditVisible(false);
              }}
            >
              {strings.cancel}
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.tagDisplayOuter}>
          <p className={styles.tagNameDisplay}>
            {tagName} &nbsp;
            <span className={styles.tagCount}>({tagCount})</span>
          </p>
          <div className={styles.actionsOuter}>
            <a
              className={styles.archive}
              onClick={() => updateTagActive(!activeTag)}
            >
              {activeTag ? strings.disable : strings.enable}
            </a>
            <a className={styles.edit} onClick={() => setEditVisible(true)}>
              {strings.edit}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagListItem;
