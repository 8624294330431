import * as React from 'react';

import MultiSelectList from '../../MultiSelectList';
import ErrorRetry from '../../MultiSelectList/ErrorRetry';
import useProjectFilter from './state';

type Props = {
  organizationId: string;
  selectedProject: string[];
  setSelectedProject: (value: string[]) => void;
};

const ProjectFilter: React.FC<Props> = ({
  organizationId,
  selectedProject,
  setSelectedProject
}) => {
  const {
    selectOption,
    fetchNextPage,
    setSearchByName,
    loading,
    error,
    refetch
  } = useProjectFilter(organizationId);

  if (error) return <ErrorRetry onClick={refetch} />;

  return (
    <MultiSelectList
      options={selectOption}
      isLoading={loading}
      onSelectChange={setSelectedProject}
      selectedValue={selectedProject}
      fetchMore={fetchNextPage}
      onSelectSearch={setSearchByName}
    />
  );
};

export default ProjectFilter;
