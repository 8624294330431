export const en = {
  verifyButton: 'Verify',
  verifyAgainButton: 'Verify again',
  verifiedSuccess: 'Verified Successfully',
  verifyCodeTitle: 'Verify your code is installed correctly',
  verfiedSuccessDescription: 'BugHerd is installed on your website! 🎉',
  verifyFailTitle: 'Verification failed',
  installJsTitle: 'Install Javascript code',
  errorMessage: 'Error verifying your site for the script',
  successMessage: 'Successfully verified your site for the script',
  inProgressMessage: 'Checking your site for the script',
  wordPressInfo:
    "Want to manage feedback and bugs on a WordPress website? Use BugHerd's WordPress" +
    ' plugin for the simplest method of installation.',
  installWordPressTitle:
    'Click the button below to download the plugin from WordPress.org or search for ' +
    '"BugHerd" in your WordPress add-on screen.',
  installWordPressButton: 'Install WordPress plugin',
  projectKeyWordPressTitle:
    'Once installed, enter the "Project Key" below on the plugin settings screen in WordPress.',
  wordPressAlert: 'For more info, visit our ',
  wordPressAlertHelpLink: 'help article',
  helpLinkArticleId: '84872'
};
