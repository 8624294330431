import React, { FC, useState } from 'react';
import { BreadCrumbs } from './BreadCrumbs';
import { Dropdown } from 'antd';
import DefaultLogo from 'appJS/assets/images/bugherd-short-logo.svg';
import CloseBtn from 'appJS/assets/images/close-button.svg';
import styles from './index.module.css';
import { Endpoints, User, GetItemArgs, Organization } from './types';
import { leftNavMobile as getItems } from './getItems';
import cx from 'classnames';
import ProjectsList from './projectsList/ProjectsList';
import { OrganizationProject } from '../../../../clients/providers/Organization';

interface Props {
  user: User;
  isMobileView: boolean;
  endpoints: Endpoints;
  container: HTMLDivElement;
  latestProjects: OrganizationProject[];
  disabledProjects: OrganizationProject[];
  multipleOrganizations: boolean;
  toggleMobileMenu: () => void;
  isMobileMenuOpen: boolean;
  organizations: Organization[];
}

export const LeftNav: FC<Props> = Props => {
  const {
    endpoints: { team, settings, newProject, organizationUrl, userActivities },
    user: {
      accessTo: {
        viewTeam,
        billing,
        createProject,
        integrations,
        viewUserActivities
      },
      organization,
      isGuest,
      organizations,
      favourite_projects
    },
    isMobileView,
    container,
    multipleOrganizations,
    latestProjects,
    disabledProjects,
    toggleMobileMenu,
    isMobileMenuOpen
  } = Props;

  const { display_name, custom_logo_light_background } = organization ?? {};

  const customLogoAllow = organization?.['custom_logos_allowed?'];
  const projectFoldersAllowed = organization?.['project_folders_allowed?'];

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const toggleDropdown = () => {
    if (!isMobileView)
      setTimeout(() => {
        setDropdownVisible(!dropdownVisible);
      }, 100);
  };

  const showLogo = () => {
    if (customLogoAllow && custom_logo_light_background) {
      return <img src={custom_logo_light_background} alt={display_name} />;
    }
    return <DefaultLogo />;
  };

  const itemsNav: GetItemArgs[] = getItems({
    organizationUrl,
    userActivities,
    team,
    settings,
    multipleOrganizations,
    createProject,
    newProject,
    latestProjects,
    viewTeam,
    integrations,
    billing,
    isMobileView,
    viewUserActivities,
    isGuest,
    organizationId: organization?.id,
    organizations
  });

  const items = itemsNav.map(item => ({
    ...item,
    onClick: toggleDropdown
  }));

  const getFavouriteProjects = () => {
    try {
      const favourites: string[] =
        JSON.parse(favourite_projects)[organization?.id] || [];
      return favourites.map(projectId => ({
        ...(latestProjects.find(({ id }) => id === Number(projectId)) || {}),
        favourite: true
      }));
    } catch (error) {
      console.error('Error parsing favourite projects', error);
    }
    return [];
  };

  return (
    <div onClick={toggleMobileMenu}>
      <Dropdown
        placement="bottomRight"
        getPopupContainer={() => container}
        disabled={isMobileView || !organization}
        overlayClassName={styles.overlay}
        className={styles.navbarMenu}
        menu={{ items }}
        dropdownRender={menu => (
          <div className={styles.contentStyle}>
            {React.cloneElement(menu, { className: styles.menuStyle })}
            <ProjectsList
              latestProjects={latestProjects}
              organizationUrl={organizationUrl}
              organizationId={organization.id}
              isGuest={isGuest}
              projectFoldersAllowed={projectFoldersAllowed}
              // @ts-expect-error
              favouriteProjects={getFavouriteProjects()}
            />
          </div>
        )}
        open={dropdownVisible}
        trigger={['click']}
        onOpenChange={(open: boolean) => {
          setDropdownVisible(open);
        }}
      >
        <div onClick={e => e.preventDefault()} className={styles.navButton}>
          <div className={styles.logoTop}>
            <div
              className={cx({
                [styles.logoOverlay]: isMobileMenuOpen && isMobileView
              })}
            >
              {showLogo()}
            </div>
            {isMobileMenuOpen && isMobileView && (
              <div className={styles.closeButton}>
                <CloseBtn />
              </div>
            )}
          </div>
          <BreadCrumbs
            organizations={organizations}
            organization={organization}
            isMobileView={isMobileView}
            disabledProjects={disabledProjects}
          />
        </div>
      </Dropdown>
    </div>
  );
};
