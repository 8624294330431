import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Modal, Button } from 'antd';
import Loader from 'components/loader';

import styles from './index.module.css';

const ArchivedList = props => {
  const {
    visible,
    hideModal,
    getContainer,
    bulkRequests,
    undoBulkArchive,
    getDateString,
    loading
  } = props;

  const isListScrollable = bulkRequests.length > 6;

  return (
    <Modal
      title="Recent Bulk archives"
      {...{ visible, getContainer }}
      onCancel={hideModal}
      width={isListScrollable ? '500px' : '480px'}
      wrapClassName={styles.modal}
      footer={null}
    >
      <p className={styles.about}>
        Bulk archive actions can be restored within 24 hours.
      </p>
      <div className={styles.modalContent}>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <ul
            className={cx(styles.archivedList, {
              [styles.scrollable]: isListScrollable
            })}
          >
            {bulkRequests.map(req => (
              <li key={req.id} className={styles.requestOuter}>
                <div className={styles.detailsContainer}>
                  <p className={styles.archivedAt}>
                    Archived at {getDateString(req.createdAt)}
                  </p>
                  <p className={styles.details}>
                    {req.details.success.length} Tasks archived by{' '}
                    {req.userFullName}
                  </p>
                </div>
                <Button
                  className={styles.restoreTasks}
                  type="secondary"
                  onClick={() => undoBulkArchive(req.id)}
                >
                  Restore Tasks
                </Button>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.modalFooter}>
          <Button type="primary" onClick={hideModal}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ArchivedList.propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func,
  getContainer: PropTypes.instanceOf(Element),
  bulkRequests: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.shape({
        success: PropTypes.arrayOf(PropTypes.number)
      }),
      createdAt: PropTypes.string,
      userFullName: PropTypes.string
    })
  ),
  undoBulkArchive: PropTypes.func,
  getDateString: PropTypes.func,
  loading: PropTypes.bool
};

export default ArchivedList;
