import * as React from 'react';

import { Priority } from 'models/Organization';

import MultiSelectCheckbox from '../../MultiSelectCheckbox';

type Props = {
  priorities: Priority[];
  value?: string[];
  onChange: (value: string[]) => void;
};

const PriorityFilter: React.FC<Props> = ({ priorities, value, onChange }) => (
  <MultiSelectCheckbox
    value={value}
    onChange={onChange}
    options={priorities.map(({ id, name }) => ({
      value: id,
      label: name
    }))}
  />
);

export default PriorityFilter;
