import React from 'react';
import PropTypes from 'prop-types';

const TOO_MANY_REDIRECTS = 'too_many_redirects';
const REDIRECT_TO_NOT_MATCH = 'redirect_to_not_match';
const CSP = 'csp';
const BROKEN_PARTS = 'broken_parts';
const NO_SCRIPT = 'no_script';
const SCRIPT_FOR_WRONG_PROJECT = 'script_for_wrong_project';
const TOO_MANY_SCRIPTS = 'too_many_scripts';
const TIMEOUT = 'timeout';
const CONNECTION_ERROR = 'connection_error';
const REQUEST_ERROR = 'request_error';
const AUTHENTICATION_REQUIRED = 'authentication_required';

const ProjectLink = ({ projectUrl }) => (
  <a href={projectUrl} target="_blank" style={{ display: 'inline' }}>
    {projectUrl}
  </a>
);

ProjectLink.propTypes = {
  projectUrl: PropTypes.string
};

const UnknownError = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because of an unknown issue.
    <br />
    Please check your website is online and try again.
  </p>
);

const TooManyRedirects = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because your website has too many
    redirects.
  </p>
);
const RedirectToNotMatch = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because it redirects to a different
    page that won't match the url given here.
  </p>
);

const Csp = ({ projectUrl }) => (
  <p>
    The content security policy (CSP) on your website is blocking BugHerd. To
    enable BugHerd on your website, update your Content Security Policy as
    descibed in{' '}
    <a
      href="https://support.bugherd.com/hc/en-us/articles/360001016396-Why-is-BugHerd-blocked-from-loading-on-my-website-"
      target="_blank"
    >
      this support article
    </a>
  </p>
);

const BrokenParts = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because it contains broken parts of
    BugHerd. <br /> Please remove these html elements.
  </p>
);

const NoScript = ({ projectUrl }) => (
  <p>
    The above Javascript code wasn't detected on{' '}
    <ProjectLink projectUrl={projectUrl} />. <br />
    Please check you have inserted the code into the <code>{`<head>`}</code> of
    your site and try again.
  </p>
);

const ScriptForWrongProject = ({ projectUrl }) => (
  <p>
    We detected JavaScript from another BugHerd project on{' '}
    <ProjectLink projectUrl={projectUrl} />. <br />
    Please replace any other BugHerd JavaScript code on your site with the
    JavaScript code above and try again.
  </p>
);

const TooManyScripts = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because it contains multiple BugHerd
    scripts, please only include the script once.
  </p>
);

const Timeout = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because your website timed out.
    <br />
    Please check that your website is online and working correctly, and try
    again.
  </p>
);

const ConnectionError = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because we couldn’t connect to your
    website. <br />
    Please check that your website is online and working correctly, and try
    again.
  </p>
);

const RequestError = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because your website returned an
    error. <br />
    Please check that your website is online and working correctly, and try
    again.
  </p>
);

const AuthenticationRequired = ({ projectUrl }) => (
  <p>
    The above Javascript code couldn’t be verified on{' '}
    <ProjectLink projectUrl={projectUrl} /> because your website requires a
    username and password to access it. <br />
    Please see{' '}
    <a
      href="https://support.bugherd.com/hc/en-us/articles/360001589935"
      target="_blank"
    >
      this support article
    </a>{' '}
    for help.
  </p>
);

UnknownError.propTypes = { projectUrl: PropTypes.string };
TooManyRedirects.propTypes = { projectUrl: PropTypes.string };
RedirectToNotMatch.propTypes = { projectUrl: PropTypes.string };
Csp.propTypes = { projectUrl: PropTypes.string };
BrokenParts.propTypes = { projectUrl: PropTypes.string };
NoScript.propTypes = { projectUrl: PropTypes.string };
ScriptForWrongProject.propTypes = { projectUrl: PropTypes.string };
TooManyScripts.propTypes = { projectUrl: PropTypes.string };
Timeout.propTypes = { projectUrl: PropTypes.string };
ConnectionError.propTypes = { projectUrl: PropTypes.string };
RequestError.propTypes = { projectUrl: PropTypes.string };
AuthenticationRequired.propTypes = { projectUrl: PropTypes.string };

const errorComponentsMap = {
  [TOO_MANY_REDIRECTS]: TooManyRedirects,
  [REDIRECT_TO_NOT_MATCH]: RedirectToNotMatch,
  [CSP]: CSP,
  [BROKEN_PARTS]: BrokenParts,
  [NO_SCRIPT]: NoScript,
  [SCRIPT_FOR_WRONG_PROJECT]: ScriptForWrongProject,
  [TOO_MANY_SCRIPTS]: TooManyScripts,
  [TIMEOUT]: Timeout,
  [CONNECTION_ERROR]: ConnectionError,
  [REQUEST_ERROR]: RequestError,
  [AUTHENTICATION_REQUIRED]: AuthenticationRequired
};

const VerificationError = ({
  siteErrors,
  projectUrl,
  responseError,
  projectId
}) => {
  if (responseError) {
    return <UnknownError projectUrl={projectUrl} />;
  }

  const lastError =
    (siteErrors || [])
      .filter(error => !error.resolve)
      .filter(
        error => error.project_id === projectId && error.url === projectUrl
      )
      .sort((a, b) => (b.id || 0) - (a.id || 0))[0] || {};

  const component = errorComponentsMap[lastError.error];

  if (component) {
    return component({ projectUrl });
  }

  return <UnknownError projectUrl={projectUrl} />;
};

VerificationError.propTypes = {
  projectUrl: PropTypes.string,
  projectId: PropTypes.number,
  responseError: PropTypes.bool,
  siteErrors: PropTypes.arrayOf(PropTypes.object)
};

export default VerificationError;
