export function matchesFragment({
  siteFragment,
  browserFragment
}: {
  siteFragment: string;
  browserFragment: string;
}): boolean {
  const browserFragmentWithoutHash =
    browserFragment[0] === '#' ? browserFragment.slice(1) : browserFragment;
  return browserFragmentWithoutHash.startsWith(siteFragment);
}

export function matchesTaskFragment({
  taskFragment,
  browserFragment
}: {
  taskFragment: string;
  browserFragment: string;
}): boolean {
  return browserFragment === taskFragment;
}
