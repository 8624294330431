import * as React from 'react';
import { Modal, Steps } from 'antd';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import { OnboardingSteps, ChecklistEnum } from './model';
import styles from './index.module.css';
import TryBugHerd from './TryBugHerd';
import ProjectSetup from './ProjectSetup';
import InstallBugherd from './InstallBugherd';
import UserSurvey from './UserSurvey';
import { getOnboardingState } from './state';
import StepTitle from './StepTitle';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import { SiteError } from 'models/siteError';
import RightPanel from './RightPanel';
import { trackStep } from './analytics';
import cx from 'classnames';

const { Step } = Steps;

interface OnboardingPropsType {
  showOnboarding: boolean;
  projectName: string;
  projectUrl: string;
  isJavascriptInstalled: boolean;
  isWebsiteVisited: boolean;
  isExtensionInstalled: boolean;
  widgetCode: string;
  organizationId: number;
  userId: number;
  projectId: number;
  siteErrors: SiteError[];
  lastSiteCheckAt: string;
  experiments: string[];
  onCloseModal: () => void;
}

const Onboarding: React.FC<OnboardingPropsType> = ({
  showOnboarding,
  projectUrl,
  projectName,
  projectId,
  organizationId,
  userId,
  isJavascriptInstalled,
  isWebsiteVisited,
  isExtensionInstalled,
  widgetCode,
  siteErrors,
  lastSiteCheckAt,
  experiments,
  onCloseModal
}) => {
  const strings = translations[getLangKey()];

  const [visibility, setVisibility] = React.useState<boolean>(showOnboarding);
  const [checkExtensionInstall, setExtensionInstalled] = React.useState<
    boolean
  >(isExtensionInstalled);
  const [currentProjectUrl, setProjectUrl] = React.useState<string>(projectUrl);
  const [currentProjectName, setProjectName] = React.useState<string>(
    projectName
  );
  const [isPersonalized, setPersonalized] = React.useState<boolean>(false);

  const currentState = getOnboardingState({
    name: currentProjectName,
    url: currentProjectUrl,
    isJavascriptInstalled: isJavascriptInstalled,
    isWebsiteVisited: isWebsiteVisited,
    isExtensionInstalled: checkExtensionInstall,
    isPersonalized: isPersonalized
  });

  const [visibleStep, setVisibleStep] = React.useState<ChecklistEnum>(
    currentState
  );

  const [showEmbedJavascript, setShowEmbedJavascript] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists) {
        setExtensionInstalled(exists);
      }
    })();
  }, [isExtensionInstalled]);

  React.useEffect(() => {
    setVisibleStep(currentState);
  }, [
    isExtensionInstalled,
    isJavascriptInstalled,
    checkExtensionInstall,
    isWebsiteVisited,
    currentProjectUrl,
    currentProjectName,
    isPersonalized
  ]);

  React.useEffect(() => {
    trackStep(visibleStep);
  }, [visibleStep]);

  const container = document.getElementById('empty_state_container');

  const hideModal = () => {
    setVisibility(false);
    onCloseModal();
  };

  return (
    <Modal
      className={cx(styles.onbModal, 'onbModal')}
      open={visibility}
      getContainer={container}
      footer={null}
      closable={false}
    >
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <Steps
            className={styles.step}
            size={'small'}
            direction="vertical"
            current={currentState}
          >
            <Step
              key={OnboardingSteps[ChecklistEnum.SETUP_PROJECT].title}
              title={
                <StepTitle
                  title={OnboardingSteps[ChecklistEnum.SETUP_PROJECT].title}
                  visibleStepTitle={
                    OnboardingSteps[ChecklistEnum.SETUP_PROJECT]
                      .visibleStepTitle
                  }
                  activeTitle={strings.setupProjectTitleDone(
                    currentProjectName
                  )}
                  isVisibleStep={visibleStep === ChecklistEnum.SETUP_PROJECT}
                  isActive={currentState >= ChecklistEnum.SETUP_PROJECT}
                  stepOnClick={() =>
                    setVisibleStep(ChecklistEnum.SETUP_PROJECT)
                  }
                />
              }
              description={
                visibleStep === ChecklistEnum.SETUP_PROJECT && (
                  <ProjectSetup
                    currentProjectName={currentProjectName}
                    currentProjectUrl={currentProjectUrl}
                    projectId={projectId}
                    onStepSuccess={(
                      projectName: string,
                      projectUrl: string
                    ) => {
                      setProjectUrl(projectUrl);
                      setProjectName(projectName);
                    }}
                  />
                )
              }
            />
            <Step
              key={OnboardingSteps[ChecklistEnum.INSTALL_BH].title}
              title={
                <StepTitle
                  title={OnboardingSteps[ChecklistEnum.INSTALL_BH].title}
                  visibleStepTitle={
                    OnboardingSteps[ChecklistEnum.INSTALL_BH].visibleStepTitle
                  }
                  isVisibleStep={visibleStep === ChecklistEnum.INSTALL_BH}
                  activeTitle={
                    OnboardingSteps[ChecklistEnum.INSTALL_BH].activeTitle
                  }
                  isActive={currentState >= ChecklistEnum.INSTALL_BH}
                  stepOnClick={() => setVisibleStep(ChecklistEnum.INSTALL_BH)}
                />
              }
              description={
                visibleStep === ChecklistEnum.INSTALL_BH && (
                  <InstallBugherd
                    jsInstalled={isJavascriptInstalled}
                    widgetCode={widgetCode}
                    projectUrl={currentProjectUrl}
                    projectId={projectId}
                    siteErrors={siteErrors}
                    lastSiteCheckAt={lastSiteCheckAt}
                    showEmbedJavascript={showEmbedJavascript}
                    setShowEmbedJavascript={setShowEmbedJavascript}
                  />
                )
              }
            />
            <Step
              key={OnboardingSteps[ChecklistEnum.WEBSITE].title}
              title={
                <StepTitle
                  title={OnboardingSteps[ChecklistEnum.WEBSITE].title}
                  visibleStepTitle={
                    OnboardingSteps[ChecklistEnum.WEBSITE].visibleStepTitle
                  }
                  isVisibleStep={visibleStep === ChecklistEnum.WEBSITE}
                  activeTitle={
                    OnboardingSteps[ChecklistEnum.WEBSITE].activeTitle
                  }
                  isActive={currentState >= ChecklistEnum.WEBSITE}
                  stepOnClick={() => setVisibleStep(ChecklistEnum.WEBSITE)}
                />
              }
              description={
                visibleStep === ChecklistEnum.WEBSITE && (
                  <TryBugHerd
                    projectUrl={currentProjectUrl}
                    projectId={projectId}
                    experiments={experiments}
                  />
                )
              }
            />

            <Step
              key={OnboardingSteps[ChecklistEnum.PERSONALIZE].title}
              title={
                <StepTitle
                  title={OnboardingSteps[ChecklistEnum.PERSONALIZE].title}
                  visibleStepTitle={
                    OnboardingSteps[ChecklistEnum.PERSONALIZE].visibleStepTitle
                  }
                  isVisibleStep={visibleStep === ChecklistEnum.PERSONALIZE}
                  activeTitle={
                    OnboardingSteps[ChecklistEnum.PERSONALIZE].activeTitle
                  }
                  isActive={currentState >= ChecklistEnum.PERSONALIZE}
                  stepOnClick={() => setVisibleStep(ChecklistEnum.PERSONALIZE)}
                />
              }
              description={
                visibleStep === ChecklistEnum.PERSONALIZE && (
                  <UserSurvey
                    organizationId={organizationId}
                    onFinish={() => {
                      setPersonalized(true);
                      hideModal();
                    }}
                  />
                )
              }
            />
          </Steps>
        </div>
        <div className={styles.rightPanel}>
          <RightPanel
            organizationId={organizationId}
            userId={userId}
            step={visibleStep}
            showEmbedJavascript={showEmbedJavascript}
            experiments={experiments}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Onboarding;
