import React, { FC, useEffect } from 'react';
import styles from './styles.module.css';
import { fileLoader } from './fileLoader';

type Props = {
  source: string;
  sourceLoading: boolean;
  setSourceLoading: (loading: boolean) => void;
  txt: { [key: string]: string }[];
  setTxt: (txt: { [key: string]: string }[]) => void;
};

export const TxtPreview: FC<Props> = ({
  source,
  sourceLoading,
  setSourceLoading,
  txt,
  setTxt
}: Props) => {
  const textString = txt.find(textData => textData[source]);

  useEffect(() => {
    if (!textString) {
      fileLoader({
        url: source,
        onLoaded: () => setSourceLoading(false),
        setFileLoader: (text: string) => {
          const txtClone = txt.slice();
          const textData = { [source]: text };
          txtClone.push(textData);
          setTxt(txtClone);
        },
        readerTypeFunction: 'text'
      });
    } else if (textString && sourceLoading) {
      setSourceLoading(false);
    }
  }, [source, textString]);

  if (!textString) return null;

  return <div className={styles.textPreview}>{textString[source]}</div>;
};
