export const en = {
  startYourFreeTrial: 'Start your free trial',
  alreadyHaveAnAccount: 'Already have an account? ',
  or: 'or',
  signUpWithEmail: 'Sign up with email',
  joinExistingProject: 'Join an existing project? ',
  backToWebsite: 'Back to website'
};

export type Translations = typeof en;
