import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode
} from 'react';

declare var paragon: any;

type ProjectParagonIntegration = {
  configuredWorkflows: {
    [key: string]: {
      enabled: boolean;
      settings: {
        [key: string]: boolean;
      };
    };
  };
  enabled: boolean;
  credentials?: string;
  credentialStatus?: string;
  providerData?: {};
  providerId?: string;
  sharedSettings?: {
    [key: string]: string;
  };
};
type AuthenticatedParagonIntegrations =
  | {
      [key: string]: ProjectParagonIntegration;
    }
  | {};

const ParagonIntegrationsContext = createContext({
  hasParagonLoaded: false,
  paragonIntegrations: {},
  setParagonIntegrations: ({}) => {}
});

type ParagonIntegrationsProviderProps = {
  paragonProjectId: string | undefined;
  projectId: string;
  children: ReactNode;
};

const ParagonIntegrationsProvider = ({
  paragonProjectId,
  projectId,
  children
}: ParagonIntegrationsProviderProps) => {
  const [hasParagonLoaded, setHasParagonLoaded] = useState<boolean>(false);
  const [paragonIntegrations, setParagonIntegrations] = useState<
    AuthenticatedParagonIntegrations
  >({});

  const getParagonJWT = async () => {
    const response = await fetch(`/projects/${projectId}/generate_jwt`);
    return response.json();
  };

  const authenticateParagon = async () => {
    const paragonJWT = await getParagonJWT();
    return paragon.authenticate(paragonProjectId, paragonJWT.jwt);
  };

  useEffect(() => {
    if (!hasParagonLoaded) return;
    if (!paragonProjectId) {
      console.error(
        'paragon_project_id must be set in config/initializers/01_settings.rb'
      );
      return;
    }
    // We need to force a reauth here if auth is false OR if the project IDs don't match. This happens when the user switches projects.
    // Since paragon loads on the window, it won't automatically reauth on load and it won't reauth if the project changes.
    localStorage.removeItem('paragon-connect-user-state');
    authenticateParagon().then(() => {
      const { authenticated, integrations } = paragon.getUser();
      if (authenticated) {
        setParagonIntegrations(integrations);
      }
    });
  }, [hasParagonLoaded]);

  useEffect(() => {
    const MAX_TRIES = 50;
    let tries = 0;
    const checkParagonInterval = setInterval(() => {
      if (tries >= MAX_TRIES) return clearInterval(checkParagonInterval);
      // @ts-expect-error
      if (window.paragon) {
        clearInterval(checkParagonInterval);
        setHasParagonLoaded(true);
      }
      tries++;
    }, 200);

    return () => clearInterval(checkParagonInterval);
  }, []);

  useEffect(() => {
    if (!hasParagonLoaded) return;
    // If we have long running sessions the user will become unauthed and our integrations list will be null. After we've forced a reauth
    // we need to repopulate the paragonIntegrations state.
    if (Object.keys(paragonIntegrations || {}).length === 0) {
      const { authenticated, integrations } = paragon.getUser();
      if (authenticated) {
        setParagonIntegrations(integrations);
      }
    }
  });

  return (
    <ParagonIntegrationsContext.Provider
      value={{ hasParagonLoaded, paragonIntegrations, setParagonIntegrations }}
    >
      {children}
    </ParagonIntegrationsContext.Provider>
  );
};

const useParagonIntegrationsState = () => {
  const context = useContext(ParagonIntegrationsContext);
  if (context === undefined) {
    throw new Error(
      'useParagonIntegrations must be used within a IntegrationsProvider'
    );
  }
  return context;
};

export { ParagonIntegrationsProvider, useParagonIntegrationsState };
