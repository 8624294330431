import React from 'react';

import { ProjectSite } from 'models/Project';
import { stripUrlProtocol } from 'utils/projectSettingsOperations';

import { Project } from '../types';

export function getProjectSites({
  devurl,
  hostnames,
  sites
}: Project): ProjectSite[] {
  if (sites.length > 0) return sites;

  const legacyUrl = (devurl || '').trim();

  const devurlHostname = stripUrlProtocol(legacyUrl);

  const hostnameUrls = hostnames
    .map(hostname => hostname.trim())
    .filter(
      hostname =>
        hostname !== '' &&
        hostname !== devurlHostname &&
        `www.${hostname}` !== devurlHostname
    )
    .map(hostname => `https://${hostname}`);

  const legacyUrls =
    legacyUrl !== '' ? [legacyUrl, ...hostnameUrls] : hostnameUrls;

  return legacyUrls.map((url, index) => ({ id: '', url }));
}

const useProjectSites = (
  project: Project
): [ProjectSite[], (sites: ProjectSite[]) => void] =>
  React.useState<ProjectSite[]>([...getProjectSites(project)]);

export default useProjectSites;
