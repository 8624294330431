export const en = {
  publicFeedbackDescription: 'Public Feedback allows any user to give you feedback about your site. ',
  installEmbedCode: 'Install the BugHerd embed code to enable Public Feedback. ',
  getTheEmbedCode: 'Get the embed code',
  here: 'here',
  save: 'Save',
  cancel: 'Cancel',
  somethingWentWrong: 'Something went wrong, please try again',
  notFound: 'Not found',
  publicFeedbackIsNow: 'Public Feedback is now ',
  enabled: 'enabled',
  disabled: 'disabled',
  enabledCaps: 'Enabled',
  disabledCaps: 'Disabled',
  forThisProject: 'for this project',

};

export type Translations = typeof en;
