import { v4 as uuid } from 'uuid';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';

export type FileType = {
  lastModified?: number;
  lastModifiedDate?: Date;
  name: string;
  originFileObj?: any;
  percent?: number;
  size?: number;
  status?: UploadFileStatus;
  type?: string;
  uid: string;
  url?: string;
  initialized?: boolean;
  taskId?: number;
  createdAt?: string;
};

export type AttachedFile = {
  id?: number;
  name: string;
  url?: string;
  createdAt?: string;
  percent?: number;
  status?: 'uploading' | 'done';
  uid?: string;
  taskId?: number;
};

export const getFileInfo = (
  file: UploadFile<File>,
  taskId?: number
): FileType => {
  const { lastModified, lastModifiedDate, name, size, type } = file;
  return {
    lastModified,
    lastModifiedDate,
    name,
    percent: 0,
    size,
    type,
    status: 'uploading',
    uid: uuid().toString(),
    originFileObj: file,
    initialized: false,
    createdAt: new Date().toISOString(),
    taskId
  };
};

export const getFileListCopy = (list: FileType[] | AttachedFile[]) =>
  JSON.parse(JSON.stringify(list));

export const getAttachedFileInfo = (
  file: UploadFile<File>,
  taskId?: number
): AttachedFile => ({
  name: file.name,
  createdAt: new Date().toISOString(),
  percent: 0,
  status: 'uploading',
  uid: file.uid,
  taskId
});
