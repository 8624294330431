import React from 'react';

import styles from './index.module.css';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
import magicLinkImage from './images/magic-link-image.png';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
const strings = translations[getLangKey()];

export const EmailSent = () => {
  return (
    <Space
      size={20}
      direction={'vertical'}
      className={styles.emailSentContainer}
    >
      <h1 className={styles.title}>{strings.checkYourEmail}</h1>
      <p>{strings.loginLinkDescription}</p>
      <img
        height={190}
        width={190}
        src={magicLinkImage}
        alt="Person looking at computer"
      />
      <div>
        <p>{strings.cantAccessYourEmail}</p>
        <p>
          <Link to="/sign_in">{strings.logInWithPassword}</Link>
        </p>
      </div>
    </Space>
  );
};
