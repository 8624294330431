import React from 'react';
import PropTypes from 'prop-types';
import Invite from '../../../components/invite';
import UserList from '../../../components/userList';
import EditAccess from '../../../components/user/editAccess';
import { Progress } from 'antd';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

export default class Team extends React.Component {
  static propTypes = {
    authenticityToken: PropTypes.string,
    commit: PropTypes.string,
    container: PropTypes.func,
    filter: PropTypes.string,
    membersMax: PropTypes.number,
    orgId: PropTypes.number,
    parentSetState: PropTypes.func,
    project: PropTypes.bool,
    presetRole: PropTypes.string,
    responseError: PropTypes.string,
    role: PropTypes.string,
    saving: PropTypes.bool,
    searchTerm: PropTypes.string,
    sendInvite: PropTypes.func,
    successful: PropTypes.string,
    updateUser: PropTypes.func,
    users: PropTypes.array,
    validation: PropTypes.bool,
    projects: PropTypes.array,
    access_to: PropTypes.array,
    user: PropTypes.object,
    organizations: PropTypes.array
  };

  state = {
    role: 'member',
    showAddedUserAccessModal: undefined,
  };

  getAccessTo = () => {
    return this.props.access_to.find(
      obj => obj.organization_id === this.props.orgId
    );
  };

  getCurrentOrganization = () =>
    this.props.organizations.find(
      org => org.organization.id === this.props.orgId
    );

  handleInvitedUser = _user => {
    const user = Object.assign({}, _user, {
      on_projects: []
    });

    window.settings_props.organizations
      .find(org => org.organization.id === this.props.orgId)
      .users.push(user);

    this.setState({
      showAddedUserAccessModal: user
    });
  };

  handleRemovedUser = id => {
    const currentOrg = this.getCurrentOrganization();
    const index = currentOrg.users.findIndex(user => user.id === id);

    window.settings_props.organizations
      .find(org => org.organization.id === this.props.orgId)
      .users.splice(index, 1);
  };

  handleUpdateUser = user => {
    Object.assign(
      window.settings_props.organizations
        .find(org => org.organization.id === this.props.orgId)
        .users.find(_user => _user.id === user.id),
      user
    );
  };

  clearField = (field, ref) => {
    ref.focus();
    this.setState({
      [field]: null
    });
  };

  parentSetState = obj => {
    this.setState(obj);
  };

  getUsers = () => this.getCurrentOrganization().users;

  getCurrentUser = () =>
    this.getCurrentOrganization().users.find(
      user => user.id === this.props.user.id
    );

  getMembersCount = () =>
    this.getUsers().filter(
      user => user.role !== 'guest' && user.role !== 'collaborator'
    ).length;

  getBillingCTA = seatsLeft => {
    if (seatsLeft > 2 || !this.getAccessTo().billing) {
      return null;
    }

    return (
      <span>
        . {strings.needMore}
        <a href={`/organizations/${this.props.orgId}/subscription`}>
          {strings.upgradeHere}
        </a>
        .
      </span>
    );
  };

  getMemberLimitMessage = () => {
    const organization = this.getCurrentOrganization().organization;
    const hasUnlimited = organization.max_members === null;
    return (
      <div className={styles.feedbackBox}>
        <p className={styles.membersFeedback}>
        {strings.youAreCurrentlyUsing}
          <strong className={styles.memberCount}>
            {` ${this.getMembersCount()} member${
              this.getMembersCount() !== 1 ? 's' : ''
            } `}
          </strong>
          {!hasUnlimited && (
            <span className={styles.memberLimitInfo}>
              {strings.outOf}
              <strong className={styles.memberCount}>
                {organization.max_members + ' total'}
              </strong>
              {this.getBillingCTA(
                organization.max_members - this.getMembersCount()
              )}
            </span>
          )}
        </p>

        {!hasUnlimited && (
          <Progress
            className={styles.progressBar}
            percent={Math.round(
              (this.getMembersCount() / organization.max_members) * 100
            )}
            showInfo={false}
            strokeColor="#1CB8E3"
          />
        )}
      </div>
    );
  };

  setPopOverToShow = () => {
    return;
  };

  render() {
    const { authenticityToken, container } = this.props;
    const organization = this.getCurrentOrganization().organization;
    const projects = this.getCurrentOrganization().projects;
    const users = this.getUsers();

    return (
      <div className={styles.manageAccessContainer}>
        <h3>{strings.teamMembers}</h3>
        {this.getMemberLimitMessage()}
        <Invite
          authenticityToken={authenticityToken}
          container={container}
          endpoint={{
            sendEmail: this.getCurrentOrganization().endpoints.addMembers,
            fetchUsers: null
          }}
          membersCount={this.getMembersCount()}
          membersMax={organization.max_members}
          parentSetState={this.parentSetState}
          role={this.state.role}
          success={` has been invited to ${organization.name}`}
          onInvitedUser={this.handleInvitedUser}
          users={users}
          collaboratorsEnabled={this.getAccessTo().collaborators}
          billingRights={this.getAccessTo().billing}
          orgId={this.props.orgId}
        />
        <h3 className={styles.tabHeader}>{strings.members}</h3>
        <p className={styles.accessLabel}>
          {strings.followingPeople}
        </p>
        <UserList
          authenticityToken={authenticityToken}
          clearField={this.clearField}
          container={container}
          responseError="Something went wrong. Please try again."
          endpoints={this.getCurrentOrganization().endpoints}
          onRemovedUser={this.handleRemovedUser}
          projects={projects}
          success={{
            remove: ` has been removed from ${organization.name}`,
            role: ` role has been updated for ${organization.name}`,
            resend: ` has been resent an invite to join ${organization.name}`
          }}
          users={users}
          collaboratorsEnabled={this.getAccessTo().collaborators}
          canChangeOwner={this.getCurrentOrganization().plan != null}
          hasAdminRights={this.getAccessTo().admin}
          onUpdateUser={this.handleUpdateUser}
          user={this.getCurrentUser()}
        />

        {this.state.showAddedUserAccessModal && (
          <EditAccess
            container={container}
            projects={projects}
            member={this.state.showAddedUserAccessModal}
            show
            endpoint={this.getCurrentOrganization().endpoints.updateUserProject}
            toggleModal={() => {
              this.setState({
                showAddedUserAccessModal: undefined
              });
            }}
            responseError="Something went wrong. Please try again."
            authenticityToken={authenticityToken}
            onUpdateUser={this.handleUpdateUser}
          />
        )}
      </div>
    );
  }
}
