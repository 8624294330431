import React from 'react';
import cx from 'classnames';
import { Archive } from 'lucide-react';

import { getLangKey } from 'jsModels/Application';

import styles from './index.module.css';
import * as translations from './strings';
import {
  ArchiveAllProps,
  ARCHIVE_STAGE_CONFIRMING,
  ARCHIVE_TASK_LIMIT
} from './types';

const ArchiveAllComponent = ({ stage, taskCount }) => {
  const confirming = stage === ARCHIVE_STAGE_CONFIRMING;
  const strings = translations[getLangKey()];
  const archiveAll = taskCount <= ARCHIVE_TASK_LIMIT;

  const label = confirming
    ? strings.reallyArchive
    : strings.archiveTasks({ all: archiveAll, size: ARCHIVE_TASK_LIMIT });

  return (
    <div className={styles.actionBox}>
      <Archive
        className={cx({
          [styles.actionIcon]: true,
          [styles.confirmArchive]: confirming
        })}
      />
      <span
        className={cx({
          [styles.actionText]: true,
          [styles.confirmArchive]: confirming
        })}
      >
        {label}
      </span>
    </div>
  );
};

ArchiveAllComponent.propTypes = ArchiveAllProps;

export default ArchiveAllComponent;
