import * as React from 'react';
import { Input } from 'antd';
import styles from './index.module.css';
import FilterContext from '../../UserTasks/filterContext';
import cx from 'classnames';

type Props = {
  setDescriptionSearch: (value: string) => void;
  descriptionSearch?: string;
};

const MAX_COUNT = 256;
const DescriptionSearch: React.FC<Props> = ({
  setDescriptionSearch,
  descriptionSearch
}) => {
  const filterRef = React.useContext(FilterContext);

  const handleOnFocus = () => {
    if (filterRef && filterRef.current && window.innerWidth < 430) {
      filterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div
      className={cx(styles.inputOuter, {
        [styles.hasFilter]: !!descriptionSearch?.length
      })}
    >
      <Input
        className={styles.description}
        value={descriptionSearch}
        onChange={event => setDescriptionSearch(event.target.value)}
        maxLength={MAX_COUNT}
        onFocus={handleOnFocus}
      ></Input>
      <span className={styles.characterCount}>
        {descriptionSearch?.length || 0} / {MAX_COUNT}
      </span>
    </div>
  );
};

export default DescriptionSearch;
