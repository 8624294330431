import React, { FC, useState } from 'react';
import { Menu, Button, Alert, message } from 'antd';
import { Plus } from 'lucide-react';
import styles from './styles.module.css';
import { getNavItems } from './navItems';
import { CloseModal } from 'appJS/components/CloseModal';
import { post } from 'appJS/utils/fetch';

export type Folder = {
  id: string;
  subfolders: { id: string; name: string }[];
  name: string;
};

type Props = {
  folders: Folder[];
  canCreateProject: boolean;
  activeKey: string;
  setActiveKey: (key: string) => void;
  toggleAddFolderModal: () => void;
  manageProjectFolders: boolean;
  seenProjectFolderVideo: boolean;
  createdProjectFolder: boolean;
  favouriteProjects: string[];
};

export const ProjectFolders: FC<Props> = ({
  folders,
  canCreateProject,
  activeKey,
  setActiveKey,
  toggleAddFolderModal,
  seenProjectFolderVideo,
  createdProjectFolder,
  manageProjectFolders,
  favouriteProjects
}) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(!seenProjectFolderVideo);

  const items = getNavItems({
    canCreateProject,
    folders,
    activeKey,
    manageProjectFolders,
    seenProjectFolderVideo,
    createdProjectFolder
  });

  const handleSeenProjectFolderVideo = async () => {
    setShowAlert(false);
    const event = 'Seen project folder video';
    try {
      post('/actions/event', { event });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {canCreateProject && (
        <Button
          data-cypress-trigger="newProjectButton"
          className={styles.newProjectButton}
          type="primary"
          size="large"
          icon={<Plus className={styles.newProjectIcon} />}
          onClick={() => {
            const currentURL = new URL(window.location.href);
            currentURL.pathname += '/new';
            window.location.href = currentURL.href;
          }}
        >
          New Project
        </Button>
      )}
      <Menu
        mode="inline"
        theme="light"
        className={styles.menu}
        openKeys={openKeys}
        onSelect={({ key }) => {
          if (key === 'new-folder') {
            toggleAddFolderModal();
          } else if (key === 'favourites' && !favouriteProjects.length) {
            message.warning(
              "You don't have any favourite projects, look for the ⭐️ on the project card to add some.",
              4
            );
            setActiveKey('all-projects');
          } else if (key !== 'new-project') {
            setActiveKey(key);
          }
        }}
        selectedKeys={[]}
        onOpenChange={_openKeys => {
          const justOpenedKey = _openKeys.find(key => !openKeys.includes(key));
          const justClosedKey = openKeys.find(key => !_openKeys.includes(key));
          if (justOpenedKey) {
            setActiveKey(justOpenedKey);
            setOpenKeys(_openKeys);
          } else if (justClosedKey) {
            if (
              activeKey !== justClosedKey &&
              justClosedKey.includes(activeKey)
            ) {
              setActiveKey(justClosedKey);
              setOpenKeys([justClosedKey]);
            } else {
              setOpenKeys(_openKeys);
              setActiveKey('all-projects');
            }
          }
        }}
        items={items}
      />
      {showAlert && (
        <Alert
          type="info"
          className={styles.alert}
          message={
            <div className={styles.alertContent}>
              <CloseModal
                className={styles.alertCloseIcon}
                handleClick={handleSeenProjectFolderVideo}
              />
              <iframe
                className={styles.videoPreview}
                src="https://player.vimeo.com/video/1036597542?h=6b68ca4cd8&title=0&byline=0&portrait=0"
                width="402"
                height="226"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Project Folders and Favourites"
              />
              <p className={styles.infoMessage}>
                <strong>NEW</strong> Organise your Projects with Folders and
                Favourites!
                <Button
                  type="link"
                  size="small"
                  onClick={() => {}}
                  className={styles.watchButton}
                >
                  Watch how it works
                </Button>
              </p>
            </div>
          }
        />
      )}
    </>
  );
};
