
import React from 'react';

export type ContainerType = HTMLElement | null;

const ContainerContext = React.createContext<ContainerType>(null);

export const ContainerProvider = ContainerContext.Provider;

export default ContainerContext;
