export const en = {
  filterBy: 'Filter by',
  feedback: {
    all: 'All feedback',
    assets: 'Design feedback',
    websites: 'Website feedback',
    mentioned: 'Mentioned',
    unread: 'Unread comments'
  },
  sortBy: 'Sort by',
  sorting: {
    position: 'manually',
    priority_id: 'severity',
    due_at: 'due date',
    created_at: 'time created',
    assignee_ids: 'assigned to',
    updated_at: 'last modified'
  },
  orderBy: 'Order by',
  order: {
    ascending: 'ascending',
    descending: 'descending'
  }
};

export type Translations = typeof en;
