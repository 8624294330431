import React from 'react';
import styles from './index.module.css';

export default () => (
  <iframe
    className={styles.iframe}
    src="https://bugherd.com/embed"
    title="Embedded content from bugherd.com"
  />
);
