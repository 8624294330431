export const en = {
  youve: "You've ",
  discovered: 'discovered',
  premiumFeature: ' a premium feature',
  upgradePlan: 'Upgrade plan',
  contactOwner: 'Contact owner',
  contactSupport: 'Contact support'
};

export type Translations = typeof en;
