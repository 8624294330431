export const en = {
  learnMore: 'Learn more',
  sidebarVersion: 'Sidebar version',
  whichVersion:
    'Select which version of the BugHerd sidebar to display for this project.',
  newMessage: `BugHerd for the modern web. Mobile first, clean & simple UI. Use the shortcut (Ctrl + Cmd + B for Mac or Ctrl + Alt + B for a Windows device) to reveal the BugHerd sidebar.`,
  newHeader: 'NEW',
  legacyMessage: 'The original BugHerd sidebar experience.',
  legacyHeader: 'LEGACY',
  publicFeedbackHeader: 'Public Feedback',
  publicFeedbackMessage: `Show the public "Send Feedback" tab on your website, when users aren't logged into BugHerd.`,
  shortcutHeader: 'Shortcut',
  shorcutMessage:
    'Create your own shortcut to show the BugHerd sidebar (default is Ctrl + Cmd + B or Ctrl + Alt + B for Windows users).',
  verticalPositionHeader: 'Vertical Position',
  verticalPositionMessage:
    'How far the BugHerd tab sits from the top of your website.',
  save: 'Save',
  cancel: 'Cancel'
};

export type Translations = typeof en;
