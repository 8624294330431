import * as React from 'react';
import PropTypes from 'prop-types';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const mapping = {
  small: 16,
  default: 24,
  large: 36
};

export default class Loader extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(Object.keys(mapping)).isRequired,
    delay: PropTypes.number.isRequired,
    spinning: PropTypes.bool.isRequired,
    children: PropTypes.node
  };

  static defaultProps = {
    size: 'default',
    delay: 0,
    spinning: true
  };

  render() {
    return (
      <Spin
        spinning={this.props.spinning}
        delay={this.props.delay}
        indicator={
          <Loading3QuartersOutlined
            style={{ fontSize: mapping[this.props.size] }}
            spin
          />
        }
      >
        {this.props.children}
      </Spin>
    );
  }
}
