import { useEffect } from 'react';
import { Tag, User, Severity } from '../CreateTask/create_task/types';
import { priorities } from '../CreateTask/create_task/right_panel/severity';

type Props = {
  availableTags?: Tag[];
  onTagsChange?: (tags: Tag[]) => void;
  assignableUsers?: User[];
  onAssigneesChange?: (assignees: number[]) => void;
  onSeverityChange?: (severity: Severity) => void;
};

const useConfigDefaultsInCreateTask = ({
  availableTags,
  onTagsChange,
  assignableUsers,
  onAssigneesChange,
  onSeverityChange
}: Props) => {
  const config = window?.BugHerdConfig;

  if (!config) return;

  const { tags, assignees, severity } = config;

  const initialiseDefaultTags = () => {
    if (availableTags?.length) {
      const presetTags = availableTags.reduce((result: Tag[], value: Tag) => {
        if (tags?.find?.((configTag: string) => configTag === value.display))
          result.push(value);
        return result;
      }, []);
      onTagsChange?.(presetTags);
    }
  };

  const initialiseDefaultAssignees = () => {
    if (assignableUsers?.length) {
      const presetAssignees = assignableUsers.reduce(
        (result: number[], value: User) => {
          if (assignees?.find?.((assignee: string) => assignee === value.email))
            result.push(value.id);
          return result;
        },
        []
      );
      onAssigneesChange?.(presetAssignees);
    }
  };

  const initialiseDefaultSeverity = () => {
    if (severity) {
      const presetSeverity = priorities.find(({ name }) => name === severity);
      if (presetSeverity) onSeverityChange?.(presetSeverity.id);
    }
  };

  useEffect(() => {
    initialiseDefaultTags();
    initialiseDefaultAssignees();
    initialiseDefaultSeverity();
  }, []);
};

export default useConfigDefaultsInCreateTask;
