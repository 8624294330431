export const en = {
  save: 'Save',
  cancel: 'Cancel',
  ignoreListHeader: 'Querystring Ignore list',
  ignoreListMessage:
    'Sometimes querystring parameters can confuse BugHerd when displaying pins. List any parameters here that BugHerd should ignore in URLs (one per line).',
  ignoreListRuleMessage: 'Please ensure the parameters are formatted correctly'
};

export type Translations = typeof en;
