import { useState } from 'react';

const useDescriptionState = () => {
  const [escaped, setEscaped] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [undoLoading, setUndoLoading] = useState<boolean>(false);
  const [showUndo, setShowUndo] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [tempDescription, setTempDescription] = useState<string>('');
  const [previousDescriptions, setPreviousDescriptions] = useState<string[]>(
    []
  );

  return {
    escaped,
    setEscaped,
    editing,
    setEditing,
    undoLoading,
    setUndoLoading,
    showUndo,
    setShowUndo,
    showMore,
    setShowMore,
    tempDescription,
    setTempDescription,
    previousDescriptions,
    setPreviousDescriptions
  };
};

export default useDescriptionState;
