import React, { useEffect, FC, useRef } from 'react';
import { RotateCcw } from 'lucide-react';
import { Button, Input, message } from 'antd';
import HighlightedMentions from '../../../../../../../javascript/components/HighlightedMentions';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import useDescriptionState from '../../../hooks/useDescriptionState';

const strings = translations[getLangKey()];

import cx from 'classnames';
import styles from './styles.module.css';
import { User } from '../../../../models/User';

type Props = {
  taskId: number;
  description: string;
  canEditDescription: boolean;
  updateDescription: (description: string) => void;
  users?: User[];
  usersNotOnProject?: User[];
  isExpandedView: boolean;
  assignGuestsAllowed: boolean;
  assignGuests: boolean;
};

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Description: FC<Props> = ({
  taskId,
  description,
  canEditDescription,
  updateDescription,
  users,
  usersNotOnProject,
  isExpandedView,
  assignGuestsAllowed,
  assignGuests
}) => {
  const {
    escaped,
    setEscaped,
    editing,
    setEditing,
    undoLoading,
    setUndoLoading,
    showUndo,
    setShowUndo,
    showMore,
    setShowMore,
    tempDescription,
    setTempDescription,
    previousDescriptions,
    setPreviousDescriptions
  } = useDescriptionState();

  const previousDescription = usePrevious(description);
  const previousTaskId = usePrevious(taskId);

  const handleStorageOfPreviousDescriptions = () => {
    const previousClone = previousDescriptions.length
      ? previousDescriptions.slice()
      : [];

    setUndoLoading(false);
    const current: undefined | string = previousDescription;
    const next = description;
    if (taskId !== previousTaskId) {
      setEditing(false);
      setTempDescription('');
      setPreviousDescriptions([]);
    } else if (current !== next) {
      if (!previousDescriptions.length && current) {
        setPreviousDescriptions([current]);
      } else if (previousDescriptions[0] === next) {
        previousClone.shift();
        setPreviousDescriptions(previousClone.length ? previousClone : []);
      } else if (current) {
        previousClone.unshift(current);
        setPreviousDescriptions(previousClone);
      }
    }
  };

  useEffect(() => {
    handleStorageOfPreviousDescriptions();
  }, [taskId, description]);

  useEffect(() => {
    setShowUndo(!!previousDescriptions.length);
  }, [previousDescriptions, setShowUndo]);

  const handleUndoDescription = (event: any) => {
    event.stopPropagation();
    setTempDescription(previousDescriptions[0]);
    setUndoLoading(true);
    updateDescription(previousDescriptions[0]);
  };

  const focusInputField = () => {
    setEditing(true);
    setTempDescription(description);
  };

  const toggleDescriptionEdit = (event: any) => {
    const tempLength = tempDescription.trim().length;

    if (
      event.key === 'Escape' ||
      event.type === 'blur' ||
      (event.key === 'Enter' && event.metaKey)
    ) {
      setEditing(false);
    }

    if (!canEditDescription) return;

    if (tempDescription !== description) {
      if (event.key === 'Escape') {
        updateDescription(description);
        setEscaped(true);
        event.target.blur();
      } else if (event.type === 'blur' && escaped) {
        setEscaped(false);
      } else if (event.type === 'blur' && !escaped) {
        if (tempLength) {
          updateDescription(tempDescription);
        } else {
          setTempDescription(description);
          event.target.blur();
          return message.error(strings.descriptionBlank);
        }
      } else if (event.key === 'Enter' && event.metaKey) {
        if (tempLength) {
          updateDescription(tempDescription);
        } else {
          setTempDescription(description);
          event.target.blur();
        }
        setEscaped(true);
      }
    } else if (
      (event.key === 'Enter' && event.metaKey) ||
      event.key === 'Escape'
    ) {
      event.target.blur();
    }
  };

  const currentDescription: string = editing ? tempDescription : description;

  const highlightedMentionsProps = {};

  const mapToHighlightedUserType = ({ id, email, name }: User) => ({
    id,
    email,
    name
  });

  if (users?.length && usersNotOnProject?.length) {
    Object.assign(highlightedMentionsProps, {
      projectMembers: users
        .filter(({ role }: User) => role !== 'guest')
        .map(mapToHighlightedUserType),
      projectUsers: users.map(mapToHighlightedUserType),
      allUsers: users.concat(usersNotOnProject).map(mapToHighlightedUserType)
    });
  }

  const handleDescriptionClick = event => {
    if (!canEditDescription) {
      return;
    }

    const textSelection = document.getSelection()?.toString();

    if (textSelection && textSelection.length) {
      navigator.clipboard.writeText(textSelection);
      message.success('Text copied to clipboard');

      return;
    }

    focusInputField();
  };

  return !editing || !canEditDescription ? (
    <>
      <div
        className={cx(styles.descriptionDisplay, {
          [styles.uneditable]: !canEditDescription,
          [styles.descriptionExpanded]: showMore,
          [styles.viewIsExpanded]: isExpandedView
        })}
        onClick={handleDescriptionClick}
        onScroll={event => event.stopPropagation()}
        data-cypress-trigger="taskDescription"
      >
        <HighlightedMentions
          description={description}
          className={styles.mentionText}
          {...highlightedMentionsProps}
          includeGuests={assignGuestsAllowed && assignGuests}
        />
      </div>
      {(showUndo || currentDescription.length > 320) && (
        <div className={cx(styles.descriptionActions)}>
          {showUndo && (
            <Button
              icon={<RotateCcw className={styles.undoIcon} />}
              loading={undoLoading}
              className={styles.undoButton}
              onClick={undoLoading ? () => {} : handleUndoDescription}
              title={strings.undoChanges}
              type="link"
            >
              Undo
            </Button>
          )}
          {currentDescription.length > 235 && !isExpandedView && (
            <div className={styles.readMoreOuter}>
              <Button
                loading={undoLoading}
                className={styles.readMoreButton}
                onClick={() => setShowMore(!showMore)}
                title="Read more..."
                type="link"
              >
                {!showMore ? strings.show : strings.hide}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <Input.TextArea
      className={cx(styles.description, styles.editable, {
        [styles.maxCountReached]: currentDescription.length === 2000,
        [styles.editedIsExpanded]: isExpandedView
      })}
      value={currentDescription}
      onChange={event => setTempDescription(event.target.value)}
      onBlur={toggleDescriptionEdit}
      onKeyDown={toggleDescriptionEdit}
      autoSize={{ minRows: 6, maxRows: 70 }}
      autoFocus
      showCount
      maxLength={2000}
      onScroll={event => event.stopPropagation()}
    />
  );
};

export default Description;
