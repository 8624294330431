import React, { FC, useState } from 'react';
import { Input, Button } from 'antd';
import styles from '../styles.module.css';
import { put } from 'appJS/utils/fetch';

type Props = {
  currentUser?: {
    email: string;
    id: string;
  };
  loading: boolean;
  onUpdated: (newName: string) => void;
};

export const WelcomeContent: FC<Props> = ({
  currentUser,
  loading,
  onUpdated
}) => {
  const [userName, setUserName] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const { email, id } = currentUser || {};

  const handleUpdateName = async () => {
    setSaving(true);
    const response = await put(`/users/${id}`, {
      name: userName
    });
    if (response.message === 'user updated') {
      setSaving(false);
      onUpdated(userName);
    }
  };

  return (
    <div className={styles.welcomeModalContainer}>
      <h2 className={styles.welcomeModalTitle}>Welcome to BugHerd</h2>
      <p className={styles.welcomeModalText}>
        Add your info to let the team know who's giving feedback.
      </p>
      <div className={styles.userDetails}>
        <div className={styles.inputsContainer}>
          <label>First name *</label>
          <Input
            type="text"
            value={userName}
            onChange={event => setUserName(event.target.value)}
            className={styles.userNameInput}
          />
          <label>Email *</label>
          <Input type="email" value={email} disabled />
        </div>
        <Button
          type="primary"
          onClick={handleUpdateName}
          disabled={!userName.trim()}
          loading={saving || loading}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
