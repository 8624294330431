import get from 'lodash/get';
import ipaddr from 'ipaddr.js';

import checkHealth from './checkHealth';
import { updateTaskWithScreenshotError } from '../api/TaskUpdateApi';
import {
  regnerateScreenshotFromServer,
  sendScreenshotRequest
} from './sendScreenshotRequest';
import { SCREENSHOT_SERVER_UNAVAILIBLE } from '../models/ScreenshotCaptureError';
import { post } from 'appJS/utils/fetch';
import { getBugsnag } from '../models/Application';

export const isPrivateUrl = screenshotUrl => {
  try {
    const url = new URL(screenshotUrl);
    if (url.hostname === 'localhost') {
      return true;
    }

    // not an ip address, not private
    if (!ipaddr.isValid(url.hostname)) {
      return false;
    }

    const range = ipaddr.parse(url.hostname).range();

    return range === 'loopback' || range === 'private';
  } catch (e) {
    console.log(`${screenshotUrl} ${e.message}`);
  }

  return false;
};

type RequestScreenshotDataParams = {
  bugherdUrl: string;
  projectId: number;
  taskId: number;
  polyfillData: unknown;
};

const sendScreenshotData = async ({
  bugherdUrl,
  projectId,
  taskId,
  polyfillData
}: RequestScreenshotDataParams) => {
  try {
    await post(
      `${bugherdUrl}/projects/${projectId}/tasks/${taskId}/screenshot_data`,
      { screenshot_data: polyfillData }
    );
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      getBugsnag().notify('Error uploading screenshot data due to' + e.message);
    } else {
      getBugsnag().notify(
        'Error uploading screenshot data due to unknown error' + e
      );
    }
  }
};

type ScreenshotData = {
  taskId: number;
  polyfillData: unknown;
  s3Response: unknown;
  screenshotServerUrl: string;
  projectId: number;
  bugherdUrl: string;
  rescueScreenshotsEnabled?: boolean;
  organizationId: number;
  userId?: number;
};
export const requestScreenshot = async (
  screenshotData: ScreenshotData
): Promise<void> => {
  const {
    taskId,
    polyfillData,
    s3Response,
    screenshotServerUrl,
    projectId,
    bugherdUrl,
    rescueScreenshotsEnabled,
    organizationId,
    userId
  } = screenshotData;
  const screenshotUrl = get(polyfillData, 'properties.url');

  if (isPrivateUrl(screenshotUrl)) {
    console.log(`[BH] Cannot take screenshot on a private url`);
    return;
  }

  if (rescueScreenshotsEnabled) {
    const isRetry = polyfillData.requestType === 'retry';
    const { basicAuthUrl, basicAuthPresent } = polyfillData;

    if (isRetry) {
      return regnerateScreenshotFromServer({
        screenshotServerUrl,
        projectId,
        bugherdUrl,
        taskId,
        s3Response,
        organizationId,
        userId,
        basicAuthUrl,
        basicAuthPresent
      });
    } else {
      // Fire and forget so we don't hold up the task creation
      sendScreenshotData({
        bugherdUrl,
        projectId,
        taskId,
        polyfillData
      }).catch(e => console.error('unknown error', e));
    }
  }

  return checkHealth(screenshotServerUrl)
    .then(() =>
      sendScreenshotRequest({
        polyfillData,
        s3Response,
        taskId,
        screenshotServerUrl,
        projectId,
        bugherdUrl,
        userId
      })
    )
    .catch(() => {
      delete polyfillData.basicAuthUrl;
      delete polyfillData.basicAuthPresent;

      updateTaskWithScreenshotError(
        taskId,
        SCREENSHOT_SERVER_UNAVAILIBLE,
        bugherdUrl,
        projectId,
        polyfillData
      );
    });
};
