export const en = {
  notificationPreferences: 'Notification Preferences',
  newTaskCreated: 'New Task Created',
  receiveEmail: 'Receive an email immediately when a new task is created.',
  taskUpdates: 'Task Updates',
  taskAssignedToYou: 'Task assigned to you',
  commentOnATaskReported: 'Comment on a task you reported',
  commentOnATaskAssigned: 'Comment on a task assigned to you',
  receiveEmails: 'Receive emails ',
  instantly: 'instantly',
  whenThere: "when there's a",
  commentOnATask: 'Comment on a task you have commented on',
  commentOnAnUnassignedTask:
    "Comment on an un-assigned task (if you're a manager of a project)",
  taskFollowingCompleted: `Task you're following has been completed`,
  projectUpdates: 'Project Updates',
  aSummary:
    'A summary of what has happened on the project since your last update ',
  seeAn: 'see an ',
  exampleHere: 'example here',
  updateWillInclude: 'The update will include...',
  newTasksCreated: 'New tasks created',
  comments: 'Comments',
  tasksMovedToDone: 'Tasks moved to done',
  tasksClosed: 'Tasks closed',
  sendProjectUpdatesToMe: 'Send project updates to me:',
  never: 'Never',
  hourly: 'Hourly',
  daily: 'Daily',
  weekly: 'Weekly',
  subscribeToBugherd: 'Subscribe to BugHerd Mailing List',
  subscribeDescription:
    "Every now & then we send emails regarding feature updates and other interesting stuff related to BugHerd. Don't worry, we don't send many… we've got better things to do and we figure you have more interesting reading to do. ;)",
  save: 'Save',
  notificationSettingsSaved: 'Notification settings saved',
  somethingWentWrong: 'Something went wrong, please try again'
};

export type Translations = typeof en;
