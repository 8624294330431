import React, { FC } from 'react';
import styles from './index.module.css';
import GreenTick from './images/ic-green-tick.svg';
import { integrationsInfo } from './integrationsInfo';
import { MemberRole } from '../../../../clients/sidebar/models/CurrentUserProject';
import { IntegrationActions } from './integrationActions';
import { IntegrationIcon } from './integrationIcon';

type Props = {
  paragonAllowed: boolean;
  isTrialUser: boolean;
  msTeamsEnabled: boolean;
  teamworkEnabled: boolean;
  isParagonIntegration: boolean;
  integrationList: {
    [key: string]: {
      enabled: boolean;
      loaded?: boolean | null;
    };
  };
  name: string;
  handleShowIntegration: (name: string, paragonIntegration: boolean) => void;
  handleShowUpgrade: (name: string) => void;
  role?: MemberRole;
  editingAllowed: boolean;
  setPopOverToShow: (integrationName: string) => void;
  popOverToShow: string;
  ownerEmail: string;
  bugherdUrl: string;
  organizationId: string;
  setShowPreview: (showPreview: string) => void;
};

export const premiumIntegrations = [
  'jira',
  'asana',
  'azuredevops',
  'linear',
  'clickup',
  'monday.com'
];

export const IntegrationItem: FC<Props> = ({
  name,
  paragonAllowed,
  isTrialUser,
  msTeamsEnabled,
  teamworkEnabled,
  isParagonIntegration,
  integrationList,
  handleShowIntegration,
  handleShowUpgrade,
  role,
  editingAllowed,
  setPopOverToShow,
  popOverToShow,
  ownerEmail,
  bugherdUrl,
  organizationId,
  setShowPreview
}) => {
  const isPremiumIntegration = premiumIntegrations.includes(name);
  if (
    (name === 'teams' && !msTeamsEnabled) ||
    (name === 'teamwork' && !teamworkEnabled)
  )
    return null;

  const { loaded, enabled } = integrationList[name];

  const isButtonEnabled = () => {
    if (!role) return false;
    if (role === MemberRole.MEMBER) return isParagonIntegration;
    return (
      editingAllowed && !!(loaded || loaded === undefined || loaded === null)
    );
  };

  const integrationNameParsed = name.startsWith('custom.')
    ? name.charAt(7).toUpperCase() + name.slice(8)
    : name.charAt(0).toUpperCase() + name.slice(1);

  const integrationName = name.replace(/\./g, '');
  const integrationInfo = integrationsInfo[integrationName] || {};
  const { link, icon, description, label, video, articleId } = integrationInfo;
  const isConnectedButton = enabled || label === 'Webhooks';

  const integrationsActions = (mobileView?: boolean) => (
    <IntegrationActions
      mobileView={mobileView}
      name={name}
      link={link}
      articleId={articleId}
      video={video}
      isConnectedButton={isConnectedButton}
      isPremiumIntegration={isPremiumIntegration}
      integrationName={integrationName}
      enabled={enabled}
      buttonDisabled={!isButtonEnabled()}
      onButtonClick={() => {
        !isPremiumIntegration || isTrialUser || paragonAllowed
          ? handleShowIntegration(name, isParagonIntegration)
          : handleShowUpgrade(name);
      }}
      isTrialUser={isTrialUser}
      paragonAllowed={paragonAllowed}
      setPopOverToShow={setPopOverToShow}
      popOverToShow={popOverToShow}
      ownerEmail={ownerEmail}
      bugherdUrl={bugherdUrl}
      organizationId={organizationId}
      setShowPreview={setShowPreview}
    />
  );

  return (
    <li
      id={'integration-' + name}
      className={styles.bulletPoint}
      key={integrationNameParsed}
    >
      <div className={styles.integrationNameAndIcon}>
        <IntegrationIcon
          name={name}
          icon={icon}
          integrationName={integrationName}
        />
        <div className={styles.integrationNameAndDescription}>
          <div className={styles.labelAndConnected}>
            <IntegrationIcon
              mobileView
              name={name}
              icon={icon}
              integrationName={integrationName}
            />
            <h3>{label}</h3>
            {enabled && label !== 'Webhooks' && (
              <span className={styles.connectedTag}>
                <GreenTick className={styles.connectedTick} />
                Connected
              </span>
            )}
          </div>
          <p className={styles.integrationDescription}>{description}</p>
          {integrationsActions(true)}
        </div>
      </div>
      {integrationsActions()}
    </li>
  );
};
