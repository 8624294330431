export const en = {
  save: 'Save',
  cancel: 'Cancel',
  feedbackUrlNote:
    'BugHerd will appear on the URL(s) listed here. Guests are directed to the first URL when giving feedback.',
  removeUrl: 'Remove url',
  oneUrlMessage: 'Cannot remove, need at least one url',
  confirmRemove: 'Really remove?',
  saveBeforeRemove: 'Please save at least one url, before removing this.',
  projectUrlsHelp: 'Help Guide: Learn how to setup your website URL',
  urlsHelpId: '33865',
  wereMovingWebsites: `We're moving how you manage websites to the 'Websites & Files' screen.`,
  checkItOut: 'CHECK IT OUT'
};

export type Translations = typeof en;
