import React from 'react';
import styles from './index.module.css';
import { getInstallItems } from './items';
import { Tabs } from 'antd';
import { TabItems } from './types';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

export const InstallBugherd = ({
  project,
  accessTo: guestsSeeGuestsAccess,
  bugherdUrl,
  user,
  projectInit,
  refetch
}: TabItems) => {
  const items = getInstallItems({
    project: project,
    bugherdUrl: bugherdUrl,
    accessTo: { guestsSeeGuestsAccess },
    user,
    refetch,
    projectInit
  });

  return (
    <div className={styles.formWrapper}>
      <h2 className={styles.projectSettingsTitle}>
        {strings.installBugherdTitle}
      </h2>
      <Tabs type="card" items={items} />
    </div>
  );
};

export default InstallBugherd;
