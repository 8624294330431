import * as React from 'react';
import { Row, Col } from 'antd';
import { message } from 'antd';
import { Navigate } from 'react-router-dom';

import Error from 'components/Error';
import RefreshMessage from 'components/RefreshMessage';
import UserPageLayout from 'components/UserPageLayout';
import { getLangKey } from 'models/Language';
import { useCurrentOrganizationIdParam } from 'models/Organization';

import * as translations from './strings';
import Loader from './Loader';
import UserComments from './UserComments';
import UserTasks from './UserTasks';
import styles from './index.module.css';
import { useGetCurrentUserQuery } from './queries';

const UserActivities = () => {
  const strings = translations[getLangKey()];
  const organizationId = useCurrentOrganizationIdParam();

  const [showRefresh, setShowRefresh] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const { data, loading, error } = useGetCurrentUserQuery({ organizationId });

  if (loading) return <Loader />;

  if (error) {
    message.error(strings.refreshPageErrorMessage);
    return <Error error={error} />;
  }

  if (!data || !data.currentUser.accessTo.viewUserActivities) {
    return <Navigate replace to="/" />;
  }

  return (
    <UserPageLayout>
      <div className={styles.layoutContent}>
        <RefreshMessage
          show={showRefresh}
          message={strings.refreshPageMessage}
          onRefresh={() => {
            setRefresh(!refresh);
            setShowRefresh(false);
          }}
        />

        <Row gutter={[{ xs: 0, md: 8, lg: 16, xl: 32 }, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={18}>
            <UserTasks
              organization={data.currentUser.organization}
              onPusherUpdate={() => setShowRefresh(true)}
              refresh={refresh}
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={6}>
            <UserComments
              organizationId={organizationId}
              onPusherUpdate={() => setShowRefresh(true)}
              refresh={refresh}
            />
          </Col>
        </Row>
        <Row className={styles.comments}>
          <Col xs={24} sm={24} md={24} lg={24} xl={0}>
            <UserComments
              organizationId={organizationId}
              onPusherUpdate={() => setShowRefresh(true)}
              refresh={refresh}
            />
          </Col>
        </Row>
      </div>
    </UserPageLayout>
  );
};

export default UserActivities;
