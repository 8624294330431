import React, { FC, useState, useEffect } from 'react';
import { Space, Button, Dropdown, Input, message, Spin, Modal } from 'antd';
import { Edit, MoreHorizontal, Trash2 } from 'lucide-react';
import styles from './index.module.css';
import cx from 'classnames';
import { deleteItem, put } from 'appJS/utils/fetch';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

type Props = {
  folder?: {
    id: string;
    name: string;
  };
  activeKey: string;
  manageProjectFolders: boolean;
  organizationId: string;
  refetchProjects: () => void;
  refecthCurrentUser: () => void;
  container: HTMLDivElement;
};

export const ProjectHeader: FC<Props> = ({
  folder,
  activeKey,
  manageProjectFolders,
  organizationId,
  refetchProjects,
  refecthCurrentUser,
  container
}) => {
  const [editFolderName, setEditFolderName] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>(folder?.name || '');

  const setLoaded = (success?: boolean) => {
    setTimeout(() => setLoading(false), 1000);
    if (success)
      setTimeout(
        () => message.success('Project folder updated successfully'),
        2000
      );
  };

  const header = () => {
    switch (activeKey) {
      case 'all-projects':
        return strings.myProjects;
      case 'favourites':
        return activeKey;
      default:
        return folder?.name;
    }
  };

  const handleDeleteFolder = async () => {
    if (!folder) return;
    try {
      await deleteItem(
        `/organizations/${organizationId}/project_folders/${folder.id}`
      );
      refetchProjects();
      message.success('Project folder deleted successfully');
    } catch (error) {
      console.error(error);
      message.error('Project folder did not delete, please try again');
    }
  };

  const handleFolderMenuClick = async ({ key }: any) => {
    if (key === 'edit') {
      setEditFolderName(true);
    } else if (key === 'delete') {
      Modal.confirm({
        title: 'Are you sure you want to delete this folder?',
        onOk: handleDeleteFolder
      });
    }
  };

  const onUpdateSuccess = () => {
    refecthCurrentUser();
    refetchProjects();
    setLoaded(true);
  };

  const handleSaveFolderName = async () => {
    setLoading(true);
    try {
      const response = await put(
        `/organizations/${organizationId}/project_folders/${folder?.id}`,
        {
          name: folderName
        }
      );
      if (response.message.includes('updated')) onUpdateSuccess();
    } catch (error) {
      console.error(error);
      message.error('Project folder did not update, please try again');
      setLoaded();
    }
    setLoaded();
  };

  const handleFolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(e.target.value);
  };

  useEffect(() => {
    if (folder) {
      setFolderName(folder.name);
    }
  }, [folder]);

  useEffect(() => {
    if (!loading) {
      setEditFolderName(false);
    }
  }, [loading]);

  return (
    <div className={styles.headerContainer}>
      {editFolderName ? (
        <Space.Compact className={styles.folderNameInputWrapper}>
          <Input
            className={styles.folderNameInput}
            value={folderName}
            autoFocus
            onChange={handleFolderNameChange}
            onPressEnter={handleSaveFolderName}
            suffix={
              loading ? (
                <Spin
                  delay={500}
                  size="small"
                  wrapperClassName={styles.spinner}
                />
              ) : (
                undefined
              )
            }
          />
          <Button type="primary" onClick={handleSaveFolderName}>
            Save
          </Button>
          <Button
            onClick={() => {
              setFolderName(folder?.name || '');
              setEditFolderName(false);
            }}
          >
            Cancel
          </Button>
        </Space.Compact>
      ) : (
        <h2
          className={cx(styles.myProject, {
            [styles.favourites]: activeKey === 'favourites'
          })}
        >
          {header()}
        </h2>
      )}
      {manageProjectFolders &&
        activeKey !== 'all-projects' &&
        activeKey !== 'favourites' && (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'edit',
                  icon: <Edit className={styles.editIcon} />,
                  label: 'Edit folder name',
                  className: styles.manageFolderMenuItem
                },
                {
                  key: 'delete',
                  icon: <Trash2 className={styles.deleteIcon} />,
                  label: 'Delete folder',
                  danger: true,
                  className: styles.manageFolderMenuItem
                }
              ],
              onClick: handleFolderMenuClick
            }}
            getPopupContainer={() => container as HTMLDivElement}
            overlayClassName={styles.sortOverlay}
            placement="bottom"
            className={styles.manageFolderDropdown}
          >
            <Button
              icon={<MoreHorizontal className={styles.moreIcon} />}
              type="default"
              className={styles.manageFolderDropdownButton}
            >
              Manage Folder
            </Button>
          </Dropdown>
        )}
    </div>
  );
};
