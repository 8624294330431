import takeScreenshot, {
  extensionExists as _extensionExists
} from './extensionScreenshot';
import {
  hideBeforeScreenshot,
  afterScreenshot as _afterScreenshot
} from './prePostScreenshot';
import getScreenshotPolyfill from './screenshotPolyfill';
import { generateCoordinates as generatePinCoordinates } from '../screenshotPinning';
import { handleScreenshot as updateTaskScreenshot } from '../screenshotTaskUpdate';

import { getBugherd } from '../models/Application';
import { SCREENSHOT_NOT_SUPPORTED } from '../models/ScreenshotCaptureError';
import PropTypes from 'prop-types';

export const captureScreen = async ({
  screenShotServerAccess,
  beforeScreenshot = hideBeforeScreenshot,
  afterScreenshot = _afterScreenshot,
  requestScreenshotDataURI = getBugherd().requestScreenshotDataURI,
  screenshotPolyfill = getScreenshotPolyfill,
  extensionExists = _extensionExists,
  legacy = false
}) => {
  await beforeScreenshot();
  const screenshotPolyfillData = await screenshotPolyfill();
  if (await extensionExists()) {
    const details = await takeScreenshot(
      requestScreenshotDataURI,
      afterScreenshot,
      legacy
    ).catch(failure => {
      if (screenShotServerAccess) {
        return screenshotPolyfill();
      }
      afterScreenshot();
      return { error: failure, ...screenshotPolyfillData };
    });
    return Promise.resolve({ ...details, ...screenshotPolyfillData });
  }
  if (screenShotServerAccess) {
    return screenshotPolyfill();
  }
  afterScreenshot();
  return Promise.resolve({
    error: SCREENSHOT_NOT_SUPPORTED,
    ...screenshotPolyfillData
  });
};

captureScreen.proptype = {
  screenShotServerAccess: PropTypes.bool.isRequired
};

export { generatePinCoordinates, updateTaskScreenshot };
