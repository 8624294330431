import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'api/apollo/client';

import UserActivities from 'views/user_activities';
import UserProjects from 'views/user_projects';

import * as routes from './routes';
import { App, ConfigProvider } from 'antd';
import { antdTheme } from '../../clients/shared/theme';

type Props = {
  container: HTMLDivElement;
};

const DashboardRouter = ({ container }: Props) => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={() => {
              window.location.href = '/';
              return null;
            }}
          />
          <Route
            path={routes.userActivitiesPath({
              organizationId: ':organizationId'
            })}
            element={<UserActivities />}
          />
          <Route
            path={routes.userProjectsPath({
              organizationId: ':organizationId'
            })}
            element={<UserProjects container={container} />}
          />
        </Routes>
      </Router>
    </ApolloProvider>
  );
};

const Dashboard = (props: Props) => {
  return (
    <ConfigProvider theme={antdTheme}>
      <App>
        <DashboardRouter {...props} />
      </App>
    </ConfigProvider>
  );
};

export default Dashboard;
