import React from 'react';
import PropTypes from 'prop-types';
import { Paperclip as Attach, X as Remove } from 'lucide-react';

import styles from './index.module.css';
import cx from 'classnames';

const FileUploading = props => {
  return (
    <div className={styles.container}>
      <Attach className={styles.attachIcon} />
      <div className={styles.listInfoBox}>
        <div className={styles.nameAndPreviewBox}>
          <div className={styles.fileNameBox}>
            <span title={props.file.name} className={styles.fileName}>
              {props.file.name}
            </span>
          </div>
          {props.file.url && (
            <span className={styles.linkContainer}>
              (
              <a
                href={props.file.url}
                rel="noreferrer"
                target="_blank"
                className={styles.preview}
              >
                view
              </a>
              )
            </span>
          )}
        </div>
        {props.onRemoveFile && (
          <Remove
            title="Remove file"
            className={styles.removeIcon}
            onClick={() => props.onRemoveFile()}
          />
        )}
        <div
          className={cx({
            [styles.outerProgress]: true,
            [styles.completed]:
              props.file.percent === 100 || props.file.status === 'done'
          })}
        >
          <div
            style={{ width: `${props.file.percent}%` }}
            className={cx({
              [styles.completing]: props.file.percent >= 85,
              [styles.innerProgress]: true
            })}
          />
        </div>
      </div>
    </div>
  );
};

FileUploading.propTypes = {
  file: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string
  }),
  onRemoveFile: PropTypes.func
};
export default FileUploading;
