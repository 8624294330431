import { gql } from '@apollo/client';
import { ProjectSite } from 'models/Project';

export interface CreateProjectSiteResponse {
  project: {
    id: string;
    feedbackUrl: string;
  };
  projectSite: ProjectSite;
}

export interface CreateProjectSiteDetails {
  projectId: string;
  url: string;
}

const CREATE_PROJECT_SITE = gql`
  mutation CreateProjectSite($input: CreateProjectSiteInput!) {
    createProjectSite(input: $input) {
      project {
        __typename
        id
        feedbackUrl
      }
      projectSite {
        __typename
        id
        url
      }
    }
  }
`;

export interface DeleteProjectSiteResponse {
  project: {
    id: string;
    feedbackUrl?: string;
  };
  projectSite: {
    id: string;
  };
}

export interface DeleteProjectSiteDetails {
  projectSiteId: string;
}

const DELETE_PROJECT_SITE = gql`
  mutation DeleteProjectSite($input: DeleteProjectSiteInput!) {
    deleteProjectSite(input: $input) {
      project {
        __typename
        id
        feedbackUrl
      }
      projectSite {
        __typename
        id
      }
    }
  }
`;

export interface UpdateProjectSiteResponse {
  project: {
    id: string;
    feedbackUrl: string;
  };
  projectSite: ProjectSite;
}

export interface UpdateProjectSiteDetails {
  projectSiteId: string;
  url: string;
}

const UPDATE_PROJECT_SITE = gql`
  mutation UpdateProjectSite($input: UpdateProjectSiteInput!) {
    updateProjectSite(input: $input) {
      project {
        __typename
        id
        feedbackUrl
      }
      projectSite {
        __typename
        id
        url
      }
    }
  }
`;

export { UPDATE_PROJECT_SITE, DELETE_PROJECT_SITE, CREATE_PROJECT_SITE };
