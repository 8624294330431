import React, { FC, useState } from 'react';
import { Popover, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as translations from '../strings';
import { getLangKey } from '../../../../../models/Language';
import styles from './styles.module.css';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLDivElement;
  taskHost: string;
  showSite: () => void;
  hideSite: () => void;
  canManageProject: boolean;
  isAdminView?: boolean;
};

const AlertPopover: FC<Props> = ({
  container,
  taskHost,
  showSite,
  hideSite,
  canManageProject,
  isAdminView
}) => {
  const [show, setShow] = useState<boolean>(false);

  const onOk = () => {
    setShow(false);
    showSite();
  };

  const onCancel = () => {
    setShow(false);
    hideSite();
  };

  const canEditProjectUrl: boolean = canManageProject;

  const getPopoverContent = () => {
    const host = (
      <a key="taskHost" title={taskHost}>
        {strings.taskHost}
      </a>
    );
    const projectSettings = isAdminView ? (
      <a
        key="projectSettings"
        onClick={() => {
          setShow(false);
          // @ts-ignore
          window.project_settings_props.setShow(true);
        }}
      >
        {strings.projectSettings}
      </a>
    ) : (
      (strings.projectSettings as string)
    );

    return strings.alertPopoverContent(host, projectSettings);
  };

  return (
    <Popover
      overlayClassName={styles.hostPopover}
      open={show}
      onOpenChange={visible => setShow(visible)}
      placement="bottomLeft"
      trigger={['hover', 'click']}
      title={null}
      getPopupContainer={() => container}
      content={
        <div>
          <p className={styles.hostPopoverContent}>{getPopoverContent()}</p>
          <div className={styles.labelPopoverActionsOuter}>
            <Button
              type="primary"
              size="small"
              onClick={canEditProjectUrl ? onOk : () => {}}
              className={styles.popoverOk}
            >
              {strings.okButton}
            </Button>
            <Button
              type="text"
              size="small"
              onClick={onCancel}
              className={styles.popoverCancel}
            >
              {strings.cancelButton}
            </Button>
          </div>
        </div>
      }
    >
      <ExclamationCircleFilled
        style={{ color: '#faad14', marginRight: '6px' }}
        onClick={() => setShow(true)}
        className={styles.alertPopoverIcon}
      />
    </Popover>
  );
};

export default AlertPopover;
