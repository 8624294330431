import React, { useEffect, useState } from 'react';
import { Form, Button, Alert, Input } from 'antd';
import { post } from 'utils/fetch';

import styles from './index.module.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
const strings = translations[getLangKey()];

export const LoginLink = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const error = searchParams.get('error');
    if (error === 'expired') {
      setErrorMessage(strings.expiredErrorMessage);
    }
  }, [searchParams]);

  const onFinish = async ({ email }) => {
    setLoading(true);

    const body = await post(`/users/sign_in/login_link/`, {
      email
    });
    if (body.error) {
      setLoading(false);
      setErrorMessage(
        'There was an error when attempting to send a login link'
      );
      return;
    }
    navigate('/sign_in/login_link/email_sent');
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <Alert
          className={styles.errorMessage}
          message={errorMessage}
          type="error"
          closable
          onClose={() => setErrorMessage(undefined)}
        />
      );
    }
  };

  const renderContent = () => {
    return (
      <div>
        <h1 className={styles.title}>{strings.emailMeALoginLink}</h1>

        {renderErrorMessage()}

        <Form name="login" onFinish={onFinish} layout="vertical" form={form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: strings.pleaseEnterYourEmail }]}
          >
            <Input type="email" />
          </Form.Item>
          <div className={styles.loginLinkForm}>
            <Form.Item noStyle>
              <div>
                <Button
                  className={styles.submit}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  {strings.continue}
                </Button>
              </div>
            </Form.Item>
            <p>
              <Link to="/sign_in">{strings.logInWithEmail}</Link>
            </p>
          </div>
        </Form>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};
