import React from 'react';
import lightStyles from './lightStyles.module.css';
import darkStyles from './darkStyles.module.css';
import cx from 'classnames';
import { Loader as LoaderIcon } from 'lucide-react';

const Loader = ({
  className,
  useDarkStyles
}: {
  className: string;
  useDarkStyles?: boolean;
}) => (
  <LoaderIcon
    className={cx(className, {
      [lightStyles.loaderIcon]: !useDarkStyles,
      [darkStyles.loaderIcon]: useDarkStyles
    })}
  />
);

export default Loader;
