import React, { FC, ReactNode, UIEvent } from 'react';
import { Popover, Button } from 'antd';
import { getLangKey } from '../../models/Language';
import * as translations from './strings';
import cx from 'classnames';
import styles from './styles.module.css';
import { Mail } from 'lucide-react';
import ArrowUp from '../../assets/images/ic-upgrade-circle.svg';
import { TooltipPlacement } from 'antd/lib/tooltip';

const strings = translations[getLangKey()];

type Props = {
  children: ReactNode;
  open: boolean;
  onOpenChange: (visible: boolean) => void;
  message: ReactNode;
  placement: TooltipPlacement;
  getContainer: () => HTMLElement;
  className: string;
  imageSrc: string;
  ownerEmail?: string;
  billingRights: boolean;
  href: string;
  isSmallView?: boolean;
  isAdminView?: boolean;
  headerContent?: ReactNode;
  trigger?: ('click' | 'hover')[];
  buttonText?: string;
};

const UpgradePopover: FC<Props> = ({
  children,
  open,
  onOpenChange,
  message,
  placement,
  getContainer,
  className,
  imageSrc,
  ownerEmail,
  href,
  billingRights,
  headerContent,
  isSmallView,
  isAdminView,
  trigger = ['click'],
  buttonText
}) => {
  const handleUpgradeOnClick = (event: UIEvent) => {
    if (isAdminView) return;

    event.preventDefault();

    window.parent.location.href = href;
  };

  const upgradeButtonText = buttonText || strings.upgradePlan;

  return (
    <Popover
      overlayClassName={cx(styles.popover, className)}
      open={open}
      onOpenChange={onOpenChange}
      placement={placement}
      trigger={trigger}
      title={null}
      getPopupContainer={getContainer}
      content={
        <div
          className={cx(styles.contentOuter, {
            [styles.smallContentOuter]: isSmallView
          })}
        >
          <img className={styles.image} src={imageSrc} alt="upgrade_content" />
          <div className={styles.content}>
            <h3 className={styles.discoverFeature}>
              {headerContent
                ? headerContent
                : [
                    strings.youve,
                    <span key="discovered" className={styles.discovered}>
                      {strings.discovered}
                    </span>,
                    strings.premiumFeature
                  ]}
            </h3>
            {message}
            {billingRights ? (
              <Button
                type="primary"
                href={href}
                onClick={handleUpgradeOnClick}
                className={styles.upgrade}
              >
                <span>
                  <ArrowUp className={styles.arrowIcon} />
                  {upgradeButtonText}
                </span>
              </Button>
            ) : (
              <Button
                type="primary"
                className={styles.contactOwner}
                href={`mailto:${ownerEmail || 'support@bugherd.com'}`}
              >
                <span>
                  <Mail className={styles.mailIcon} />
                  {ownerEmail ? strings.contactOwner : strings.contactSupport}
                </span>
              </Button>
            )}
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default UpgradePopover;
