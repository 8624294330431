export const en = {
  paymentExtension: (expiresAt: string) =>
    `Note: you currently have an extension on your payment until ${expiresAt}`,
  canceled: 'Your plan has been cancelled.',
  cancelling: (renewalDate: string) =>
    `Note: Your subscription is set to cancel on ${renewalDate} unless you reactivate`,
  updatePaymentDetails: 'update your payment details',
  contactUs: 'contact us',
  overdue: 'Your account is currently overdue. Please reactivate below',
  expired: 'Your account has expired, please',
  trailing: (expiresAt: string) =>
    `You are currently on your free trial. If you want to continue using BugHerd uninterrupted, please select a plan before ${expiresAt}`, // <%= @organization.user.trial_expiry_date.strftime("%e %B %Y") %>.
  trialExpired:
    'Your free trial has now expired but all you have to do is pick a plan and you can continue on your way!',
  currentPlanIsPaused: (price: number) =>
    `Your current plan is paused at $${price}/mo`,
  additionalUsersBody:
    'Add more members to your current plan for just $8 per user.',
  additionalUsersHeader: 'Just need a few more users?',
  addMembers: 'Add member(s)',
  havingTrouble: 'Having trouble deciding?',
  getInTouch: 'Get in-touch',
  currentPlan: (couponDescription: string) =>
    `${couponDescription} has been applied to your subscription`,
  planCallout: 'Upgrade to a Plan that works for you'
};

export type Translations = typeof en;
