import React, { FC } from 'react';
import { Avatar, Dropdown, Space, Button, Flex } from 'antd';
import { Gift, HelpCircle } from 'lucide-react';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import { Endpoints, User } from './types';
import cx from 'classnames';
import { rightNav } from './getItems';

const strings = translations[getLangKey()];

interface Props {
  user: User;
  isActiveOrganization: boolean;
  endpoints: Endpoints;
  isMobileView: boolean;
  container: HTMLDivElement;
  openElevio: (e: any) => void;
  setIsModalOpen: (boolean) => void;
  isModalOpen: boolean;
  toggleMobileMenu: () => void;
  toggleDarkMode: () => void;
}

export const RightNav: FC<Props> = Props => {
  const {
    user: {
      id,
      isImpersonating,
      daysLeftInTrial,
      genericAvatar,
      isGuest,
      isCollaborater,
      organization,
      amountOfTrialDays,
      membership,
      accessTo,
      name,
      new_avatar_url,
      admin,
      surname,
      email
    },
    endpoints: { stopImpersonsating, adminUrl, subscription, homeUrl },
    isActiveOrganization,
    container,
    isMobileView,
    setIsModalOpen,
    openElevio,
    toggleDarkMode,
    toggleMobileMenu
  } = Props;

  const inTrial = organization?.in_trial;

  const userFirstNameFirstLetter = (name || email)?.[0];
  const emailFirstLetter = email?.[0];
  const userSurnameFirstLetter = surname?.[0];
  const userAvatar = new_avatar_url;
  const isMember = membership?.role === 'member';
  const showTrialMessage =
    !isMobileView && organization && accessTo?.billing && inTrial;
  const hasDarkModeExperiment = organization?.active_experiments.includes(
    'dark_theme'
  );

  const userClassName = (): string => {
    if (isGuest) {
      return 'guest-class';
    } else if (isCollaborater) {
      return 'collab-class';
    } else {
      return 'member-class';
    }
  };

  const items = rightNav({
    setIsModalOpen,
    adminUrl,
    admin,
    isGuest,
    isMember,
    daysLeftInTrial,
    amountOfTrialDays,
    id,
    isActiveOrganization,
    openElevio,
    homeUrl,
    toggleDarkMode,
    hasDarkModeExperiment
  });

  return (
    <Flex gap="small">
      {isImpersonating && (
        <Flex gap="small" align="center">
          <span>** Impersonating User! **</span>
          <a href={stopImpersonsating}> Back to Admin</a>
        </Flex>
      )}

      {showTrialMessage && (
        <span className={styles.upgradeWrapper}>
          <p>
            {daysLeftInTrial} {strings.remaining}
          </p>

          <Button type="link" href={subscription}>
            {' '}
            {strings.upgradeNow}
          </Button>
        </span>
      )}
      <div
        className={cx({
          [styles.greyWrapper]: !isMobileView
        })}
      >
        {!isMobileView && (
          <>
            <a onClick={e => e.preventDefault} id="whats-new">
              <Gift size={24} />
            </a>
            <a onClick={openElevio}>
              {' '}
              <HelpCircle size={24} />
            </a>
          </>
        )}

        <div onClick={toggleMobileMenu}>
          <Dropdown
            getPopupContainer={() => container}
            disabled={isMobileView}
            overlayClassName={styles.overlay}
            className={styles.navbarMenu}
            menu={{ items }}
            trigger={['click']}
            placement="bottomRight"
          >
            <a onClick={e => e.preventDefault()}>
              <Space>
                <div>
                  <div className={cx({ 'avatar-wrap': !genericAvatar })}>
                    <Avatar
                      className={userClassName()}
                      size={!genericAvatar ? 'large' : undefined}
                      src={genericAvatar ? userAvatar : undefined}
                    >
                      {userFirstNameFirstLetter || emailFirstLetter}
                      {userFirstNameFirstLetter && userSurnameFirstLetter}
                    </Avatar>
                  </div>
                </div>
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </Flex>
  );
};
