import React, { FC, useState } from 'react';
import { Popover, Button } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as translations from '../strings';
import { getLangKey } from '../../../../../models/Language';
import styles from './styles.module.css';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLDivElement;
  isValidSite: boolean;
  save: () => void;
};

const SitePopover: FC<Props> = ({ isValidSite, container, save }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Popover
      overlayClassName={styles.popover}
      open={show}
      onOpenChange={visible => {
        if (!isValidSite) {
          setShow(visible);
        }
      }}
      placement="bottomLeft"
      trigger="click"
      title={null}
      getPopupContainer={() => container}
      content={
        <div className={styles.popoverContent}>
          <div>
            <ExclamationCircleFilled className={styles.popoverIcon} />
            <span>
              {strings.sitePopoverContent(
                <code key={strings.exampleDomain}>{strings.exampleDomain}</code>
              )}
            </span>
          </div>
          <div className={styles.okContainer}>
            <Button size="small" type="primary" onClick={() => setShow(false)}>
              {strings.okButton}
            </Button>
          </div>
        </div>
      }
    >
      <Button
        type="primary"
        size="small"
        onClick={save}
        className={styles.locationSave}
      >
        {strings.okButton}
      </Button>
    </Popover>
  );
};

export default SitePopover;
