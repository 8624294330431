export const JobRole: string[] = [
  'Designer',
  'Developer',
  'Content Creator',
  'Project/Product Manager',
  'Director C-Level',
  'Marketer',
  'QA',
  'Support',
  'Job not listed'
];

export const Industry: string[] = [
  'an Agency',
  'Marketing/Communications',
  'Online learning',
  'E-commerce',
  'Saas/Product',
  'Industry not listed'
];

export const CompanySize: string[] = [
  'Self-employed',
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10,000',
  '10,000+'
];

export const UserType: string[] = [
  'My clients',
  'My users',
  'My team',
  'My agency'
];

export const TeamType: string[] = ['Internal', 'External'];

export const Replace: string[] = [
  'Nothing',
  'Emails',
  'Spreadsheets (Excel, Google sheets)',
  'Annotated PDF’s',
  'Word documents (Word, Google Docs)',
  'Screenshots',
  'Jira',
  'Asana',
  'Trello',
  'Other software product'
];
