import * as React from 'react';
import { List, Input, Space, Divider, Tooltip, Button } from 'antd';
import { SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import Error from 'components/Error';
import { getLangKey } from 'models/Language';

import Loader from '../Loader';
import UserCommentItem from './UserComment';
import * as translations from '../strings';
import styles from './index.module.css';
import HelpArticleButton from 'utils/HelpArticleButton';

import EmptyState from '../EmptyState';

import { useState } from './state';

type Props = {
  organizationId: string;
  onPusherUpdate: () => void;
  refresh: boolean;
};

const getPopupContainer = () =>
  document.getElementById('ReactApp') as HTMLElement;

const UserComments = ({ organizationId, onPusherUpdate, refresh }: Props) => {
  const strings = translations[getLangKey()];

  const {
    pageNumber,
    pusherUpdated,
    searchText,
    setPageNumber,
    setSearchText,
    userComments,
    userCommentsPageSize,
    getUserComments: { data, loading, error, refetch }
  } = useState({ organizationId });

  React.useEffect(() => {
    if (pusherUpdated) onPusherUpdate();
  }, [pusherUpdated]);

  if (refresh) refetch();

  const emptyUserComments = data && data.currentUser.userComments.length === 0;
  const noSearchContents = emptyUserComments && !!searchText;

  /* can only guarantee there's no more comments if the current page has less than the page size */
  const hasMoreComments =
    data && data.currentUser.userComments.length >= userCommentsPageSize;

  const hasCommentsButNoneOnThisPage = pageNumber > 1 && emptyUserComments;

  const showContent = () => {
    if (loading && userComments.length === 0) return <Loader />;
    if (error) return <Error error={error} />;
    if (!data && userComments.length === 0) return <p>Not found</p>;

    if (noSearchContents) {
      return (
        <EmptyState
          title={strings.emptyNoSearchTitle}
          help={strings.emptyNoSearchHelp}
          container="CommentSearch"
        />
      );
    }

    return (
      <>
        {hasCommentsButNoneOnThisPage ? (
          <EmptyState
            title={strings.emptyCommentsTitle}
            help={strings.emptyCommentsHelp}
            container="CommentSearch"
          />
        ) : (
          <List
            className={styles.list}
            itemLayout="vertical"
            size="large"
            pagination={false}
            dataSource={userComments}
            renderItem={item => (
              <List.Item key={item.id} className={styles.listItem}>
                <UserCommentItem
                  user={item.user}
                  createdAt={item.createdAt}
                  text={item.text}
                  project={item.project}
                  isMemberOnly={item.isPrivate}
                  localTaskId={item.localTaskId}
                  searchText={searchText}
                />
              </List.Item>
            )}
          ></List>
        )}
        <Space className={styles.pagination}>
          {pageNumber !== 1 && (
            <Button
              type="primary"
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => setPageNumber(pageNumber - 1)}
            />
          )}
          {hasMoreComments && (
            <Button
              type="primary"
              shape="circle"
              icon={<RightOutlined />}
              onClick={() => setPageNumber(pageNumber + 1)}
            />
          )}
        </Space>
      </>
    );
  };

  return (
    <>
      <h4 className={styles.label}>
        {strings.commentLabel}
        <Tooltip
          title={<p>{strings.commentsForMeInfoIcon}</p>}
          getPopupContainer={getPopupContainer}
          placement="rightBottom"
        >
          <span className={styles.helpIconOuter}>
            <HelpArticleButton articleId={strings.myTaskInfoHelpArticleId} />
          </span>
        </Tooltip>
      </h4>
      <Divider />

      {emptyUserComments && !searchText && !hasCommentsButNoneOnThisPage ? (
        <EmptyState
          title={strings.emptyUserCommentsTitle}
          help={strings.emptyUserCommentsHelp}
          container="Comment"
        />
      ) : (
        <>
          <Input
            prefix={<SearchOutlined className={styles.search} />}
            value={searchText}
            placeholder={strings.searchPlacholder}
            onChange={({ target: { value } }) => {
              setSearchText(value);
            }}
            className={styles.searchInput}
          />
          <Space />
          {showContent()}
        </>
      )}
    </>
  );
};

export default UserComments;
