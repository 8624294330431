import { useEffect } from 'react';
import {
  setupPusher,
  Event,
  Subscription,
  teardownPusher,
  PusherProject
} from 'appJS/models/Pusher';
import Config from 'appJS/models/Config';

type Props = {
  pusher?: {
    config: Config;
    projects: PusherProject[];
    events: {
      name: Event;
      onUpdate: (pusherData: any) => void;
    }[];
    subscription: Subscription;
  };
  loading: boolean;
};

const usePusherSubscribe = ({ pusher, loading }: Props) => {
  useEffect(() => {
    if (!loading && pusher) {
      const { config, projects, events, subscription } = pusher;

      setupPusher({
        config,
        events,
        subscription,
        projects
      });

      return () => {
        teardownPusher({
          config,
          projects,
          subscription
        });
      };
    }
  }, [loading]);
};

export default usePusherSubscribe;
