import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { Button, Alert, message } from 'antd';
import zapier from 'appAssets/images/zapier_logo.png';
import slack from 'appAssets/images/slack_logo.png';
import github from 'appAssets/images/github_logo.png';
import bcx from 'appAssets/images/basecamp_logo.png';
import harvest from 'appAssets/images/harvest_logo.png';
import { Check } from 'lucide-react';
import { editIntegration, createIntegration } from 'utils/integrations';

import styles from './index.module.css';

const cx = classnames.bind(styles);

export default class Integration extends React.Component {
  static propTypes = {
    img: PropTypes.shape({
      className: PropTypes.string,
      alt: PropTypes.string
    }),
    title: PropTypes.string,
    code: PropTypes.string,
    about: PropTypes.string,
    primaryButton: PropTypes.string,
    secondaryButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    orgId: PropTypes.number,
    integrationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    intInfo: PropTypes.object,
    isConnected: PropTypes.bool,
    setParentState: PropTypes.func,
    isLoading: PropTypes.bool,
    zapierData: PropTypes.shape({
      id: PropTypes.number,
      organization_id: PropTypes.number
    }),
    integrationAllowed: PropTypes.bool.isRequired,
    billingRights: PropTypes.bool.isRequired,
    subscriptionLink: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired
  };

  navigateToEditPage = (response, tempData) => {
    const { navigate, integrationId, orgId } = this.props;
    let _integrationId;

    if (response) {
      _integrationId = response.id;
    } else if (integrationId) {
      _integrationId = integrationId;
    } else {
      _integrationId = tempData.id;
    }

    navigate(`/organizations/${orgId}/integrations/${_integrationId}/edit`);
  };

  createZapierIntegration = event => {
    const { orgId, code, setParentState, zapierData } = this.props;
    const endpoint = `/organizations/${orgId}/integrations/new/${code}`;
    event.preventDefault();
    setParentState({ isLoading: true });
    fetch(endpoint, {
      method: 'GET'
    })
      .then(response => {
        if (response.status !== 200 && response.status !== 302) {
          throw Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then(data => {
        setParentState({
          isLoading: false,
          tempZapierData: data
        });
        this.navigateToEditPage(data, zapierData);
      })
      .catch(() => {
        setParentState({ isLoading: false });
        message.error('Something went wrong, please try again.', 2.5);
      });
  };

  getPrimaryButton = tempData => {
    const {
      primaryButton,
      code,
      orgId,
      integrationId,
      isLoading,
      secondaryButton,
      zapierData
    } = this.props;
    const link = `/organizations/${orgId}/integrations/`;
    const intId = tempData ? tempData.id : integrationId;
    const projects = link + intId + '/projects';
    const edit = '/edit';
    const _new = 'new/';
    const zapierProps = {
      onClick: intId
        ? () => this.navigateToEditPage(null, zapierData)
        : this.createZapierIntegration,
      loading: isLoading,
      children: primaryButton
    };

    const harvestProps = {
      onClick: intId
        ? () => editIntegration(orgId, intId)
        : () => createIntegration(orgId, code),
      loading: isLoading,
      children: primaryButton
    };

    const getLinks = () => {
      let newOrEdit = link;
      if (intId) {
        if (primaryButton === 'Manage') {
          newOrEdit += intId + edit;
          return [newOrEdit, projects];
        }
      } else {
        newOrEdit += _new + code;
      }
      return [newOrEdit];
    };

    const buttonProps = (anchorFlag, child) => {
      let isManageOrConnect;
      if (typeof child === 'object') {
        isManageOrConnect = child.props.href.split('/')[5] !== 'projects';
      }
      return {
        type: 'primary',
        className: cx({
          connectManage:
            child === 'Manage' || child === 'Connect' || isManageOrConnect,
          connectManageAnchor: anchorFlag,
          projects: !isManageOrConnect
        })
      };
    };

    const getButton = (props, anchorFlag, child) => {
      return (
        <Button {...buttonProps(anchorFlag, child)} {...props}>
          {child}
        </Button>
      );
    };
    const anchor = (href, string) => <a href={href}>{string}</a>;

    const getChildren = links => {
      if (links.length) {
        const getMultipleButtons = () => (
          <div
            className={cx({
              [styles.twoButtons]: secondaryButton
            })}
          >
            {getButton({}, true, anchor(links[0], primaryButton))}
            {links[1] && getButton({}, true, anchor(links[1], secondaryButton))}
          </div>
        );
        return getMultipleButtons();
      }
      return getButton({}, true, primaryButton);
    };

    if (code === 'zapier') {
      if (!intId) {
        return getButton(zapierProps, false, primaryButton);
      }
      return (
        <Link to={link + intId + edit}>
          {getButton({}, false, primaryButton)}
        </Link>
      );
    }
    if (code === 'harvest') {
      if (!intId) {
        return getButton(harvestProps, false, primaryButton);
      }
      return getButton({}, false, anchor(link + intId + edit, primaryButton));
    } else {
      return getChildren(getLinks());
    }
  };

  getSecondaryButton = () => {
    const { isConnected, code } = this.props;
    let elevioArticleId;

    switch (code) {
      case 'zapier':
        elevioArticleId = '33883';
        break;
      case 'slack':
        elevioArticleId = '84823';
        break;
      case 'github':
        elevioArticleId = '33875';
        break;
      case 'bcx':
        elevioArticleId = '33886';
        break;
      case 'harvest':
        elevioArticleId = '84964';
        break;
    }

    if (isConnected) {
      return (
        <div className={styles.connected}>
          <Check className={styles.check} />
          <span className={styles.secondaryButtonChild}>Connected</span>
        </div>
      );
    }

    return (
      <Button
        type="secondary"
        data-elevio-article={elevioArticleId || null}
        data-elevio-style="nothing"
        data-elevio-uniquie-id="1"
        className={styles.findOutMoreButton}
      >
        Find out more
      </Button>
    );
  };

  render() {
    const {
      img,
      about,
      title,
      code,
      zapierData,
      billingRights,
      integrationAllowed,
      subscriptionLink
    } = this.props;
    const { className, alt } = img;
    const logos = { zapier, slack, github, bcx, harvest };

    return (
      <div
        className={cx({ integrationBox: true, firstBox: code === 'zapier' })}
      >
        <div className={styles.logoBox}>
          <img
            className={cx([className, 'logo'])}
            src={logos[code]}
            alt={alt}
          />
        </div>
        <div className={styles.moreInfo}>
          <span className={styles.title}>{title}</span>
          <p className={styles.about}>{about}</p>
          {!integrationAllowed ? (
            <div className={styles.alertBox}>
              <Alert
                type="warning"
                className={styles.upgradeMessage}
                message={
                  <div className={styles.alertMessage}>
                    {title} isn’t available on your plan.{' '}
                    {billingRights ? (
                      <a href={subscriptionLink} className={styles.upgradeLink}>
                        Upgrade your plan
                      </a>
                    ) : (
                      'Contact your account owner to upgrade your account.'
                    )}
                  </div>
                }
              />
            </div>
          ) : (
            <div className={styles.controlsBox}>
              {this.getPrimaryButton(zapierData)}
              {this.getSecondaryButton()}
            </div>
          )}
        </div>
      </div>
    );
  }
}
