import { ThemeConfig } from 'antd';

export const antdTheme: ThemeConfig = {
  token: {
    fontFamily: `'Inter', sans-serif`,
    fontSize: 14,
    fontSizeSM: 12,
    fontSizeLG: 16,
    fontSizeXL: 20,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    colorTextBase: '#19222A',
    colorLink: '#3F71E0',
    colorLinkHover: '#1A49B0',
    colorLinkActive: '#133683',
    colorPrimaryHover: '#1A49B0',
    colorPrimary: '#3f71e0',
    colorTextLightSolid: '#ffffff',
    colorError: '#ff4d4f',
    colorWarning: '#faad14',
    colorSuccess: '#52c41a',
    colorInfo: '#3F71E0',
    boxShadow: 'none',
    colorBgBase: '#ffffff',
    boxShadowSecondary: '0px 10px 10px 0px rgba(0, 57, 128, 0.20)'
  },
  components: {
    Button: {
      borderRadius: 20,
      borderRadiusLG: 20,
      colorBgContainerDisabled: '#97b3ee',
      defaultBg: '#ffffff',
      defaultBorderColor: '#3F71E0',
      defaultColor: '#3F71E0'
    },
    Dropdown: {
      controlItemBgHover: '#F0F7FF',
      controlItemBgActive: 'F0F7FF'
    },
    Badge: {
      dotSize: 8
    },
    Breadcrumb: {
      iconFontSize: 16,
      itemColor: '#19222A',
      linkColor: '#3F71E0',
      lastItemColor: '#19222A',
      linkHoverColor: '##1A49B0',
      separatorColor: '#19222A'
    },
    Tabs: {
      itemActiveColor: '#000000',
      itemSelectedColor: '#000000',
      itemColor: '#3F71E0'
    },
    Table: {
      headerSplitColor: 'transparent'
    },
    Form: {
      labelColor: '#19222A'
    },
    Layout: {
      bodyBg: '#F6F9FC'
    },
    Pagination: {
      itemActiveBg: 'transparent'
    },
    Collapse: {
      contentPadding: '0px !important',
      headerPadding: '0px !important'
    },
    Avatar: {
      textFontSizeSM: 12
    }
  }
};
