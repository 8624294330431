import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';
import zapier from 'appAssets/images/zapier_logo.png';
import ZapierEmbed from './zapier_embed';

import styles from './index.module.css';

export default class Manage extends React.Component {
  static propTypes = {
    apiKey: PropTypes.string,
    connected: PropTypes.bool,
    orgName: PropTypes.string,
    orgId: PropTypes.number,
    integrationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  getUnconnectedElements = () => {
    const { apiKey } = this.props;
    return (
      <div className={styles.moreInfoBox}>
        <p className={styles.about}>
          Connecting Zapier to your BugHerd account couldn't be easier. Simply
          choose from one of the popular zaps listed below and follow the
          instructions to setup your account.
        </p>
        <span className={styles.need}>What you'll need...</span>
        <ul className={styles.needList}>
          <li>
            - A Zapier account (
            <a
              href="https://zapier.com/sign-up/"
              target="_blank"
              className={styles.zapierLink}
            >
              sign up free here
            </a>
            )
          </li>
          <li>
            - Your BugHerd API Key:{' '}
            <span className={styles.apiKey}>{apiKey}</span>
          </li>
        </ul>
        <Button type="primary" className={styles.connectManage}>
          <a
            href="https://zapier.com/apps/bugherd/integrations"
            className={styles.connectManageAnchor}
            target="blank"
          >
            Connect
          </a>
        </Button>
      </div>
    );
  };

  getConnectedElements = () => {
    return (
      <div className={styles.connectedElements}>
        <div className={styles.connectedButton}>
          <Check className={styles.check} />
          <span className={styles.connectedChild}>Connected</span>
        </div>
        <Button type="primary" className={styles.connectManage}>
          <a
            href="https://zapier.com/zapbook/bugherd"
            className={styles.connectManageAnchor}
            target="_blank"
          >
            Manage on Zapier.com
          </a>
        </Button>
      </div>
    );
  };

  render() {
    const { connected, orgId } = this.props;

    return (
      <div className={styles.manageContainer}>
        <div className={styles.manageIntegration}>
          <Link to={`/organizations/${orgId}/integrations`}>
            <Button type="secondary" className={styles.navigateBack}>
              {'< Back to Integrations'}
            </Button>
          </Link>
          <div className={styles.manageBox}>
            <div className={styles.logoBox}>
              <img className={styles.logo} src={zapier} alt="zapier_logo" />
            </div>
            <div className={styles.moreInfo}>
              <h2 className={styles.moreInfoHeader}>Zapier</h2>
              {connected
                ? this.getConnectedElements()
                : this.getUnconnectedElements()}
            </div>
          </div>
          <div className={styles.embedContainer}>
            <h4 className={styles.zapsHeader}>Most Popular Zaps...</h4>
            <ZapierEmbed />
          </div>
        </div>
      </div>
    );
  }
}
