export const handleRedirect = (
  installLink: string,
  redirectCallback?: () => void
) => {
  const width = window.innerWidth * 0.8; // 80% of window's width
  const height = window.innerHeight * 0.8; // 80% of window's height
  const midLeft = window.screenX + window.outerWidth / 2 - width / 2;
  const midTop = window.screenY + window.outerHeight / 2 - height / 2;

  const webstoreWindow = window.open(
    installLink,
    'Exten',
    'status=0,width=' +
      width +
      ',height=' +
      height +
      ',resizable=yes,left=' +
      midLeft +
      ',top=' +
      midTop +
      ',toolbar=0,menubar=0,scrollbars=0,location=0'
  );

  // handling for firefox, as window.open is returning null
  if (webstoreWindow) {
    webstoreWindow.focus();
  }
  redirectCallback?.();
};
