export const en = {
  giveFeedback: 'Give feedback',
  refresh: 'Refresh',
  viewWebsite: 'View website',
  viewAsset: 'View asset',
  viewProject: 'View project',
  updatedAgo: (readableTime: string) => `Updated: ${readableTime} ago`,
  disabled: 'Disabled',
  gettingCover: 'Getting cover photo...',
  notCreated: 'Cover not created?',
  why: 'why?',
  getCover: 'Get cover photo',
  pleaseWait: 'Please wait...',
  wentWrong: 'Something went wrong, please try again.',
  markAsReadSuccess: 'Commends and mentiones marked as read',
  markAsReadFailure: 'Read all comments and mentions failed',
  success: (hasCover: boolean) =>
    `Project cover photo is being ${
      hasCover ? 'updated' : 'generated'
    }, check back later.`,
  tasksCountTitle: (openCount: string, triageCount: string) =>
    `${openCount} open tasks ${
      parseInt(triageCount, 10) > 0 ? `(${triageCount} more in Feedback)` : ''
    }`,
  coverScreenshot: 'Screenshot'
};

export type Translations = typeof en;
