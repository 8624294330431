import * as React from 'react';
import { Pagination } from 'antd';

import styles from './index.module.css';
import { PAGE_SIZE_OPTIONS } from './types';
import cx from 'classnames';

const ProjectPagination = ({
  pageNumber,
  onChange,
  userProjectsCount,
  userProjectsPageSize,
  position = 'top',
  simple
}: {
  pageNumber: number;
  onChange: (pageNumber: number, pageSize?: number) => void;
  userProjectsCount: number;
  userProjectsPageSize: number;
  position?: 'top' | 'bottom';
  simple: boolean;
}) =>
  userProjectsCount > 0 && userProjectsCount > userProjectsPageSize ? (
    <Pagination
      onChange={onChange}
      current={pageNumber}
      pageSize={userProjectsPageSize}
      total={userProjectsCount}
      className={cx(styles.pagination, styles[`${position}PagePosition`])}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      simple={simple}
    />
  ) : null;

export default ProjectPagination;
