import * as React from 'react';

import MultiSelectCheckbox from '../../MultiSelectCheckbox';

type Props = {
  statusNames: string[];
  value?: string[];
  onChange: (value: string[]) => void;
};

const StatusNameFilter: React.FC<Props> = ({
  statusNames,
  value,
  onChange
}) => (
  <MultiSelectCheckbox
    value={value}
    onChange={onChange}
    options={statusNames.map(name => ({
      value: name,
      label: name
    }))}
  />
);

export default StatusNameFilter;
