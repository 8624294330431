import Pusher from 'pusher-js/with-encryption';
import { Channel } from 'pusher-js';
import Config from './Config';

import * as handles from './pusher/handles';

let bugHerdPusher: Pusher;

export enum Event {
  PROJECT_UPDATE = 'project_update',
  TASK_CREATE = 'task_create',
  TASK_UPDATE = 'task_update',
  TASK_DELETE = 'task_delete',
  ASSET_CREATE = 'asset_create',
  ASSET_UPDATE = 'asset_update',
  ASSET_DELETE = 'asset_delete',
  PROJECT_TAGS_UPDATED = 'project_tags_updated',
  PROJECT_SITE_UPDATE = 'project_site_update',
  SITE_JS_VERIFIED = 'site_js_verified',
  SITE_JS_NOT_VERIFIED = 'site_js_verification_failed',
  GUEST_EDIT_STATUS_UPDATED = 'guest_edit_status_updated'
}

export enum Subscription {
  USER_ACTIVITIES = 'user_activities',
  USER_PROJECTS = 'user_projects',
  USER_ASSETS = 'user_assets',
  GUEST_PROJECT = 'guest_project',
  ASSET_GROUP = 'asset_group',
  SHARING = 'sharing',
  KANBAN = 'kanban',
  TASK_ACTIONS = 'task_actions',
  TRIAGE = 'triage',
  ARCHIVE = 'archive'
}

export interface TaskData {
  task: {
    id: string;
    description: string;
  };
}

export interface ProjectData {
  project: {
    id: string;
    name: string;
  };
}

export const getPusher = (
  apiKey: string,
  { authEndpoint }: { authEndpoint: string }
) => {
  if (!bugHerdPusher) {
    bugHerdPusher = new Pusher(apiKey, {
      authEndpoint,
      disableStats: true
    });
  }

  return bugHerdPusher;
};

export type OnSubscribeProps = {
  projectId?: string;
  channel: Channel;
};

export type PusherProject = { id: string; pusherChannelName: string };

const projectChannels: Record<string, Channel> = {};
// Avoid double subscribing
const projectChannelsOnSubscriptionDone: Record<string, boolean> = {};

type SubscribeProjectChannelProps = {
  pusher: Pusher;
  project: { id: string; pusherChannelName: string };
  subscription: Subscription;
  onSubscribe: (props: OnSubscribeProps) => void;
};

export const subscribeProjectChannel = ({
  pusher,
  project,
  subscription,
  onSubscribe
}: SubscribeProjectChannelProps) => {
  if (!projectChannels[project.id]) {
    const projectChannel = pusher.subscribe(project.pusherChannelName);

    if (
      subscription === Subscription.USER_ACTIVITIES ||
      subscription === Subscription.USER_PROJECTS
    ) {
      // Default handles
      projectChannel.bind(Event.PROJECT_UPDATE, handles.handleProjectUpdate);
      projectChannel.bind(Event.TASK_UPDATE, handles.handleTaskUpdate);
    }

    projectChannels[project.id] = projectChannel;
  }

  const channel = projectChannels[project.id];

  const subKey = `project:${project.id}:${subscription}`;
  if (!projectChannelsOnSubscriptionDone[subKey]) {
    onSubscribe({ projectId: project.id, channel });
    projectChannelsOnSubscriptionDone[subKey] = true;
  }

  return channel;
};

type UnsubscribeProjectChannelProps = {
  pusher: Pusher;
  project: PusherProject;
  subscription: Subscription;
};

export const unsubscribeProjectChannel = ({
  pusher,
  project,
  subscription
}: UnsubscribeProjectChannelProps): void => {
  if (!projectChannels[project.id]) return;

  const subKey = `project:${project.id}:${subscription}`;

  delete projectChannelsOnSubscriptionDone[subKey];

  const hasSubscriptionLeft = Object.keys(Subscription).some(
    sub => projectChannelsOnSubscriptionDone[`project:${project.id}:${sub}`]
  );

  if (hasSubscriptionLeft) return;

  pusher.unsubscribe(project.pusherChannelName);

  delete projectChannels[project.id];
};

type SetupPusherProps = {
  config: Config;
  projects: PusherProject[];
  events: { name: Event; onUpdate: (input: any) => void }[];
  subscription: Subscription;
};

export const setupPusher = ({
  config,
  projects,
  events,
  subscription
}: SetupPusherProps) => {
  const pusher = getPusher(config.pusherApiKey, {
    authEndpoint: config.pusherChannelAuthEndpoint
  });

  const onSubscribe = ({ channel }: OnSubscribeProps) => {
    events.forEach(({ name, onUpdate }) => {
      channel.bind(name, onUpdate);
    });
  };

  projects.forEach(project =>
    subscribeProjectChannel({
      pusher,
      project,
      subscription,
      onSubscribe
    })
  );
};

export const teardownPusher = ({
  config,
  projects,
  subscription
}: {
  config: Config;
  projects: { id: string; pusherChannelName: string }[];
  subscription: Subscription;
}) => {
  const pusher = getPusher(config.pusherApiKey, {
    authEndpoint: config.pusherChannelAuthEndpoint
  });

  projects.forEach(project => {
    unsubscribeProjectChannel({
      pusher,
      project,
      subscription
    });
  });
};
