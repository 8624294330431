import { gql } from '@apollo/client';

const TaskFields = gql`
  fragment TaskFields on Task {
    id
    description
    localTaskId
    projectId
    statusName
    dueAt
    createdAt
    updatedAt
  }
`;

export const fragments = {
  TaskFields
};
