export function composeTaskLocation({
  site,
  page
}: {
  site: string;
  page: string;
}): string {
  try {
    const siteUrl = new URL(site);
    const pageUrl = new URL('http://fake.com' + page);

    return (
      siteUrl.origin +
      (siteUrl.pathname === '/' && !site.endsWith('/')
        ? ''
        : siteUrl.pathname) +
      (pageUrl.pathname !== '/' ? pageUrl.pathname : '') +
      siteUrl.search +
      (!!siteUrl.search && !!pageUrl.search
        ? `&${pageUrl.search.substring(1)}`
        : pageUrl.search) +
      (siteUrl.hash
        ? !pageUrl.hash
          ? siteUrl.hash
          : siteUrl.hash + pageUrl.hash.substring(1)
        : pageUrl.hash)
    );
  } catch {
    return site + page;
  }
}
