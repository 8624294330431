import { useEffect, useState } from 'react';

const ALT_KEY = 'Alt';

export const useListenForAltKey = () => {
  const [isAltKeyDown, setIsAltKeyDown] = useState<boolean>(false);

  const handleKeyDown = ({ key }: KeyboardEvent) =>
    setIsAltKeyDown(key === ALT_KEY);
  const handleKeyUp = () => setIsAltKeyDown(false);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return { isAltKeyDown };
};
