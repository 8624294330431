import get from 'lodash/get';

import { getCurrentProjectId } from 'jsModels/Organization';
import { STATUS_ARCHIVE } from './Task';

export const newArchiveBulkRequest = ({ columnId, taskIds }) => ({
  objectAction: 'archive',
  objectType: 'tasks',
  projectId: getCurrentProjectId(),
  details: {
    objects: taskIds,
    action: 'archive',
    sourceState: {
      columnId: columnId,
      statusId: null
    },
    targetState: {
      statusId: STATUS_ARCHIVE,
      columnId: null
    }
  }
});

export const newUndoBulkRequest = bulkRequest => ({
  objectAction: `undo_${bulkRequest.details.action}`,
  objectType: bulkRequest.objectType,
  projectId: bulkRequest.projectId,
  relatedBulkRequestId: bulkRequest.id,
  details: {
    action: get(bulkRequest, 'details.action'),
    sourceState: get(bulkRequest, 'details.targetState'),
    targetState: get(bulkRequest, 'details.sourceState'),
    objects: get(bulkRequest, 'details.success')
  }
});

export type UpdateState = {
  statusId?: null | 5;
  columnId?: null | number;
  priorityId?: 0 | 1 | 2 | 3 | 4;
  dueAt?: null | string;
  tagIds?: number[];
  assigneeIds?: number[];
};

export type BulkRequestActions =
  | 'status_changed'
  | 'archived'
  | 'assigned'
  | 'tagged'
  | 'severity_updated'
  | 'due_date_updated';

export type NewStateUpdateBulkRequestArgs = {
  taskIds: number[];
  targetState: UpdateState;
  actionsPerformed: BulkRequestActions[];
};

export const newStateUpdateBulkRequest = ({
  taskIds,
  targetState
}: NewStateUpdateBulkRequestArgs) => ({
  objectAction: 'update_task',
  objectType: 'tasks',
  projectId: getCurrentProjectId(),
  details: {
    objects: taskIds,
    targetState: targetState
  }
});
