import React from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Modal, message } from 'antd';
import Avatar from '../../../clients/shared/Avatar';
import classnames from 'classnames/bind';
import EditAccess from './editAccess';
import { X as Remove } from 'lucide-react';
import Loader from 'components/loader';
import styles from './index.module.css';

const Option = Select.Option;
const confirm = Modal.confirm;

const cx = classnames.bind(styles);

export default class User extends React.Component {
  static propTypes = {
    member: PropTypes.object,
    authenticityToken: PropTypes.string,
    container: PropTypes.func,
    endpoints: PropTypes.object,
    onRemovedUser: PropTypes.func,
    success: PropTypes.object,
    roles: PropTypes.array,
    project: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onUpdateUser: PropTypes.func.isRequired,
    searchTerm: PropTypes.string,
    user: PropTypes.object,
    lastUser: PropTypes.bool,
    projects: PropTypes.array
  };

  state = {
    saving: false,
    show: false,
    role: this.props.member.role || 'Unset',
    removing: false
  };

  responseError = 'Something went wrong. Please try again.';

  onRoleChange = (role, { id, email }) => {
    const request = () => {
      const { authenticityToken, endpoints, success } = this.props;

      const headers = {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      };

      this.setState({
        saving: true,
        role: role
      });
      fetch(endpoints.updateUser, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({
          user_id: id,
          organization_member: { role: role }
        })
      })
        .then(response => {
          if (response.status !== 200 && response.status !== 302) {
            throw Error(response.statusText);
          } else {
            message.success(`${email}${success.role}`);
            this.setState({
              saving: false
            });
          }
        })
        .catch(error => {
          message.error(this.responseError);

          this.setState({
            saving: false
          });
        });
    };

    if (role === 'owner') {
      Modal.confirm({
        title: 'Do you want to transfer ownership?',
        content:
          "Changing the organization owner means you'll no longer be the owner of the organization.",
        onOk: request,
        getContainer: this.props.container
      });
    } else {
      request();
    }
  };

  getPermission = (user = this.props.user) =>
    this.props.roles.find(role => role.value === user.role).permission;

  rolesAvailableToSet = roles => {
    const currentUserPermission = this.getPermission();
    return roles
      .filter(role => role.value !== 'guest')
      .filter(role => role.permission <= currentUserPermission);
  };

  canChangeMembersRole = member =>
    this.getPermission(member) > this.getPermission();

  removeUser = member => {
    const {
      authenticityToken,
      endpoints,
      onRemovedUser,
      success,
      container
    } = this.props;

    const removeRequest = ({ id, email }) => {
      const headers = {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      };

      this.setState({
        removing: true
      });

      fetch(endpoints.removeUser, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify({ user_id: id })
      })
        .then(response => {
          if (response.status !== 200 && response.status !== 302) {
            throw Error(response.statusText);
          } else {
            this.setState({
              removing: false
            });
            message.success(`${email}${success.remove}`);
            onRemovedUser(id);
          }
        })
        .catch(error => {
          message.error(this.responseError);
          this.setState({
            removing: false
          });
        });
    };

    confirm({
      title: 'Remove User?',
      content: 'Are you sure you want to remove this user?',
      okText: 'Yes',
      cancelText: 'No',
      getContainer: container,
      onOk() {
        removeRequest(member);
      },
      onCancel() {}
    });
  };

  handleResendInvite = ({ id, role, email }) => {
    const { endpoints, authenticityToken, success } = this.props;
    let endpoint = endpoints.sendInvite;
    let _role = role;
    if (_role !== 'guest' && _role !== 'collaborator') {
      _role = 'member';
    }
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': authenticityToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        role: _role,
        user_id: id,
        send_email: 1,
        ...(this.props.project ? { project_id: this.props.project.id } : {})
      })
    })
      .then(response => {
        if (response.status !== 200 && response.status !== 302) {
          throw Error(response.statusText);
        } else {
          message.success(`${email}${success.resend}`);
        }
      })
      .catch(error => message.error(this.responseError));
  };

  toggleModal = () => {
    this.setState({
      show: !this.state.show
    });
  };

  getRoleDisplay = member => {
    if (member.owner) {
      return 'Owner';
    }
    return this.props.roles.find(role => role.value === member.role).display;
  };

  getHighlightedElements = (value, isEmail) => {
    const _searchTerm = this.props.searchTerm;

    if (
      !_searchTerm ||
      !value.toLowerCase().includes(_searchTerm.toLowerCase())
    ) {
      return (
        <span
          className={cx({
            emailUnmatched: isEmail,
            displayUnmatched: !isEmail
          })}
        >
          {value}
        </span>
      );
    }

    const searchTerm = _searchTerm.toLowerCase();
    const name = value.toLowerCase();
    const splits = name.split(searchTerm);
    const startOfMatch = [];

    for (var i = 0; i < splits.length - 1; i++) {
      if (!startOfMatch.length) {
        startOfMatch.push(name.indexOf(searchTerm));
      } else {
        startOfMatch.push(name.indexOf(searchTerm, startOfMatch[i - 1] + 1));
      }
    }

    const nonMatches = [];

    for (var j = 0; j <= startOfMatch.length; j++) {
      let startOfNonMatch;
      let endOfNonMatch;

      if (j === 0) {
        startOfNonMatch = 0;
      } else {
        startOfNonMatch = startOfMatch[j - 1] + searchTerm.length;
      }

      if (j === startOfMatch.length) {
        endOfNonMatch = value.length - 1;
      } else {
        endOfNonMatch = startOfMatch[j] - 1;
      }

      nonMatches.push([startOfNonMatch, endOfNonMatch]);
    }

    return nonMatches.map((nonMatch, index) => {
      const nonMatchString = value.slice(nonMatch[0], nonMatch[1] + 1);

      const searchTermString = value.slice(
        nonMatch[1] + 1,
        nonMatch[1] + 1 + searchTerm.length
      );

      const isLast = !isEmail && nonMatches.length - 1 === index;

      return (
        <span
          className={cx({
            emailMatched: isEmail,
            displayMatched: !isEmail,
            isLast
          })}
          key={nonMatchString + nonMatch[1]}
        >
          {nonMatchString}
          <span className={styles.matchedTerm}>{searchTermString}</span>
        </span>
      );
    });
  };

  getUserDisplayInfo = member => (
    <span className={styles.userDisplay}>
      {member.display_name}
      {member.display_name !== member.email && (
        <span className={styles.userEmail}>({member.email})</span>
      )}
    </span>
  );

  getTitle = member => {
    if (member.display_name !== member.email) {
      return member.display_name + ',  (' + member.email + ')';
    }
    return member.display_name;
  };

  render() {
    const {
      member,
      project,
      roles,
      container,
      endpoints,
      authenticityToken,
      onUpdateUser,
      lastUser,
      projects
    } = this.props;

    const { email } = member;
    const displayName = member.display_name;
    const confirmed = member.account_confirmed;
    const accessIsEditable = !member.role.includes('manager');

    return (
      <div className={cx({ membersBox: true, lastUser })} key={member.id}>
        {this.state.show && (
          <EditAccess
            container={container}
            member={member}
            show={this.state.show}
            endpoint={endpoints.updateUserProject}
            toggleModal={this.toggleModal}
            authenticityToken={authenticityToken}
            onUpdateUser={onUpdateUser}
            projects={projects}
          />
        )}
        <div className={styles.membersLeft}>
          <Avatar member={member} />
          <span className={styles.membersName}>
            <span className={styles.displayName}>
              <div
                id="displayContainer"
                className={styles.innerDisplayName}
                title={this.getTitle(member)}
              >
                {project
                  ? this.getUserDisplayInfo(member)
                  : this.getHighlightedElements(displayName, false)}
                {!project && displayName !== email && (
                  <span className={styles.innerEmail}>
                    ({this.getHighlightedElements(email, true)})
                  </span>
                )}
              </div>
              {!confirmed && !member.owner && (
                <span className={styles.notConfirmed}>
                  (Invite not yet accepted)
                </span>
              )}
            </span>
          </span>
        </div>
        <div className={styles.userSettings}>
          {confirmed ? (
            <div className={styles.controlsBox}>
              {!project && accessIsEditable && (
                <div className={styles.editBox}>
                  <Button
                    type="secondary"
                    className={styles.editAccess}
                    size="small"
                    onClick={this.toggleModal}
                  >
                    Edit project access
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={cx(['controlsBox', 'controlsPadded'])}>
              {!member.owner && (
                <div className={styles.editBox}>
                  <Button
                    className={styles.secondaryButton}
                    type="secondary"
                    onClick={() => this.handleResendInvite(member)}
                    size="small"
                  >
                    Re-send Invite
                  </Button>
                </div>
              )}
            </div>
          )}
          {project || member.owner || this.canChangeMembersRole(member) ? (
            <span>{this.getRoleDisplay(member)}</span>
          ) : (
            <div className={styles.selectBox}>
              <Select
                disabled={this.state.saving}
                className={styles.roleSelect}
                getPopupContainer={container}
                value={this.state.role}
                onChange={value => this.onRoleChange(value, member)}
                dropdownMatchSelectWidth={false}
              >
                {this.rolesAvailableToSet(roles).map(role => {
                  return (
                    <Option value={role.value} key={role.value}>
                      {role.display}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}

          {!this.state.removing ? (
            <Remove
              className={styles.removeIcon}
              onClick={() => this.removeUser(member)}
              style={{
                visibility: !project && member.owner ? 'hidden' : 'visible'
              }}
            />
          ) : (
            <Loader size="small" />
          )}
        </div>
      </div>
    );
  }
}
