import * as React from 'react';
import { Select, Spin, List } from 'antd';

import styles from './index.module.css';
import { useScroll } from './fetchOnScroll';
import FilterContext from '../UserTasks/filterContext';
export interface SelectItemProps {
  label: string;
  value: string;
}

interface Props {
  options: SelectItemProps[];
  isLoading?: boolean;
  onSelectChange: (value: string[]) => void;
  selectedValue: string[];
  fetchMore?: (isForward: boolean) => void;
  onSelectSearch?: (value: string) => void;
}

const MultiSelectList: React.FC<Props> = ({
  options,
  isLoading,
  onSelectChange,
  selectedValue,
  fetchMore,
  onSelectSearch
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(
    selectedValue
  );
  const [loading, setLoading] = React.useState<boolean>(!!isLoading);
  const filterRef = React.useContext(FilterContext);

  const onPopupScroll = fetchMore
    ? (event: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScroll({ event, fetchMore, loading });
      }
    : undefined;

  React.useEffect(() => {
    setSelectedOptions(selectedValue);
  }, [selectedValue]);

  React.useEffect(() => {
    setLoading(!!isLoading);
  }, [isLoading]);

  const handleOnFocus = () => {
    if (filterRef && filterRef.current && window.innerWidth < 430) {
      filterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className={styles.multiSelectFilter}>
      <Select
        filterOption={false}
        showSearch={!!onSelectSearch}
        onFocus={handleOnFocus}
        className={styles.multiSelect}
        loading={isLoading}
        options={options}
        value={selectedOptions}
        mode="multiple"
        dropdownMatchSelectWidth
        onChange={value => {
          setSelectedOptions(value);
          onSelectChange(value);
        }}
        dropdownRender={menu => (
          <>
            {loading && <Spin size="small" />}
            <List>{menu}</List>
          </>
        )}
        onSearch={onSelectSearch}
        onPopupScroll={onPopupScroll}
      />
    </div>
  );
};

export default MultiSelectList;
