import React, { useState, FC } from 'react';
import styles from './index.module.css';
import Assets from './Assets';
import { ArchivedFilter } from './ArchivedFilter';
import {
  useDesignAssetsState,
  ASSETS_SUPPORT_ARTICLE_ID
} from '../providers/DesignAssets';
import HelpArticleButton from 'appJS/utils/HelpArticleButton';
import { ShareModal } from './ShareModal';
import { GroupNameModal } from './GroupNameModal';
export const UNGROUPED = 'Ungrouped';
import { default as DesignLogo } from './assets/design_icon.svg';
import SearchAndFilter from './SearchAndFilter';

export const AssetsScreen: FC = () => {
  const {
    data,
    container,
    bugherdUrl,
    handleSearchAndSort,
    setShowShareModal,
    showShareModal,
    project,
    isGroupNameModalOpen,
    setIsGroupNameModalOpen,
    setGroupName,
    groupName,
    onAddToGroup
  } = useDesignAssetsState();

  const [showArchived, setShowArchived] = useState<boolean>(false);
  const assets = handleSearchAndSort();
  const numberOfActiveAssets = assets.filter(
    asset => asset.status !== 'archived'
  ).length;

  const assetParameter =
    typeof showShareModal === 'string'
      ? `show_group?group_name=${encodeURI(showShareModal)}`
      : showShareModal;

  return (
    <>
      <ShareModal
        open={!!showShareModal}
        closeModal={() => setShowShareModal(false)}
        shareURL={`${bugherdUrl}/projects/${project.id}/assets/${assetParameter}`}
        container={container}
        canInviteToProject
      />
      <GroupNameModal
        onAddToGroup={onAddToGroup}
        setIsGroupNameModalOpen={setIsGroupNameModalOpen}
        setGroupName={setGroupName}
        isGroupNameModalOpen={isGroupNameModalOpen}
        container={container}
        groupName={groupName}
      />

      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>
          <DesignLogo />
          <h2>Designs</h2>
        </div>
        <div className={styles.archiveSearchWrapper}>
          <SearchAndFilter />
          <ArchivedFilter
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            numberOfActiveAssets={numberOfActiveAssets}
          />
          <HelpArticleButton articleId={ASSETS_SUPPORT_ARTICLE_ID} />
        </div>
      </div>

      <Assets assets={assets} data={data} disabled={showArchived} />
    </>
  );
};
