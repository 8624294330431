import React from 'react';
import styles from './index.module.css';
import { Input, message } from 'antd';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import { CommitProps } from './types';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

export const CommitHooks = ({
  bitBucketHook,
  gitHubHook,
  gitLabHook,
  projectId,
  organizationId
}: CommitProps) => {
  message.config({
    top: 100,
    duration: 5,
    maxCount: 3
  });

  const handleCopy = (label, value) => {
    navigator.clipboard.writeText(`${value}`);
    message.success(`${label} key copied to clipboard!`);
  };

  return (
    <div className={styles.commitWrapper}>
      <p className={styles.about}>
        {strings.commitIntro}
        <HelpArticleButton articleId="33869" />
      </p>
      <div className={styles.textBlock}>
        <h3 className={styles.subHeader}>GitHub:</h3>
        <label htmlFor="github" className={styles.label}>
          {strings.commitGit}
        </label>
        <div className={styles.urlInputBox}>
          <Input
            id="github"
            className={styles.urlInput}
            readOnly
            value={gitHubHook}
          />
          <span
            className={styles.copy}
            onClick={() => handleCopy('GitHub', gitHubHook)}
          >
            {strings.copy}
          </span>
        </div>
      </div>
      <div className={styles.textBlock}>
        <h3 className={styles.subHeader}>GitLab:</h3>
        <label htmlFor="gitlab" className={styles.label}>
          {strings.commitGitlab}
        </label>
        <div className={styles.urlInputBox}>
          <Input
            id="gitlab"
            className={styles.urlInput}
            readOnly
            value={gitLabHook}
          />
          <span
            className={styles.copy}
            onClick={() => handleCopy('GitLab', gitLabHook)}
          >
            {strings.copy}
          </span>
        </div>
      </div>
      <div className={styles.textBlock}>
        <h3 className={styles.subHeader}>Bitbucket:</h3>
        <label htmlFor="bitbucket" className={styles.label}>
          {strings.commitBitbucket}
        </label>
        <div className={styles.urlInputBox}>
          <Input
            id="bitbucket"
            className={styles.urlInput}
            value={bitBucketHook}
            readOnly
          />
          <span
            className={styles.copy}
            onClick={() => handleCopy('BitBucket', bitBucketHook)}
          >
            {strings.copy}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommitHooks;
