import React from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Lock } from 'lucide-react';
import cx from 'classnames';

import styles from '../index.module.css';

export default class NavItem extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['sub', 'my', 'all', 'lock']),
    count: PropTypes.number,
    isSelected: PropTypes.bool,
    isChildSelected: PropTypes.bool,
    tooltip: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {},
    type: 'top',
    isChildSelected: null,
    isSelected: null,
    tooltip: null,
    count: null
  };

  constructor(props) {
    super(props);
    this.navItem = React.createRef();
  }

  componentDidMount() {
    // Timeout exists because of race-condition, likely to do with 'react-measure'
    // from the 'subFilter_list' component. Can come back and fix this.

    setTimeout(this.scrollToActiveFilter, 2000);
  }

  scrollToActiveFilter = () => {
    const { isSelected, type } = this.props;
    if (isSelected && type === 'sub') {
      scrollIntoView(this.navItem.current, {
        scrollMode: 'if-needed',
        behavior: 'smooth'
      });
    }
  };

  render() {
    const {
      name,
      type,
      count,
      onClick,
      isSelected,
      isChildSelected,
      tooltip
    } = this.props;
    return (
      <div
        ref={this.navItem}
        className={cx({
          [styles.listContainer]: true,
          [styles[type + 'ListItem']]: ['my', 'all', 'lock'].includes(type),
          [styles.expandedOuter]: isSelected || isChildSelected,
          navLocked: type === 'lock'
        })}
        onClick={onClick}
        data-tooltip={tooltip}
      >
        <div
          className={cx({
            [styles.listInner]: true,
            [styles.activeFilter]: isSelected,
            [styles.expandedFilter]: isChildSelected
          })}
        >
          <div
            className={cx({
              [styles.filterHead]: true,
              [styles[type]]: true
            })}
            onClick={onClick}
          >
            {type === 'lock' && <Lock className={styles.lockIcon} />}
            <span className={styles.filterName}>{name}</span>
            {count != null && (
              <strong
                className={cx([styles.count], {
                  [styles.activeCount]: isSelected
                })}
              >
                {count}
              </strong>
            )}
          </div>
        </div>
      </div>
    );
  }
}
