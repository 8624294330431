export const en = {
  openWebsite: 'Open website',
  uploadAsset: 'Upload an asset',
  uploadFigma: 'Upload a Figma file',
  completeGettingStartedSuccess:
    "All set! You've completed the Setup guide tasks.",
  completeGettingStartedError:
    'Something went wrong! Please refresh & try again.'
};

export type Translations = typeof en;
