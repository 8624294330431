import { message } from 'antd';

export const createIntegration = async (
  organizationId: string,
  code: string
) => {
  const endpoint = `/organizations/${organizationId}/integrations/new/${code}`;
  try {
    const response = await fetch(endpoint, {
      method: 'GET'
    });
    const data = await response.json();
    if (response.status !== 200 && response.status !== 302) {
      throw new Error(response.statusText);
    } else {
      window.location.pathname = `/organizations/${organizationId}/integrations/${data.id}/edit`;
    }
  } catch (err) {
    message.error('Something went wrong, please try again.', 2.5);
  }
};

export const editIntegration = (
  organizationId: string,
  integrationId: number
) => {
  window.location.pathname = `/organizations/${organizationId}/integrations/${integrationId}/edit`;
};
