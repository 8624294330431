import React, { useEffect, useState } from 'react';
import { Tabs, Alert, TabsProps } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import General from '../general';
import Team from '../team';
import Integrations from '../integrations';
import styles from './index.module.css';
import Billing from '../billing';
import Statements from '../statements';
import Templates from '../templates';

type Props = {
  organizations: any[];
  access_to: any;
  container: (x: unknown) => unknown;
  onOrganizationChange: (x: unknown) => unknown;
  authenticityToken: string;
  user: {};
  avatarUrl: string;
};

const Organization = ({
  organizations,
  access_to,
  container,
  onOrganizationChange,
  authenticityToken,
  user,
  avatarUrl
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tempZapierData, setTempZapierData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const handleTabClick = key => {
    navigate(`/organizations/${params.id}/${key}`);
  };

  const getChildData = value => {
    if ('isLoading' in value) {
      setIsLoading(value.isLoading);
    }

    if ('tempZapierData' in value) {
      setTempZapierData(value.tempZapierData);
    }
  };

  const getOrgId = () => parseInt(params.id, 10);

  const getCurrentOrganization = () =>
    organizations.find(value => value.organization.id === getOrgId());

  const location = useLocation();

  const urlChunks = location.pathname.split('/');
  const activeKey = urlChunks[3];
  const orgId = getOrgId();
  const org = getCurrentOrganization()?.organization;
  const accessTo = access_to.find(value => value.organization_id === orgId);
  const [items, setItems] = useState<TabsProps['items']>([]);

  useEffect(() => {
    const tempItems: TabsProps['items'] = [];
    tempItems.push(
      {
        key: 'edit',
        label: 'General',
        children: (
          <General
            orgId={orgId}
            access_to={access_to}
            onOrganizationChange={onOrganizationChange}
            organizations={organizations}
            container={container}
            authenticityToken={authenticityToken}
            user={user}
            avatarUrl={avatarUrl}
          />
        )
      },
      {
        key: 'templates',
        label: 'Templates',
        children: <Templates orgId={orgId} />
      }
    );
    if (accessTo.team) {
      tempItems.push({
        key: 'team',
        label: 'Team',
        children: (
          <Team
            orgId={orgId}
            access_to={access_to}
            onOrganizationChange={onOrganizationChange}
            organizations={organizations}
            container={container}
            authenticityToken={authenticityToken}
            user={user}
            avatarUrl={avatarUrl}
          />
        )
      });
    }

    if (accessTo.integrations) {
      tempItems.push({
        key: 'integrations',
        label: 'Integrations',
        children: (
          <Integrations
            setParentState={getChildData}
            isLoading={isLoading}
            org={getCurrentOrganization()}
            tempZapierData={tempZapierData}
            accessTo={accessTo}
            access_to={access_to}
            onOrganizationChange={onOrganizationChange}
            organizations={organizations}
            container={container}
            authenticityToken={authenticityToken}
            user={user}
            avatarUrl={avatarUrl}
            navigate={navigate}
          />
        )
      });
    }
    if (accessTo.billing) {
      tempItems.push({
        key: 'subscription',
        label: 'Plan & Billing',
        children: <Billing organizationId={org.id} />
      });
    }

    if (accessTo.billing && accessTo.subscription_id) {
      tempItems.push({
        key: 'statements',
        label: 'Invoices',
        children: (
          <Statements
            statements={getCurrentOrganization().statements}
            organizationId={org.id}
          />
        )
      });
    }

    setItems(tempItems);
  }, [accessTo]);

  if (!accessTo.edit) {
    return (
      <div>
        <h2 className={styles.orgHeader}>{`${org.display_name} settings`}</h2>
        <Alert
          type="error"
          message="You don't have access to view this page. Please contact the account owner(s)."
        />
      </div>
    );
  }

  return (
    <div>
      <h2 className={styles.orgHeader}>{`${org.display_name} settings`}</h2>
      <Tabs
        moreIcon={false}
        className={styles.tabsContainer}
        type="card"
        activeKey={activeKey}
        animated={false}
        onTabClick={handleTabClick}
        items={items}
      />
    </div>
  );
};

export default Organization;
