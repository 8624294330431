import React, { FC, useState, ReactEventHandler } from 'react';
import styles from './styles.module.css';
import { XCircle } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { Button, Space, Tooltip } from 'antd';
import cx from 'classnames';
import { WhyModal } from './whyModal';

const strings = translations[getLangKey()];

type Props = {
  isSmallView: boolean;
  error?: {
    message: string;
    details?: {
      code: string;
      message: string;
    };
  };
  onTryAgain: () => void;
  createError: string;
  container: HTMLDivElement;
};

const Error: FC<Props> = ({
  isSmallView,
  error,
  onTryAgain,
  createError,
  container
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal: ReactEventHandler = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={styles.errorContainer}>
      <Space className={styles.space}>
        <Tooltip
          getPopupContainer={() => container}
          title={error?.message || createError}
          placement="bottomLeft"
          overlayClassName={styles.tooltip}
        >
          <XCircle className={styles.errorIcon} />
        </Tooltip>
        <span className={styles.errorText}>
          {isSmallView ? strings.taskNotSent : strings.couldntSend}
        </span>
        <Button
          className={styles.whyButton}
          type="default"
          onClick={showModal}
          title={error?.message}
        >
          {strings.why}
        </Button>
        <WhyModal
          setIsModalOpen={setIsModalOpen}
          error={error}
          createError={createError}
          isModalOpen={isModalOpen}
        />
        <Button
          onClick={onTryAgain}
          type="primary"
          size={isSmallView ? 'small' : 'middle'}
          className={cx(styles.tryAgain, { [styles.smallButton]: isSmallView })}
        >
          {strings.tryAgain}
        </Button>
      </Space>
    </div>
  );
};

export default Error;
