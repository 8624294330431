import React, { useEffect, useState, ReactNode } from 'react';
import { Breadcrumb } from 'antd';
import Separator from 'appJS/assets/images/separator.svg';
import { Organization, Project } from './types';
import styles from './index.module.css';

interface Props {
  organization: Organization;
  organizations: Organization[];
  isMobileView: boolean;
  disabledProjects: Project[];
}

export const BreadCrumbs: React.FC<Props> = ({
  organization,
  organizations,
  isMobileView,
  disabledProjects
}) => {
  const [pathname, setPathname] = useState<string>(window.location.pathname);
  useEffect(() => {
    // @ts-expect-error
    window.setBreadcrumbsPathname = setPathname;
  }, []);
  let defaultTitle = document?.title?.replace(' | BugHerd', '');

  type Item = {
    onClick?: (e: any) => void;
    title: ReactNode;
    className: string;
  };

  const titleItem = ({ title = defaultTitle }: { title?: string }): Item => ({
    title: <span title={title}>{title}</span>,
    className: styles.pageTitle
  });

  const items: Item[] = [];

  const splitPathname = pathname.split('/').filter(_pathname => _pathname);
  const totalParams = splitPathname.length;
  const lastIndex = totalParams - 1;
  const firstParam = splitPathname[0];
  const lastParam = splitPathname[lastIndex];

  const parsedOrganizationId = Number(
    pathname?.match(/\/organizations\/(\d+)/)?.[1]
  );
  const parsedProjectId = Number(pathname?.match(/^\/projects\/(\d+)/)?.[1]);

  const isOrganizationSettings = /\/settings\/organizations\/\d+\/\w+/.test(
    pathname
  );
  const isOrganizationIntegrationsSettings = /\/organizations\/\d+\/integrations\/\d+\/\w+/.test(
    pathname
  );
  const isIntegrationsProject = /\/integrations\/\d+\/projects/.test(pathname);

  const hasOrganizationName = parsedOrganizationId || parsedProjectId;

  const currentOrganization = organizations.find(
    ({ id }) => id === parsedOrganizationId
  );

  const isDisabledProject = !!disabledProjects?.find(
    ({ id }) => id === parsedProjectId
  );

  if (isDisabledProject) {
    defaultTitle += ' (Disabled)';
  }

  const organizationNameItem = (): Item => {
    const title = (currentOrganization || organization)?.name;
    return {
      title: (
        <span id="org-name-title" title={title}>
          {title}
        </span>
      ),
      className: styles.organizationName
    };
  };

  const titles: { [key: string]: string } = {
    settings: 'Settings',
    projects: 'My Projects',
    dashboard: 'Dashboard',
    notifications: 'Notifications',
    profile: 'Profile'
  };

  const lastTitle = titleItem({ title: titles[lastParam] });
  const settingsItem = titleItem({ title: titles.settings });
  const orgNameItem = organizationNameItem();
  const defaultTitleItem = titleItem({});

  if (hasOrganizationName) {
    items.push(orgNameItem);
    if (parsedProjectId) {
      items.push(defaultTitleItem);
    } else if (
      isOrganizationSettings ||
      isOrganizationIntegrationsSettings ||
      isIntegrationsProject
    ) {
      items.push(settingsItem);
    } else {
      items.push(lastTitle);
    }
  } else if (firstParam === 'settings') {
    items.push(settingsItem);
    items.push(lastTitle);
  }

  //Todo display breadcrumbs one more level deep after project settings page is ready

  const mobileItems = [
    {
      title: <span>{defaultTitle}</span>,
      className: styles.mobileTitle
    }
  ];

  let breadcrumbItems: Item[];
  if (isMobileView) {
    breadcrumbItems = mobileItems;
  } else if (items.length) {
    breadcrumbItems = items;
  } else {
    breadcrumbItems = [defaultTitleItem];
  }

  return (
    <Breadcrumb
      separator={<Separator />}
      items={breadcrumbItems}
      className={styles.breadcrumbsContainer}
    />
  );
};
