export const en = {
    youAreCurrentlyUsing: 'You are currently using',
    outOf: 'out of ',
    teamMembers: 'Team members',
    members: 'Members',
    followingPeople: 'The following people have access to projects within this Organization.',
    needMore: 'Need more? ',
    upgradeHere: 'Upgrade here',

  };
  
  export type Translations = typeof en;
  