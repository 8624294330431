export const en = {
  customLogo: 'Custom Logo',
  replaceLogo:
    'Replace the BugHerd logo with your own throughout the UI and any emails that are sent to you and your clients. ',
  justSoYouKnow: 'Just so you know: ',
  onlyAvailable: 'This feature is normally only available on our ',
  premium: 'Premium ',
  planAndAbove: 'plan and above.',
  goCrazy: `But while you're still in your trial period, go crazy!`,
  customizingLogoNotAvailable: 'Customizing the logo is not available on your plan. To enable this feature ',
  upgradeYourPlan: 'upgrade your plan',
  contactTheAccount: ' contact the account ',
  owner: 'owner',
  ownersEmailAddress: 'The owner\'s email address',
  hasBeenCopied: 'has been copied to the clipboard',
};

export type Translations = typeof en;
