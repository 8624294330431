export const en = {
  integrations: 'Integrations',
  connectBugherdTo: 'Connect BugHerd to 4000+ apps via Zapier',
  zapierAbout:
    'Instantly connect BugHerd with 1,500+ apps to automate your work and find productivity super powers.',
  slackAbout:
    'Send notifications directly to a Slack channel, making it easier for everyone on your Slack team to keep up to date with submitted tasks, comments and more.',
  githubAbout:
    'Create GitHub issues for each bug in a nominated repository and keep them in sync.',
  bcxAbout:
    'Create To-do list items for each bug in a nominated To-do list and keep them in sync.',
  harvestAbout: 'Add time tracking to your BugHerd tasks through Harvest.'
};

export type Translations = typeof en;
