import * as DateFns from 'date-fns';

export type SortableProject = {
  id: number;
  name: string;
  updatedAt: string;
};

export default ({
  projects,
  separateGroupLimit = 8,
  numberOfTopMostRecent = 5
}: {
  projects: SortableProject[];
  separateGroupLimit?: number;
  numberOfTopMostRecent?: number;
}) => {
  const today = new Date();
  const fourWeeksAgo = DateFns.subWeeks(today, 4).toISOString();

  const sortedLexically = projects.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();

    return aName == bName ? 0 : aName < bName ? -1 : 1;
  });

  if (projects.length <= separateGroupLimit) {
    return [[], sortedLexically];
  }

  const recentProjects = projects
    .filter(project => project.updatedAt > fourWeeksAgo)
    .sort((a, b) =>
      a.updatedAt == b.updatedAt ? 0 : a.updatedAt < b.updatedAt ? -1 : 1
    )
    .reverse()
    .slice(0, numberOfTopMostRecent);

  return [recentProjects, sortedLexically];
};
