import React from 'react';
import { Tooltip } from 'antd';
import {
  CloseCircleFilled,
  ExclamationCircleFilled,
  CheckCircleFilled
} from '@ant-design/icons';

import { getLangKey } from 'models/Language';
import * as translations from './strings';
import styles from './style.module.css';
import { ValidateStatus } from 'utils/validateUrls';
import Loader from 'components/loader';
import HelpArticleButton from 'utils/HelpArticleButton';

type Props = {
  validateStatus: ValidateStatus;
  validationMessage?: string;
  container: () => HTMLElement;
};

const ValidationStateIcon: React.FC<Props> = ({
  validateStatus,
  validationMessage,
  container
}: Props) => {
  const strings = translations[getLangKey()];

  return (
    <span>
      {validateStatus === ValidateStatus.Validating && (
        <Loader size={'small'} />
      )}
      {validateStatus === ValidateStatus.Warning && (
        <Tooltip
          getPopupContainer={container}
          title={
            <span>
              {validationMessage}
              {' ('}
              <HelpArticleButton articleId={'84878'}>
                <span>{strings.help}</span>
              </HelpArticleButton>{' '}
              {') '}
            </span>
          }
          className={styles.validationIconWarn}
        >
          <ExclamationCircleFilled size={10} />
        </Tooltip>
      )}
      {validateStatus === ValidateStatus.Error && (
        <CloseCircleFilled size={10} />
      )}
      {validateStatus === ValidateStatus.Success && (
        <CheckCircleFilled className={styles.validationIconSuccess} size={10} />
      )}
    </span>
  );
};

export default ValidationStateIcon;
