type ReaderTypeFunction = 'arrayBuffer' | 'binaryString' | 'dataURL' | 'text';

type FileLoaderProps = {
  url: string;
  onLoaded: () => void;
  setFileLoader: (fileLoader: any) => void;
  readerTypeFunction: ReaderTypeFunction;
};

export const fileLoader = ({
  url,
  onLoaded,
  setFileLoader,
  readerTypeFunction
}: FileLoaderProps) => {
  return fetch(url)
    .then(async res => {
      const blob = await res.blob();

      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', () => {
        onLoaded();
        setFileLoader(fileReader.result);
      });

      switch (readerTypeFunction) {
        case 'arrayBuffer':
          fileReader.readAsArrayBuffer(blob);
          break;
        case 'binaryString':
          fileReader.readAsBinaryString(blob);
          break;
        case 'dataURL':
          fileReader.readAsDataURL(blob);
          break;
        case 'text':
          fileReader.readAsText(blob);
          break;

        default:
          break;
      }
    })
    .catch(e => {
      return e;
    });
};
