import Config from 'models/Config';
import {
  getPusher,
  subscribeProjectChannel,
  unsubscribeProjectChannel,
  Event,
  Subscription,
  OnSubscribeProps
} from 'models/Pusher';

import { Project } from 'models/Project';

type SetupPusherProps = {
  config: Config;
  projects: Project[];
  onUpdate: () => void;
};

export const setupPusher = ({
  config,
  projects,
  onUpdate
}: SetupPusherProps) => {
  const pusher = getPusher(config.pusherApiKey, {
    authEndpoint: config.pusherChannelAuthEndpoint
  });

  const onSubscribe = ({ channel }: OnSubscribeProps) => {
    channel.bind(Event.PROJECT_UPDATE, onUpdate);
  };

  projects.forEach(project =>
    subscribeProjectChannel({
      pusher,
      project,
      subscription: Subscription.USER_PROJECTS,
      onSubscribe
    })
  );
};

export const teardownPusher = ({
  config,
  projects
}: {
  config: Config;
  projects: Project[];
}) => {
  const pusher = getPusher(config.pusherApiKey, {
    authEndpoint: config.pusherChannelAuthEndpoint
  });

  projects.forEach(project => {
    unsubscribeProjectChannel({
      pusher,
      project,
      subscription: Subscription.USER_PROJECTS
    });
  });
};
