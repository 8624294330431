import ipaddr from 'ipaddr.js';

const HOSTNAME_MAX_LENGTH = 253;
export enum ValidateStatus {
  Default = '',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Validating = 'validating'
}

const validationMessage = {
  InvalidError: 'Invalid URL',
  DNSNotFound:
    `We couldn't find this website. It's no big deal, we'll still save it. Maybe it's not live ` +
    `yet but just double check it in case you made a mistake.`,
  DNSNotFoundError: `We couldn't find this website. Please try again with a valid website`,
  InvalidHostname: `Please enter a valid URL format`,
  HostnameTooLong: `The URL is too long`
};

type UrlValidation = {
  status: ValidateStatus;
  message: string;
};

const isHostnameValid: (hostname: string) => boolean = hostname => {
  const hostnameRegEx = new RegExp(
    '^(localhost|(?!://)([a-z0-9-_~]+\\.)+[a-z-]{2,})$',
    'i'
  );
  return hostnameRegEx.test(hostname);
};

const isValidDNS = async (hostname: string) => {
  const res = await fetch(
    `https://dns.google/resolve?type=A&name=${encodeURIComponent(hostname)}`
  );
  const body = await res.json();
  const NO_ERROR = 0;
  const NO_DOMAIN_ERROR = 3;
  if (body.Status === NO_ERROR) {
    return true;
  } else if (body.Status === NO_DOMAIN_ERROR) {
    return false;
  } else {
    return false; // some other dns error occurred, ignore it.
  }
};

export const validateUrl: (
  url: string,
  showWarningAsError?: boolean
) => Promise<UrlValidation> = async (
  url: string,
  showWarningAsError = false
) => {
  let parsedUrl: URL;
  try {
    parsedUrl = new URL(url);
    if (!parsedUrl) {
      return {
        status: ValidateStatus.Error,
        message: validationMessage.InvalidError
      };
    }
  } catch (e) {
    return {
      status: ValidateStatus.Error,
      message: validationMessage.InvalidError
    };
  }

  if (parsedUrl.hostname.length > HOSTNAME_MAX_LENGTH)
    return {
      status: ValidateStatus.Error,
      message: validationMessage.HostnameTooLong
    };

  // If it's an ipv4 address just assume it's fine
  if (ipaddr.isValid(parsedUrl.hostname)) {
    return { status: ValidateStatus.Success, message: '' };
  }

  if (!isHostnameValid(parsedUrl.hostname)) {
    return {
      status: ValidateStatus.Error,
      message: validationMessage.InvalidHostname
    };
  }

  try {
    // dns query to check hostname makes sense
    return (await isValidDNS(parsedUrl.hostname))
      ? { status: ValidateStatus.Success, message: '' }
      : {
          status: showWarningAsError
            ? ValidateStatus.Error
            : ValidateStatus.Warning,
          message: showWarningAsError
            ? validationMessage.DNSNotFoundError
            : validationMessage.DNSNotFound
        };
  } catch (e) {
    return {
      status: showWarningAsError
        ? ValidateStatus.Error
        : ValidateStatus.Warning,
      message: showWarningAsError
        ? validationMessage.DNSNotFoundError
        : validationMessage.DNSNotFound
    };
  }
};
