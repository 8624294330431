import React from 'react';
import { Modal, Tabs } from 'antd';
import { ApolloProvider, useQuery } from '@apollo/client';

import client from 'api/apollo/client';
import Loader from 'components/loader';
import { trackPage } from 'utils/analytics';
import attachListenerOnMultipleNodes from 'utils/domListeners';

import General from './forms/general/general';
import Security from './forms/security/security';
import Sidebar from './forms/sidebar';
import Advanced from './forms/advanced/advanced';
import styles from './index.module.css';
import { ContainerProvider, ContainerType } from './containerContext';
import { PROJECT_QUERY } from './queries';
import EditTags from './forms/tags';

const TabPane = Tabs.TabPane;
interface Props {
  project: {
    organizationId: string;
    id: string;
  };
  mobileScreenshots: boolean;
  guestsSeeGuests: boolean;
}

const ProjectSettings: React.FC<Props> = (props: Props) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('General');

  const { loading, data, refetch } = useQuery(PROJECT_QUERY, {
    variables: { projectId: props.project.id }
  });

  const hideModal = () => {
    const { id, organizationId } = props.project;
    if (!show) {
      trackPage('Project settings popup', {
        organization_id: organizationId,
        project_id: id
      });
    }
    setShow(false);
    window.location.reload();
  };

  React.useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show, refetch]);

  React.useEffect(() => {
    attachListenerOnMultipleNodes(
      "[data-trigger='project_settings']",
      (element: Element) =>
        element.addEventListener('click', () => {
          setShow(true);
        })
    );
    // @ts-ignore
    window.project_settings_props.setShow = setShow;
    // @ts-ignore
    window.project_settings_props.setActiveTab = setActiveTab;
  }, []);

  const project = data?.currentUser?.userProject;
  const isAdminUser = data?.currentUser?.admin;
  const getContainer = document.getElementById('project_settings_container');
  const container: ContainerType = getContainer;

  return (
    <Modal
      open={show}
      className={styles.modalContainer}
      getContainer={getContainer as HTMLElement}
      footer={null}
      title="Project Settings"
      destroyOnClose
      onCancel={hideModal}
    >
      <div ref={modalRef} className={styles.container}>
        {loading ? (
          <Loader spinning={loading} />
        ) : (
          <Tabs
            className={styles.overrideTextShadow}
            type="card"
            defaultActiveKey="General"
            activeKey={activeTab}
            onTabClick={setActiveTab}
          >
            <TabPane tab="General" key="General">
              <ContainerProvider value={container}>
                <General
                  project={project}
                  onCancel={hideModal}
                  accessTo={{
                    guestsSeeGuestsAccess: props.guestsSeeGuests
                  }}
                  refetch={refetch}
                />
              </ContainerProvider>
            </TabPane>
            <TabPane tab="Security" key="Security">
              <Security
                project={project}
                accessTo={{
                  mobileScreenshots: props.mobileScreenshots
                }}
                onSave={hideModal}
                onCancel={hideModal}
              />
            </TabPane>
            <TabPane tab="Sidebar" key="Sidebar">
              <Sidebar
                project={project}
                onCancel={hideModal}
                isAdminUser={isAdminUser}
              />
            </TabPane>
            <TabPane tab="Tags" key="Tags">
              <EditTags
                projectId={project.id}
                createTagsViaNewTask={project.createTagsViaNewTask}
              />
            </TabPane>
            {project.organizationId === '1' && (
              <TabPane tab="Advanced" key="Advanced">
                <Advanced
                  refetch={refetch}
                  project={project}
                  onCancel={hideModal}
                />
              </TabPane>
            )}
          </Tabs>
        )}
      </div>
    </Modal>
  );
};

export default (props: Props) => (
  <ApolloProvider client={client}>
    <ProjectSettings {...props} />
  </ApolloProvider>
);
