import { Coordinates } from '../../clients/sidebar/views/CreateTask/create_task/types/task';

type Arguments = {
  containerWidth: number;
  containerHeight: number;
  isCroppedView: boolean;
  coordinates: Coordinates;
  pinUrl: string;
  isScreenshotServer: boolean;
};

export default ({
  containerWidth,
  containerHeight,
  isCroppedView,
  coordinates,
  pinUrl,
  isScreenshotServer
}: Arguments) => {
  const getPinAndDimensions = () => {
    const {
      visualViewport,
      scrollY,
      scrollX,
      screenshotWindowFlagY,
      screenshotWindowFlagX,
      screenshotWindowHeight,
      screenshotWindowWidth
    } = coordinates;

    let pinX = screenshotWindowFlagX;
    let pinY = screenshotWindowFlagY;
    let viewportWidth = screenshotWindowWidth;
    let viewportHeight = screenshotWindowHeight;

    if (
      visualViewport &&
      visualViewport.pinX &&
      visualViewport.pinY &&
      visualViewport.width &&
      visualViewport.height
    ) {
      pinX = visualViewport.pinX - scrollX;
      pinY = visualViewport.pinY - scrollY;
    }

    if (isScreenshotServer && visualViewport) {
      viewportWidth = visualViewport.width;
      viewportHeight = visualViewport.height;
    }

    return { pinX, pinY, viewportWidth, viewportHeight };
  };

  const getTranslation = () => {
    let x = 0;
    let y = 0;

    const { pinX, pinY, viewportWidth, viewportHeight } = getPinAndDimensions();

    const isLower = (point: number, container: number) => {
      return point <= container / 2;
    };

    const isUpper = (point: number, container: number, image: number) => {
      return point + container / 2 > image;
    };

    const isLowerX = isLower(pinX, containerWidth);
    const isUpperX = isUpper(pinX, containerWidth, viewportWidth);
    const isLowerY = isLower(pinY, containerHeight);
    const isUpperY = isUpper(pinY, containerHeight, viewportHeight);

    if (isLowerX) {
      x = 0;
    } else if (isUpperX) {
      x = viewportWidth - containerWidth;
    } else {
      x = pinX - containerWidth / 2;
    }

    if (isLowerY) {
      y = 0;
    } else if (isUpperY) {
      y = viewportHeight - containerHeight;
    } else {
      y = pinY - containerHeight / 2;
    }

    return { x, y };
  };

  const getStyles = () => {
    const { pinX, pinY, viewportWidth, viewportHeight } = getPinAndDimensions();

    const pinXPercent = (pinX / viewportWidth) * 100;
    const pinYPercent = (pinY / viewportHeight) * 100;

    let imageStyle = {};
    let containerStyle = {};
    let wrapperStyle = {};
    let pinStyle = {
      backgroundImage: `url(${pinUrl})`,
      left: `${pinXPercent}%`,
      top: `${pinYPercent}%`
    };

    if (
      (pinX === 0 && pinY === 0) ||
      pinX > viewportWidth ||
      pinY > viewportHeight
    ) {
      Object.assign(pinStyle, { display: 'none' });
    }

    if (isCroppedView) {
      const translateToPin = `translate(-${getTranslation().x}px, -${
        getTranslation().y
      }px)`;

      wrapperStyle = {
        width: viewportWidth,
        transform: translateToPin
      };

      imageStyle = {
        maxWidth: 'none',
        maxHeight: 'none',
        width: '100%'
        // marginTop: ``
      };

      containerStyle = {
        overflow: 'hidden',
        height: containerHeight
      };

      Object.assign(pinStyle, {
        left: pinX - getTranslation().x,
        top: pinY - getTranslation().y
      });
    }

    return { imageStyle, containerStyle, wrapperStyle, pinStyle };
  };

  return { screenshotStyles: getStyles(), viewport: getPinAndDimensions() };
};
