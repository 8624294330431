import * as React from 'react';
import { Button } from 'antd';
import { isEmpty, isUndefined } from 'lodash-es';

import { getLangKey } from 'models/Language';
import { Organization } from 'models/Organization';

import * as translations from './strings';
import styles from './index.module.css';
import FilterPopover from './FilterPopover';
import PriorityFilter from './PriorityFilter';
import ProjectFilter from './ProjectFilter';
import RequesterFilter from './RequesterFilter';
import StatusNameFilter from './StatusNameFilter';
import DescriptionSearch from './DescriptionSearch';
import DateFilter from './DateFilter';
import { UserTaskFilter } from '../types';

type Props = {
  onFilter: (filterParams: UserTaskFilter) => void;
  defaultFilter?: UserTaskFilter;
  organization: Organization;
};

const FilterPanel: React.FC<Props> = ({
  onFilter,
  defaultFilter,
  organization
}) => {
  const strings = translations[getLangKey()];
  const [filterParams, setFilterParams] = React.useState<UserTaskFilter>({
    ...defaultFilter
  });

  const triggerFilter = () => {
    const filters: UserTaskFilter = {
      ...filterParams,
      dueAtEnd: filterParams.dueAtEnd || filterParams.dueAtStart,
      createdAtEnd: filterParams.createdAtEnd || filterParams.createdAtStart,
      updatedAtEnd: filterParams.updatedAtEnd || filterParams.updatedAtStart
    };
    setFilterParams(filters);
    onFilter(filters);
  };

  const resetFilters = (filters: UserTaskFilter) => {
    setFilterParams(filters);
    onFilter(filters);
  };

  const noShowClear = isEmpty(defaultFilter) || isUndefined(defaultFilter);

  return (
    <div className={styles.filterPanel}>
      <div className={styles.filtersContainer}>
        <span className={styles.boldFont}>{strings.FilterBy}</span>
        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.Project}
          isFilterApplied={!!filterParams.projectId}
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({
              ...filterParams,
              projectId: undefined
            });
          }}
        >
          <ProjectFilter
            organizationId={organization.id}
            setSelectedProject={value =>
              setFilterParams({
                ...filterParams,
                projectId: value.length ? value : undefined
              })
            }
            selectedProject={
              filterParams?.projectId?.length ? filterParams?.projectId : []
            }
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.Description}
          isFilterApplied={!!filterParams.description}
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({ ...filterParams, description: undefined });
          }}
        >
          <DescriptionSearch
            descriptionSearch={filterParams?.description}
            setDescriptionSearch={value =>
              setFilterParams({
                ...filterParams,
                description: value.length > 0 ? value : undefined
              })
            }
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.Status}
          isFilterApplied={!!filterParams.status}
          onSearch={triggerFilter}
          layoutStyle={'vertical'}
          onReset={() => {
            resetFilters({
              ...filterParams,
              status: undefined
            });
          }}
        >
          <StatusNameFilter
            statusNames={organization.statusNames}
            onChange={value =>
              setFilterParams({
                ...filterParams,
                status: value.length > 0 ? value : undefined
              })
            }
            value={filterParams?.status}
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.Priority}
          isFilterApplied={!!filterParams.priorityId}
          onSearch={triggerFilter}
          layoutStyle={'vertical'}
          onReset={() => {
            resetFilters({
              ...filterParams,
              priorityId: undefined
            });
          }}
        >
          <PriorityFilter
            priorities={organization.priorities}
            onChange={value =>
              setFilterParams({
                ...filterParams,
                priorityId: value.length > 0 ? value : undefined
              })
            }
            value={filterParams?.priorityId}
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.ReportedBy}
          isFilterApplied={!!filterParams.requesterId}
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({
              ...filterParams,
              requesterId: undefined
            });
          }}
        >
          <RequesterFilter
            organizationId={organization.id}
            onChange={value =>
              setFilterParams({
                ...filterParams,
                requesterId: value.length ? value : undefined
              })
            }
            value={
              filterParams?.requesterId?.length ? filterParams?.requesterId : []
            }
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.createdAt}
          isFilterApplied={
            !!filterParams.createdAtStart || !!filterParams.createdAtEnd
          }
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({
              ...filterParams,
              createdAtStart: undefined,
              createdAtEnd: undefined
            });
          }}
        >
          <DateFilter
            setDateStart={(value: string) =>
              setFilterParams({
                ...filterParams,
                createdAtStart: value
              })
            }
            setDateEnd={(value: string) =>
              setFilterParams({
                ...filterParams,
                createdAtEnd: value
              })
            }
            value={[filterParams.createdAtStart, filterParams.createdAtEnd]}
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.dueAt}
          isFilterApplied={!!filterParams.dueAtStart || !!filterParams.dueAtEnd}
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({
              ...filterParams,
              dueAtStart: undefined,
              dueAtEnd: undefined
            });
          }}
        >
          <DateFilter
            setDateStart={(value: string) =>
              setFilterParams({
                ...filterParams,
                dueAtStart: value
              })
            }
            setDateEnd={(value: string) =>
              setFilterParams({
                ...filterParams,
                dueAtEnd: value
              })
            }
            value={[filterParams.dueAtStart, filterParams.dueAtEnd]}
          />
        </FilterPopover>

        <FilterPopover
          className={styles.filterPopover}
          buttonText={strings.updatedAt}
          isFilterApplied={
            !!filterParams.updatedAtStart || !!filterParams.updatedAtEnd
          }
          onSearch={triggerFilter}
          onReset={() => {
            resetFilters({
              ...filterParams,
              updatedAtStart: undefined,
              updatedAtEnd: undefined
            });
          }}
        >
          <DateFilter
            setDateStart={(value: string) =>
              setFilterParams({
                ...filterParams,
                updatedAtStart: value
              })
            }
            setDateEnd={(value: string) =>
              setFilterParams({
                ...filterParams,
                updatedAtEnd: value
              })
            }
            value={[filterParams.updatedAtStart, filterParams.updatedAtEnd]}
          />
        </FilterPopover>
        {!noShowClear && (
          <Button
            className={styles.clearButton}
            onClick={() => resetFilters({})}
          >
            {strings.clear}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilterPanel;
