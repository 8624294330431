export const en = {
  heading: 'Project columns',
  subTitle: 'Customize columns to fit your workflow. This will be applied to new projects only.',
  save: 'Save',
  addColumn: 'Add column',
  revertToDefault: 'Revert to default',
  newColumn: 'New column'
};

export type Translations = typeof en;
