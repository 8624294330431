import queryString from 'query-string';
import { isEmpty } from 'lodash-es';
export function matchesQueryString({
  siteQs,
  browserQs
}: {
  siteQs: string;
  browserQs: URLSearchParams;
}) {
  const siteQSTokens = queryString.parse(siteQs);
  const siteQsKeys: string[] = Object.keys(siteQSTokens);
  return siteQsKeys.every(key => {
    const browserQsValue: string | null = browserQs.get(key);

    if (!browserQsValue) return false;
    if (browserQsValue && siteQSTokens[key] === '*') return true;
    if (siteQSTokens[key] !== '*' && siteQSTokens[key] !== browserQsValue) {
      return false;
    }
    return true;
  });
}

export function matchesTaskQueryString({
  siteQs,
  pageQs,
  browserQs
}: {
  siteQs: URLSearchParams;
  pageQs: string;
  browserQs: URLSearchParams;
}) {
  const taskQSTokens = queryString.parse(pageQs);
  const taskQsKeys: string[] = Object.keys(taskQSTokens);

  const matchesPage = taskQsKeys.every((key: string) => {
    if (!browserQs.has(key)) {
      return false;
    }
    const browserQsValue: string | null = browserQs.get(key);

    if (!taskQSTokens[key] && !isEmpty(browserQsValue)) {
      // Check to see if the task query parameter has NO value (i.e. is `foo=`) and that the browser query parameter exists
      return false;
    } else if (taskQSTokens[key] && taskQSTokens[key] !== browserQsValue) {
      // Check to see if the task query parameter value exists and the task query parameter value does not equal the browser query parameter value
      return false;
    }

    return true;
  });

  let matchesSite = true;
  siteQs.forEach((value, key) => {
    matchesSite = matchesSite && browserQs.get(key) === value;
  });

  return !!matchesPage && matchesSite;
}
