import { skinToneModifiers } from '../../../../../../../../../../javascript/components/EmojiPicker/skinTones';

export type Emoji = {
  id: string;
  name: string;
  native: string;
  skin?: number;
};

export type UserReaction = {
  skin?: number;
  native: string;
  users: number[];
};

const applySkinTone = (emoji: string, skinTone: number): string => {
  const parts = emoji.split('\u200D'); // Split at the zero-width joiner
  // @ts-expect-error
  const baseEmoji = parts[0].replace(/[\u{1f3fb}-\u{1f3ff}]/u, ''); // Remove any existing skin tone modifier
  const genderModifier =
    parts.length > 1 ? '\u200D' + parts.slice(1).join('\u200D') : '';
  const modifier = skinToneModifiers[skinTone];
  const newEmoji = `${baseEmoji}${modifier}`;
  return `${newEmoji}${genderModifier || ''}`;
};

export const getNewReactions = ({
  commentReactions,
  reactionKey,
  emoji,
  currentUserId
}: {
  reactionKey?: string;
  emoji?: Emoji;
  commentReactions: {
    [key: string]: {
      name: string;
      userReactions: UserReaction[];
    };
  };
  currentUserId: number;
}): {
  [key: string]: {
    name: string;
    userReactions: UserReaction[];
  };
} => {
  if (!emoji && reactionKey) {
    const reaction = commentReactions[reactionKey];
    if (!reaction) return commentReactions;

    const myReaction = reaction.userReactions.find(userReaction =>
      userReaction.users.includes(currentUserId)
    );

    const skinReaction = reaction.userReactions.find(
      userReaction => userReaction.skin
    );
    if (myReaction) {
      myReaction.users = myReaction.users.filter(
        userId => userId !== currentUserId
      );
      if (!myReaction.users.length) {
        reaction.userReactions = reaction.userReactions.filter(
          userReaction => userReaction !== myReaction
        );
        if (!reaction.userReactions.length) {
          const newReactions = { ...commentReactions };
          Reflect.deleteProperty(newReactions, reactionKey);
          return newReactions;
        }
      }
    } else if (!skinReaction) {
      reaction.userReactions[0].users.push(currentUserId);
      return {
        ...commentReactions,
        [reactionKey]: {
          name: reaction.name,
          userReactions: reaction.userReactions
        }
      };
    } else {
      const myPreference =
        // @ts-expect-error
        parseInt(window.localStorage.getItem('_BugHerd_skin_tone'), 10) || 1;
      const myPreferenceReaction = reaction.userReactions.find(
        userReaction => userReaction.skin === myPreference
      );
      if (myPreferenceReaction) {
        myPreferenceReaction.users.push(currentUserId);
        return {
          ...commentReactions,
          [reactionKey]: {
            name: reaction.name,
            userReactions: reaction.userReactions
          }
        };
      } else {
        const native = skinReaction.native;
        reaction.userReactions.push({
          skin: myPreference,
          native: applySkinTone(native, myPreference),
          users: [currentUserId]
        });
        return {
          ...commentReactions,
          [reactionKey]: {
            name: reaction.name,
            userReactions: reaction.userReactions
          }
        };
      }
    }
  } else if (emoji) {
    const { skin, native, name, id } = emoji;
    reactionKey = reactionKey || id;
    const reaction = commentReactions[reactionKey];

    const skinReaction = reaction?.userReactions.find(
      userReaction => userReaction.skin === skin
    );

    if (skin) {
      if (reaction && skinReaction) {
        const inSkinReaction = skinReaction?.users.includes(currentUserId);
        skinReaction.users = inSkinReaction
          ? skinReaction.users.filter(userId => userId !== currentUserId)
          : [...skinReaction.users, currentUserId];
        return {
          ...commentReactions,
          [reactionKey]: {
            name,
            userReactions: reaction.userReactions
          }
        };
      } else {
        const newUserReaction = {
          skin,
          native,
          users: [currentUserId]
        };

        return {
          ...commentReactions,
          [reactionKey]: {
            name,
            userReactions: reaction
              ? [...reaction.userReactions, newUserReaction]
              : [newUserReaction]
          }
        };
      }
    }

    if (!reaction) {
      return {
        ...commentReactions,
        [reactionKey]: {
          name,
          userReactions: [
            {
              native,
              users: [currentUserId]
            }
          ]
        }
      };
    } else {
      const sameNativeReaction = reaction.userReactions.find(
        userReaction => userReaction.native === native
      );

      if (!sameNativeReaction) {
        return {
          ...commentReactions,
          [reactionKey]: {
            name,
            userReactions: [
              ...reaction.userReactions,
              {
                native,
                users: [currentUserId]
              }
            ]
          }
        };
      } else {
        const iReacted = sameNativeReaction.users.includes(currentUserId);
        const newReactionUsers = iReacted
          ? sameNativeReaction.users.filter(userId => userId !== currentUserId)
          : [...sameNativeReaction.users, currentUserId];

        if (!newReactionUsers.length) {
          reaction.userReactions = reaction.userReactions.filter(
            userReaction => userReaction.native !== native
          );
          if (!reaction.userReactions.length) {
            const newReactions = { ...commentReactions };
            Reflect.deleteProperty(newReactions, reactionKey);
            return newReactions;
          } else {
            return {
              ...commentReactions,
              [reactionKey]: {
                name,
                userReactions: reaction.userReactions
              }
            };
          }
        }
      }
    }
  }

  return commentReactions;
};
