import React, { FC, useState } from 'react';
import {
  Modal,
  Input,
  Checkbox,
  Select,
  Button,
  message,
  Form,
  FormProps
} from 'antd';
import { CloseModal } from 'appJS/components/CloseModal';
import styles from './index.module.css';
import { post } from 'appJS/utils/fetch';
import { Folder } from 'lucide-react';

type Props = {
  setShowAddFolder: (showAddFolder: boolean) => void;
  showAddFolder: boolean;
  projectFolders: {
    id: string;
    name: string;
    subfolders: {
      id: string;
      name: string;
    }[];
  }[];
  organizationId: string;
  refetchCurrentUser: () => void;
  container: HTMLDivElement;
};

type FieldType = {
  folder_name: string;
  subfolder: boolean;
  parent_folder: string;
};

export const AddFolderModal: FC<Props> = ({
  setShowAddFolder,
  showAddFolder,
  projectFolders,
  organizationId,
  refetchCurrentUser,
  container
}) => {
  const [folderName, setFolderName] = useState<string>('');
  const [isSubfolder, setIsSubfolder] = useState<boolean>(false);
  const [parentFolder, setParentFolder] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;

  const resetState = () => {
    setFolderName('');
    setIsSubfolder(false);
    setParentFolder('');
    setFieldsValue({
      folder_name: '',
      subfolder: false,
      parent_folder: ''
    });
  };

  const onCancel = () => {
    setLoading(false);
    setShowAddFolder(false);
    resetState();
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async ({
    folder_name,
    subfolder,
    parent_folder
  }: {
    folder_name: string;
    subfolder: boolean;
    parent_folder: string;
  }) => {
    setLoading(true);
    const params: { name: string; parent_folder_id?: string } = {
      name: folder_name
    };
    if (subfolder) params.parent_folder_id = parent_folder;
    const response = await post(
      `/organizations/${organizationId}/project_folders`,
      params
    );
    if (response.project_folder) {
      onCancel();
      message.success('Project folder created');
      refetchCurrentUser();
    } else {
      onCancel();
      message.error('Failed to create project folder, please try again');
      console.error(response);
    }
  };

  const invalidFolderName = () => {
    if (isSubfolder) {
      if (parentFolder) {
        return !!projectFolders
          .find(({ id }) => id === parentFolder)
          ?.subfolders.find(
            ({ name }) => name.toLowerCase() === folderName.toLowerCase()
          );
      }
      return false;
    }

    return !!projectFolders.find(
      ({ name }) => name.toLowerCase() === folderName.toLowerCase()
    );
  };

  const invalidTooltipTitle = `Invalid folder name: ${
    isSubfolder && parentFolder ? 'Subfolder' : 'Folder'
  } with that name already exists`;

  return (
    <Modal
      closeIcon={
        <CloseModal
          className={styles.addFolderCloseModal}
          handleClick={onCancel}
        />
      }
      getContainer={() => container}
      footer={null}
      title={null}
      open={showAddFolder}
      onCancel={onCancel}
      centered
      destroyOnClose
    >
      <h2 className={styles.addFolderHeader}>Name your project folder</h2>
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.addFolderForm}
        layout="vertical"
      >
        <Form.Item
          className={styles.addFolderFormItem}
          label="Folder name"
          name="folder_name"
          hasFeedback
          dependencies={['parent_folder']}
          validateStatus={
            folderName && invalidFolderName()
              ? 'error'
              : folderName
              ? 'success'
              : undefined
          }
          rules={[
            {
              required: true,
              message: 'Please enter a folder name'
            },
            () => ({
              validator(_, value) {
                if (invalidFolderName()) {
                  return Promise.reject(new Error(invalidTooltipTitle));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input
            className={styles.addFolderInput}
            onChange={event => {
              setFieldsValue({
                folder_name: event.target.value
              });
              setFolderName(event.target.value);
            }}
            placeholder="Enter a project folder name"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          className={styles.addFolderFormItem}
          dependencies={['folder_name']}
          label={null}
          name="subfolder"
        >
          <Checkbox
            className={styles.subFolderCheckbox}
            onChange={event => {
              setFieldsValue({ subfolder: event.target.checked });
              setIsSubfolder(event.target.checked);
            }}
            disabled={!projectFolders.length || !folderName}
          >
            Make it a subfolder
          </Checkbox>
        </Form.Item>
        {isSubfolder && (
          <Form.Item
            className={styles.addFolderFormItem}
            label="Parent folder"
            name="parent_folder"
            colon
          >
            <Select
              className={styles.selectParentFolder}
              placeholder="Select a parent folder"
              options={projectFolders.map(({ name, id }) => ({
                label: name,
                value: id,
                key: id,
                icon: <Folder className={styles.menuIcon} />,
                className: styles.parentFolderOption
              }))}
              onChange={value => {
                setFieldsValue({ parent_folder: value });
                setParentFolder(value);
              }}
            />
          </Form.Item>
        )}
        <Form.Item className={styles.addFolderFormItem} label={null}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.addFolderButton}
            disabled={!folderName || invalidFolderName()}
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
