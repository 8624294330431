import React, { FC, useState } from 'react';
import { Button, Typography } from 'antd';
import cx from 'classnames';
import { getLangKey } from 'models/Language';
import { CheckCircle, X, PlayCircle } from 'lucide-react';

import * as translations from './strings';

import styles from './styles.module.css';

const strings = translations[getLangKey()];

type Props = {
  isChecked: boolean;
  handleClick: (event) => void;
  title: string;
  description: string | JSX.Element;
  actionButtons: JSX.Element;
  showSkip: boolean;
  elevioArticleId: string | undefined;
  handleChecklistComplete: () => void;
};

const Step: FC<Props> = ({
  isChecked,
  title,
  description,
  actionButtons,
  showSkip,
  elevioArticleId,
  handleChecklistComplete
}) => {
  const [isSkipped, setIsSkipped] = useState(false);
  const [showCompletedStep, setShowCompletedStep] = useState(false);

  const handleClick = () => {
    setShowCompletedStep(prevState => !prevState);
  };

  return (
    <div
      onClick={handleClick}
      className={cx(styles.stepContainer, {
        [styles.checkedStep]: isChecked || isSkipped,
        [styles.showCompletedstep]: showCompletedStep
      })}
    >
      <div className={cx(styles.stepHeader, styles.header)}>
        <Typography.Title level={3} className={styles.title}>
          {title}
        </Typography.Title>
        {isChecked && (
          <div className={styles.checkedBadge}>
            <CheckCircle width={16} height={16} />
            {strings.complete}
          </div>
        )}
        {!isSkipped && !isChecked && showSkip && (
          <Button
            type="default"
            onClick={() => {
              handleChecklistComplete();
              setIsSkipped(true);
            }}
          >
            <X />
            {strings.skip}
          </Button>
        )}
        {isSkipped && (
          <div className={styles.checkedBadge}>
            <CheckCircle width={16} height={16} />
            {strings.skipped}
          </div>
        )}
      </div>
      <p className={styles.descriptionWrapper}>{description}</p>
      <div className={styles.actionButtons}>
        <>
          {actionButtons}
          {elevioArticleId && (
            <Button
              type="text"
              data-elevio-article={elevioArticleId || null}
              data-elevio-style="nothing"
              className={styles.buttonWithIcon}
            >
              <PlayCircle width={16} height={16} />
              {strings.learnHow}
            </Button>
          )}
        </>
      </div>
    </div>
  );
};

export default Step;
