export const browserConfig = [
  {
    name: 'chrome',
    display: 'Chrome',
    installLink:
      'https://chrome.google.com/webstore/detail/bugherd-plus/popigpemobhbfkhnnkllkjgkaabedgpb',
    image: 'browser_toolbar',
    cta: 'Get the extension',
    emptyCta: 'Add to Chrome',
    cta_signup: 'Get the Chrome extension',
    installing: {
      title: 'Waiting for you to get the extension…',
      message: 'A new tab should now open to take you to the Chrome Webstore.',
      linkCopy: 'Nothing happened? Open the Chrome Webstore.',
      elevioArticleId: 33884
    }
  },
  {
    name: 'edge-chromium',
    display: 'Edge',
    installLink:
      'https://microsoftedge.microsoft.com/addons/detail/nkdboghnnmoenjjmcakndhjdecakkfpn',
    image: 'browser_toolbar_edge',
    cta: 'Get the extension',
    emptyCta: 'Add to Edge',
    cta_signup: 'Get the Edge extension',
    installing: {
      title: 'Waiting for you to get the extension…',
      message:
        'A new tab should now open to take you to the Microsoft Edge Webstore.',
      linkCopy: 'Nothing happened? Open the Microsoft Edge Webstore.',
      elevioArticleId: 84863
    }
  },
  {
    name: 'safari',
    display: 'Safari',
    installLink:
      'https://apps.apple.com/us/app/bugherd-website-feedback-tool/id1545845981',
    cta: 'Get the extension',
    emptyCta: 'Add to Safari',
    cta_signup: 'Get the Safari extension',
    installing: {
      title: 'Waiting for you to get the extension…',
      message:
        'A new tab should now open to take you to the Safari Extensions Webstore.',
      linkCopy: 'Nothing happened? Open the Safari Extensions Webstore.',
      elevioArticleId: 84876
    }
  },
  {
    name: 'firefox',
    display: 'Firefox',
    installLink: 'https://addons.mozilla.org/en-US/firefox/addon/bugherd/',
    image: 'browser_toolbar_dark',
    cta: 'Get the extension',
    emptyCta: 'Add to Firefox',
    cta_signup: 'Get the Firefox extension',
    installing: {
      title: 'Waiting for you to get the extension…',
      message:
        'A new tab should now open to take you to the Firefox Add-Ons Store.',
      linkCopy: 'Nothing happened? Open the Firefox Add-Ons Store.',
      elevioArticleId: 84901
    }
  }
];

export type BrowserConfigType = typeof browserConfig[0];
