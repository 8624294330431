import React from 'react';
import ArrowUp from '../../../clients/sidebar/assets/images/ic-upgrade-circle.svg';

export const getSortedAssignableUsers = (
  assignableUsers: any[],
  canAssignGuests: boolean
): any[] => {
  if (!canAssignGuests) {
    return assignableUsers
      .filter(user => user.role !== 'guest')
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  const users: { [key: string]: any[] } = {
    guests: [],
    collabs: [],
    members: []
  };

  const { guests, collabs, members } = assignableUsers.reduce((acc, user) => {
    acc[
      user.role === 'guest'
        ? 'guests'
        : user.role === 'collaborator'
        ? 'collabs'
        : 'members'
    ].push(user);
    return acc;
  }, users);

  guests.sort((a, b) =>
    (a.name || a.email || '').localeCompare(b.name || b.email || '')
  );
  collabs.sort((a, b) => a.name.localeCompare(b.name));
  members.sort((a, b) => a.name.localeCompare(b.name));

  return [...members, ...collabs, ...guests];
};

export const upgradeOption = ({
  domain,
  styles,
  upgradeText,
  value,
  billing,
  isAdminView
}: {
  domain: string;
  styles: { [key: string]: string };
  upgradeText: string;
  value: any;
  billing: boolean;
  isAdminView?: boolean;
}) => ({
  className: styles.upgradeOption,
  value,
  label: (
    <div
      title={upgradeText}
      className={styles.userContainer}
      onClick={(event: any) => {
        if (!billing) return;
        event.preventDefault();
        event.stopPropagation();
        window.open(`${domain}/subscription`, isAdminView ? '_self' : '_blank');
      }}
    >
      <ArrowUp className={styles.upgradeIcon} />
      <span className={styles.userName}>
        {billing ? (
          <>
            <a
              className={styles.upgradeLink}
              href={`${domain}/subscription`}
              onClick={e => e.preventDefault()}
            >
              Upgrade
            </a>
            {upgradeText.replace('Upgrade', '')}
          </>
        ) : (
          upgradeText
        )}
      </span>
    </div>
  )
});
