import React, { FC } from 'react';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  projectId: number;
  goToBasicAuth: () => void;
  isSidebar: boolean;
  url: string;
};

// @ts-ignore
const strings = translations[getLangKey()];

const BasicAuth: FC<Props> = ({ projectId, isSidebar, goToBasicAuth, url }) => {
  const buttonProps = isSidebar
    ? {
        children: (
          <a
            className={styles.buttonAnchor}
            href={`${url}/projects/${projectId}/kanban`}
            target="_blank"
          >
            {strings.openSettings}
          </a>
        )
      }
    : { children: strings.openSettings, onClick: goToBasicAuth };

  return (
    <div className={styles.modalContent}>
      <p className={styles.whyMessage}>{strings.couldntAccess}</p>
      <p className={styles.whyMoreInfo}>
        {strings.enableBasicAuth}
        <a
          href="https://support.bugherd.com/hc/en-us/articles/360000172236-Why-didn-t-my-screenshot-work-"
          target="_blank"
          className={styles.whyMoreInfoLink}
        >
          {strings.helpArticle}
        </a>
        .
      </p>
      <Button
        className={styles.whyButton}
        {...buttonProps}
        size="large"
        type="primary"
      />
    </div>
  );
};

export default BasicAuth;
