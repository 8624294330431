import * as React from 'react';
import { Button, Progress } from 'antd';
import { PlanActions } from './PlanActions';
import styles from './index.module.css';
import * as translations from './strings';
import PlanHeader from '../plan/PlanHeader';
import { PlanInterval } from '../types';
import { PremiumIntegrationsItem } from '../addonPremiumIntegrations';

const strings = translations['en'];

interface Plan {
  id: string;
  price: number;
  name: string;
  handle: string;
  interval: PlanInterval;
}

function pluralize(num: number, word: string) {
  return `${num} ${word}${num < 2 ? '' : 's'}`;
}

export function CurrentPlan(props: {
  organization: {
    id: string;
    numMembers: number;
  };
  subscription: {
    state: string;
    price: number;
    additionalMembers: number;
    maxMembers: number;
    subscriptionPrice: number;
    addonPremiumIntegrations: boolean;
  };
  plan: Plan;
  pausePlan: Plan;
  showAdditionalMembers: () => void;
}) {
  const {
    organization,
    plan,
    pausePlan,
    subscription,
    subscription: {
      additionalMembers,
      maxMembers,
      subscriptionPrice,
      addonPremiumIntegrations
    },
    showAdditionalMembers
  } = props;

  const memberPercent =
    maxMembers == null
      ? 0
      : Math.floor((organization.numMembers / maxMembers) * 100);

  return (
    <div>
      {pausePlan.handle !== plan.handle && (
        <>
          <p className={styles.heroText}>{strings.yourCurrentPlan}</p>
          <PlanHeader
            plan={{ ...plan, price: subscriptionPrice }}
            numAdditionalMembers={additionalMembers}
            addonPremiumIntegrations={addonPremiumIntegrations}
          />
        </>
      )}
      <div className={styles.usageAndActions}>
        <div className={styles.usage}>
          {pausePlan.handle !== plan.handle && (
            <>
              <h4>{strings.currentlyUsing}:</h4>
              {subscription.addonPremiumIntegrations && (
                <PremiumIntegrationsItem organizationId={organization.id} />
              )}
              <div className={styles.groupingHorizontal}>
                <span>
                  {maxMembers == null
                    ? pluralize(organization.numMembers, 'member')
                    : `${organization.numMembers} out of ${pluralize(
                        maxMembers,
                        'member'
                      )}`}
                </span>
                <Button
                  type="link"
                  onClick={() => showAdditionalMembers()}
                  className={styles.additionalMemberLabel}
                >
                  {strings.additionalMembers}
                </Button>
              </div>
              {maxMembers !== null && (
                <Progress
                  type="line"
                  percent={memberPercent}
                  status={memberPercent === 100 ? 'exception' : 'normal'}
                  showInfo={false}
                />
              )}
            </>
          )}
        </div>
        <PlanActions
          state={subscription.state}
          pausePlan={pausePlan}
          organization={organization}
        />
      </div>
    </div>
  );
}
