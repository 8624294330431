import { SkinTone } from './skinTones';
import { InputRef } from 'antd';
import { MutableRefObject } from 'react';

export type Emoji = {
  id: string;
  name: string;
  keywords: string[];
  skins: { unified: string; native: string }[];
  version: number;
};

export type Category = {
  id: string;
  emojis: string[];
};

export type EmojiData = {
  categories: Category[];
  emojis: {
    [key: string]: Emoji;
  };
};

export type CategoryIcon = {
  icon: any;
  category: CategoryName;
};

export enum CategoryName {
  SEARCH = 'search',
  FREQUENT = 'frequent',
  PEOPLE = 'people',
  NATURE = 'nature',
  FOODS = 'foods',
  ACTIVITY = 'activity',
  PLACES = 'places',
  OBJECTS = 'objects',
  SYMBOLS = 'symbols',
  FLAGS = 'flags'
}

export enum CategoryLabel {
  SEARCH = 'Search',
  FREQUENT = 'Frequently Used',
  PEOPLE = 'Smileys & People',
  NATURE = 'Animals & Nature',
  FOODS = 'Food & Drink',
  ACTIVITY = 'Activities',
  PLACES = 'Travel & Places',
  OBJECTS = 'Objects',
  SYMBOLS = 'Symbols',
  FLAGS = 'Flags'
}

export type EmojiState = {
  emojiData: EmojiData | null;
  searchValue: string;
  setSearchValue: (value: string) => void;
  hoveredEmoji: Emoji | undefined;
  setHoveredEmoji: (emoji: Emoji | undefined) => void;
  skinTone: SkinTone;
  setSkinTone: (skinTone: SkinTone) => void;
  selectedCategory: CategoryName;
  setSelectedCategory: (categoryName: CategoryName) => void;
  getEmoji: (emojiName: string) => Emoji | null;
  handleCategoryClick: (category: CategoryName) => void;
  container?: HTMLDivElement;
  getContainer?: () => HTMLDivElement;
  darkMode?: boolean;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  onEmojiSelect: (emoji: {
    id: string;
    name: string;
    native: string;
    skin?: SkinTone;
  }) => void;
  searchResults: Emoji[];
  getRecentEmojis: () => Emoji[];
  storeRecentEmoji: (emoji: Emoji) => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  defaultPreviewEmoji: Emoji;
  isAdminView: boolean;
};

export type EmojiProviderProps = {
  children: React.ReactNode;
  emojiData: EmojiData;
  container?: HTMLDivElement;
  getContainer?: () => HTMLDivElement;
  darkMode?: boolean;
  onEmojiSelect: (emoji: {
    id: string;
    name: string;
    native: string;
    skin?: SkinTone;
  }) => void;
  isAdminView: boolean;
  projectId: number;
};
