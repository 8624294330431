import React from 'react';
import { Radio } from 'antd';
import cx from 'classnames';
import styles from './index.module.css';
import { MenuProps } from 'antd/lib';
import { Folders, Star, FolderPlus } from 'lucide-react';
import { Folder } from './project_folders';
import { folderItems } from './project_folders/navItems';

type Props = {
  sortBy: string;
  activeOnly: boolean;
  isMobileView: boolean;
  projectFoldersAllowed: boolean;
  isGuest: boolean;
  activeKey: string;
  projectFolders: Folder[];
};

export const filterMenuItems = ({
  sortBy,
  activeOnly,
  projectFoldersAllowed,
  isMobileView,
  isGuest,
  activeKey,
  projectFolders
}: Props) => {
  const filterChildren = [
    {
      key: 'active',
      label: (
        <Radio checked={activeOnly === true}>{`Showing ${
          activeOnly ? 'active' : 'all'
        } projects`}</Radio>
      ),
      title: `Toggle to show ${
        activeOnly ? 'active and disabled' : 'acitve'
      } projects`,
      className: styles.activeMenuRadio
    }
  ];

  if (!isGuest && projectFoldersAllowed && isMobileView) {
    filterChildren.push(
      {
        key: 'favourites',
        // @ts-expect-error
        label: 'Favourites',
        icon: <Star className={styles.starIcon} />,
        className: cx(styles.favouritesMenuItem, {
          [styles.active]: activeKey === 'favourites'
        })
      },
      {
        key: 'all-projects',
        label: 'All Projects',
        icon: <Folders className={styles.foldersIcon} />,
        className: cx(styles.allProjectsMenuItem, {
          [styles.active]: activeKey === 'all-projects'
        })
      },
      {
        key: 'new-folder',
        label: 'New Folder',
        title: 'New Folder',
        icon: <FolderPlus className={styles.menuIcon} />,
        className: cx(styles.menuItem, styles.newFolderMenuItem)
      },
      ...folderItems(projectFolders, activeKey, styles)
    );
  }

  const items: Required<MenuProps['items']> = [
    {
      key: 'sort',
      label: 'Sort By',
      type: 'group',
      children: [
        {
          key: 'name',
          label: <Radio checked={sortBy === 'name'}>Alphabetical</Radio>,
          className: styles.sortMenuRadio
        },
        {
          key: 'updated_at',
          label: <Radio checked={sortBy === 'updated_at'}>Updated At</Radio>,
          className: styles.sortMenuRadio
        }
      ],
      className: styles.sortGroupMenuItem
    },
    {
      key: 'divider-1',
      type: 'divider'
    },
    {
      key: 'filter',
      label: 'Filter by',
      type: 'group',
      children: filterChildren,
      className: styles.filterGroupMenuItem
    }
  ];

  return items;
};
