import { useEffect } from 'react';

const useHideParentOverflow = () => {
  const addClassNameAndStyleToBody = () => {
    window.document.body.classList.add('BUGHERD_hideParentBodyOverflow');
    const style = window.document.createElement('style');
    style.id = 'bugherdMobileOverflow';
    style.innerHTML =
      '@media (max-width: 469px) {.BUGHERD_hideParentBodyOverflow { overflow: hidden !important; position: fixed !important; }}';
    window.document.body.appendChild(style);
  };

  const removeClassNameAndStyleFromBody = () => {
    window.document.body.classList.remove('BUGHERD_hideParentBodyOverflow');
    const style = window.document.getElementById('bugherdMobileOverflow');

    if (style) {
      style.remove();
    }
  };
  useEffect(() => {
    // If this is the first route that's mounted, don't delay any resizing
    addClassNameAndStyleToBody();

    return removeClassNameAndStyleFromBody;
  }, []);

  return null;
};
export default useHideParentOverflow;
