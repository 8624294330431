import React, { useState, FC, ReactNode } from 'react';
import {
  MoreHorizontal,
  CheckCircle,
  Ban,
  Trash2,
  MessageCircle
} from 'lucide-react';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { deleteItem, post } from 'utils/fetch';
import { useMutation } from '@apollo/client';
import { UPDATE_PROJECT } from 'views/user_projects/queries';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import cx from 'classnames';
import styles from './index.module.css';

const strings = translations[getLangKey()];

type Props = {
  projectId: string;
  isActive: boolean;
  getPopupContainer: () => HTMLElement;
  setShowRefresh: (showRefresh: boolean) => void;
  canDelete: boolean;
  canDisable: boolean;
  handleReadAll: () => void;
};

const Actions: FC<Props> = props => {
  const {
    isActive,
    projectId,
    getPopupContainer,
    setShowRefresh,
    canDelete,
    canDisable,
    handleReadAll
  } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);

  const [updateProject] = useMutation(UPDATE_PROJECT);

  const handleActive = () => {
    updateProject({
      variables: {
        projectId,
        isActive: !isActive
      }
    });
  };

  const handleDelete = async () => {
    setVisible(false);
    const response = await deleteItem(`/projects/${projectId}`);
    if (response && response.status === 'ok') {
      setShowRefresh(true);
    }
  };

  const handleMenuClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    if (key === 'active') {
      handleActive();
      setVisible(false);
    } else if (key === 'delete') {
      if (confirming) {
        handleDelete();
        setVisible(false);
      } else {
        setConfirming(true);
      }
    } else if (key === 'readAll') {
      handleReadAll();
      setVisible(false);
    }
  };

  const ActiveMenuItem = () =>
    isActive ? (
      <span>
        <Ban className={styles.disableIcon} />
        {strings.disable}
      </span>
    ) : (
      <span>
        <CheckCircle className={styles.enableIcon} />
        {strings.enable}
      </span>
    );

  const showMenu = canDisable || canDelete;

  const items: MenuProps['items'] = [];

  const getItem = (key: string, label: ReactNode, danger?: boolean) => ({
    key,
    label,
    onClick: handleMenuClick,
    className: styles.menuItem,
    danger
  });

  if (canDisable) items.push(getItem('active', <ActiveMenuItem />));

  if (canDelete) {
    items.push(
      getItem(
        'delete',
        <span>
          <Trash2 className={styles.deleteIcon} />
          {confirming ? strings.confirmDelete : strings.delete}
        </span>,
        confirming
      )
    );
  }

  items.push(
    getItem(
      'readAll',
      <span>
        <MessageCircle
          width={15}
          height={15}
          color={'#848484'}
          className={styles.unreadIcon}
        />
        {strings.readAll}
      </span>
    )
  );

  if (!showMenu) {
    return null;
  }

  return (
    <Dropdown
      className={styles.moreDropdown}
      trigger={['click']}
      getPopupContainer={getPopupContainer}
      open={visible}
      overlayClassName={styles.overlay}
      placement="bottomRight"
      menu={{ items }}
    >
      <Tooltip
        getPopupContainer={getPopupContainer}
        title={strings.moreActions}
      >
        <MoreHorizontal
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            setVisible(!visible);
          }}
          className={cx(styles.moreIcon, { [styles.active]: visible })}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default Actions;
