import Config from 'appJS/models/Config';
import { ReactNode } from 'react';

export type DesignAsset = {
  id: number;
  name: string;
  url: string;
  updatedAt: string;
  createdAt: string;
  fileType: string;
  projectId: number;
  openTasksCount?: number;
  triageTasksCount?: number;
  groupName?: string;
  isFigma: boolean;
  status: 'created' | 'archived';
};

export type UploadAsset = (params: any) => any;

export type AssetsPayload = {
  data: DesignAsset[];
  status: 'loading' | 'success' | 'error';
  uploadAsset: (params: any) => Promise<any>;
  setData: (data: DesignAsset[]) => void;
};

export type AssetProject = {
  id: number;
  config: Config;
  name: string;
  pusherChannelName: string;
  organizationId: number;
  javascriptInstalled: boolean;
};

export type DesignAssetsProviderProps = {
  project: AssetProject;
  figmaClientId: string;
  figmaClientSecret: string;
  figmaCallbackURI: string;
  figmaUserToken: string | null;
  figmaRefreshToken: string | null;
  bugherdUrl: string;
  container: HTMLDivElement;
  boardContainer: HTMLDivElement;
  children: ReactNode;
  currentUserId: number;
  viewGuestKanban: boolean;
  isGuest: boolean;
};

export enum Status {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error'
}

export type EventParams = {
  assetsClone: DesignAsset[];
  existingAsset?: DesignAsset;
  asset: DesignAsset;
};

export type Website = {
  id: string;
  url: string;
  updated_at: string;
  cover_url: string;
  host: string;
};

export type FigmaUploadProviderProps = {
  children: ReactNode;
};

export type FigmaNode = {
  id: string;
  name: string;
  type: string;
  imageUrl?: string | undefined;
  isSelected?: boolean | undefined;
  largerImageUrl?: string | undefined;
  progress?: number | undefined;
};

export type ToUpdate = {
  imageUrl?: string;
  largerImageUrl?: string;
  isSelected?: boolean;
  progress?: number;
};

export type ShareModalProps = {
  open: boolean;
  closeModal: () => void;
  shareURL: string;
  container: HTMLDivElement;
  canInviteToProject?: boolean;
  inviteEndpoint?: string;
};
