// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import {
  Type as TypeIcon,
  ArrowLeft as ArrowLeftIcon,
  Square as SquareIcon,
  Edit2 as PenIcon,
  MousePointer as MouseIcon,
  Circle as FilledCircleIcon,
  Trash2 as TrashIcon,
  Save as SaveIcon,
  X as CloseIcon
} from 'lucide-react';
import { TwitterPicker } from 'react-color';
import cx from 'classnames';
import { Tooltip } from 'antd';
import Draggable from 'react-draggable';
import DraggerIcon from '../../dragger/dragger.svg';

import styles from './style.module.css';

export default class AnnotateTools extends React.Component {
  static propTypes = {
    tool: PropTypes.string,
    color: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    canDelete: PropTypes.bool.isRequired,
    onToolChange: PropTypes.func.isRequired,
    onColorChange: PropTypes.func.isRequired,
    container: PropTypes.instanceOf(Element),
    fixedToWindow: PropTypes.bool.isRequired
  };

  static defaultProps = {
    tool: 'draw',
    color: '#FCB900'
  };

  state = {
    tool: this.props.tool,
    color: this.props.color,
    pickerActive: false,
    isDragging: false,
    hasMoved: false
  };

  setTool = tool => {
    this.setState(
      {
        tool
      },
      () => this.props.onToolChange(tool)
    );
  };

  handlePicker = () => {
    this.setState({
      pickerActive: !this.state.pickerActive
    });
  };

  handleColorChange = color => {
    this.setState({ color: color.hex, pickerActive: false });
    this.props.onColorChange(color.hex);
  };

  render() {
    const { tool, hasMoved, color, isDragging, pickerActive } = this.state;
    const {
      container,
      fixedToWindow,
      canDelete,
      onSave,
      onCancel
    } = this.props;

    const toolClass = _tool =>
      cx(styles.toolButton, styles[_tool], {
        [styles.toolActive]: tool === _tool
      });

    if (!container) {
      return null;
    }

    return (
      <Draggable
        onStart={() => this.setState({ isDragging: true })}
        onStop={() =>
          this.setState({
            isDragging: false
          })
        }
        onDrag={(event, data) => {
          if (hasMoved !== !!(data.x || data.y)) {
            this.setState({ hasMoved: data.x || data.y });
          }
        }}
        handle=".dragHandler"
        className={styles.draggable}
      >
        <div
          className={cx(styles.tools, {
            [styles.movedTools]: hasMoved
          })}
          style={fixedToWindow ? { position: 'fixed' } : {}}
        >
          <div className={styles.toolsInner}>
            <button
              onClick={() => this.setTool('mouse')}
              className={toolClass('mouse')}
            >
              <MouseIcon className={styles.icon} />
            </button>

            <button
              onClick={() => this.setTool('rect')}
              className={toolClass('rect')}
            >
              <SquareIcon className={styles.icon} />
            </button>
            <button
              onClick={() => this.setTool('arrow')}
              className={toolClass('arrow')}
            >
              <ArrowLeftIcon className={styles.icon} />
            </button>
            <button
              onClick={() => this.setTool('draw')}
              className={toolClass('draw')}
            >
              <PenIcon className={styles.icon} />
            </button>
            <button
              onClick={() => this.setTool('text')}
              className={toolClass('text')}
            >
              <TypeIcon className={styles.icon} />
            </button>
            <button
              onClick={() => {
                this.setTool('trash');
                this.props.onDelete();
              }}
              className={cx(styles.toolButton, styles.toolButtonTrash, {
                [styles.toolButtonTrashEnabled]: canDelete
              })}
            >
              <TrashIcon className={styles.icon} />
            </button>

            <hr className={styles.break} />
            <span className={styles.pickerContainer}>
              <button
                onClick={this.handlePicker}
                className={toolClass('paint')}
                style={{ color }}
              >
                <FilledCircleIcon
                  className={cx(styles.icon, styles.circleIcon)}
                />
              </button>
              {pickerActive && (
                <TwitterPicker
                  triangle="top-right"
                  className={styles.picker}
                  color={color}
                  onChangeComplete={this.handleColorChange}
                />
              )}
            </span>
            <hr className={styles.break} />
            <Tooltip
              placment="left"
              title="Save annotations"
              mouseEnterDelay={0.5}
              align={{ offset: [-12] }}
              getPopupContainer={() => container}
            >
              <button onClick={() => onSave()} className={styles.save}>
                <SaveIcon className={styles.icon} />
              </button>
            </Tooltip>
            <Tooltip
              placment="left"
              title="Cancel"
              mouseEnterDelay={0.5}
              align={{ offset: [-12] }}
              getPopupContainer={() => container}
            >
              <button onClick={onCancel} className={styles.cancel}>
                <CloseIcon className={styles.icon} />
              </button>
            </Tooltip>
            <Tooltip
              placment="left"
              title="Move me"
              mouseEnterDelay={0.5}
              align={{ offset: [-12] }}
              getPopupContainer={() => container}
            >
              <DraggerIcon
                className={cx(styles.draggerIcon, 'dragHandler', {
                  [styles.isDragging]: isDragging
                })}
              />
            </Tooltip>
          </div>
        </div>
      </Draggable>
    );
  }
}
