import React from 'react';
import { Folders, Star, Folder, FolderOpen, FolderPlus } from 'lucide-react';
import styles from './styles.module.css';
import cx from 'classnames';
import { MenuProps } from 'antd/lib';
import { Popover } from 'antd';
import { Folder as FolderType } from '.';

type MenuItem = Required<MenuProps>['items'][number];

export const folderItems = (
  folders: FolderType[],
  activeKey: string,
  _styles: { [key: string]: string } = styles
) => {
  return folders.map(({ name, id, subfolders }) => {
    const key = [id, ...subfolders.map(({ id }) => id)].join(',');
    const isFolderActive = activeKey === key || key.includes(activeKey);
    const item = {
      key,
      label: name,
      className: cx(_styles.menuItem, { [_styles.active]: isFolderActive }),
      title: name,
      icon: isFolderActive ? (
        <FolderOpen className={_styles.menuIcon} />
      ) : (
        <Folder className={_styles.menuIcon} />
      )
    };
    if (subfolders.length) {
      // @ts-expect-error
      item.children = subfolders.map(({ name, id }) => {
        const isSubfolderActive = activeKey === id;
        return {
          key: id,
          label: name,
          title: name,
          icon: isSubfolderActive ? (
            <FolderOpen className={_styles.menuIcon} />
          ) : (
            <Folder className={_styles.menuIcon} />
          ),
          className: cx(_styles.subMenuItem, 'antd-container', {
            [_styles.active]: isSubfolderActive
          })
        };
      });
    }
    return item;
  });
};

export const getNavItems = ({
  canCreateProject,
  manageProjectFolders,
  createdProjectFolder,
  seenProjectFolderVideo,
  folders,
  activeKey
}: {
  canCreateProject: boolean;
  folders: FolderType[];
  activeKey: string;
  manageProjectFolders: boolean;
  createdProjectFolder: boolean;
  seenProjectFolderVideo: boolean;
}): MenuItem[] => {
  const navItems = [
    {
      key: 'divider-1',
      type: 'divider'
    },
    {
      key: 'favourites',
      label: 'Favourites',
      title: 'Favourites',
      icon: <Star className={styles.menuIcon} />,
      className: cx(styles.menuItem, styles.favourites, {
        [styles.active]: activeKey === 'favourites'
      })
    },
    {
      key: 'divider-2',
      type: 'divider'
    },
    {
      key: 'all-projects',
      label: 'All Projects',
      title: 'All Projects',
      icon: <Folders className={styles.menuIcon} />,
      className: cx(styles.menuItem, {
        [styles.active]: activeKey === 'all-projects'
      })
    }
  ];

  if (manageProjectFolders) {
    const seenOrUsedFeature = seenProjectFolderVideo || createdProjectFolder;
    const label = seenOrUsedFeature ? (
      'New Folder'
    ) : (
      <div className={styles.newFolderLabel}>
        <Popover
          title="Organise your projects into folders"
          content="Click New Folder to create a project folder"
        >
          <div className={styles.pulseIndicator} />
        </Popover>
        New Folder
      </div>
    );

    navItems.push({
      key: 'new-folder',
      label,
      title: 'New Folder',
      icon: <FolderPlus className={styles.menuIcon} />,
      className: cx(styles.menuItem, styles.newFolder, {
        [styles.newFolderTour]: !seenOrUsedFeature
      })
    });
  }

  const items = canCreateProject ? navItems : navItems.slice(1);

  return [...items, ...folderItems(folders, activeKey)];
};
