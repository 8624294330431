import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
  MutableRefObject
} from 'react';
import { EmojiState, EmojiProviderProps, Emoji, CategoryName } from './types';
import { defaultMostUsedEmojis } from './defaultMostUsedEmojis';
import { SkinTone } from './skinTones';
import {
  getPreference,
  setPreferences
} from '../../../clients/sidebar/views/utils/getPreferences';

// @ts-expect-error
const EmojiStateContext = createContext<EmojiState>();

const MAX_EMOJIS_COUNT = 36;

const DEFAULT_PREVIEW_EMOJI: Emoji = {
  id: 'point_up_2',
  name: 'Backhand Index Pointing Up',
  keywords: ['point', '2', 'fingers', 'hand', 'direction'],
  skins: [
    { unified: '1f446', native: '👆' },
    { unified: '1f446-1f3fb', native: '👆🏻' },
    { unified: '1f446-1f3fc', native: '👆🏼' },
    { unified: '1f446-1f3fd', native: '👆🏽' },
    { unified: '1f446-1f3fe', native: '👆🏾' },
    { unified: '1f446-1f3ff', native: '👆🏿' }
  ],
  version: 1
};

const EmojiProvider = ({
  children,
  emojiData,
  getContainer,
  darkMode,
  onEmojiSelect,
  isAdminView,
  projectId
}: EmojiProviderProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [hoveredEmoji, setHoveredEmoji] = useState<Emoji | undefined>();
  const [skinTone, _setSkinTone] = useState<SkinTone>(SkinTone.Default);
  const [recentEmojis, setRecentEmojis] = useState<Emoji[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryName>(
    CategoryName.FREQUENT
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const setSkinTone = (skinTone: SkinTone) => {
    _setSkinTone(skinTone);
    setPreferences({
      projectId,
      preferences: { skin_tone: skinTone },
      isAdminView
    });
  };

  const initSkinTonePreference = async () => {
    const preference = (await getPreference({
      projectId,
      preference: 'skin_tone',
      isAdminView
    })) as SkinTone;
    if (preference) _setSkinTone(preference);
  };

  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const handleCategoryClick = (category: CategoryName) => {
    setSelectedCategory(category);
    if (inputRef.current && category === CategoryName.SEARCH) {
      inputRef.current.focus();
    } else if (category === CategoryName.FREQUENT) {
    }
  };

  const getEmoji = (emojiName: string): Emoji => emojiData.emojis[emojiName];

  const defaultEmojis = (): Emoji[] =>
    defaultMostUsedEmojis
      .map(emojiName => getEmoji(emojiName))
      .filter(emoji => emoji !== null);

  const getRecentEmojis = (): Emoji[] => {
    try {
      const recentTotal = recentEmojis.length;
      if (recentTotal < MAX_EMOJIS_COUNT) {
        const remainingEmojis = MAX_EMOJIS_COUNT - recentTotal;
        return [
          ...recentEmojis,
          ...defaultEmojis()
            .filter(
              ({ id }) =>
                !recentEmojis.find(({ id: recentId }) => recentId === id)
            )
            .slice(0, remainingEmojis)
        ];
      }
    } catch (e) {
      console.error(e);
    }
    return defaultEmojis();
  };

  const searchedEmojis = (): Emoji[] =>
    Object.entries(emojiData.emojis).reduce((acc, [key, { keywords }]) => {
      if (
        keywords.find(keyword =>
          keyword.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        // @ts-expect-error
        acc.push(emojiData.emojis[key]);
      }
      return acc;
    }, []);

  const getRecentEmojisFromPreferences = async () => {
    const recentEmojisFromPreferences = await getPreference({
      projectId,
      preference: 'recent_emojis',
      isAdminView
    });

    return Array.isArray(recentEmojisFromPreferences)
      ? recentEmojisFromPreferences
      : [];
  };

  const storeRecentEmoji = async (emoji: Emoji) => {
    const recentEmojisClone = [...recentEmojis];
    if (!recentEmojisClone.find(({ id }) => id === emoji.id)) {
      recentEmojisClone.unshift(emoji);
      setRecentEmojis(recentEmojisClone);
      setPreferences({
        projectId,
        preferences: { recent_emojis: recentEmojisClone.slice(0, 36) },
        isAdminView
      });
    }
  };

  const initRecentEmojis = async () => {
    const recentlyUsedEmojis = await getRecentEmojisFromPreferences();
    setRecentEmojis(recentlyUsedEmojis);
  };

  const searchResults = searchValue ? searchedEmojis() : [];

  useEffect(() => {
    if (searchValue && selectedCategory !== CategoryName.SEARCH) {
      setSelectedCategory(CategoryName.SEARCH);
    }
  }, [searchValue]);

  useEffect(() => {
    initSkinTonePreference();
    initRecentEmojis();
  }, []);

  return (
    <EmojiStateContext.Provider
      value={{
        emojiData,
        searchValue,
        setSearchValue,
        hoveredEmoji,
        setHoveredEmoji,
        skinTone,
        setSkinTone,
        selectedCategory,
        setSelectedCategory,
        getEmoji,
        handleCategoryClick,
        getContainer,
        darkMode,
        inputRef,
        onEmojiSelect,
        searchResults,
        getRecentEmojis,
        storeRecentEmoji,
        defaultPreviewEmoji: DEFAULT_PREVIEW_EMOJI,
        isDropdownOpen,
        setIsDropdownOpen,
        isAdminView
      }}
    >
      {children}
    </EmojiStateContext.Provider>
  );
};

const useEmojiState = (): EmojiState => {
  const context = useContext(EmojiStateContext);
  if (context === undefined) {
    throw new Error('useEmojiState must be used within a EmojiProvider');
  }
  return context;
};

export { EmojiProvider, useEmojiState };
