import * as React from 'react';
import { Button } from 'antd';
import { Edit } from 'lucide-react';

import styles from './index.module.css';

interface Props {
  title: string;
  visibleStepTitle: string;
  activeTitle: string;
  isVisibleStep: boolean;
  isActive: boolean;
  stepOnClick: () => void;
}

const StepTitle: React.FC<Props> = ({
  title,
  visibleStepTitle,
  activeTitle,
  isActive,
  isVisibleStep,
  stepOnClick
}) => {
  const [showEdit, setShowEdit] = React.useState<boolean>(false);

  if (isVisibleStep) {
    return (
      <div className={styles.stepVisibleStepTitle}>{visibleStepTitle}</div>
    );
  }

  if (!isActive) {
    return <div className={styles.stepTitle}>{title}</div>;
  }

  return (
    <Button
      className={styles.stepTitleDone}
      onMouseEnter={() => setShowEdit(true)}
      onMouseOver={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      onBlur={() => setShowEdit(false)}
      onClick={() => {
        setShowEdit(false);
        stepOnClick();
      }}
    >
      <span className={styles.activeTitle}>{activeTitle}</span> {showEdit && <Edit size={16} />}
    </Button>
  );
};

export default StepTitle;
