import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Button, message } from 'antd';
import { post } from 'jsUtilities/fetch';
import EmailForm from './email_form';

const InstructionsTab = ({ instructionsEndpoint }) => {
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async event => {
    setSaving(true);
    event.preventDefault();
    try {
      const request = await post(instructionsEndpoint, { email });
      const response = await request;
      if (response) {
        message.success(
          `Installation instructions will be delivered to ${email}`
        );
        setSaving(false);
      }
    } catch (err) {
      message.error('Something went wrong, please try again.');
      setSaving(false);
    }
  };

  return (
    <div className={styles.instructionsOuter}>
      <p className={styles.sendInstruction}>
        Send instructions over to a team member.
      </p>
      <div className={styles.emailBox}>
        <EmailForm emailPass={setEmail} />
      </div>
      <Button
        className={styles.sendButton}
        type="primary"
        loading={saving}
        onClick={handleSubmit}
      >
        Send
      </Button>
    </div>
  );
};

InstructionsTab.propTypes = {
  instructionsEndpoint: PropTypes.string
};

export default InstructionsTab;
