import React from 'react';
import { Alert, Switch, Button, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];
export default class PublicFeedback extends React.Component {
  static propTypes = {
    javascript_installed: PropTypes.bool.isRequired,
    is_public: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    authenticityToken: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    publicMode: this.props.is_public,
    saving: false
  };

  handleSwitch = checked => {
    this.setState({
      publicMode: checked
    });
  };

  handleCancel = () => {
    this.props.onCancel();

    this.setState({
      publicMode: this.props.is_public
    });
  };

  handleSave = () => {
    this.setState({
      saving: true
    });

    fetch(this.props.endpoint, {
      method: 'PATCH',
      body: JSON.stringify({ is_public: this.state.publicMode }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.authenticityToken
      }
    })
      .then(res => {
        if (res.status !== 302 && res.status !== 200) {
          throw Error(strings.notFound);
        }
        res.json();
      })
      .then(() => {
        this.setState({
          saving: false
        });

        message.success(
          `${strings.publicFeedbackIsNow}${
            this.state.publicMode ? strings.enabled : strings.disabled
          } ${strings.forThisProject}`
        );
      })
      .catch(err => {
        this.setState({
          saving: false,
          publicMode: !this.state.publicMode
        });

        message.error(strings.somethingWentWrong);
      });
  };

  render() {
    const javascriptInstalled = true || this.props.javascript_installed;
    const { publicMode, saving } = this.state;

    return (
      <div className={styles.publicFeedbackContainer}>
        <p>
          {strings.publicFeedbackDescription}
          <HelpArticleButton articleId="36352" />
        </p>
        {!javascriptInstalled && (
          <Alert
            type="info"
            showIcon
            message={
              <span className={styles.alertMessage}>
                {strings.installEmbedCode}
                <span className={styles.getEmbed}>
                  {strings.getTheEmbedCode} <span className={styles.here}>{strings.here}</span>
                </span>
                .
              </span>
            }
          />
        )}

        {javascriptInstalled && (
          <div>
            <div className={styles.switchBox}>
              <Switch checked={publicMode} onChange={this.handleSwitch} />
              <span className={publicMode ? styles.enabled : styles.disabled}>
                {publicMode ? strings.enabledCaps : strings.disabledCaps}
              </span>
            </div>
          </div>
        )}

        <div className={styles.action}>
          <Button
            type="primary"
            size="large"
            onClick={this.handleSave}
            loading={saving}
          >
            {strings.save}
          </Button>
          <Button size="large" onClick={this.handleCancel}>
            {strings.cancel}
          </Button>
        </div>
      </div>
    );
  }
}
