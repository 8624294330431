import * as React from 'react';
import { debounce } from 'lodash-es';

const debounceWait = 500;

type Filter = {
  name: string;
};

const useFilterByName = ({
  onChange
}: {
  onChange: () => void;
}): {
  filter?: Filter;
  setSearchByName: (name: string) => void;
  searchByName: string;
} => {
  const [searchByName, setSearchByName] = React.useState<string>('');
  const [filter, setFilter] = React.useState<Filter>();

  const debounceSetFilter = React.useCallback(
    debounce(
      (name: string) => {
        setFilter({ name });
        onChange();
      },
      debounceWait,
      { trailing: true }
    ),
    []
  );

  React.useEffect(() => {
    if (searchByName === '') {
      setFilter(undefined);
      onChange();
    } else {
      debounceSetFilter(searchByName);
    }
  }, [searchByName, debounceSetFilter]);

  return {
    filter,
    setSearchByName,
    searchByName
  };
};

export default useFilterByName;
