import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, message, Image } from 'antd';

import { getLangKey } from 'models/Language';
import { setupProjectUrl } from '../../api/ProjectApi';
import { stripUrlSearchAndHash } from '../../utils/projectSettingsOperations';
import { Project } from './types';

import ProjectUrlInput from '../../components/projectUrlInput';

import * as translations from './strings';
import websiteFeedbackImage from './assets/website-feedback.png';
import styles from './index.module.css';

const strings = translations[getLangKey()];

type WebsiteFeedbackProps = {
  project: Project;
  projectUrl: string;
  setProjectUrl: (projectUrl: string) => void;
};

const WebsiteFeedback = ({
  project,
  projectUrl,
  setProjectUrl
}: WebsiteFeedbackProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const submitUrlData = async () => {
    if (!project || !project.id || !projectUrl) {
      return setIsSubmitting(false);
    }
    try {
      const response = await setupProjectUrl({
        projectId: project.id,
        updateProjectData: {
          name: project.name,
          devurl: stripUrlSearchAndHash(projectUrl),
          toggle_vertical_position: 90
        }
      });
      if (response.url) {
        // navigate to embedded sidebar with project key
        setIsSubmitting(false);
        setTimeout(() => navigate('/preview'), 300);
      } else {
        message.error(strings.updateError);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleSubmitUrl = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    try {
      if (await form.validateFields(['WebsiteURL'])) {
        submitUrlData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.flexContainerWrapper}>
        <div className={styles.contentWrapper}>
          <p>{strings.step1}</p>
          <h2>{strings.whatWebsiteFeedback}</h2>
          <Form
            form={form}
            className={styles.inputButtonContainer}
            onFinish={handleSubmitUrl}
          >
            <ProjectUrlInput
              fieldName={['WebsiteURL']}
              currentUrl={projectUrl}
              onChange={(newUrl: string) => {
                setProjectUrl(newUrl);
              }}
              autoFocus
              isUrlRequired
              showUrlPlaceHolder
              validateFirst={false}
              shouldValidateDNS={false}
            />
            <Button
              type="primary"
              htmlType="submit"
              className={styles.nextButton}
              loading={isSubmitting}
            >
              {strings.next}
            </Button>
          </Form>
          <p>{strings.websiteFeedbackDescription}</p>
          <Link to="/before-we-begin" type="button">
            <Button type="text" className={styles.backButton}>
              {strings.back}
            </Button>
          </Link>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            placeholder
            loading="eager"
            src={websiteFeedbackImage}
            alt={strings.websiteFeedback}
            preview={false}
          />
        </div>
      </div>
    </div>
  );
};

export default WebsiteFeedback;
