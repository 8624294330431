import React, { useState } from 'react';
import cx from 'classnames';

import { Input } from 'antd';
import { X as Cancel } from 'lucide-react';
import styles from './index.module.css';

interface ColumnNameProps {
  onCancel: () => void;
  onSave: (name: string) => void;
  value: string;
  color: string;
  placeholder: string;
}

const ColumnName: React.FC<ColumnNameProps> = ({
  onCancel,
  onSave,
  value,
  color,
  placeholder
}) => {
  const [tempName, setTempName] = useState(value);

  const handleOnChange = event => {
    setTempName(event.target.value);
  };

  const handleBlur = () => {
    onCancel();
  };

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  const handleCreate = e => {
    e.preventDefault();
    onSave(tempName);
  };

  return (
    <div className={styles.inputAndControls}>
      <form onSubmit={handleCreate} className={styles.form}>
        <Input
          className={styles.columnNameInput}
          data-cy="columnNameInput"
          value={tempName}
          onChange={handleOnChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          maxLength={255}
          autoFocus
          required
        />
        <Input type="submit" style={{ display: 'none' }} />
      </form>
      <Cancel
        id="editCancel"
        className={cx({
          [styles.cancelIcon]: true,
          [styles.colorBackground]: color !== 'white' && color !== null
        })}
        onClick={onCancel}
      />
    </div>
  );
};

export default ColumnName;
