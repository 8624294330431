export type BulkAction = 'selectInRange' | 'selectInGroup';
export const SELECT_TASK_RANGE: BulkAction = 'selectInRange';
export const SELECT_TASK_GROUP: BulkAction = 'selectInGroup';

const multiSelectRange = (taskId, selectedTaskIdList, columnTaskList) => {
  const indexOfLast = columnTaskList.findIndex(task => {
    return (
      (task.id ?? task) === selectedTaskIdList[selectedTaskIdList.length - 1]
    );
  });

  const indexOfNew = columnTaskList.findIndex(
    task => (task.id ?? task) === taskId
  );

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;
  const inBetweenTasks = columnTaskList.slice(start, end + 1);
  const inBetweenTaskIds = inBetweenTasks.map(task => task.id ?? task);
  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetweenTaskIds.filter(id => !selectedTaskIdList.includes(id));

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedTaskIdList, ...sorted];
  return combined;
};

const toggleSelectionInGroup = (taskId, selectedTaskIdList) => {
  const index = selectedTaskIdList.indexOf(taskId);

  // if not selected - add it to the selected items
  if (index === -1) {
    return [...selectedTaskIdList, taskId];
  }

  // it was previously selected and now needs to be removed from the group
  const shallow = [...selectedTaskIdList];
  shallow.splice(index, 1);
  return shallow;
};

export function getMultiSelectList(
  action,
  taskId,
  selectedList,
  columnList
): any[] {
  if (action === SELECT_TASK_RANGE) {
    return multiSelectRange(taskId, selectedList, columnList);
  } else if (action === SELECT_TASK_GROUP) {
    return toggleSelectionInGroup(taskId, selectedList);
  }
  return [];
}

export const wasToggleInSelectionGroupKeyUsed = event => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
  return isUsingWindows ? event.ctrlKey : event.metaKey;
};

export const wasMultiSelectKeyUsed = event => event.shiftKey;
