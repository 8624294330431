import React from 'react';
import PropTypes from 'prop-types';
import Integration from './integration';
import { Route, Routes } from 'react-router-dom';
import Manage from './manage';
import { Divider, Typography } from 'antd';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];
const { Title } = Typography;

export default class Integrations extends React.Component {
  static propTypes = {
    org: PropTypes.object,
    setParentState: PropTypes.func,
    isLoading: PropTypes.bool,
    tempZapierData: PropTypes.object,
    user: PropTypes.object,
    accessTo: PropTypes.shape({
      billing: PropTypes.bool
    }),
    navigate: PropTypes.func
  };

  getIntegration = value =>
    this.props.org.integrations.find(
      integration => integration.connector.code === value
    );

  manageIntegrations = integrations => {
    const { org } = this.props;
    if (integrations.length) {
      return integrations.map(int => {
        return (
          <Route
            key={int.title}
            path={`/${int.integrationId}/edit`}
            element={
              <Manage
                connected={int.isConnected}
                apiKey={org.organization.api_key}
                orgName={org.organization.name}
                orgId={org.organization.id}
                integrationId={int.integrationId}
              />
            }
          />
        );
      });
    }

    return null;
  };

  getIntegrationContent = code => {
    const { org, tempZapierData } = this.props;
    const integration = this.getIntegration(code);
    const connector = org.connectors.find(connector => connector.code === code);
    if (!connector) return;
    let isConnected = integration ? integration.isConnected : false;
    const getId = () => {
      if (integration) {
        return integration.id;
      } else if (tempZapierData) {
        return tempZapierData.id;
      }
      return null;
    };
    const data = {
      intInfo: integration,
      title: connector.name,
      code: code,
      isConnected,
      primaryButton: isConnected ? 'Manage' : 'Connect',
      secondaryButton: isConnected && code !== 'zapier' && 'Projects',
      orgId: org.organization.id,
      integrationId: getId(),
      img: {
        className: code,
        alt: `${connector.name} logo`
      },
      key: code
    };

    switch (code) {
      case 'zapier':
        data.about = strings.zapierAbout;
        break;
      case 'slack':
        data.about = strings.slackAbout;
        break;
      case 'github':
        data.about = strings.githubAbout;
        break;
      case 'bcx':
        data.about = strings.bcxAbout;
        break;
      case 'harvest':
        data.about = strings.harvestAbout;
        break;
    }

    return data;
  };

  render() {
    const {
      isLoading,
      setParentState,
      tempZapierData,
      org,
      accessTo
    } = this.props;
    const data = this.getIntegrationContent;
    const zapier = data('zapier');
    const zapierProps = {
      ...zapier,
      isLoading,
      setParentState,
      tempZapierData
    };
    const harvest = data('harvest');
    const harvestProps = { ...harvest, isLoading, setParentState };
    const integrations = ['slack', 'github', 'bcx'];
    const manageableIntegrations = [data('zapier')];
    return (
      <div className={styles.componentContainer}>
        <Routes>
          {zapier.integrationId &&
            this.manageIntegrations(manageableIntegrations)}
          <Route
            path="/*"
            element={
              <div className={styles.integrationContainer}>
                <h3 className={styles.integrationHeader}>
                  {strings.integrations}
                </h3>
                {integrations.map(integration => (
                  <Integration
                    {...data(integration)}
                    integrationAllowed
                    billingRights={accessTo.billing}
                    subscriptionLink={org.subscriptionLink}
                    navigate={this.props.navigate}
                  />
                ))}

                {harvest && (
                  <Integration
                    {...harvestProps}
                    integrationAllowed
                    billingRights={accessTo.billing}
                    subscriptionLink={org.subscriptionLink}
                    navigate={this.props.navigate}
                  />
                )}

                <Divider />

                <Title level={3}>{strings.connectBugherdTo}</Title>
                <Integration
                  {...zapierProps}
                  integrationAllowed={org.zapierAllowed}
                  billingRights={accessTo.billing}
                  subscriptionLink={org.subscriptionLink}
                  navigate={this.props.navigate}
                />

                {org.zapierAllowed && (
                  <zapier-app-directory
                    app="bugherd"
                    theme="light"
                    applimit={4}
                    zaplimit={6}
                    zapstyle="row"
                    introcopy="hide"
                    style={{
                      width: 'calc(100% - 4px)',
                      margin: '40px auto 0 auto'
                    }}
                  />
                )}
              </div>
            }
          />
        </Routes>
      </div>
    );
  }
}
