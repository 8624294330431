import React, { ReactNode } from 'react';
import { Input } from 'antd';
import { Search } from 'lucide-react';

import * as routes from 'app/routes';
import { getLangKey } from 'models/Language';

import * as translations from './strings';
import styles from './index.module.css';
import { AddButton } from '../project_board/project_header/AddButton';

type Props = {
  organizationId: string;
  searchByName: string;
  setSearchByName: (name: string) => void;
  children: ReactNode;
  showAddButton: boolean;
};

const SearchAndAddProject = ({
  organizationId,
  searchByName,
  setSearchByName,
  children,
  showAddButton
}: Props) => {
  const strings = translations[getLangKey()];

  return (
    <div className={styles.topContainer}>
      <div className={styles.searchInputContainer}>
        <Input
          placeholder={strings.searchForProjectByName}
          className={styles.searchInput}
          prefix={<Search className={styles.search} />}
          value={searchByName}
          onChange={({ target: { value } }) => setSearchByName(value)}
        />
        {children}
        {showAddButton && (
          <AddButton
            onClick={() =>
              window.open(routes.newProjectPath({ organizationId }), '_self')
            }
            className={styles.addButton}
            title="Create a new project"
            dataCypressTrigger="newProjectButton"
          />
        )}
      </div>
    </div>
  );
};

export default SearchAndAddProject;
