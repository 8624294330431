import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';

type Props = {
  url: string;
};

const HarvestIframe: FC<Props> = ({ url }: Props) => {
  const [iframeHeight, setIframeHeight] = useState('350');

  useEffect(() => {
    const handler = event => {
      if (event.origin !== 'https://platform.harvestapp.com') {
        return;
      }

      if (event.data.type === 'frame:resize') {
        setIframeHeight(event.data.value + 'px');
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  return (
    <div className={styles.iframeContainer}>
      <iframe
        height={iframeHeight}
        className={styles.harvestIframe}
        src={url}
      />
    </div>
  );
};

export default HarvestIframe;
