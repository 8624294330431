import React from 'react';
import { Link } from 'react-router-dom';

import { getLangKey } from 'models/Language';

import { User } from './types';

import * as translations from './strings';
import styles from './index.module.css';
import www from './assets/www.png';
import pdfImage from './assets/pdfimage.png';
import figma from './assets/figma.png';
import track from 'jsUtilities/analytics';

const strings = translations[getLangKey()];

export const TryBugherdOn = ({ user }: { user: User }) => {
  document.title = 'Welcome | BugHerd';
  const firstName =
    user?.name.charAt(0).toUpperCase() + user?.name.slice(1) || 'there';

  const trackSignupMethod = (method: string) => () => {
    track('Onboarding signup method', {
      method,
      user: user?.id,
      organization: user?.trial_organization_id
    });
  };

  return (
    <div className={`${styles.tryBugherdOnContainer} ${styles.container}`}>
      <div className={styles.containerWrapper}>
        <div className={styles.content}>
          <h2>
            <span className={styles.waveHand}>👋 </span>{' '}
            {strings.nameIntro(firstName)}
          </h2>
          <p>{strings.contentIntro}</p>
          <b>{strings.whatFeedback}</b>
        </div>
        <Link
          onClick={trackSignupMethod('website')}
          to="/before-we-begin"
          className={styles.stepCard}
        >
          <img
            height={100}
            width={100}
            src={www}
            alt={strings.websiteFeedback}
          />
          <div className={styles.stepCardWrapper}>
            <h4>{strings.website}</h4>
            <p>{strings.websiteFeedback}</p>
          </div>
        </Link>
        <Link
          onClick={trackSignupMethod('figma')}
          className={styles.stepCard}
          to="/figma-feedback"
        >
          <img
            height={100}
            width={100}
            src={figma}
            alt={strings.figmaFeedback}
          />
          <div className={styles.stepCardWrapper}>
            <h4>{strings.figma}</h4>
            <p>{strings.figmaFeedback}</p>
          </div>
        </Link>

        <Link
          onClick={trackSignupMethod('assets')}
          className={styles.stepCard}
          to="/pdf-or-image-feedback"
        >
          <img
            height={100}
            width={100}
            src={pdfImage}
            alt={strings.pdfImageFeedback}
          />
          <div className={styles.stepCardWrapper}>
            <h4>{strings.pdfImage}</h4>
            <p>{strings.pdfImageFeedback}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
