import { gql } from '@apollo/client';
import client from 'api/apollo/client';

export const getAvailablePlans = (organizationId: string) =>
  client.readFragment({
    id: `Organization:${organizationId}`,
    fragment: gql`
      fragment AvaliablePlans on Organization {
        availablePlans {
          id
          name
          handle
          price
          maxMembers
          interval
        }
      }
    `
  });
