import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'appAssets/images/bugherd-word-logo.svg';

import styles from './index.module.css';

export default () => {
  const pathname = window.location.pathname;

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div>
        <a href={window.location.origin} className={styles.backLink}>
          Back to website
        </a>
        {pathname === '/registrations/new' && (
          <Link to="/sign_in" className={styles.existingAccount}>
            Already have an account
          </Link>
        )}
      </div>
    </div>
  );
};
