export const checkForExtension = () => {
  let exists = false;
  const attempts = 15;
  let count = 1;

  const removeListener = cb =>
    document.removeEventListener('bugherdExistanceResponse', cb);

  return new Promise((resolve, reject) => {
    const onResponse = () => {
      exists = true;
      removeListener(onResponse);
      resolve(true);
    };

    const onNoResponse = () => {
      removeListener(onResponse);
      resolve(false);
    };

    document.addEventListener('bugherdExistanceResponse', onResponse);

    const event = document.createEvent('CustomEvent');

    event.initCustomEvent(
      'bugherdExistanceRequest',
      true,
      true,
      JSON.stringify({})
    );

    document.dispatchEvent(event);

    const looper = () => {
      setTimeout(() => {
        count = count + 1;

        if (!exists) {
          if (count === attempts) {
            onNoResponse();
          } else {
            document.dispatchEvent(event);
            looper();
          }
        }
      }, 100);
    };

    looper();
  });
};
