import React, { FC } from 'react';

import cx from 'classnames';

import {
  X as Close,
  Maximize2 as Maximize,
  Minimize2 as Minimize
} from 'lucide-react';
import { Button } from 'antd';

import styles from './index.module.css';

type Props = {
  closeDetailBar: () => void;
  toggleExpandedView: () => void;
  mode: 'maximized' | 'minimized';
  className: string;
  isExpandedView: boolean;
};

const ViewActions: FC<Props> = ({
  closeDetailBar,
  toggleExpandedView,
  mode = 'minimized',
  className = '',
  isExpandedView
}) => {
  return (
    <div
      className={cx(styles.detailsViewActions, className, {
        [styles.isExpanded]: isExpandedView
      })}
    >
      {
        {
          maximized: (
            <Button
              className={styles.iconWrapper}
              onClick={toggleExpandedView}
              type="text"
              icon={
                <Minimize
                  className={cx(styles.viewActionIcon, styles.minimize)}
                />
              }
            />
          ),
          minimized: (
            <Button
              className={styles.iconWrapper}
              onClick={toggleExpandedView}
              type="text"
              icon={
                <Maximize
                  className={cx(styles.viewActionIcon, styles.maximize)}
                />
              }
            />
          )
        }[mode]
      }
      <Button
        className={styles.iconWrapper}
        onClick={closeDetailBar}
        icon={<Close className={styles.closeIcon} />}
        type="text"
      />
    </div>
  );
};

export default ViewActions;
