import React from 'react';
import DraggerIcon from './dragger.svg';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import cx from 'classnames';
import { Tooltip } from 'antd';

import styles from './index.module.css';

type Props = {
  isDragging: boolean;
  projectName?: string;
  container: HTMLDivElement;
};

const strings = translations[getLangKey()];

const Dragger = (props: Props) => {
  const { isDragging, projectName, container } = props;
  return (
    <>
      <div
        key="dragWrapper"
        className={cx(styles.dragWrapper, 'dragWrapper', {
          [styles.isDragging]: isDragging
        })}
      >
        {isDragging ? (
          <>
            <DraggerIcon key="dragger" className={styles.dragIcon} />
            {projectName && (
              <span key="projectName" className={styles.projectName}>
                {projectName}
              </span>
            )}
          </>
        ) : (
          <>
            <Tooltip
              key="dragger"
              title={strings.moveMe}
              placement="right"
              getPopupContainer={() => container}
            >
              <DraggerIcon className={styles.dragIcon} />
            </Tooltip>
            <span
              title={`Project: ${projectName}`}
              className={styles.projectName}
            >
              {projectName}
            </span>
          </>
        )}
      </div>
      {projectName && (
        <span
          key="projectName"
          className={cx(styles.projectName, styles.noDragging)}
          title={`Project: ${projectName}`}
        >
          {projectName}
        </span>
      )}
    </>
  );
};

export default Dragger;
