import React, { FC, useState } from 'react';
import styles from './../index.module.css';
import { Modal, Radio, Typography, Button } from 'antd';
import { ExternalLink } from 'lucide-react';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Language';
import { detect } from 'detect-browser';
import EmbedCode from '../../../javascript/components/embed_code';
import { default as IconScript } from './../assets/icon_javascript.svg';
import SupportPerson from './../assets/support_kriz.png';
import GetBrowserExtension from '../../../../assets/images/browser_logos/get-browser-extension.svg';
import ChromeLogo from '../../../../assets/images/browser_logos/chrome.svg';
import EdgeLogo from '../../../../assets/images/browser_logos/edge.svg';
import FirefoxLogo from '../../../../assets/images/browser_logos/firefox.svg';
import SafariLogo from '../../../../assets/images/browser_logos/safari.svg';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLElement;
  isUseBugherdModalOpen: boolean;
  handleCloseUseBugherdModal: () => void;
  url: string;
  isGuest?: boolean;
};

const UseBugherdModal: FC<Props> = ({
  container,
  isUseBugherdModalOpen,
  handleCloseUseBugherdModal,
  url,
  isGuest
}) => {
  const projectBoardProps = window?.project_board_props;
  const project = projectBoardProps?.assetFeedbackProps?.project;
  const detectedBrowser = detect();
  const browser = detectedBrowser?.name || 'unknown';

  const { widgetCode } = project || {};

  const [value, setValue] = useState('extension');

  const onChange = e => {
    setValue(e.target.value);
  };

  const openElevio = e => {
    e.preventDefault();
    //@ts-expect-error
    // eslint-disable-next-line no-unused-expressions
    window._elev?.openHome();
  };

  const CHROME_BROWSER_NAME = 'chrome';
  const FIREFOX_BROWSER_NAME = 'firefox';
  const EDGE_BROWSER_NAME = 'edge-chromium';
  const SAFARI_BROWSER_NAME = 'safari';

  const browserOptions = [
    {
      id: 'chrome-option',
      href:
        'https://chrome.google.com/webstore/detail/bugherd-plus/popigpemobhbfkhnnkllkjgkaabedgpb',
      logo: ChromeLogo,
      name: CHROME_BROWSER_NAME
    },
    {
      id: 'firefox-option',
      href: 'https://addons.mozilla.org/en-US/firefox/addon/bugherd/',
      logo: FirefoxLogo,
      name: FIREFOX_BROWSER_NAME
    },
    {
      id: 'edge-option',
      href:
        'https://microsoftedge.microsoft.com/addons/detail/nkdboghnnmoenjjmcakndhjdecakkfpn',
      logo: EdgeLogo,
      name: EDGE_BROWSER_NAME
    },
    {
      id: 'safari-option',
      href:
        'https://apps.apple.com/us/app/bugherd-website-feedback-tool/id1545845981',
      logo: SafariLogo,
      name: SAFARI_BROWSER_NAME
    }
  ];

  const width = window.innerWidth * 0.8; // 80% of window's width
  const height = window.innerHeight * 0.8; // 80% of window's height
  const midLeft = window.screenX + window.outerWidth / 2 - width / 2;
  const midTop = window.screenY + window.outerHeight / 2 - height / 2;

  const openExtensionPage = option => {
    window.open(
      option.href,
      'newwindow',
      `width=${width},height=${height},left=${midLeft},top=${midTop}`
    );
    window.focus();
  };

  const currentBrowserOption = browserOptions.find(
    option => option.name === browser
  );

  const Logo = currentBrowserOption?.logo;

  return (
    <Modal
      open={isUseBugherdModalOpen}
      getContainer={container}
      destroyOnClose
      closable
      onCancel={() => {
        handleCloseUseBugherdModal();
      }}
      footer={null}
      centered
      className={styles.useBugherdModal}
      wrapClassName={'antd-container'}
    >
      <div className={styles.addWebsiteModal}>
        <h2> {strings.toUseBugherd} </h2>
        {!isGuest && (
          <>
            <p>{strings.youllNeedExtensionOrCode}</p>
            <p>{strings.chooseHow}</p>

            <div className={styles.radioWrapper}>
              <Radio.Group
                className={styles.radioWrapper}
                onChange={onChange}
                value={value}
              >
                <Radio className={styles.radioValue} value={'extension'}>
                  <div className={styles.logoSimpleWrapper}>
                    <Logo
                      className={styles.logoSimple}
                      alt={currentBrowserOption?.name}
                    />

                    <span className={styles.blueTextWrapper}>
                      {strings.simple}
                    </span>
                  </div>
                  <p>{strings.getTheExtension}</p>
                </Radio>
                <Radio
                  className={styles.radioValue}
                  value={'Javascript install'}
                >
                  <IconScript />
                  <p>{strings.javascriptInstall}</p>
                </Radio>
              </Radio.Group>
            </div>
          </>
        )}
        <div className={styles.blueWrapper}>
          {value === 'extension' ? (
            <div className={styles.blueExtensionWrapper}>
              <div>
                <Typography.Title level={4}>
                  {strings.extensionInfo}
                </Typography.Title>
                <Button
                  icon={
                    <Logo
                      className={styles.buttonIcon}
                      alt={currentBrowserOption?.name}
                    />
                  }
                  type="primary"
                  onClick={() => {
                    openExtensionPage(currentBrowserOption);
                  }}
                >
                  {strings.getExtension}
                </Button>
              </div>
              <div className={styles.extensionImageWrapper}>
                <GetBrowserExtension />
              </div>
            </div>
          ) : (
            <div className={styles.embedCode}>
              <div>
                <Typography.Title level={3}>
                  {strings.installJavascript}
                </Typography.Title>
                {strings.addThisCode}
                <code className={styles.head}>{strings.head}</code>
                {strings.addThisCode2}
              </div>
              <div className={styles.embedWrapper}>
                <EmbedCode widgetCode={widgetCode} />
              </div>

              <Button
                icon={<ExternalLink className={styles.websiteIcon} />}
                type="primary"
                onClick={() => window.open(url, '_blank')}
              >
                {strings.openWebsite}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.supportWrapper}>
          <img
            width={30}
            height={30}
            src={SupportPerson}
            alt="Support person"
          />
          <span>{strings.needHelp}</span>
          <span className={styles.contactLink} onClick={openElevio}>
            {strings.contactSupport}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default UseBugherdModal;
