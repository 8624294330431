import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Select, Button } from 'antd';
import Papa from 'papaparse';

type Props = {
  source: string;
  setSourceLoading: (loading: boolean) => void;
  sourceLoading: boolean;
  csvData: {
    [key: string]: any[];
  }[];
  setCsvData: (
    csvData: {
      [key: string]: any[];
    }[]
  ) => void;
};

export const CsvPreview: FC<Props> = ({
  source,
  setSourceLoading,
  sourceLoading,
  csvData,
  setCsvData
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const csvDataObject = csvData.find(csv => csv[source]);

  useEffect(() => {
    if (!csvDataObject) {
      fetch(source)
        .then(response => response.text())
        .then(data => {
          Papa.parse(data, {
            header: true,
            complete: results => {
              const csvClone = csvData.slice();
              csvClone.push({ [source]: results.data });
              setCsvData(csvClone);
              setSourceLoading(false);
            }
          });
        })
        .catch(error => {
          console.error('Error fetching CSV file:', error);
          setSourceLoading(false);
        });
    } else if (sourceLoading) {
      setSourceLoading(false);
    }
  }, [source, csvDataObject]);

  if (!csvDataObject) return null;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const data = csvDataObject[source];
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(data.length / rowsPerPage);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.paginationControls}>
        <div className={styles.controls}>
          <Select
            className={styles.rowsPerPage}
            value={rowsPerPage}
            onChange={(value: number) => {
              setRowsPerPage(value);
              setCurrentPage(1);
            }}
            getPopupContainer={() => document.body}
            popupClassName={styles.rowsPerPagePopup}
            options={[
              {
                value: 10,
                label: '10'
              },
              {
                value: 20,
                label: '20'
              },
              {
                value: 50,
                label: '50'
              },
              {
                value: 100,
                label: '100'
              }
            ]}
          />
          <span className={styles.perPageLabel}>Rows per page</span>
          <Button
            type="default"
            size="small"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.controlButton}
          >
            Previous
          </Button>
          <Button
            type="default"
            size="small"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.controlButton}
          >
            Next
          </Button>
        </div>
        <span>
          Page {currentPage} of {totalPages}
        </span>
      </div>
      <table className={styles.csvTable}>
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map(header => (
                <th key={header}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => (
            <tr key={index}>
              {Object.values(row).map((value, i) => (
                <td key={i}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
