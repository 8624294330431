import NewBugPin0 from '../../assets/images/bug-p0-new.png';
import NewBugPin1 from '../../assets/images/bug-p1-new.png';
import NewBugPin2 from '../../assets/images/bug-p2-new.png';
import NewBugPin3 from '../../assets/images/bug-p3-new.png';
import NewBugPin4 from '../../assets/images/bug-p4-new.png';

export const newPins = [
  NewBugPin0,
  NewBugPin1,
  NewBugPin2,
  NewBugPin3,
  NewBugPin4
];
