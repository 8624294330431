import * as React from 'react';

import useFilterByName from 'hooks/useFilterByName';

import { useGetUserProjectQuery } from './query';
import { SelectItemProps } from '../../MultiSelectList';
import useFetchPage from '../../hooks/useFetchPage';

const PAGE_SIZE: number = 100;

const useProjectFilter = (organizationId: string) => {
  const limit = PAGE_SIZE;

  const [selectOption, setSelectOption] = React.useState<SelectItemProps[]>([]);
  const [totalPageNumber, setTotalPageNumber] = React.useState<number>(0);

  const { pageNumber, setPageNumber, fetchNextPage } = useFetchPage({
    totalPageNumber
  });

  const { filter, setSearchByName } = useFilterByName({
    onChange: () => setPageNumber(1)
  });

  const { data, loading, error, refetch } = useGetUserProjectQuery({
    organizationId,
    offset: (pageNumber - 1) * limit,
    limit,
    filter
  });

  React.useEffect(() => {
    if (loading || !data) return;

    setTotalPageNumber(Math.ceil(data.currentUser.userProjectsCount / limit));

    const options: SelectItemProps[] = data.currentUser.userProjects.map(
      ({ name, id }) => ({
        label: name,
        value: id
      })
    );
    setSelectOption(options);
  }, [loading, data]);

  return {
    selectOption,
    fetchNextPage,
    setSearchByName,
    loading,
    error,
    refetch
  };
};

export default useProjectFilter;
