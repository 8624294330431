import React, { useEffect } from 'react';
import { ApolloCache, FetchResult, useMutation } from '@apollo/client';
import { Button, FormInstance, message, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';

import { ProjectSite } from 'models/Project';
import {
  decodeURLAndStripProtocol,
  encodeURL
} from 'utils/projectSettingsOperations';

import styles from './index.module.css';
import {
  DeleteProjectSiteDetails,
  DeleteProjectSiteResponse,
  DELETE_PROJECT_SITE
} from './mutations';

import ProjectUrlInput from 'components/projectUrlInput';
import RemoveUrl from '../RemoveUrl';
import { validateUrlRemoval } from './validateUrlRemove';

const updateCacheOnDeleteSite = (
  cache: ApolloCache<any>,
  mutationResult: FetchResult<{ deleteProjectSite: DeleteProjectSiteResponse }>
) => {
  const project = mutationResult.data?.deleteProjectSite.project;
  const projectSite = mutationResult.data?.deleteProjectSite.projectSite;

  if (!project) {
    return;
  }

  const projectId = cache.identify(project);
  cache.modify({
    id: projectId,
    fields: {
      sites(projectSiteRefs, { readField }) {
        return projectSiteRefs.filter(
          (siteRef: any) => projectSite?.id !== readField('id', siteRef)
        );
      }
    }
  });
};

interface Props {
  projectId: string;
  projectSites: ProjectSite[];
  onChange: (sites: ProjectSite[]) => void;
  parentForm: FormInstance;
}

const ProjectSites = ({
  projectId,
  projectSites,
  onChange,
  parentForm
}: Props) => {
  const form = parentForm;

  useEffect(() => {
    form.setFieldsValue({
      url: projectSites.map(({ id, url }) => ({
        id,
        url: decodeURLAndStripProtocol(url)
      }))
    });
  }, [projectSites]);

  const [deleteProjectSite] = useMutation<
    { deleteProjectSite: DeleteProjectSiteResponse },
    { input: DeleteProjectSiteDetails }
  >(DELETE_PROJECT_SITE, {
    update: updateCacheOnDeleteSite,
    onCompleted: ({ deleteProjectSite: { projectSite } }) => {
      if (projectSite) {
        onChange([...projectSites.filter(({ id }) => id !== projectSite.id)]);
      }
    },
    onError: error => {
      if (error.message) {
        message.error(error.message);
      }
    }
  });
  const sitesLength = projectSites.length;

  const handleRemoveUrl = (id: string, index: number) => {
    if (!!validateUrlRemoval(projectSites, index)) return;
    if (id === '') {
      onChange([...projectSites.filter((_, position) => position !== index)]);
    } else {
      deleteProjectSite({
        variables: { input: { projectSiteId: id } },
        optimisticResponse: {
          deleteProjectSite: {
            project: {
              id: projectId
            },
            projectSite: {
              id
            }
          }
        }
      });
    }
  };

  return (
    <>
      <div className={styles.inputsOuter}>
        {projectSites.length > 4 && <div className={styles.overlay} />}
        <div className={styles.inputsContainer}>
          {projectSites.map(({ id, url }, index) => {
            const key = `${id}-${index}-${url}`;
            return (
              <Row key={key}>
                <div className={styles.urlInput}>
                  {' '}
                  <ProjectUrlInput
                    fieldName={['url', index, 'url']}
                    currentUrl={url}
                    onChange={(newUrl: string) => {
                      const newProjectSites = [...projectSites];
                      newProjectSites[index] = {
                        id,
                        url: encodeURL(newUrl)
                      };
                      onChange(newProjectSites);
                    }}
                    autoFocus={url.trim() === ''}
                    isUrlRequired={projectSites.length < 2}
                    validateFirst={projectSites.length < 2}
                    checkUrlOnEnter={false}
                  />
                  {projectSites.length > 1 && (
                    <RemoveUrl
                      key="removeurl"
                      onClick={() => handleRemoveUrl(id, index)}
                    />
                  )}
                </div>
              </Row>
            );
          })}
        </div>
      </div>
      <Button
        type="link"
        className={cx(styles.addAnotherUrlButton, {
          [styles.addMargin]: sitesLength > 4
        })}
        icon={<PlusCircleOutlined />}
        onClick={() => {
          onChange([...projectSites, { id: '', url: '' }]);
        }}
      >
        Add another URL
      </Button>
    </>
  );
};

export default ProjectSites;
