let previousStyle = null;

export default {
  // currently only takes width, where any css value is accepted ('100%', 50, '50px')
  resize: width =>
    new window.Promise((resolve, reject) => {
      if (window.self === window.top) {
        // If this isn't loaded inside an iframe (legacy sidebar),
        // we dont need to resize the iframe
        resolve();
      }

      const slimSidebar = document.getElementsByClassName('mainNav')[0];
      const sidebarSwitch = document.getElementsByClassName('navOpts')[0];
      const listBar = document.getElementsByClassName('listbarWrapper')[0];

      const onMessage = event => {
        if (typeof event.data === 'object' && event.data !== null) {
          if (event.data.type === 'bugherd_resize_sidebar_response') {
            window.removeEventListener('message', onMessage);
            resolve();
          }
        }
      };

      window.addEventListener('message', onMessage);

      if (!previousStyle) {
        previousStyle = {
          slimZedIndex: slimSidebar.style.zIndex,
          switchZedIndex: sidebarSwitch.style.zIndex,
          listBarZedIndex: listBar.style.zIndex
        };
      }

      slimSidebar.style.zIndex = '9';
      sidebarSwitch.style.zIndex = '9';
      listBar.style.zIndex = '9';

      window.parent.postMessage(
        {
          type: 'bugherd_resize_sidebar',
          style: {
            width: width,
            right: 0,
            left: 'auto',
            transformOrigin: 'right top'
          }
        },
        '*'
      );
    }),
  reset: () =>
    new window.Promise((resolve, reject) => {
      if (window.self === window.top) {
        // If this isn't loaded inside an iframe (legacy sidebar),
        // we dont need to resize the iframe
        resolve();
      }

      if (!previousStyle) {
        return reject('No attributes to reset to');
      }

      const onMessage = event => {
        if (typeof event.data === 'object' && event.data !== null) {
          if (event.data.type === 'bugherd_reset_size_sidebar_response') {
            window.removeEventListener('message', onMessage);
            resolve();
          }
        }
      };

      window.addEventListener('message', onMessage);

      const slimSidebar = document.getElementsByClassName('mainNav')[0];
      const sidebarSwitch = document.getElementsByClassName('navOpts')[0];
      const listBar = document.getElementsByClassName('listbarWrapper')[0];

      const { slimZedIndex, switchZedIndex, listBarZedIndex } = previousStyle;

      slimSidebar.style.zIndex = slimZedIndex;
      sidebarSwitch.style.zIndex = switchZedIndex;
      listBar.style.zIndex = listBarZedIndex;

      window.parent.postMessage(
        {
          type: 'bugherd_reset_size_sidebar'
        },
        '*'
      );

      previousStyle = null;
    })
};
