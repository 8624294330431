import React, { useState } from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import { Menu, Dropdown, message } from 'antd';
import {
  Trash as Remove,
  Edit,
  PlusCircle as Add,
  MoreHorizontal as More,
  MessageCircle
} from 'lucide-react';

import { getLangKey } from 'jsModels/Application';

import ColumnName from './column_name/ColumnName';
import styles from './index.module.css';
import ArchiveAll from './ArchiveAll';
import * as translations from './strings';

import {
  ARCHIVE_ALL_MENU_KEY,
  ARCHIVE_STAGE_INITIAL,
  ARCHIVE_TASK_LIMIT
} from './types';
import { post } from 'appJS/utils/fetch';
import { useDispatch } from 'react-redux';

const ColumnHeader: React.FC<any> = props => {
  const {
    column,
    getColumnToDelete,
    toggleDeleteModal,
    toggleArchiveModal,
    getArchive,
    canManageColumn,
    isPlaceholder,
    archiveAllowed,
    cards,
    updateColumnName,
    isMinColumns,
    unfilteredTaskCount,
    dragHandleProps
  } = props;

  const [showEditName, setShowEditName] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [archiveStage, setArchiveStage] = useState(ARCHIVE_STAGE_INITIAL);
  const dispatch = useDispatch();

  const hideEditMode = () => {
    setShowEditName(false);
  };

  const handleColumnNameSave = tempName => {
    updateColumnName(column.id, tempName);
    hideEditMode();
  };

  const showEditMode = () => {
    if (canManageColumn) {
      setShowEditName(true);
    }
  };

  const getDeleteColumnProps = () => {
    let disabled = true;
    let title =
      "This column can't be deleted because it contains tasks. Try removing all of the tasks from the column and ensure you aren't filtering the tasks via the search or by user.";
    if (isMinColumns) {
      title =
        "This column can't be deleted because a minimum of two columns is required.";
    } else if (unfilteredTaskCount === 0) {
      return {
        disabled: false
      };
    }
    return {
      disabled,
      title
    };
  };

  const handleAddColumn = () => {
    const { insertFieldForNewColumn, columnPosition } = props;
    insertFieldForNewColumn(columnPosition);
  };

  const setIsMenuOpenWrapper = flag => {
    setIsMenuOpen(flag);
    setArchiveStage(flag ? ARCHIVE_STAGE_INITIAL : archiveStage);
  };

  const enableDropDownMenu = canManageColumn;
  const archiveDisabled = cards.length < 1;
  const langKey = getLangKey();
  const strings = translations[langKey];

  // hide unread comments open for the moment
  const unreadCommentsAllowed = false;
  return (
    <div
      className={cx({
        [styles.columnHeader]: true,
        [styles.redHeader]: column.color === 'red',
        [styles.blueHeader]: column.color === 'blue',
        [styles.greenHeader]: column.color === 'green',
        [styles.orangeHeader]: column.color === 'orange',
        [styles.whiteHeader]: column.color === 'white' || column.color === null,
        [styles.draggableHeader]: !showEditName && canManageColumn,
        ['dragHandler']: !showEditName,
        [styles.dummyHeader]: isPlaceholder
      })}
      {...dragHandleProps}
    >
      {!canManageColumn || !showEditName ? (
        <div className={styles.columnHeaderInfo}>
          <div onClick={showEditMode} className={styles.columnNameDisplay}>
            {column.name}
          </div>
          <div className={styles.columnCount}>
            <span className={styles.countInner}>{column.children.length}</span>
          </div>
        </div>
      ) : (
        <ColumnName
          onCancel={hideEditMode}
          onSave={handleColumnNameSave}
          value={column.name}
          color={column.color}
        />
      )}
      {enableDropDownMenu && (
        <Dropdown
          className={styles.moreDropdown}
          trigger={['click']}
          placement="bottomLeft"
          getPopupContainer={() =>
            document.getElementById('kanbanBoard') as HTMLElement
          }
          onOpenChange={setIsMenuOpenWrapper}
          open={isMenuOpen}
          overlay={
            <Menu>
              <Menu.Item
                className={styles.dropDownItem}
                onClick={() => {
                  // give it a bit of time for the animation to play
                  // out before showing the popup
                  setTimeout(
                    () =>
                      dispatch({
                        type: 'CREATE_TASK',
                        payload: { columnId: column.id }
                      }),
                    300
                  );
                }}
              >
                <div className={styles.actionBox}>
                  <Add className={styles.actionIcon} />
                  <span className={styles.actionText}>Add task</span>
                </div>
              </Menu.Item>
              <Menu.Item
                className={styles.dropDownItem}
                onClick={handleAddColumn}
              >
                <div className={styles.actionBox}>
                  <Add className={styles.actionIcon} />
                  <span className={styles.actionText}>Add column</span>
                </div>
              </Menu.Item>
              <Menu.Item className={styles.dropDownItem} onClick={showEditMode}>
                <div className={styles.actionBox}>
                  <Edit className={styles.actionIcon} />
                  <span className={styles.actionText}>Rename column</span>
                </div>
              </Menu.Item>

              {unreadCommentsAllowed && (
                <Menu.Item
                  className={styles.dropDownItem}
                  onClick={() => {
                    const tasksToMarkAsRead = cards.map(card =>
                      get(card, 'props.data.id')
                    );

                    post(`/projects/${props.project.id}/mark_tasks_as_read`, {
                      task_ids: tasksToMarkAsRead
                    })
                      .then(response => {
                        message.success(strings.markAllAsReadSuccess);
                      })
                      .catch(error => {
                        message.error(strings.markAllAsReadFailure);
                      });
                  }}
                >
                  <div className={styles.actionBox}>
                    <MessageCircle
                      width={15}
                      height={15}
                      color={'#848484'}
                      className={styles.unreadIcon}
                    />
                    <span className={styles.actionText}>Mark all as read</span>
                  </div>
                </Menu.Item>
              )}

              {archiveAllowed && (
                <Menu.Item
                  key={ARCHIVE_ALL_MENU_KEY}
                  className={styles.dropDownItem}
                  disabled={archiveDisabled}
                  onClick={() => {
                    toggleArchiveModal();

                    const tasksToArchive = cards
                      .map(card => get(card, 'props.data.id'))
                      .slice(0, ARCHIVE_TASK_LIMIT);
                    getArchive(tasksToArchive, column.id);
                  }}
                >
                  <ArchiveAll stage={archiveStage} taskCount={cards.length} />
                </Menu.Item>
              )}

              <Menu.Divider />
              <Menu.Item
                className={styles.dropDownItem}
                onClick={() => {
                  toggleDeleteModal();
                  getColumnToDelete(column.id);
                }}
                {...getDeleteColumnProps()}
              >
                <div className={styles.actionBox}>
                  <Remove className={styles.actionIcon} />
                  <span className={styles.actionText}>Delete column</span>
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <More
            className={cx({
              [styles.columnIcon]: true,
              [styles.whiteStrokeColor]:
                column.color !== 'white' && column.color !== null
            })}
            data-cy="columnIcon"
          />
        </Dropdown>
      )}
    </div>
  );
};
export default ColumnHeader;
