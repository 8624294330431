import * as React from 'react';
import { Input, message } from 'antd';
import HelpArticleButton from 'utils/HelpArticleButton';
import styles from './index.module.css';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
import cx from 'classnames';

type Props = {
  apiKey: string;
  className?: string;
};

const ProjectKey = (props: Props) => {
  const { apiKey, className } = props;
  const strings = translations[getLangKey()];
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <div className={cx([styles.apiOuter, className])}>
      {' '}
      <label htmlFor="apiInput" className={styles.apiLabel}>
        <h4>
          {strings.label}
          {<HelpArticleButton articleId={strings.articleId} />}
        </h4>
      </label>
      <div className={styles.apiBox}>
        <Input
          className={styles.apiInput}
          readOnly
          value={apiKey}
          id={strings.inputId}
        />
        <span
          className={styles.copySpan}
          onClick={() => {
            copyToClipBoard();
            message.info(strings.copied);
          }}
        >
          {strings.copy}
        </span>
      </div>
    </div>
  );
};

export default ProjectKey;
