import { getApplication } from './Application';
import { useParams } from 'react-router-dom';

export interface Priority {
  id: string;
  name: string;
}

type CustomLogo = {
  logo: string;
  isCustom: boolean;
  toggle: string;
};

export interface Organization {
  id: string;
  name: string;
  activePartnerCouponDescription?: string;
  validPartnerCoupon?: string;
  isActive: boolean;
  owner: {
    name: string;
    email: string;
    id: number | string;
    avatar: string;
  };
  priorities: Priority[];
  statusNames: string[];
  customLogo: CustomLogo;
  experiments?: string[];
  projectFolders: {
    id: string;
    name: string;
    subfolders: {
      id: string;
      name: string;
    }[];
  }[];
  projectFoldersAllowed: boolean;
}

export const getCurrentProjectId = (): number | null => {
  const application = getApplication();
  return application ? application.get('projectId') : null;
};

export const getCurrentOrganizationId = (): number => {
  const application = getApplication();
  return application ? application.get('organizationId') : null;
};

export const useCurrentOrganizationIdParam = (): string => {
  const { organizationId } = useParams<Record<string, string>>();

  return organizationId as string;
};

export const getCurrentUserId = (): number => {
  const application = getApplication();
  return application ? application.get('currentUser').id : null;
};

export const getCurrentUserEmail = (): string | null => {
  const application = getApplication();
  return application ? application.get('currentUser').email : null;
};
