export const en = {
  attachments: 'ATTACHMENTS:',
  tooLarge: (fileName: string): string =>
    `Sorry, the file (${fileName}) is too large. Maximum file size is 20mb.`,
  wentWrong: 'Something went wrong, please try again.',
  dragText: 'Drag & Drop over this area to upload',
  attachFile: 'Attach File'
};

export type Translations = typeof en;
