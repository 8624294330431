import React, { FC, useState, useRef, useEffect } from 'react';
import styles from './styles.module.css';
import { SmilePlus } from 'lucide-react';
import { Tooltip, Button, Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import useMediaQuery from '../../../../../../../../CreateTask/create_task/useMediaQuery';
import cx from 'classnames';
import { EmojiPicker } from '../../../../../../../../../../../javascript/components/EmojiPicker';
import { EmojiProvider } from '../../../../../../../../../../../javascript/components/EmojiPicker/EmojiProvider';
import { useInitEmojiData } from '../../../../../../../../../../../javascript/components/EmojiPicker/useInitEmojiData';
import { EmojiData } from '../../../../../../../../../../../javascript/components/EmojiPicker/types';

type Props = {
  getContainer: () => HTMLDivElement;
  onEmojiSelect: (emoji: { id: string; name: string; native: string }) => void;
  tooltipTitle: string;
  placement: TooltipPlacement;
  setPopoverOpen: (open: boolean) => void;
  popoverOpen: boolean;
  reactionPicker?: boolean;
  apiDomain: string;
  isAdminView: boolean;
  projectId: number;
};

export const PickerButton: FC<Props> = ({
  getContainer,
  onEmojiSelect,
  placement,
  tooltipTitle,
  setPopoverOpen,
  popoverOpen,
  reactionPicker,
  apiDomain,
  isAdminView,
  projectId
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [emojiData, setEmojiData] = useState<EmojiData | null>(null);
  const pickerButtonRef = useRef<HTMLButtonElement | null>(null);
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useInitEmojiData({ setEmojiData, emojiData, apiDomain });

  useEffect(() => {
    if (popoverOpen) setShowTooltip(false);
  }, [popoverOpen]);

  if (!emojiData) return null;

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      trigger={['click']}
      destroyTooltipOnHide
      placement={placement}
      overlayClassName={cx(styles.reactionPopover, {
        [styles.darkMode]: isDarkMode
      })}
      arrow={{ pointAtCenter: true }}
      content={
        <EmojiProvider
          emojiData={emojiData}
          getContainer={getContainer}
          darkMode={isDarkMode}
          onEmojiSelect={onEmojiSelect}
          isAdminView={isAdminView}
          projectId={projectId}
        >
          <EmojiPicker />
        </EmojiProvider>
      }
      getPopupContainer={getContainer}
    >
      <Tooltip
        title={tooltipTitle}
        placement="topRight"
        overlayClassName={styles.reactionTooltip}
        trigger={['hover']}
        open={showTooltip}
        onOpenChange={setShowTooltip}
        getPopupContainer={getContainer}
      >
        <Button
          type="text"
          ref={pickerButtonRef}
          icon={<SmilePlus className={styles.reactionIcon} />}
          className={cx(styles.reactionButton, {
            [styles.reactionPickerButton]: reactionPicker,
            [styles.descriptionPickerButton]: !reactionPicker
          })}
          size="small"
          onClick={() => setPopoverOpen(!popoverOpen)}
        />
      </Tooltip>
    </Popover>
  );
};
