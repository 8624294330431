export const en = {
    thereWasAnError: 'There was an error, please try again.',
    logInToYourOrganization: 'Log in to your Organization',
    organizationNameDoesntExist: 'Organization name doesn\'t exist',
    pleaseEnterYourOrganizationsDomain: 'Please enter your organization\'s domain',
    continue: 'Continue',
    dontKnowYourOrganizationsUrl: 'Don\'t know your organization\'s URL?',
    help: 'Help',
    beOnAnEnterprisePlan: 'To log in with SSO, you must be on an Enterprise plan.',
    findOutMore: 'Find out more',
    back: 'Back',
  };
  
  export type Translations = typeof en;
  