import React, { FC, useState, useRef, LegacyRef, useEffect } from 'react';
import styles from './styles.module.css';
import { User } from '../../../../../clients/sidebar/models/User';
import ToolbarButton from '../ToolbarButton';
import { Dropdown, Menu, Input } from 'antd';
import cx from 'classnames';
import {
  Check,
  Search,
  SearchX,
  UserIcon,
  Tag as TagIcon,
  X as Close
} from 'lucide-react';
import Avatar from '../../../../../clients/shared/Avatar';
import { AssignGuestUpgrade } from '../assign_guest_upgrade';

export type TagType = {
  id: number;
  name: string;
  active?: boolean;
};

export type Assignee = {
  name: string;
  id: number;
};

type Props = {
  values: { id: number; name: string }[];
  options: (User | TagType)[];
  onChange: (values: { id: number; name: string }[]) => void;
  getContainer: () => HTMLDivElement;
  isAssignees?: boolean;
  billingRights?: boolean;
  guestsAssignedToTasksAllowed?: boolean;
};

const NoResults = ({ isAssignees }) => (
  <div className={styles.noResults}>
    <SearchX className={styles.resultsIcon} />
    {`No matching ${
      isAssignees ? 'users' : 'tags'
    } found, try another search term?`}
  </div>
);

const BulkMultiple: FC<Props> = ({
  values,
  options,
  onChange,
  getContainer,
  isAssignees,
  billingRights,
  guestsAssignedToTasksAllowed
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  // @ts-expect-error
  const inputRef: LegacyRef<Input> | undefined = useRef(null);

  const isSelected = (_id: number): boolean =>
    !!values.find(({ id }) => id === _id);

  const handleOptionClick = (value: { id: number; name: string }) => {
    if (isSelected(value.id))
      return onChange([...values.filter(({ id }) => id !== value.id)]);

    onChange([...values, value]);
  };

  const handleRemove = (value: number) => {
    onChange(values.filter(({ id }) => id !== value));
  };

  const getOptions = (
    value: User | { id: number; name: string },
    index: number,
    list: (User | { id: number; name: string })[]
  ) => (
    <div
      className={cx(styles.option, {
        [styles.firstOption]: index === 0,
        [styles.secondOption]: index && index % 2 === 1,
        [styles.lastOption]: index === list.length - 1,
        [styles.withUpgrade]: !guestsAssignedToTasksAllowed && isAssignees
      })}
      key={`${value.id}-${value.name}`}
      onClick={() => handleOptionClick(value)}
    >
      {'avatar' in value && (
        <div className={styles.avatarContainer}>
          <Avatar member={value} size="small" />
        </div>
      )}
      <span title={value.name} className={styles.optionText}>
        {value.name}
      </span>
      {isSelected(value.id) && <Check className={styles.checkIcon} />}
    </div>
  );

  const filteredOptions: (
    | User
    | { id: number; name: string }
  )[] = options.filter(({ name }) =>
    name
      ?.toLocaleString()
      .toLocaleLowerCase()
      .includes(search?.toLocaleString().toLocaleLowerCase())
  );

  useEffect(() => {
    if (inputRef?.current) inputRef.current.focus();
  }, [values]);

  return (
    <Dropdown
      open={visible}
      className={styles.popover}
      placement="top"
      getPopupContainer={getContainer}
      onOpenChange={setVisible}
      trigger={['click']}
      overlayClassName={styles.overlay}
      arrow={{ pointAtCenter: true }}
      overlay={
        <div className={styles.overlayWrapper}>
          <div className={styles.contentContainer}>
            <Menu className={styles.tagsAndSearch}>
              {values.map(({ name, id }) => {
                return (
                  <Menu.Item
                    key={`${name}${id}`}
                    className={cx(styles.tag, {
                      [styles.assigneeTag]: isAssignees
                    })}
                    onClick={() => {
                      handleRemove(id);
                    }}
                  >
                    <div className={styles.tagContainer}>
                      <span className={styles.tagLabel}>
                        {isAssignees ? (
                          <UserIcon className={styles.userIcon} />
                        ) : (
                          <TagIcon className={styles.tagIcon} />
                        )}
                        {name}
                      </span>
                      <Close className={styles.closeIcon} />
                    </div>
                  </Menu.Item>
                );
              })}
              <Input
                ref={inputRef}
                className={cx(styles.searchInput, {
                  [styles.fullWidth]: !values.length,
                  [styles.assigneeInput]: isAssignees
                })}
                autoFocus
                prefix={
                  !values.length ? (
                    <Search className={styles.searchIcon} />
                  ) : null
                }
                placeholder={
                  !values.length
                    ? `Search for ${
                        isAssignees ? 'project users to assign' : 'tags apply'
                      }`
                    : ''
                }
                onChange={event => setSearch(event.target.value)}
                value={search}
              />
            </Menu>
            <div className={styles.optionsContainer}>
              <AssignGuestUpgrade
                isAssignees={isAssignees}
                billingRights={billingRights}
                guestsAssignedToTasksAllowed={guestsAssignedToTasksAllowed}
              />
              {filteredOptions?.length ? (
                filteredOptions.map(getOptions)
              ) : (
                <NoResults isAssignees={isAssignees} />
              )}
            </div>
          </div>
        </div>
      }
    >
      <ToolbarButton
        open={visible}
        onClick={() => setVisible(!visible)}
        active={!!values.length}
        label={isAssignees ? 'Assignees' : 'Tags'}
      />
    </Dropdown>
  );
};

export default BulkMultiple;
