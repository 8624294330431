import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import browserResources from 'jsComponents/onboarding/extension_config.json';
import styles from './index.module.css';
import cx from 'classnames';
import { handleRedirect } from './handleRedirect';

const InstallButton = props => {
  const {
    isSignUpOnBoarding,
    redirectCallback,
    browserConfig,
    allInstallCallback,
    emptyState,
    className
  } = props;

  const renderAllInstall = () => {
    if (isSignUpOnBoarding) {
      allInstallCallback();
    }

    return (
      <div className={styles.unknownBrowserList}>
        {browserResources.map(browser => (
          <div
            className={cx(styles.unknownBrowser, className)}
            key={`allinstall-${browser.name}`}
          >
            <img
              src={require(`../install_extension/browser_icons/${browser.name}.png`)}
              alt={browser.display}
              className={cx(
                { [styles.emptyunknownBrowserLogo]: emptyState },
                { [styles.unknownBrowserLogo]: !emptyState }
              )}
            />
            {emptyState && <h5>{browser.display}</h5>}
            <a
              href={browser.installLink}
              {...(browser.installLink[0] === '/' ? {} : { target: '_blank' })}
              onClick={() =>
                handleRedirect(browser.installLink, redirectCallback)
              }
            >
              <Button type="primary">
                {emptyState ? 'Install' : browser.cta}
              </Button>
            </a>
          </div>
        ))}
      </div>
    );
  };

  const getButtonCTA = () => {
    if (emptyState) {
      return browserConfig.emptyCta;
    } else if (isSignUpOnBoarding) {
      return browserConfig['cta_signup'];
    }
    return browserConfig.cta;
  };

  if (browserConfig) {
    return (
      <button
        className={cx(
          styles[`installButton${browserConfig.display}`],
          className,
          {
            [styles.emptyStateAnimation]: emptyState
          }
        )}
        onClick={() =>
          handleRedirect(browserConfig.installLink, redirectCallback)
        }
      >
        {getButtonCTA()}
      </button>
    );
  }

  return renderAllInstall();
};

InstallButton.propTypes = {
  isSignUpOnBoarding: PropTypes.bool,
  currentBrowser: PropTypes.string,
  redirectCallback: PropTypes.func,
  allInstallCallback: PropTypes.func,
  setBrowser: PropTypes.func,
  emptyState: PropTypes.bool,
  browserConfig: PropTypes.object,
  className: PropTypes.string
};

InstallButton.defaultProps = {
  isSignUpOnBoarding: false,
  redirectCallback: () => {},
  allInstallCallback: () => {},
  setBrowser: () => {},
  currentBrowser: null,
  emptyState: false,
  className: ''
};

export default InstallButton;
