import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

type Style = {
  color?: string;
  textDecoration?: string;
};

type Props = {
  articleId: string;
  children?: React.ReactNode;
  style?: Style;
};

const HelpArticleButton = ({ articleId, children, style }: Props) => {
  return (
    <span
      style={style || {}}
      data-elevio-article={articleId}
      data-elevio-style="nothing"
    >
      {children ? (
        children
      ) : (
        <InfoCircleOutlined style={{ color: '#3F71E0' }} />
      )}
    </span>
  );
};

export const HelpModuleButton = ({
  moduleId,
  children
}: {
  moduleId: string;
  children?: React.ReactNode;
}) => {
  return (
    <a data-elevio-module={moduleId} data-elevio-style="nothing">
      {children ? (
        children
      ) : (
        <InfoCircleOutlined style={{ color: '#3F71E0' }} />
      )}
    </a>
  );
};

export default HelpArticleButton;
