import React, { FC, useState } from 'react';
import { BrowserInfo, detect } from 'detect-browser';
import {
  browserConfig,
  BrowserConfigType
} from '../../../../../javascript/components/onboarding/extensionConfig';
import { Button } from 'antd';
import * as translations from './strings';
import { getLangKey } from '../../../../../javascript/models/Language';
import UpgradeIcon from '../../../assets/images/ic-upgrade-circle.svg';
import UpgradePopover from '../../UpgradePopover';
import screenshotAsset from './screenshot-upgrade-asset.png';
import styles from './styles.module.css';
import cx from 'classnames';

type Props = {
  hasBillingPermission: boolean;
  subscriptionUrl: string;
  owner: {
    email?: string;
    id: number;
  };
  container: HTMLElement;
  isAdminView?: boolean;
  isSmallView?: boolean;
  onShowOverlay?: (visible: boolean) => void;
};

const strings = translations[getLangKey()];

const browser = detect() as BrowserInfo;

const browserInfo: BrowserConfigType | undefined = browserConfig.find(
  config => config.name === browser?.name
);

const ScreenshotsUpgrade: FC<Props> = ({
  hasBillingPermission,
  subscriptionUrl,
  owner,
  container,
  isAdminView,
  isSmallView,
  onShowOverlay
}) => {
  const href = hasBillingPermission
    ? subscriptionUrl
    : `mailto:${owner?.email || 'support@bugherd.com'}`;

  const [showPopover, setShowPopover] = useState<boolean>(false);

  const isCreateTask: boolean = !!onShowOverlay;

  const handleVisibleChange = async (visible: boolean) => {
    if (isAdminView || !isCreateTask) {
      setShowPopover(visible);
    } else if (isCreateTask) {
      // @ts-ignore
      onShowOverlay(visible);
    }
  };

  const UpgradeMessage = (props: {}) => (
    <p
      className={cx(styles.popoverMessage, {
        [styles.noBillingPopoverMessage]: !hasBillingPermission
      })}
    >
      {strings.toEnable}
      {hasBillingPermission
        ? [strings.upgradePlan, strings.or, strings.askUsers]
        : strings.contactOwner}
      {[
        strings.or,
        strings.installTheBugherd,
        browserInfo ? (
          <Button
            key="install"
            type="link"
            className={styles.install}
            href={browserInfo.installLink}
          >
            {strings.browserExtension}
          </Button>
        ) : (
          strings.browserExtension
        )
      ]}
      .
    </p>
  );

  return (
    <UpgradePopover
      open={showPopover}
      onOpenChange={handleVisibleChange}
      message={<UpgradeMessage />}
      headerContent={strings.onlyAvailable}
      placement="left"
      getContainer={() =>
        (isAdminView
          ? document.getElementById('panelWrapper')
          : container) as HTMLElement
      }
      className={styles.screenshotsUpgradePopover}
      imageSrc={screenshotAsset}
      ownerEmail={owner?.email}
      href={href}
      isAdminView={isAdminView}
      billingRights={hasBillingPermission}
      isSmallView={isSmallView}
      trigger={isCreateTask ? ['click'] : ['hover']}
    >
      <div
        className={cx(styles.upgradeOuter, {
          [styles.isCreateTask]: isCreateTask
        })}
      >
        <UpgradeIcon className={styles.icon} />
        <p
          className={cx(styles.message, {
            [styles.noBillingMessage]: !hasBillingPermission
          })}
        >
          {strings.toCapture}
          <Button
            href={href}
            type="link"
            className={styles.upgrade}
            onClick={event => event.stopPropagation()}
            onMouseOver={event => event.stopPropagation()}
          >
            {hasBillingPermission ? strings.upgradePlan : strings.contactOwner}
          </Button>
          {strings.or}
          {browserInfo
            ? [
                strings.install,
                <Button
                  key="install"
                  type="link"
                  className={styles.install}
                  href={browserInfo.installLink}
                  onClick={event => event.stopPropagation()}
                  onMouseOver={event => event.stopPropagation()}
                >
                  {strings.browserExtension}
                </Button>
              ]
            : '.'}
        </p>
      </div>
    </UpgradePopover>
  );
};

export default ScreenshotsUpgrade;
