// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import Tools from '../tools';
import { fabric } from 'fabric';
import { Modal, Input } from 'antd';

import styles from './style.module.css';

export default class AnnotateImage extends React.Component {
  static propTypes = {
    blob: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    toolsFixed: PropTypes.bool,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number,
    container: PropTypes.instanceOf(Element)
  };

  static defaultProps = {
    toolsFixed: false
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.buildCanvas();
  }

  state = {
    canvas: null,
    tool: 'draw',
    container: { width: 0, height: 0 },
    color: '#FCB900',
    textActive: false,
    text: '',
    selectedObject: undefined,
    saved: false
  };

  getCanvasSize = async canvas =>
    new window.Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        let width = image.naturalWidth;
        let height = image.naturalHeight;
        let widthRatio = width / height;
        let heightRatio = height / width;
        let scale = 1;
        const windowWidth = this.props.containerWidth || window.innerWidth;
        const windowHeight = this.props.containerHeight || window.innerHeight;
        const heightOverflow = height - windowHeight;
        const widthOverflow = width - windowWidth;

        if (heightOverflow > 0 || widthOverflow > 0) {
          if (heightOverflow > widthOverflow) {
            scale = windowHeight / height;
            height = windowHeight;
            width = windowHeight * widthRatio;
          } else if (widthOverflow > heightOverflow) {
            scale = windowWidth / width;
            width = windowWidth;
            height = windowWidth * heightRatio;
          }
        }

        resolve({
          width,
          height,
          scale,
          imageWidth: image.naturalWidth,
          imageHeight: image.naturalHeight
        });
      };

      image.src = this.props.blob;
    });

  buildCanvas = async (blob = this.props.blob) => {
    if (this.canvas) {
      // if the user comes back to edit annotations
      this.canvas.renderAll();
      return;
    }

    const dimensions = await this.getCanvasSize();

    const canvas = this.imageRef.current;

    const fabricCanvas = new fabric.Canvas(canvas, {
      isDrawingMode: true,
      imageSmoothingEnabled: false
    });

    fabricCanvas.setDimensions({
      width: dimensions.width,
      height: dimensions.height
    });

    this.canvas = fabricCanvas;

    fabricCanvas.setBackgroundImage(
      blob,
      fabricCanvas.renderAll.bind(fabricCanvas),
      {
        scaleX: dimensions.scale,
        scaleY: dimensions.scale
      }
    );

    fabricCanvas.on('object:scaling', e => {
      var o = e.target;
      if (!o.strokeWidthUnscaled && o.strokeWidth) {
        o.strokeWidthUnscaled = o.strokeWidth;
      }
      if (o.strokeWidthUnscaled) {
        o.strokeWidth = o.strokeWidthUnscaled / o.scaleX;
      }
    });

    fabricCanvas.freeDrawingBrush.color = this.state.color;
    fabricCanvas.freeDrawingBrush.width = 5;

    fabricCanvas.on('selection:created', e => {
      this.setState({ selectedObject: e.selected });
    });

    fabricCanvas.on('selection:updated', e => {
      this.setState({ selectedObject: e.selected });
    });

    fabricCanvas.on('before:selection:cleared', e => {
      this.setState({ selectedObject: undefined });
    });
  };

  setContainerDimensions = () => {
    const rect = this.props.container.getBoundingClientRect();

    this.setState({
      container: {
        width: rect.width,
        height: rect.height
      }
    });
  };

  blobDimensions = () =>
    new window.Promise((resolve, reject) => {
      const image = new Image();

      image.onload = function() {
        resolve({
          width: image.width,
          height: image.height
        });
      };

      image.src = this.props.blob;
    });

  handleToolChange = tool => {
    switch (tool) {
      case 'mouse':
        this.handleMouse();
        break;
      case 'arrow':
        this.handleArrow();
        break;
      case 'rect':
        this.handleRect();
        break;
      case 'draw':
        this.handleDraw();
        break;
      case 'text':
        this.handleText();
        break;
    }
  };

  handleMouse = () => {
    this.canvas.isDrawingMode = false;
  };

  handleArrow = () => {
    const object = new fabric.Path(
      'M 0 0 L 150 0 M 0 0 L 10 -10 M 0 0 L 10 10 z',
      {
        originX: 'center',
        stroke: this.state.color,
        strokeWidth: 4
      }
    );
    this.canvas
      .add(object)
      .centerObject(object)
      .setActiveObject(object);
    this.handleMouse();
  };

  handleRect = () => {
    var object = new fabric.Rect({
      stroke: this.state.color,
      strokeWidth: 3,
      fill: '',
      width: 150,
      height: 100
    });
    this.canvas
      .add(object)
      .centerObject(object)
      .setActiveObject(object);
    this.handleMouse();
  };

  handleDraw = () => {
    this.canvas.isDrawingMode = true;
  };

  handleText = () => this.setState({ textActive: true });

  resetText = () => this.setState({ text: '', textActive: false });

  renderText = () => {
    const object = new fabric.Text(this.state.text, {
      fontFamily: 'Arial',
      fill: this.state.color
    });
    this.canvas
      .add(object)
      .centerObject(object)
      .setActiveObject(object);
    this.handleMouse();

    this.setState({
      text: ''
    });
  };

  handleOk = async () => {
    const dimensions = await this.getCanvasSize();
    const dataURL = this.canvas.toDataURL({
      format: 'png',
      multiplier: 1 / dimensions.scale
    });
    this.setState({ saved: true });
    this.props.onSave(dataURL);
  };

  handleCancel = () => {
    if (!this.state.saved) {
      this.canvas.remove(...this.canvas.getObjects());
    }
    this.props.onCancel();
  };

  handleDelete = () => {
    this.canvas.remove(this.canvas.getActiveObject());
  };

  handleColorChange = color => {
    this.setState({ color });
    this.canvas.freeDrawingBrush.color = color;

    const activeObject = this.canvas.getActiveObject();

    if (activeObject) {
      if (activeObject.stroke) {
        activeObject.set({ stroke: color });
      }
      if (activeObject.fill) {
        activeObject.set({ fill: color });
      }
      this.canvas.renderAll();
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.markerContainer}>
          <canvas className={styles.image} ref={this.imageRef} />
        </div>
        <Tools
          color={this.state.color}
          onSave={this.handleOk}
          onCancel={this.handleCancel}
          onDelete={this.handleDelete}
          canDelete={!!this.state.selectedObject}
          container={this.props.container}
          onToolChange={this.handleToolChange}
          onColorChange={this.handleColorChange}
          fixedToWindow={this.props.toolsFixed}
        />
        <Modal
          open={this.state.textActive}
          getContainer={this.props.container}
          destroyOnClose
          title="Text Annotation"
          okText="Add"
          maskClosable
          onOk={() => {
            this.renderText();
            this.resetText();
          }}
          okButtonProps={{ disabled: !this.state.text }}
          onCancel={() => {
            this.resetText();
          }}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              if (!this.state.text) return;
              this.renderText();
              this.resetText();
            }}
          >
            <Input
              autoFocus
              ref={inputRef => inputRef && inputRef.focus()}
              size="large"
              type="text"
              placeholder="Enter your text"
              onChange={e => this.setState({ text: e.target.value })}
            />
          </form>
        </Modal>
      </div>
    );
  }
}
