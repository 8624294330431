
export type optionTranslationsType =  { [key: string]: string };

export const en: optionTranslationsType = {
  'Designer': 'Designer',
  'Developer': 'Developer',
  'Content Creator': 'Content Creator',
  'Project/Product Manager': 'Project/Product Manager',
  'Director C-Level': 'Director C-Level',
  'Marketer': 'Marketer',
  'QA': 'QA',
  'Support': 'Support',
  'Job not listed': 'Job not listed',
  'an Agency': 'an Agency',
  'Marketing/Communications': 'Marketing/Communications',
  'Online learning': 'Online learning',
  'E-commerce': 'E-commerce',
  'Saas/Product': 'Saas/Product',
  'Industry not listed': 'Industry not listed',
  'Self-employed': 'Self-employed',
  '1-10': '1-10',
  '11-50': '11-50',
  '51-200': '51-200',
  '201-500': '201-500',
  '501-1000': '501-1000',
  '1001-5000': '1001-5000',
  '5001-10,000': '5001-10,000',
  '10,000+': '10,000+',
  'Nothing':'Nothing',
  'Emails': 'Emails',
  'Spreadsheets (Excel, Google sheets)': 'Spreadsheets (Excel, Google sheets)',
  'Annotated PDF’s': 'Annotated PDF’s',
  'Word documents (Word, Google Docs)': 'Word documents (Word, Google Docs)',
  'Screenshots': 'Screenshots',
  'Jira': 'Jira',
  'Asana': 'Asana',
  'Trello': 'Trello',
  'Other software product': 'Other software product',
  'My clients': 'My clients',
  'My users': 'My users' ,
  'My team': 'My team',
  'My agency': 'My agency',
  'Internal': 'Internal',
  'External': 'External',
};

