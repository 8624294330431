import React, { FC } from 'react';
import cx from 'classnames';
import BugHerdLogo from '../logo-BH-initials.svg';
import { Button } from 'antd';
import { ChevronRight } from 'lucide-react';
import styles from './styles.module.css';

type Props = {
  project: {
    id: number;
    organizationId: number;
    name: string;
  };
  fileName: string;
  viewKanbanProject: boolean;
  hasAccess: boolean;
  loggedIn: boolean;
};

const NavBar: FC<Props> = ({
  project,
  fileName,
  viewKanbanProject,
  hasAccess,
  loggedIn
}) => {
  const enabled = loggedIn && hasAccess;

  const projectLink = viewKanbanProject
    ? `/projects/${project.id}/assets`
    : `/organizations/${project.organizationId}/projects/${project.id}`;

  const logoLink =
    loggedIn && hasAccess
      ? `/organizations/${project.organizationId}/projects`
      : loggedIn
      ? '/organizations'
      : '/users/sign_in';

  return (
    <div className={styles.navBar}>
      <a href={logoLink}>
        <span className={styles.logoContainer}>
          <BugHerdLogo className={styles.bugherdLogo} />
        </span>
      </a>
      <Button
        title={project.name}
        type="link"
        className={cx(styles.projectLink, {
          [styles.disabledLink]: !enabled
        })}
        href={projectLink}
        disabled={!enabled}
      >
        {project.name}
      </Button>
      <ChevronRight className={styles.chevronIcon} />
      <span className={styles.assetName} title={fileName}>
        {fileName}
      </span>
    </div>
  );
};

export default NavBar;
