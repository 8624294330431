import React from 'react';

type ErrorProps = {
  error: any;
};

const openElevio = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  //@ts-expect-error
  // eslint-disable-next-line no-unused-expressions
  window._elev?.openHome();
};

const Error = ({ error }: ErrorProps) => {
  //eslint-disable-next-line
  if (window.bugsnagClient) {
    window.bugsnagClient.notify(error);
  } else {
    console.error(error);
  }

  return (
    //TODO: change this back after weve fixed the 403 errors
    <div style={{ paddingLeft: '12px' }}>
      <p>
        We're currently experiencing some technical difficulties.
        <br />
        If you see any <b>403 errors</b>
        , please try the following:
        <br />
      </p>
      <ol>
        <li>Uninstall the Chrome BugHerd extension</li>
        <li>Clear your cookies</li>
        <li>Restart your browser</li>
        <li>Log into BugHerd</li>
      </ol>

      <p>
        If you continue to experience issues, please{' '}
        {/* Do not worry 😎 and{' '} */}
        <a onClick={openElevio} href="">
          contact support
        </a>{' '}
        .
      </p>
      {error && (
        <pre style={{ padding: '12px', maxHeight: '75px', overflow: 'hidden' }}>
          {error.message}
          {error.stack}
        </pre>
      )}
    </div>
  );
};

export default Error;
