import React, { FC } from 'react';
import { Dropdown, Button } from 'antd';
import { Filter } from 'lucide-react';
import styles from './styles.module.css';
import useFilterAndSort from './useFilterAndSort';
import { FilterAndSortProps } from './types';
import { getItems } from './getItems';

const FilterAndSort: FC<FilterAndSortProps> = ({ container }) => {
  const {
    onClick,
    onOpenChange,
    open,
    feedbackView,
    feedbackFilter,
    sortAttribute,
    feedbackOrder
  } = useFilterAndSort();

  const showButton = () => {
    if (!feedbackView) return false;

    switch (feedbackView) {
      case 'home':
      case 'assets':
      case 'settings':
      case 'integrations':
      case 'tags':
        return false;
      default:
        return true;
    }
  };

  return showButton() ? (
    <Dropdown
      className={styles.dropdown}
      placement="bottomLeft"
      menu={{
        items: getItems({
          feedbackView,
          feedbackFilter,
          sortAttribute,
          feedbackOrder
        }),
        onClick
      }}
      open={open}
      getPopupContainer={() => container}
      trigger={['click']}
      onOpenChange={onOpenChange}
    >
      <Button
        type="text"
        style={{ borderColor: 'transparent' }}
        icon={<Filter className={styles.filterIcon} />}
        className={styles.button}
      />
    </Dropdown>
  ) : null;
};

export default FilterAndSort;
