import * as React from 'react';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { Info } from 'lucide-react';
import cx from 'classnames';

import styles from './index.module.css';

const HintComponent: React.FC<AlertProps> = props => {
  const { message, description, className, showIcon } = props;

  const _props = {
    className: className
      ? cx(styles.hintContainer, className)
      : styles.hintContainer,
    showIcon: showIcon !== undefined ? showIcon : true,
    icon: <Info className={styles.hintIcon} />,
    message: message && <div className={styles.hintMessage}>{message}</div>,
    description
  };

  return <Alert type="info" {..._props} />;
};

export default HintComponent;
