import { get, post, put } from '../utils/fetch';
import {
  apiBulkRequestPath,
  apiEventPath,
  apiTagPath
} from '../routing/routes';
import { newUndoBulkRequest } from '../models/BulkRequest';
import { newOnboardingCompletedEvent } from '../models/Event';

import { createUserSurvey as submitUserSurvey } from './UserSurveyApi';
import { createUserSurvey as submitOptionalUserSurvey } from './OptionalUserSurveyApi';
import { inviteUsers } from './ProjectApi';
import { getAccount } from './AccountApi';
import track from '../utils/analytics';
import { getCurrentProjectId, getCurrentUserId } from '../models/Organization';

const createBulkRequest = bulkRequest => {
  return post(apiBulkRequestPath, bulkRequest);
};

export const fetchBulkRequests = params => {
  const queryParams = new URLSearchParams(params);

  return get(`${apiBulkRequestPath}?${queryParams}`);
};

const fetchBulkRequest = bulkRequestId => {
  return get(`${apiBulkRequestPath}/${bulkRequestId}`);
};

export const undoBulkRequest = bulkRequest => {
  track('Undo bulk task action', {
    user_id: getCurrentUserId(),
    project_id: getCurrentProjectId()
  });
  const undoBulkRequest = newUndoBulkRequest(bulkRequest);

  return post(apiBulkRequestPath, undoBulkRequest);
};

const createEvent = event => {
  return post(apiEventPath, event);
};

export const completeOnboarding = () =>
  createEvent(newOnboardingCompletedEvent());

export const fetchTags = params => {
  const queryParams = new URLSearchParams(params);

  return get(`${apiTagPath}?${queryParams}`);
};

const updateTag = tag => put(`${apiTagPath}/${tag.id}`, tag);

export { inviteUsers };

const api = {
  createBulkRequest,
  fetchBulkRequest,
  fetchBulkRequests,
  undoBulkRequest,
  createEvent,
  completeOnboarding,
  fetchTags,
  updateTag,
  submitUserSurvey,
  submitOptionalUserSurvey,
  inviteUsers,
  getAccount
};

export default api;
