import React, { FC, useEffect } from 'react';
import ReactJson from 'react-json-view';
import styles from './styles.module.css';

type Props = {
  source: string;
  setSourceLoading: (loading: boolean) => void;
  sourceLoading: boolean;
  json: {
    [key: string]: any;
  }[];
  setJson: (
    json: {
      [key: string]: any;
    }[]
  ) => void;
};

export const JsonPreview: FC<Props> = ({
  source,
  setSourceLoading,
  sourceLoading,
  json,
  setJson
}) => {
  const jsonData = json.find(json => json[source]);

  useEffect(() => {
    if (!jsonData) {
      fetch(source)
        .then(response => response.json())
        .then(data => {
          const jsonClone = json.slice();
          jsonClone.push({ [source]: data });
          setJson(jsonClone);
          setSourceLoading(false);
        })
        .catch(error => {
          console.error('Error fetching JSON file:', error);
          setSourceLoading(false);
        });
    } else if (sourceLoading) {
      setSourceLoading(false);
    }
  }, [source, jsonData]);

  if (!jsonData) return null;

  return (
    <div className={styles.jsonContainer}>
      {/* @ts-expect-error */}
      <ReactJson
        src={jsonData[source]}
        theme="monokai"
        indentWidth={2}
        collapseStringsAfterLength={30}
        iconStyle="triangle"
      />
    </div>
  );
};
