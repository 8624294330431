export const en = {
  goToYourOrganizations: 'Go to your organizations',
  goToYourWebsite: 'Go to your website',
  goToYourProject: 'Go to your project',
  viewYourProjects: 'View your projects',
  youreReady: 'You’re ready to use BugHerd',
  quickVideo: 'To get you up to speed we’ve put together a quick video. Enjoy!'
};

export type Translations = typeof en;
