import {
  findLocalTask,
  updateLocalTask,
  findLocalProject,
  updateLocalProject
} from '../../api/apollo/client';

import { TaskData, ProjectData } from 'models/Pusher';

export const handleProjectUpdate = ({ project: { id, name } }: ProjectData) => {
  if (findLocalProject(id)) {
    updateLocalProject({ id, name });
  }
};

export const handleTaskUpdate = ({ task }: TaskData) => {
  if (findLocalTask(task.id)) {
    updateLocalTask({
      id: task.id,
      description: task.description
    });
  }
};
