import React from 'react';
import { Badge, Button } from 'antd';
import { ArrowRight } from 'lucide-react';

import styles from './index.module.css';
import track from '../../../../javascript/utils/analytics';

type Props = {
  projectLink: string;
};
export const ProjectSetupPendingBanner: React.FC<Props> = ({ projectLink }) => {
  const trackClick = () => {
    track('finish project setup clicked');
  };
  return (
    <div className={styles.projectSetupPendingBanner}>
      <span>
        You’re almost ready! Finish setting up your project to get the most out
        of BugHerd:&nbsp;
      </span>
      <Badge className={styles.pulseIndicator} dot offset={[-5, 3]}>
        <a href={projectLink} onClick={trackClick}>
          <Button type="primary">
            Go to project
            <ArrowRight size={20} />
          </Button>
        </a>
      </Badge>
    </div>
  );
};
