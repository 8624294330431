import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import ToolbarButton from '../ToolbarButton';
import { Popover, Calendar, Select } from 'antd';
import moment, { Moment } from 'dayjs';

const { Option } = Select;

type Props = {
  dueAt: string;
  onChange: (dueDate: string) => void;
  getContainer: () => HTMLDivElement;
};

const BulkDueDate: FC<Props> = ({ dueAt, getContainer, onChange }) => {
  const [date, setDate] = useState<Moment>(moment());
  const [visible, setVisible] = useState<boolean>(false);

  const onDateChange = (newDate: Moment) => {
    if (newDate.date() !== date.date()) {
      onChange(newDate.utc().toISOString());
      setVisible(false);
    }
    setDate(newDate);
  };

  const monthOptions = moment()
    .localeData()
    .monthsShort()
    .map((month, index) => (
      <Option className={styles.option} key={index}>
        {month}
      </Option>
    ));

  const yearOptions = Array.from(Array(10)).map((x, index) => {
    const thisYear = moment().year();
    const indexOfThisYearInOptions = 4;
    const year = thisYear - indexOfThisYearInOptions + index;
    return (
      <Option className={styles.option} key={year}>
        {year}
      </Option>
    );
  });

  return (
    <Popover
      title={null}
      open={visible}
      className={styles.popover}
      placement="top"
      getPopupContainer={getContainer}
      onOpenChange={setVisible}
      trigger={['click']}
      overlayClassName={styles.overlay}
      content={
        <Calendar
          mode="month"
          value={undefined}
          onSelect={onDateChange}
          fullscreen={false}
          disabledDate={current => current && current < moment().endOf('day')}
          className={styles.calendar}
          headerRender={({ value, onChange }) => (
            <div className={styles.dateHeader}>
              <Select
                size="small"
                className={styles.selectYear}
                value={value.year()}
                onChange={newYear => onChange(value.clone().year(newYear))}
                getPopupContainer={getContainer}
              >
                {yearOptions}
              </Select>
              <Select
                size="small"
                className={styles.selectMonth}
                value={String(value.month())}
                onChange={newMonth => onChange(value.clone().month(newMonth))}
                getPopupContainer={getContainer}
              >
                {monthOptions}
              </Select>
            </div>
          )}
        />
      }
    >
      <ToolbarButton
        open={visible}
        onClick={() => setVisible(!visible)}
        active={!!dueAt}
        label={dueAt ? moment(dueAt).format('D MMM YYYY') : 'Due Date'}
      />
    </Popover>
  );
};

export default BulkDueDate;
