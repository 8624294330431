export const en = {
  openSettings: 'Open Project Settings',
  couldntAccess:
    "We couldn't access your site because it requires a username & password.",
  enableBasicAuth:
    'Please enable Basic Authentication in the Project Settings. For More information on how to do this,',
  helpArticle: 'see this help article',
  followingError:
    'We received the following error from your website when trying to capture a screenshot.',
  ok: 'OK',
  contactSupport: 'Contact Support',
  errorOccurred: 'An error occurred when we tried to capture the screen',
  readMore: 'Click here to read more.',
  forHelp: 'For help, please contact',
  andMention: (taskId: number) =>
    `and mention the ID (${taskId}) of this task.`,
  publiclyAvailable:
    'Please make sure your website is publicly available and takes less than 10 seconds to load.',
  noResponse: "We didn't get a response from your website with 10 seconds.",
  unavailable:
    'Our Screenshot service was unavailable at the time this Task was created.',
  screenshotService:
    'You can check the status of our screenshot service by visiting',
  checkStatus: 'Check Status',
  differentPlan:
    "When this task was created, you were on a different plan that didn't have access to capture screenshots without the extension installed.",
  goodNews:
    "However, we have good news! You're now on a plan which supports the feature, so any newly created tasks should have a screenshot.",
  toCaptureScreenshots: (
    canAccessBilling: boolean
  ) => `To capture screenshots without a browser extension installed, you need
  to ${canAccessBilling ? '' : 'ask the account owner to '}`,
  upgradeToOurPremium: 'upgrade to our Premium Plan',
  usersInstall: "If you don't want to upgrade, ask your users to install the ",
  alternatively: 'Alternatively, you can install the ',
  bugHerdExtension: 'BugHerd browser extension',
  upgradeToPremium: 'Upgrade to Premium',
  protected: 'Your site is protected by Basic Authentication',
  serviceUnavailable: 'Screenshot service unavailable',
  wentWrong: 'Something went wrong',
  noResponseFromSite: 'No response from your site',
  wrongPlan: 'On the Wrong Plan',
  upgradePlan: 'Upgrade your Plan',
  returnedError: 'Your site returned an error'
};

export type Type = typeof en;
