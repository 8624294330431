import * as React from 'react';
import styles from './index.module.css';
import { Button } from 'antd';
import { X as Remove } from 'lucide-react';
import * as translations from '../strings';
import { getLangKey } from 'models/Language';

const strings = translations[getLangKey()];

type Props = {
  onClick: () => void;
};

const RemoveUrl = ({ onClick }: Props) => {
  return (
    <Button
      className={styles.removeButton}
      title={strings.removeUrl}
      icon={<Remove className={styles.removeUrl} />}
      onClick={onClick}
    />
  );
};

export default RemoveUrl;
