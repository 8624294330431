import React from 'react';
import { NavLink } from 'react-router-dom';
import withRouter from '../../../utils/withRouter';
import styles from '../index.module.css';
export default withRouter(props => (
  <ul className={styles.submenu}>
    <li>
      <NavLink
        to="/profile"
        className={styles.organizationLink}
        style={({ isActive }) =>
          isActive ? { fontWeight: 'bold' } : undefined
        }
      >
        Profile
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/notifications"
        className={styles.organizationLink}
        style={({ isActive }) =>
          isActive ? { fontWeight: 'bold' } : undefined
        }
      >
        Notifications
      </NavLink>
    </li>
  </ul>
));
