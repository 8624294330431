import React, { FC, useRef, useEffect } from 'react';
import { Emoji } from './types';
import styles from './styles.module.css';
import cx from 'classnames';
import { useEmojiState } from './EmojiProvider';

type Props = {
  name: string;
  label?: string;
  emojis: string[] | Emoji[];
  hide?: boolean;
};

export const EmojiCategory: FC<Props> = ({
  name,
  label = name,
  emojis,
  hide
}: Props) => {
  const {
    storeRecentEmoji,
    selectedCategory,
    getEmoji,
    setHoveredEmoji,
    skinTone,
    onEmojiSelect
  } = useEmojiState();
  const categoryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (categoryRef.current && selectedCategory === name) {
      categoryRef.current.scrollIntoView({
        block: 'start',
        inline: 'nearest'
      });
    }
  }, [selectedCategory]);

  if (!emojis.length || hide) return null;

  return (
    <div ref={categoryRef} className={styles.emojiCategory}>
      <div className={styles.categoryLabel}>{label}</div>
      <div className={styles.wrappedEmojis}>
        {emojis.map((emojiItem: string | Emoji, index: number) => {
          let key, emoji;
          if (typeof emojiItem === 'string' && getEmoji) {
            emoji = getEmoji(emojiItem);
            key = emojiItem;
          } else {
            emoji = emojiItem;
            // @ts-expect-error
            key = emojiItem?.id;
          }
          const hasSkinTones = emoji && emoji.skins.length > 1;
          const skinToneIndex = hasSkinTones ? parseInt(skinTone, 10) - 1 : 0;
          const { native } = emoji.skins[skinToneIndex];

          return emoji ? (
            <span
              key={key}
              role="img"
              className={cx(
                styles.emojiItem,
                styles[`emojiItem-${index < 6 ? index : index % 6}`]
              )}
              onMouseEnter={() => setHoveredEmoji(emoji)}
              onMouseLeave={() => setHoveredEmoji(undefined)}
              onClick={() => {
                onEmojiSelect({
                  id: emoji.id,
                  name: emoji.name,
                  native,
                  skin: skinTone
                });
                storeRecentEmoji(emoji);
              }}
            >
              {native}
            </span>
          ) : null;
        })}
      </div>
    </div>
  );
};
