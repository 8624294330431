import React from 'react';
import PropTypes from 'prop-types';

import uploadToS3 from 'jsUtilities/uploadToS3';
import { getFileInfo } from 'utils/fileListOperations';
import { Dragger } from 'jsUtilities/uploadDragListeners';
import { Camera, Upload as UploadIcon, Trash2 as Trash } from 'lucide-react';
import FileUploading from '../../../../components/fileUploading';
import { message, Upload, Button } from 'antd';

import styles from './index.module.css';

export default class AvatarUpload extends React.Component {
  static propTypes = {
    hasAvatar: PropTypes.bool,
    endpoint: PropTypes.string.isRequired,
    onUploadComplete: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    render: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.dragRef = React.createRef();
    this.Abort = () => {};
  }

  state = {
    file: { percent: 100 },
    isUploading: false
  };

  handleOnChange = file => {
    if (!this.validateSize(file)) {
      message.error(
        `Sorry, the file (${file.name}) is too large. Maximum file size is 1MB`
      );
      return;
    }
    if (!this.isValidateType(file.name.toLowerCase())) {
      message.error(
        `Sorry, the file (${file.name}) is not a png, jpg or jpeg.`
      );
      return;
    }
    const _file = getFileInfo(file);
    this.setState({ file: _file });
    const uploadParameters = {
      file: _file,
      remove: this.onError,
      endpoint: this.props.endpoint,
      additionalQueryParams: { avatar: true, filename: _file.name },
      feedback: message.error,
      onStart: this.onStart,
      onProgress: this.onProgress,
      onError: this.onError,
      onComplete: this.onComplete,
      isContentType: false
    };
    uploadToS3(uploadParameters);
  };

  updateFile = (file, data) => {
    Object.assign(file, data);

    this.setState({
      file: file
    });
  };

  onProgress = ({ loaded, total }, file) => {
    let percent = Math.round((loaded / total) * 100);
    this.updateFile(file, {
      percent,
      status: percent !== 100 ? 'uploading' : 'done'
    });
  };

  onError = (event, file) => {
    message.error('Something went wrong, please try again.');
    this.setUploadingStatus(false);
    this.updateFile(this.state.file, {
      percent: 100,
      status: 'error'
    });
  };

  setUploadingStatus = isUploading => {
    this.setState({
      isUploading: isUploading
    });
  };

  onStart = uploadRequest => {
    this.setUploadingStatus(true);
    this.Abort = () => {
      uploadRequest.abort();
      this.setUploadingStatus(false);
      this.updateFile(this.state.file, {
        percent: 100,
        status: 'error'
      });
    };
  };

  onComplete = (_file, S3URL, key, callbackUrl) => {
    this.setUploadingStatus(false);
    this.props.onUploadComplete(S3URL, key, callbackUrl);
  };

  validateSize = file => file.size < 1048576;

  isValidateType = name =>
    name.endsWith('.png') ||
    name.endsWith('.jpeg') ||
    name.endsWith('.jpg') ||
    name.endsWith('.svg');

  render() {
    const { file } = this.state;

    return (
      <Dragger
        container={this.dragRef}
        prevent={window}
        handleOnChange={this.handleOnChange}
        isDragRef
      >
        {isDragging => (
          <div ref={this.dragRef}>
            {this.props.render(isDragging)}
            {isDragging && (
              <div className={styles.overlayContent}>
                <div className={styles.iconBoxLarge}>
                  <UploadIcon className={styles.uploadIcon} />
                </div>
                <div className={styles.dragAndDrop}>
                  <p className={styles.instruction}>Drag & drop to upload</p>
                  <p className={styles.validFiles}>
                    Valid file types include: .svg .png .jpg
                  </p>
                </div>
              </div>
            )}
            <div className={styles.buttonContainer}>
              {!this.state.isUploading && (
                <Upload
                  name="file"
                  onChange={event =>
                    this.handleOnChange(event.file.originFileObj)
                  }
                  showUploadList={false}
                  customRequest={() => {}}
                >
                  <Button className={styles.uploadFileButton} type="link">
                    <Camera className={styles.attachIcon} />
                    Upload
                  </Button>
                </Upload>
              )}
              {!this.state.isUploading && this.props.hasAvatar && (
                <div className={styles.buttonContainer}>
                  <span className={styles.attachFileButton}> | </span>
                  <Button
                    type="link"
                    className={styles.attachFileButton}
                    onClick={() => this.props.onRemove()}
                  >
                    <Trash className={styles.attachIcon} />
                    Remove
                  </Button>
                </div>
              )}
            </div>
            {this.state.isUploading && <div>Uploading...</div>}
            {this.state.isUploading && (
              <div className={styles.fileUpload}>
                <FileUploading file={file} onRemoveFile={this.Abort} />
              </div>
            )}
          </div>
        )}
      </Dragger>
    );
  }
}
