import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import styles from './index.module.css';

const Preview = ({
  embeddedSidebarUrl,
  projectUrl
}: {
  embeddedSidebarUrl: string;
  projectUrl: string;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidSite, setIsValidSite] = useState<boolean | null>(null);

  const navigate = useNavigate();

  const checkValidSite = async () => {
    const compatibleEndpoint = `${embeddedSidebarUrl}/compatibility?url=${projectUrl}`;
    try {
      const response = await fetch(compatibleEndpoint);
      const { compatible } = await response.json();
      setIsValidSite(!!compatible);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigate = event => {
    if (event.data.action === 'navigate') window.location.href = event.data.url;
  };

  useEffect(() => {
    checkValidSite();

    window.addEventListener('message', handleNavigate);

    return () => {
      window.removeEventListener('message', handleNavigate);
    };
  }, []);

  useEffect(() => {
    if (isValidSite === false) navigate('/website-error');
  }, [isValidSite]);

  return isLoading ? (
    <div className={styles.container}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.iframeWrapper}>
      <iframe
        src={`${embeddedSidebarUrl}`}
        title="Preview"
        className={styles.iframeContainer}
        allow="display-capture"
      />
    </div>
  );
};

export default Preview;
