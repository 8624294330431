interface CustomEvent extends Event {
  type: string;
  detail: string;
}

export const listen = (
  eventName: string,
  callback: Function
): { callback: Function; remove: () => void } => {
  const cb = (event: CustomEvent) => {
    try {
      const data = JSON.parse(event.detail);

      callback(data);

      // @ts-ignore
      window.removeEventListener(eventName, cb);
    } catch (err) {
      console.warn(err);
    }
  };

  // @ts-ignore
  window.addEventListener(eventName, cb);

  return {
    callback: cb,
    remove: () => {
      // @ts-ignore
      window.removeEventListener(eventName, cb);
    }
  };
};

type EmitData<T extends {}> = {
  eventName: string;
  responseEventName?: string;
  eventData?: T;
};
export const emit = (
  { eventName, responseEventName, eventData = {} }: EmitData<any>,
  onResponse?: () => void
) => {
  if (responseEventName && onResponse) {
    listen(responseEventName, onResponse);
  }

  const event = new CustomEvent(eventName, {
    bubbles: true,
    cancelable: true,
    detail: JSON.stringify(eventData)
  });
  window.document.dispatchEvent(event);
};

export default {
  listen,
  emit
};
