export const en = {
  wentWrong: 'Something went wrong, please try again.',
  label: 'SCREENSHOT',
  crop: 'crop',
  focus: 'focus',
  fullScreen: 'full screen',
  full: ' full',
  addingAnnotations: 'Adding annotations',
  generating: 'Generating...',
  notCaptured: 'Screen not captured ',
  why: 'why?',
  retry: 'Retry'
};

export type Type = typeof en;
