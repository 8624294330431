import * as React from 'react';
import { Role } from '../../sidebar/models/User';
import * as Types from '../../sidebar/views/CreateTask/create_task/types';

import AvatarWrapper from './AvatarWrapper';
import { MemberRole } from 'jsModels/MemberRole';

export type Member = {
  avatar?: string;
  name: string;
  role?: MemberRole | Role;
  email?: string;
  id?: number;
  large_avatar_url?: string;
  new_avatar_url?: string;
  surname?: string;
  first_name?: string;
  firstName?: string;
};

interface AvatarProps {
  member?: Member | Types.User;
  size: 'default' | 'large' | 'small';
  noPhoto?: () => React.Component | null;
  className?: string;
}

function Avatar(props: AvatarProps) {
  const { member, noPhoto, size, className } = props;
  let name;
  let avatar;
  let role;
  let largeAvatar;
  let newAvatar;
  let surname;
  let email;
  let firstName;

  if (member) {
    name = member.name;
    avatar = member.avatar;
    role = member.role;
    largeAvatar = member.large_avatar_url;
    newAvatar = member.new_avatar_url;
    firstName = member.first_name || member.firstName || member.name;
    surname = member.surname;
    email = member.email;
  }

  const firstNameFirstLetter = (firstName || name)?.[0]?.toUpperCase();
  const surnameFirstLetter = surname?.[0]?.toUpperCase();
  const emailFirstLetter = email?.[0]?.toUpperCase();

  const isGeneric = (avatarUrl: string): boolean =>
    avatarUrl.includes('avatar-generic.svg');

  const color = (role: string): string => {
    if (role === 'guest') {
      return 'blue';
    } else if (role === 'collaborator') {
      return 'green';
    }

    return 'pink';
  };

  const avatars: (string | undefined)[] =
    size === 'large'
      ? [largeAvatar, avatar, newAvatar]
      : [avatar, largeAvatar, newAvatar];

  const avatarUrl = (): string | undefined =>
    avatars.filter(
      (url: string | undefined): boolean => !!url && !isGeneric(url)
    )[0];

  return (
    <div>
      {avatarUrl() ? (
        <AvatarWrapper size={size} src={avatarUrl()} className={className}>
          {noPhoto && noPhoto()}
        </AvatarWrapper>
      ) : (
        <AvatarWrapper
          color={role ? color(role) : 'default'}
          size={size}
          className={className}
        >
          {firstNameFirstLetter || emailFirstLetter}
          {firstName && surnameFirstLetter}
        </AvatarWrapper>
      )}
    </div>
  );
}

export default Avatar;
