import { Task } from 'jsModels/Task';
import { KanbanColumn } from './drag_and_drop/column';
export interface SceneColumn {
  id: number;
  status_id: string | null;
  children: Task[];
  unfilteredTaskCount: number;
  name: string;
  color: string;
  type: string;
  props: {
    orientation: string;
  };
}
export interface Scene {
  children: SceneColumn[];
  type: string;
  props: {
    orientation: string;
  };
}

export const buildSceneState = (columns: KanbanColumn[]): Scene => {
  return {
    type: 'container',
    props: {
      orientation: 'horizontal'
    },
    children: columns.map(column => ({
      id: column.columnId,
      status_id: column.statusId,
      type: 'container',
      name: column.title,
      color: column.color,
      props: {
        orientation: 'vertical'
      },
      unfilteredTaskCount: column.tasks.length,
      children: column.tasks
        .filter(task => task.visible)
        .map(task => {
          return {
            type: 'draggable',
            id: `${task.id}`,
            props: {
              data: task
            },
            data: task
          };
        })
    }))
  };
};
