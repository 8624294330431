export const en = {
  statementsHeader: 'Account Invoices',
  statementsEmptyState:
    "You don't have any account invoices yet. Your statements will appear here when you subscribe to a paid plan.",
  statementId: 'Invoice No.',
  statementDate: 'Date',
  statementStatus: 'Status',
  statementTotal: 'Total',
  dateFormat: 'd MMM yyyy',
};

export type Translations = typeof en;
