export const updateExtensionProjectsList = (onComplete: () => void) => {
  const customEvent = new CustomEvent('updateProjectList');
  document.dispatchEvent(customEvent);

  document.addEventListener('bugherdExtensionListenersInitialised', () => {
    document.dispatchEvent(customEvent);
  });

  document.addEventListener('bugherdProjectsListUpdated', onComplete);
};
