import React from 'react';
import { userType, locationType } from '../../../utils/propTypes';
import Form from './form';
import sharedStyles from '../../../components/onboarding/shared.module.css';
import styles from './style.module.css';
import queryString from 'qs';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

export default class Update extends React.Component {
  static propTypes = {
    user: userType,
    location: locationType
  };

  render() {
    const query = queryString.parse(this.props.location.search);

    return (
      <div className={sharedStyles.container}>
        <div className={styles.header}>
          <h1>{strings.updateYourDetails}</h1>
          <p>
            {strings.weMadeChanges}
          </p>
          <p>{strings.couldYouTakeAMoment}</p>
        </div>
        <Form {...this.props} validationToken={query.validation_token} />
        <div className={sharedStyles.footer}>
          <p>
            {strings.waitWhats}
            <a href="https://bugherd.com" target="_blank">
              {strings.bugherd}
            </a>
            ?
          </p>
        </div>
      </div>
    );
  }
}
