import React, { FC } from 'react';
import { Select } from 'antd';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../javascript/models/Language';
import cx from 'classnames';
import * as Types from '../../types';
import styles from '../index.module.css';

const { Option } = Select;
const strings = translations[getLangKey()];

export type Priority = {
  id: Types.Severity;
  name: 'not set' | 'minor' | 'normal' | 'important' | 'critical';
  color: '#cccccc' | '#d46060' | '#e8b019' | '#60c5d4' | '#8bb863';
};

export const priorities: Priority[] = [
  { id: 0, name: 'not set', color: '#cccccc' },
  { id: 1, name: 'critical', color: '#d46060' },
  { id: 2, name: 'important', color: '#e8b019' },
  { id: 3, name: 'normal', color: '#60c5d4' },
  { id: 4, name: 'minor', color: '#8bb863' }
];

type Props = {
  container: Element;
  onSeverityChange: (priorityId: Types.Severity) => void;
  severity: Types.Severity;
  inputSize: 'large' | 'middle';
};

const Severity: FC<Props> = props => {
  const { container, onSeverityChange, severity, inputSize } = props;

  const getPriorityOptions = ({ id, name }: Priority) => (
    <Option
      className={cx(styles.priorityOption, [styles[`priority-${id}`]])}
      value={id}
      label={name}
      key={id + name}
    >
      {name}
    </Option>
  );

  const handleSeverityChange = (priorityId: Types.Severity) => {
    onSeverityChange(priorityId);
  };

  return (
    <Select
      placeholder={
        <span className={styles.placeHolder}>{strings.severity}</span>
      }
      className={cx(
        styles.select,
        styles.selectSeverity,
        styles.selectStatus,
        styles[`selectPriority-${severity}`],
        { [styles.hasValue]: severity !== undefined }
      )}
      style={{color: "red"}}
      popupClassName={styles.dropdownSeverity}
      getPopupContainer={() => container as HTMLElement}
      onChange={handleSeverityChange}
      value={severity}
      size={inputSize}
    >
      {priorities.map(getPriorityOptions)}
    </Select>
  );
};

export default Severity;
