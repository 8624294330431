import React, {
  useEffect,
  useState,
  Children,
  cloneElement,
  isValidElement
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import Welcome from './welcome';
import InstallExtension from 'jsComponents/onboarding/install_extension';
import InstallError from './error';
import Installing from 'jsComponents/onboarding/installing';
import Login from './login';
import Success from './success';
import ProxySuccess from './proxySuccess';
import Update from './update';
import styles from './index.module.css';
import { checkForExtension } from 'jsUtilities/checkForBugherdExtension';

const Wrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { location: location, navigate: navigate });
    }
    return child;
  });

  return childrenWithProps;
};

export default props => {
  const [extensionInstalled, setExtensionInstalled] = useState(undefined);

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      setExtensionInstalled(exists);
    })();
  }, []);

  if (extensionInstalled === undefined) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Router>
        <Routes>
          <Route
            path="/invitation/:share_token"
            element={
              <Wrapper>
                <Welcome extensionInstalled={extensionInstalled} {...props} />
              </Wrapper>
            }
          />
        </Routes>
      </Router>

      <Router basename="/accept-invite">
        <Routes>
          <Route
            path="/welcome"
            element={
              <Wrapper>
                <Welcome extensionInstalled={extensionInstalled} {...props} />
              </Wrapper>
            }
          />
          <Route
            path="/extension"
            element={
              <Wrapper>
                <InstallExtension
                  extensionInstalled={extensionInstalled}
                  {...props}
                />
              </Wrapper>
            }
          />
          <Route
            path="/extension/error"
            element={
              <Wrapper>
                <InstallError
                  extensionInstalled={extensionInstalled}
                  {...props}
                />
              </Wrapper>
            }
          />
          <Route
            path="/extension/installing"
            element={
              <Wrapper>
                <Installing
                  extensionInstalled={extensionInstalled}
                  {...props}
                />
              </Wrapper>
            }
          />
          <Route
            path="/extension/success"
            element={
              <Wrapper>
                <Success extensionInstalled={extensionInstalled} {...props} />
              </Wrapper>
            }
          />
          <Route
            path="/proxy/success"
            element={
              <Wrapper>
                <ProxySuccess {...props} />
              </Wrapper>
            }
          />
          <Route
            path="/login"
            element={
              <Wrapper>
                <Login extensionInstalled={extensionInstalled} {...props} />
              </Wrapper>
            }
          />
          <Route
            path="/update"
            element={
              <Wrapper>
                <Update extensionInstalled={extensionInstalled} {...props} />
              </Wrapper>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};
