import React from 'react';
import styles from './harvestSettings.module.css';
import { Button } from 'antd';
import { HarvestIntegration } from 'appJS/components/Integrations/types';
import harvestDemoImage from './images/img-integration-harvest.jpg';

interface Props {
  harvestIntegration: HarvestIntegration;
  handleHarvestClick: Function;
}

const HarvestSettings: React.FC<Props> = ({
  harvestIntegration,
  handleHarvestClick
}: Props) => {
  const harvestEnabled = !!harvestIntegration?.id;
  return (
    <div className={styles.harvestModalContainer}>
      <span>
        Add time tracking to your Bugherd tasks through Harvest. This will
        enable Harvest time tracking for all projects in your organization.
      </span>
      <img src={harvestDemoImage} alt="Harvest Demo Image" />
      {harvestEnabled && (
        <div className={styles.disconnectContainer}>
          <span>Disconnect integration</span>
          <Button
            className={styles.disconnectButton}
            onClick={() => handleHarvestClick()}
            type="text"
          >
            Disconnect
          </Button>
        </div>
      )}
    </div>
  );
};

export default (props: Props) => <HarvestSettings {...props} />;
