export const en = {
  accountIsInactive: 'Account is inactive',
  accountContainsNoProjects: 'This account contains no projects.',
  redirectToCreateNewProject: 'Redirect to create new project...',
  refreshPageMessage: 'Some of your projects have been updated.',
  searchForProjectByName: 'Search for Project by name...',
  addProject: '+ Add Project',
  refreshPageErrorMessage:
    'Errors occurred when retrieving data. Please refresh the page.',
  myProjects: 'My Projects'
};

export type Translations = typeof en;
