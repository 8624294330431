import React from 'react';
import PropTypes from 'prop-types';
import FileUploading from '../fileUploading';

import styles from './index.module.css';

export default class UploadList extends React.Component {
  static propTypes = {
    fileList: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        url: PropTypes.string
      })
    ),
    onRemoveFile: PropTypes.func,
    taskDetails: PropTypes.bool
  };

  static defaultProps = {
    onRemoveFile: null,
    taskDetails: false
  };

  render() {
    if (!this.props.fileList.length) {
      return null;
    }

    return (
      <ul className={styles.uploadList}>
        {this.props.fileList.map(file => {
          return (
            <li key={file.uid} className={styles.listItem}>
              <FileUploading
                file={file}
                onRemoveFile={() => this.props.onRemoveFile(file)}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}
