import React from 'react';
import styles from './index.module.css';
import PropTypes from 'prop-types';

const Video = props => {
  const type = props.url.endsWith('.webm') ? 'webm' : 'mp4';
  const videoProps = {
    controls: true,
    style: { width: '100%' }
  };
  if (props.videoRef) videoProps.ref = props.videoRef;
  return (
    <div className={styles.videoOuter}>
      <video {...videoProps}>
        <source src={props.url} type={`video/${type}`} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  videoRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default Video;
