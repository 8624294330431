import * as translations from './strings';
import { getLangKey } from 'models/Language';
import { message } from 'antd';

const strings = translations[getLangKey()];

export const showUpdateMemberMessage = ({
  previousAdditionalMemberCount,
  currentAdditionalMemberCount
}: {
  previousAdditionalMemberCount: number;
  currentAdditionalMemberCount: number;
}) => {
  if (previousAdditionalMemberCount < currentAdditionalMemberCount) {
    message.info(
      strings.updatedAdditionalMembers({
        membersAdded:
          currentAdditionalMemberCount - previousAdditionalMemberCount,
        currentMembers: currentAdditionalMemberCount
      })
    );
  } else if (previousAdditionalMemberCount > currentAdditionalMemberCount) {
    message.info(
      strings.memberRemoveMessage({
        membersRemoved:
          previousAdditionalMemberCount - currentAdditionalMemberCount,
        currentMembers: currentAdditionalMemberCount
      })
    );
  }
};
