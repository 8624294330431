import { useState, useEffect } from 'react';

const useMediaQuery = (query: string, useParentWindow?: boolean) => {
  const _window = useParentWindow ? window.parent : window;
  const media = _window.matchMedia(query);
  const [matches, setMatches] = useState(media.matches);

  const listener = (event: MediaQueryListEvent) => setMatches(event.matches);
  useEffect(() => {
    media.addListener(listener);
    () => media.removeListener(listener);
  }, [query, matches]);

  return matches;
};

export default useMediaQuery;
