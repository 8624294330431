import React, { useState, useRef } from 'react';
import { Spin } from 'antd';
import styles from './../index.module.css';
import TaskCount from '../TaskCount';
import WebsiteMenu from './WebsiteMenu';
import { formatDistanceStrict, parseISO } from 'date-fns';
import EmptyProjectSite from './../assets/empty_project_site.png';
import { useDesignAssetsState } from '../../providers/DesignAssets';
import AddWebsiteModal from './AddWebsiteModal';
import * as translations from '../strings';
import { getLangKey } from 'appJS/models/Application';
import ProxyCompatibilityChecker from './../ProxyCompatibilityChecker';
import ShareButton from 'appJS/components/ShareButton';

const strings = translations[getLangKey()];

type Props = {
  site: any;
  index: number;
  project: any;
  apiKey: string;
  proxyHost: string;
  container: any;
  isNoLoginGuestExperienceEnabled: boolean;
  showProxyPreview: boolean;
  loadingCoverUrl: boolean;
  getCoverImage: any;
  onSharingClick: () => void;
};

const ProjectSite = ({
  site,
  index,
  project,
  apiKey,
  proxyHost,
  container,
  isNoLoginGuestExperienceEnabled,
  showProxyPreview,
  loadingCoverUrl,
  getCoverImage,
  onSharingClick
}: Props) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { isGuest } = useDesignAssetsState();

  const handleOpenModal = () => setOpenEditModal(prev => !prev);
  const handleCloseWebsiteModal = () => setOpenEditModal(false);

  const proxyUrl = `https://${project.apiKey}_${site.id}.${proxyHost}`;

  const showButtonOrText = () =>
    loadingCoverUrl ? (
      <Spin />
    ) : (
      <img
        width={130}
        height={100}
        src={EmptyProjectSite}
        alt="Your project site"
      />
    );

  const cleanUrl = url => url.replace(/^(https?:\/\/)?(.+?)\/?$/i, '$2');

  const { id, url, cover_url: coverUrl, updated_at: updatedAt } = site;

  const childRef = useRef<{ handleClick: () => void }>(null);

  const handleCardClick = () => {
    if (openEditModal) return;
    if (childRef.current) {
      childRef.current.handleTriggerClick();
    }
  };

  const onShareButtonClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onSharingClick();
  };

  return (
    <div
      style={{ '--index': index }}
      key={id}
      data-index={index}
      className={`${styles.websiteCard} ${styles.staggerInAnimation} projectSiteCard`}
      onClick={handleCardClick}
    >
      <div className={styles.loadingWebsiteImage}>
        {coverUrl ? <img src={coverUrl} /> : showButtonOrText()}
      </div>
      <div className={styles.cardContent}>
        <div className={styles.leftCardContent}>
          <h3>{cleanUrl(url)}</h3>
          <div className={styles.taskCountUpdated}>
            <TaskCount
              container={container}
              openTasksCount={undefined}
              triageTasksCount={undefined}
            />
            <p className={styles.lastUpdated}>
              Updated: {formatDistanceStrict(new Date(), parseISO(updatedAt))}{' '}
              ago
            </p>
          </div>
        </div>
        <div className={styles.cardFooter}>
          {!isGuest && (
            <span
              className={styles.websiteMenu}
              onClick={e => e.stopPropagation()}
            >
              <WebsiteMenu
                container={container}
                handleOpenWebsiteModal={handleOpenModal}
                disabled={false}
                id={id}
                projectId={project.id}
                apiKey={apiKey}
                handleGetCoverImage={getCoverImage}
              />
            </span>
          )}
          <ShareButton
            onClick={onShareButtonClick}
            hide={!isNoLoginGuestExperienceEnabled}
          />
          <ProxyCompatibilityChecker
            proxyUrl={proxyUrl}
            projectSiteId={id}
            projectSiteUrl={url}
            getTriggerElement={() => null}
            project={project}
            onSharingClick={onSharingClick}
            showProxyPreview={showProxyPreview}
            isGuest={isGuest}
            isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
            childRef={childRef}
            showTour
          />
          <AddWebsiteModal
            container={container}
            isWebsiteModalOpen={openEditModal}
            activeItem=""
            handleCloseWebsiteModal={handleCloseWebsiteModal}
            title={strings.editWebsiteUrl}
            projectSiteUrl={url}
            projectUrlUpdating
            projectSiteId={id}
            hideInstallInstructions={showProxyPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectSite;
