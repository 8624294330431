import { useLocation, useNavigate } from 'react-router-dom';

import useSearchParams from './useSearchParams';
import { UserTaskFilter } from '../types';

type Props = {
  setPageNumber: (page: number) => void;
};

type ReturnValues = [
  UserTaskFilter | undefined,
  (userTaskFilter?: UserTaskFilter) => void
];

const useFilter = ({ setPageNumber }: Props): ReturnValues => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    ...location,
    navigate
  });

  return [
    Object.keys(searchParams).length === 0 ? undefined : { ...searchParams },
    (userTaskFilter: UserTaskFilter | undefined) => {
      setSearchParams({ ...userTaskFilter });
      setPageNumber(1);
    }
  ];
};

export default useFilter;
