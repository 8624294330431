import React from 'react';
import styles from './index.module.css';
import { Button, Switch } from 'antd';
import { Trash2 } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

interface DisableProjectProps {
  project: {
    id: number;
    show_on_live: boolean;
  };
}

export const DisableProject: React.FC<DisableProjectProps> = ({ project }) => {
  const isProjectActive = project?.show_on_live !== false;

  return (
    <div className={styles.disableWrapper}>
      <p>Your project is {isProjectActive ? 'active' : 'inactive'}. </p>
      <p>{strings.disableCopy}</p>

      <div className={styles.switchWrapper}>
        <a
          className={styles.switchLink}
          rel="nofollow"
          data-method="post"
          href={`/projects/${project?.id}/${
            isProjectActive ? 'disable' : 'enable'
          }`}
        >
          <Switch checked={isProjectActive} />
          <span className={styles.switchText}>
            {isProjectActive ? strings.enableButton : strings.disableButton}
          </span>
        </a>
      </div>
      <Button
        className={styles.disableButton}
        data-confirm="Woah now, don't be hasty! This will delete your project and all of your bugs, are you REALLY sure you want to do that?"
        rel="nofollow"
        data-method="delete"
        href={`/projects/${project?.id}`}
        icon={<Trash2 size={18} />}
      >
        {strings.deleteButton}
      </Button>
    </div>
  );
};

export default DisableProject;
