import React, { useState, useEffect } from 'react';
import { getLangKey } from 'models/Language';
import * as translations from './PageStrings';
import cx from 'classnames';
import * as placeholders from './Placeholders';
import * as surveyOptions from './SurveyOptions';
import DropDownSelect from './DropdownSelect';
import api from 'api/CommandApi';
import styles from './index.module.css';
import UserSurvey from 'models/UserSurvey';
import { Button, Input } from 'antd';
import track, { trackFacebook } from 'utils/analytics';

interface OnboardingSurveyProps {
  organizationId: number;
  onboardingEndpoint: string;
}

type GetContainerType = () => HTMLElement;

function OnboardingSurvey(props: OnboardingSurveyProps) {
  const { organizationId, onboardingEndpoint } = props;
  const strings = translations[getLangKey()];
  const getContainer: GetContainerType = () =>
    document.getElementById('onboarding_survey_container') as HTMLElement;

  const [jobRole, setJobRole] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [companySize, setCompanySize] = useState<string>('');
  const [userType, setUserType] = useState<string>('');
  const [teamType, setTeamType] = useState<string>('');
  const [replace, setReplace] = useState<string[]>([]);
  const [marketingSource, setMarketingSource] = useState<string>('');
  const [errorState, setErrorState] = useState<boolean>(false);
  const [showReplace, setShowReplace] = useState<boolean>();
  const [showMarketingSource, setShowMarketingSource] = useState<boolean>();

  const checkNullValidation: boolean = !!(
    jobRole &&
    industry &&
    companySize &&
    userType &&
    teamType
  );

  useEffect(() => {
    const show: boolean = checkNullValidation;
    setShowReplace(show);
    setShowMarketingSource(replace.length > 0);
    setErrorState(false);
  }, [jobRole, industry, companySize, userType, teamType]);

  useEffect(() => {
    trackFacebook('StartTrial');
  }, []);

  const submitSurvey = async (event: any) => {
    event.preventDefault();
    setErrorState(!checkNullValidation);
    if (checkNullValidation) {
      const userSurvey: UserSurvey = {
        organizationId,
        jobRole,
        industry,
        companySize,
        userType,
        teamType,
        replace,
        marketingSource
      };
      const request = await api.submitUserSurvey(userSurvey);
      track('Signup Survey Submitted');
      if (request) {
        window.location.href = onboardingEndpoint;
      }
    }
  };

  const skipSurvey = () => {
    track('Onboarding Skip link clicked', { page_url: window.location.href });
    window.location.href = onboardingEndpoint;
  };

  const onReplaceChange = (value: any) => {
    setReplace(value);
    setShowMarketingSource(true);
  };

  const onMarketingSourceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMarketingSource(event.target.value);
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.fullSurveyShown]: showReplace
      })}
    >
      <div className={styles.headerLogo} />
      <div className={styles.surveyOuter}>
        <h2 className={styles.surveyHeader}>{strings.welcomeHeader}</h2>
        <p className={styles.surveyTagline}>{strings.helpUs}</p>
        <div className={styles.lineOne}>
          I'm a{' '}
          <DropDownSelect
            name="jobRole"
            defaultText={placeholders.JobRole}
            question={surveyOptions.JobRole}
            getContainer={getContainer}
            value={jobRole}
            onChange={setJobRole}
            errorState={errorState}
          />{' '}
          in{' '}
          <DropDownSelect
            name="industry"
            defaultText={placeholders.Industry}
            question={surveyOptions.Industry}
            getContainer={getContainer}
            value={industry}
            onChange={setIndustry}
            errorState={errorState}
          />{' '}
          with{' '}
          <DropDownSelect
            name="companySize"
            defaultText={placeholders.CompanySize}
            question={surveyOptions.CompanySize}
            getContainer={getContainer}
            value={companySize}
            onChange={setCompanySize}
            errorState={errorState}
          />{' '}
          people in my company.
        </div>
        <div
          className={cx(styles.lineTwo, { [styles.lastLine]: !showReplace })}
        >
          I am looking to use BugHerd with{' '}
          <DropDownSelect
            name="userType"
            defaultText={placeholders.UserType}
            question={surveyOptions.UserType}
            getContainer={getContainer}
            value={userType}
            onChange={setUserType}
            errorState={errorState}
          />{' '}
          who are{' '}
          <DropDownSelect
            name="teamType"
            defaultText={placeholders.TeamType}
            question={surveyOptions.TeamType}
            getContainer={getContainer}
            value={teamType}
            onChange={setTeamType}
            errorState={errorState}
          />{' '}
          to my company.
        </div>
        {showReplace && (
          <div className={styles.lineThree}>
            Before BugHerd, I have been using these tool(s) for this purpose:
            <DropDownSelect
              name="replace"
              defaultText={placeholders.Replace}
              question={surveyOptions.Replace}
              mode="multiple"
              getContainer={getContainer}
              value={replace}
              onChange={onReplaceChange}
              errorState={errorState}
            />
          </div>
        )}
        {showMarketingSource && (
          <div className={styles.lineThree}>
            And finally, where did you hear about BugHerd?
            <Input
              name="marketingSource"
              className={styles.marketingSourceInput}
              placeholder={placeholders.MarketingSource}
              onChange={onMarketingSourceChange}
            />
          </div>
        )}
        <Button
          className={styles.submitButton}
          disabled={!showReplace || !replace.length}
          type="primary"
          onClick={submitSurvey}
        >
          Continue
        </Button>
        <Button
          className={styles.surveyButton}
          type="link"
          onClick={skipSurvey}
        >
          Skip survey
        </Button>
        {errorState && <p className={styles.errorMessage}>{strings.error}</p>}
      </div>
    </div>
  );
}

export default OnboardingSurvey;
