// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';
import { Modal } from 'antd';

import styles from './style.module.css';

export default class AnnotateScreenshot extends React.Component {
  static propTypes = {
    container: PropTypes.object.isRequired,
    blob: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    cta: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
  };

  handleSave = dataURI => {
    this.props.toggleModal();
    this.props.onComplete(dataURI);
  };

  render() {
    return (
      <div>
        <div>{this.props.cta}</div>
        <Modal
          footer={null}
          open={this.props.showModal}
          onOk={this.handleOk}
          closable={false}
          getContainer={() => this.props.container}
          width="100%"
          className={styles.screenshotModal}
        >
          <Image
            {...this.props}
            onSave={this.handleSave}
            onCancel={this.props.toggleModal}
            container={this.props.container}
          />
        </Modal>
      </div>
    );
  }
}
