import React, { FC } from 'react';
import { Select } from 'antd';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../javascript/models/Language';
import * as Types from '../../types';
import cx from 'classnames';
import styles from '../index.module.css';

type Props = {
  onStatusChange: (columnId: number) => void;
  status?: number;
  container: Element;
  columns: Types.Column[];
  inputSize: 'large' | 'middle';
};

const { Option } = Select;
const strings = translations[getLangKey()];

const Status: FC<Props> = props => {
  const { onStatusChange, container, columns, status, inputSize } = props;

  const getColumnOptions = ({ id, name }: Types.Column) => (
    <Option
      className={styles.columnOption}
      value={id}
      label={name}
      key={id + name}
    >
      {name}
    </Option>
  );

  const handleStatusChange = (columnId: number) => {
    onStatusChange(columnId);
  };

  return (
    <Select
      className={cx(styles.select, styles.selectStatus)}
      placeholder={<span className={styles.placeHolder}>{strings.status}</span>}
      getPopupContainer={() => container as HTMLElement}
      onChange={handleStatusChange}
      value={status}
      size={inputSize}
    >
      {columns
        .filter((column: Types.Column) => column.name !== 'archive')
        .map(getColumnOptions)}
    </Select>
  );
};

export default Status;
