import React, { FC } from 'react';
import { Menu } from 'antd';
import { FixedSizeList } from 'react-window';
import styles from './styles.module.css';

type Props = {
  projects: {
    id: number;
    name: string;
  }[];
  onClick: (id: number, domEvent?: any) => void;
};

const LIST_HEIGHT = 320;
const ITEM_SIZE = 32;

export const ProjectList: FC<Props> = ({ projects, onClick }) => {
  return (
    <FixedSizeList
      height={Math.min(LIST_HEIGHT, projects.length * ITEM_SIZE)}
      itemData={projects}
      itemCount={projects.length}
      itemSize={ITEM_SIZE}
      width="100%"
    >
      {({ data, index, style }) => {
        const { id, name } = data[index];
        return (
          <Menu.Item
            key={id}
            style={style}
            onClick={({ domEvent }) => {
              onClick(id, domEvent);
            }}
            title={name}
            className={styles.projectListItem}
          >
            {name}
          </Menu.Item>
        );
      }}
    </FixedSizeList>
  );
};
