import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { skinTones } from './skinTones';
import cx from 'classnames';
import { useEmojiState } from './EmojiProvider';

export const PreviewBox = () => {
  const {
    isDropdownOpen,
    setIsDropdownOpen,
    hoveredEmoji,
    skinTone,
    setSkinTone,
    defaultPreviewEmoji,
    darkMode,
    isAdminView
  } = useEmojiState();
  const hasSkinTones = hoveredEmoji && hoveredEmoji.skins.length > 1;
  const skinToneIndex = hasSkinTones ? parseInt(skinTone, 10) - 1 : 0;
  const skinToneButtonRef = useRef<HTMLSpanElement | null>(null);
  const [ignoreClick, setIgnoreClick] = useState<boolean>(false);

  const hideOverlay = event => {
    if (ignoreClick) return setIgnoreClick(false);
    if (event.target !== skinToneButtonRef.current && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', hideOverlay);
    return () => document.removeEventListener('click', hideOverlay);
  }, [isDropdownOpen, ignoreClick]);

  const preventBubbling = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={styles.previewBox}>
      <div className={styles.preview}>
        <span role="img" aria-label="preview" className={styles.previewEmoji}>
          {(hoveredEmoji || defaultPreviewEmoji).skins[skinToneIndex].native}
        </span>
        <span className={styles.name}>
          {hoveredEmoji?.name || 'Select an emoji'}
        </span>
      </div>
      <span
        ref={skinToneButtonRef}
        className={cx(
          styles.skinToneButton,
          styles[`skinToneButton-${skinTone}`]
        )}
        onMouseDown={event => {
          setIgnoreClick(true);
          preventBubbling(event);
          setIsDropdownOpen(!isDropdownOpen);
        }}
      />
      <ul
        className={cx(styles.skinToneOverlay, {
          [styles.hiddenSkinToneOptions]: !isDropdownOpen,
          [styles.darkOverlay]: !darkMode,
          [styles.sidebarSkinToneOverlay]: !isAdminView
        })}
      >
        {skinTones.map(({ key, label }) => (
          <li
            key={key}
            className={cx(styles.skinToneItem, [
              styles[`skinTone-${key}`],
              { [styles.active]: skinTone === key }
            ])}
            onMouseDown={event => {
              preventBubbling(event);
              setSkinTone(key);
              setIsDropdownOpen(false);
            }}
          >
            <span className={styles.skinToneIcon} />
            <span className={styles.skinToneLabel}>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
