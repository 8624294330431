export const en = {
  addonName: 'Premium Integrations addon',
  addonPromoContent:
    'Get access to Jira, ClickUp, Monday.com, Asana and more by adding our Premium Integrations add-on to your subscription.',
  addonPromoBtn: 'Upgrade for $15/month',
  addModalTitle: 'Add Premium Integrations to your subscription',
  addModalContent:
    'You are adding our Premium Integrations addon to your BugHerd subscription. This will be charged immediately to the card we have on file and added to your ongoing subscription. You can downgrade at any time.',
  addModalBtn: 'Yes, upgrade for $15/month',
  removeBtn: 'Remove',
  removeModalTitle: 'Remove Premium Integrations from your subscription',
  removeModalContent:
    'Are you sure you want to remove the Premium Integrations addon from your subscription? Doing so will immediately disconnect any active premium integrations on your account.',
  removeModalBtn: 'Downgrade',
  upgradeSuccess:
    'You have successfully added Premium Integrations to your subscription.',
  upgradeError:
    'Oops, something went wrong applying this addon to your subscription. Please contact support for help.',
  downgradeSuccess:
    'You have successfully removed the premium integrations addon from your subscription.',
  downgradeError:
    'Oops, something went wrong removing this addon from your subscription. Please contact support for help.'
};
export type Translations = typeof en;
