import React, { FC, useEffect, useState } from 'react';
import cx from 'classnames';
import { BranchesOutlined } from '@ant-design/icons';
import { Badge, Tag, Skeleton } from 'antd';
import Avatar from '../../../../../../../../shared/Avatar';
import { format, isThisYear, isToday } from 'date-fns';
import AddComment from '../../AddComment';
import { Eye, Edit } from 'lucide-react';
import styles from './styles.module.css';
import HighlightedMentions from '../../../../../../../../../javascript/components/HighlightedMentions';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../models/Language';
import { User } from '../../../../../../../models/User';
import { useCommentsState } from '../../../../../../../../providers/Comments';
import { useProjectState } from '../../../../../../../../providers/Project';
import { useCurrentUserState } from '../../../../../../../../providers/CurrentUser';
import Reactions from './Reactions';

const strings = translations[getLangKey()];

type Props = {
  id: number;
  userId: number;
  commitUrl?: string;
  text: string;
  createdAt: string;
  isPrivate: boolean;
  commenter?: User;
  readByUsers: number[] | null | undefined; // this can be null due to some legacy handling of our data in backbone
  reactions: string;
};

const determineHasUserReadComment = (
  readByUsers: number[] | null | undefined,
  userId: number,
  featureEnabled: boolean,
  createdAt: Date,
  featureEnabledDate: Date,
  userCreatedDate: Date
) => {
  // if there is no info then we optimistically assume the user has read the comment
  if (readByUsers === null || readByUsers === undefined) return true;
  if (!featureEnabled) return true;

  // If the comment was created before the feature went live then show as read
  if (createdAt < featureEnabledDate) return true;
  // If the comment was created before the user then show as read
  if (createdAt < userCreatedDate) return true;

  return readByUsers.includes(userId);
};

const Comment: FC<Props> = ({
  id,
  userId,
  commitUrl,
  text,
  createdAt,
  isPrivate,
  commenter,
  readByUsers,
  reactions
}) => {
  if (!commenter) return null;

  const [editMode, setEditMode] = useState(false);

  const {
    canEditComment,
    currentUserId,
    users,
    usersNotOnProject,
    updateComment,
    isAdminView,
    getContainer,
    taskId,
    apiDomain
  } = useCommentsState();

  const { currentUser } = useCurrentUserState();
  const { project } = useProjectState();

  const unreadCommentsEnabled = true;

  const getEditor = () => {
    return (
      <AddComment
        initialValue={text}
        commentId={id}
        onCancel={() => setEditMode(false)}
        isPrivate={isPrivate}
      />
    );
  };

  const renderDate = () => {
    const date = new Date(createdAt);
    let displayFormat = "MMM d, yyyy 'at' h:mma";

    if (isThisYear(date)) {
      if (isToday(date)) {
        displayFormat = "'Today' 'at' h:mma";
      } else {
        displayFormat = "MMM d 'at' h:mma";
      }
    }

    return <span className={styles.date}>{format(date, displayFormat)}</span>;
  };

  const hasEditCommentPermission: boolean =
    canEditComment && userId === currentUserId;

  const highlightedMentionsProps = {};

  const mapToHighlightedUserType = ({ id, email, name }: User) => ({
    id,
    email,
    name
  });

  if (users?.length && usersNotOnProject?.length) {
    Object.assign(highlightedMentionsProps, {
      projectMembers: users
        .filter(({ role }: User) => role !== 'guest')
        .map(mapToHighlightedUserType),
      projectUsers: users.map(mapToHighlightedUserType),
      allUsers: users.concat(usersNotOnProject).map(mapToHighlightedUserType)
    });
  }

  const userReadComment = determineHasUserReadComment(
    readByUsers,
    Number(currentUserId),
    unreadCommentsEnabled,
    new Date(createdAt),
    new Date(project?.unreadCommentsEnabledDate || 0),
    new Date(currentUser?.createdAt || 0)
  );

  const [hasUserReadComment, setHasUserReadComment] = useState<boolean>(
    userReadComment
  );

  useEffect(() => {
    setHasUserReadComment(userReadComment);
  }, [userReadComment]);
  const markAsRead = () => {
    if (hasUserReadComment) return;

    updateComment({
      commentId: id,
      commentEvent: {
        type: 'read'
      },
      taskId
    });
    setHasUserReadComment(true);
  };

  if (id === -1) {
    return (
      <div className={cx(styles.container)}>
        <Skeleton active avatar paragraph={{ rows: 1 }} />
      </div>
    );
  }

  return (
    <Badge dot={!hasUserReadComment} className={styles.antdBadgeOverride}>
      <div
        className={cx(styles.container, {
          [styles.privateComment]: isPrivate && !editMode,
          [styles.unreadComment]: !hasUserReadComment,
          [styles.adminContainer]: isAdminView
        })}
        onMouseOver={markAsRead}
      >
        <div className={styles.commentContent}>
          <Avatar className={styles.avatar} member={commenter} size="small" />
          <div className={styles.bodyContainer}>
            <div className={styles.header}>
              <div>
                <b>{commenter.name}</b>
                {renderDate()}
              </div>
              {hasEditCommentPermission && (
                <span
                  className={styles.editButton}
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <Edit className={styles.editIcon} />
                  {strings.edit}
                </span>
              )}
            </div>
            {editMode ? (
              getEditor()
            ) : (
              <div className={styles.body}>
                <HighlightedMentions
                  description={text}
                  className={cx(styles.commentText, {
                    [styles.privateCommentText]: isPrivate
                  })}
                  isPrivate={isPrivate}
                  {...highlightedMentionsProps}
                  isComment
                  includeGuests={
                    project?.organization?.assignGuestsAllowed &&
                    project?.assignGuests
                  }
                />
                {commitUrl && (
                  <div className={styles.actions}>
                    <Tag>
                      <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={commitUrl}
                        className={styles.externalCommitLink}
                      >
                        <BranchesOutlined />
                        <span className={styles.externalCommitText}>
                          {strings.viewCommit}
                        </span>
                      </a>
                    </Tag>
                  </div>
                )}
              </div>
            )}
            {!editMode && isPrivate && (
              <div className={styles.privateCommentFooter}>
                <Eye className={styles.eyeIcon} />
                <span className={styles.visibleTo}>{strings.membersOnly}</span>
              </div>
            )}
          </div>
        </div>
        {!editMode && (
          <Reactions
            id={id}
            currentUser={currentUser}
            getContainer={getContainer}
            reactions={reactions}
            isPrivate={isPrivate}
            users={[
              ...users,
              ...usersNotOnProject.filter(({ role }) =>
                role?.includes('manager')
              )
            ]}
            updateComment={updateComment}
            taskId={taskId}
            apiDomain={apiDomain}
            isAdminView={!!isAdminView}
            projectId={project?.id || 0}
          />
        )}
      </div>
    </Badge>
  );
};

export default Comment;
