import * as React from 'react';
import moment from 'dayjs';
import { DatePicker } from 'antd';

import styles from './index.module.css';

import { DATE_FORMAT } from 'models/Application';

type Moment = moment.Moment;

const { RangePicker } = DatePicker;

type Props = {
  setDateStart: (value: string) => void;
  setDateEnd: (value: string) => void;
  value: (string | undefined)[];
};

const DateFilter: React.FC<Props> = ({ setDateStart, setDateEnd, value }) => {
  const setDates = (dates: (Moment | null)[]) => [
    dates[0] ? setDateStart(dates[0].format(DATE_FORMAT)) : null,
    dates[1] ? setDateEnd(dates[1].format(DATE_FORMAT)) : null
  ];

  return (
    <RangePicker
      allowEmpty={[false, true]}
      allowClear={false}
      className={styles.datePicker}
      onChange={dates => setDates(dates || [])}
      format="D MMM YYYY"
      getPopupContainer={trigger => trigger.parentElement as HTMLElement}
      value={[
        value[0] ? moment(value[0]) : null,
        value[1] ? moment(value[1]) : null
      ]}

    ></RangePicker >
  );
};

export default DateFilter;
