import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layers } from 'lucide-react';
import { format } from 'date-fns';
import { undoBulkRequest } from 'jsApi/CommandApi';
import ArchivedList from './archived_list';
import ArchivedListHeader from './archived_list_header';
import styles from './index.module.css';
import taskListStyles from '../kanban/task_list/index.module.css';

const BulkArchived = props => {
  const {
    loading,
    setLoading,
    archiveBulkRequests,
    getContainer,
    singleRequest,
    fetchCompleted
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  const undoBulkArchive = requestId => {
    const bulkRequest = archiveBulkRequests.find(
      request => request.id === requestId
    );

    setLoading(true);
    undoBulkRequest(bulkRequest).then(() => fetchCompleted());
  };

  const getDateString = date => {
    let string;
    const _date = new Date(date);
    string = format(_date, 'h:mm');
    string = string + format(_date, 'aa').toLowerCase();
    string = string + format(_date, ', do LLL, yyyy');
    return string;
  };

  return (
    <div className={styles.bulkArchiveOuter}>
      <ArchivedList
        {...{
          visible: isModalVisible,
          hideModal,
          getContainer,
          bulkRequests: archiveBulkRequests,
          undoBulkArchive,
          getDateString,
          loading
        }}
      />
      <div className={taskListStyles.separatorOuter}>
        <div className={taskListStyles.listSeparator}>
          <span className={taskListStyles.separatorName}>
            Recently archived
          </span>
        </div>
      </div>
      <div className={styles.listItemOuter}>
        <Layers />
        <ArchivedListHeader
          {...{
            showModal,
            undoBulkArchive,
            singleRequest,
            bulkRequest: archiveBulkRequests[0],
            getDateString,
            loading
          }}
        />
      </div>
    </div>
  );
};

BulkArchived.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  archiveBulkRequests: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.shape({
        success: PropTypes.arrayOf(PropTypes.number)
      }),
      createdAt: PropTypes.string,
      userFullName: PropTypes.string
    })
  ),
  getContainer: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.object
  ]),
  singleRequest: PropTypes.bool.isRequired,
  fetchCompleted: PropTypes.func
};

export default BulkArchived;
