import * as React from 'react';
import { Checkbox } from 'antd';

import styles from './index.module.css';

type Props = {
  options: { label: string; value: string }[];
  value?: string[];
  onChange: (value: string[]) => void;
};

const MultiSelectCheckbox: React.FC<Props> = ({
  options,
  value = [],
  onChange
}) => (
  <div className={styles.multiSelectFilter}>
    <Checkbox.Group
      value={value}
      onChange={value => onChange(value.map(v => `${v}`))}
      className={styles.multiSelectCheckboxGroup}
    >
      {options.map(({ label, value }) => (
        <div key={value} className={styles.multiSelect}>
          <Checkbox value={value}>{label}</Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  </div>
);

export default MultiSelectCheckbox;
