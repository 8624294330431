import React, { FC, useState, useEffect } from 'react';
import styles from './index.module.css';
import { FileText, Image, PlusCircle } from 'lucide-react';
import { Button, Upload, Form, message, Tooltip } from 'antd';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { default as FigmaLogo } from './assets/figma_logo.svg';
import { validateUrl } from 'utils/validateUrls';
import ProjectUrlInput from '../../javascript/components/projectUrlInput';
import track from 'jsUtilities/analytics';
import { useProjectSitesState } from '../providers/ProjectSites';
import { addProjectSites } from './Sites/ProjectSites';
import { useOrganizationState } from '../../../clients/providers/Organization';
import Tour from 'reactour';
import { getAccount } from 'api/AccountApi';
import { AccountType } from 'models/Account';

type Props = {
  project: any;
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  showButtons?: boolean;
};

const AssetsHeader: FC<Props> = ({ project }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [projectUrl, setProjectUrl] = useState<string>('');
  const [isAddWebsiteDisabled, setIsAddWebsiteDisabled] = useState<boolean>(
    true
  );
  const { handleOpenFigma, onUploadChange } = useDesignAssetsState();
  const { handleGetProjectSites } = useProjectSitesState();
  const { hasOrganizationExperiment } = useOrganizationState();
  const [form] = Form.useForm();

  const { apiKey, id: projectId, showAddWebsiteOnboarding, websites } = project;

  const hasAddWebsiteOnboardingExperiment =
    hasOrganizationExperiment('add_website_onboarding_variant') ||
    hasOrganizationExperiment('add_website_onboarding_no_proxy') ||
    hasOrganizationExperiment('capture_url_onboarding_variant');

  const tourSteps: Step[] = [
    {
      selector: '#addWebsiteWrapper',
      content: (
        <>
          <h2>Enter a website for your project</h2>
          <p>Click add website to start using BugHerd</p>
        </>
      ),
      action: node => {
        node.focus();
        setIsTourOpen(true);
      }
    }
  ];

  const handleUrlChange = (value: string) => {
    setProjectUrl(value.trim());
  };

  const handleSuccessfulResponse = async response => {
    if (response?.error) {
      message.error(
        'There was an error with adding your website url. Please try again.'
      );
      return;
    }

    if (response?.project_site) {
      message.info('Your website has been updated');
      setProjectUrl(response.project_site.url);
      return;
    }

    setProjectUrl('');
    form.setFieldsValue({ WebsiteURL: '' });
    message.success('Website added successfully');
    track('Add Website');
  };

  const handleSubmitUrl = async () => {
    try {
      setIsSubmitting(true);
      await form.validateFields(['WebsiteURL']);

      const response = await addProjectSites(apiKey, projectUrl, projectId);
      await handleSuccessfulResponse(response);
      handleGetProjectSites();
    } catch (error) {
      console.error('Error submitting URL:', error);
      message.error('Failed to add website');
    } finally {
      setIsSubmitting(false);
      setProjectUrl('');
      setIsTourOpen(false);
    }
  };

  useEffect(() => {
    if (!hasAddWebsiteOnboardingExperiment) return;
    if (websites.length > 0) return;

    const hasCompletedTour =
      localStorage.getItem('completed_add_website_onboarding') === 'true' ||
      !showAddWebsiteOnboarding;

    setIsTourOpen(!hasCompletedTour);
  }, [hasAddWebsiteOnboardingExperiment, showAddWebsiteOnboarding]);

  useEffect(() => {
    if (!isTourOpen) return;
    const fetchAccountData = async () => {
      try {
        const accountData: { data: AccountType } = await getAccount();
        const domain = accountData?.data?.suggestedProjectDomain || null;
        if (!domain) return;
        setProjectUrl('https://' + domain);
        form.setFieldsValue({ WebsiteURL: domain });
      } catch (error) {
        window?.bugsnagClient?.notify(error);
      }
    };

    fetchAccountData();
  }, [isTourOpen]);

  useEffect(() => {
    const checkUrl = async () => {
      // check for empty url field
      const emptyUrlInput =
        !projectUrl.length ||
        projectUrl === 'http://' ||
        projectUrl === 'https://';
      if (emptyUrlInput) {
        setIsAddWebsiteDisabled(true);
        return;
      }

      // check status of url to disable the add website button;
      const { status } = await validateUrl(projectUrl);

      setIsAddWebsiteDisabled(status === 'error');
    };

    checkUrl();
  }, [projectUrl]);

  return (
    <div className={styles.deliverablesWrapper}>
      <h2>Websites & Files</h2>
      <div className={styles.buttonsWrapper}>
        <div className={styles.addWebsiteWrapper} id="addWebsiteWrapper">
          <Form
            form={form}
            className={styles.addWebsiteForm}
            onFinish={handleSubmitUrl}
            id="addWebsiteForm"
          >
            <ProjectUrlInput
              fieldName={['WebsiteURL']}
              currentUrl={projectUrl}
              onChange={handleUrlChange}
              isUrlRequired={false}
              placeHolder="Enter website URL"
              checkUrlOnEnter
              shouldValidateDNS
              showHelpWarning
            />
            <Tooltip
              title={
                !projectUrl.length
                  ? "First enter a website URL, then click '+ Add website'"
                  : ''
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                className={styles.saveButton}
                loading={isSubmitting}
                disabled={isAddWebsiteDisabled}
                id="addWebsiteButton"
              >
                + Add website
              </Button>
            </Tooltip>
          </Form>
        </div>

        <span className={styles.orDivider}>or</span>

        <Upload
          name="file"
          onChange={onUploadChange}
          showUploadList={false}
          multiple
          customRequest={() => {}}
          accept=".pdf"
        >
          <Button
            className={styles.uploadDeliverable}
            icon={<FileText />}
            type="primary"
          >
            PDF
            <PlusCircle className={styles.circlePlus} />
          </Button>
        </Upload>
        <Upload
          name="file"
          onChange={onUploadChange}
          showUploadList={false}
          multiple
          customRequest={() => {}}
          accept="image/*"
        >
          <Button
            className={styles.uploadDeliverable}
            icon={<Image />}
            type="primary"
          >
            Image
            <PlusCircle className={styles.circlePlus} />
          </Button>
        </Upload>
        <Button
          onClick={handleOpenFigma}
          className={`${styles.uploadDeliverable} ${styles.figmaButton}`}
          icon={<FigmaLogo />}
          type="primary"
        >
          {'Figma'}
          <PlusCircle className={styles.circlePlus} />
        </Button>
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          accentColor="#3f71e0"
          closeWithMask={false}
          disableInteraction={false}
          disableDotsNavigation
          disableFocusLock
          lastStepNextButton={<Button type="primary">Finish</Button>}
          rounded={12}
          scrollDuration={500}
          showNavigation={false}
          showCloseButton={false}
          showNumber={false}
          showButtons={false}
        />
      </div>
    </div>
  );
};

export default AssetsHeader;
