import React, {
  FC,
  MutableRefObject,
  useRef,
  useState,
  useEffect
} from 'react';
import { AutoComplete, Input, Tag } from 'antd';
import Avatar from '../../../../../shared/Avatar';
import { User, UserPlus, SearchX } from 'lucide-react';
import DetailsLabel from '../DetailsLabel';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { User as Assignee } from '../../../../models/User';
import { User as UserType } from '../../../SidebarPanel/Detailbar/CommentsAndLog/types';

// @ts-ignore
import styles from './styles.module.css';
import cx from 'classnames';
import {
  getSortedAssignableUsers,
  upgradeOption
} from '../../../../../../redesign/javascript/utils/getAssignableUsers';

const strings = translations[getLangKey()];

type Props = {
  canEditAssignees: boolean;
  viewAssignees: boolean;
  assignees: any[];
  updateAssignees: (assigneeIds: number[]) => void;
  billing: boolean;
  assignableUsers: any[];
  assignGuests: boolean;
  assignGuestsAllowed: boolean;
  bugherdUrl: string;
  container: HTMLDivElement;
  currentUser: UserType;
  isAdminView?: boolean;
  guestVisible: boolean;
};

export const Assignees: FC<Props> = ({
  container,
  canEditAssignees,
  viewAssignees,
  assignees,
  updateAssignees,
  billing,
  assignableUsers,
  assignGuests,
  assignGuestsAllowed,
  bugherdUrl,
  currentUser,
  isAdminView,
  guestVisible
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [positionDirection, setPositionDirection] = useState<string>('top');
  const inputRef: MutableRefObject<null | Input> = useRef(null);

  const canAssignGuests = assignGuestsAllowed && assignGuests;
  const isGuest = currentUser.role === 'guest';
  const imAssigned = assignees.find(({ id }) => id === currentUser.id);
  const imAssignedAsGuest = imAssigned && isGuest;
  const guestAssignees = guestVisible
    ? assignees.filter(({ role }) => role === 'guest')
    : canAssignGuests && imAssignedAsGuest
    ? [imAssigned]
    : [];

  const showAssignees =
    canEditAssignees ||
    viewAssignees ||
    (canAssignGuests && guestAssignees.length);

  if (!showAssignees) return null;

  const placeholder =
    (!assignees.length && strings.unassigned) ||
    (showDropdown && strings.searchForUser) ||
    '';

  const handleUnassignUser = (assignee: Assignee) =>
    updateAssignees(
      assignees
        .filter((user: any) => user.id !== assignee.id)
        .map((user: any) => user.id)
    );

  const getDataSource = () => {
    let query = value;
    let data = getSortedAssignableUsers(
      assignableUsers || [],
      assignGuestsAllowed && assignGuests
    ).filter(
      (user: Assignee) =>
        !assignees.find((assignee: Assignee) => assignee.name === user.name)
    );

    if (query) {
      query = query.toLowerCase();
      data = data.filter(
        (user: Assignee) =>
          user.name.slice(0, query.length).toLowerCase() === query
      );
    }

    if (data.length) {
      return data.map((user: Assignee) => ({
        value: user.name,
        label: (
          <div className={styles.userContainer}>
            <Avatar size="small" member={user} />
            <span className={styles.userName}>{user.name}</span>
          </div>
        )
      }));
    } else {
      return [
        {
          value: strings.noResults,
          label: (
            <div className={styles.userContainer}>
              <SearchX className={styles.slashIcon} />
              <span className={styles.userName}>{strings.noMatchingUsers}</span>
            </div>
          )
        }
      ];
    }
  };

  const getAssignees = () =>
    !!assignees?.length &&
    (isGuest ? guestAssignees : assignees).map((assignee: Assignee) => (
      <span className={styles.tagHolder} key={assignee.id + assignee.name}>
        <Tag
          className={cx(styles.tag, {
            [styles.activeTag]: assignee.id === imAssigned?.id
          })}
          closable={canEditAssignees}
          onClose={() => handleUnassignUser(assignee)}
        >
          <span className={styles.tagContent}>
            <User className={styles.userIcon} />
            <span className={styles.innerTag}>{assignee.name}</span>
          </span>
        </Tag>
      </span>
    ));

  const blurInput = () => {
    setShowInput(false);
    setValue('');
  };

  const handleOnBlur = (inputValue: number | string = value) => {
    if (!inputValue) return blurInput();

    let updatedAssignees = assignees.map((user: Assignee) => user.id);

    if (typeof inputValue !== 'string') {
      updatedAssignees.push(inputValue);
    } else if (assignableUsers.length) {
      let newAssignee = assignableUsers.find(
        (user: any) =>
          user.name.slice(0, inputValue.length).toLowerCase() ===
          inputValue.toLowerCase()
      );
      if (newAssignee) {
        updatedAssignees.push(newAssignee.id);
      }
    }
    if (assignees.length < updatedAssignees.length)
      updateAssignees(updatedAssignees);

    blurInput();
  };

  const escapeCode: number = 27;

  const handleOnKeyDown = (event: any) =>
    event?.keyCode === escapeCode && blurInput();

  let options = getDataSource();

  const upgradeOptionItem = upgradeOption({
    domain: bugherdUrl,
    styles,
    upgradeText: strings.assignUpgrade,
    value: 0,
    billing,
    isAdminView
  });

  useEffect(() => {
    if (!showInput || !inputRef.current) return;

    const inputRect = inputRef.current.input.getBoundingClientRect();
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;
    const dropdownHeight = 8 + Math.min(options.length, 7.56) * 34;

    if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
      setPositionDirection('bottom');
    } else {
      setPositionDirection('top');
    }
  }, [showInput, showDropdown]);

  return (
    <div className={styles.assigneesOuter}>
      <DetailsLabel label={strings.assignedTo} />
      <div className={styles.assigneesInner}>
        <div className={styles.assigneesBox}>{getAssignees()}</div>
        {canEditAssignees && (
          <div
            className={cx(styles.inputBox, {
              [styles.inputWithUpgrade]: !assignGuestsAllowed,
              [styles.sidebarInputWithUpgrade]:
                !isAdminView && !assignGuestsAllowed
            })}
          >
            {!assignGuestsAllowed && showInput && showDropdown && (
              <div
                className={cx(upgradeOptionItem.className, [
                  styles[positionDirection]
                ])}
                style={{
                  [positionDirection]: `${(isAdminView &&
                  positionDirection !== 'top'
                    ? 38
                    : 41) +
                    Math.min(options.length, 7.56) * 34}px`
                }}
              >
                {upgradeOptionItem.label}
              </div>
            )}
            {showInput ? (
              <AutoComplete
                className={cx(styles.assigneesAutoComplete, 'assigneesInput')}
                getPopupContainer={trigger =>
                  container || trigger.parentElement
                }
                options={options}
                value={value}
                onChange={setValue}
                onBlur={() => handleOnBlur()}
                popupMatchSelectWidth
                popupClassName={cx(styles.assigneesDropdown, {
                  [styles.sidebarDropdownWithUpgrade]:
                    !isAdminView && !assignGuestsAllowed
                })}
                // @ts-ignore
                onSelect={inputValue => {
                  setValue(inputValue);
                  handleOnBlur(inputValue);
                }}
                onDropdownVisibleChange={setShowDropdown}
                open={showDropdown}
                disabled={!canEditAssignees}
              >
                <Input
                  ref={inputRef}
                  className={styles.assigneesInput}
                  type="text"
                  onKeyDown={handleOnKeyDown}
                  placeholder={placeholder}
                  onPressEnter={() => handleOnBlur()}
                  disabled={!canEditAssignees}
                />
              </AutoComplete>
            ) : (
              <div className={styles.tagAndAlertBox}>
                <Tag
                  onClick={() => {
                    setShowInput(true);
                    setTimeout(() => {
                      inputRef?.current?.focus();
                      setShowDropdown(true);
                    }, 300);
                  }}
                  className={cx(styles.tag, styles.addTag)}
                >
                  <UserPlus className={styles.assignUserIcon} />{' '}
                  {strings.assignUser}
                </Tag>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
