import React, { FC, useState, KeyboardEventHandler, useEffect } from 'react';
import { Select, Tag } from 'antd';
import * as translations from '../../strings';
import { getLangKey } from '../../../../../../../javascript/models/Language';
import { PlusCircle, Tag as TagIcon } from 'lucide-react';
import * as Types from '../../types';
import cx from 'classnames';
import styles from '../index.module.css';

type Props = {
  container: Element;
  tags: Types.Tag[];
  onTagsChange: (tags: Types.Tag[]) => void;
  availableTags: Types.Tag[];
  inputSize: 'large' | 'middle';
  createTagsViaNewTask: boolean;
};

const strings = translations[getLangKey()];
const { Option } = Select;

const Tags: FC<Props> = props => {
  const {
    container,
    availableTags,
    tags,
    onTagsChange,
    inputSize,
    createTagsViaNewTask
  } = props;

  const availableTagIds = availableTags.map(({ id }) => id);

  useEffect(() => {
    if (tags.length && availableTagIds.length) {
      const isValidTag = (id: number): boolean =>
        (id < 1 && createTagsViaNewTask) || availableTagIds.includes(id);

      const hasInvalidTags = tags.find(({ id }) => {
        return !isValidTag(id);
      });

      if (hasInvalidTags) {
        onTagsChange(tags.filter(({ id }) => isValidTag(id)));
      }
    }
  }, [availableTagIds, createTagsViaNewTask]);

  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<Types.Tag | undefined>();
  const [searchValue, setSearchValue] = useState<string>();

  const addedTags = tags.filter((tag: Types.Tag) => tag.id < 1);
  const hasAddedTags = !!addedTags.length;

  const getSelectableTags = () => {
    const selectableTags = availableTags
      .slice()
      .sort((a, b) => a.display.localeCompare(b.display));
    if (hasAddedTags) {
      addedTags.forEach((tag: Types.Tag) => selectableTags.push(tag));
    }

    const isAdded = tags.find(tag => tag.display === newTag?.display);
    const matchedSubstring = (_newTagDisplay: string) =>
      availableTags.filter(tag => {
        const display = tag.display.toLowerCase();
        const newDisplay = _newTagDisplay.toLowerCase();
        return (
          display.length > newDisplay.length && display.includes(newDisplay)
        );
      }).length;

    if (newTag?.display && !isAdded && matchedSubstring(newTag.display) > 0) {
      selectableTags.push(newTag);
    }
    return selectableTags;
  };

  const handleTagsFocus = () => {
    if (!tags.length) {
      setShowPlaceholder(!showPlaceholder);
    }
  };

  const handleTagsBlur = () => {
    handleTagsFocus();
    setNewTag(undefined);
    setSearchValue('');
  };

  const removeTag = (value: string) => {
    const tagsClone = tags.filter((tag: Types.Tag) => tag.display !== value);
    onTagsChange(tagsClone);
  };

  const tagOptions = ({ id, display }: { id: number; display: string }) => {
    const isNewTag = display === newTag?.display && newTag?.id === id;
    return (
      <Option
        className={styles.tagOption}
        value={display}
        label={display}
        key={id + display}
      >
        {isNewTag ? (
          <PlusCircle className={styles.newTagIcon} />
        ) : (
          <TagIcon className={styles.optionIcon} />
        )}
        <span title={display} className={styles.optionText}>
          {display}
        </span>
      </Option>
    );
  };

  const getTags = (props: any) => (
    <Tag
      className={styles.tag}
      icon={<TagIcon className={styles.tagIcon} />}
      closable
      onClose={() => removeTag(props.value)}
    >
      {props.label[1]}
    </Tag>
  );

  const handleTagsChange = (tagDisplays: string[]) => {
    const selectableTags = getSelectableTags();
    const updatedTags = selectableTags.filter(
      (tag: Types.Tag) =>
        !!tagDisplays.find((display: string) => display === tag.display)
    );
    onTagsChange(updatedTags);
    setSearchValue('');
    setNewTag(undefined);
  };

  const handleTagSearch = (search: string) => {
    setSearchValue(search);
    if (createTagsViaNewTask) {
      const id = parseFloat(Math.random().toFixed(4));
      setNewTag({ id, display: search });
    }
  };

  const handleAddNewTag = () => {
    setSearchValue('');
    const tagsClone = tags.slice();
    if (newTag && newTag.display) {
      tagsClone.push(newTag);
    }
    onTagsChange(tagsClone);
    setNewTag(undefined);
  };

  const handleTagInputKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    const isEnter = event.keyCode === 13;
    if (isEnter && newTag && newTag.display) {
      handleAddNewTag();
    }
  };

  const renderTagOptions = () =>
    getSelectableTags().map((tag: Types.Tag) => tagOptions(tag));

  const handleOnSelect = () => {
    if (dropdownOpen) setDropdownOpen(!dropdownOpen);
  };

  return (
    <Select
      mode="multiple"
      placeholder={
        showPlaceholder ? (
          <span className={styles.placeHolder}>{strings.tags}</span>
        ) : null
      }
      className={cx(styles.select, styles.selectTags, {
        [styles.hasValue]: tags.length
      })}
      // @ts-expect-error
      getPopupContainer={() => container}
      onChange={handleTagsChange}
      value={tags.map(({ display }: { display: string }) => display)}
      onFocus={handleTagsFocus}
      onBlur={handleTagsBlur}
      tagRender={getTags}
      onSearch={handleTagSearch}
      searchValue={searchValue}
      open={dropdownOpen}
      suffixIcon={null}
      onSelect={handleOnSelect}
      notFoundContent={
        newTag ? (
          <div onClick={handleAddNewTag} className={styles.newTagOuter}>
            <PlusCircle className={styles.newTagIcon} />
            <span className={styles.optionText}>{newTag.display}</span>
          </div>
        ) : (
          <div className={styles.noTags}>{strings.noTagsFound}</div>
        )
      }
      onInputKeyDown={handleTagInputKeyDown}
      size={inputSize}
      onDropdownVisibleChange={setDropdownOpen}
    >
      {renderTagOptions()}
    </Select>
  );
};

export default Tags;
