import React, { FC } from 'react';
import styles from './styles.module.css';
import { Select } from 'antd';
import { getLangKey } from '../../../../../../javascript/models/Language';
import * as translations from './strings';
import { Info } from 'lucide-react';
import cx from 'classnames';
import DetailsLabel from '../DetailsLabel';

const strings = translations[getLangKey()];

const { Option } = Select;

type Props = {
  guestVisible: boolean;
  updateVisibility: (visibility: boolean) => void;
  isAdminView?: boolean;
};

type VisibleOption = {
  label: string;
  value: boolean;
};

const VisibleTo: FC<Props> = ({
  guestVisible,
  updateVisibility,
  isAdminView
}) => {
  const handleHelp = (event: any) => {
    event.stopPropagation();
    if (isAdminView) {
      // @ts-ignore
      window._elev.openArticle('84898');
    } else {
      window.open(
        'https://support.bugherd.com/hc/en-us/articles/5410502973711',
        '_blank'
      );
    }
  };

  const options: VisibleOption[] = [
    {
      label: strings.members,
      value: false
    },
    {
      label: strings.membersAndGuests,
      value: true
    }
  ];

  return (
    <div className={styles.visibleToOuter}>
      <DetailsLabel label={strings.visibleTo} />
      <Select
        className={styles.visibleToSelect}
        value={guestVisible}
        onSelect={updateVisibility}
        optionLabelProp="label"
        getPopupContainer={trigger => trigger.parentElement}
        popupMatchSelectWidth={false}
      >
        {options.map(({ value, label }: VisibleOption, index: number) => (
          <Option
            className={cx(styles.visibleOption, styles.withLink)}
            key={`${value}-${index}`}
            value={value}
            label={label}
          >
            {label}
            {value && <Info onClick={handleHelp} className={styles.infoIcon} />}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default VisibleTo;
