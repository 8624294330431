import React from 'react';
import { Archive } from 'lucide-react';

import { getLangKey } from 'jsModels/Application';

import styles from './index.module.css';
import * as translations from './strings';
import { ArchiveSelectedProps, ARCHIVE_TASK_LIMIT } from './types';
import { processArchiveBulkRequest } from "jsViews/kanban/createBulkRequest";

const ArchiveSelected = props => {
  const langKey = getLangKey();
  const strings = translations[langKey];
  const { selectedTaskList, columnId, onCompleted } = props;

  return (
    <div className={styles.multiSelectMenuContainer}>
      <div
        className={styles.multiSelectMenuItem}
        onClick={() => {
          processArchiveBulkRequest({
            columnId: columnId,
            taskIds: selectedTaskList.slice(0, ARCHIVE_TASK_LIMIT)
          });
          onCompleted();
        }}
      >
        <Archive className={styles.multiSelectMenuItemIcon} />
        <span>{strings.archiveSelectedTasks}</span>
        <span>&nbsp;{'(' + selectedTaskList.length + ')'}</span>
      </div>
    </div>
  );
};

ArchiveSelected.propTypes = ArchiveSelectedProps;

export default ArchiveSelected;
