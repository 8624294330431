import React from 'react';
import cx from 'classnames';
import { CategoryName, Emoji, CategoryLabel } from './types';
import { categoryIcons } from './categoryIcons';
import styles from './styles.module.css';
import { PreviewBox } from './PreviewBox';
import { useEmojiState } from './EmojiProvider';
import { EmojiCategory } from './EmojiCategory';

export const EmojiPicker = () => {
  const {
    emojiData,
    selectedCategory,
    setSelectedCategory,
    handleCategoryClick,
    searchValue,
    setSearchValue,
    inputRef,
    searchResults,
    getRecentEmojis,
    darkMode
  } = useEmojiState();

  if (!emojiData) return null;

  const recentEmojis = getRecentEmojis();

  return (
    <div
      className={cx(styles.pickerContainer, { [styles.darkMode]: darkMode })}
    >
      <div className={styles.pickerUpper}>
        <div className={styles.categoryIcons}>
          {categoryIcons.map(({ category, icon: Icon }) => (
            <Icon
              key={category}
              className={cx(styles.categoryIcon, {
                [styles.active]: selectedCategory === category
              })}
              onClick={() => handleCategoryClick(category)}
              title={category}
            />
          ))}
        </div>
        <input
          placeholder="Search all emoji"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          className={styles.searchInput}
          ref={inputRef}
          onFocus={() => setSelectedCategory(CategoryName.SEARCH)}
        />
        <div className={styles.emojisContainer}>
          <div className={styles.emojiList}>
            <EmojiCategory name="Search results" emojis={searchResults} />
            <EmojiCategory
              emojis={Array.isArray(recentEmojis) ? recentEmojis : []}
              name={CategoryName.FREQUENT}
              label="frequently used"
              hide={!!searchValue}
            />
            {emojiData?.categories.map(({ id: name, emojis }) => (
              <EmojiCategory
                key={name}
                emojis={emojis}
                name={name}
                label={CategoryLabel[name.toUpperCase()]}
                hide={!!searchValue}
              />
            ))}
          </div>
        </div>
      </div>
      <PreviewBox />
    </div>
  );
};
