const hideNotice = () => {
  const appAlert = document.querySelector('.app-alert');

  if (appAlert && appAlert.children.length > 0) {
    const children = Array.from(appAlert.children).filter(
      child => !child.classList.contains('no-fade')
    );

    children.forEach(child => {
      // Get the full height of the element before collapsing
      const fullHeight = child.scrollHeight + 'px';

      // Apply the full height so that the transition starts from the full size
      child.style.maxHeight = fullHeight;

      setTimeout(() => {
        // Add transitions for max-height, padding, margin, and opacity
        child.style.transition =
          'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out, margin 0.5s ease-in-out';

        // Start the slide up and fade out
        child.style.maxHeight = '0';
        child.style.padding = '0';
        child.style.margin = '0';
        child.style.opacity = '0';

        child.addEventListener(
          'transitionend',
          () => {
            child.style.display = 'none';
          },
          { once: true }
        );
      }, 5000); // Delay before the animation starts
    });
  }
};

hideNotice();

// Adding to global scope
window.showNotice = message => {
  const out = document.createElement('p');
  out.className = 'notice';
  out.textContent = message;

  const appAlert = document.querySelector('.app-alert');
  if (!appAlert) throw new Error('No app alert to find');
  appAlert.innerHTML = '';
  appAlert.appendChild(out);

  hideNotice();
};
