import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Progress, Image } from 'antd';

import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import figmaFeedback from './assets/figma_feedback.png';
import { Project } from './types';
import figmaCard from './assets/figma_sample_file.png';
import figmaSampleFile from './assets/figma_sample_file.png';
import { upLoadAssets } from './uploadAsset';

import { default as FigmaLogo } from './assets/figma_logo.svg';

const strings = translations[getLangKey()];

const FigmaFeedback = ({ project }: { project: Project }) => {
  document.title = 'Figma feedback | BugHerd';

  const { onUploadChange, isLoading, uploadProgress } = upLoadAssets(project);

  const getFigmaFile = () => {
    fetch(figmaSampleFile)
      .then(response => response.blob())
      .then(blob => {
        const mockFile = new File([blob], 'Sample Figma file', {
          type: 'image/png'
        });

        const mockEvent = {
          file: {
            originFileObj: mockFile
          },
          fileList: [] // Add an empty fileList array property
        };

        onUploadChange(mockEvent, true);
      });
  };

  const handleFigmaSampleFile = () => {
    getFigmaFile();
  };

  return (
    <div className={styles.container}>
      <div className={styles.flexContainerWrapper}>
        <div className={styles.contentWrapper}>
          <p>{strings.stepOne}</p>
          <h2>{strings.importFigma}</h2>
          <p>{strings.sampleDesign}</p>
          <div className={styles.stepCard} onClick={handleFigmaSampleFile}>
            {isLoading && uploadProgress >= 0 ? (
              <div className={styles.figmaProgress}>
                <Progress
                  strokeLinecap="butt"
                  type="circle"
                  percent={uploadProgress}
                />
                <p>{strings.loadingFigmaFile}</p>
              </div>
            ) : (
              <>
                <img height={100} width={100} src={figmaCard} />
                <div className={styles.stepCardWrapper}>
                  <h4>{strings.sample}</h4>
                  <Button icon={<FigmaLogo />} className={styles.figmaButton}>
                    FIGMA
                  </Button>
                </div>
              </>
            )}
          </div>
          <Link to="/try-bugherd-on" type="button">
            <Button type="text" className={styles.backButton}>
              {strings.back}
            </Button>
          </Link>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            loading="eager"
            src={figmaFeedback}
            placeholder
            alt="Give feedback on Figma with BugHerd"
            preview={false}
          />
        </div>
      </div>
    </div>
  );
};

export default FigmaFeedback;
