import React, { FC, useState } from 'react';
import { Dropdown, Menu, Radio } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import ToolbarButton from '../ToolbarButton';

export type Priority = { name: string; id: 0 | 1 | 2 | 3 | 4 };

type Props = {
  priorities: Priority[];
  getContainer: () => HTMLDivElement;
  onChange: (severity: Priority) => void;
  severity?: Priority;
};

const BulkSeverity: FC<Props> = ({
  priorities,
  getContainer,
  severity,
  onChange
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Dropdown
      className={styles.severityDropdown}
      trigger={['click']}
      getPopupContainer={getContainer}
      onOpenChange={setVisible}
      open={visible}
      placement="top"
      arrow={{ pointAtCenter: true }}
      overlayClassName={styles.bulkSeverityOverlay}
      overlay={
        <Menu className={styles.severityMenu}>
          {priorities.map(({ name, id }) => {
            return (
              <Menu.Item
                key={`${name}${id}`}
                className={cx(
                  styles.severityMenuItem,
                  styles[`severity-${id}`],
                  { [styles.checked]: severity?.id === id }
                )}
                onClick={() => {
                  onChange({ name, id });
                  setVisible(false);
                }}
              >
                <Radio className={styles.radio} checked={severity?.id === id}>
                  <span className={styles.label}>{name}</span>
                </Radio>
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <ToolbarButton
        open={visible}
        onClick={() => setVisible(!visible)}
        label={severity?.name || 'Severity'}
        active={!!severity}
        className={cx({ [styles[`severityButton-${severity?.id}`]]: severity })}
      />
    </Dropdown>
  );
};

export default BulkSeverity;
