import React from 'react';
import cx from 'classnames';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
const strings = translations[getLangKey()];
import Iframe from './../shared/Iframe';
import MobileHeader from './../shared/MobileHeader';
import RegistrationForm from './RegistrationForm';

export default ({
  sitekey,
  cookies,
  authenticityToken,
  isReviewApp
}: {
  sitekey: string;
  cookies: { [key: string]: string };
  authenticityToken: string;
  isReviewApp: boolean;
}) => {
  return (
    <>
      <MobileHeader />
      <div className={styles.registerWrapper}>
        <div className={styles.formWebsiteWrapper}>
          <div className={styles.formWrapper}>
            <h1 className={styles.title}>{strings.freeTrialToday}</h1>
            <RegistrationForm
              sitekey={sitekey}
              authenticityToken={authenticityToken}
              isReviewApp={isReviewApp}
              cookies={cookies}
            />
          </div>
          <div className={cx(styles.backToWebsite, styles.hideOnMobile)}>
            <a href="https://bugherd.com">{strings.backToWebsite}</a>
          </div>
        </div>
        <Iframe />
      </div>
    </>
  );
};
