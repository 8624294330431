import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Modal, Button } from 'antd';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
const strings = translations[getLangKey()];
import SocialLogin from '../SocialLogin';
import RegistrationForm from '../sign_up_email/RegistrationForm';
import { fetchWithTimeout } from 'utils/fetch';

type Props = {
  authenticityToken: string;
  sitekey: string;
  isReviewApp: boolean;
  cookies: { [key: string]: string };
};

const SignupCaptureUrl = ({
  authenticityToken,
  sitekey,
  isReviewApp,
  cookies
}: Props) => {
  const location = useLocation();
  const queryString = location.search;
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [landingCaptureUrl, setLandingCaptureUrl] = useState('');

  const params = new URLSearchParams(queryString);
  const tryBugherdUrl = params.get('landing_capture_url') || '';
  const decodedCaptureUrl = decodeURIComponent(tryBugherdUrl);
  const embedFallbackUrl = 'https://bugherd.com/embed';

  const fetchIframe = async () => {
    try {
      const response = await fetchWithTimeout(
        `/check_cors_in_csp?src=${decodedCaptureUrl}`
      );
      if (response?.statusText === 'ok' || response?.status === 200) {
        setLandingCaptureUrl(decodedCaptureUrl);
        setIsLoading(false);
      } else {
        setLandingCaptureUrl(embedFallbackUrl);
        setIsLoading(false);
      }
      return response;
    } catch (error) {
      setLandingCaptureUrl(embedFallbackUrl);
      setIsLoading(false);
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    fetchIframe();
  }, []);

  return (
    <>
      <div id="iframeContainer" className={styles.iframeContainer}>
        {!isLoading && (
          <iframe src={landingCaptureUrl} className={styles.fullWidthIframe} />
        )}
      </div>

      <div className={styles.registerWrapper}>
        <Modal
          open
          wrapClassName={`antd-container`}
          className={`${styles.captureModal} ${
            showRegistrationForm ? styles.registrationModal : ''
          }`}
          closable={false}
          title={null}
          footer={null}
          centered
        >
          <div className={styles.formWebsiteWrapper}>
            {!showRegistrationForm && (
              <div className={styles.formWrapper}>
                <SocialLogin authenticityToken={authenticityToken} />
                <div className={styles.or}>
                  <span>{strings.or}</span>
                </div>

                <Button
                  onClick={() => setShowRegistrationForm(true)}
                  className={styles.signUpButton}
                >
                  {strings.signUpWithEmail}
                </Button>

                <div className={styles.accountLink}>
                  {strings.alreadyHaveAnAccount}
                  <a href="/users/sign_in">Log in</a>
                </div>
              </div>
            )}
          </div>

          {showRegistrationForm && (
            <>
              <h2>Start a free trial to continue</h2>
              <RegistrationForm
                sitekey={sitekey}
                cookies={cookies}
                authenticityToken={authenticityToken}
                isReviewApp={isReviewApp}
                hideSocialButtons
                landingCaptureUrl={decodedCaptureUrl}
              />
            </>
          )}
        </Modal>
      </div>
    </>
  );
};

export default ({
  authenticityToken,
  sitekey,
  isReviewApp,
  cookies
}: Props) => (
  <Router>
    <SignupCaptureUrl
      authenticityToken={authenticityToken}
      sitekey={sitekey}
      isReviewApp={isReviewApp}
      cookies={cookies}
    />
  </Router>
);
