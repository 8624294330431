import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Alert } from 'antd';
import PropTypes from 'prop-types';
import { antdForm, userType, locationType } from '../../../utils/propTypes';

import styles from '../welcome/form.module.css';

class SignupForm extends React.Component {
  static propTypes = {
    form: antdForm,
    user: userType,
    endpoints: PropTypes.shape({
      login: PropTypes.string.isRequired
    }).isRequired,
    authenticityToken: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    location: locationType
  };

  state = {
    loading: false,
    error: null
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      loading: true,
      error: null
    });

    fetch(this.props.endpoints.login, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        utf8: '✓',
        authenticity_token: this.props.authenticityToken,
        user: {
          password: this.props.form.getFieldsValue(['password']).password,
          email: this.props.user.email,
          remember_me: 1
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(body => {
        if (body.error && body.error.message) {
          throw Error(body.error.message);
        } else {
          this.props.navigate('/extension' + this.props.location.search);
        }
      })
      .catch(({ message }) => {
        this.setState({
          loading: false,
          error: message
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        layout="vertical"
        hideRequiredMark
        className={styles.form}
        onSubmit={this.handleSubmit}
      >
        {!!this.state.error && (
          <Alert message={this.state.error} type="error" showIcon />
        )}
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: this.props.user.email,
            rules: [
              {
                required: true,
                message: 'Email is required'
              }
            ]
          })(
            <Input
              type="email"
              disabled
              onChange={event =>
                this.props.form.setFieldsValue({
                  email: event.target.value
                })
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Password is required'
              }
            ]
          })(
            <Input
              type="password"
              onChange={event =>
                this.props.form.setFieldsValue({
                  password: event.target.value
                })
              }
            />
          )}
        </Form.Item>
        <div className={styles.actions}>
          <Button className={styles.button} type="primary" htmlType="submit">
            Log in
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(SignupForm);
