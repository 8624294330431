import * as React from 'react';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash-es';

import { getData, setData, UA_MY_COMMENT_PAGE_SIZE } from 'models/LocalStorage';
import {
  GET_USER_COMMENTS,
  GetUserCommentsData as Data,
  GetUserCommentsVars as Vars
} from '../queries';
import { setupPusher, teardownPusher } from '../pusher';
import { UserComment, USER_COMMENTS_PAGE_SIZE } from '../types';

const debouneSearchWait = 500;

const useUserCommentPageSize = (): [number, (size: number) => void] => {
  const [userCommentPageSize, _setUserCommentsPageSize] = React.useState<
    number
  >(getData(UA_MY_COMMENT_PAGE_SIZE) || USER_COMMENTS_PAGE_SIZE);

  const setUserCommentsPageSize = (size: number) => {
    _setUserCommentsPageSize(size);
    setData(UA_MY_COMMENT_PAGE_SIZE, size);
  };

  return [userCommentPageSize, setUserCommentsPageSize];
};

export const useState = ({ organizationId }: { organizationId: string }) => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  const [searchText, setSearchText] = React.useState<string>('');
  const [filterText, setFilterText] = React.useState<{ text: string }>();

  const [pusherUpdated, setPusherUpdated] = React.useState<boolean>(false);

  const [userComments, setUserComments] = React.useState<UserComment[]>([]);

  const [
    userCommentsPageSize,
    setUserCommentsPageSize
  ] = useUserCommentPageSize();

  const { data, loading, error, refetch } = useQuery<Data, Vars>(
    GET_USER_COMMENTS,
    {
      variables: {
        organizationId,
        offset: (pageNumber - 1) * userCommentsPageSize,
        limit: userCommentsPageSize,
        filter: filterText
      }
    }
  );

  React.useEffect(() => {
    if (loading) return;

    if (data) {
      setUserComments(data.currentUser.userComments);
      setPusherUpdated(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (!data) return;

    const projects = data.currentUser.userComments.map(
      ({ project }) => project
    );

    setupPusher({
      config: data.config,
      projects,
      onUpdate: () => setPusherUpdated(!pusherUpdated)
    });

    return () =>
      teardownPusher({
        config: data.config,
        projects
      });
  }, [data]);

  const debounceSearch = React.useCallback(
    debounce((searchText: string) => {
      setPageNumber(1);
      if (!searchText) {
        setFilterText(undefined);
      } else {
        setFilterText({ text: searchText });
      }
    }, debouneSearchWait),
    []
  );

  React.useEffect(() => {
    debounceSearch.cancel();
    debounceSearch(searchText);
  }, [searchText]);

  return {
    pageNumber,
    searchText,
    pusherUpdated,
    setPageNumber,
    setSearchText,
    userComments,
    userCommentsPageSize,
    setUserCommentsPageSize,
    getUserComments: { data, loading, error, refetch }
  };
};
