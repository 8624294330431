import * as React from 'react';

import MultiSelectList from '../../MultiSelectList';
import ErrorRetry from '../../MultiSelectList/ErrorRetry';

import useRequesterFilter from './hooks/useRequesterFilter';

type Props = {
  organizationId: string;
  value: string[];
  onChange: (value: string[]) => void;
};

const RequesterFilter: React.FC<Props> = ({
  organizationId,
  value,
  onChange
}) => {
  const {
    selectOption,
    fetchNextPage,
    setSearchByName,
    loading,
    error,
    refetch
  } = useRequesterFilter({ organizationId });

  if (error) return <ErrorRetry onClick={refetch} />;

  return (
    <MultiSelectList
      options={selectOption}
      isLoading={loading}
      onSelectChange={onChange}
      selectedValue={value}
      fetchMore={fetchNextPage}
      onSelectSearch={setSearchByName}
    />
  );
};

export default RequesterFilter;
