export type Integration = {
  id?: string;
  name?: string;
  url?: string;
  error?: {
    code: number;
    message: string;
    created_at: string;
  };
  parent?: {
    id: string;
    url: string;
  };
};

export type ParagonIntegration = {
  [key: string]: Integration;
};

const handleObjectFormat = (keysAndValues: string[]): ParagonIntegration => {
  keysAndValues = keysAndValues
    .filter(integration => !!integration)
    .map(integration => integration.replace('  ', ''));

  const integrationKeys = keysAndValues
    .filter(integration => integration.endsWith(':'))
    .map(integration => integration.replace(':', ''));

  return integrationKeys.reduce((prev, current, index) => {
    if (!current) return prev;
    const integrationIndex = keysAndValues.findIndex(integration =>
      integration.includes(`${current}:`)
    );
    const nextIntegrationIndex = keysAndValues.findIndex(integration =>
      integration.includes(`${integrationKeys[index + 1]}:`)
    );
    const integrationFieldsAndValues = keysAndValues.slice(
      integrationIndex + 1,
      nextIntegrationIndex < 0 ? keysAndValues.length : nextIntegrationIndex
    );

    const formattedIntegrationFields = integrationFieldsAndValues.reduce(
      (prevFieldAndValue, currentFieldAndValue) => {
        if (!currentFieldAndValue) return prevFieldAndValue;
        const field = currentFieldAndValue.split(': ')[0];
        const value = currentFieldAndValue.split(': ')[1];
        return {
          ...prevFieldAndValue,
          [field]: value
        };
      },
      {}
    );

    return {
      ...prev,
      [current]: formattedIntegrationFields
    };
  }, {});
};

export default (
  paragonIntegration: ParagonIntegration | string | null
): ParagonIntegration => {
  if (paragonIntegration && typeof paragonIntegration === 'string') {
    const isObjectFormat = paragonIntegration.includes(':\n');
    const keysAndValues: string[] = paragonIntegration
      .replace('---', '')
      .split('\n');

    if (isObjectFormat) {
      return handleObjectFormat(keysAndValues);
    }

    // @ts-ignore
    return keysAndValues.reduce((prev, current) => {
      if (!current) return prev;
      const keyAndName = current.split(': ');
      return {
        // @ts-ignore
        ...prev,
        [keyAndName[0]]: {
          id: keyAndName[1]?.replace(/\'/g, ''),
          name: '',
          url: '',
          error: {}
        }
      };
    }) as ParagonIntegration;
  }
  return paragonIntegration as ParagonIntegration;
};
