import { toUnicode } from 'punycode/punycode';

export const getUrlProtocol = url => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl) {
      return parsedUrl.protocol + '//';
    }
    return 'https://';
  } catch (e) {
    return 'https://';
  }
};

export const stripUrlSearchAndHash = (url: string) => {
  const parsedURL = new URL(url);
  parsedURL.search = '';
  parsedURL.hash = '';
  return parsedURL.toString();
};

export const stripUrlProtocol = value => {
  if (
    value &&
    (value.indexOf('https://') >= 0 || value.indexOf('http://') >= 0)
  ) {
    return value.slice(value.indexOf('://') + 3, value.length);
  }
  return value;
};

export const decodeURLAndStripProtocol = value => {
  try {
    const parsedURL = new URL(value);

    const decodedHostname = decodeURIComponent(toUnicode(parsedURL.hostname));
    const decodedURL = decodeURIComponent(
      parsedURL.pathname + parsedURL.search + parsedURL.hash
    );
    const port = parsedURL.port && ':' + parsedURL.port;

    return decodedHostname + port + decodedURL;
  } catch {
    //todo: this needs to goto bugsnag for triaging
    const stripProtocol = stripUrlProtocol(value);
    if (!stripProtocol) return '';
    try {
      return decodeURIComponent(stripProtocol);
    } catch {
      return stripProtocol;
    }
  }
};

export const encodeURL = value => {
  try {
    const parsedURL = new URL(value);
    return parsedURL.toString();
  } catch {
    //todo: this needs to goto bugsnag for triaging
    return value;
  }
};
