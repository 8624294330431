import React, { useState, useEffect } from 'react';
import { checkForExtension } from 'utils/checkForBugherdExtension';

import styles from './../index.module.css';
import { message } from 'antd';
import { default as WebsitesLogo } from './../assets/website_icon.svg';
import InstallExtension from './InstallExtension';
import { useProjectSitesState } from '../../providers/ProjectSites';
import { useOrganizationState } from '../../../../clients/providers/Organization';
import { useDesignAssetsState } from '../../providers/DesignAssets';
import ProjectSite from './ProjectSite';
import { post } from 'appJS/utils/fetch';

export const WebsitesScreen = () => {
  const projectBoardProps = window?.project_board_props;
  const project = projectBoardProps?.assetFeedbackProps?.project;
  const projectId = project?.id;
  const [loadingCoverUrls, setLoadingCoverUrls] = useState({});

  const {
    projectSites,
    handleGetProjectSites,
    proxyHost
  } = useProjectSitesState();
  const {
    isNoLoginGuestExperienceEnabled,
    onSharingClick,
    isGuest
  } = useDesignAssetsState();
  const { hasProxyEnabledExperiment } = useOrganizationState();
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );

  const container =
    document.getElementById('designAssetsBoard') || document.body;
  const apiKey = project?.apiKey;

  const getCoverImage = async id => {
    try {
      await post(`/projects/${projectId}/generate_cover?site_id=${id}`, {
        headers: { 'Content-Type': 'application/json; charset=utf-8' }
      });
      setLoadingCoverUrls(prevState => ({ ...prevState, [id]: true }));
      message.success(
        'Your cover screenshot is being created, and will appear here shortly'
      );
      setTimeout(() => {
        handleGetProjectSites();
        setLoadingCoverUrls(prevState => ({ ...prevState, [id]: false }));
      }, 6000);
    } catch (e) {
      console.error(e);
      message.error('Something went wrong, please try again.');
    }
  };

  const sortedProjectSites = projectSites.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  );

  useEffect(() => {
    (async () => {
      try {
        const exists = await checkForExtension();
        if (exists) {
          setExtensionInstalled(exists);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const showInstallBh =
    !hasExtensionInstalled &&
    !hasProxyEnabledExperiment &&
    project.openTasksCount === 0;

  return (
    <div className={styles.websiteWrapper}>
      {showInstallBh && (
        <InstallExtension
          showInstallBh={showInstallBh}
          showJavascriptOption={!isGuest}
        />
      )}
      <div className={styles.titleWrapper}>
        <WebsitesLogo />
        <h2>Websites</h2>
      </div>
      <div
        className={styles.websiteCards}
        data-cy="websiteCards"
        id="websiteCards"
      >
        {sortedProjectSites.map((site, index) => (
          <ProjectSite
            key={site.id}
            site={site}
            index={index}
            project={project}
            apiKey={apiKey}
            proxyHost={proxyHost}
            container={container}
            isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
            showProxyPreview={hasProxyEnabledExperiment}
            loadingCoverUrl={loadingCoverUrls[site.id]}
            getCoverImage={() => getCoverImage(site.id)}
            onSharingClick={() => onSharingClick({ siteId: site.id })}
          />
        ))}
      </div>
    </div>
  );
};
