import React, { FC, useState } from 'react';
import { Button, message, Popover } from 'antd';
import styles from './styles.module.css';
import { CloseModal } from 'appJS/components/CloseModal';
import { post } from 'appJS/utils/fetch';
import { MoreActionsDropdown } from './MoreActionsDropdown';
import { MoveToFolderDropdown } from './MoveToFolderDropdown';

type Props = {
  selectedProjects: { id: string; name: string }[];
  setSelectedProjects: (
    selectedProjects: { id: string; name: string }[]
  ) => void;
  projectFoldersAllowed: boolean;
  projectFolders: {
    id: string;
    name: string;
    subfolders: {
      id: string;
      name: string;
    }[];
  }[];
  organizationId: string;
  container: HTMLDivElement;
  refetchProjects: () => void;
  refetchCurrentUser: () => void;
  setCurrentStep: (currentStep: number) => void;
};

type Loading = 'add-to-folder' | 'more-actions' | false;

export type UpdateParams = {
  [key: string]: boolean | null | string;
};

export const ProjectFolderBar: FC<Props> = ({
  selectedProjects,
  setSelectedProjects,
  projectFoldersAllowed,
  projectFolders,
  organizationId,
  container,
  refetchProjects,
  setCurrentStep,
  refetchCurrentUser
}) => {
  const [loading, setLoading] = useState<Loading>(false);
  const [selectedFolder, setSelectedFolder] = useState('');

  const resetState = () => {
    setSelectedFolder('');
    setSelectedProjects([]);
  };

  const updateMessage = (params: UpdateParams) => {
    const key = Object.keys(params)[0];
    if (key === 'delete') return 'deleted';
    if (key === 'project_folder_id')
      return `${params[key] ? 'added to' : 'removed from'} folder`;
    if (key === 'show_on_live') return params[key] ? 'enabled' : 'disabled';
  };

  const handleUpdate = async (params: UpdateParams) => {
    try {
      const response = await post(
        `/organizations/${organizationId}/update_projects`,
        { ...params, project_ids: selectedProjects.map(({ id }) => id) }
      );
      if (response.message) {
        setLoading(false);
        message.success(`Projects ${updateMessage(params)} successfully`);
        refetchProjects();
        refetchCurrentUser();
        resetState();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error('Project were not updated, please try again');
      console.error(error);
    }
  };

  return projectFoldersAllowed && selectedProjects.length ? (
    <div className={styles.projectFolderBar}>
      <Popover
        getPopupContainer={() => container}
        placement="top"
        trigger={['click', 'hover']}
        content={
          <div className={styles.selectedProjectsList}>
            {selectedProjects.map(({ id, name }) => (
              <div key={id} className={styles.selectedProject} title={name}>
                {name}
              </div>
            ))}
          </div>
        }
      >
        <span className={styles.selectedProjects}>
          {selectedProjects.length} project(s) selected
        </span>
      </Popover>
      <MoveToFolderDropdown
        projectFolders={projectFolders}
        setSelectedFolder={setSelectedFolder}
        selectedFolder={selectedFolder}
        container={container}
        setCurrentStep={setCurrentStep}
      />
      <MoreActionsDropdown
        container={container}
        loading={loading === 'more-actions'}
        handleUpdate={(params: UpdateParams) => {
          setLoading('more-actions');
          handleUpdate(params);
        }}
        setSelectedFolder={setSelectedFolder}
      />
      <Button
        type="primary"
        className={styles.updateButton}
        loading={loading === 'add-to-folder'}
        onClick={() => {
          setLoading('add-to-folder');
          handleUpdate({ project_folder_id: selectedFolder });
        }}
        disabled={!selectedFolder}
      >
        Update
      </Button>
      <CloseModal className={styles.closeModal} handleClick={resetState} />
    </div>
  ) : null;
};
