import React, { FC } from 'react';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { ChevronDown as Down } from 'lucide-react';
import { Column } from '../../../../../models/Project';
import styles from './styles.module.css';
import cx from 'classnames';

type Props = {
  columns: Column[];
  column: Column;
  updateStatus: (
    columnOrStatus:
      | Column
      | { statusId: number | null; columnId: number | null }
  ) => void;
  container: HTMLDivElement;
  taskEditStatus: boolean;
  isNewSidebar?: boolean;
  isAdminView?: boolean;
};

const formatMarkAsLabel = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

const MarkAs: FC<Props> = ({
  columns,
  column,
  updateStatus,
  container,
  taskEditStatus,
  isNewSidebar,
  isAdminView
}) => {
  const handleMoveMenuItemOnClick = (col: Column) => {
    const { columnId, statusId } = col;
    return isNewSidebar
      ? updateStatus({ columnId, statusId })
      : updateStatus(col);
  };

  const getMoveMenuItems = (): MenuProps['items'] =>
    columns.map(col => {
      if (!col.name) throw new Error('Undefined column name');
      return {
        key: `${col.columnId}-${col.statusId}`,
        label: formatMarkAsLabel(col.name)
      };
    });

  const findColumnFromKey = (key: string) => {
    const column = columns.find(
      (col: Column) => key === `${col.columnId}-${col.statusId}`
    );
    if (!column) throw new Error('cant find column to move to');
    return column;
  };

  const menuProps: MenuProps = {
    items: getMoveMenuItems(),
    selectable: true,
    defaultSelectedKeys: [`${column.columnId}-${column.statusId}`],
    onClick: info => {
      handleMoveMenuItemOnClick(findColumnFromKey(info.key));
    },
    className: styles.markAsDropdown
  };

  const getNextStatus = () => {
    const lastIndex = columns.length - 1;
    const currentIndex = columns.findIndex(
      (col: Column) =>
        col.columnId === column.columnId && col.statusId === column.statusId
    );
    const nextIndex = currentIndex === lastIndex ? 1 : currentIndex + 1;
    if (nextIndex < lastIndex) {
      return columns[nextIndex];
    }
    return columns[lastIndex];
  };

  const markAsLabel = `Mark as ${formatMarkAsLabel(
    getNextStatus().name || ''
  )}`;

  return (
    <Tooltip
      className={styles.tooltip}
      title={
        taskEditStatus
          ? markAsLabel
          : 'You do not have permission to change the status of this task.'
      }
      getPopupContainer={() => container}
      overlayClassName={cx(styles.tooltipOverlay, {
        [styles.adminTooltipOverlay]: isAdminView
      })}
    >
      <Dropdown.Button
        type="primary"
        getPopupContainer={() => container}
        menu={menuProps}
        disabled={!taskEditStatus}
        onClick={() => {
          handleMoveMenuItemOnClick(getNextStatus());
        }}
        trigger={['click']}
        icon={<Down />}
        className={cx(styles.markAsButton, {
          [styles.adminMarkAsButton]: isAdminView
        })}
      >
        {markAsLabel}
      </Dropdown.Button>
    </Tooltip>
  );
};

export default MarkAs;
