export const en = {
  screenshotCaptured: 'Screenshot captured',
  videoCaptured: 'Video captured',
  annotate: 'Annotate',
  reviewVideo: 'Review video',
  why: 'Why?',
  previewScreenshot: 'Show screenshot'
};

export type Type = typeof en;
