import React from 'react';
import { FolderOutput, SquareCheckBig, Ban, Trash2 } from 'lucide-react';
import styles from './styles.module.css';
import cx from 'classnames';

export const moreActionsItems = [
  {
    key: 'remove-folder',
    icon: <FolderOutput className={styles.removeFolderIcon} />,
    label: 'Remove from folder',
    className: styles.menuItem
  },
  {
    key: 'divider',
    type: 'divider'
  },
  {
    key: 'enable',
    icon: <SquareCheckBig className={styles.enableIcon} />,
    label: 'Enable',
    className: cx(styles.menuItem, styles.enableMenuItem)
  },
  {
    key: 'disable',
    icon: <Ban className={styles.disableIcon} />,
    label: 'Disable',
    className: cx(styles.menuItem, styles.disableMenuItem)
  },
  {
    key: 'delete',
    icon: <Trash2 className={styles.deleteIcon} />,
    label: 'Delete',
    className: cx(styles.menuItem, styles.deleteMenuItem)
  }
];
