import React, { useState, FC, useEffect } from 'react';
import styles from './index.module.css';
import { Dropdown, Menu, message } from 'antd';
import {
  Share,
  MoreHorizontal,
  Edit,
  Trash,
  Archive,
  Group,
  Ungroup
} from 'lucide-react';
import cx from 'classnames';
import { put, deleteItem } from 'appJS/utils/fetch';
import { DesignAsset } from './types';
import track from 'appJS/utils/analytics';

type Props = {
  container: HTMLDivElement;
  asset: DesignAsset;
  assetEndpoint: string;
  onRenameClick: () => void;
  groupNames: string[];
  canManageAssets: boolean;
  disabled: boolean;
  setData: (data: DesignAsset[]) => void;
  handleSharingClick: () => void;
  onAddToGroup?: (groupName: string, assetId: number) => void;
  openNewGroupModal?: () => void;
};

const AssetMenu: FC<Props> = ({
  container,
  asset,
  assetEndpoint,
  onRenameClick,
  groupNames,
  canManageAssets,
  disabled,
  setData,
  handleSharingClick,
  onAddToGroup,
  openNewGroupModal
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleMenuClick = ({ key }) => {
    if (key === 'delete' && confirmDelete) setIsMenuOpen(true);
  };

  const handleArchive = async () => {
    const response = await put(assetEndpoint, { status: 'archived' });
    if (response.status === 'ok') {
      message.info('Your asset has been archived.');
      track('Archived asset');
    } else {
      message.error('Something went wrong, please try again.');
    }
  };

  const handleRestore = async () => {
    const response = await put(assetEndpoint, { status: 'created' });
    if (response.status === 'ok') {
      message.info('Your asset has been restored.');
    } else {
      message.error('Something went wrong, please try again.');
    }
  };

  const handleDelete = async () => {
    if (confirmDelete) {
      const response = await deleteItem(assetEndpoint);
      if (response.status === 'ok') {
        message.info('Your asset has been deleted.');
        setConfirmDelete(false);
        // @ts-expect-error
        setData((previousValue: DesignAsset[]): DesignAsset[] =>
          previousValue.filter(
            ({ id }) => id.toString() !== assetEndpoint.split('/').slice(-1)[0]
          )
        );
      } else if (response.status === 'asset not found') {
        message.info('Your asset has already been deleted.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      setConfirmDelete(true);
    }
  };

  const removeFromGroup = async () => {
    const previousGroupName = asset.groupName;
    const response = await put(assetEndpoint, { remove_group_name: true });
    if (response.status === 'ok') {
      message.success(
        `Asset has been removed from group: ${previousGroupName}`
      );
    } else {
      message.error('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    if (confirmDelete) {
      setIsMenuOpen(true);
    }
  }, [confirmDelete]);

  const handleVisibleChange = (visible: boolean) => {
    setIsMenuOpen(visible);
  };

  if (!canManageAssets) {
    return null;
  }

  const menuItems = [
    {
      key: 'share',
      show: !disabled,
      icon: <Share className={styles.shareIcon} />,
      text: 'Share',
      onClick: handleSharingClick
    },
    {
      key: 'move',
      show: !disabled,
      icon: <Group className={styles.groupIcon} />,
      title: 'Add to group',
      type: 'submenu',
      items: (
        <>
          {groupNames
            .filter(groupName => groupName !== asset.groupName)
            .map(groupName => (
              <Menu.Item
                key={groupName}
                onClick={() => {
                  onAddToGroup?.(groupName, asset.id);
                }}
              >
                {groupName}
              </Menu.Item>
            ))}
          <Menu.Item key="create" onClick={() => openNewGroupModal?.()}>
            Create new group
          </Menu.Item>
        </>
      )
    },
    {
      key: 'remove',
      show: !disabled && asset.groupName,
      icon: <Ungroup className={styles.ungroupIcon} />,
      text: 'Remove from group',
      onClick: removeFromGroup
    },
    {
      key: 'rename',
      show: !disabled,
      icon: <Edit className={styles.editIcon} />,
      text: 'Rename',
      onClick: onRenameClick
    },
    {
      key: 'archive',
      show: !disabled,
      icon: <Archive className={styles.archiveIcon} />,
      text: 'Archive',
      onClick: handleArchive
    },
    {
      key: 'restore',
      show: disabled,
      icon: <Archive className={styles.archiveIcon} />,
      text: 'Unarchive',
      onClick: handleRestore
    },
    {
      key: 'delete',
      show: true,
      icon: <Trash className={styles.trashIcon} />,
      text: confirmDelete ? 'Really Delete?' : 'Delete',
      onClick: handleDelete
    }
  ];

  return (
    <>
      <Dropdown
        className={styles.assetDropdown}
        trigger={['click']}
        getPopupContainer={() => container}
        onOpenChange={handleVisibleChange}
        open={isMenuOpen}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        overlayClassName={styles.overlay}
        overlay={
          <Menu onClick={handleMenuClick} className={styles.menu}>
            {menuItems
              .filter(({ show }) => show)
              .map(({ key, title, type, items, icon, text, onClick }) =>
                type === 'submenu' ? (
                  <Menu.SubMenu
                    key={key}
                    title={title}
                    className={styles.menuInner}
                    icon={icon}
                  >
                    {items}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={key}
                    className={styles.menuItem}
                    onClick={onClick}
                  >
                    <div className={styles.menuInner}>
                      {icon}
                      {text}
                    </div>
                  </Menu.Item>
                )
              )}
          </Menu>
        }
      >
        <MoreHorizontal
          onClick={event => event.preventDefault()}
          className={cx(styles.moreIcon, { [styles.active]: isMenuOpen })}
        />
      </Dropdown>
    </>
  );
};

export default AssetMenu;
