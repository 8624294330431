import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import cx from 'classnames';
import camelcaseKeys from 'camelcase-keys';
import NavBar from '../design_feedback/NavBar';
import SizePicker from './SizePicker';
import { Spin } from 'antd';
import track from 'jsUtilities/analytics';

type Props = {
  projectSite: any;
  project: any;
  proxyHost: string;
  viewKanbanProject: boolean;
  bugherdUrl: string;
  loggedIn: boolean;
  hasAccess: boolean;
  showOnboardingDemo: boolean;
  loginless: boolean;
};

let taskPath = '';

if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search || '');
  const taskPathParam = urlParams.get('task_path');

  if (taskPathParam) {
    history.pushState(null, '', location.href.split('?')[0]);

    taskPath = decodeURIComponent(taskPathParam);
  }
}

const SiteFeedback: FC<Props> = ({
  projectSite,
  project,
  proxyHost,
  viewKanbanProject,
  loggedIn,
  hasAccess,
  showOnboardingDemo,
  loginless
}) => {
  const proxyUrl = `https://${project.api_key}_${projectSite.id}.${proxyHost}${taskPath}`;

  project = camelcaseKeys(project, { deep: true });
  const [layout, setLayout] = useState('desktop');
  const [sidebarLoading, setSidebarLoading] = useState(true);

  window.addEventListener('message', event => {
    if (event.data.action === 'navigate') {
      window.location.replace(event.data.url);
    }
    if (event.data.action === 'hard_navigate') {
      window.location.href = event.data.url;
    }
    if (event.data.message === 'Sidebar is loaded') {
      setSidebarLoading(false);
    }
  });

  const handleLayoutChange = (newLayout: string) => {
    setLayout(newLayout);
  };

  useEffect(() => {
    //failsafe to hide loader after 5 seconds
    const timer = setTimeout(() => {
      setSidebarLoading(false);
    }, 5000);

    track('Viewed embedded proxy site', {
      project_id: project.id,
      project_site_id: projectSite.id
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <div
        className={cx(styles.pageHeader, {
          [styles.disableInteraction]: showOnboardingDemo && !loginless
        })}
      >
        <NavBar
          project={project}
          fileName={projectSite.host}
          viewKanbanProject={viewKanbanProject}
          loggedIn={loggedIn}
          hasAccess={hasAccess}
        />
        <SizePicker layout={layout} handleLayout={handleLayoutChange} />
        {showOnboardingDemo && !sidebarLoading && !loginless && (
          <div className={styles.onboardingOverlay} />
        )}
      </div>
      {sidebarLoading && (
        <Spin
          className={styles.loader}
          wrapperClassName={styles.loaderWrapper}
          fullscreen
          tip={
            <>
              <h3>
                Loading the BugHerd sidebar <br /> into your website.
              </h3>
              <p>Hold on tight.</p>
            </>
          }
          size="large"
        />
      )}
      <div className={`${styles.iframeWrapper} ${layout}`}>
        <iframe
          src={proxyUrl}
          title="Preview"
          className={styles.iframeContainer}
          allow="display-capture"
        />
      </div>
    </div>
  );
};

export default SiteFeedback;
