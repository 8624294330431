import { differenceInCalendarDays } from 'date-fns';

export default (collection, sortAttribute) => {
  const assigneeGroups = [];

  collection.forEach(task => {
    let taskAssignees = task.getAssignees();

    // Filter out [null] i.e. unassigned
    taskAssignees = taskAssignees.filter(a => a !== null);

    if (taskAssignees.length) {
      const names = taskAssignees
        .map(assignee => assignee.get('name'))
        .join(', ');

      if (!assigneeGroups.find(g => g.title === names)) {
        assigneeGroups.push({
          title: names,
          ids: task.get('assignee_ids'),
          tasks: []
        });
      }
    }
  });

  const groupSchema = [
    {
      attributes: ['priority_id'],
      groups: [
        { title: 'minor', id: 4, tasks: [] },
        { title: 'normal', id: 3, tasks: [] },
        { title: 'important', id: 2, tasks: [] },
        { title: 'critical', id: 1, tasks: [] },
        { title: 'not set', id: 0, tasks: [] }
      ],
      grouper: (group, task) => {
        return task.priority_id === group.id;
      }
    },
    {
      attributes: ['assignee_ids'],
      groups: [
        { title: 'Unassigned', ids: [null], tasks: [] },
        ...assigneeGroups
      ],
      grouper: (group, task) => {
        return (
          JSON.stringify(task.assignee_ids.sort()) === JSON.stringify(group.ids)
        );
      }
    },
    {
      attributes: ['created_at', 'due_at', 'updated_at'],
      groups: [
        // since groups get stringified, passing Infinity becomes null
        // think about how to do this better than 1000000000000
        {
          title: 'futuristic',
          maxDays: 1000000000000,
          minDays: 366,
          tasks: [],
          future: true
        },
        {
          title: 'next year',
          maxDays: 365,
          minDays: 31,
          tasks: [],
          future: true
        },
        {
          title: 'next 30 days',
          maxDays: 30,
          minDays: 8,
          tasks: [],
          future: true
        },
        {
          title: 'next 7 days',
          maxDays: 7,
          minDays: 1,
          tasks: [],
          future: true
        },
        { title: 'today', maxDays: 0, tasks: [] },
        {
          title: 'last 7 days',
          maxDays: 6,
          tasks: []
        },
        {
          title: 'last 30 days',
          maxDays: 29,
          tasks: []
        },
        {
          title: 'older',
          maxDays: 364,
          tasks: []
        },
        {
          title: 'prehistoric',
          maxDays: 1000000000000,
          tasks: []
        }
      ],
      grouper: (group, task) => {
        const taskDate = new Date(task[sortAttribute]);
        const now = new Date();
        const diffInDays = differenceInCalendarDays(now, taskDate);

        if (diffInDays < 0) {
          if (!group.future) {
            return false;
          }

          const absDiffInDays = Math.abs(diffInDays);

          return (
            absDiffInDays <= group.maxDays && absDiffInDays >= group.minDays
          );
        }

        if (group.future) {
          return false;
        }

        return diffInDays <= group.maxDays;
      }
    }
  ];

  return groupSchema;
};
