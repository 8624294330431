/*
  With the extension: we send a postmessage to the extension to proxy requests
  Without the extension: we send a postmessage to an iframe to proxy requests
*/

export const iframeOrExtExists = () => {
  if (!window.BUGHERD_REACT_APP_SIDEBAR_MOUNT) {
    return false;
  }

  const iframeElement = window.document.querySelector(
    '#bugherd_embed_communication_frame'
  );

  return (
    iframeElement ||
    (window.BUGHERD_EXTENSION_CONFIG &&
      window.BUGHERD_EXTENSION_CONFIG.extensionId)
  );
};

export default (...args) => {
  if (window.BUGHERD_REACT_APP_SIDEBAR_MOUNT) {
    const iframeElement = window.document.querySelector(
      '#bugherd_embed_communication_frame'
    );

    if (iframeElement) {
      iframeElement.contentWindow.postMessage(...args);
      return;
    }
  }

  if (
    window.BUGHERD_EXTENSION_CONFIG &&
    window.BUGHERD_EXTENSION_CONFIG.extensionId
  ) {
    window.postMessage(...args);
    return;
  }

  console.error(
    "Couldn't find bugherd_embed_communication_frame iframe or window.BUGHERD_EXTENSION_CONFIG.extensionId to use for postMessage"
  );
};
