export const JobRole = [
  { value: 'Designer', label: 'Designer' },
  { value: 'Developer', label: 'Developer' },
  { value: 'Content Creator', label: 'Content Creator' },
  { value: 'Project/Product Manager', label: 'Project/Product Manager' },
  { value: 'Director C-Level', label: 'Director C-Level' },
  { value: 'Marketer', label: 'Marketer' },
  { value: 'QA', label: 'QA' },
  { value: 'Support', label: 'Support' },
  { value: 'Job not listed', label: 'Job not listed' }
];

export const Industry = [
  { value: 'an Agency', label: 'Agency' },
  { value: 'Marketing/Communications', label: 'Marketing/Communications' },
  { value: 'Online learning', label: 'Online learning' },
  { value: 'E-commerce', label: 'E-commerce' },
  { value: 'Saas/Product', label: 'Saas/Product' },
  { value: 'Industry not listed', label: 'Industry not listed' }
];

export const CompanySize = [
  { value: 'Self-employed', label: 'Self-employed' },
  { value: '1-10', label: '1-10' },
  { value: '11-50', label: '11-50' },
  { value: '51-200', label: '51-200' },
  { value: '201-500', label: '201-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1001-5000', label: '1001-5000' },
  { value: '5001-10,000', label: '5001-10,000' },
  { value: '10,000+', label: '10,000+' }
];

export const Integrations = [
  { value: 'Jira', label: 'Jira' },
  { value: 'Asana', label: 'Asana' },
  { value: 'Basecamp', label: 'Basecamp' },
  { value: 'Clickup', label: 'Clickup' },
  { value: 'Productive', label: 'Productive' },
  { value: 'Github', label: 'Github' },
  { value: 'FullStory', label: 'FullStory' },
  { value: 'Harvest', label: 'Harvest' },
  { value: 'Linear', label: 'Linear' },
  { value: 'LogRocket', label: 'LogRocket' },
  { value: 'Monday.com', label: 'Monday.com' },
  { value: 'Slack', label: 'Slack' },
  { value: 'Microsoft Teams', label: 'Microsoft Teams' },
  { value: 'Trello', label: 'Trello' },
  { value: 'Zapier', label: 'Zapier' },
  { value: 'Other integration', label: 'Other integration' }
];
