import React from 'react';

export type AdditionalMemberState =
  | 'canAdd'
  | 'canRemove'
  | 'upgrade'
  | 'seatsOccupied'
  | null;

export const getAdditionalMemberState = ({
  previousCount,
  seatsOccupied,
  recommendMax,
  count
}: {
  previousCount: number;
  seatsOccupied: number;
  recommendMax?: number;
  count: number;
}): AdditionalMemberState => {
  if (count < seatsOccupied) {
    return 'seatsOccupied';
  } else if (!!recommendMax && count >= recommendMax) {
    return 'upgrade';
  } else if (previousCount > count) {
    return 'canRemove';
  } else if (previousCount < count) {
    return 'canAdd';
  } else return null;
};

const useCounterState = ({
  previousCount,
  seatsOccupied,
  recommendMax
}: {
  previousCount: number;
  seatsOccupied: number;
  recommendMax?: number;
}): {
  memberCount: number;
  setMemberCount: React.Dispatch<React.SetStateAction<number>>;
  counterState?: AdditionalMemberState;
} => {
  const [count, setCount] = React.useState(previousCount);
  const [state, setState] = React.useState<AdditionalMemberState>(null);

  React.useEffect(() => {
    const state = getAdditionalMemberState({
      count,
      previousCount,
      seatsOccupied,
      recommendMax
    });
    setState(state);
  }, [count]);

  return {
    memberCount: count,
    setMemberCount: setCount,
    counterState: state
  };
};

export default useCounterState;
