import React from 'react';
import { Modal } from 'antd';
import styles from './styles.module.css';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { X as Close } from 'lucide-react';

const strings = translations[getLangKey()];

type Props = {
  isModalOpen: boolean;
  error?: {
    created_at: string;
    message: string;
  };
  createError: string;
  setIsModalOpen: (open: boolean) => void;
};
export const WhyModal: React.FC<Props> = ({
  isModalOpen,
  error,
  createError,
  setIsModalOpen
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const container = document.getElementById('kanbanBoard');
  if (!container) throw new Error('No container found to mount modal to');

  return (
    <Modal
      open={isModalOpen}
      getContainer={container}
      closable={false}
      onCancel={handleClose}
      footer={null}
      className={styles.whyModal}
    >
      <h2>{strings.errorTitle}</h2>
      <div className={styles.closeContainer} onClick={handleClose}>
        <Close className={styles.closeIcon} />
      </div>
      <div className={styles.contentContainer}>
        {error && (
          <>
            <pre className={styles.whyModalDetails}>
              {JSON.stringify(error, null, 2)}
            </pre>
          </>
        )}
        {!error && createError && <p>{createError}</p>}
      </div>
    </Modal>
  );
};
