import * as React from 'react';
import { ReactNode, useEffect } from 'react';

import { subscriptionOrganizationPath } from 'app/routes';
import * as translations from './strings';
import { getLangKey } from 'models/Language';

import { REDIRECT_DELAY_MS } from './types';
import styles from './index.module.css';

type Props = {
  organizationId: string;
  children: ReactNode;
};

const RedirectToSubscription = ({ organizationId, children }: Props) => {
  const strings = translations[getLangKey()];

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = subscriptionOrganizationPath({
        organizationId: organizationId
      });
    }, REDIRECT_DELAY_MS);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={styles.emptyWrapper}>
      <h2>{strings.accountIsInactive}</h2>
      {children}
    </div>
  );
};

export default RedirectToSubscription;
