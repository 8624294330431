import React, { FC, useState, useEffect } from 'react';
import styles from './styles.module.css';
import { User } from '../types';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import { UserPlus } from 'lucide-react';
import { Collapse, Avatar, Button, Badge, Tooltip } from 'antd';
import cx from 'classnames';
import { getCollapsedState, setCollapsedState } from '../../utils';
const strings = translations[getLangKey()];

type Props = {
  key: string;
  users: User[];
  title: string;
  canAssignGuests?: boolean;
};

const userClassName = (user: User): string => {
  switch (user.role) {
    case 'guest':
      return 'guest-class';
    case 'collaborator':
      return 'collab-class';
    default:
      return 'member-class';
  }
};

const Users: FC<Props> = ({ users, title, canAssignGuests }) => {
  const [collapsed, setCollapsed] = useState<boolean>(
    getCollapsedState()[title] ?? true
  );
  const activeKey = collapsed ? '' : '1';

  useEffect(() => {
    setCollapsedState(title, collapsed);
  }, [collapsed, title]);

  const guestFacet = canAssignGuests ? 'created or assigned' : 'created';

  const handleClickUser = (user: User) => {
    const token =
      user.role === 'guest'
        ? guestFacet
        : user.role === 'collaborator'
        ? 'created'
        : 'assigned';
    // @ts-expect-error
    window.bugherd.applicationView.trigger('addFacet', token, user.email);
  };

  const handleClickUsersPage = event => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-expect-error
    window.share_project_props.setRole(title.toLowerCase().slice(0, -1));
    // @ts-expect-error
    window.share_project_props.setShow(true);
  };

  const usersLabel = (
    <div className={styles.usersHeader}>
      <span>{title}</span>
      <Button
        icon={<UserPlus className={styles.navIcon} />}
        type="text"
        onClick={e => handleClickUsersPage(e)}
        className={styles.usersButton}
        size="small"
        shape="round"
        data-cypress-trigger={`invite-${title.toLowerCase()}`}
      />
    </div>
  );

  const usersList = (
    <div className={styles.usersContainer}>
      <Button
        type="text"
        className={styles.usersInviteContainer}
        onClick={e => handleClickUsersPage(e)}
        size="small"
        shape="round"
        icon={<UserPlus className={styles.navIcon} />}
      >
        <span>Invite</span>
      </Button>
      {users.length > 0 ? (
        users.map(user => {
          const {
            id,
            display_name,
            first_name,
            surname,
            email,
            new_avatar_url
          } = user;
          const completedCount = user.completed_task_count;
          const taskCount = user.task_count;
          const userTaskCount = `${completedCount}/${taskCount}`;
          const allTasksComplete = taskCount === completedCount;
          const firstNameFirstLetter = (first_name ||
            email)?.[0]?.toUpperCase();
          const surnameFirstLetter = surname?.[0]?.toUpperCase();
          return (
            <Button
              type="text"
              onClick={() => handleClickUser(user)}
              className={styles.userButton}
              key={id}
            >
              <div className={styles.userListItem}>
                <Avatar
                  size="small"
                  className={cx(styles[userClassName(user)], styles.avatar)}
                  src={new_avatar_url}
                >
                  {firstNameFirstLetter}
                  {first_name && surnameFirstLetter}
                </Avatar>
                <span className={styles.userName} title={display_name}>
                  {display_name}
                </span>
                <Tooltip title={strings.taskCount} trigger={['hover']}>
                  <Badge
                    count={userTaskCount}
                    showZero
                    color={allTasksComplete ? '#D9E7F4' : '#A8C1D9'}
                    className={styles.userTaskCount}
                  />
                </Tooltip>
              </div>
            </Button>
          );
        })
      ) : (
        <span className={styles.emptyUsersMessage}>No active users</span>
      )}
    </div>
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: usersLabel,
      children: usersList,
      className: styles.collapseItem
    }
  ];

  return (
    <Collapse
      defaultActiveKey={[activeKey]}
      ghost
      items={items}
      size="small"
      className={styles.collapsibleContainer}
      onChange={() => setCollapsed(!collapsed)}
    />
  );
};

export default Users;
