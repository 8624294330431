import React, { useState, useEffect } from 'react';
import { antdForm } from 'jsUtilities/propTypes';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Input, Select } from 'antd';
import styles from './style.module.css';
import {
  getUrlProtocol,
  stripUrlProtocol,
  decodeURLAndStripProtocol,
  encodeURL
} from 'utils/projectSettingsOperations';

import { validateUrl, ValidateStatus } from 'utils/validateUrls';

export const DNSWarn = `We couldn’t verify this URL. Would you still like to proceed?`;
const ProjectUrlFormItem = 'projectUrl';

const OnbProjectUrlInput = ({
  parentForm,
  stylingClass,
  labelText,
  placeholder,
  setterCallback,
  container,
  currentUrl,
  size
}) => {
  const Success = 'success';
  const Warning = 'warning';
  const Error = 'error';

  const initialUrlProtocol = getUrlProtocol(currentUrl);
  const initialUrl = decodeURLAndStripProtocol(currentUrl);

  const [urlProtocol, setUrlProtocol] = useState(initialUrlProtocol);
  const [projectUrl, setProjectUrl] = useState(initialUrl);

  const { getFieldDecorator, getFieldError } = parentForm;

  const validateDNS = async (_rule, value, cb) => {
    if (!value) cb();
    const validationResponse = await validateUrl(urlProtocol + value);
    if (validationResponse.status === ValidateStatus.Warning) {
      cb(DNSWarn);
    } else if (validationResponse.status === ValidateStatus.Error) {
      cb(validationResponse.message);
    } else {
      cb();
    }
  };

  useEffect(() => {
    const encodedURL = encodeURL(urlProtocol + projectUrl);
    setterCallback(encodedURL);
  }, [urlProtocol, projectUrl, setterCallback]);

  useEffect(() => {
    const currentURLProtocal = getUrlProtocol(currentUrl);
    const currentStripUrl = decodeURLAndStripProtocol(currentUrl);
    setProjectUrl(currentStripUrl);
    setUrlProtocol(currentURLProtocal);
  }, [currentUrl]);

  const selectBefore = (
    <Select
      defaultValue={urlProtocol}
      style={{ width: 100 }}
      onChange={value => setUrlProtocol(value)}
      getPopupContainer={container}
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https:// </Select.Option>
    </Select>
  );

  const getValidState = () => {
    const projectUrlError = getFieldError(ProjectUrlFormItem);
    if (!projectUrlError) return Success;
    if (projectUrlError[0] === DNSWarn) {
      return Warning;
    } else {
      return Error;
    }
  };

  return (
    <Form.Item
      name={ProjectUrlFormItem}
      className={stylingClass}
      label={labelText}
      hasFeedback
      validateStatus={getValidState()}
    >
      {getFieldDecorator(ProjectUrlFormItem, {
        validateTrigger: ['onBlur'],
        initialValue: projectUrl,
        rules: [
          {
            required: true,
            message: 'Please complete this field'
          },
          {
            validator: validateDNS
          }
        ],
        getValueFromEvent: event => {
          const newProjectUrl = stripUrlProtocol(event.target.value);
          setProjectUrl(newProjectUrl);
          return newProjectUrl;
        }
      })(
        <Input
          size={size}
          className={styles.inputBorder}
          addonBefore={selectBefore}
          placeholder={placeholder}
        />
      )}
    </Form.Item>
  );
};

OnbProjectUrlInput.propTypes = {
  parentForm: antdForm,
  stylingClass: PropTypes.string,
  labelText: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  setterCallback: PropTypes.func.isRequired,
  container: PropTypes.func,
  currentUrl: PropTypes.string.isRequired
};

OnbProjectUrlInput.defaultProps = {
  size: 'default', // Set a default value for the size prop (e.g. 'default', 'small', or 'large')
};

export default OnbProjectUrlInput;
