import { message } from 'antd';

type Arguments = {
  text: string;
  successMessage: string;
};

export const handleCopy = ({ text, successMessage }: Arguments) => {
  navigator.clipboard.writeText(text);
  message.success(successMessage);
};
