import React, { FC } from 'react';
import styles from '../index.module.css';
import * as translations from '../strings';
import { getLangKey } from '../../../../models/Language';
import { Form, Input, Button, Space, Divider, message, Alert } from 'antd';
import { handleCopy } from '../../../../utils/handleCopy';

const FormItem = Form.Item;

const strings = translations[getLangKey()];

type Props = {
  isNoLoginGuestExperienceEnabled: boolean;
  shareLink: string;
  inviteUrl: string | null;
  loadingInviteUrl: boolean;
  disableInviteUrl: () => void;
  generateInviteUrl: () => void;
  projectDisabled: boolean;
  isShareDeliverable: boolean;
  isGuest?: boolean;
};

export const GuestInvite: FC<Props> = ({
  shareLink,
  inviteUrl,
  isNoLoginGuestExperienceEnabled,
  loadingInviteUrl,
  disableInviteUrl,
  generateInviteUrl,
  projectDisabled,
  isShareDeliverable,
  isGuest
}) => {
  const messaging = {
    header: strings.shareLink,
    message: strings.shareThisLink
  };

  if (isShareDeliverable || isGuest) {
    messaging.message = strings.copyLink;
  }

  const loading = shareLink === '';

  return (
    <div>
      <h3>
        <span>{messaging.header}</span>
      </h3>
      <p>{messaging.message}</p>
      {isNoLoginGuestExperienceEnabled ? (
        <>
          <Space.Compact className={styles.space}>
            <Input className={styles.shareLink} readOnly value={shareLink} />
            <Button
              type="primary"
              shape="round"
              loading={loading}
              onClick={() =>
                handleCopy({
                  text: shareLink,
                  successMessage: strings.copiedToClipboard
                })
              }
            >
              {strings.copy}
            </Button>
          </Space.Compact>
          <Alert
            className={styles.anyoneWithLink}
            message="Anyone with the link can join this project as a Guest."
            type="info"
            showIcon
          />
          {!isGuest && <Divider className={styles.divider}>OR</Divider>}
        </>
      ) : (
        <Form className={styles.inviteUrlForm}>
          {inviteUrl && (
            <FormItem>
              <div className={styles.inviteUrlBox}>
                <Input
                  className={styles.inviteUrlInput}
                  readOnly
                  value={inviteUrl}
                />
                {inviteUrl && inviteUrl.length > 0 && (
                  <span
                    className={styles.copySpan}
                    onClick={() => {
                      navigator.clipboard.writeText(`${inviteUrl}`);
                      message.info(strings.copiedToClipboard);
                    }}
                  >
                    {strings.copy}
                  </span>
                )}
              </div>
            </FormItem>
          )}
          <FormItem>
            {inviteUrl ? (
              <Button
                type="link"
                className={styles.inviteUrlLink}
                size="large"
                loading={loadingInviteUrl}
                onClick={disableInviteUrl}
              >
                {strings.disableLink}
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                loading={loadingInviteUrl}
                onClick={generateInviteUrl}
                disabled={projectDisabled}
              >
                {strings.createInviteLink}
              </Button>
            )}
          </FormItem>
        </Form>
      )}
    </div>
  );
};
