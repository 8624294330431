import React from 'react';
import { userType, locationType } from '../../../utils/propTypes';
import Form from './form';
import sharedStyles from '../../../components/onboarding/shared.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

export default class extends React.Component {
  static propTypes = {
    user: userType,
    location: locationType
  };

  render() {
    return (
      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <h1>{strings.acceptYourInvitation}</h1>
          <p className="intro">{strings.loginToYourBugherdAccount}</p>
        </div>
        <Form {...this.props} />
        <div className={sharedStyles.footer}>
          <p>
            <a href="/users/passwords/new" target="_blank">
              {strings.forgotPassword}
            </a>
          </p>
        </div>
      </div>
    );
  }
}
