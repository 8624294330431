import React from 'react';
import styles from './index.module.css';
import { IntroductionScreen } from './IntroductionScreen';
import { AssetsScreen } from './AssetsScreen';
import { WebsitesScreen } from './Sites/WebsitesScreen';
import { FigmaModal } from './FigmaModal';
import { LoadingModal } from './LoadingModal';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { FigmaUploadProvider } from '../providers/FigmaUpload';
import { useProjectSitesState } from '../../clients/providers/ProjectSites';
import WelcomeBanner from './Guests/WelcomeBanner';
import QuickLinks from './Guests/QuickLinks';

const LoadingScreen = () => {
  return <div className={styles.header} />;
};

export const MainScreen = () => {
  const {
    data,
    status,
    viewGuestKanban,
    project,
    isGuest
  } = useDesignAssetsState();
  const { projectSites, isProjectSitesLoading } = useProjectSitesState();

  const renderIntroScreen = () => {
    if (status === 'loading' || isProjectSitesLoading) {
      return <LoadingScreen />;
    }

    if (status !== 'success') {
      return <AssetsScreen />;
    }

    return <IntroductionScreen />;
  };

  const showIntroScreen = !projectSites?.length && !data.length;

  return (
    <div className={styles.mainScreen}>
      {isGuest && (
        <div className={styles.guestIntroWrapper}>
          <WelcomeBanner project={project} />
          <QuickLinks project={project} />
        </div>
      )}
      <FigmaUploadProvider>
        <FigmaModal />
      </FigmaUploadProvider>
      <LoadingModal />
      {projectSites?.length > 0 && <WebsitesScreen />}
      <div className={styles.newOnboarding}>
        {data.length > 0 && <AssetsScreen />}
        {!viewGuestKanban && showIntroScreen && renderIntroScreen()}
      </div>
    </div>
  );
};
