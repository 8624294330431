import * as React from 'react';
import { useQuery } from '@apollo/client';
import { TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';

import { setData, UA_MY_TASK_PAGE_SIZE } from 'models/LocalStorage';

import {
  GET_USER_TASKS,
  GetUserTasksData as Data,
  GetUserTasksVars as Vars
} from '../queries';
import { setupPusher, teardownPusher } from '../pusher';
import { UserTask, UserTaskOrder, USER_TASKS_PAGE_SIZE } from '../types';
import {
  orderTaskByColumn,
  getPreferenceOrderBy,
  setPreferenceOrderBy
} from '../sort';

import useFilter from '../hooks/useFilter';

const useUserTasksPageSize = (): [number, (size: number) => void] => {
  const [userTasksPageSize, _setUserTasksPageSize] = React.useState<number>(
    USER_TASKS_PAGE_SIZE
  );

  const setUserTasksPageSize = (size: number) => {
    _setUserTasksPageSize(size);
    setData(UA_MY_TASK_PAGE_SIZE, size);
  };

  return [userTasksPageSize, setUserTasksPageSize];
};

export const useState = ({ organizationId }: { organizationId: string }) => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [taskOrderBy, _setTaskOrderBy] = React.useState<UserTaskOrder>(
    getPreferenceOrderBy()
  );

  const setTaskOrderBy = (orderBy: UserTaskOrder) => {
    _setTaskOrderBy(orderBy);
    setPreferenceOrderBy(orderBy);
  };

  const [pusherUpdated, setPusherUpdated] = React.useState<boolean>(false);

  const [userTasks, setUserTasks] = React.useState<UserTask[]>([]);
  const [userTasksPageSize, setUserTasksPageSize] = useUserTasksPageSize();

  const [filter, setUserTaskFilter] = useFilter({ setPageNumber });

  const { data, loading, error, refetch } = useQuery<Data, Vars>(
    GET_USER_TASKS,
    {
      variables: {
        organizationId,
        offset: (pageNumber - 1) * userTasksPageSize,
        limit: userTasksPageSize,
        orderBy: taskOrderBy,
        filter
      }
    }
  );

  React.useEffect(() => {
    if (!data) return;

    const projects = data.currentUser.userTasks.map(({ project }) => project);

    setupPusher({
      config: data.config,
      projects,
      onUpdate: () => setPusherUpdated(true)
    });

    return () =>
      teardownPusher({
        config: data.config,
        projects
      });
  }, [data]);

  React.useEffect(() => {
    if (loading) return;

    if (data) {
      setUserTasks(data.currentUser.userTasks);
      setPusherUpdated(false);
    }
  }, [data]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Partial<Record<keyof UserTask, string[]>>,
    sorter: SorterResult<UserTask> | SorterResult<UserTask>[]
  ) => {
    if (!sorter) return;

    const orderBy: UserTaskOrder = orderTaskByColumn(
      sorter as SorterResult<UserTask>
    );
    setTaskOrderBy(orderBy);
  };

  const handlePagination = (page: number) => {
    setPageNumber(page);
  };

  return {
    pageNumber,
    pusherUpdated,
    userTasks,
    userTasksPageSize,
    setUserTasksPageSize,
    handleTableChange,
    handlePagination,
    userTaskFilter: filter,
    setUserTaskFilter,
    getUserTasks: { data, loading, error, refetch }
  };
};
