type ContentType = string | undefined;
type Base64Type = ContentType;

export default (dataURI: string) => {
  const b64Data: Base64Type = dataURI.split(';base64,').pop();
  const contentType: ContentType = dataURI
    .slice(5)
    .split(';base64')
    .shift();

  if (!b64Data || !contentType) {
    return null;
  }
  const sliceSize: number = 512;
  const b64toBlob = () => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const blob = b64toBlob();
  return URL.createObjectURL(blob);
};
