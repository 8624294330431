import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import WhiteLogo from '../../../../../assets/images/icons/bugherd-white-logo.svg';
import * as translations from '../register_sign_up/strings';
import { getLangKey } from 'appJS/models/Application';
const strings = translations[getLangKey()];

export default () => (
  <div className={styles.mobileWraper}>
    <div className={styles.mobileLogo}>
      <WhiteLogo />
    </div>
    <div className={styles.backToWebsite}>
      <Link to="https://bugherd.com">{strings.backToWebsite}</Link>
    </div>
  </div>
);
