import * as React from 'react';
import { useQuery } from '@apollo/client';

import { Project } from 'models/Project';

import {
  GET_USER_PROJECTS,
  GetUserProjectsData as Data,
  GetUserProjectsVars as Vars
} from './queries';

import { setupPusher, teardownPusher } from './pusher';
import usePagination from './hooks/usePagination';
import useFilterByName from 'hooks/useFilterByName';
import { UP_MY_PROJECT_PAGE_SIZE } from 'appJS/models/LocalStorage';
import { USER_PROJECTS_PAGE_SIZE, PAGE_SIZE_OPTIONS } from './types';

export const useUserProjectsState = ({
  organizationId,
  activeKey,
  favouriteProjects
}: {
  organizationId: string;
  activeKey: string;
  favouriteProjects: string;
}) => {
  const [userProjects, setUserProjects] = React.useState<Project[]>([]);
  const [userProjectsCount, setUserProjectsCount] = React.useState<number>(0);
  const [showRefresh, setShowRefresh] = React.useState<boolean>(false);
  const [sortBy, setSortBy] = React.useState<string>('updated_at');
  const [activeOnly, setActiveOnly] = React.useState<boolean>(true);

  const {
    pageNumber,
    setPageNumber,
    setPageSize,
    pageSize,
    onPaginationChange
  } = usePagination(UP_MY_PROJECT_PAGE_SIZE, USER_PROJECTS_PAGE_SIZE);

  // My projects page size options has been updated. Reset the value if previous options were stored;
  if (!PAGE_SIZE_OPTIONS.includes(pageSize.toString())) {
    setPageSize(USER_PROJECTS_PAGE_SIZE);
  }

  const { filter, setSearchByName, searchByName } = useFilterByName({
    onChange: () => setPageNumber(1)
  });

  const favourites = () => {
    try {
      return JSON.parse(favouriteProjects)[organizationId] || [];
    } catch (error) {
      console.error('Error parsing favourite projects', error);
      return [];
    }
  };

  const folderFilter = () => {
    if (activeKey === 'all-projects') return {};
    if (activeKey === 'favourites')
      return { favourites: favourites().join(',') };

    return { projectFolderId: activeKey };
  };

  const { data, loading, error, refetch } = useQuery<Data, Vars>(
    GET_USER_PROJECTS,
    {
      variables: {
        organizationId,
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
        filter: {
          name: filter?.name || null,
          ...folderFilter()
        },
        sortBy,
        activeOnly
      }
    }
  );

  React.useEffect(() => {
    if (!data) return;

    setUserProjects(data.currentUser.userProjects);
    setUserProjectsCount(data.currentUser.userProjectsCount || 0);

    setupPusher({
      config: data.config,
      projects: data.currentUser.userProjects,
      onUpdate: () => setShowRefresh(true)
    });

    return () =>
      teardownPusher({
        config: data.config,
        projects: data.currentUser.userProjects
      });
  }, [data]);

  React.useEffect(() => {
    if (
      (activeKey === 'favourites' || activeKey !== 'all-projects') &&
      pageNumber !== 1
    )
      setPageNumber(1);
  }, [activeKey]);

  return {
    pageNumber,
    onPaginationChange,
    searchByName,
    setSearchByName,
    sortBy,
    activeOnly,
    setActiveOnly,
    setSortBy,
    showRefresh,
    setShowRefresh,
    userProjects,
    userProjectsCount,
    userProjectsPageSize: pageSize,
    getUserProjects: { data, loading, error, refetch }
  };
};
