import * as React from 'react';
import styles from './styles.module.css';
import { Button } from 'antd';
import { View } from './project_nav/types';

interface Props {
  projectSites: any[];
  assets: any[];
  setView: (view: View) => void;
  setShowAssets: (boolean) => void;
  kanbanCount: number;
  projectBoardOnboardingCompleted: boolean;
}

const FeedbackBanner: React.FC<Props> = ({
  projectSites,
  assets,
  setView,
  setShowAssets,
  kanbanCount,
  projectBoardOnboardingCompleted
}) => {
  const handleAddButton = () => {
    //@ts-expect-error
    window.bugherd.applicationView.showAssets();
    setShowAssets(true);
    setView(View.Assets);
  };

  const handleCloseBanner = () => {
    const feedbackBanner = document.querySelector(`.${styles.feedbackBanner}`);
    if (feedbackBanner) {
      feedbackBanner.classList.add(styles.hidden);
    }
  };

  const amountOfAssets = assets?.length || 0;
  const amountOfSites = projectSites?.length || 0;
  const showBanner =
    (amountOfAssets === 0 && amountOfSites === 0) ||
    (kanbanCount === 0 && !projectBoardOnboardingCompleted);

  return (
    <>
      {showBanner && (
        <div className={styles.feedbackBanner}>
          <div onClick={handleCloseBanner} className={styles.closeButton}>
            X
          </div>
          <h2>
            Where’s my feedback?
            <span role="img" aria-label="thinking face">
              🤔
            </span>
          </h2>
          <p>
            {kanbanCount === 0
              ? 'Your kanban board looks empty. Click on your website or asset to create a task and give feedback.'
              : `Before you can create or gather feedback, you need to add a website,
            design or document to your project. Click the ‘add’ button.`}
          </p>
          <Button onClick={handleAddButton} type="default">
            Add
          </Button>
        </div>
      )}
    </>
  );
};

export default FeedbackBanner;
