import React, { useState, useRef } from 'react';
import { getLangKey } from 'models/Language';
import styles from './index.module.css';
import cx from 'classnames';
import * as placeholders from '../../onboardingSurvey/Placeholders';
import { JobRole } from './userSurveyOptions';
import OptionalUserSurvey from 'models/OptionalUserSurvey';
import api from 'api/CommandApi';
import { Button, Select, Form, message, Input } from 'antd';
import track from 'utils/analytics';
import { Integrations } from 'appJS/views/kanban/Onboarding/userSurveyOptions';
import * as translations from './strings';

interface UserSurveyProps {
  onFinish: () => void;
  organizationId: number;
}

const UserSurvey: React.FC<UserSurveyProps> = ({
  organizationId,
  onFinish
}) => {
  const strings = translations[getLangKey()];
  const [jobRole, setJobRole] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOtherIntegration, setIsOtherIntegration] = useState(false);

  const [form] = Form.useForm();
  const checkNullValidation: boolean = !!jobRole;

  const integrationIput = useRef(null);

  const handleIntegrationChange = (value: string[]) => {
    const hasOtherIntegration = value.includes('Other integration');
    setIsOtherIntegration(hasOtherIntegration);
    if (hasOtherIntegration) {
      const filteredValue = value.filter(
        integration => integration !== 'Other integration'
      );
      // eslint-disable-next-line no-unused-expressions
      if (integrationIput.current) {
        integrationIput.current.blur();
      }
      form.setFieldsValue({ integrations: filteredValue });
    }
  };

  const submitSurvey = async () => {
    if (checkNullValidation) {
      setLoading(true);
      let integrations = form.getFieldValue('integrations').join(', ') || null;
      const otherIntegrations = form.getFieldValue('integration_other');
      if (otherIntegrations) {
        integrations = integrations
          ? `${integrations}, ${otherIntegrations}`
          : otherIntegrations;
      }
      const optionalUserSurvey: OptionalUserSurvey = {
        organizationId,
        jobRole,
        integrations
      };

      try {
        await api.submitOptionalUserSurvey(optionalUserSurvey);
        track('Signup Survey Submitted', optionalUserSurvey);
        setLoading(false);
        onFinish();
      } catch (e) {
        console.error(e);
        message.error(strings.completeOnboardingError);
      } finally {
        setLoading(false);
      }
    }
  };

  const skipSurvey = async () => {
    track('Onboarding Skip link clicked', { page_url: window.location.href });
    onFinish();
  };

  return (
    <div>
      <div className={cx(styles.marginTop, styles.surveyHeaders)}>
        {strings.aboutYou}
      </div>

      <Form
        name="user_survey"
        form={form}
        onFinish={submitSurvey}
        className={styles.userForm}
      >
        <p className={styles.surveyHeaders}>{strings.jobRole}</p>
        <Form.Item
          name="job_role"
          rules={[{ required: true, message: 'Please select your job role' }]}
        >
          <Select
            placeholder={placeholders.JobRole}
            options={JobRole}
            onChange={setJobRole}
          />
        </Form.Item>
        <p className={styles.surveyHeaders}>{strings.whichIntegrations} </p>
        <Form.Item
          name="integrations"
          rules={[
            {
              required: false,
              message: strings.organizationTypeErrorMessage
            }
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="select integration"
            onChange={handleIntegrationChange}
            options={Integrations}
            ref={integrationIput}
          />
        </Form.Item>

        {isOtherIntegration && (
          <>
            <p className={styles.surveyHeaders}>{strings.specifyIntegration}</p>
            <Form.Item
              name="integration_other"
              rules={[
                {
                  required: true,
                  message: 'Please specify integration'
                }
              ]}
            >
              <Input placeholder="Other integration" />
            </Form.Item>
          </>
        )}

        <div>
          <Button
            className={styles.submitButton}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
          <Button
            className={styles.surveyButton}
            type="link"
            onClick={skipSurvey}
          >
            skip
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UserSurvey;
