import React, { FC } from 'react';
import { Dropdown, Button, Modal } from 'antd';
import { moreActionsItems } from './moreActionsItems';
import { ChevronUp } from 'lucide-react';
import styles from './styles.module.css';
import { UpdateParams } from '.';

type Props = {
  container: HTMLDivElement;
  loading: boolean;
  handleUpdate: (params: UpdateParams) => void;
  setSelectedFolder: (selectedFolder: string) => void;
};

export const MoreActionsDropdown: FC<Props> = ({
  container,
  loading,
  handleUpdate,
  setSelectedFolder
}) => {
  const areYouSure = (action: string) =>
    `Are you sure you want to ${action} this project?`;

  const handleMenuClick = ({ key }) => {
    setSelectedFolder('');
    const params: UpdateParams = {};
    switch (key) {
      case 'delete':
        params.delete = true;
        Modal.confirm({
          title: areYouSure('delete'),
          onOk: () => handleUpdate(params)
        });
        break;
      case 'enable':
        params.show_on_live = true;
        handleUpdate(params);
        break;
      case 'disable':
        params.show_on_live = false;
        Modal.confirm({
          title: areYouSure('disable'),
          onOk: () => handleUpdate(params)
        });
        break;
      case 'remove-folder':
        params.project_folder_id = null;
        handleUpdate(params);
        break;
    }
  };

  return (
    <Dropdown
      className={styles.moreActionsDropdown}
      overlayClassName={styles.moreActionsOverlay}
      getPopupContainer={() => container}
      placement="top"
      trigger={['click']}
      menu={{
        items: moreActionsItems,
        selectable: true,
        onClick: handleMenuClick
      }}
    >
      <Button
        type="link"
        icon={<ChevronUp className={styles.crossIcon} />}
        loading={loading}
      >
        More Actions
      </Button>
    </Dropdown>
  );
};
