import React, { FC, Ref, useEffect, useRef } from 'react';
import styles from './index.module.css';
import { Modal, Input, InputRef } from 'antd';
import track from 'appJS/utils/analytics';

type Props = {
  onAddToGroup: (groupName: string) => Promise<void>;
  setIsGroupNameModalOpen: (isGroupNameModalOpen: number | false) => void;
  setGroupName: (groupName: string) => void;
  isGroupNameModalOpen: number | false;
  container: HTMLDivElement;
  groupName: string;
};

export const GroupNameModal: FC<Props> = ({
  onAddToGroup,
  setIsGroupNameModalOpen,
  setGroupName,
  isGroupNameModalOpen,
  container,
  groupName
}) => {
  const inputRef: Ref<InputRef> = useRef(null);

  useEffect(() => {
    if (isGroupNameModalOpen && inputRef?.current) {
      inputRef.current?.focus();
    }
  }, [isGroupNameModalOpen]);

  const closeModalAndResetGroupName = () => {
    setIsGroupNameModalOpen(false);
    setGroupName('');
  };

  const onOk = async () => {
    track('Group created');
    await onAddToGroup(groupName);
    closeModalAndResetGroupName();
  };

  return (
    <Modal
      open={!!isGroupNameModalOpen}
      closeIcon={false}
      onOk={onOk}
      onCancel={closeModalAndResetGroupName}
      getContainer={container}
      wrapClassName={styles.groupNameModal}
    >
      <Input
        ref={inputRef}
        onChange={({ target: { value } }) => setGroupName(value)}
        onPressEnter={onOk}
        placeholder="Enter group name here"
      />
    </Modal>
  );
};
