import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { AutoComplete, Input, Select, Button, message, Alert } from 'antd';
import Avatar from '../../../clients/shared/Avatar';
import sortUsers from '../../utils/sortUsers';
import { X as Close, Mail } from 'lucide-react';
import styles from './index.module.css';
import Loader from 'components/loader';
import { throttle } from 'lodash-es';
import ArrowUp from './../../../clients/sidebar/assets/images/ic-upgrade-circle.svg';
import UpgradePopover from '../../../clients/sidebar/components/UpgradePopover';
import ssoPopoverHeaderImage from './images/img-sso-upgrade-tooltip.png';

const Option = Select.Option;
const FormItem = Form.Item;
const AutoOption = AutoComplete.Option;

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default class Invite extends React.Component {
  static propTypes = {
    authenticityToken: PropTypes.string.isRequired,
    container: PropTypes.func.isRequired,
    endpoint: PropTypes.shape({
      sendEmail: PropTypes.string.isRequired,
      fetchUsers: PropTypes.string
    }),
    onInvitedUser: PropTypes.func,
    membersCount: PropTypes.number.isRequired,
    membersMax: PropTypes.number,
    project: PropTypes.object,
    role: PropTypes.string,
    responseError: PropTypes.string,
    success: PropTypes.string,
    users: PropTypes.array,
    collaboratorsEnabled: PropTypes.bool.isRequired,
    billingRights: PropTypes.bool.isRequired,
    orgId: PropTypes.number.isRequired,
    isShareDeliverable: PropTypes.bool,
    siteId: PropTypes.number,
    assetId: PropTypes.number
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.props.role !== nextProps.role) {
      this.setState({
        role: nextProps.role
      });
    }
  };

  setDefaultRole = () => {
    const {
      membersMax,
      membersCount,
      role,
      collaboratorsEnabled,
      project,
      users
    } = this.props;
    const remainingMembers = membersMax > membersCount;

    let selectValue = role;

    if (membersMax === null) {
      selectValue = role;
    } else if (!remainingMembers && role === 'member') {
      if (project) {
        if (users.length > 0) {
          selectValue = 'member';
        } else {
          selectValue = 'guest';
        }
      } else if (collaboratorsEnabled) {
        selectValue = 'collaborator';
      } else {
        selectValue = 'member';
      }
    }

    return selectValue;
  };

  memberDisabled = () => {
    const { membersMax, membersCount, project, users } = this.props;
    const noRemainingMembers =
      membersMax !== null && membersMax <= membersCount;
    if (!project) {
      return noRemainingMembers;
    } else {
      return users.length < 1 && noRemainingMembers;
    }
  };

  state = {
    saving: false,
    isValid: false,
    email: '',
    role: this.setDefaultRole(),
    loading: false,
    users: this.props.users,
    search: '',
    memberDisabled: this.memberDisabled(),
    showContactAdminMessage: false,
    popoverVisible: false
  };

  emailValidation = (value = '') => {
    return (value.match(/@/g) || []).length === 1;
  };

  onRoleChange = value => {
    this.setState({
      role: value
    });
  };

  onEmailChange = value => {
    const { project, membersMax, membersCount, users } = this.props;
    let { memberDisabled } = this.state;
    if (project) {
      this.doSearch(value);
    }

    const memberLimitReached =
      membersMax !== null && membersMax <= membersCount;

    if (project && users.length < 1) {
      memberDisabled = !memberDisabled;
    }

    this.setState({ email: value }, () => {
      let selectValue = this.state.role;

      if (this.isExistingUser()) {
        let userRole = this.isExistingUser().role;
        selectValue =
          userRole === 'collaborator' || userRole === 'guest'
            ? userRole
            : 'member';
      } else if (!this.isExistingUser() && memberLimitReached) {
        if (project) {
          selectValue = 'guest';
        } else {
          selectValue = 'collaborator';
        }
      }
      this.setState({
        role: selectValue,
        memberDisabled
      });
    });

    this.setState({
      isValid: true
    });
  };

  buildAutoCompleteLayout = user => {
    return (
      <AutoOption
        key={user.email}
        className={styles.resultContainer}
        text={user.email}
      >
        <Avatar member={user} autoComp size="small" />
        <span className={styles.autoCompName}>{user.display_name}</span>
        {user.display_name !== user.email && (
          <span className={styles.autoCompEmail}>{`(${user.email})`}</span>
        )}
      </AutoOption>
    );
  };

  lastFetchId = 0;

  doSearch = throttle(search => {
    this.setState({ loading: true });
    this.lastFetchId++;
    const fetchId = this.lastFetchId;
    const query =
      search.length > 0 ? `?search=${encodeURIComponent(search)}` : '';
    fetch(`${this.props.endpoint.fetchUsers}${query}`, {})
      .then(res => res.json())
      .then(res => {
        if (fetchId === this.lastFetchId) {
          this.setState({
            loading: false,
            users: res.users,
            perPage: res.perPage,
            page: res.page,
            total: res.total
          });
        }
      });
  }, 1000);

  filterAutoComplete = query => {
    let usersNotOnProject = this.state.users.filter(user => {
      return (
        this.props.project.usersOnProject.filter(
          projectUser => projectUser.id === user.id
        ).length < 1
      );
    });

    if (query) {
      usersNotOnProject = usersNotOnProject.filter(
        user =>
          user.email.toLowerCase().includes(query.toLowerCase()) ||
          user.display_name.toLowerCase().includes(query.toLowerCase())
      );
    }

    usersNotOnProject = usersNotOnProject.slice(0, 50);

    return sortUsers(usersNotOnProject);
  };

  buildFormData = () => {
    const { siteId, assetId } = this.props;

    const data = [
      ['utf8', '✓'],
      ['role', this.state.role],
      ['email_addresses', this.state.email],
      ['send_email', '1']
    ];

    if (siteId) data.push(['site_id', siteId]);
    if (assetId) data.push(['asset_id', assetId]);

    if (this.isExistingUser()) {
      data.push(['user_id', this.isExistingUser().id]);
    }

    const formData = new FormData();
    data.forEach(keyValue => {
      formData.append(keyValue[0], keyValue[1]);
    });
    return formData;
  };

  sendEmail = event => {
    const {
      success,
      authenticityToken,
      responseError,
      onInvitedUser
    } = this.props;
    const { email } = this.state;

    let fetchOptions = {
      method: 'POST',
      body: this.buildFormData(),
      credentials: 'include',
      headers: { 'X-CSRF-Token': authenticityToken }
    };
    const isValid = this.emailValidation(email);
    event.preventDefault();
    if (isValid) {
      this.setState({
        saving: true,
        isValid
      });
      fetch(this.props.endpoint.sendEmail, fetchOptions)
        .then(response => {
          if (response.status !== 200 && response.status !== 302) {
            throw Error(response.statusText);
          }

          return response.json();
        })
        .then(myData => {
          this.setState({
            saving: false,
            isValid: false,
            email: ''
          });

          onInvitedUser(myData[0]);

          message.success(email + success);
        })
        .catch(error => {
          this.setState({
            saving: false
          });
          message.error(responseError);
        });
    } else {
      this.setState({
        isValid
      });
    }
  };

  isExistingUser = () => {
    return this.state.users.find(user => user.email === this.state.email);
  };

  isSelectDisabled = () => {
    return this.isExistingUser() || this.canNoLongerInviteAnyone();
  };

  canNoLongerInviteAnyone = () => {
    const { membersCount, membersMax, collaboratorsEnabled } = this.props;
    return (
      membersMax !== null && membersMax <= membersCount && !collaboratorsEnabled
    );
  };

  isValid = () => {
    const validEmail = this.state.isValid || this.state.email === '';
    const existingUser = this.isExistingUser();
    const canInvitedExistingUser = this.props.project;

    return validEmail && (canInvitedExistingUser || !existingUser);
  };

  isValidEmailFormat = () => {
    return emailRegex.test(this.state.email);
  };

  helpMessage = () => {
    if (!(this.state.isValid || this.state.email === '')) {
      return 'This is not a valid email address.';
    }

    const existingUser = this.isExistingUser();

    if (existingUser && !this.props.project) {
      return `${existingUser.display_name} is already a user on this Organization.`;
    }

    return null;
  };

  isButtonDisabled = () => {
    const { membersMax, membersCount, project } = this.props;
    const existingUser = this.isExistingUser();
    const { role } = this.state;
    const hasRemainingMembers =
      membersMax === null || membersMax > membersCount;

    return (
      !this.isValidEmailFormat() ||
      (!hasRemainingMembers && role === 'member' && !existingUser) ||
      (existingUser && !project)
    );
  };

  showAdditionalMembersMessage = billingRights => {
    return (
      <Alert
        type="warning"
        className={styles.upgradeMessage}
        message={
          billingRights ? (
            <span>
              To invite more people,{' '}
              <a
                href={`/organizations/${this.props.orgId}/subscription`}
                className={styles.planLink}
              >
                upgrade your plan
              </a>{' '}
              or{' '}
              <a
                type="link"
                href={`/organizations/${this.props.orgId}/subscription?showAdditionalMember=true`}
                className={styles.planLink}
              >
                add more members
              </a>
            </span>
          ) : (
            <span>
              To invite more people please contact the account owner to upgrade
              your plan or add more members.
            </span>
          )
        }
      />
    );
  };

  popoverMessage = () => {
    const text =
      'Need to get everyone in your large organisation set up with BugHerd?';
    const text2 =
      'Single Sign-On (SSO) is the easiest and most secure way to do it.';
    const text3 = 'Available only on the Enterprise plan.';

    return (
      <span className={styles.upgradeMessage}>
        <p>
          <b>{text}</b> {text2}
        </p>
        <p>
          <br />
          {text3}
        </p>
        <br />
      </span>
    );
  };

  renderSsoPopOver = () => {
    const billingRights = true;
    const getContainer = document.getElementById('ssoContainer');
    const visible = this.state.popoverVisible;

    return (
      <div className={styles.upgradePopoverContainer}>
        <UpgradePopover
          children=""
          isSmallView={false}
          open={visible}
          onOpenChange={() => this.setState({ popoverVisible: false })}
          message={this.popoverMessage()}
          placement="topRight"
          className={styles.upgradePopover}
          getContainer={() => getContainer}
          imageSrc={ssoPopoverHeaderImage}
          billingRights={billingRights}
          headerContent="SSO for Bugherd"
          buttonText="Contact us for pricing"
          href={`https://bugherd.typeform.com/to/OcoH14?typeform-source=bugherd.com`}
        />
      </div>
    );
  };

  render() {
    const {
      container,
      membersCount,
      membersMax,
      project,
      collaboratorsEnabled,
      billingRights,
      isShareDeliverable
    } = this.props;
    const { email, saving, loading, memberDisabled } = this.state;
    const popoverVisible = this.state.popoverVisible;

    const inputProps = {
      prefix: <Mail className={styles.mailIcon} />,
      suffix: this.state.email && this.isValid() && !project && (
        <Close
          className={styles.closeIcon}
          onClick={() =>
            this.setState({
              email: ''
            })
          }
        />
      ),
      className: styles.inviteEmail,
      placeholder:
        (project ? 'Search/invite' : 'Invite new') + ' users by email',
      size: 'large'
    };

    const remainingMembers = membersMax - membersCount;
    const isProjectActive = this.props.project?.show_on_live !== false;

    const messaging = {
      header: 'Share via email',
      message:
        'Add existing Team Members or invite new Members & Guests to this project'
    };

    if (isShareDeliverable) {
      messaging.header = 'Share via email';
      messaging.message =
        'Send this to anyone in the project team, or invite new Guests / Members to this project to view this deliverable.';
    }

    return (
      <div className={styles.inviteBox} id="inviteBox">
        <h3 className={styles.tabHeader}>
          <span id="elevioHelperMemberInvite">{messaging.header}</span>
        </h3>
        {project && <p className={styles.inviteInfo}>{messaging.message}</p>}
        {!isProjectActive && (
          <Alert
            className={styles.upgradeMessage}
            message={
              <span>
                Project has been disabled.{' '}
                <a
                  href={`/projects/${project.id}/settings`}
                  className={styles.planLink}
                >
                  Enable Project
                </a>{' '}
                to invite members.
              </span>
            }
            type="warning"
            showIcon
          />
        )}
        <Form
          className={styles.emailInviteForm}
          onSubmit={event => this.sendEmail(event)}
        >
          <FormItem
            className={styles.emailField}
            hasFeedback
            validateStatus={this.isValid() ? null : 'error'}
            help={this.helpMessage()}
          >
            {project ? (
              <AutoComplete
                getPopupContainer={container}
                dataSource={this.filterAutoComplete(email).map(
                  this.buildAutoCompleteLayout
                )}
                size="large"
                value={email}
                loading
                className={styles.autoComplete}
                optionLabelProp="text"
                onChange={this.onEmailChange}
                disabled={!isProjectActive}
              >
                <Input
                  {...inputProps}
                  suffix={loading ? <Loader size="small" /> : null}
                  disabled={!isProjectActive}
                />
              </AutoComplete>
            ) : (
              <Input
                {...inputProps}
                value={email}
                onChange={event => this.onEmailChange(event.target.value)}
                disabled={!isProjectActive || this.canNoLongerInviteAnyone()}
              />
            )}
          </FormItem>
          <FormItem className={styles.inviteRoleWrapper}>
            {(project || (!project && collaboratorsEnabled)) && (
              <Select
                className={styles.inviteRole}
                size="large"
                name="role"
                getPopupContainer={container}
                value={this.state.role}
                onChange={this.onRoleChange}
                dropdownMatchSelectWidth={false}
                disabled={!isProjectActive}
              >
                {project && <Option value="guest">Guest</Option>}
                <Option value="member" disabled={memberDisabled}>
                  Member
                </Option>
                {collaboratorsEnabled && (
                  <Option value="collaborator">Collaborator</Option>
                )}
              </Select>
            )}
          </FormItem>
          <Button
            className={styles.inviteButton}
            type="primary"
            size="large"
            htmlType="submit"
            loading={saving}
            disabled={!isProjectActive || this.isButtonDisabled()}
          >
            {this.isExistingUser() ? 'Add' : 'Invite'}
          </Button>
        </Form>
        {membersMax != null &&
          remainingMembers <= 0 &&
          this.showAdditionalMembersMessage(billingRights)}
        <div id="ssoContainer" className={styles.ssoContainer}>
          <ArrowUp
            className={styles.upgradeArrow}
            onClick={() => this.setState({ popoverVisible: !popoverVisible })}
          />
          {this.renderSsoPopOver()}
          <span>
            Security important to your team?&nbsp;
            <a
              className={styles.ssoText}
              onClick={() => this.setState({ popoverVisible: !popoverVisible })}
            >
              Single Sign-On (SSO) is now available
            </a>
          </span>
        </div>
      </div>
    );
  }
}
