import React, { FC } from 'react';
import DetailsLabel from '../DetailsLabel';
import { ExternalLink } from 'lucide-react';
import styles from './styles.module.css';

type Props = {
  title: string;
  linkText: string;
  url: string;
  titleIcon?: JSX.Element;
};

const IntegrationLink: FC<Props> = ({ title, url, linkText, titleIcon }) => {
  return (
    <div className={styles.integrationLinkOuter}>
      <DetailsLabel label={`${title.toUpperCase()}:`} icon={titleIcon} />
      <a className={styles.link} href={url} target="_blank">
        {linkText}{' '}
        <span className={styles.linkIcon}>
          <ExternalLink size={14} />
        </span>
      </a>
    </div>
  );
};

export default IntegrationLink;
