import * as React from 'react';
import { throttle } from 'lodash-es';

const THROTTLE_WAIT_TIME = 500;

const useFetchPages = ({
  setIsFetching,
  totalPageNumber
}: {
  setIsFetching?: (isFetching: boolean) => void;
  totalPageNumber: number;
}): {
  pageNumber: number;
  fetchNextPage: (isForward: boolean) => void;
  setPageNumber: (increamentBy: number) => void;
} => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [nextPageNumber, setNextPageNumber] = React.useState<number>(1);

  const throttleFetchMore = React.useCallback(
    throttle(
      (nextPage: number) => {
        setIsFetching && setIsFetching(true);
        setPageNumber(nextPage);
      },
      THROTTLE_WAIT_TIME,
      { trailing: true }
    ),
    [pageNumber, setNextPageNumber, setIsFetching]
  );

  React.useEffect(() => {
    if (nextPageNumber <= totalPageNumber && nextPageNumber > 0)
      throttleFetchMore(nextPageNumber);
  }, [nextPageNumber]);

  const fetchNextPage = React.useCallback(
    (isForward: boolean) => {
      setNextPageNumber(isForward ? pageNumber + 1 : pageNumber - 1);
    },
    [pageNumber]
  );

  return {
    pageNumber,
    fetchNextPage,
    setPageNumber
  };
};

export default useFetchPages;
