import { gql } from '@apollo/client';

const SET_MEMBER_LICENCE = gql`
  mutation SetMemberLicenses(
    $organizationId: ID!
    $userCount: Int!
    $duration: String!
  ) {
    setMemberLicences(
      input: {
        organizationId: $organizationId
        userCount: $userCount
        duration: $duration
      }
    ) {
      totalCurrentUsers
    }
  }
`;

export { SET_MEMBER_LICENCE };
