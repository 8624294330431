import * as React from 'react';
import { detect, BrowserInfo, Browser } from 'detect-browser';

import Hint from 'components/Hint';
import {
  browserConfig,
  BrowserConfigType
} from 'components/onboarding/extensionConfig';
import ExtensionInstallButton from 'components/onboarding/install_button';
import { getLangKey } from 'models/Language';
import InstallJavascript from './InstallJavascript';
import { SiteError } from 'models/siteError';
import * as translations from './strings';
import styles from './index.module.css';

interface Props {
  jsInstalled: boolean;
  widgetCode: string;
  siteErrors: SiteError[];
  projectUrl: string;
  projectId: number;
  lastSiteCheckAt: string;
  showEmbedJavascript: boolean;
  setShowEmbedJavascript: (show: boolean) => void;
}

const InstallBugherd: React.FC<Props> = ({
  jsInstalled,
  widgetCode,
  projectUrl,
  projectId,
  siteErrors,
  lastSiteCheckAt,
  showEmbedJavascript,
  setShowEmbedJavascript
}) => {
  const strings = translations[getLangKey()];

  const browser: BrowserInfo = detect() as BrowserInfo;
  const currentBrowser: Browser = browser.name;

  const getBrowserConfig: () => BrowserConfigType | null = () =>
    currentBrowser === null
      ? null
      : (browserConfig.find(
          browserInfo => browserInfo.name === currentBrowser
        ) as BrowserConfigType);

  const hasBrowserExtension = !!getBrowserConfig();

  React.useEffect(() => {
    setShowEmbedJavascript(!hasBrowserExtension);
  }, []);

  const extensionSupported: () => React.ReactNode = () => (
    <div>
      <div className={styles.installBhBtn}>
        <ExtensionInstallButton
          browserConfig={getBrowserConfig()}
          isSignUpOnBoarding
        />
      </div>
      <p className={styles.installJsText}>
        {strings.or}{' '}
        <a onClick={() => setShowEmbedJavascript(true)}>
          {strings.installJsText}
        </a>
      </p>
    </div>
  );

  const showBrowserExtension: boolean =
    !showEmbedJavascript && hasBrowserExtension;

  const embedJavascript: () => React.ReactNode = () => (
    <InstallJavascript
      widgetCode={widgetCode}
      javascriptInstalled={jsInstalled}
      projectId={projectId}
      projectUrl={projectUrl}
      siteErrors={siteErrors}
      lastSiteCheckAt={lastSiteCheckAt}
      isExtensionSupported={hasBrowserExtension}
      showExtension={() => setShowEmbedJavascript(false)}
    />
  );

  return (
    <div className={styles.stepContainer}>
      {showBrowserExtension ? extensionSupported() : embedJavascript()}
      {showBrowserExtension && (
        <Hint
          message={strings.hintMessageInstallBugHerd}
          description={strings.hintDescriptionInstallBugHerd}
        />
      )}
    </div>
  );
};

export default InstallBugherd;
