import React, { FC } from 'react';
import * as translations from '../design_assets/strings';
import { getLangKey } from 'appJS/models/Language';
import styles from '../design_assets/index.module.css';
import { Button } from 'antd';
import { Share, ExternalLink } from 'lucide-react';

const strings = translations[getLangKey()];

type Props = {
  id: number;
  projectId?: number;
  url: string;
  disabled: boolean;
  onShareClick?: (event: any) => any;
  showShareHover?: boolean;
};

const AssetImage: FC<Props> = ({
  id,
  projectId,
  url,
  disabled,
  onShareClick,
  showShareHover
}) => {
  return disabled ? (
    <div className={styles.imgWrapper}>
      <img className={styles.grayscale} width={280} height={165} src={url} />
      <div className={styles.archived}>{strings.archived}</div>
    </div>
  ) : (
    <div className={styles.imgWrapper}>
      {onShareClick && showShareHover && (
        <HoverShareButton
          onShareClick={onShareClick}
          projectId={projectId}
          id={id}
        />
      )}
      <img width={280} height={165} src={url} />
    </div>
  );
};

export default AssetImage;

type HoverShareButtonProps = {
  onShareClick: (event: any) => any;
  projectId?: number;
  id: number;
};

export const HoverShareButton = ({
  onShareClick,
  projectId,
  id
}: HoverShareButtonProps) => {
  return (
    <div className={styles.slideInButtonWrapper}>
      <div className={styles.cardHoverInfoContainer}>
        {projectId && (
          <Button
            icon={<ExternalLink size={20} />}
            className={styles.openButton}
            type="primary"
            onClick={() =>
              window.open(`/projects/${projectId}/assets/${id}`, '_self')
            }
          >
            Open file
          </Button>
        )}
        <Button
          className={styles.cardShareButton}
          type="link"
          icon={<Share size={20} />}
          onClick={onShareClick}
        >
          Share
        </Button>
      </div>
    </div>
  );
};
