import * as React from 'react';
import { Button, Badge } from 'antd';
import { Filter } from 'lucide-react';
import styles from './index.module.css';
import FilterPanel from './FilterPanel';
import { getLangKey } from 'models/Language';
import { UserTaskFilter } from '../types';
import * as translations from './strings';
import cx from 'classnames';
import { FilterProvider, FilterType } from '../UserTasks/filterContext';
import { isEmpty, isUndefined } from 'lodash-es';
import { Organization } from 'models/Organization';

type Props = {
  className: string;
  onFilter: (filterParams: UserTaskFilter) => void;
  defaultFilter?: UserTaskFilter;
  organization: Organization;
};

const FilterTask: React.FC<Props> = ({
  className,
  onFilter,
  defaultFilter,
  organization
}) => {
  const strings = translations[getLangKey()];
  const noShowPanel = isEmpty(defaultFilter) || isUndefined(defaultFilter);

  const [showPanel, setShowPanel] = React.useState<boolean>(!noShowPanel);

  const filterCount = defaultFilter
    ? Object.keys(defaultFilter).filter((key: string) => !key.includes('End'))
        .length
    : 0;

  const filterRef: FilterType = React.useRef(null);

  return (
    <div ref={filterRef} className={cx(className, styles.filterPanelContainer)}>
      <Button
        className={cx(styles.filterButton, {
          [styles.filterNoPanelShow]: !showPanel,
          [styles.filterPressedButton]: showPanel
        })}
        onClick={() => setShowPanel(!showPanel)}
      >
        <div className={styles.filterButtonContainer}>
          {strings.filterButton}
          <span className={styles.filterIcon}>
            <Filter size={14} />{' '}
          </span>
          <Badge
            count={filterCount}
            size={'small'}
            className={styles.badgeCount}
          />
        </div>
      </Button>
      {showPanel && (
        <FilterProvider value={filterRef}>
          <FilterPanel
            onFilter={onFilter}
            defaultFilter={defaultFilter}
            organization={organization}
          />
        </FilterProvider>
      )}
    </div>
  );
};

export default FilterTask;
