import React, { FC } from 'react';
import { Button, Progress } from 'antd';
import styles from './index.module.css';
import { X as Close, Loader } from 'lucide-react';
import { FigmaImages } from './FigmaImages';
import cx from 'classnames';
import animationStyles from '../../../clients/sidebar/views/CreateTask/create_task/overlay/animation.module.css';
import { useFigmaUploadState } from '../providers/FigmaUpload';
import { useDesignAssetsState } from '../providers/DesignAssets';

export const FigmaUpload: FC = () => {
  const {
    nodes,
    setNodes,
    isImagesLoading,
    setShowUpload,
    uploadImages,
    handleSelect,
    selectedNodes,
    uploadedImages,
    progress
  } = useFigmaUploadState();
  const { setIsFigmaModalOpen } = useDesignAssetsState();

  return (
    <>
      <h2>Select screens to upload</h2>
      <div
        className={styles.closeContainer}
        onClick={() => {
          setIsFigmaModalOpen(false);
          setNodes([]);
          setShowUpload(false);
        }}
      >
        <Close className={styles.closeIcon} />
      </div>
      <div className={styles.uploadLinkWrapper}>
        <Button type="link" onClick={() => handleSelect(true)}>
          Select all
        </Button>
        <span>|</span>
        <Button type="link" onClick={() => handleSelect(false)}>
          Deselect all
        </Button>
      </div>

      <div className={styles.imagesWrapper}>
        {nodes.map(image => (
          <FigmaImages key={image.id} image={image} />
        ))}
      </div>

      <div className={styles.bottomModalWrapper}>
        <p>
          {selectedNodes.length} out of {nodes.length} screens selected
        </p>
        <Button
          onClick={uploadImages}
          disabled={selectedNodes.length === 0}
          type="primary"
        >
          Upload
        </Button>
      </div>
      <div
        className={cx(styles.loadingWrapper, {
          [styles.modalVisible]: isImagesLoading
        })}
      >
        <Loader className={animationStyles.loaderIcon} />
        <span>
          Uploading {Math.min(uploadedImages.length + 1, selectedNodes.length)}{' '}
          / {selectedNodes.length}
        </span>
        <Progress
          percent={progress}
          className={styles.progress}
          showInfo={false}
        />
      </div>
    </>
  );
};
