import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  Button,
  message as antmessage
} from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import SocialLogin from '../SocialLogin';
import { addCelloUccToFields } from '../shared/addCelloUccToFields';
import { post } from 'utils/fetch';
import { checkForExtension } from 'jsUtilities/checkForBugherdExtension';
import { countryCodes } from 'appJS/components/CountryCode/index';
import { Industry } from 'appJS/views/kanban/Onboarding/userSurveyOptions';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
const strings = translations[getLangKey()];

const searchParams = new URLSearchParams(window.location.search);
const isE2ETesting = 'Cypress' in window;

type RegistrationFormProps = {
  sitekey: string;
  authenticityToken: string;
  isReviewApp: boolean;
  cookies: any;
  hideSocialButtons?: boolean;
  landingCaptureUrl?: string | null;
};

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  sitekey,
  authenticityToken,
  isReviewApp,
  cookies,
  hideSocialButtons,
  landingCaptureUrl
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string | undefined>();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean>(
    false
  );
  const [countryCode, setCountryCode] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [form] = Form.useForm();
  const [formKey, setFormKey] = useState(0);
  const navigate = useNavigate();
  const utmSource = sessionStorage.getItem('utm_source') ?? '';
  const [isOtherIndustry, setIsOtherIndustry] = useState(false);

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      if (exists) {
        setIsExtensionInstalled(exists);
      }
    })();
    if (utmSource) {
      navigate(`/registrations/sign-up-email${utmSource}`);
    }
  }, []);

  const hiddenInputs = [
    ['real', 'yes'],
    ['terms', '1'],
    ['signup_segment', ''],
    ['commit', 'sign up for free'],
    ['landing_user_agent', encodeURIComponent(navigator.userAgent)],
    ['landing_capture_url', landingCaptureUrl ?? ''],
    ['experiments', searchParams.get('experiments') ?? ''],
    // eslint-disable-next-line new-cap
    ['time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone],
    ['experiments', searchParams.get('experiments') ?? '']
  ];

  const checkRecaptcha = !isReviewApp && !isE2ETesting;

  const handleFinish = async (values: any) => {
    if (!recaptcha && checkRecaptcha) {
      antmessage.warning('ReCaptcha is required to proceed.', 5);
      return;
    }

    // This happens at the last moment to give Cello all the time in the world to load
    values = await addCelloUccToFields(values);

    setLoading(true);

    post('/users/registrations', {
      utf8: '✓',
      authenticity_token: authenticityToken,
      'g-recaptcha-response': recaptcha,
      user: { extension_installed: isExtensionInstalled, ...values }
    })
      .then(body => {
        if (body.error && body.error.message) {
          throw Error(body.error.message);
        } else {
          window.location.href = body.redirect_to;
        }
      })
      .catch(({ message, status }) => {
        setLoading(false);
        setFormKey(prevKey => prevKey + 1); // Force a re-render of the form
        if (message === 'HTTP error! status: 409') {
          setRecaptcha(undefined);
          if (!recaptcha && checkRecaptcha) {
            antmessage.warning('ReCaptcha is required to proceed.', 5);
            return;
          } else {
            antmessage.error(
              <div>
                {strings.accountAlreadyExists}
                <a href="/users/sign_in">{strings.logInHere}</a>.
              </div>,
              5
            );
          }
        } else if (message === 'HTTP error! status: 500') {
          antmessage.error(strings.somethingWentWrong, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  const handleReCAPTCHA = (value: string | null) => {
    if (value) {
      setRecaptcha(value);
    } else {
      setRecaptcha(undefined);
      console.warn(strings.captchaExpired);
    }
  };

  const buildCookieFields = () => {
    const keys = Object.keys(cookies);

    return keys.map(key => {
      const name = 'landing_' + key;
      return (
        <Form.Item hidden name={name} key={name}>
          <Input type="hidden" />
        </Form.Item>
      );
    });
  };

  const buildHiddenInputs = () => {
    return hiddenInputs.map(info => {
      return (
        <Form.Item hidden name={info[0]} key={info[0]}>
          <Input type="hidden" />
        </Form.Item>
      );
    });
  };

  const getInitialValues = () => {
    const values = {};

    hiddenInputs.forEach(input => {
      // @ts-ignore
      values[input[0]] = input[1];
    });

    Object.keys(cookies).map(key => {
      const name = 'landing_' + key;

      // @ts-ignore
      values[name] = cookies[key];
    });

    const utmParams = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_term',
      'utm_content'
    ];
    utmParams.forEach(param => {
      const value = searchParams.get(param);
      if (value) {
        values[`landing_${param}`] = value;
        console.log(value);
      }
    });
    return values;
  };

  const handleSelectChange = value => {
    setCountryCode(value);
  };

  const handleInputChange = e => {
    setPhoneNumber(e.target.value);
  };

  const onSubmit = () => {
    form.setFieldsValue({
      phone_number: (countryCode || '') + (phoneNumber || '')
    });
  };

  const handleOrganizationChange = (value: string) => {
    setIsOtherIndustry(value === 'Industry not listed');
  };

  return (
    <Form
      className={styles.form}
      name="login"
      onFinish={handleFinish}
      layout="vertical"
      initialValues={getInitialValues()}
      form={form}
      key={formKey}
    >
      <Row gutter={20}>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="First name"
            name="name"
            rules={[{ required: true, message: strings.firstNameErrorMessage }]}
          >
            <Input placeholder="Bill" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={12}>
          <Form.Item
            label="Last name"
            name="surname"
            rules={[{ required: true, message: strings.lastNameErrorMessage }]}
          >
            <Input placeholder="Murray" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: strings.emailErrorMessage }]}
      >
        <Input type="email" placeholder="Your work email address" />
      </Form.Item>

      <Form.Item
        label="Organization"
        name="initial_organization_name"
        rules={[
          { required: true, message: strings.organizationNameErrorMessage }
        ]}
      >
        <Input placeholder="Your organization" />
      </Form.Item>

      <Form.Item
        label="Organization type"
        name="industry"
        rules={[
          { required: true, message: strings.organizationTypeErrorMessage }
        ]}
      >
        <Select
          placeholder="Please select"
          onChange={handleOrganizationChange}
          options={Industry}
        />
      </Form.Item>

      {isOtherIndustry && (
        <Form.Item
          label="Please specify industry"
          name="industry_other"
          rules={[{ required: true, message: 'Please specify industry' }]}
        >
          <Input placeholder="Your organization type" />
        </Form.Item>
      )}

      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: strings.passwordErrorMessage },
          { max: 100, message: 'maximum 100 characters' }
        ]}
      >
        <Input.Password placeholder="••••••" />
      </Form.Item>
      <Row gutter={20} align="top">
        <Col className={styles.countrySelect} span={8}>
          <Form.Item
            label="Phone number"
            name="country_code"
            rules={[
              {
                validator: (_, value) => {
                  if (phoneNumber && !value) {
                    return Promise.reject(strings.countryCodeError);
                  }
                  return Promise.resolve();
                }
              }
            ]}
            dependencies={['phone_without_code']}
          >
            <Select
              placeholder="+1"
              optionLabelProp="value"
              options={countryCodes}
              className={styles.countrySelect}
              onChange={handleSelectChange}
              dropdownStyle={{ width: '300px' }}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label=" "
            name="phone_without_code"
            rules={[
              { required: false },
              { max: 20, message: strings.max20Characters },
              {
                pattern: /^[0-9*#\-\(\)+\s]+$/,
                message: strings.phoneNumberErrorMessage
              }
            ]}
            validateTrigger="onChange"
            validateDebounce={0.3}
          >
            <Input placeholder="Optional" onChange={handleInputChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="phone_number" className={styles.hideVisibilty}>
        <Input type="tel" />
      </Form.Item>

      <Form.Item>
        <Checkbox name="newsletter">
          <div className={styles.subscribeCopy}>{strings.tickToSubscribe}</div>
        </Checkbox>
      </Form.Item>

      <ReCAPTCHA
        type="image"
        className={styles.recaptcha}
        onChange={handleReCAPTCHA}
        sitekey={sitekey}
      />
      {buildCookieFields()}
      {buildHiddenInputs()}

      <p className={styles.disclaimer}>
        {strings.bySigningUp}
        <a href="https://get.bugherd.com/terms" target="_blank" rel="noopener">
          {strings.termsOfService}
        </a>
        {strings.and}
        <a
          href="https://get.bugherd.com/privacy"
          target="_blank"
          rel="noopener"
        >
          {strings.privacyPolicy}
        </a>
        .
      </p>

      <Form.Item className={styles.submit}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          onClick={onSubmit}
        >
          {strings.startYourFreeTrial}
        </Button>
      </Form.Item>
      <a href="/users/sign_in" className={styles.accountLink}>
        {strings.alreadyHaveAnAccount}
      </a>

      {!hideSocialButtons && (
        <>
          <div className={styles.or}>
            <span>{strings.or}</span>
          </div>
          <SocialLogin authenticityToken={authenticityToken} />
        </>
      )}
    </Form>
  );
};

export default RegistrationForm;
