import React, { FC } from 'react';
import styles from '../bulk_mutliple/styles.module.css';
import ArrowUp from '../../../../../../clients/sidebar/assets/images/ic-upgrade-circle.svg';
import cx from 'classnames';

type Props = {
  isAssignees?: boolean;
  guestsAssignedToTasksAllowed?: boolean;
  billingRights?: boolean;
};

export const AssignGuestUpgrade: FC<Props> = ({
  isAssignees,
  guestsAssignedToTasksAllowed,
  billingRights
}) => {
  if (!isAssignees || guestsAssignedToTasksAllowed) return null;

  const url = new URL(window.location.href);
  url.pathname = '/subscription';

  const upgrade = 'Upgrade';
  const toGuests = ' to assign guests';
  const containerClassName = cx(styles.option, styles.upgradeOption);

  return billingRights ? (
    <a href={url.href} className={containerClassName} key={upgrade}>
      <ArrowUp className={styles.upgradeIcon} />
      <span className={styles.optionText}>
        <strong>{upgrade}</strong>
        <span className={styles.withLink}>{toGuests}</span>
      </span>
    </a>
  ) : (
    <div className={containerClassName} key={upgrade}>
      <ArrowUp className={styles.upgradeIcon} />
      <span className={styles.optionText}>
        {upgrade}
        {toGuests}
      </span>
    </div>
  );
};
