import React from 'react';
import { Link } from 'react-router-dom';

import { message, Button } from 'antd';

import { getLangKey } from 'models/Language';
import { setupProjectUrl } from '../../api/ProjectApi';

import { Project } from './types';

import * as translations from './strings';
import styles from './index.module.css';
import sampleWebsite from './assets/sample-website.png';

const strings = translations[getLangKey()];

const InvalidWebsite = ({ project }: { project: Project }) => {
  const handleUseTestSite = async () => {
    try {
      const response = await setupProjectUrl({
        projectId: project.id,
        updateProjectData: {
          name: project.name,
          devurl: 'https://bugherdexamplewebsite.webflow.io/',
          toggle_vertical_position: 90
        }
      });
      if (response.url) {
        window.location.href = '/welcome/preview';
      } else {
        message.error(strings.updateError);
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <div
      className={`${styles.tryBugherdOnContainer} ${styles.websiteErrorContainer}`}
    >
      <div className={styles.flexContainerWrapper}>
        <div className={styles.errorContentWrapper}>
          <h2>{strings.invalidWebsiteMessage}</h2>
          <div
            className={`${styles.errorContentWrapper} ${styles.withSpacing}`}
          >
            <p className={styles.invalidUrlMessage}>
              {strings.invalidWebsiteToSampleWebsiteMessage}
            </p>
            <div onClick={handleUseTestSite} className={styles.stepCard}>
              <img
                height={100}
                width={100}
                src={sampleWebsite}
                alt={strings.websiteFeedback}
              />
              <div className={styles.stepCardWrapper}>
                <h4>{strings.tryBugHerdOnASampleWebsite}</h4>
                <p>{strings.sampleUrl}</p>
              </div>
            </div>
            <Link to="/website-feedback">
              <Button type="text">{strings.tryADifferentWebsite}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidWebsite;
