import React, { FC, useEffect, useState } from 'react';
import { message, Switch } from 'antd';
import { Tag } from 'lucide-react';

import styles from './tags.module.css';
import * as tagApiRequests from './TagsApiRequest';
import TagListItem from './TagListItem';
import { getLangKey } from 'models/Application';
import Loader from 'components/loader';
import { getSortedTags } from './SortUtil';
import { getCurrentProjectId } from 'models/Organization';
import track from 'jsUtilities/analytics';

import * as translations from './strings';
import { useMutation } from '@apollo/client';
import { UPDATE_PROJECT } from '../../queries';

type Props = {
  projectId: number;
  createTagsViaNewTask: boolean;
};

type Tag = {
  active: boolean | null;
  name: string;
  id: number;
};

const EditTags: FC<Props> = ({ projectId, createTagsViaNewTask }) => {
  const strings = translations[getLangKey()];
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagsLoading, setTagsLoading] = useState(true);

  const preventCreatingNewTags = !createTagsViaNewTask;

  const [updateProject, { loading: updateProjectLoading }] = useMutation(
    UPDATE_PROJECT
  );

  const handleOnChange = (checked: boolean) => {
    updateProject({
      variables: { projectId, createTagsViaNewTask: !checked }
    });
  };

  useEffect(() => {
    setTagsLoading(true);
    tagApiRequests.fetchTagsRequest().then(payload => {
      setTagsLoading(false);
      if (payload.error) {
        message.error(strings.errorApi);
      } else {
        setTags(payload.data);
      }
    });
    track('Edit tags popup', { project_id: getCurrentProjectId() });
  }, []);

  const updateTagArray = (id, name, active = null) => {
    const previousTagList = [...tags];
    const updatedTagIndex = previousTagList.findIndex(tag => tag.id === id);
    if (name === null) {
      previousTagList[updatedTagIndex].active = active;
    } else {
      previousTagList[updatedTagIndex].name = name;
    }
    setTags(previousTagList);
  };

  const updateTagName = (id, updatedTagName) => {
    tagApiRequests
      .updateTagRequest({ id: id, name: updatedTagName })
      .then(payload => {
        if (payload.error) {
          message.error(strings.errorApi);
        } else {
          updateTagArray(id, updatedTagName);
        }
      });
  };

  const updateTagActive = (id, active) => {
    tagApiRequests.updateTagRequest({ id, active }).then(payload => {
      if (payload.error) {
        message.error(
          active ? strings.errorEnablingTag : strings.errorDisablingTag
        );
      } else {
        updateTagArray(id, null, active);
        message.success(
          active ? strings.successEnablingTag : strings.successDisablingTag
        );
      }
    });
  };

  const getTagItems = tag => (
    <TagListItem
      key={tag.id}
      tagName={tag.name}
      tagCount={tag.taskCount || 0}
      updateTagName={updatedTagName => updateTagName(tag.id, updatedTagName)}
      activeTag={tag.active}
      updateTagActive={active => updateTagActive(tag.id, active)}
    />
  );

  const archivedTags = getSortedTags(tags).filter(tag => !tag.active);
  const activeTags = getSortedTags(tags).filter(tag => tag.active);

  const renderListOrEmptyMessage = () =>
    tags && tags.length > 0 ? (
      <ul className={styles.tagListScroll}>
        {activeTags.length > 0 && (
          <li className={styles.activeOuter}>
            {activeTags.map(tag => getTagItems(tag))}
          </li>
        )}
        {archivedTags.length > 0 && (
          <li className={styles.archivedOuter}>
            {[
              <div className={styles.archivedTagsDivider} key="divider">
                {strings.archivedSubHeader}
                <p>{strings.archivedDescription}</p>
              </div>,
              archivedTags.map(tag => getTagItems(tag))
            ]}
          </li>
        )}
      </ul>
    ) : (
      <div className={styles.tagEmptyContainer}>
        <h4 className={styles.tagEmptyHeader}>{strings.emptyHeader}</h4>
        <p className={styles.tagEmptyContent}>{strings.emptyContent}</p>
      </div>
    );

  return (
    <div className={styles.tagsContainer}>
      <div className={styles.switchContainer}>
        <Switch
          className={styles.createTagsSwitch}
          checked={preventCreatingNewTags}
          loading={updateProjectLoading}
          onChange={handleOnChange}
        />
        <span className={styles.switchLabel}>
          {strings.preventCreatingTags}
        </span>
      </div>
      {tagsLoading ? (
        <div className={styles.tagEmptyContainer}>
          <Loader />
        </div>
      ) : (
        renderListOrEmptyMessage()
      )}
    </div>
  );
};

export default EditTags;
