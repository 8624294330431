import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import { NavItemProps } from '../types';

const transformedCount = (count: number | string): string => {
  if (typeof count === 'number') {
    return count > 99 ? '99+' : count.toString();
  }
  return count;
};

export const NavItem: FC<NavItemProps> = ({
  item: { label, key, icon, withDivider, count, className, showIndicator },
  onClick,
  collapsed,
  active,
  view
}) => {
  // The task collection resets when switching between views and performs several recalculations of kanban & feedback task counts.
  // Let's use the application loading state to only show the final recalculated task count.

  const [loading, setLoading] = useState(
    // @ts-expect-error
    window.bugherd.application.get('loading')
  );

  useEffect(() => {
    if (!loading) return;
    const MAX_INTERVAL_TIME = 5000;
    let currentTimeInterval = 0;
    const interval = setInterval(() => {
      // @ts-expect-error
      setLoading(window.bugherd.application.get('loading'));
      currentTimeInterval += 50;
      if (currentTimeInterval >= MAX_INTERVAL_TIME || !loading) {
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);

  const showCount = count !== 0 && count && !collapsed;
  const isSetupGuide = label === 'Setup guide';

  return (
    <div
      key={label}
      id={`nav-item-${key}`}
      className={cx(styles.itemAndCount, {
        [styles.active]: active,
        [styles.withDivider]: withDivider,
        [styles.collapsed]: collapsed
      })}
    >
      {showIndicator && <div className={styles.pulseIndicator} />}
      <Button
        type="text"
        size="small"
        shape="round"
        className={cx(styles.navItem, className)}
        icon={icon}
        onClick={onClick}
        title={`${label}${count ? ` (${count})` : ''}`}
      >
        {!collapsed && <span className={styles.navItemLabel}>{label}</span>}
      </Button>
      {showCount ? (
        <span
          className={cx(styles.count, `${key}-task-count`, {
            [styles.hideCount]: !isSetupGuide && (view === 'archive' || loading)
          })}
        >
          {transformedCount(count)}
        </span>
      ) : null}
    </div>
  );
};
