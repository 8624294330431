import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TryBugherdOn } from './TryBugherdOn';
import WebsiteFeedback from './WebsiteFeedback';
import PdfOrImageFeedback from './PdfOrImageFeedback';
import FigmaFeedback from './FigmaFeedback';
import NameYourProject from './NameYourProject';
import InvalidWebsite from './InvalidWebsite';
import Preview from './Preview';
import { BeforeWeBegin } from 'jsViews/onboarding/BeforeWeBegin';

export default (props: any) => {
  const [projectUrl, setProjectUrl] = useState<string>('');

  return (
    <BrowserRouter basename="/welcome">
      <Routes>
        <Route path="/try-bugherd-on" element={<TryBugherdOn {...props} />} />
        <Route path="/before-we-begin" element={<BeforeWeBegin {...props} />} />

        <Route
          path="/website-feedback"
          element={
            <WebsiteFeedback
              projectUrl={projectUrl}
              setProjectUrl={setProjectUrl}
              {...props}
            />
          }
        />
        <Route path="/figma-feedback" element={<FigmaFeedback {...props} />} />
        <Route
          path="/pdf-or-image-feedback"
          element={<PdfOrImageFeedback {...props} />}
        />
        <Route
          path="/preview"
          element={<Preview {...props} projectUrl={projectUrl} />}
        />
        <Route path="/website-error" element={<InvalidWebsite {...props} />} />
        <Route
          path="/name-your-project"
          element={<NameYourProject {...props} />}
        />
      </Routes>
    </BrowserRouter>
  );
};
