import * as React from 'react';

import { useGetOrganizationUsers } from '../query';
import { SelectItemProps } from '../../../MultiSelectList';
import useFetchPage from '../../../hooks/useFetchPage';
import useFilterByName from '../../../../../hooks/useFilterByName';

const PAGE_SIZE: number = 100;

const useRequesterFilter = ({ organizationId }: { organizationId: string }) => {
  const limit = PAGE_SIZE;

  const [selectOption, setSelectOption] = React.useState<SelectItemProps[]>([]);
  const [totalPageNumber, setTotalPageNumber] = React.useState<number>(0);

  const { pageNumber, setPageNumber, fetchNextPage } = useFetchPage({
    totalPageNumber
  });

  const { filter, setSearchByName } = useFilterByName({
    onChange: () => setPageNumber(1)
  });

  const { data, loading, error, refetch } = useGetOrganizationUsers({
    organizationId,
    offset: (pageNumber - 1) * limit,
    limit,
    filter
  });

  React.useEffect(() => {
    if (loading || !data) return;

    const { usersCount, users } = data.currentUser.organization;
    setTotalPageNumber(Math.ceil(usersCount / limit));

    const otherUsers = users.filter(({ id }) => data.currentUser.id !== id);

    setSelectOption(
      otherUsers.map(({ name: label, id: value }) => ({
        label,
        value
      }))
    );
  }, [data]);

  return {
    selectOption,
    fetchNextPage,
    setSearchByName,
    loading,
    error,
    refetch
  };
};

export default useRequesterFilter;
