import React, { FC, useState } from 'react';
import styles from './../index.module.css';
import { Modal, Button, Form, message, Collapse, Flex } from 'antd';
import * as translations from './../strings';
import { getLangKey } from 'appJS/models/Language';
import { validateUrl } from 'utils/validateUrls';
import { stripUrlProtocol } from 'utils/projectSettingsOperations';
import ProjectUrlInput from '../../../javascript/components/projectUrlInput';
import { addProjectSites, editProjectSites } from './ProjectSites';
import { HelpCircle as HelpIcon, Info } from 'lucide-react';
import ExtensionInstallButton from '../../../javascript/components/onboarding/install_button';
import { detect, BrowserInfo, Browser } from 'detect-browser';
import {
  browserConfig,
  BrowserConfigType
} from '../../../javascript/components/onboarding/extensionConfig';
import EmbedCode from '../../../javascript/components/embed_code';
import { useProjectSitesState } from '../../providers/ProjectSites';
import { fetchWithTimeout } from './../util';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLElement;
  isWebsiteModalOpen: boolean;
  handleCloseWebsiteModal: () => void;
  activeItem: 'extension' | 'javascript' | '';
  title?: string;
  projectSiteUrl?: string | undefined;
  projectUrlUpdating?: boolean;
  projectSiteId?: string;
  project?: any;
  hideInstallInstructions?: boolean;
};

const AddWebsiteModal: FC<Props> = ({
  container,
  isWebsiteModalOpen,
  handleCloseWebsiteModal,
  activeItem,
  title,
  projectSiteUrl,
  projectUrlUpdating,
  projectSiteId,
  hideInstallInstructions = false
}) => {
  const [projectUrl, setProjectUrl] = useState<string | undefined>(
    projectSiteUrl || ''
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();
  const projectBoardProps = window?.project_board_props;
  const project = projectBoardProps?.assetFeedbackProps?.project;
  const apiKey = project?.apiKey;
  const projectId = project?.id;
  const browser: BrowserInfo = detect();
  const currentBrowser: Browser = browser.name;

  const { widgetCode } = project || {};

  const { handleGetProjectSites, proxyHost } = useProjectSitesState();
  const hasNoProjectSites = !project?.websites || !project?.websites.length;

  const checkIsCompatibleSite = async projectSite => {
    const compatibleEndpoint = `https://${project?.apiKey}_${projectSite?.site?.id}.${proxyHost}`;
    try {
      const response = await fetchWithTimeout(compatibleEndpoint);
      const { compatible } = await response;
      return !!compatible;
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line no-undef
      window?.bugsnagClient?.notify(error);
      return false;
    }
  };

  const handleSubmitUrl = async () => {
    try {
      setIsSubmitting(true);
      await form.validateFields(['WebsiteURL']);
      const { status } = await validateUrl(projectUrl);

      if (status === 'success' || status === 'warning') {
        let response;
        if (projectUrlUpdating && projectSiteId) {
          response = await editProjectSites(
            apiKey,
            projectUrl,
            projectSiteId,
            projectId
          );
        } else {
          response = await addProjectSites(apiKey, projectUrl, projectId);
        }

        if (response?.error) {
          message.error(
            'There was an error with your website url. Please try again.'
          );
        } else if (response?.project_site) {
          message.info('Your website has been updated');
          setProjectUrl(response?.project_site?.url);
        } else {
          setProjectUrl('');
          form.setFieldsValue({ WebsiteURL: '' });
          message.success('Website added successfully');

          // we only want to redirect on the first project site added
          //this will be redirected to the PreviewPage only if the checklist_onboarding or add_website_onboarding experiment is enabled
          // eslint-disable-next-line max-depth
          if (hasNoProjectSites && hideInstallInstructions) {
            const isCompatible = await checkIsCompatibleSite(response);
            // eslint-disable-next-line max-depth
            if (isCompatible) {
              const endpoint = `/projects/${projectId}/sites/${response?.site?.id}?apikey=${apiKey}`;
              return (window.location.href = endpoint);
            }
          }
        }
        setIsSubmitting(false);
        if (response?.error) return;
        handleGetProjectSites();

        setTimeout(() => {
          handleCloseWebsiteModal();
        }, 500);
      }
    } catch (error) {
      setProjectUrl('');
      message.error(error);
      console.error(error);
      return;
    }
  };

  const getBrowserConfig: () => BrowserConfigType | null = () =>
    currentBrowser === null || currentBrowser === undefined
      ? null
      : browserConfig.find(browserInfo => browserInfo.name === currentBrowser);

  const collapseItems = [
    {
      key: 'extension',
      label: strings.useExtension,
      children: (
        <div className={styles.collapseWrapper}>
          <div className={styles.introWrapper}>
            <p>{strings.useBrowserExtension}</p>
            <b>{strings.useBrowserExtension2}</b>
            <p>{strings.useBrowserExtension3}</p>
          </div>
          <div className={styles.blueWrapper}>
            <Flex gap="small" align="center">
              <span
                className={styles.infoWrapper}
                data-elevio-article="33880"
                data-elevio-style="nothing"
              >
                <Info className={styles.infoIcon} />
              </span>{' '}
              <p>{strings.getTheExtensionFor}</p>
            </Flex>
            <ExtensionInstallButton browserConfig={getBrowserConfig()} />
          </div>
        </div>
      )
    },
    {
      key: 'javascript',
      label: strings.installJavascript,
      children: (
        <div className={styles.collapseWrapper}>
          <div className={styles.introWrapper}>
            <p>{strings.useJavascriptCode}</p>
            <a data-elevio-article="33871" href="">
              {strings.why}
            </a>
            <br />
            <p>
              {strings.addThisCode}
              <code className={styles.head}>{strings.head}</code>
              {strings.addThisCode2}
            </p>
          </div>
          <div className={styles.embedWrapper}>
            <EmbedCode widgetCode={widgetCode} />
          </div>
          {/* TODO: add logic so this works on a project site level. Currently this works only on the first devurl on a project */}
          {/* <Button
            className={styles.verify}
            type="default"
            loading={isCheckingSiteForScript}
            onClick={verifyJavascriptIsInstalled}
          >
            {responseError || isVerifiedSuccess
              ? strings.verifyAgainButton
              : strings.verify}
          </Button> */}
        </div>
      )
    }
  ];

  return (
    <Modal
      open={isWebsiteModalOpen}
      getContainer={container}
      destroyOnClose
      closable
      okText={strings.save}
      okType="primary"
      onCancel={() => {
        handleCloseWebsiteModal();
        setProjectUrl('');
      }}
      footer={null}
      centered
      className={styles.modalContent}
      wrapClassName={'antd-container'}
    >
      <div className={styles.addWebsiteModal}>
        <h2> {title || strings.addWebsite} </h2>
        <h4>{strings.websiteUrl}</h4>

        <Form
          form={form}
          className={styles.addWebsiteForm}
          onFinish={handleSubmitUrl}
        >
          <ProjectUrlInput
            fieldName={['WebsiteURL']}
            currentUrl={projectUrl}
            onChange={setProjectUrl}
            isUrlRequired
            showUrlPlaceHolder
          />
          {!hideInstallInstructions && (
            <div className={styles.setupWrapper}>
              <h4>{strings.setupOnWebsite}</h4>
              <p>
                {strings.bugherdSidebarAppear}{' '}
                <span
                  className={styles.linkWrapper}
                  data-elevio-article="84883"
                  data-elevio-style="nothing"
                >
                  <HelpIcon className={styles.linkIcon} />
                </span>
              </p>

              <Collapse
                accordion
                items={collapseItems}
                defaultActiveKey={[activeItem || '']}
                className={styles.collapse}
              />
            </div>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className={styles.saveButton}
            loading={isSubmitting}
            disabled={!stripUrlProtocol(projectUrl)}
            title={strings.addWebsite}
          >
            {strings.save}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default AddWebsiteModal;
