import { kanbanTaskPath } from 'app/routes';
import { Translations } from '../strings';
import { UserTask, classNames } from '../types';
import * as render from './columns';
import styles from '../index.module.css';
import { SortOrder } from 'antd/lib/table/interface';
import * as sortTask from '../sort';

export const getUserTasksTableColumnsConfig = (strings: Translations) => [
  {
    title: strings.projectLabel,
    dataIndex: 'project.name',
    key: 'project.name',
    className: styles[classNames.column.project],
    render: render.projectName,
    width: 150,
    ellipsis: true,
    defaultSortOrder: sortTask.defaultSortOrder('projectName') as SortOrder,
    sorter: true
  },
  {
    title: strings.taskLabel,
    dataIndex: 'description',
    key: 'description',
    className: styles[classNames.column.task],
    render: render.description,
    ellipsis: true
  },
  {
    title: strings.statusLabel,
    dataIndex: 'statusName',
    key: 'statusName',
    className: styles[classNames.column.status],
    render: render.status,
    width: 105,
    ellipsis: true,
    defaultSortOrder: sortTask.defaultSortOrder('statusName') as SortOrder,
    sorter: true
  },
  {
    title: strings.reportedByLabel,
    dataIndex: 'requesterName',
    key: 'reportedBy',
    className: styles[classNames.column.reportedBy],
    render: render.reportedBy,
    defaultSortOrder: sortTask.defaultSortOrder('requesterName') as SortOrder,
    sorter: true,
    width: 130,
    ellipsis: true
  },
  {
    title: strings.reportedLabel,
    dataIndex: 'createdAt',
    key: 'createdAt',
    className: styles[classNames.column.reported],
    render: render.reported,
    defaultSortOrder: sortTask.defaultSortOrder('createdAt') as SortOrder,
    sorter: true,
    width: 130
  },
  {
    title: strings.dueDateLabel,
    dataIndex: 'dueAt',
    key: 'dueAt',
    className: styles[classNames.column.dueDate],
    render: render.dueDate,
    width: 115,
    defaultSortOrder: sortTask.defaultSortOrder('dueAt') as SortOrder,
    sorter: true
  },
  {
    title: strings.updatedLabel,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    className: styles[classNames.column.updatedAt],
    render: render.updatedAt,
    defaultSortOrder: sortTask.defaultSortOrder('updatedAt') as SortOrder,
    sorter: true,
    width: 155
  },
  {
    title: strings.assignedToLabel,
    dataIndex: 'assignedUsers',
    key: 'assignedUsers',
    className: styles[classNames.column.assignedTo],
    render: render.assignedUsers,
    width: 115
  }
];

export const onUserTasksTableRow = (userTask: UserTask) => ({
  onClick: () => {
    window.location.href = kanbanTaskPath({
      projectId: userTask.project.id,
      localTaskId: userTask.localTaskId
    });
  }
});
