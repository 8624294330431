import { getScreenshotServerUrl } from '../models/Application';

const screenshotsServerHealthUrl = screenshotServerUrl =>
  (screenshotServerUrl || getScreenshotServerUrl()) + 'health';

const checkHealth = screenshotServerUrl =>
  window
    .fetch(screenshotsServerHealthUrl(screenshotServerUrl))
    .then(response => (response.ok ? Promise.resolve() : Promise.reject()));

export default checkHealth;
