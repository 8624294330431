import React, { FC } from 'react';
import styles from '../index.module.css';
import {
  CanAccess,
  OrganizationMemberUser,
  ShareProjectEndpoints
} from '../types';
import UserList from '../../../components/userList';
import * as translations from '../manage_access/strings';
import { getLangKey } from '../../../models/Language';

const strings = translations[getLangKey()];

type Props = {
  authenticityToken: string;
  container: () => HTMLDivElement;
  project: {
    id: number;
    name: string;
  };
  endpoints: ShareProjectEndpoints;
  onRemovedUser: (id: number) => void;
  canAccess: CanAccess;
  users: OrganizationMemberUser[];
};

export const ProjectTeam: FC<Props> = ({
  authenticityToken,
  container,
  project,
  endpoints,
  onRemovedUser,
  canAccess,
  users
}) => {
  return (
    <div>
      <h3 className={styles.tabHeader}>
        <span>Project team</span>
      </h3>
      <p className={styles.userList}>{strings.followingUsersHaveAccess}</p>
      <UserList
        authenticityToken={authenticityToken}
        container={container}
        endpoints={endpoints}
        project={project}
        onRemovedUser={onRemovedUser}
        success={{
          resend: ` ${strings.hasBeenResent} ${project.name}`,
          remove: ` ${strings.hasBeenRemoved} ${project.name}`
        }}
        users={users}
        collaboratorsEnabled={canAccess.collaborators}
      />
    </div>
  );
};
