export const migratePlanUrl = ({
  organizationId,
  planId,
  additionalMembers,
  interval
}: {
  organizationId: string;
  planId: string;
  additionalMembers: number;
  interval: string;
}) => {
  const searchParams = new URLSearchParams({
    plan_id: planId,
    additional_members: additionalMembers.toString(),
    interval
  });
  return `/organizations/${organizationId}/migrate?${searchParams.toString()}`;
};

export const reactivateUrl = ({
  organizationId,
  planId
}: {
  organizationId: string;
  planId: string;
}) =>
  `/organizations/${organizationId}/confirm_reactivate_plan?plan_id=${planId}`;

export const subscribeUrl = ({
  organizationId,
  planId,
  additionalMembers
}: {
  organizationId: string;
  planId: string;
  additionalMembers: number;
}) =>
  `/organizations/${organizationId}/subscribe?plan_id=${planId}&additional_members=${additionalMembers}`;
