import React, { FC, useState } from 'react';
import { Dropdown, Menu, Radio } from 'antd';
import styles from './styles.module.css';
import ToolbarButton from '../ToolbarButton';

export type Status = {
  name: string;
  columnId: number;
  statusId: null | 6;
};

type Props = {
  statuses: Status[];
  getContainer: () => HTMLDivElement;
  onChange: (status: Status) => void;
  status?: Status;
};

const BulkStatus: FC<Props> = ({
  statuses,
  getContainer,
  status,
  onChange
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Dropdown
      className={styles.statusDropdown}
      trigger={['click']}
      getPopupContainer={getContainer}
      onOpenChange={setVisible}
      open={visible}
      placement="top"
      arrow={{ pointAtCenter: true }}
      overlayClassName={styles.bulkStatusOverlay}
      overlay={
        <Menu className={styles.statusMenu}>
          <Radio.Group name="bulkStatus">
            {statuses.map(({ name, columnId, statusId }) => {
              return (
                <Radio
                  key={`${name}${columnId}${statusId}`}
                  className={styles.statusMenuItem}
                  value={`${name}${columnId}${statusId}`}
                  onChange={() => {
                    onChange({ name, columnId, statusId });
                    setTimeout(() => setVisible(false), 400);
                  }}
                >
                  {name}
                </Radio>
              );
            })}
          </Radio.Group>
        </Menu>
      }
    >
      <ToolbarButton
        active={!!status}
        open={visible}
        label={status?.name.toLowerCase() || 'Status'}
        onClick={() => setVisible(!visible)}
      />
    </Dropdown>
  );
};

export default BulkStatus;
