import React, { FC } from 'react';
import { Radio } from 'antd';
import styles from './index.module.css';

type Props = {
  showArchived: boolean;
  setShowArchived: (showArchived: boolean) => void;
  numberOfActiveAssets: number;
};

export const ArchivedFilter: FC<Props> = ({
  showArchived,
  setShowArchived,
  numberOfActiveAssets
}) => {
  return (
    <Radio.Group
      className={styles.archivedFilter}
      defaultValue={true}
      value={showArchived}
      buttonStyle="outline"
      optionType="button"
      onChange={event => setShowArchived(event.target.value as boolean)}
      options={[
        {
          label: (
            <span className={styles.assetsFilterLabel}>
              Assets ({numberOfActiveAssets})
            </span>
          ),
          value: false
        },
        { label: 'Archived', value: true }
      ]}
    />
  );
};
