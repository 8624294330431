export const en = {
  title: `Additional members`,
  para: ({
    planName,
    price,
    interval
  }: {
    planName: string;
    price: number;
    interval: string;
  }) => `Add members to your ${planName} plan for $${price} per ${interval}.`,
  label: 'You currently have:',
  additionalMembers: 'Additional member(s)',
  confirmMessage: ({
    count,
    currentPlanName
  }: {
    count: number;
    currentPlanName: string;
  }) =>
    `You are about to add ${count} additional member(s) to your ${currentPlanName} plan.`,
  confirmMessage1: `Your card will be charged a pro-rated amount based on the remaining billing period.`,
  confirmButton: 'Confirm',
  cancelButton: 'Cancel',
  confirmRemoveMessage: ({
    count,
    currentPlanName
  }: {
    count: number;
    currentPlanName: string;
  }) =>
    `You are about to remove ${count} additional member(s) from your ${currentPlanName} plan.`,
  confirmRemoveMessage1: `Your new monthly charge will reflect this.`,
  upgrageMessageTitle: (newPlan: string) =>
    `We recommend upgrading to the ${newPlan} Plan.`,
  upgrageMessage: ({
    upgradePlan,
    upgradePlanPrice,
    currentPlan,
    additionalMembers,
    currentPlanAddPrice,
    upgradePlanSeats,
    planInterval
  }: {
    upgradePlan: string;
    upgradePlanPrice: number;
    currentPlan: string;
    additionalMembers: number;
    currentPlanAddPrice: number;
    upgradePlanSeats: number;
    planInterval: string;
  }) =>
    `A ${upgradePlan} Plan ($${upgradePlanPrice}/${planInterval}) will be cheaper than a ${currentPlan} Plan + ${additionalMembers} members ($${currentPlanAddPrice}/${planInterval}) and will give you ${upgradePlanSeats} seats. `,
  upgradeTo: (upgradePlan: string) => `Upgrade to ${upgradePlan}`,
  seatsOccupiedTitle: `All seats are currently occupied. You will need to remove users from your team before downgrading.`,
  removeUsers: `To remove users, go to the `,
  teamsPage: 'Team Settings',
  page: 'page',
  updatedAdditionalMembers: ({
    membersAdded,
    currentMembers
  }: {
    membersAdded: number;
    currentMembers: number;
  }) =>
    `${membersAdded} member(s) added. There are now ${currentMembers} additional member(s) on your plan.`,
  updateError: (errorMessage: string) =>
    `Failed updating additional members with error ${errorMessage}`,
  mo: 'mo',
  year: 'year',
  month: 'month',
  memberRemoveMessage: ({
    membersRemoved,
    currentMembers
  }: {
    membersRemoved: number;
    currentMembers: number;
  }) =>
    `${membersRemoved} member(s) removed. There are now ${currentMembers} additional member(s) on your plan.`
};

export type Translations = typeof en;
