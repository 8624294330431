import PropTypes from 'prop-types';

export const colors = ['blue', 'green', 'red', 'orange', 'white'];

export const ArchiveAllProps = {
  stage: PropTypes.string.isRequired,
  taskCount: PropTypes.number.isRequired
};

export const ArchiveSelectedProps = {
  selectedTaskList: PropTypes.array.isRequired,
  columnId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func.isRequired
};

export const Props = {
  column: PropTypes.shape({
    color: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    name: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired
  }),
  updateColumnName: PropTypes.func.isRequired,
  getColumnToDelete: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  isMinColumns: PropTypes.bool.isRequired,
  columnPosition: PropTypes.number,
  insertFieldForNewColumn: PropTypes.func,
  canManageColumn: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  archiveAllowed: PropTypes.bool,
  cards: PropTypes.array.isRequired,
  unfilteredTaskCount: PropTypes.number.isRequired
};

export const ARCHIVE_STAGE_INITIAL = 'initial';
export const ARCHIVE_STAGE_CONFIRMING = 'confirming';
export const ARCHIVE_ALL_MENU_KEY = 'archival_all';

export const ARCHIVE_TASK_LIMIT = 500;
export const AMOUNT_OF_ARCHIVED_ITEMS = 100;
