type Containers = {
  headerContainer: HTMLDivElement;
  container: HTMLDivElement;
  gettingStartedContainer: HTMLDivElement;
  boardContainer: HTMLDivElement;
  paneHead: HTMLDivElement;
  settingsBoard: HTMLDivElement;
};
export const getContainers = (isMobileView?: boolean): Containers => {
  const paneHead = document.querySelector(
    '.paneHeadOuter .pane-head'
  ) as HTMLDivElement;

  const searchBarOuter = document.getElementById(
    'searchBarOuter'
  ) as HTMLDivElement;

  const gettingStartedContainer = document.getElementById(
    'gettingStartedBoard'
  ) as HTMLDivElement;

  const boardContainer = document.getElementById(
    'designAssetsBoard'
  ) as HTMLDivElement;

  const container = document.getElementById(
    'project_board_container'
  ) as HTMLDivElement;

  const settingsBoard = document.getElementById(
    'settingsBoard'
  ) as HTMLDivElement;

  return {
    headerContainer: isMobileView ? searchBarOuter : paneHead,
    container,
    gettingStartedContainer,
    boardContainer,
    paneHead,
    settingsBoard
  };
};
