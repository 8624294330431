// api path

import { getApplicationDomainUrl } from '../models/Application';
import { getCurrentProjectId } from '../models/Organization';

const apiPrefix = '/api_v0';

export const apiAccountPath = `${apiPrefix}/account`;

export const apiBulkRequestPath = `${apiPrefix}/bulk_requests`;

export const apiEventPath = `${apiPrefix}/events`;

export const apiTagPath = `${apiPrefix}/tags`;

export const apiUserSurveyPath = `${apiPrefix}/user_surveys`;

export const taskErrorUrl = ({ taskId, bugherdUrl, projectId }) =>
  `${bugherdUrl || getApplicationDomainUrl()}/projects/${projectId ||
    getCurrentProjectId()}/tasks/${taskId}/add_screenshot_error`;

export const completedVideoIntroUrl = bugherdUrl =>
  `${bugherdUrl || getApplicationDomainUrl()}/actions/completed_video_intro`;
