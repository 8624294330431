export const en = {
  membersOnly: 'Members only',
  allUsers: 'All users',
  mentionedIn: 'Only members can be mentioned in private comments!',
  ok: 'Ok',
  make: 'To make ',
  membersOnlyComments: 'comments that only your members can see',
  upgradePremium: ', please upgrade to the Premium plan.',
  pleaseContact: ', please contact ',
  yourAccount: 'your account ',
  owner: 'owner',
  toggleForAllUsers: 'All users',
  toggleForMembersOnly: 'Members only',
  support: 'support'
};

export type Translations = typeof en;
