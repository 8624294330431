import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Input, Button, Alert } from 'antd';
import {
  antdForm,
  userType,
  matchType,
  locationType
} from '../../../utils/propTypes';

import sharedStyles from '../../../components/onboarding/shared.module.css';
import styles from './form.module.css';
import track from '../../../utils/analytics';

class UpdateForm extends React.Component {
  static propTypes = {
    form: antdForm,
    user: userType,
    endpoints: PropTypes.shape({
      saveUser: PropTypes.string.isRequired
    }).isRequired,
    authenticityToken: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    match: matchType,
    location: locationType,
    validationToken: PropTypes.string.isRequired
  };

  state = {
    loading: false,
    error: null
  };

  handleSubmit = event => {
    event.preventDefault();

    const {
      form: { validateFields }
    } = this.props;

    let validationErrors = false;

    validateFields(errors => {
      if (errors) {
        validationErrors = true;
      }
    });

    if (validationErrors) {
      return;
    }

    this.setState({
      loading: true,
      error: null
    });

    fetch(this.props.endpoints.saveUser, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        utf8: '✓',
        _method: 'post',
        commit: 'save settings',
        authenticity_token: this.props.authenticityToken,
        validation_token: this.props.validationToken,
        user: {
          password_confirmation: this.props.form.getFieldsValue(['password'])
            .password,
          password: this.props.form.getFieldsValue(['password']).password,
          surname: this.props.form.getFieldsValue(['lastName']).lastName,
          name: this.props.form.getFieldsValue(['firstName']).firstName,
          email: this.props.user.email
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(body => {
        if (body.error && body.error.messages) {
          throw Error(body.error.messages[0]);
        } else {
          track('Onboarding alert displayed', { type: 'update details' });
          this.props.navigate('/extension' + this.props.location.search);
        }
      })
      .catch(({ message }) => {
        this.setState({
          loading: false,
          error: message
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        layout="vertical"
        hideRequiredMark
        className={styles.form}
        onSubmit={this.handleSubmit}
      >
        {!!this.state.error && (
          <Alert message={this.state.error} type="error" showIcon />
        )}
        <Form.Item label="Email">
          <Input type="email" value={this.props.user.email} readOnly disabled />
        </Form.Item>
        <div className={styles.nameWrapper}>
          <Form.Item label="First name">
            {getFieldDecorator('firstName', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'First name is required'
                }
              ]
            })(
              <Input
                onChange={event =>
                  this.props.form.setFieldsValue({
                    firstName: event.target.value
                  })
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Last name">
            {getFieldDecorator('lastName', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Last name is required'
                }
              ]
            })(
              <Input
                onChange={event =>
                  this.props.form.setFieldsValue({
                    lastName: event.target.value
                  })
                }
              />
            )}
          </Form.Item>
        </div>
        <Form.Item label="Create a Password">
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Password is required'
              }
            ]
          })(
            <Input
              type="password"
              onChange={event =>
                this.props.form.setFieldsValue({
                  password: event.target.value
                })
              }
            />
          )}
        </Form.Item>
        <p className={sharedStyles.terms}>
          By updating your details you confirm that you have read and agree to
          our
          <br />
          <a href="https://get.bugherd.com/terms" target="_blank">
            terms of service
          </a>{' '}
          and{' '}
          <a href="https://get.bugherd.com/privacy" target="_blank">
            privacy policy
          </a>
          .
        </p>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            loading={this.state.loading}
          >
            Save details
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(UpdateForm);
