export const JobRole: string = 'select role';

export const Industry: string = 'select industry';

export const CompanySize: string = 'select company size';

export const UserType: string = 'select user type';

export const TeamType: string = 'internal / external';

export const Replace: string = 'select tool(s)';

export const MarketingSource: string = '';
