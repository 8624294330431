import { HarvestIntegration } from 'appJS/components/Integrations/types';
import { ReactNode } from 'react';
import { View } from '../project_board/project_nav/types';

export enum ActiveKey {
  General = 'general',
  Integrations = 'integrations',
  InstallBugherd = 'install_bugherd',
  GuestPermissions = 'guest_permissions',
  Security = 'security',
  Sidebar = 'sidebar',
  Tags = 'tags',
  CommitHook = 'commit_hook',
  ExportBugs = 'export_bugs',
  DisableDeleteProject = 'disable_delete_project',
  Advanced = 'advanced',
  TestProjectSidebar = 'test_project_sidebar'
}

export interface NewSettingsProps {
  projectInit: any;
  accessTo: any;
  integrations: IntegrationSettingsProps;
  bugherdUrl: string;
  bitBucketHook: string;
  gitHubHook: string;
  gitLabHook: string;
  user: any;
  activeKey: ActiveKey;
  setActiveKey: (activeKey: ActiveKey) => void;
  setView: (view: View) => void;
  authenticityToken: string;
  view: View | null;
  showAssetsPage: () => void;
  currentUser: {
    id: string;
    name: string;
    email: string;
    viewedGuestProjectBoard: boolean;
    role: string;
  };
}

export type IntegrationSettingsProps = {
  project?: {
    organizationId?: string;
    id: string;
    webhooks?: Object;
  };
  zapier?: {
    id: string;
  };
  legacyIntegrations?: object;
  paragonProjectId?: string;
  premiumAllowed?: boolean;
  paragonAllowed?: boolean;
  editingAllowed?: boolean;
  msTeamsEnabled: boolean;
  teamworkEnabled: boolean;
  bugherdUrl?: string;
  ownerEmail?: string;
  paragonEnabled?: boolean;
  harvestIntegration?: HarvestIntegration;
  trialUser?: boolean;
  webhooksPath?: string;
  apiKey?: string;
  connectors?: object[];
  user?: any;
  bitBucketHook?: string;
  gitHubHook?: string;
  gitLabHook?: string;
  hasParagonLoaded: boolean;
  currentUser: {
    id: string;
    name: string;
    email: string;
    viewedGuestProjectBoard: boolean;
    role: string;
  };
};

export type GetItemArgs = {
  key: string;
  children?: ReactNode;
  label: ReactNode;
};

export type TabItems = {
  project: any;
  accessTo: any;
  bugherdUrl: string;
  integrations?: IntegrationSettingsProps;
  user: any;
  bitBucketHook?: string;
  gitHubHook?: string;
  gitLabHook?: string;
  refetch: () => void;
  projectInit: any;
  hasParagonLoaded?: boolean;
  authenticityToken: string;
  showAssetsPage: () => void;
  currentUser: {
    id: string;
    name: string;
    email: string;
    viewedGuestProjectBoard: boolean;
    role: string;
  };
};

export type CommitProps = {
  bitBucketHook?: string;
  gitHubHook?: string;
  gitLabHook?: string;
  projectId: string;
  organizationId: string;
};
