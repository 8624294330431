import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { message } from 'antd';
import EmbedCode from '../../../components/embed_code';
import { getLangKey } from '../../../models/Application';
import * as translations from '../strings';

const JavascriptTab = ({ widgetCode }) => {
  const strings = translations[getLangKey()];

  message.config({
    top: 100,
    duration: 5,
    maxCount: 3
  });

  return (
    <div className={styles.javascriptOuter}>
      <div className={styles.emptyJavascript}>
        <div className={styles.aboutOuter}>
          <p className={styles.javascriptAbout}>
            Save your clients from needing to install the browser extension by
            adding this Javascript code to your site.
            <br />
            Note: BugHerd will only appear on your website for people who have
            access to this project.
          </p>
        </div>
        <div>
          <h3>{strings.installJsTitle}</h3>
          <div className={styles.stepOne}>
            <p className={styles.instruction}>
              Add this project's Javascript code to the <code>{`<head>`}</code>{' '}
              of your website.
            </p>
            <EmbedCode widgetCode={widgetCode} />
          </div>
        </div>
      </div>
    </div>
  );
};

JavascriptTab.propTypes = {
  widgetCode: PropTypes.string
};

export default JavascriptTab;
