import React, { FC, useEffect, useState } from 'react';
import { Check, Upload, Trash2 } from 'lucide-react';
import { getLangKey } from '../../../../../models/Language';
import { message, Menu } from 'antd';
import * as translations from './strings';
import cx from 'classnames';
import styles from './styles.module.css';
import sortUserFriendly, {
  SortableProject
} from '../../../../../../../javascript/models/project/sortUserFriendly';
import { useOrganizationState } from '../../../../../../providers/Organization';
import { ProjectList } from './ProjectList';
import Loader from '../../../../../components/Loader';

const strings = translations[getLangKey()];

type Props = {
  isAdminView?: boolean;
  isNewSidebar?: boolean;
  shareUrl: string;
  taskDelete: boolean;
  taskMoveToProject: boolean;
  projectId: number;
  deleteTask: (
    onError?: (error: Error) => void,
    onSuccess?: () => void
  ) => void;
  setOpen: (open: boolean) => void;
  confirmDelete: boolean;
  setConfirmDelete: (confirmDelete: boolean) => void;
  moveToProject: (projectId: number) => void;
};

const { Item, ItemGroup, Divider } = Menu;

export const MoreMenu: FC<Props> = ({
  isAdminView,
  isNewSidebar,
  shareUrl,
  taskDelete,
  taskMoveToProject,
  deleteTask,
  projectId,
  setOpen,
  confirmDelete,
  setConfirmDelete,
  moveToProject
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [recentProjects, setRecentProjects] = useState<SortableProject[]>([]);
  const [sortedProjects, setSortedProjects] = useState<SortableProject[]>([]);

  const {
    organization,
    loading: projectsLoading,
    error
  } = useOrganizationState();

  useEffect(() => {
    if (
      !taskMoveToProject ||
      projectsLoading ||
      error ||
      !organization ||
      !organization.projects.length
    )
      return;

    const groupedProjects = sortUserFriendly({
      projects: organization.projects.filter(({ showOnLive }) => showOnLive)
    });

    setRecentProjects(groupedProjects[0]);
    setSortedProjects(
      groupedProjects[1].filter(
        (groupedProject: SortableProject) => groupedProject.id !== projectId
      )
    );
  }, [organization, projectsLoading, error]);

  const onDeleteTask = () => {
    let _deleteTask = deleteTask;
    if (isNewSidebar)
      _deleteTask = deleteTask.bind(
        null,
        error => {
          console.error(error);
          message.error(strings.taskNotDeleted);
          setIsDeleting(false);
        },
        () => {
          message.success(strings.taskDeleted);
          setIsDeleting(false);
        }
      );
    setConfirmDelete(false);
    setIsDeleting(true);
    _deleteTask();
  };

  const copyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      message.success(strings.shareCopyMessage);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <Menu
      id="moreMenu"
      className={cx(styles.moreMenu, styles.moveMenu, {
        [styles.adminMenu]: isAdminView,
        [styles.moreMenuSidebar]: !isAdminView
      })}
      onBlur={() => setOpen(false)}
      onScroll={event => event.stopPropagation()}
    >
      {shareUrl && (
        <Item
          key="shareUrl"
          icon={
            isCopied ? (
              <Check className={styles.moreMenuItemIcon} />
            ) : (
              <Upload className={styles.moreMenuItemIcon} />
            )
          }
          onClick={copyShareUrl}
          className={styles.shareUrl}
        >
          {isCopied ? strings.copied : strings.copyShareUrl}
        </Item>
      )}
      {taskDelete && (
        <Item
          key="delete"
          icon={
            isDeleting ? (
              <Loader className={styles.deleteLoader} useDarkStyles />
            ) : (
              <Trash2 className={styles.moreMenuItemIcon} />
            )
          }
          danger={confirmDelete}
          className={cx(styles.delete, {
            [styles.confirmDelete]: confirmDelete
          })}
          onClick={confirmDelete ? onDeleteTask : () => setConfirmDelete(true)}
        >
          {isDeleting
            ? strings.deletingTask
            : confirmDelete
            ? strings.confirmDelete
            : strings.deleteTask}
        </Item>
      )}
      {taskMoveToProject &&
        (projectsLoading ? (
          <Item
            key="loading"
            icon={<Loader className={styles.deleteLoader} useDarkStyles />}
            disabled
            className={styles.loadingItem}
          >
            {strings.loading}
          </Item>
        ) : (
          <>
            <Divider />
            <ItemGroup key="g1" title={strings.moveToProject}>
              <ItemGroup key="g2" title={strings.recentProjects}>
                {recentProjects.map((project: SortableProject) => (
                  <Item
                    key={project.id}
                    onClick={() => moveToProject(project.id)}
                    className={styles.recentProjectItem}
                  >
                    {project.name}
                  </Item>
                ))}
              </ItemGroup>
              <ItemGroup key="g3" title={strings.allOtherProjects}>
                <ProjectList
                  projects={sortedProjects}
                  onClick={moveToProject}
                />
              </ItemGroup>
            </ItemGroup>
          </>
        ))}
    </Menu>
  );
};
