import React, { MutableRefObject } from 'react';
import Preview from '../../../../../javascript/components/video';
import Hint from 'appJS/components/Hint';
import hintStyles from 'appJS/components/Hint/index.module.css';
import { detect, BrowserInfo } from 'detect-browser';
import { VideoOff } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from '../../../../../javascript/models/Language';
import styles from './index.module.css';
import cx from 'classnames';

const browser: BrowserInfo = detect() as BrowserInfo;
const strings = translations[getLangKey()];

type Props = {
  url: string;
  isAdminView?: boolean;
  videoRef: MutableRefObject<null | HTMLElement>;
};

const Video: React.FC<Props> = props => {
  const { url, isAdminView } = props;

  const isSafari = browser?.name === 'safari';
  const isWebmFormat = url.endsWith('.webm');
  const cantPlayFormat = isSafari && isWebmFormat;

  const Message = () => (
    <div className={styles.messageOuter}>
      <span className={styles.videoOffOuter}>
        <VideoOff />
      </span>
      <p className={cx({ [styles.inSidebar]: !isAdminView })}>
        {strings.safariCantPlay}
        <a href="https://www.google.com.au/intl/en_au/chrome/" target="_blank">
          {strings.tryChrome}
        </a>
      </p>
    </div>
  );

  return (
    <div className={styles.previewOuter}>
      {cantPlayFormat ? (
        <Hint
          message={<Message />}
          showIcon={false}
          className={hintStyles.cantPlaySafari}
        />
      ) : (
        <Preview {...props} />
      )}
    </div>
  );
};

export default Video;
