import { taskErrorUrl } from '../routing/routes';
import { put, post } from '../utils/fetch';

export const updateTaskWithScreenshotError = (
  taskId,
  error,
  bugherdUrl,
  projectId,
  screenshotData
) => {
  if (screenshotData) {
    delete screenshotData.basicAuthUrl;
    delete screenshotData.basicAuthPresent;
  }

  return post(taskErrorUrl({ taskId, bugherdUrl, projectId }), {
    error,
    screenshot_data: screenshotData
  });
};

export const updateTaskWithScreenshotKey = async (
  updateTaskURL,
  finishName
) => {
  return put(updateTaskURL, {
    key: finishName
  });
};
