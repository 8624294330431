import { ApolloCache, FetchResult, gql } from '@apollo/client';
import { ProjectSiteData } from '../types';

const updateCacheOnCreateSite = (
  cache: ApolloCache<any>,
  mutationResult: FetchResult<{ createProjectSite: ProjectSiteData }>
) => {
  {
    const project = mutationResult.data?.createProjectSite.project;

    if (!project) {
      return;
    }

    const projectSite = mutationResult.data?.createProjectSite.projectSite;

    const projectId = cache.identify(project);
    cache.modify({
      id: projectId,
      fields: {
        sites(projectSiteRefs: any[], { readField }) {
          const newProjectSiteRef = cache.writeFragment({
            data: projectSite,
            fragment: gql`
              fragment NewProjectSite on ProjectSite {
                id
                url
              }
            `
          });
          if (
            projectSiteRefs.some(
              ref => readField('id', ref) === projectSite?.id
            )
          ) {
            return projectSiteRefs;
          }

          return [...projectSiteRefs, newProjectSiteRef];
        }
      }
    });
  }
};

export default updateCacheOnCreateSite;
