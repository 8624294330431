import React, { FC } from 'react';
import { Modal } from 'antd';
import styles from './index.module.css';
import { X as Close, Loader } from 'lucide-react';
import animationStyles from '../../../clients/sidebar/views/CreateTask/create_task/overlay/animation.module.css';
import { useDesignAssetsState } from '../providers/DesignAssets';

export const LoadingModal: FC = () => {
  const {
    container,
    isUploadingModalOpen,
    setIsUploadingModalOpen
  } = useDesignAssetsState();

  return (
    <Modal
      // @ts-expect-error
      open={isUploadingModalOpen}
      className={styles.uploadModal}
      getContainer={container}
      closable={false}
      footer={null}
      centered={true}
    >
      <div
        className={styles.closeContainer}
        onClick={() => setIsUploadingModalOpen(false)}
      >
        <Close className={styles.closeIcon} />
      </div>
      <div className={styles.uploadContentContainer}>
        <Loader className={animationStyles.loaderIcon} />
        <p>Uploading assets. This may take a moment.</p>
      </div>
    </Modal>
  );
};
