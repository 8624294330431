export const en = {
  nameIntro: (firstName: string) => `Hi ${firstName}! Welcome to BugHerd. `,
  whatFeedback: 'What do you want to try BugHerd on?',
  contentIntro:
    'Let’s walk through how to use BugHerd to leave feedback on a website, design or document.',
  website: 'Website',
  websiteFeedback: 'Get feedback on your live or staging website.',
  figma: 'Figma',
  figmaFeedback: 'See how BugHerd works on Figma designs.',
  pdfImage: 'PDF or image',
  pdfImageFeedback:
    'Get feedback on your designs, docs or marketing collateral.',
  whatWebsiteFeedback: 'What website do you want to try BugHerd on?',
  websiteFeedbackDescription:
    'Tip: Try BugHerd on your company website or favorite website.',
  step1: 'Step 1',
  next: 'Next',
  updateError: 'There was an error adding your website. Please try again.',
  back: 'Back',
  stepOne: 'Step 1',
  TryBugherdOnPdfImage: 'Try BugHerd on a PDF or image',
  uploadPdfImage:
    'Upload a PDF or image by dragging your file into the box below. ',
  dragAndDrop: 'Drag & drop to upload a file',
  validTypes: 'Valid file types include: .pdf .png .jpg',
  fileBrowser: 'Click here for file browser',
  whatWebsite: 'What website do you want to try BugHerd on?',
  dontWorry: "Don't worry, only you'll see BugHerd on the website.",
  needIdeas:
    'Need ideas? Test BugHerd on your company website or your favourite website.',
  importFigma: 'Import a design from Figma',
  sampleDesign:
    "Here's a sample design we've loaded to BugHerd for you to try.",
  sample: 'Sample file',
  loadingFigmaFile: 'Loading Figma file...',
  createYourFirstProject: 'Create your first project',
  createYourFirstProjectDescription:
    'In BugHerd, your websites, designs and other files are organised into projects. \nYou can have as many projects as you want.',
  projectName: 'Project name',
  continue: 'Continue',
  namePlaceholder: 'My Amazing Project',
  error: 'Something went wrong, please try again.',
  invalidWebsiteMessage: "We couldn't access that site",
  invalidWebsiteToSampleWebsiteMessage:
    'Not to worry, you can try BugHerd on our example site below:',
  tryBugHerdOnASampleWebsite: 'Try our example website 👉',
  sampleUrl: 'example.bugherd.com',
  tryADifferentWebsite: 'Try a different website',
  welcomeToBugHerd: ', welcome to BugHerd.',
  setupYourProject: "Let's setup your first project"
};

export type Translations = typeof en;
