import React, { FC } from 'react';
import styles from './styles.module.css';
import cx from 'classnames';
import { X as Close } from 'lucide-react';

type Position = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

type Props = {
  className: string;
  handleClick: () => void;
  useWhiteBackground?: boolean;
  position?: Position;
};

export const CloseModal: FC<Props> = ({
  className,
  handleClick,
  useWhiteBackground,
  position
}) => (
  <div
    className={cx(styles.closeContainer, className, {
      [styles.whiteBackground]: useWhiteBackground,
      [styles.positioned]: position
    })}
    style={position}
    onClick={handleClick}
  >
    <Close className={styles.closeIcon} />
  </div>
);
