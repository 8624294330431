import React, { useState } from 'react';
import { Button, message as antmessage } from 'antd';
import styles from './index.module.css';
import { get } from 'utils/fetch';
import * as translations from './register_sso_page/strings';
import { getLangKey } from 'appJS/models/Application';
import GoogleIcon from './login/images/ic-sso-google.svg';
import MicrosoftIcon from './login/images/ic-sso-microsoft.svg';
import { encodeWorkOSState } from './workos';

const strings = translations[getLangKey()];

export default ({ authenticityToken }: { authenticityToken: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ssoLoading, setSsoLoading] = useState<boolean>(false);

  const onSsoFinish = (provider: string) => {
    setSsoLoading(true);

    const ssoProvider = provider;

    get(`/users/sign_up/sso/auth/${ssoProvider}`, {
      utf8: '✓',
      authenticity_token: authenticityToken
    })
      .then(body => {
        if (body.error) {
          setSsoLoading(false);
        } else {
          window.location.href = body.auth_url;
        }
      })
      .catch(({ message }) => {
        setSsoLoading(false);
        if (message.includes('HTTP error!')) {
          antmessage.error(strings.thereWasAnError, 5);
        } else {
          antmessage.error(message, 5);
        }
      });
  };

  return (
    <div>
      <Button
        className={styles.submitSocialSso}
        type="default"
        size="large"
        onClick={() => onSsoFinish('GoogleOAuth')}
        loading={loading}
      >
        <GoogleIcon className={styles.socialSsoIcon} />
        {strings.signUpWithGoogle}
      </Button>
      <Button
        className={styles.submitSocialSso}
        type="default"
        size="large"
        onClick={() => onSsoFinish('MicrosoftOAuth')}
        loading={loading}
      >
        <MicrosoftIcon className={styles.socialSsoIcon} />
        {strings.signUpWithMicrosoft}
      </Button>
    </div>
  );
};
