import * as React from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { getLangKey } from 'models/Language';

import * as translations from './strings';
import styles from './index.module.css';

const ErrorRetry = ({ onClick }: { onClick: () => void }) => {
  const strings = translations[getLangKey()];

  return (
    <div className={styles.error}>
      <Button
        className={styles.errorButton}
        icon={<ReloadOutlined />}
        onClick={onClick}
      >
        {strings.retry}
      </Button>
    </div>
  );
};

export default ErrorRetry;
