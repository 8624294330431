import { useState, useEffect } from 'react';
import {
  getPreference,
  setPreferences
} from '../../../clients/sidebar/views/utils/getPreferences';
import track from '../utils/analytics';
import { FailedScreenshotData } from '../../clients/sidebar/components/screenshots';
import { requestNewAttachmentSlot } from '../utils/uploadToS3';
import { updateTaskScreenshot } from 'appJS/screenshotCapture';

type Parameters = {
  userId: number;
  projectId: number;
  organizationId: number;
  hasSelector: boolean;
  isNewSidebar?: boolean;
  basicAuthUrl?: string;
  onRegenerateScreenshot?: (key: string) => void;
};

export type HandleRegenerateScreenshotProps = {
  taskId: number;
  screenshotData: FailedScreenshotData;
  apiDomain: string;
  projectId: number;
  hasAccess: boolean;
  screenshotServerUrl: string;
  basicAuthUrl: string;
};

const useScreenshotView = ({
  userId,
  projectId,
  organizationId,
  hasSelector,
  isNewSidebar,
  basicAuthUrl,
  onRegenerateScreenshot
}: Parameters) => {
  const storageKey: string = `sidebar_project_${projectId}`;
  const isCroppedDefault: boolean = true;
  const getScreenshotView = () => {
    const settings = JSON.parse(localStorage.getItem(storageKey) || '{}');
    return settings.isCroppedView !== undefined
      ? settings.isCroppedView
      : isCroppedDefault;
  };

  const [isCroppedPreference, setIsCroppedPreference] = useState<
    boolean | undefined
  >();

  const [isCroppedView, setIsCroppedView] = useState<boolean | undefined>();

  const initPreferences = () => {
    (async () => {
      let preference: any = getScreenshotView();
      if (isNewSidebar) {
        preference = await getPreference({
          projectId,
          preference: 'isCroppedView'
        });
        if (typeof preference !== 'boolean') {
          preference = true;
        }
      }
      setIsCroppedPreference(preference);
      setIsCroppedView(hasSelector ? preference : false);
    })();
  };

  useEffect(initPreferences, []);

  useEffect(() => {
    if (!hasSelector) {
      if (isCroppedView) setIsCroppedView(false);
    } else if (isCroppedView !== isCroppedPreference) {
      setIsCroppedView(isCroppedPreference);
    }
  }, [hasSelector]);

  const handleScreenshotToggle = (checked: boolean) => {
    if (checked !== isCroppedView) {
      if (isNewSidebar) {
        setPreferences({
          projectId,
          preferences: { isCroppedView: checked }
        });
      } else {
        localStorage.setItem(
          storageKey,
          JSON.stringify(
            Object.assign(
              JSON.parse(localStorage.getItem(storageKey) || '{}'),
              { isCroppedView: checked }
            )
          )
        );
      }
      if (isCroppedPreference !== checked) setIsCroppedPreference(checked);
      if (isCroppedView !== checked) setIsCroppedView(checked);
      track(`Screenshot view: ${checked ? 'cropped' : 'standard'}`, {
        organization: organizationId,
        user: userId
      });
    }
  };

  const handleRegenerateScreenshot = async ({
    taskId,
    screenshotData,
    apiDomain,
    projectId,
    hasAccess,
    screenshotServerUrl,
    basicAuthUrl
  }: HandleRegenerateScreenshotProps) => {
    try {
      const s3Response = await requestNewAttachmentSlot(
        'screenshot',
        'screenshot',
        apiDomain,
        projectId
      );

      onRegenerateScreenshot?.(s3Response.key);
      s3Response.success = `${apiDomain}/sidebar/projects/${projectId}/tasks/${taskId}/set_screenshot_callback`;

      track('Screenshot Retry', {
        task: taskId,
        key: s3Response.key
      });

      const polyfillData = {
        ...screenshotData,
        basicAuthUrl,
        basicAuthPresent: !!basicAuthUrl,
        userId,
        organizationId,
        requestType: 'retry'
      };

      updateTaskScreenshot({
        taskId: taskId,
        isScreenshotServerAccess: hasAccess,
        s3Response,
        polyfillData,
        dataURI: undefined,
        dataRecording: undefined,
        captureError: 'capture error',
        uploadState: {},
        screenshotServerUrl,
        projectId: projectId,
        bugherdUrl: apiDomain,
        rescueScreenshotsEnabled: true,
        organizationId,
        userId
      });
    } catch (error) {
      if (window.bugsnagClient) {
        window.bugsnagClient.notify(error);
      } else {
        console.log(error);
      }
    }
  };

  return {
    handleScreenshotToggle,
    isCroppedView: !!isCroppedView,
    handleRegenerateScreenshot
  };
};

export default useScreenshotView;
