import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client';

import { Task } from 'models/Task';
import { cache } from './cache';

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  uri: '/graphql',
  headers: (() => {
    const csrfAttribute =
      document && document.querySelector('meta[name=csrf-token]');
    const csrf = csrfAttribute
      ? csrfAttribute.getAttribute('content')
      : undefined;
    return csrf ? { 'X-CSRF-Token': csrf } : undefined;
  })()
});

export const findLocalProject = (id: string) =>
  client.readFragment({
    id: `Project:${id}`,
    fragment: gql`
      fragment FindLocalProject on Project {
        id
      }
    `
  });

export const updateLocalProject = ({
  id,
  name
}: {
  id: string;
  name: string;
}) =>
  client.writeFragment({
    id: `Project:${id}`,
    fragment: gql`
      fragment UpdateLocalProject on Project {
        name
      }
    `,
    data: { name }
  });

export const findLocalTask = (id: string) =>
  client.readFragment({
    id: `Task:${id}`,
    fragment: gql`
      fragment FindLocalTask on Task {
        id
      }
    `
  });

export const updateLocalTask = (task: Task) =>
  client.writeFragment({
    id: `Task:${task.id}`,
    fragment: gql`
      fragment UpdateLocalTask on Task {
        description
      }
    `,
    data: task
  });

export default client;
