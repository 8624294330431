import { useState, useEffect } from 'react';
import {
  Column,
  Severity,
  Tag,
  TaskState,
  TaskStateActions,
  User
} from './types';
import { getPreference, setPreferences } from '../../utils/getPreferences';

type Arguments = {
  projectId: number;
  dispatch: (value: TaskStateActions) => void;
  isAdminView?: boolean;
  assignableUsers?: User[];
  availableTags: Tag[];
  taskState: TaskState;
  columns: Column[];
};

type TaskStateKey = 'assigneeIds' | 'tags' | 'priorityId' | 'columnId';
type TaskStateValue = number[] | Tag[] | Severity | number;

type Settings = {
  rememberSettings?: boolean;
  assigneeIds?: number[];
  columnId?: number;
  priorityId?: Severity;
  tags?: Tag[];
};

type UsePreferencesState = {
  rememberTaskSettings: boolean;
  handleCheckboxChange: (checked: boolean) => void;
  storePreference: (key: TaskStateKey, value: TaskStateValue) => void;
};

const usePreferences = ({
  projectId,
  dispatch,
  assignableUsers,
  availableTags,
  columns,
  taskState,
  isAdminView
}: Arguments): UsePreferencesState => {
  const [settings, setSettings] = useState<Settings>({});
  const [rememberTaskSettings, setRememberTaskSettings] = useState<boolean>(
    false
  );
  const [initState, setInitState] = useState<boolean>(false);

  useEffect(() => {
    const initSettings = async () => {
      const _settings = (await getPreference({
        projectId,
        isAdminView
      })) as Settings;

      setSettings(_settings);
    };

    initSettings();
  }, []);

  const storePreference = (key: TaskStateKey, value: TaskStateValue) => {
    if (rememberTaskSettings)
      setPreferences({
        projectId,
        isAdminView,
        // @ts-expect-error
        preferences: { [key]: value }
      });
  };

  const initTaskState = () => {
    if (rememberTaskSettings) {
      const preferences: Settings = {};
      const { assigneeIds, tags, priorityId, columnId } = settings;

      if (assigneeIds?.length) {
        const validAssignees = assignableUsers
          ? assigneeIds.filter(_id =>
              assignableUsers.find(({ id }) => id === _id)
            )
          : assigneeIds;

        if (validAssignees.length && assigneeIds.length > validAssignees.length)
          preferences.assigneeIds = validAssignees;

        dispatch({
          type: 'SetAssignees',
          assigneeIds: validAssignees
        });
      }

      if (tags?.length) {
        const validTags = tags.filter(({ id }) =>
          availableTags?.find(tag => tag.id === id)
        );

        if (validTags.length && tags.length > validTags.length)
          preferences.tags = validTags;

        dispatch({
          type: 'SetTags',
          tags: validTags
        });
      }

      if (priorityId !== undefined)
        dispatch({ type: 'SetSeverity', priorityId });

      if (columnId !== undefined) {
        const validColumn = columns.find(({ id }) => id === columnId);

        if (!validColumn) {
          preferences.columnId = undefined;
        } else {
          dispatch({ type: 'SetStatus', columnId });
        }
      }

      if (Object.keys(preferences).length)
        setPreferences({ projectId, isAdminView, preferences });
    }
  };

  const storeOrResetPreferences = (checked: boolean) => {
    const preferences: Settings = {};
    preferences.rememberSettings = checked;

    if (checked) {
      const { assignees, tags, status, severity } = taskState;
      if (assignees.length) preferences.assigneeIds = assignees;
      if (tags.length) preferences.tags = tags;
      if (severity !== undefined) preferences.priorityId = severity;
      if (status !== undefined) preferences.columnId = status;
    } else {
      preferences.assigneeIds = undefined;
      preferences.tags = undefined;
      preferences.priorityId = undefined;
      preferences.columnId = undefined;
    }

    setPreferences({ projectId, isAdminView, preferences });
  };

  const handleCheckboxChange = (checked: boolean) => {
    setRememberTaskSettings(checked);
    storeOrResetPreferences(checked);
  };

  useEffect(() => {
    if (settings?.rememberSettings) {
      setRememberTaskSettings(true);
    }
  }, [settings]);

  useEffect(() => {
    if (rememberTaskSettings && !initState) {
      initTaskState();
      setInitState(true);
    }
  }, [rememberTaskSettings]);

  return {
    rememberTaskSettings,
    handleCheckboxChange,
    storePreference
  };
};

export default usePreferences;
