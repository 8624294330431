import React, { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import styles from './index.module.css';

const { Content, Sider } = Layout;

type Props = {
  children: ReactNode;
  siderChildren?: ReactNode;
};

const UserPageLayout: FC<Props> = ({ children, siderChildren }) => {
  return (
    <Layout className={styles.layout}>
      {siderChildren && (
        <Sider theme="light" width={300} className={styles.sider}>
          {siderChildren}
        </Sider>
      )}
      <Content>{children}</Content>
    </Layout>
  );
};

export default UserPageLayout;
