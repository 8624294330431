import moment from 'dayjs';
import queryString from 'query-string';

import { DATE_FORMAT } from 'models/Application';

import { UserTaskFilter } from '../types';
import { NavigateFunction } from 'react-router';

function getDatePart(date: string | undefined): string | undefined {
  return date ? moment(date).format(DATE_FORMAT) : undefined;
}

function getQueryString(searchParams: UserTaskFilter): string {
  const queryParams = {
    projectId: searchParams.projectId,
    description: searchParams.description,
    severityId: searchParams.priorityId,
    status: searchParams.status,
    reportedById: searchParams.requesterId,
    dueAtStart: getDatePart(searchParams.dueAtStart),
    dueAtEnd: getDatePart(searchParams.dueAtEnd),
    createdAtStart: getDatePart(searchParams.createdAtStart),
    createdAtEnd: getDatePart(searchParams.createdAtEnd),
    updatedAtStart: getDatePart(searchParams.updatedAtStart),
    updatedAtEnd: getDatePart(searchParams.updatedAtEnd)
  };

  return queryString.stringify(queryParams, { arrayFormat: 'comma' });
}

function parseForStartDate(date: string): string {
  return moment(date)
    .startOf('day')
    .toISOString(true);
}

function parseForEndDate(date: string): string {
  return moment(date)
    .endOf('day')
    .toISOString(true);
}

type ReturnSearchParams = [
  UserTaskFilter,
  (userTaskFilter: UserTaskFilter) => void
];

export default function useSearchParams({
  pathname,
  search,
  navigate
}: {
  pathname: string;
  search: string;
  navigate: NavigateFunction;
}): ReturnSearchParams {
  const params = queryString.parse(search, {
    arrayFormat: 'comma'
  });

  let searchParams: UserTaskFilter = {};

  if (params['projectId']) {
    searchParams.projectId = Array.isArray(params['projectId'])
      ? params['projectId']
      : [params['projectId']];
  }

  if (params['description']) {
    searchParams.description = params['description'] as string;
  }

  if (params['severityId']) {
    searchParams.priorityId = Array.isArray(params['severityId'])
      ? params['severityId']
      : ([params['severityId']] as string[]);
  }

  if (params['reportedById']) {
    searchParams.requesterId = Array.isArray(params['reportedById'])
      ? params['reportedById']
      : [params['reportedById']];
  }

  if (params['status']) {
    searchParams.status = Array.isArray(params['status'])
      ? params['status']
      : [params['status']];
  }

  if (params['dueAtStart']) {
    searchParams.dueAtStart = parseForStartDate(params['dueAtStart'] as string);
  }

  if (params['dueAtEnd']) {
    searchParams.dueAtEnd = parseForEndDate(params['dueAtEnd'] as string);
  }

  if (params['createdAtStart']) {
    searchParams.createdAtStart = parseForStartDate(
      params['createdAtStart'] as string
    );
  }

  if (params['createdAtEnd']) {
    searchParams.createdAtEnd = parseForEndDate(
      params['createdAtEnd'] as string
    );
  }

  if (params['updatedAtStart']) {
    searchParams.updatedAtStart = parseForStartDate(
      params['updatedAtStart'] as string
    );
  }

  if (params['updatedAtEnd']) {
    searchParams.updatedAtEnd = parseForEndDate(
      params['updatedAtEnd'] as string
    );
  }

  const setSearchParams = (searchParams: UserTaskFilter) => {
    const query = getQueryString(searchParams);
    navigate(`${pathname}?${query}`);
  };

  return [searchParams, setSearchParams];
}
