import React, { FC } from 'react';
import styles from './styles.module.css';

type Props = {
  url: string;
  onLoaded: () => void;
};

export const MSDocPreview: FC<Props> = ({ url, onLoaded }) => {
  return (
    <iframe
      id="msdoc-iframe"
      className={styles.msDocIFrame}
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        url
      )}`}
      width="100%"
      height="100%"
      style={{ border: 0 }}
      onLoad={onLoaded}
    />
  );
};
