import { TemplateColumn } from './';

export type ProjectTemplate = {
  columns: TemplateColumn[];
};

export const getProjectTemplate = async (orgId: number): Promise<ProjectTemplate> => {
  const res = await fetch(`/organizations/${orgId}/project_template`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return await res.json();
};

export const updateProjectTemplate = (
  orgId: number,
  columns: TemplateColumn[]
) => {
  return fetch(`/organizations/${orgId}/update_project_template`, {
    method: 'POST',
    body: JSON.stringify({ columns }),
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
};

const api = {
  getProjectTemplate,
  updateProjectTemplate
};

export default api;
