import React from 'react';
import { Radio } from 'antd';
import { FeedbackView, GetItem } from './types';
import styles from './styles.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import {
  FilterValues,
  SortAttributeValues,
  sortAttributeKeys,
  filterKeys,
  OrderValues,
  orders
} from './types';

const strings = translations[getLangKey()];

const getItem = ({ key, label, children }: GetItem) => ({
  key,
  className: styles.item,
  type: 'group',
  label,
  children: children.map(({ key, checked, text }) => ({
    key,
    label: (
      <Radio
        className={styles.radioItem}
        checked={checked}
        style={{ pointerEvents: 'none' }}
      >
        {text}
      </Radio>
    )
  }))
});

export const getItems = ({
  feedbackView,
  feedbackFilter,
  sortAttribute,
  feedbackOrder
}: {
  feedbackView?: FeedbackView;
  feedbackFilter: FilterValues;
  sortAttribute?: SortAttributeValues;
  feedbackOrder?: OrderValues;
}) => {
  let items = [
    getItem({
      key: 'filter',
      label: strings.filterBy,
      children: filterKeys.map(key => ({
        key,
        checked: feedbackFilter === FilterValues[key],
        text: strings.feedback[key]
      }))
    }),
    getItem({
      key: 'sort',
      label: strings.sortBy,
      children: sortAttributeKeys
        .filter(key => feedbackView === 'kanban' || key !== 'position')
        .map(key => ({
          key,
          checked: sortAttribute === SortAttributeValues[key],
          text: strings.sorting[key]
        }))
    })
  ];

  const canOrder = !(
    sortAttribute !== undefined &&
    sortAttributeKeys[sortAttribute] === 'position'
  );

  if (canOrder)
    items = [
      ...items,
      getItem({
        key: 'order',
        label: strings.orderBy,
        children: orders.map(key => ({
          key,
          checked: feedbackOrder === OrderValues[key],
          text: strings.order[key]
        }))
      })
    ];

  return items;
};
