import React, { FC } from 'react';
import cx from 'classnames';

type Props = {
  label?: string;
  symbol: string;
  className?: string;
};

const Emoji: FC<Props> = ({ label, symbol, className }) => (
  <span
    className={cx('emoji', className)}
    role="img"
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
);

export default Emoji;
