import React, { FC } from 'react';
import styles from './styles.module.css';
import { Button } from 'antd';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';
import Avatar, { Member } from '../../../shared/Avatar';
import cx from 'classnames';

const strings = translations[getLangKey()];

type Project = {
  name: string;
  owner?: Member;
};

type Props = {
  project: Project;
};

const QuickLinks: FC<Props> = ({ project }) => {
  const owner = project?.owner;

  return (
    <div className={styles.links}>
      <h2 className={styles.linksHeader}>{strings.linksHeader}</h2>
      <div className={cx(styles.linksInner)}>
        {owner && (
          <div className={styles.owner}>
            <div className={styles.ownerInfo}>
              <Avatar
                member={owner as Member}
                size="small"
                className={styles.avatar}
              />
              <span className={styles.ownerName}>
                {owner.name} ({strings.owner})
              </span>
            </div>
            <Button
              type="link"
              className={styles.contact}
              href={`mailto:${owner.email}`}
            >
              {strings.contact}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickLinks;
