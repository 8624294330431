import { v4 as uuid } from 'uuid';

const key = (projectId: number) => `sidebar_project_${projectId}`;
import iframeOrExtPostMessage from '../../../shared/utils/iframeOrExtPostMessage';
import { Tag } from '../CreateTask/create_task/types';
import { Emoji } from '../../../../javascript/components/EmojiPicker/types';

type PreferenceValue = boolean | number | string | number[] | Tag[] | Emoji[];

type Preferences = {
  [key: string]: PreferenceValue;
};

const PREFERENCES_GET_ERROR_MESSAGE = `Couldn't get user preferences from local storage`;
const PREFERENCES_SET_ERROR_MESSAGE = `Couldn't set user preferences in local storage`;

const getSidebarPreference = ({
  projectId,
  preference
}: {
  projectId: number;
  preference?: string;
}) =>
  new Promise((resolve, reject) => {
    try {
      const token = uuid().toString();
      const cb = (event: MessageEvent) => {
        if (
          event.data.EVENT_NAME === 'GET_LOCAL_STORAGE_RESPONSE' &&
          token === event.data.payload.token
        ) {
          window.removeEventListener('message', cb);
          const settings = JSON.parse(event.data.payload.localData);

          if (!settings) {
            resolve(undefined);
            return;
          }

          resolve(preference ? settings[preference] : settings);
          return;
        }
      };

      window.addEventListener('message', cb);
      iframeOrExtPostMessage(
        {
          EVENT_NAME: 'GET_LOCAL_STORAGE',
          payload: { key: key(projectId), token }
        },
        '*'
      );
    } catch (err) {
      console.error(PREFERENCES_GET_ERROR_MESSAGE, err);
    }
  });

const setSidebarPreferences = ({
  projectId,
  preferences
}: {
  projectId: number;
  preferences: Preferences;
}) => {
  try {
    getSidebarPreference({ projectId }).then((previousSettings: any) => {
      let newSettings: any = previousSettings || {};
      newSettings = { ...newSettings, ...preferences };
      iframeOrExtPostMessage(
        {
          EVENT_NAME: 'SET_LOCAL_STORAGE',
          payload: { key: key(projectId), value: JSON.stringify(newSettings) }
        },
        '*'
      );
    });
  } catch (err) {
    console.error(PREFERENCES_SET_ERROR_MESSAGE, err);
  }
};

const localKey = (projectId: number) => `projectSettings_${projectId}`;

const getLocalPreference = ({
  projectId,
  preference
}: {
  projectId: number;
  preference?: string;
}) =>
  new Promise((resolve, reject) => {
    try {
      const preferences = JSON.parse(
        window.localStorage.getItem(localKey(projectId)) || '{}'
      );
      resolve(preference ? preferences[preference] : preferences);
    } catch (err) {
      console.error(PREFERENCES_GET_ERROR_MESSAGE, err);
    }
  });

const setLocalPreferences = ({
  projectId,
  preferences
}: {
  projectId: number;
  preferences: Preferences;
}) => {
  try {
    getLocalPreference({ projectId }).then(previousSettings => {
      window.localStorage.setItem(
        localKey(projectId),
        JSON.stringify({ ...(previousSettings || {}), ...preferences })
      );
    });
  } catch (err) {
    console.error(PREFERENCES_SET_ERROR_MESSAGE, err);
  }
};

export const getPreference = async ({
  projectId,
  preference,
  isAdminView
}: {
  projectId: number;
  preference?: string;
  isAdminView?: boolean;
}) =>
  isAdminView
    ? getLocalPreference({ projectId, preference })
    : getSidebarPreference({ projectId, preference });

export const setPreferences = ({
  projectId,
  preferences,
  isAdminView
}: {
  projectId: number;
  preferences: Preferences;
  isAdminView?: boolean;
}) =>
  isAdminView
    ? setLocalPreferences({ projectId, preferences })
    : setSidebarPreferences({ projectId, preferences });
