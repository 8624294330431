export const en = {
  copyShareUrl: 'Copy Share URL',
  copied: 'Copied to Clipboard!',
  more: 'More',
  moveToProject: 'Move to Project',
  deleteTask: 'Delete Task',
  shareCopyMessage:
    'The Share URL for this task has been copied to your clipboard.',
  taskNotMoved: "We couldn't move the task, please try again.",
  taskNotDeleted: "We couldn't delete the task, please try again.",
  deletingTask: 'Deleting task...',
  taskDeleted: 'Task was successfully deleted',
  confirmDelete: 'Really delete?',
  allOtherProjects: 'All other projects',
  recentProjects: 'Recent Projects',
  loading: 'Loading...'
};

export type Translations = typeof en;
