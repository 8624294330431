import React, { useEffect, useRef } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'qs';
import Vimeo from '@vimeo/player';
import { locationType } from '../../../utils/propTypes';
import track from '../../../utils/analytics';
import { CheckCircle as Check } from 'lucide-react';
import styles from './style.module.css';
import sharedStyles from '../../../components/onboarding/shared.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';

const strings = translations[getLangKey()];

const Success = props => {
  const vimeoRef = useRef(null);

  useEffect(() => {
    track('Onboarding Complete');

    const player = new Vimeo(vimeoRef.current);

    player.on('play', () => {
      track('Onboarding video viewed', {});
    });

    player.on('ended', () => {});
  }, []);

  const getOrganizationId = () => {
    const parsed = queryString.parse(props.location.search);
    return parsed.organization_id;
  };

  const renderCTA = () => {
    const organizationId = getOrganizationId();
    let link = `/organizations`;
    let linkCopy = strings.goToYourOrganizations;

    if (props.project && props.project.devurl) {
      link = `${props.project.devurl}`;
      linkCopy = strings.goToYourWebsite;
    } else if (organizationId) {
      link = `/organizations/${organizationId}`;
      linkCopy = strings.viewYourProjects;
    }

    if (
      props.project &&
      props.project.id &&
      props.organizationMember.role !== 'guest'
    ) {
      link = `/projects/${props.project.id}`;
      linkCopy = strings.goToYourProject;
    }

    return (
      <a href={link}>
        <Button type="primary" size="large">
          {linkCopy}
        </Button>
      </a>
    );
  };
  const { role } = props.organizationMember;

  return (
    <div className={sharedStyles.container}>
      <div className={sharedStyles.header}>
        <Check className={styles.icon} />
        <h1 className={styles.heading}>{strings.youreReady}</h1>
        <p>{strings.quickVideo}</p>
        <div className={styles.videoContainer}>
          <iframe
            src={`https://player.vimeo.com/video/${
              role === 'guest' ? 329271950 : 329272793
            }`}
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
            ref={vimeoRef}
          />
        </div>
        {renderCTA()}
      </div>
    </div>
  );
};

Success.propTypes = {
  location: locationType,
  organizationMember: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  project: PropTypes.shape({
    devurl: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

export default Success;
