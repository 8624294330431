import { Translations } from './strings';
import * as render from './columns';

export const getStatementsTableColumnsConfig = (strings: Translations, organizationId: string) => [
  {
    title: strings.statementId,
    dataIndex: 'number',
    key: 'number',
    render: render.statementId,
    width: 150
  },
  {
    title: strings.statementDate,
    dataIndex: 'issued_at',
    key: 'issued_at',
    render: render.statementDate,
    width: 150
  },
  {
    title: strings.statementStatus,
    dataIndex: 'settled_at',
    key: 'settled_at',
    render: render.statementStatus,
    width: 105
  },
  {
    title: strings.statementTotal,
    dataIndex: 'total_in_cents',
    key: 'total_in_cents',
    render: render.statementTotal,
    width: 130
  },
  {
    title: ' ',
    dataIndex: 'id',
    key: 'id',
    render: (text: string, number: string) =>
      render.statementDownloadLink(text, organizationId, number),
    width: 100
  }
];
