import { Trigger } from './getShortcuts';

export const getPreAndPostMention = ({
  index,
  mention,
  description,
  trigger
}: {
  index: number;
  mention: string;
  description: string;
  trigger: Trigger | string;
}) => {
  const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
  let match;
  const mentions: {
    fullMatch: string;
    name: string;
    id: string;
    index: number;
  }[] = [];

  while ((match = mentionRegex.exec(description)) !== null) {
    mentions.push({
      fullMatch: match[0],
      name: match[1],
      id: match[2],
      index: match.index
    });
  }

  let preMention = description.substring(0, index) || '';
  const postMentionIndex = index + (mention ? mention.length : 0);
  let postMention = description.substring(postMentionIndex) || '';

  mentions.forEach(mention => {
    const mentionStart = mention.index;
    const mentionEnd = mention.index + mention.fullMatch.length;

    if (index > mentionStart && index < mentionEnd) {
      preMention = description.substring(0, mentionStart);
      postMention = description.substring(mentionEnd);
    }
  });

  if (trigger) {
    const triggerMention = trigger + mention;
    if (
      postMention.startsWith(triggerMention) &&
      postMention[triggerMention.length] !== '['
    ) {
      postMention = postMention.replace(triggerMention, '');
    }
  }

  return { preMention, postMention };
};
