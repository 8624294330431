import React, { useRef } from 'react';

import { Input, Switch, Button, Form, message } from 'antd';
import ProjectKey from 'components/ProjectKey';
import styles from './general.module.css';
import HelpArticleButton from 'utils/HelpArticleButton';

import ProjectSites from './ProjectSites';
import { getLangKey } from 'models/Language';
import { Project, ProjectFormField } from './types';
import * as translations from './strings';
import useProjectSites from './hooks/useProjectSites';
import useProjectMutation from './hooks/useProjectMutation';

interface Props {
  project: Project;
  onCancel?: () => void;
  refetch: () => void;
  showAssetsPage?: () => void;
}

const General: React.FC<Props> = (props: Props) => {
  const { project, refetch, showAssetsPage } = props;
  const strings = translations[getLangKey()];

  const [form] = Form.useForm();

  const [projectSites, setProjectSites] = useProjectSites(project);
  const [submit, { updateProjectLoading }] = useProjectMutation({
    project,
    projectSites,
    message,
    refetch,
    ...form
  });

  const ref = useRef(null);

  return (
    <div ref={ref}>
      <Form
        form={form}
        layout="vertical"
        onFinish={submit}
        hideRequiredMark
        className={styles.generalForm}
      >
        <Form.Item
          label={<span>Project Name:</span>}
          name={ProjectFormField.Name}
          initialValue={project.name}
          className={styles.projectName}
          rules={[
            {
              type: 'string',
              message: 'This is not a valid name'
            },
            {
              required: true,
              message: 'A project name is required'
            }
          ]}
        >
          <Input className={styles.nameInput} name="project[name]" />
        </Form.Item>
        <div className={styles.labelStyle}>
          <label>
            <h4>Website URL(s):</h4>
          </label>
          <p>
            <div className={styles.blueWrapper}>
              <HelpArticleButton articleId="33865" />
              <p>{strings.wereMovingWebsites}</p>

              <Button
                onClick={() => {
                  if (showAssetsPage) {
                    showAssetsPage();
                  }
                }}
                type="link"
              >
                {strings.checkItOut}
              </Button>
            </div>
          </p>
          <>
            <p className={styles.feedbaclUrlNote}>{strings.feedbackUrlNote}</p>
            <div className={styles.helpContainer}>
              <HelpArticleButton articleId={strings.urlsHelpId} />
              <HelpArticleButton
                articleId={strings.urlsHelpId}
                children={strings.projectUrlsHelp}
              />
            </div>
            <ProjectSites
              projectId={project.id}
              projectSites={projectSites}
              onChange={sites => setProjectSites(sites)}
              parentForm={form}
            />
          </>
        </div>

        <ProjectKey
          apiKey={props.project.apiKey}
          className={styles.labelStyle}
        />
        <div className={styles.switchWrapper}>
          <Form.Item
            className={styles.switchWrapper}
            label={
              <span className={styles.spanLabel}>
                Allow creating tasks via email{' '}
                <HelpArticleButton articleId="33902" />
              </span>
            }
            name={ProjectFormField.AllowEmailTasks}
            initialValue={project.allowEmailTasks}
            valuePropName="checked"
          >
            <Switch className={styles.switch} />
          </Form.Item>
          <Form.Item
            className={styles.switchWrapper}
            label={
              <span className={styles.spanLabel}>
                Allow commenting on tasks via email{' '}
                <HelpArticleButton articleId="33902" />
              </span>
            }
            name={ProjectFormField.AllowEmailComments}
            initialValue={project.allowEmailComments}
            valuePropName="checked"
          >
            <Switch className={styles.switch} />
          </Form.Item>
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            loading={updateProjectLoading}
          >
            {strings.save}
          </Button>
          {props.onCancel && (
            <Button
              className={styles.ghost}
              onClick={() => {
                props.onCancel();
              }}
            >
              {strings.cancel}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default General;
