type TooltipOuterCss = {
  borderRadius: number;
  maxWidth: number;
  minWidth: number;
  padding: string;
  fontSize: number;
  fontWeight: number;
  position: 'relative';
  borderTop: string;
};

export const tooltipOuterCss = function(
  priorityColor: string
): TooltipOuterCss {
  return {
    borderRadius: 2,
    maxWidth: 220,
    minWidth: 100,
    padding: '5px 10px 5px 5px',
    fontSize: 14,
    fontWeight: 500,
    position: 'relative',
    borderTop: `7px solid ${priorityColor}`
  };
};

type TaskIdCss = {
  fontSize: number;
  color: string;
};
type DescriptionOuterCss = {
  margin: string;
  lineHeight: string;
  fontSize: string;
  display: string;
  padding: string;
  minHeight: number;
};
type DescriptionInnerCss = {
  maxWidth: number;
  overflow: string;
  textOverflow: string;
  display: string;
};
type RequesterNameCss = {
  color: string;
  fontSize: number;
  fontWeight: number;
  padding: string;
  maxWidth: number;
  whiteSpace: 'nowrap';
  overflow: string;
  display: string;
  textOverflow: string;
};

export const taskIdCss: TaskIdCss = {
  fontSize: 12,
  color: 'rgb(170, 170, 170)'
};

export const descriptionOuterCss: DescriptionOuterCss = {
  margin: '4px 0px 5px',
  lineHeight: '20px',
  fontSize: 'inherit',
  display: 'flex',
  padding: '0px 0px 0px 24px',
  minHeight: 40
};

export const descriptionInnerCss: DescriptionInnerCss = {
  maxWidth: 170,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box'
};

export const requesterNameCss: RequesterNameCss = {
  color: '#7a8da0',
  fontSize: 12,
  fontWeight: 400,
  padding: '0px 0px 0px 24px',
  maxWidth: 190,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline-block',
  textOverflow: 'ellipsis'
};

export const avatarOuterCss: AvatarOuterCss = {
  background: '#d8d8d8',
  borderRadius: '50%',
  position: 'absolute',
  top: 'calc((100% - 53px) / 2)',
  left: '-26.5px',
  right: 'unset'
};

export const avatarLetterCss: AvatarLetterCss = {
  textAlign: 'center',
  width: 18.53,
  height: 20,
  marginRight: 1,
  lineHeight: '20px'
};

type AvatarLetterCss = {
  textAlign: 'center';
  width: number;
  height: number;
  marginRight: number;
  lineHeight: string;
};

type AvatarOuterCss = {
  background: string;
  borderRadius: string;
  position: 'absolute';
  top: string;
  left: string;
  right: string;
};

type AvatarContainerCss = {
  borderRadius: string;
  width: number;
  height: number;
  border: string;
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  backgroundColor?: string;
  color: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  fontSize: number;
  fontWeight: number;
};

export const avatarContainerCss = function(
  isGeneric: boolean,
  avatar: string,
  role: string
): AvatarContainerCss {
  const styles: AvatarContainerCss = {
    borderRadius: '50%',
    width: 45,
    height: 45,
    border: 'solid 4px white',
    color: '#373737',
    fontSize: 20,
    fontWeight: 600
  };

  if (isGeneric) {
    Object.assign(styles, {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    });
    if (role) {
      if (role === 'guest') {
        styles.backgroundColor = '#b5e7f9';
      } else if (role === 'collaborator') {
        styles.backgroundColor = '#bcdb91';
      } else {
        styles.backgroundColor = '#fbbaa8';
      }
    } else {
      styles.backgroundColor = 'rgb(cc, cc, cc)';
    }
  } else {
    Object.assign(styles, {
      backgroundImage: 'url(' + avatar + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'auto 55px'
    });
  }

  return styles;
};

export type MentionContainerCss = {
  borderRadius: '2px';
  backgroundColor: '#dbdbdb' | '#20c0e720';
  opacity: 0.7 | 1;
  padding: '0px 2px';
  whiteSpace: 'nowrap';
};

export const mentionCss = (isProjectMember: boolean): MentionContainerCss => {
  const styles: MentionContainerCss = {
    borderRadius: '2px',
    backgroundColor: '#dbdbdb',
    opacity: 0.7,
    padding: '0px 2px',
    whiteSpace: 'nowrap'
  };

  if (isProjectMember) {
    styles.backgroundColor = '#20c0e720';
    styles.opacity = 1;
  }

  return styles;
};

export type NotMentionContainerCss = {
  borderRadius: '2px';
  padding: '0px 2px';
  whiteSpace: 'nowrap';
  backgroundColor?: '#dbdbdb';
  opacity?: 0.7;
};

export const notMentionCss = (
  notFoundOrNotOnProject: boolean
): NotMentionContainerCss => {
  const styles: NotMentionContainerCss = {
    borderRadius: '2px',
    padding: '0px 2px',
    whiteSpace: 'nowrap'
  };

  if (notFoundOrNotOnProject) {
    styles.backgroundColor = '#dbdbdb';
    styles.opacity = 0.7;
  }

  return styles;
};
