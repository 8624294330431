export const en = {
  setupProjectTitle: `Welcome! Create your first project`,
  setupProjectVisibleStepTitle: `Welcome! Create your first project`,
  setupProjectTitleDone: (projectName: string) => `Project: ${projectName}`,
  installBugHerdTitle: 'Get the extension',
  installBugHerdVisibleStepTitle: 'Get the extension',
  installBugHerdTitleDone: 'BugHerd installed',
  tryOnWebsiteTitle: 'Try BugHerd on your website',
  tryOnWebsiteVisibleStepTitle: `Try BugHerd`,
  tryOnWebsiteTitleDone: 'Try BugHerd on your website',
  aboutYou: `Tell us a bit about you`,
  completeOnboardingError: 'There were errors to complete onboarding.',
  editLabel: 'Edit',
  tryBugHerdDescription:
    'Head to your website and click on the section or element you want to capture feedback on.',
  hintMessageTryBugHerd: 'Having trouble?',
  hintDescriptionTryBugHerd: `If the BugHerd sidebar doesn't appear, try click on the BugHerd browser extension icon in your browser for further help, or try change the project URL.`,
  tryBugHerdHelpArticleId: '33898',
  visitWebsite: 'Go to website',
  createProject: 'Create Project',
  projectNameLabel: 'Project name',
  projectUrlLabel: 'Website URL:',
  message: "Don't worry, BugHerd will only be visible to you.",
  emptyValidationMessage: 'Please complete this field',
  hintMessageProjectSetup: `What's a project?`,
  hintDescriptionProjectSetup: `A project is a website you're working on. You can have as many projects as you like, and invite different people to each one.`,
  inviteMembersPlaceholder:
    'Enter one or more email addresses, separated by commas',
  inviteMembersError: 'Errors occurred when inviting members.',
  hintMessageInviteTeam: `What’s a team member?`,
  hintDescriptionInviteTeamPara1: `In BugHerd, team members are your core team (think devs, Designers, QAs, etc). They can both give feedback & manage feedback from others.`,
  hintDescriptionInviteTeamPara2: `BugHerd also has Guest access, great for clients & stakeholders. You can find out more`,
  inviteTeamHelpArticleId: '84839',
  inviteMembers: 'Invite team members:',
  addMembers: 'Add members',
  doThisLater: 'do this later',
  moreInfo: 'More info',
  or: 'or',
  here: 'here',
  recommendedText: 'Recommended install:',
  installJsText: 'Install via Javascript code',
  installJsLabel1: `Add this project's Javascript code to the`,
  installJsLabel2: ` of your website. After that, click the ‘Verify’ button.`,
  verify: `Verify`,
  verifyAgain: `Verify again`,
  verifySuccess: 'Verified successfully',
  verifySuccessMessage: 'BugHerd is installed on your website. 🎉',
  installExtMessage: `Install the Browser Extension`,
  verifyingJsMessage: 'Checking your site for the script',
  hintMessageInstallBugHerd:
    'Install the BugHerd extension to see the BugHerd sidebar on your website.',
  hintDescriptionInstallBugHerd:
    'Alternatively, you can add the Javascript code to your website’s <head> tag.',
  guideHeader: 'getting started guide',
  createGuideMessage: 'Let us help you set up your first project in BugHerd',
  createVideoLength: '53sec',
  createVideoKey: '83109c316627449f96a497a153e06a81',
  installGuideMessage: `There's two ways to install BugHerd. Merrin can help you work out which one is right for you.`,
  installVideoLength: '58sec',
  installVideoKey: '992a7fe3046947cab8237b4656f2da3f',
  feedbackGuideMessage: `It's feedback time! If you need help, Merrin can show you how in less than 60 seconds.`,
  feedbackVideoLength: '56sec',
  feedbackVideoKey: '495bf8a05106457f9be4e5490fc429f3',
  supportHref: 'mailto:support@bugherd.com',
  helpMessagePartOne: 'Need help? ',
  helpMessagePartTwo: 'Talk to us',
  personalizeExperience: 'Personalize your experience',
  personalizeExperienceTitle:
    '🎉 Nice work! Let’s personalize your BugHerd experience',
  jobRole: 'Job role:',
  industry: 'Industry:',
  companySize: 'Company size:',
  whichIntegrations:
    'Which of the following do you use? Select all that apply.',
  specifyIntegration: 'Please specify integration',
  bugherdLoad:
    'The BugHerd sidebar will appear after your page has finished loading.'
};

export const Type = typeof en;
