export const en = {
  loginHeader: 'Login to BugHerd to give feedback',
  login: 'Login',
  noAccount: "Don't have an account, request an invite from the ",
  projectOwner: 'Project Owner',
  contactOwner: (owner: { email: string; name: string; id: number } | null) =>
    owner
      ? `Contact the Project Owner: ${owner.name} (${owner.email})`
      : 'Contact support (support@bugherd.com) for further instructions',
  invited: 'You need to be invited to this project to give feedback',
  archivedHeader: 'This asset has been archived',
  backToProject: 'Back to project'
};

export type Translations = typeof en;
