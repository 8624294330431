const roleOrdering = {
  billing_manager: 3,
  billing: 3,
  manager: 3,
  member: 3,
  collaborator: 2,
  guest: 1
};

export default users => {
  const sorted = users.concat([]).sort((a, b) => {
    if (a.role && b.role) {
      if (roleOrdering[a.role] > roleOrdering[b.role]) {
        return -1;
      }
      if (roleOrdering[a.role] < roleOrdering[b.role]) {
        return 1;
      }
    }

    const aDisplayName = a.display_name.toLowerCase();
    const bDisplayName = b.display_name.toLowerCase();
    if (aDisplayName < bDisplayName) {
      return -1;
    }
    if (aDisplayName > bDisplayName) {
      return 1;
    }
    return 0;
  });

  return sorted;
};
