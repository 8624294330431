import * as React from 'react';
import { message } from 'antd';
import { Navigate } from 'react-router-dom';

import Error from 'components/Error';
import UserPageLayout from 'components/UserPageLayout';
import { getLangKey } from 'models/Language';
import { useCurrentOrganizationIdParam } from 'models/Organization';

import UserProjectsList from './UserProjectsList';
import { useGetCurrentUserQuery } from './queries';
import RedirectToSubscription from './RedirectToSubscription';
import Loader from './Loader';
import * as translations from './strings';
import { ProjectFolders } from './project_folders';
import useMediaQuery from '../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';

const UserProjects = ({ container }: { container: HTMLDivElement }) => {
  const [activeKey, setActiveKey] = React.useState('all-projects');
  const [selectedProjects, setSelectedProjects] = React.useState<
    { id: string; name: string }[]
  >([]);
  const [showAddFolder, setShowAddFolder] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);

  const strings = translations[getLangKey()];
  const organizationId = useCurrentOrganizationIdParam();
  const isMobileView = useMediaQuery('(max-width: 925px)');

  const {
    data,
    loading,
    error,
    refetch: refetchCurrentUser
  } = useGetCurrentUserQuery({
    organizationId
  });

  if (loading) return <Loader />;
  if (error) {
    message.error(strings.refreshPageErrorMessage);
    return <Error error={error} />;
  }

  if (!data) {
    return <Navigate replace to="/" />;
  }

  const hasUnreadCommentsExperiment = true;

  const {
    currentUser: {
      accessTo,
      organization: { projectFoldersAllowed, projectFolders },
      role,
      seenProjectFolderVideo,
      createdProjectFolder
    }
  } = data;

  const getCurrentFolder = () => {
    const parentWithSubfolders = activeKey.includes(',');
    const parentFolder = projectFolders.find(folder => folder.id === activeKey);
    const parentFromSubfolder = projectFolders.find(folder =>
      folder.subfolders.find(subfolder => subfolder.id === activeKey)
    );
    const subfolderFromParent = parentFromSubfolder?.subfolders.find(
      subfolder => subfolder.id === activeKey
    );
    if (parentWithSubfolders) {
      return projectFolders.find(
        folder => folder.id === activeKey.split(',')[0]
      );
    } else if (parentFolder) {
      return parentFolder;
    } else if (parentFromSubfolder && subfolderFromParent) {
      return subfolderFromParent;
    }
  };

  const favouriteProjects = () => {
    try {
      return (
        JSON.parse(data.currentUser.favouriteProjects)[organizationId] || []
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const projectList = (disabled?: boolean) => (
    <UserProjectsList
      currentUser={data.currentUser}
      accessTo={accessTo}
      hasUnreadCommentsExperiment={hasUnreadCommentsExperiment}
      container={container}
      activeKey={activeKey}
      folder={getCurrentFolder()}
      selectedProjects={selectedProjects}
      setSelectedProjects={setSelectedProjects}
      setActiveKey={setActiveKey}
      favouriteProjectsTotal={favouriteProjects().length}
      refetchCurrentUser={refetchCurrentUser}
      setShowAddFolder={setShowAddFolder}
      showAddFolder={showAddFolder}
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
      disabled={!!disabled}
      isMobileView={isMobileView}
    />
  );

  return (
    <UserPageLayout
      siderChildren={
        projectFoldersAllowed &&
        role !== 'guest' &&
        !isMobileView && (
          <ProjectFolders
            canCreateProject={accessTo.createProject}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            toggleAddFolderModal={() => setShowAddFolder(!showAddFolder)}
            seenProjectFolderVideo={seenProjectFolderVideo}
            createdProjectFolder={createdProjectFolder}
            manageProjectFolders={accessTo.manageProjectFolders}
            favouriteProjects={favouriteProjects()}
            folders={projectFolders}
          />
        )
      }
    >
      {data.currentUser.organization.isActive ||
      !data.currentUser.accessTo.billing ? (
        projectList()
      ) : (
        <RedirectToSubscription organizationId={organizationId}>
          {projectList(true)}
        </RedirectToSubscription>
      )}
    </UserPageLayout>
  );
};

export default UserProjects;
