import React, { FC } from 'react';
import styles from './index.module.css';
import cx from 'classnames';
import { Endpoints, User } from './types';
import { Menu } from 'antd';
import { rightNavMobile, leftNavMobile } from './getItems';
import { GetItemArgs } from './types';

interface Props {
  isMobileMenuOpen: boolean;
  isActiveOrganization: boolean;
  endpoints: Endpoints;
  user: User;
  isMobileView: boolean;
  latestProjects: any;
  multipleOrganizations: boolean;
  toggleMobileMenu: () => void;
  container: HTMLDivElement;
  openElevio: (e: Event) => void;
  setIsModalOpen: (boolean) => void;
}

export const MobileNav: FC<Props> = Props => {
  const {
    endpoints: {
      team,
      settings,
      newProject,
      organizationUrl,
      userActivities,
      homeUrl
    },
    user: {
      accessTo: {
        viewTeam,
        billing,
        createProject,
        integrations,
        viewUserActivities
      },
      id,
      amountOfTrialDays,
      admin,
      organization,
      isGuest
    },
    isMobileMenuOpen,
    multipleOrganizations,
    latestProjects,
    isActiveOrganization,
    setIsModalOpen,
    openElevio,
    isMobileView
  } = Props;

  const leftNavMenuItems: GetItemArgs[] = leftNavMobile({
    organizationUrl,
    userActivities,
    viewUserActivities,
    isGuest,
    organizationId: organization?.id,
    team,
    settings,
    multipleOrganizations,
    createProject,
    newProject,
    latestProjects,
    viewTeam,
    integrations,
    billing,
    isMobileView
  });

  const rightNavMenuItems: GetItemArgs[] = rightNavMobile({
    admin,
    isActiveOrganization,
    setIsModalOpen,
    openElevio,
    id,
    amountOfTrialDays,
    homeUrl,
    isMobileView
  });

  const menuItems = leftNavMenuItems.concat(rightNavMenuItems);

  return (
    <div
      className={cx(styles.mobileMenu, {
        [styles.mobileOpen]: isMobileMenuOpen,
        [styles.mobileHidden]: !isMobileMenuOpen
      })}
    >
      {isMobileMenuOpen && <Menu mode="vertical" items={menuItems} />}
    </div>
  );
};
