import React, { FC, useState, MouseEvent } from 'react';
import { FigmaNode } from './types';
import { Checkbox, Image } from 'antd';
import { Eye, Loader } from 'lucide-react';
import styles from './index.module.css';
import cx from 'classnames';
import animationStyles from '../../../clients/sidebar/views/CreateTask/create_task/overlay/animation.module.css';
import { useFigmaUploadState } from '../providers/FigmaUpload';
import { useDesignAssetsState } from '../providers/DesignAssets';
import { useListenForAltKey } from './useListenForAltKey';

type Props = {
  image: FigmaNode;
};

export const FigmaImages: FC<Props> = ({
  image: { largerImageUrl, imageUrl, name, id, isSelected, type }
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSelecting, setIsSelecting] = useState<boolean>(true);
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);

  const { updateNode, fileKey } = useFigmaUploadState();
  const { container, getFigmaData } = useDesignAssetsState();
  const { isAltKeyDown } = useListenForAltKey();

  const handleImageClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isAltKeyDown) {
      handleShowPreviewImage(id);
      setIsPreviewVisible(!isPreviewVisible);
    } else {
      setIsSelecting(true);
      updateNode(id, { isSelected: !isSelected });
    }
  };

  const handleShowPreviewImage = async (imageId): Promise<void> => {
    const unscaledImage = await getFigmaData(
      `/images/${fileKey}?ids=${imageId}&format=jpg`
    );
    const imageData = await unscaledImage.json();
    updateNode(imageId, { largerImageUrl: imageData.images[imageId] });
  };

  return (
    <div className={styles.figmaImageWrapper}>
      <div
        className={cx(styles.figmaImageLoader, {
          [styles.figmaImageIsLoaded]: !isLoading
        })}
      >
        <Loader
          className={cx(styles.imageLoaderIcon, animationStyles.loaderIcon)}
        />
      </div>
      <div className={cx({ [styles.figmaImageIsLoaded]: isLoading })}>
        <Image
          className={styles.figmaImage}
          src={largerImageUrl || imageUrl}
          alt={name}
          title={`NAME: ${name}\nTYPE: ${type}`}
          onClick={handleImageClick}
          onLoad={() => setIsLoading(false)}
          preview={{
            getContainer: () => container as HTMLElement,
            visible: isPreviewVisible,
            onVisibleChange: (visible: boolean) =>
              isSelecting
                ? setIsSelecting(false)
                : setIsPreviewVisible(visible),
            src: largerImageUrl,
            maskClassName: styles.mask,
            mask: (
              <div
                className={cx(styles.previewMask, {
                  [styles.hidePreviewHint]: isAltKeyDown
                })}
              >
                <span className={styles.previewHint}>
                  <code>ALT + CLICK</code>
                  to Preview
                </span>
                <span className={styles.preview}>
                  <Eye className={styles.previewIcon} /> Preview
                </span>
              </div>
            )
          }}
        />
        <Checkbox
          className={styles.uploadCheckbox}
          checked={isSelected}
          onChange={({ target: { checked } }) =>
            updateNode(id, { isSelected: checked })
          }
        />
      </div>
    </div>
  );
};
