import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { App, ConfigProvider } from 'antd';
import { antdTheme } from '../../clients/shared/theme';
import styles from '../../clients/shared/overrides.module.css';

// Options
// listenForPropChanges: if you want to listen to props that get changed from your erb file
// host: the container the react app is rendered into
// App: the react app you want to render

const getProps = (hostname, host) => {
  let props = {};
  if (window[hostname + '_props']) {
    props = window[hostname + '_props'];

    const stringedProps = JSON.stringify(props);
    stringedProps.replace(/<\\\//g, '</');

    return JSON.parse(stringedProps);
  }

  return props;
};

export default opts => {
  if (!opts.hasOwnProperty('App') || !opts.hasOwnProperty('host')) {
    throw Error("You're missing an App or host", opts);
  }

  document.addEventListener('DOMContentLoaded', () => {
    if (opts.waitForRedux && opts.listenForPropChanges) {
      throw new Error(
        `Either use  waitForRedux OR listenForPropChanges for host component ${opts.host}`
      );
    }
    if (opts.waitForRedux) {
      const host = document.getElementById(opts.host + '_container');
      if (!host) {
        return;
      }

      document.addEventListener('bugherdStoreInitialized', () => {
        const props = getProps(opts.host, host);

        ReactDOM.render(
          <AntdWrapper>
            <opts.App {...props} container={host} />
          </AntdWrapper>,
          host
        );
      });
      if (window.bugherdStore) {
        document.dispatchEvent(new CustomEvent('bugherdStoreInitialized'));
      }

      return;
    }

    if (!opts.listenForPropChanges) {
      const host = document.getElementById(opts.host + '_container');
      if (host) {
        const props = getProps(opts.host, host);

        ReactDOM.render(
          <AntdWrapper>
            <opts.App {...props} container={host} />
          </AntdWrapper>,
          host
        );
      }

      return;
    }

    // These seems really stupid, and I'm sorry
    // Because of the way backbone works, we can't assume
    // the element exists even after the domcontentloaded
    // event gets fired.
    // this is mainly for the sidebar js.

    let interval = setInterval(() => {
      const host = document.getElementById(opts.host + '_container');

      if (host) {
        clearInterval(interval);

        let props = getProps(opts.host, host);
        let oldProps = {};

        const propHandler = {
          destroy: () => {},

          listen: cb => {
            const interval = setInterval(() => {
              let newProps = getProps(opts.host, host);

              if (JSON.stringify(newProps) !== JSON.stringify(oldProps)) {
                oldProps = newProps;

                if (cb) {
                  cb(newProps);
                }

                // this is an antipattern beyond belief
                ReactDOM.render(
                  <AntdWrapper>
                    <opts.App
                      {...newProps}
                      propHandler={propHandler}
                      container={host}
                    />
                  </AntdWrapper>,
                  host
                );
              }
            }, 100);

            propHandler.destroy = () => {
              clearInterval(interval);
            };
          }
        };

        ReactDOM.render(
          <AntdWrapper>
            <opts.App {...props} propHandler={propHandler} container={host} />
          </AntdWrapper>,
          host
        );
      }
    }, 1000);
  });
};

const AntdWrapper = ({ children }: PropsWithChildren<{}>) => (
  <ConfigProvider theme={antdTheme}>
    <App className={styles.antdOverrides}>{children}</App>
  </ConfigProvider>
);
