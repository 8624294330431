import React, { useState } from 'react';
import { Switch, Button, Input, Form } from 'antd';
import ShortcutPicker from 'components/shortcut_picker';
import styles from './style.module.css';
import securityStyles from '../security/security.module.css';
import HelpArticleButton from 'utils/HelpArticleButton';
import { UPDATE_PROJECT } from '../../queries';
import { useMutation } from '@apollo/client';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
interface Props {
  project: {
    id: string;
    isPublic: boolean;
    shortcutToShowWidget: string;
    toggleVerticalPosition: number;
    experiments: Array<string>;
  };
  onCancel?: () => void;
}

const strings = translations[getLangKey()];

const Sidebar: React.FC<Props> = (props: Props) => {
  const { project, onCancel } = props;
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, isFieldTouched, resetFields } = form;
  const {
    isPublic,
    shortcutToShowWidget,
    toggleVerticalPosition = 90
  } = props.project;

  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [saving, setSaving] = React.useState(false);
  const [shortcutOutput, setShortcutName] = useState(shortcutToShowWidget);

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      setSaving(false);
    },
    onError: () => {
      setSaving(false);
    }
  });

  const getSetting = (settingName: string) =>
    isFieldTouched(settingName) ? getFieldValue(settingName) : undefined;

  const submit = () => {
    setSaving(true);

    let togglePosition = getSetting('toggleVerticalPosition');
    if (typeof togglePosition === 'string') {
      togglePosition = parseInt(togglePosition, 10);
    }

    const body: {
      isPublic?: boolean;
      shortcutToShowWidget?: string;
      toggleVerticalPosition?: number;
    } = {
      isPublic: getSetting('isPublic'),
      shortcutToShowWidget: getSetting('shortcutToShowWidget'),
      toggleVerticalPosition: togglePosition
    };

    body.shortcutToShowWidget = shortcutOutput;

    updateProject({
      variables: {
        projectId: project.id,
        ...body
      }
    });
  };

  const handleCancel = () => {
    onCancel();
    // This is because antd animates the modal, so if you reset NOW, the user sees.
    setTimeout(() => {
      // Maybe iterate over the props in the future, it's less obvious though
      resetFields();
    }, 500);
  };

  const initialValues: {
    showNewSidebar?: boolean;
    isPublic?: boolean;
    shortcutToShowWidget?: string;
    toggleVerticalPosition?: number;
  } = {
    isPublic,
    shortcutToShowWidget,
    toggleVerticalPosition
  };

  const _isPublic: boolean =
    typeof getFieldValue('isPublic') === 'undefined'
      ? initialValues.isPublic
      : getFieldValue('isPublic');

  const _showNewSidebar: boolean =
    typeof getFieldValue('showNewSidebar') === 'undefined'
      ? initialValues.showNewSidebar
      : getFieldValue('showNewSidebar');

  if (shortcutOutput === 'undefined' || shortcutOutput === null) {
    setShortcutName('Cmd + Ctrl + B');
  }

  const updateShortCutWidget = value => {
    setFieldsValue({ shortcutToShowWidget: value });
    setShortcutName(value);
  };

  return (
    <Form
      form={form}
      onFinish={submit}
      layout="vertical"
      initialValues={initialValues}
      className={styles.form}
      onValuesChange={() => {
        forceUpdate();
      }}
    >
      <>
        <h3 className={securityStyles.basicHeader}>
          {strings.publicFeedbackHeader}
        </h3>
        <p>
          {strings.publicFeedbackMessage}{' '}
          <span className={securityStyles.learnMoreWrapper}>
            <HelpArticleButton articleId="36352" style={{ color: '#3f71e0' }}>
              {strings.learnMore}
            </HelpArticleButton>
          </span>
        </p>
        <Form.Item
          name="isPublic"
          className={securityStyles.switchWrapper}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </>
      <>
        <h3 className={securityStyles.basicHeader}>{strings.shortcutHeader}</h3>
        <p>{strings.shorcutMessage}</p>
        <Form.Item name="shortcutOutput" valuePropName="shortcutOutput">
          <ShortcutPicker
            name="project[shortcut_to_show_widget]"
            value={shortcutOutput}
            disabled={false}
            onChange={(value: string) => updateShortCutWidget(value)}
          />
        </Form.Item>
      </>

      <h3 className={securityStyles.basicHeader}>
        {strings.verticalPositionHeader}
      </h3>
      <p>{strings.verticalPositionMessage}</p>
      <Form.Item name="toggleVerticalPosition">
        <Input
          min="0"
          max="100"
          className={styles.verticalField}
          type="number"
          addonAfter="%"
        />
      </Form.Item>
      <div className={styles.saveCancel}>
        <Button
          className={styles.button}
          type="primary"
          htmlType="submit"
          loading={saving}
        >
          {strings.save}
        </Button>
        {onCancel && (
          <Button className={styles.ghost} onClick={handleCancel}>
            {strings.cancel}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default Sidebar;
