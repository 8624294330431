import React, { FC, MouseEventHandler } from 'react';
import { Button } from 'antd';
import styles from './styles.module.css';
import cx from 'classnames';
import { Share } from 'lucide-react';

type Props = {
  className?: string;
  onClick: MouseEventHandler<HTMLElement> | (() => void);
  hide?: boolean;
  iconOnly?: boolean;
};

const ShareButton: FC<Props> = ({ className, onClick, hide, iconOnly }) =>
  hide ? null : (
    <Button
      type="default"
      className={cx(styles.shareButton, {
        [className || '']: className,
        [styles.iconOnly]: iconOnly
      })}
      icon={<Share className={styles.shareIcon} />}
      onClick={onClick}
    >
      {!iconOnly ? 'Share' : null}
    </Button>
  );

export default ShareButton;
