export const en = {
  urlPlaceholder: 'www.amazing.com/',
  urlLabel: 'Website URL',
  urlOptionalLabel: 'Website URL (optional)',
  urlHint: 'The URL that you use to access the home of your website.',
  urlHelpArticleId: '33865',
  nameLabel: 'Project name',
  namePlaceholder: 'My Amazing Project',
  guestsPermissionMessage:
    'Allow guests on this project to see feedback from other guests',
  allowGuestChangeTaskStatus: 'Allow Guests to change the status of a task',
  guestsHelpArticleId: '85000',
  createError: 'Something went wrong, please try again.',
  createSuccess: 'The project has been created successfully.',
  emptyValidationMessage: 'Please complete this field',
  createProject: `Create Project`
};

export type Translations = typeof en;
