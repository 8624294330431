export const en = {
  filterButton: 'Filter',
  Project: 'Project',
  ReportedBy: 'Reported by',
  Priority: 'Severity',
  Status: 'Status',
  Description: 'Description',
  FilterBy: 'Filter by: ',
  search: 'Search',
  clear: 'Clear',
  retry: 'Retry',
  dueAt: 'Due date',
  updatedAt: 'Last updated',
  createdAt: 'Report date'
};

export type Translations = typeof en;
