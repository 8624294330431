import * as React from 'react';
import styles from './index.module.css';

import { Steps, Button } from 'antd';
import Hint from 'components/Hint';
import ProjectKey from 'components/ProjectKey';
import { getLangKey } from 'models/Language';
import cx from 'classnames';
import * as translations from '../strings';

const { Step } = Steps;

type Props = {
  apiKey: string;
};

const WordPressTab = (props: Props) => {
  const strings = translations[getLangKey()];
  const wordPressLink: string = 'https://wordpress.org/plugins/bugherd/';

  return (
    <div className={cx(styles.wordPressTabOuter)}>
      <div className={styles.innerContainer}>
        <p className={styles.info}>{strings.wordPressInfo}</p>
        <Steps direction="vertical">
          <Step
            status="process"
            title={
              <p className={styles.title}>{strings.installWordPressTitle}</p>
            }
            icon={1}
            description={
              <Button
                href={wordPressLink}
                className={styles.install}
                type="primary"
                target="_blank"
              >
                {strings.installWordPressButton}
              </Button>
            }
          />
          <Step
            status="process"
            title={
              <p className={styles.title}>{strings.projectKeyWordPressTitle}</p>
            }
            icon={2}
            description={<ProjectKey {...props} />}
          />
        </Steps>
        <div className={styles.hintOuter}>
          <Hint
            message={null}
            description={
              <span>
                {strings.wordPressAlert}
                <a
                  data-elevio-article={strings.helpLinkArticleId}
                  data-elevio-style="nothing"
                  href=""
                >
                  {strings.wordPressAlertHelpLink}
                </a>
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WordPressTab;
