import { Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'lucide-react';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import TemplatesApi from './templatesApi';
import { PlusCircleOutlined } from '@ant-design/icons';
import ColumnInput from './ColumnInput';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import track from 'appJS/utils/analytics';

export type TemplateColumn = {
  id?: string;
  name: string;
  color?: string;
  project_rank_position?: number;
};

interface TemplatesProps {
  orgId: number;
}

// needs to be a function to create new objects on call
const getDefaultColumns = () => [
  {
    id: uuid().toString(),
    name: 'backlog',
    color: 'white',
    project_rank_position: 0
  },
  {
    id: uuid().toString(),
    name: 'todo',
    color: 'white',
    project_rank_position: 1
  },
  {
    id: uuid().toString(),
    name: 'doing',
    color: 'white',
    project_rank_position: 2
  },
  {
    id: uuid().toString(),
    name: 'done',
    color: 'white',
    project_rank_position: 3
  }
];

const Templates: React.FC<TemplatesProps> = ({ orgId }) => {
  const strings = translations[getLangKey()];
  const {
    getProjectTemplate: fetchProjectTemplate,
    updateProjectTemplate
  } = TemplatesApi;

  const [columns, setColumns] = useState<TemplateColumn[]>([]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const newColumns = Array.from(columns);
    const [reorderedItem] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, reorderedItem);
    setColumns(newColumns);
  };

  useEffect(() => {
    const getProjectTemplate = async () => {
      const defaultColumns = await fetchProjectTemplate(orgId);
      if (!defaultColumns.columns || defaultColumns.columns.length === 0) {
        defaultColumns.columns = getDefaultColumns();
      }
      const updatedColumns = defaultColumns.columns.map(
        (column: TemplateColumn) => ({
          ...column,
          id: uuid().toString()
        })
      );
      setColumns(updatedColumns);
    };
    getProjectTemplate();
  }, []);

  const handleSave = async () => {
    track('Project default columns set', { org_id: orgId });

    const updatedColumns = columns.map((col, i) => ({
      ...col,
      name: col.name.trim(),
      project_rank_position: i
    }));
    setColumns(updatedColumns);

    updateProjectTemplate(orgId, updatedColumns)
      .then(() => {
        message.success('Template updated successfully');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      {
        id: uuid().toString(),
        name: '',
        color: 'white'
      }
    ]);
  };

  const handleRemoveColumn = (id: string) => {
    const updatedColumns = columns.filter(column => column.id !== id);
    setColumns(updatedColumns);
  };

  const handleInputBlur = (id: string, value: string) => {
    if (value === '') {
      handleRemoveColumn(id);
    }
  };

  const handleOnChange = (id: string, value: string) => {
    const updatedColumns = [...columns];
    const updateIndex = updatedColumns.findIndex(col => col.id === id);
    if (updateIndex !== -1) {
      columns[updateIndex].name = value;
      setColumns(updatedColumns);
    }
  };

  const handleRevertToDefault = () => {
    setColumns(getDefaultColumns());
  };

  return (
    <>
      <h2 className={styles.heading}>{strings.heading}</h2>
      <p>{strings.subTitle}</p>
      <Form>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="columns">
            {provided => (
              <div
                className={styles.columns}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columns.map(({ id, name }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={styles.columnContainer}
                      >
                        <ColumnInput
                          dragHandleProps={provided.dragHandleProps}
                          value={name}
                          id={id || ''}
                          removeColumn={handleRemoveColumn}
                          onBlur={handleInputBlur}
                          onChange={handleOnChange}
                          removeDisabled={columns.length <= 2}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={styles.buttonGroup}>
          <Button
            type="link"
            className={styles.actionButton}
            icon={<PlusCircleOutlined />}
            onClick={handleAddColumn}
          >
            {strings.addColumn}
          </Button>
          <Button
            type="link"
            className={cx(styles.actionButton, styles.refreshButton)}
            icon={<RefreshCw size={14} className={styles.refreshIcon} />}
            onClick={handleRevertToDefault}
          >
            {strings.revertToDefault}
          </Button>
        </div>
        <Button
          type="primary"
          onClick={handleSave}
          className={styles.saveButton}
        >
          {strings.save}
        </Button>
      </Form>
    </>
  );
};

export default Templates;
