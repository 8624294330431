import * as React from 'react';
import { Form, Input, Button, message } from 'antd';
import styles from './advanced.module.css';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import useProjectMutation from '../general/hooks/useProjectMutation';
import { getProjectSites } from '../general/hooks/useProjectSites';
import { Project, ProjectFormField } from '../general/types';

interface Props {
  project: Project;
  onCancel?: () => void;
  refetch: () => void;
}

const Advanced: React.FC<Props> = (props: Props) => {
  const { project, onCancel, refetch } = props;
  const strings = translations[getLangKey()];
  const [form] = Form.useForm();

  const projectSites = getProjectSites(project);

  const [submit, { updateProjectLoading }] = useProjectMutation({
    project,
    projectSites,
    message,
    ...form,
    refetch
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={submit}
      hideRequiredMark
      className={styles.advancedForm}
    >
      <Form.Item
        label={
          <div className={styles.ignoreLabelOuter}>
            <h3 className={styles.ignoreLabelHeader}>
              {strings.ignoreListHeader}
            </h3>
            <p className={styles.ignoreListMessage}>
              {strings.ignoreListMessage}
            </p>
          </div>
        }
        name={ProjectFormField.IgnoreList}
        initialValue={project.ignoredQuerystrings.join('\n')}
        rules={[
          {
            type: 'string',
            message: strings.ignoreListRuleMessage
          }
        ]}
      >
        <Input.TextArea
          className={styles.ignoreListInput}
          name="project[ignored_querystrings]"
          autoSize={{ minRows: 5, maxRows: 5 }}
        />
      </Form.Item>
      <div className={styles.actions}>
        <Button type="primary" htmlType="submit" loading={updateProjectLoading}>
          {strings.save}
        </Button>
        {onCancel && (
          <Button onClick={() => onCancel()}>{strings.cancel}</Button>
        )}
      </div>
    </Form>
  );
};

export default Advanced;
