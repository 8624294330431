import React, { FC } from 'react';
import HighlightedMentions from '../../../../javascript/components/HighlightedMentions';
import { Props } from './types';
import * as styles from './styles';

const priorities: string[] = [
  '#ccc',
  '#d46060',
  '#e8b019',
  '#60c5d4',
  '#8bb863'
];

const PinTooltip: FC<Props> = props => {
  const { task, requester } = props;
  const { description, priorityId, id } = task;
  const { display_name, name, email, role } = requester;
  const innerDescription = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (innerDescription.current) {
      innerDescription.current.style.webkitBoxOrient = 'vertical';
      innerDescription.current.style.webkitLineClamp = '2';
    }
  }, []);

  const isGeneric: boolean = requester.avatar.includes('avatar-generic.svg');
  const getName: string = display_name || name || email;

  return (
    <div
      id={`tooltipOuter_${id}`}
      style={styles.tooltipOuterCss(priorities[priorityId])}
    >
      <div id={`tooltipAvatarOuter_${id}`} style={styles.avatarOuterCss}>
        <div
          style={styles.avatarContainerCss(isGeneric, requester.avatar, role)}
        >
          <span style={styles.avatarLetterCss}>
            {isGeneric ? getName[0].toUpperCase() : null}
          </span>
        </div>
      </div>
      <p
        id={`tooltipDescriptionOuter_${id}`}
        style={styles.descriptionOuterCss}
      >
        <span
          ref={innerDescription}
          className="descriptionInner"
          style={styles.descriptionInnerCss}
        >
          <HighlightedMentions
            {...{
              description,
              mentionInlineStyle: styles.mentionCss,
              notMentionInlineStyle: styles.notMentionCss
            }}
            includeGuests={false}
          />
        </span>
      </p>
      <span id={`tooltipRequesterName_${id}`} style={styles.requesterNameCss}>
        — {getName}
      </span>
    </div>
  );
};

export default PinTooltip;
