import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';
import Unavailable from './unavailable';
import Upgrade from './upgrade';
import BasicAuth from './basicAuth';
import Generic from './generic';
import TimeOut from './timeout';
import Error from './error';
import * as translations from './strings';
import { getLangKey } from '../../../../../javascript/models/Language';

import styles from './index.module.css';

type Props = {
  show: boolean;
  toggleWhy: (isOpen: boolean, modalName: string) => void;
  orgId: number;
  projectId: number;
  taskId: number;
  url: string;
  screenshotError: string;
  isSidebar: boolean;
  hasAccess: boolean;
  screenshotKey: string;
  canAccessBilling: boolean;
  container: HTMLElement;
};

// @ts-ignore
const strings = translations[getLangKey()];

const WhyModal: FC<Props> = ({
  toggleWhy,
  projectId,
  orgId,
  url,
  taskId,
  show,
  isSidebar,
  screenshotError,
  hasAccess,
  screenshotKey,
  canAccessBilling,
  container
}) => {
  const goToBasicAuthTab = () => {
    // @ts-ignore
    if (window.project_settins_props) {
      toggleWhy(show, 'showWhy');
      // @ts-ignore
      Object.assign(window.project_settings_props, {
        // @ts-ignore
        show: !window.project_settings_props.show,
        activeKey: 'Security'
      });
    }
  };

  const getModalInfo = () => {
    let title: string = '';
    let content: ReactNode = '';

    switch (screenshotError) {
      case '401':
        title = strings.protected;
        content = (
          <BasicAuth
            projectId={projectId}
            isSidebar={isSidebar}
            goToBasicAuth={goToBasicAuthTab}
            url={url}
          />
        );
        break;
      case 'unavailable':
        title = strings.serviceUnavailable;
        content = <Unavailable />;
        break;
      case 'generic':
        title = strings.wentWrong;
        content = (
          <Generic
            taskId={taskId}
            isSidebar={isSidebar}
            screenshotKey={screenshotKey}
          />
        );
        break;
      case 'timeout':
        title = strings.noResponseFromSite;
        content = <TimeOut show={show} toggleWhy={toggleWhy} />;
        break;
      case 'wrong_plan':
        title = hasAccess ? strings.wrongPlan : strings.upgradePlan;
        content = (
          <Upgrade
            url={url}
            isSidebar={isSidebar}
            orgId={orgId}
            hasAccess={hasAccess}
            canAccessBilling={canAccessBilling}
          />
        );
        break;
      default:
        title = strings.returnedError;
        content = (
          <Error error={screenshotError} toggleWhy={toggleWhy} show={show} />
        );
        break;
    }
    return { title, content };
  };

  const { title, content } = getModalInfo();

  return (
    <Modal
      title={title}
      open={show}
      className={styles.whyModal}
      getContainer={() => container}
      footer={null}
      onCancel={() => toggleWhy(show, 'showWhy')}
    >
      {content}
    </Modal>
  );
};

export default WhyModal;
