const PREFERENCES_GET_ERROR_MESSAGE = `Couldn't get collapsed states from local storage`;
const PREFERENCES_SET_ERROR_MESSAGE = `Couldn't set collapsed states in local storage`;

let collapsedStatesCache = {};

export const getCollapsedState = () => {
  try {
    if (!Object.keys(collapsedStatesCache).length) {
      collapsedStatesCache = JSON.parse(
        window.localStorage.getItem('collapsedStates') || '{}'
      );
    }
    return collapsedStatesCache;
  } catch (err) {
    console.error(PREFERENCES_GET_ERROR_MESSAGE, err);
    return {};
  }
};

export const setCollapsedState = (title: string, collapsed: boolean) => {
  try {
    collapsedStatesCache = { ...collapsedStatesCache, [title]: collapsed };
    window.localStorage.setItem(
      'collapsedStates',
      JSON.stringify(collapsedStatesCache)
    );
  } catch (err) {
    console.error(PREFERENCES_SET_ERROR_MESSAGE, err);
  }
};
