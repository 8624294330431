import * as React from 'react';
import { Button, Popover, Divider } from 'antd';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
import styles from './index.module.css';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import cx from 'classnames';

type voidFunc = () => void;

type LayoutStyle = 'horizontal' | 'vertical';

type Props = {
  buttonText: string;
  className?: string;
  onSearch: voidFunc;
  onReset: voidFunc;
  isFilterApplied: boolean;
  layoutStyle?: LayoutStyle;
};

const FilterPopover: React.FC<Props> = ({
  buttonText,
  children,
  className,
  onSearch,
  onReset,
  isFilterApplied,
  layoutStyle = 'horizontal'
}) => {
  const strings = translations[getLangKey()];
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  return (
    <Popover
      className={className}
      placement="bottom"
      getPopupContainer={trigger => trigger.parentElement as HTMLElement}
      content={
        <div
          className={
            layoutStyle === 'vertical'
              ? styles.popoverContentVertical
              : styles.popoverContent
          }
        >
          <div
            className={
              layoutStyle === 'vertical' ? styles.scrollableContent : styles
            }
          >
            {children}
          </div>
          <div>
            {layoutStyle === 'vertical' && (
              <Divider className={styles.popoverDivider} />
            )}
          </div>
          <div
            className={cx(styles.popoverFooterContainer, {
              [styles.footerContainerWithFilter]: isFilterApplied
            })}
          >
            <Button
              type="primary"
              onClick={() => {
                setShowDropdown(false);
                onSearch();
              }}
            >
              {strings.search}
            </Button>
            <Button
              className={styles.clearButton}
              onClick={() => {
                setShowDropdown(false);
                onReset();
              }}
            >
              {strings.clear}
            </Button>
          </div>
        </div>
      }
      trigger="click"
      open={showDropdown}
      onOpenChange={() => setShowDropdown(!showDropdown)}
    >
      <Button
        type="primary"
        className={cx({
          [styles.panelWrap]: true,
          [styles.panelButton]: !showDropdown && !isFilterApplied,
          [styles.filterAppliedButton]: isFilterApplied && !showDropdown,
          [styles.filterPressedButton]: showDropdown
        })}
      >
        <span>{buttonText} </span>
        {showDropdown ? <UpOutlined size={10} /> : <DownOutlined size={10} />}
      </Button>
    </Popover>
  );
};

export default FilterPopover;
