import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ViewActions from '../view_actions';
import { ExternalLink } from 'lucide-react';
import { Button } from 'antd';

import styles from './index.module.css';

function TitleBar(props) {
  const getPriorityClassName = () => {
    return (
      [
        styles.notSet,
        styles.critical,
        styles.important,
        styles.normal,
        styles.minor
      ][props.priorityId] || styles.notSet
    );
  };

  const {
    localTaskId,
    column,
    toggleExpandedView,
    closeDetailBar,
    url,
    projectId,
    isExpandedView,
    canViewTaskBoardLink
  } = props;

  const taskUrl = `${url}/projects/${projectId}/tasks/${localTaskId}`;

  const getTaskLink = () => {
    const linkProps = { className: styles.taskInfo };
    if (canViewTaskBoardLink) {
      linkProps.className = styles.taskLink;
      Object.assign(linkProps, {
        href: taskUrl,
        target: '_blank',
        rel: 'noopener noreferrer'
      });
    }
    return <a {...linkProps}>{'Task #' + localTaskId}</a>;
  };

  return (
    <div
      className={cx(
        { [styles.titleHeaderExpanded]: isExpandedView },
        styles.titleHeader
      )}
    >
      <div
        className={cx(
          { [styles.containerExpanded]: isExpandedView },
          styles.container
        )}
      >
        <div className={styles.detailsHead}>
          <div
            className={cx(
              { [styles.priorityIndicatorExpanded]: isExpandedView },
              styles.priorityIndicator,
              getPriorityClassName()
            )}
          >
            {getTaskLink()}
            <div className={styles.columnName}>
              {' | '}
              {column.name}
            </div>
          </div>
          {canViewTaskBoardLink && (
            <Button
              key="taskBoardButton"
              type="secondary"
              href={taskUrl}
              target="_blank"
              className={styles.viewInTaskBoard}
            >
              <span className={styles.viewTaskBoardInner}>
                View on Task Board
              </span>
              <ExternalLink size={16} className={styles.linkIcon} />
            </Button>
          )}
          <ViewActions
            toggleExpandedView={toggleExpandedView}
            closeDetailBar={closeDetailBar}
            mode={isExpandedView ? 'maximized' : 'minimized'}
            isExpandedView={isExpandedView}
          />
        </div>
      </div>
    </div>
  );
}

TitleBar.propTypes = {
  localTaskId: PropTypes.number,
  column: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  url: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  toggleExpandedView: PropTypes.func,
  closeDetailBar: PropTypes.func,
  priorityId: PropTypes.number,
  isExpandedView: PropTypes.bool,
  canViewTaskBoardLink: PropTypes.bool
};

export default TitleBar;
