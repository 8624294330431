type FilterAndSortProps = {
  container: HTMLDivElement;
  isMobileView?: boolean;
};

type SortAttributeKey =
  | 'position'
  | 'priority_id'
  | 'due_at'
  | 'created_at'
  | 'assignee_ids'
  | 'updated_at';

const sortAttributeKeys: SortAttributeKey[] = [
  'position',
  'priority_id',
  'due_at',
  'created_at',
  'assignee_ids',
  'updated_at'
];

enum SortAttributeValues {
  position = 0,
  priority_id = 1,
  due_at = 2,
  created_at = 3,
  assignee_ids = 4,
  updated_at = 5
}

type FilterKey = 'all' | 'assets' | 'websites' | 'mentioned' | 'unread';

const filterKeys: FilterKey[] = [
  'all',
  'assets',
  'websites',
  'mentioned',
  'unread'
];

enum FilterValues {
  all = 0,
  assets = 1,
  websites = 2,
  mentioned = 3,
  unread = 4
}

type FeedbackView =
  | 'kanban'
  | 'triage'
  | 'archive'
  | 'assets'
  | 'settings'
  | 'integrations'
  | 'tags'
  | 'home';

type App =
  | undefined
  | { get?: (key: string) => any; set?: (key: string, value: any) => void };

type Order = 'ascending' | 'descending';

const orders: Order[] = ['ascending', 'descending'];

enum OrderValues {
  ascending = 0,
  descending = 1
}

type ChildrenItemType = {
  key: string | number;
  checked: boolean;
  text: string;
};

type GetItem = {
  key: string | number;
  label: string;
  children: ChildrenItemType[];
  disabled?: boolean;
};

export {
  SortAttributeKey,
  sortAttributeKeys,
  SortAttributeValues,
  FilterKey,
  filterKeys,
  FilterValues,
  FeedbackView,
  Order,
  orders,
  OrderValues,
  FilterAndSortProps,
  App,
  GetItem
};
