import * as React from 'react';
import { AtSign, MessageCircle } from 'lucide-react';
import { Badge } from 'antd';
import styles from './index.module.css';
import cx from 'classnames';
import { useProjectState } from '../../providers/Project';
import { useCurrentUserState } from '../../providers/CurrentUser';
import { CurrentUser } from '../../sidebar/models/CurrentUser';
import { Skeleton } from 'antd';

export type CommentReadInfo = {
  read_by_user_ids: number[];
  mention_ids: number[];
  created_at: string;
  is_private: boolean;
};

type CommentsProps = {
  comments?: CommentReadInfo[];
  currentUserId: number;
  className?: string;
};

type ProjectProps = {
  numberOfUnreadComments: number;
  numberOfUnreadMentions: number;
  isLoading: boolean;
};

const commentCreatedAfterExperiment = (unreadCommentsEnabledDate: string) => (
  comment: CommentReadInfo
) => new Date(comment.created_at) > new Date(unreadCommentsEnabledDate);

const commentCreatedAfterUser = (currentUser: CurrentUser) => (
  comment: CommentReadInfo
) => new Date(comment.created_at) > new Date(currentUser.createdAt);

const allowedMemberOnlyComments = (currentUser: CurrentUser) => (
  comment: CommentReadInfo
) => {
  if (currentUser.role !== 'guest') return true;

  return !comment.is_private;
};

export const CommentIndicator: React.FC<CommentsProps> = ({
  comments,
  currentUserId,
  className
}) => {
  const { project } = useProjectState();
  const { currentUser } = useCurrentUserState();

  if (!comments || comments.length <= 0 || !project || !currentUser) {
    return null;
  }

  const visibleComments = comments.filter(
    allowedMemberOnlyComments(currentUser)
  );

  if (!visibleComments.length) return null;

  const unreadCommentsEnabledDate = project?.unreadCommentsEnabledDate || '';

  const showCommentsAsUnread = visibleComments
    .filter(commentCreatedAfterExperiment(unreadCommentsEnabledDate))
    .filter(commentCreatedAfterUser(currentUser))
    .map(comment => !comment.read_by_user_ids.includes(currentUserId))
    .some(comment => comment);

  const showMentions = visibleComments
    .map(comment => comment.mention_ids.includes(currentUserId))
    .some(comment => comment);

  const showMentionsAsUnread = visibleComments
    .filter(commentCreatedAfterExperiment(unreadCommentsEnabledDate))
    .filter(commentCreatedAfterUser(currentUser))
    .map(
      comment =>
        comment.mention_ids.includes(currentUserId) &&
        !comment.read_by_user_ids.includes(currentUserId)
    )
    .some(comment => comment);

  return (
    <div className={cx(styles.indicatorContainer, className)}>
      <Badge dot={showCommentsAsUnread} className={styles.indicatorInfo}>
        <MessageCircle width={15} height={15} color={'#848484'} />
      </Badge>
      {showMentions && (
        <Badge dot={showMentionsAsUnread} className={styles.indicatorInfo}>
          <AtSign width={15} height={15} color={'#848484'} />
        </Badge>
      )}
    </div>
  );
};

export const ProjectIndicator: React.FC<ProjectProps> = ({
  numberOfUnreadComments,
  numberOfUnreadMentions,
  isLoading
}) => {
  const showComments = numberOfUnreadComments > 0;
  const showMentions = numberOfUnreadMentions > 0;

  if (isLoading) {
    return (
      <div className={styles.indicatorContainer}>
        <Skeleton
          active
          paragraph={{ rows: 0 }}
          className={styles.indicatorLoader}
        />
      </div>
    );
  }

  if (!showComments) return <div className={styles.indicatorContainer} />;
  return (
    <div className={styles.indicatorContainer}>
      <Badge dot className={styles.indicatorInfo}>
        <MessageCircle width={15} height={15} color={'#848484'} />
      </Badge>
      <span>{numberOfUnreadComments}</span>
      {showMentions && (
        <>
          <Badge dot className={styles.indicatorInfo}>
            <AtSign width={15} height={15} color={'#848484'} />
          </Badge>
          <span>{numberOfUnreadMentions}</span>
        </>
      )}
    </div>
  );
};
