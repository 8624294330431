export const en = {
  project: 'project',
  team: 'team',
  welcomeToBugherd: 'Welcome to BugHerd',
  toGetStarted: 'To get started, we just need some details',
  waitWhats: 'Wait, what’s ',
  bugherd: 'BugHerd',
  tickToSubscribe:
    'Tick to subscribe and get BugHerd updates (no spam, we promise)',
  youveBeenInvitedProject: (projectName: string) =>
    `You’ve been invited to join the ${projectName} project!`,
  youveBeenInvitedTeam: (teamName: string) =>
    `You’ve been invited to join the ${teamName} team!`
};

export type Translations = typeof en;
