import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, message as antmessage } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Button, Input } from 'antd';
import { post } from 'utils/fetch';

import styles from './index.module.css';

export default ({
  sitekey,
  isReviewApp
}: {
  sitekey: string;
  isReviewApp: boolean;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string | undefined>();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleReCAPTCHA = (value: string | null) => {
    if (value) {
      setRecaptcha(value);
    } else {
      setRecaptcha(undefined);
      console.warn('Captcha expired');
    }
  };

  const onFinish = (values: any) => {
    if (!recaptcha && !isReviewApp) {
      antmessage.warning('ReCaptcha is required to proceed.', 5);
      return;
    }
    setLoading(true);

    const { email } = values;

    post('/users/passwords', {
      email: email,
      'g-recaptcha-response': recaptcha
    })
      .then(body => {
        if (body.error && body.error.message) {
          throw Error(body.error.message);
        } else {
          setSubmitted(true);
          antmessage.success(
            'Please check your inbox for a reset password email.',
            10
          );
        }
      })
      .catch(() => {
        setLoading(false);
        antmessage.error('Something went wrong, please try again.', 5);
      });
  };

  return (
    <div>
      <h1 className={styles.title}>Forgot password?</h1>
      {window.location.search.includes('invalidToken') && (
        <Alert
          className={styles.alert}
          message="Password token is either invalid or expired, enter your email address below to get a new one. "
          type="error"
        />
      )}
      <Form
        className={styles.form}
        name="login"
        onFinish={onFinish}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }]}
        >
          <Input type="email" />
        </Form.Item>

        <ReCAPTCHA
          type="image"
          className={styles.recaptcha}
          onChange={handleReCAPTCHA}
          sitekey={sitekey}
        />

        <div className={styles.center}>
          <Form.Item noStyle>
            <div>
              <Button
                className={styles.submit}
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Send password reset link
              </Button>
            </div>
          </Form.Item>
          <Link to="/sign_in">Back to login</Link>
        </div>
        <div>
          {submitted && (
            <Alert
              className={styles.alert}
              type="info"
              showIcon
              message={
                <p className={styles.alertMessage}>
                  <b>Didn't get an email?</b>
                  <p>
                    Emails are only sent if a matching address is found. If you
                    didn't receive an email, it's possible that we can't find an
                    account that matches the email address you provided.
                  </p>
                </p>
              }
            />
          )}
        </div>
      </Form>
    </div>
  );
};
