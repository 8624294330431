export const en = {
  pathBeginWith: 'The URL path for the location of the task must begin with a ',
  okButton: 'OK',
  alertPopoverContent: (
    taskHost: JSX.Element,
    projectSettings: JSX.Element | string
  ): (string | JSX.Element)[] => [
    'The ',
    taskHost,
    " for this task doesn't match any URLs in your ",
    projectSettings,
    '. Would you like to change it?'
  ],
  alertEmptyUrl: (canEditProjectUrl: boolean): string =>
    `The task location will not work as there is no project URL set${
      canEditProjectUrl ? '. Would you like to set it?' : '.'
    }`,
  cancelButton: 'Cancel',
  exampleDomain: 'https://bugherd.com',
  sitePopoverContent: (example: JSX.Element): (string | JSX.Element)[] => [
    'The URL must have a valid protocol and correct formatting, eg. ',
    example
  ],
  taskHost: 'URL',
  projectSettings: 'project settings',
  location: 'LOCATION:'
};

export type Translations = typeof en;
