import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { BulkRequest, LastUpdate } from '..';
import { Check, RotateCcw, X as Close } from 'lucide-react';
import { Button } from 'antd';
import { undoBulkRequest } from 'appJS/api/CommandApi';
import Loader from 'appJS/../clients/sidebar/components/Loader';
import animationStyles from '../../../../../../clients/sidebar/views/CreateTask/create_task/overlay/animation.module.css';
import cx from 'classnames';

type Props = {
  bulkActions: BulkRequest[];
  lastUpdateAction: BulkRequest | undefined;
  updating: boolean;
  onUndoComplete: () => void;
  setLastBulkUpdate: (lastBulkUpdate: LastUpdate | null) => void;
};

const StatusBar: FC<Props> = ({
  bulkActions,
  lastUpdateAction,
  updating,
  onUndoComplete,
  setLastBulkUpdate
}) => {
  const [undoing, setUndoing] = useState<number>(0);

  useEffect(() => {
    if (bulkActions) {
      const undoneRequest: BulkRequest | undefined = bulkActions.find(
        ({ relatedBulkRequestId }) =>
          relatedBulkRequestId === lastUpdateAction?.id
      );

      if (undoneRequest?.completedAt) {
        setUndoing(0);

        setTimeout(() => setLastBulkUpdate(null), 300);
        setTimeout(onUndoComplete, 500);
      }
    }
  }, [bulkActions]);

  if (!lastUpdateAction) return null;

  const handleUndoBulkAction = () => {
    setUndoing(lastUpdateAction?.id);

    const { details } = lastUpdateAction;
    Object.assign(details, { action: 'update_task' });

    undoBulkRequest(Object.assign(lastUpdateAction, details));
  };

  const taskCount =
    lastUpdateAction?.details?.[updating ? 'objects' : 'success']?.length || 0;

  return (
    <div className={styles.container}>
      {updating ? (
        <Loader
          useDarkStyles
          className={cx(animationStyles.loaderIcon, styles.loaderIcon)}
        />
      ) : (
        <span className={styles.checkContainer}>
          <Check className={styles.checkIcon} />
        </span>
      )}
      <span className={styles.latestBulkAction}>
        {taskCount} tasks {updating ? 'updating' : 'updated'}
      </span>
      {!updating && (
        <Button
          className={styles.undoButton}
          type="link"
          icon={<RotateCcw className={styles.rotateIcon} />}
          loading={!!undoing}
          onClick={handleUndoBulkAction}
        >
          Undo
        </Button>
      )}
      <div
        className={styles.closeContainer}
        onClick={() => setLastBulkUpdate(null)}
      >
        <Close className={styles.closeIcon} />
      </div>
    </div>
  );
};

export default StatusBar;
