import React from 'react';

export class DragListener {
  constructor(props) {
    this.props = props;
  }

  timeout = null;

  onEnter = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onDrag(event);
  };

  onDrop = event => {
    event.preventDefault();
    this.props.onDragEnd();
    const filesCount = event.dataTransfer.files.length;
    for (let i = 0; i < filesCount; i++) {
      this.props.onDrop(event.dataTransfer.files[i]);
    }
  };

  onOver = event => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
    this.timeout = setTimeout(() => this.props.onDragEnd(), 100);
  };

  onPreventDrop = event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'none';
    this.props.onDragEnd();
  };

  listen = () => {
    this.props.container.addEventListener('dragenter', this.onEnter);
    this.props.container.addEventListener('dragover', this.onOver);
    this.props.container.addEventListener('drop', this.onDrop);
    this.props.preventDropContainer.addEventListener(
      'dragover',
      this.onPreventDrop
    );
  };

  unListen = () => {
    this.props.container.removeEventListener('dragenter', this.onEnter);
    this.props.container.removeEventListener('dragover', this.onOver);
    this.props.container.removeEventListener('drop', this.onDrop);
    this.props.preventDropContainer.removeEventListener(
      'dragover',
      this.onPreventDrop
    );
  };
}

function useDragger(container, prevent, handleOnChange, isDragRef) {
  const [isDragging, setIsDragging] = React.useState(false);

  React.useEffect(() => {
    const showDragZone = () => setIsDragging(true);
    const hideDragZone = () => setIsDragging(false);

    let dragListener = new DragListener({
      container: isDragRef ? container.current : container,
      preventDropContainer: prevent,
      onDrag: showDragZone,
      onDragEnd: hideDragZone,
      onDrop: handleOnChange
    });
    dragListener.listen();

    return () => dragListener.unListen();
  }, [container, handleOnChange, isDragRef, prevent]);

  return [isDragging, setIsDragging];
}

export function Dragger({
  container,
  prevent,
  handleOnChange,
  isDragRef = false,
  children
}) {
  let [isDragging, showDragger] = useDragger(
    container,
    prevent,
    handleOnChange,
    isDragRef
  );
  return children(isDragging, showDragger);
}
