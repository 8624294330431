import React from 'react';

export default class ZapierEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.zapierRef = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src =
      'https://zapier.com/apps/embed/widget.js?services=bugherd&limit=10&html_id=zapierEmbed';
    script.async = true;
    script.id = 'zapierScript';
    this.zapierRef.current.appendChild(script);
  }

  render() {
    return <div id="zapierEmbed" ref={this.zapierRef} />;
  }
}
