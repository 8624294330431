import React, { useState, FC } from 'react';

import { UploadFile } from 'antd/lib/upload/interface';

import * as Types from '../types';
import { Task } from '../types/task';

import Preview from '../preview';
import AttachFile from '../attach_file';
import Description from '../description';

import cx from 'classnames';
import styles from './index.module.css';
import { PickerButton } from '../../../SidebarPanel/Detailbar/CommentsAndLog/Comments/CommentStream/Comment/Reactions/PickerButton';
import { handleEmojiSelect } from './handleEmojiSelect';

type Props = {
  setUploadingState: (state: boolean) => void;
  container: HTMLDivElement;
  task: Task;
  accessTo: Types.AccessTo;
  modalContainer: HTMLDivElement;
  modalOuter: HTMLDivElement;
  attachmentsUrl: string;
  removeFile: (file: UploadFile<any>) => void;
  fileList: any[];
  onFileListChange: (fileList: any[]) => void;
  assignees: number[];
  onAssigneesChange: (assignees: number[]) => void;
  description: string;
  onDescriptionChange: (description: string) => void;
  submitTask: () => any;
  severity: Types.Severity;
  setOverlay: (overlay: Types.Overlay) => void;
  onAnnotationChange: (dataURI: Blob) => void;
  dataURI?: Blob | null;
  isAdminView: boolean;
  assignableUsers?: Types.User[];
  subscriptionUrl: string;
  owner: {
    id: number;
    email?: string;
  };
  availableTags: Types.Tag[];
  onTagsChange: (tags: Types.Tag[]) => void;
  tags: Types.Tag[];
  createTagsViaNewTask: boolean;
  handleUpdateUserName: () => void;
  onSeverityChange: (severity: Types.Severity) => void;
  status?: number;
  onStatusChange: (status: number) => void;
  columns: Types.Column[];
  apiDomain: string;
  projectId: number;
};

const LeftPanel: FC<Props> = props => {
  const {
    setUploadingState,
    container,
    task,
    accessTo,
    modalContainer,
    modalOuter,
    attachmentsUrl,
    removeFile,
    fileList,
    onFileListChange,
    onAssigneesChange,
    description,
    onDescriptionChange,
    submitTask,
    assignees,
    severity,
    onSeverityChange,
    status,
    onStatusChange,
    columns,
    tags,
    onTagsChange,
    availableTags,
    setOverlay,
    onAnnotationChange,
    dataURI,
    isAdminView,
    assignableUsers,
    subscriptionUrl,
    owner,
    createTagsViaNewTask,
    apiDomain,
    projectId
  } = props;

  const showScreenshotPreview = !isAdminView;
  const [emojiPopoverOpen, setEmojiPopoverOpen] = useState<boolean>(false);
  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(
    null
  );
  const [caretPosition, setCaretPosition] = useState<number>(0);
  const {
    canEditAssignees,
    canEditSeverity,
    canEditStatus,
    canEditTags
  } = accessTo;

  const onEmojiSelect = (emoji: { native: string }) => {
    handleEmojiSelect({
      emoji,
      description,
      caretPosition,
      onDescriptionChange,
      setCaretPosition,
      descriptionRef: { current: textAreaRef }
    });
  };

  const closePopoverWhenTypingContinues = () => {
    if (emojiPopoverOpen) setEmojiPopoverOpen(false);
  };

  return (
    <div className={cx(styles.leftPanel)}>
      <div
        className={cx({
          [styles.descriptionOuter]: true,
          [styles.shortDescriptionOuter]: !!task.screenshotPolyfill
        })}
      >
        <Description
          submitTask={submitTask}
          description={description}
          onDescriptionChange={onDescriptionChange}
          fullHeight={isAdminView || !!task.screenshotPolyfill}
          assignees={assignees}
          onAssigneesChange={onAssigneesChange}
          canEditAssignees={canEditAssignees}
          assignableUsers={assignableUsers || []}
          severity={severity}
          onSeverityChange={onSeverityChange}
          canEditSeverity={canEditSeverity}
          status={status}
          onStatusChange={onStatusChange}
          columns={columns}
          canEditStatus={canEditStatus}
          tags={tags}
          onTagsChange={onTagsChange}
          availableTags={availableTags}
          canEditTags={canEditTags}
          createTagsViaNewTask={createTagsViaNewTask}
          closePopoverWhenTypingContinues={closePopoverWhenTypingContinues}
          textAreaRef={textAreaRef}
          setTextAreaRef={setTextAreaRef}
          setCaretPosition={setCaretPosition}
        />
        <div className={styles.descriptionFooter}>
          <div className={styles.footerRight}>
            <PickerButton
              getContainer={() => container}
              onEmojiSelect={onEmojiSelect}
              tooltipTitle="Add emoji"
              placement="rightTop"
              setPopoverOpen={() => setEmojiPopoverOpen(!emojiPopoverOpen)}
              popoverOpen={emojiPopoverOpen}
              apiDomain={apiDomain}
              isAdminView={isAdminView}
              projectId={projectId}
            />
            <AttachFile
              {...{
                endpoint: attachmentsUrl,
                setUploadingState,
                fileList,
                onFileListChange,
                removeFile,
                modalContainer,
                modalOuter
              }}
            />
          </div>
        </div>
      </div>
      {showScreenshotPreview && task && (
        <Preview
          {...{
            task,
            dataURI,
            container,
            accessTo,
            severity,
            setOverlay,
            owner,
            subscriptionUrl,
            onAnnotationChange
          }}
        />
      )}
    </div>
  );
};

export default LeftPanel;
