import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { detect } from 'detect-browser';
import { locationType } from '../../../utils/propTypes';
import Alternatives from '../alternatives';
import browserResources from '../extension_config.json';
import InstallButton from '../install_button';
import queryString from 'qs';
import cx from 'classnames';

import styles from './style.module.css';
import sharedStyles from '../shared.module.css';

interface Props {
  location: typeof locationType;
  javascriptInstalled: boolean;
  extensionInstalled: boolean;
  ownerEmail: string;
  flow: string;
  proxyUrl: string;
  projectSite: any;
  newOnboardingExperiment: boolean;
  authenticityToken: string;
  organizationMember: {
    role: string;
  };
  project: {
    devurl: string;
    id: number;
  };
  proxyChromeUrl?: string;
}

const browser = detect();
const browserName = browser?.name;

const fetchWithTimeout = (url, timeout = 5000) => {
  return Promise.race([
    fetch(url),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    )
  ]);
};

const InstallExtension: React.FC<Props> = props => {
  const [currentBrowser, setBrowser] = useState(browserName);
  const [compatibilityCheckDone, setCompatibilityCheckDone] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();

  const getBrowserConfig = () => {
    if (currentBrowser === null) {
      return null;
    }

    return browserResources.find(browser => browser.name === currentBrowser);
  };

  const browserConfig = getBrowserConfig();

  const {
    projectSite,
    proxyUrl,
    newOnboardingExperiment,
    extensionInstalled,
    javascriptInstalled,
    project,
    authenticityToken
  } = props;

  const checkCompatibilityWithProxy = async () => {
    if (!projectSite || !proxyUrl) {
      return false;
    }

    const projectUrl = `${projectSite.scheme}://${projectSite.host}${
      projectSite.port ? `:${projectSite.port}` : ''
    }${projectSite.path}`;

    const compatibleEndpoint = `${proxyUrl}/compatibility?url=${projectUrl}`;
    try {
      const response: any = await fetchWithTimeout(compatibleEndpoint);
      const { compatible } = await response.json();
      return !!compatible;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    (async function() {
      if (newOnboardingExperiment) {
        const compatible = await checkCompatibilityWithProxy();

        if (compatible) {
          navigate('/proxy/success' + location.search);
        } else {
          setCompatibilityCheckDone(true);
        }
      } else {
        setCompatibilityCheckDone(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (compatibilityCheckDone && (extensionInstalled || javascriptInstalled)) {
      navigate('/extension/success' + location.search);
    }
  }, [compatibilityCheckDone]);

  const unsupportedBrowser = !browserConfig;

  const handleEmbedNotInstalled = event => {
    event.preventDefault();
    const qs = queryString.parse(location.search);

    const formData = new FormData();
    if (qs.validation_token) {
      formData.append('validation_token', qs.validation_token);
    }
    formData.append('project_id', project.id);

    fetch('/accept-invite/embed_code_not_installed', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': authenticityToken
      },
      body: formData
    })
      .then(res => {
        navigate(
          `success?project_id=${project.id}${
            qs.validation_token
              ? `&validation_token=${qs.validation_token}`
              : ''
          }`
        );
      })
      .catch(error => console.error(error));
  };

  if (!compatibilityCheckDone) {
    return <div>Please wait...</div>;
  }

  return (
    <div
      className={cx(
        sharedStyles.container,
        styles.container,
        styles.linksClosed
      )}
    >
      <div className={sharedStyles.header}>
        <h1>You're almost ready to go</h1>
        <div className="intro">
          <p className={sharedStyles.info}>
            {unsupportedBrowser
              ? 'Unfortunately the BugHerd extension is not available for your browser.'
              : "All that's left to do is get the BugHerd Browser Extension."}
          </p>
        </div>
        {unsupportedBrowser && (
          <div className={styles.unsupportedMessage}>
            Alternatively, you can use the BugHerd extension with one of the
            browsers listed below, otherwise please{' '}
            <a
              href=""
              className={styles.notifyOwner}
              onClick={handleEmbedNotInstalled}
            >
              click here
            </a>{' '}
            to continue and we will send further instructions to the account
            owner.
          </div>
        )}
        <InstallButton
          browserConfig={browserConfig}
          redirectCallback={() =>
            navigate('/extension/installing' + location.search)
          }
        />
        {!unsupportedBrowser && (
          <Link to={'/extension/error'} className={styles.cantInstall}>
            <b>I can't install {browserConfig.display} extensions</b>
          </Link>
        )}
        {browserConfig && <hr className={styles.hr} />}
        {!unsupportedBrowser && (
          <div className={styles.extensionContainer}>
            <span className={styles.extensionDesc}>
              What's an extension?… this thing
            </span>
            <img
              src={require(`./images/${
                browserConfig ? browserConfig.image : 'browser_toolbar'
              }.png`)}
              alt="Your web browser"
              className={styles.browser}
            />
          </div>
        )}
        {!browserConfig && <hr className={styles.hr} />}
        <Alternatives
          hasBrowserData={!!browserConfig}
          setBrowser={setBrowser}
          currentBrowser={currentBrowser}
        />
      </div>
    </div>
  );
};

export default InstallExtension;
