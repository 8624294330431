export const en = {
  accountDetails: 'Account Details',
  authenticationToken: 'Authentication Token',
  apiKeySpecificAndFullAccess:
    "Your API key is account specific and allows full access to manage this account's data.",
  reset: 'Reset',
  copy: 'Copy',
  apiDocumentation: 'API Documentation',
  save: 'Save',
  setupSsoBelow:
    'To set up SSO for your organization, use the Setup link below.',
  setupLink: 'Setup link: ',
  clickHereToBegin: 'Click here to begin',
  expiresIn24Hours: 'Expires in 24 hours or when set up is complete',
  organizationUrl: 'Organization URL:',
  sso: 'Single Sign-On (SSO)',
  ssoForBugherd: 'SSO for BugHerd',
  largeOrganisationSetup:
    'Need to get everyone in your large organisation set up with BugHerd?',
  ssoDescription:
    'Single Sign-on (SSO) is the easiest and most secure way to do it.',
  availableOnEnterprisePlan: 'Available only to the enterprise plan',
  contactUsForPricing: 'Contact us for pricing'
};

export type Translations = typeof en;
