export const en = {
  openWebsite: 'Open website',
  uploadAsset: 'Upload an asset',
  uploadFigma: 'Upload a Figma file',
  setupYourProject: 'Set up your project',
  setupIntro:
    'Complete these steps to ensure you, your team and client get the most out of BugHerd.',
  addADeliverableTitle: 'Add a website or design',
  addADeliverableDescription:
    'Add something to share with clients for feedback – a website, a design, some images or documents.',
  learnHow: 'Learn how',
  add: 'Add',
  getExtensionTitle: 'Get the extension or install the Javascript code',
  getExtensionDescription:
    'BugHerd needs some Javascript code in your page or the extension to work.',
  getExtension: 'Get Extension',
  javascriptCode: 'Javascript code',
  inviteTeamTitle: 'Invite your team and clients',
  inviteTeamDescription:
    'BugHerd is better with others. Invite your team to BugHerd as members to assign them tasks. Invite your clients as guests to leave feedback.',
  share: 'Share',
  setupIntegrationTitle: 'Set up an integration (optional)',
  setupIntegrationDescription:
    'Integrate BugHerd with Slack, Jira, Asana, ClickUp, Monday or other tools.',
  setup: 'Setup',
  needHelp: 'Need help?',
  needHelpDescription: "We're here for you.",
  bookADemo: 'Book a demo',
  contactSupport: 'Contact support',
  readOurSupportDocs: 'Read our support docs',
  upgrade: 'Upgrade',
  chooseTheRightPlan: 'Choosing the right plan',
  chooseTheRightPlanDescription:
    "You're trialing our premium plan. See what other plans are available.",
  exploreOurPlans: 'Explore our plans',
  skip: 'Skip',
  skipped: 'Skipped',
  complete: 'Complete',
  completeGettingStartedSuccess:
    "All set! You've completed the Setup guide tasks.",
  completeGettingStartedError:
    'Something went wrong! Please refresh & try again.',
  watchNow: 'Watch now',
  quickIntroduction: 'A quick introduction to BugHerd',
  quickIntroductionDescription:
    'Relax & take a minute while we give you a quick introduction to BugHerd and how it works.',
  giveFeedback: 'Give feedback',
  manageFeedback: 'Experience giving & managing feedback',
  manageFeedbackDescription:
    'See how easy it is to leave feedback and manage it from your BugHerd task board.'
};

export type Translations = typeof en;
