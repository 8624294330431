import React from 'react';
import { LeftNavMobileItemsArgs, GetItemArgs } from './types';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';
import {
  Folder,
  TableProperties,
  Users,
  Settings,
  ArrowRightLeft,
  PlusCircle
} from 'lucide-react';
import { Button } from 'antd';

const strings = translations[getLangKey()];
const FOLDER_SIZE = 20;

export const leftNavMobileItems = ({
  organizationUrl,
  userActivities,
  team,
  settings,
  multipleOrganizations,
  createProject,
  newProject,
  viewTeam,
  integrations,
  billing,
  isMobileView,
  viewUserActivities,
  organizationId,
  organizations
}: LeftNavMobileItemsArgs): GetItemArgs[] => {
  return [
    {
      href: organizationUrl,
      text: strings.myProjects,
      key: 'projects',
      icon: <Folder size={24} />
    },
    {
      href: userActivities,
      text: strings.dashboard,
      key: 'dashboard',
      icon: <TableProperties size={FOLDER_SIZE} />,
      hidden: !viewUserActivities
    },
    {
      href: team,
      text: strings.team,
      key: 'team',
      icon: <Users size={FOLDER_SIZE} />,
      hidden: !viewTeam
    },
    {
      href: settings,
      text: strings.orgSettings,
      key: 'org_settings',
      icon: <Settings size={FOLDER_SIZE} />,
      hidden: !integrations && !billing
    },
    {
      href: '/organizations',
      text: strings.switchOrg,
      key: 'switch_org',
      icon: <ArrowRightLeft size={FOLDER_SIZE} />,
      children: (organizations ?? []).map(({ id, display_name }) => ({
        key: id.toString(),
        label: <a href={`/organizations/${id}`}>{display_name}</a>,
        disabled: id === organizationId,
        title: id === organizationId ? strings.currentOrg : undefined
      })),

      hidden: !multipleOrganizations
    },
    {
      key: 'div-3',
      type: 'divider',
      hidden: isMobileView || !createProject
    },
    {
      label: (
        <Button href={newProject} type="link">
          {strings.createProject}
        </Button>
      ),
      key: 'new_project',
      icon: <PlusCircle size={FOLDER_SIZE} />,
      hidden: !createProject
    },
    { key: 'div-3', type: 'divider', hidden: !isMobileView }
  ];
};
