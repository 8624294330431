import { SorterResult } from 'antd/lib/table/interface';
import { UserTask, OrderBy, UserTaskOrder } from './types';
import * as LocalStorage from '../../models/LocalStorage';

const sorterFieldMap: Record<string, string> = {
  'project.name': 'projectName'
};

export const orderTaskByColumn = (sorter: SorterResult<UserTask>) => {
  let sortOrder: UserTaskOrder = {};
  const sorterField: string =
    sorterFieldMap[sorter.field as string] || (sorter.field as string);
  if (sorter.order === 'ascend') {
    sortOrder[sorterField] = OrderBy.ASC;
  } else if (sorter.order === 'descend') {
    sortOrder[sorterField] = OrderBy.DESC;
  }
  return sortOrder;
};

export const getPreferenceOrderBy = () => {
  const previousOrderBy = LocalStorage.getData(LocalStorage.UA_MY_TASK_ORDERBY);
  return previousOrderBy ? previousOrderBy : {};
};

export const setPreferenceOrderBy = (taskorder: UserTaskOrder) =>
  LocalStorage.setData(LocalStorage.UA_MY_TASK_ORDERBY, taskorder);

export const defaultSortOrder = (column: string) => {
  const previousOrderBy = LocalStorage.getData(LocalStorage.UA_MY_TASK_ORDERBY);
  if (previousOrderBy && previousOrderBy[column]) {
    return previousOrderBy[column] === OrderBy.ASC ? 'ascend' : 'descend';
  } else {
    return null;
  }
};
