import * as React from 'react';
import { Select } from 'antd';
import { getLangKey } from 'models/Language';
import * as translations from './strings';
import cx from 'classnames';
import styles from './index.module.css';

type SelectOptionMode = "multiple" | "tags" | undefined;

export interface DropDownSelectPropsType {
  question: string[];
  defaultText: string;
  getContainer: () => HTMLElement;
  mode?: SelectOptionMode;
  value?: string | string[];
  onChange: React.Dispatch<string> | React.Dispatch<string[]>;
  name: string;
  errorState: boolean;
}

const { Option } = Select;
const MultipleMode = 'multiple';

const DropDownSelect: React.FunctionComponent<DropDownSelectPropsType> = ({
  question,
  defaultText,
  getContainer,
  mode,
  value,
  onChange,
  name,
  errorState
}) => {
  const strings = translations[getLangKey()];

  const conditionalProps = () => {
    let props: {
      placeholder?: string;
      defaultValue?: string;
      value?: string | string[];
    };
    if (mode === MultipleMode) {
      props = { placeholder: defaultText };
    } else {
      props = { defaultValue: defaultText };
    }
    if (value) {
      props.value = value;
    }
    return props;
  };

  return (
    <Select
      className={cx(styles.dropdown, {
        [styles.unused]: !value || !value.length,
        [styles.multiple]: mode === MultipleMode,
        [styles[name]]: true,
        [styles.dropdownError]: !value && errorState
      })}
      mode={mode}
      dropdownMatchSelectWidth={false}
      {...conditionalProps()}
      getPopupContainer={getContainer}
      onChange={(event: any) => onChange(event)}
      bordered={false}
    >
      {question.map(optionValue => (
        <Option key={optionValue} value={optionValue}>
          {strings[optionValue]}
        </Option>
      ))}
    </Select>
  );
};

export default DropDownSelect;
