import get from 'lodash/get';

export type Application = { get: (key: string) => any } | null | undefined;

export const PROJECT_TAGS = 'projectTags';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const SHOWING_MODAL = 'showingModal';
export const SIDEBAR_WIDTH = '79px';

export const getApplication = () =>
  get(window, 'bugherd.application') as Application;

export const getLangKey = () => 'en' as const;

export const getApplicationDomainUrl = () => {
  const application = getApplication();
  return application ? application.get('bugherdUrl') : null;
};

export const getScreenshotServerUrl = () => {
  const application = getApplication();
  return application ? application.get('screenshotServerUrl') : null;
};

export const getBugherd = () => get(window, 'bugherd');

export const getBugsnag = () => get(window, 'bugsnagClient');
