import { matchesQueryString } from './matchQuery';
import { matchesFragment } from './matchFragment';
import { ProjectSite } from './types';
import { orderBy } from 'lodash-es';
import qs from 'query-string';

export function matchProjectSite({
  site,
  browserUrl
}: {
  site: ProjectSite;
  browserUrl: URL;
}): boolean {
  if (browserUrl.protocol !== site.scheme + ':') return false;
  if (browserUrl.hostname !== site.host) return false;
  if (site.port && browserUrl.port !== site.port) return false;
  if (site.path && !browserUrl.pathname.startsWith(site.path)) {
    return false;
  }
  if (
    site.query &&
    !matchesQueryString({
      siteQs: site.query,
      browserQs: browserUrl.searchParams
    })
  ) {
    return false;
  }
  if (
    site.fragment &&
    !matchesFragment({
      siteFragment: site.fragment,
      browserFragment: browserUrl.hash
    })
  ) {
    return false;
  }
  return true;
}

export function getBestMatchedProjectSite({
  matchedSites,
  browserLocationUrl
}: {
  matchedSites: ProjectSite[];
  browserLocationUrl: URL;
}): ProjectSite | undefined {
  const sortedSites = orderBy(
    matchedSites,
    [
      site1 => site1.path.length,
      site1 => Object.keys(qs.parse(site1.query || '')).length
    ],
    ['desc', 'desc']
  );

  const locationSearch = qs.parse(browserLocationUrl.search || '');
  return sortedSites.find(site => {
    const matchesPath = browserLocationUrl.pathname.startsWith(site.path);
    const siteQueries = qs.parse(site.query || '');
    let matchesQueries = true;
    Object.keys(siteQueries).forEach(key => {
      matchesQueries =
        matchesQueries &&
        !!locationSearch[key] &&
        locationSearch[key] === siteQueries[key];
    });
    return matchesPath && matchesQueries;
  });
}

export function getHostnamePathfromSite(site: ProjectSite): string {
  const port = site.port ? ':' + site.port : '';
  const pathname = site.path && site.path === '/' ? '' : site.path;
  return (
    site.scheme +
    '://' +
    site.host +
    port +
    pathname.replace(/\/$/, '') +
    (site.query ? `?${site.query}` : ``)
  );
}
