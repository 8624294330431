import React, { FC, useState } from 'react';
import { App, ConfigProvider } from 'antd';

import { antdTheme } from '../../../clients/shared/theme';
import { getCurrentProjectName, getCurrentProjectUrl } from 'models/Project';
import {
  getCurrentProjectId,
  getCurrentOrganizationId,
  getCurrentUserId
} from 'models/Organization';

import Cards from './Cards';
import Onboarding from './Onboarding';
import { KanbanProps as Props } from './types';
import ProjectTour from './ProjectTour';

import styles from '../../../clients/shared/overrides.module.css';
import { CurrentUserProvider } from '../../../../clients/providers/CurrentUser';
import { Provider } from 'react-redux';

const Kanban: FC<Props> = props => {
  const {
    showOnboarding,
    isJavascriptInstalled,
    kanbanLoading,
    isExtensionInstalled,
    widgetCode,
    siteErrors,
    lastSiteCheckAt,
    isTaskCreated,
    experiments,
    closeTask,
    setActiveTask,
    isGuest,
    viewGuestKanban
  } = props;

  const [isLegacyOnboardingComplete, setLegacyOnboardingComplete] = useState(
    false
  );

  const completedOnboarding =
    !showOnboarding || localStorage.getItem('finished_project_onboarding');

  const hasNewOnboardingExperiment =
    window?.project_board_props?.assetFeedbackProps?.project
      ?.hasNewOnboardingExperiment;
  const hasChecklistOnboardingExperiment = experiments.includes(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = experiments.includes(
    'add_website_onboarding_variant'
  );
  const hasNoProxyOnboardingExperiment = experiments.includes(
    'add_website_onboarding_no_proxy'
  );
  const hasCaptureUrlOnboardingExperiment = experiments.includes(
    'capture_url_onboarding_variant'
  );

  const hasNewOnboarding =
    hasNewOnboardingExperiment ||
    hasChecklistOnboardingExperiment ||
    hasAddWebsiteOnboardingExperiment ||
    hasNoProxyOnboardingExperiment ||
    hasCaptureUrlOnboardingExperiment;

  const showProjectTour =
    (!kanbanLoading && !completedOnboarding && hasNewOnboarding) ||
    (!kanbanLoading &&
      !completedOnboarding &&
      !hasNewOnboarding &&
      isLegacyOnboardingComplete);

  const handleOnCloseModal = () => {
    setLegacyOnboardingComplete(true);
  };

  return (
    <CurrentUserProvider
      apiDomain={''}
      projectId={Number(getCurrentProjectId())}
    >
      <ConfigProvider theme={antdTheme}>
        <Provider store={window.bugherdStore}>
          <App className={styles.antdOverrides}>
            {(!isGuest || viewGuestKanban) && <Cards {...props} />}
            {/* Project tour for legacy & new onboarding */}
            {!isGuest && showProjectTour && (
              <ProjectTour
                projectId={getCurrentProjectId()}
                closeTask={closeTask}
                setActiveTask={setActiveTask}
              />
            )}
            {/* Legacy onboarding modal */}
            {!isGuest &&
              !kanbanLoading &&
              !completedOnboarding &&
              !hasNewOnboarding && (
                <Onboarding
                  showOnboarding={!completedOnboarding}
                  isWebsiteVisited={isTaskCreated}
                  isJavascriptInstalled={isJavascriptInstalled}
                  projectName={getCurrentProjectName()}
                  projectUrl={getCurrentProjectUrl()}
                  projectId={getCurrentProjectId()}
                  userId={getCurrentUserId()}
                  organizationId={getCurrentOrganizationId()}
                  isExtensionInstalled={isExtensionInstalled}
                  widgetCode={widgetCode}
                  siteErrors={siteErrors}
                  lastSiteCheckAt={lastSiteCheckAt}
                  experiments={experiments}
                  onCloseModal={handleOnCloseModal}
                />
              )}
          </App>
        </Provider>
      </ConfigProvider>
    </CurrentUserProvider>
  );
};

export default Kanban;
