export default async function _fetch(endpoint, options = {}) {
  const csrfAttribute =
    document && document.querySelector('meta[name=csrf-token]');
  const csrfHeader = csrfAttribute
    ? { 'X-CSRF-Token': csrfAttribute.getAttribute('content') }
    : {};
  const headerAccept = 'application/json';
  options = Object.assign({}, options, {
    credentials: 'include',
    headers: Object.assign(
      {},
      options.headers || {},
      {
        'Content-Type': 'application/json',
        Accept: headerAccept
      },
      csrfHeader
    )
  });
  const response = await fetch(endpoint, options);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export function post(endpoint, body, options = {}) {
  options = Object.assign({}, options, {
    method: 'POST',
    body: JSON.stringify(body)
  });
  return _fetch(endpoint, options);
}

export function put(endpoint, body, options = {}) {
  options = Object.assign({}, options, {
    method: 'PUT',
    body: JSON.stringify(body)
  });
  return _fetch(endpoint, options);
}

export function deleteItem(endpoint, options = {}) {
  options = Object.assign({}, options, {
    method: 'DELETE'
  });
  return _fetch(endpoint, options);
}

export function get(endpoint, options = {}) {
  options = Object.assign({}, options, { method: 'GET' });
  return _fetch(endpoint, options);
}

export const fetchWithTimeout = (url, timeout = 5000) => {
  return Promise.race([
    fetch(url),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    )
  ]);
};
