import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SiteFeedback from './SiteFeedback';

export default (props: any) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/projects/${props.project.id}/sites/${props.projectSite.id}`}
          element={<SiteFeedback {...props} />}
        />
      </Routes>
    </BrowserRouter>
  );
};
