import { Button, Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';
import { AlignJustify, X as Remove } from 'lucide-react';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import cx from 'classnames';

interface ColumnInputProps {
  value?: string;
  id: string;
  removeColumn: (id: string) => void;
  onBlur: (id: string, value: string) => void;
  onChange: (id: string, value: string) => void;
  removeDisabled?: boolean;
  dragHandleProps?: any;
  
}

const ColumnInput: React.FC<ColumnInputProps> = ({
  value,
  id,
  removeColumn,
  onBlur,
  removeDisabled,
  dragHandleProps,
  onChange
}) => {
  const strings = translations[getLangKey()];

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className={styles.inputFlex}>
        <Input.Group
          compact
          className={cx(styles.inputFlex, styles.columnInputGroup)}
        >
          <Button
            size="large"
            className={styles.arrangeButton}
            icon={<AlignJustify className={styles.justifyIcon} />}
            {...dragHandleProps}
          />
          <Form.Item
            className={styles.columnInputWrapper}
          >
            <Input
              className={styles.columnInput}
              ref={inputRef}
              size="large"
              onBlur={e => onBlur(id, e.target.value)}
              onChange={e => onChange(id, e.target.value)}
              value={value}
              placeholder={strings.newColumn}
              required
            />
          </Form.Item>
        </Input.Group>
        <Button
          disabled={removeDisabled}
          className={styles.removeButton}
          title="Remove URL"
          icon={<Remove className={styles.removeUrl} />}
          onClick={() => removeColumn(id)}
        />
      </div>
    </>
  );
};

export default ColumnInput;
