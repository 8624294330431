import React, { FC, MutableRefObject, KeyboardEvent, ReactNode } from 'react';
import cx from 'classnames';
import Avatar from '../../../../../../../../shared/Avatar';
import { User } from '../../../../../../../models/User';
import { canUserViewPrivateComments } from '../../permissions';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import * as translations from './strings';
import { getLangKey } from '../../../../../../../models/Language';
import styles from './styles.module.css';
import { MAX_COMMENT_LENGTH } from '..';
import { useCommentsState } from '../../../../../../../../providers/Comments';

const strings = translations[getLangKey()];

type Props = {
  mentionRef: MutableRefObject<HTMLInputElement | null>;
  comment: string;
  isPrivate: boolean;
  handleOnChange: (value: string) => void;
  onSubmit: () => void;
  handleOnFocus: () => void;
  onWrongShortcut: () => void;
};

const AtMention: FC<Props> = ({
  mentionRef,
  comment,
  isPrivate,
  handleOnChange,
  onSubmit,
  handleOnFocus,
  onWrongShortcut
}) => {
  const {
    users,
    guestVisible,
    assignGuestsAllowed,
    assignGuests,
    assignees
  } = useCommentsState();
  const mentionsClassName: string = isPrivate ? 'membersOnly' : 'allUsers';

  const submitShortcut = (e: KeyboardEvent) =>
    (e.keyCode === 10 || e.keyCode === 13) && (e.ctrlKey || e.metaKey);

  const submitShortcutRetired = (e: KeyboardEvent) =>
    (e.keyCode === 10 || e.keyCode === 13) && e.shiftKey;

  const handleInputChange = (value: string) => {
    if (comment.trim().length < MAX_COMMENT_LENGTH) {
      handleOnChange(value);
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (submitShortcut(event)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (submitShortcutRetired(event)) {
      onWrongShortcut();
    }
    if (submitShortcut(event)) {
      event.preventDefault();
      onSubmit();
    }
  };

  const filterMentions = (query: string) => {
    const usersAccumulator: { [key: string]: any[] } = {
      guests: [],
      members: [],
      collabs: []
    };
    const { guests, members, collabs } = users
      .filter(
        (user: User) =>
          (user.role !== 'guest' ||
            guestVisible ||
            (assignGuestsAllowed &&
              assignGuests &&
              assignees.find(({ id }) => id === user.id))) &&
          (!isPrivate || canUserViewPrivateComments(user))
      )
      .reduce((acc, user) => {
        acc[
          user.role === 'guest'
            ? 'guests'
            : user.role === 'collaborator'
            ? 'collabs'
            : 'members'
        ].push(user);
        return acc;
      }, usersAccumulator);

    guests.sort((a, b) =>
      (a.name || a.email || '').localeCompare(b.name || b.email || '')
    );
    collabs.sort((a, b) => a.name.localeCompare(b.name));
    members.sort((a, b) => a.name.localeCompare(b.name));

    return [...members, ...collabs, ...guests]
      .map(({ id, name }: User) => ({ id, display: name }))
      .filter((user: { id: string | number; display: string }) => {
        const lowerCaseDisplay = user.display.toLowerCase();
        const startOfLowerCaseDisplay = lowerCaseDisplay.slice(0, query.length);
        return startOfLowerCaseDisplay === query.toLowerCase();
      });
  };

  const renderSuggestion = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: ReactNode,
    index: number,
    focused: boolean
  ) => (
    <div
      className={cx(styles.user, {
        [styles.selected]: !!focused
      })}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          // @ts-ignore
          className={styles.avatar}
          member={users.find(user => user.id === suggestion.id)}
          size="default"
        />
      </div>
      {highlightedDisplay}
    </div>
  );

  return (
    <MentionsInput
      value={comment}
      onChange={event => handleInputChange(event.target.value as string)}
      className={mentionsClassName}
      classNames={styles}
      onFocus={handleOnFocus}
      placeholder={strings.addComment}
      inputRef={mentionRef}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      allowSpaceInQuery
      maxLength={MAX_COMMENT_LENGTH}
    >
      <Mention
        trigger="@"
        data={filterMentions}
        appendSpaceOnAdd
        renderSuggestion={renderSuggestion}
        className={styles.mentions}
        displayTransform={(id, display) => `@${display}`}
      />
    </MentionsInput>
  );
};

export default AtMention;
