import React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { Button, Input, Col, Row, message, Spin, Alert, Select } from 'antd';

import Avatar from '../../../../clients/shared/Avatar';
import AvatarUpload from './avatarUpload';

import styles from './index.module.css';
import * as translations from './strings';

import timezones from './timezones';

const strings: translations.Translations = translations.en;
const { Option } = Select;

type UserProfileProps = {
  user: {
    name: string;
    surname: string;
    password?: string;
    email: string;
    avatar?: string;
    time_zone?: string;
    workos_id?: string;
  };
  authenticityToken: string;
  avatarUrl: string;
} & FormComponentProps;

const UserProfile: React.FC<UserProfileProps> = props => {
  const [user, setUser] = React.useState(props.user);
  const [confirmDirty, setConfirmDirty] = React.useState(false);
  const [loading, setLoading] = React.useState({
    details: false,
    password: false
  });
  const [avatarLoading, setAvatarLoading] = React.useState(false);

  const { getFieldDecorator } = props.form;

  const httpRequester = async (url, httpMethod, request) => {
    const httpRequest = { method: httpMethod, ...request };
    let httpResponse = null;
    try {
      httpResponse = await fetch(url, {
        ...httpRequest
      });
      if (httpResponse.status && httpResponse.status !== 200) {
        throw await httpResponse.json();
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-expressions
      (e.error && e.error.length) > 0
        ? message.error(e.error[0])
        : message.error('Something went wrong, please try again');
    } finally {
      setLoading({ password: false, details: false });
      return httpResponse;
    }
  };

  const uploadAvatar = async (s3Url, key, success) => {
    setAvatarLoading(true);
    const httpResponse = await httpRequester(success + '?key=' + key, 'GET', {
      credentials: 'include'
    });
    setAvatarLoading(false);
    if (httpResponse && httpResponse.status === 200) {
      window.location.reload();
      message.success('Profile picture uploaded');
    }
  };

  const removeAvatar = async () => {
    const formData = new FormData();
    formData.append('utf8', '✓');
    formData.append('_method', 'patch');
    formData.append('authenticity_token', props.authenticityToken);
    formData.append('user[avatar_url]', '');

    const httpResponse = await httpRequester('/save_settings', 'POST', {
      credentials: 'include',
      body: formData
    });
    if (httpResponse.status && httpResponse.status === 200) {
      window.location.reload();
      message.success('Updated details');
    }
  };

  const updateDetails = async setPass => {
    const formData = new FormData();
    formData.append('utf8', '✓');
    formData.append('_method', 'patch');
    formData.append('authenticity_token', props.authenticityToken);

    if (setPass) {
      setLoading({ password: true });
      formData.append('user[password]', user.password || '');
    } else {
      setLoading({ details: true });

      formData.append('user[name]', user.name);
      formData.append('user[surname]', user.surname);
      formData.append('user[email]', user.email);
      formData.append('user[time_zone]', user.time_zone || 'UTC');
    }
    const httpResponse = await httpRequester('/save_settings', 'POST', {
      credentials: 'include',
      body: formData
    });
    if (httpResponse.status && httpResponse.status === 200) {
      window.location.reload();
      message.success('Updated details');
    }
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback('The two passwords entered are inconsistent');
    } else {
      callback();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(
      ['email', 'firstName', 'lastName'],
      (err, values) => {
        if (!err) {
          updateDetails(false);
        }
      }
    );
  };

  const handleSubmitPassword = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(
      ['password', 'confirmPassword'],
      (err, values) => {
        if (!err) {
          updateDetails(true);
        }
      }
    );
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <h2>Edit your Profile</h2>
      <div>
        <h3>Basic Details</h3>
        <Form layout="vertical" hideRequiredMark onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col xs={24} md={16}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator('firstName', {
                      initialValue: props.user.name,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your First Name'
                        }
                      ]
                    })(
                      <Input
                        onChange={event => {
                          let updateUser = { ...user };
                          updateUser.name = event.target.value;
                          setUser(updateUser);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator('lastName', {
                      initialValue: props.user.surname,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your Last Name'
                        }
                      ]
                    })(
                      <Input
                        onChange={event => {
                          let updateUser = { ...user };
                          updateUser.surname = event.target.value;
                          setUser(updateUser);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email', {
                      initialValue: props.user.email,
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not a valid E-mail'
                        },
                        {
                          required: true,
                          message: 'Please enter your email',
                          whitespace: true
                        }
                      ]
                    })(
                      <Input
                        onChange={event => {
                          let updateUser = { ...user };
                          updateUser.email = event.target.value;
                          setUser(updateUser);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Timezone">
                    {getFieldDecorator('time_zone', {
                      initialValue: props.user.time_zone,
                      rules: [
                        {
                          required: false
                        }
                      ]
                    })(
                      <Select
                        showSearch
                        placeholder={
                          props.user.time_zone === null
                            ? 'UTC'
                            : props.user.time_zone
                        }
                        onChange={value => {
                          let updateUser = { ...user };
                          updateUser.time_zone = value;
                          setUser(updateUser);
                        }}
                      >
                        {Object.entries(timezones).map(
                          ([key, value]) =>
                            key !== props.user.time_zone && (
                              <Option key={key} value={key}>
                                {`${key}`}
                              </Option>
                            )
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  className={styles.button}
                  type="primary"
                  htmlType="submit"
                  loading={loading.details}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <div className={styles.avatarBlock}>
                <AvatarUpload
                  hasAvatar={!props.user.avatar.includes('avatar-generic.svg')}
                  endpoint={props.avatarUrl}
                  onRemove={removeAvatar}
                  onUploadComplete={(s3Url, key, success) => {
                    if (s3Url) {
                      uploadAvatar(s3Url, key, success);
                    }
                  }}
                  render={showState =>
                    !showState && (
                      <Spin indicator={antIcon} spinning={avatarLoading}>
                        <Avatar
                          member={user}
                          size="large"
                          noPhoto={() => (
                            <div className={styles.noAvatar}>
                              Photo not <br /> uploaded{' '}
                            </div>
                          )}
                        />
                      </Spin>
                    )
                  }
                />
              </div>
            </Col>
          </Row>
        </Form>
        {!props.user.workos_id ? (
          <>
            <h3>Update Password</h3>
            <Form
              layout="vertical"
              hideRequiredMark
              onSubmit={handleSubmitPassword}
            >
              <Form.Item label="New Password" wrapperCol={{ span: 16 }}>
                {getFieldDecorator('password', {
                  initialValue: props.user.password,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your password'
                    }
                  ]
                })(
                  <Input.Password
                    onChange={event => {
                      let updateUser = { ...user };
                      updateUser.password = event.target.value;
                      setUser(updateUser);
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Confirm New Password" wrapperCol={{ span: 16 }}>
                {getFieldDecorator('confirmPassword', {
                  initialValue: props.user.password,
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!'
                    },
                    {
                      validator: compareToFirstPassword
                    }
                  ]
                })(<Input.Password onBlur={handleConfirmBlur} />)}
              </Form.Item>
              <Form.Item>
                <Button
                  className={styles.button}
                  type="primary"
                  htmlType="submit"
                  loading={loading.password}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <Alert
            message={strings.updatePasswordDisabled}
            type="info"
            showIcon
          />
        )}
      </div>
    </div>
  );
};

export default Form.create<UserProfileProps>()(UserProfile);
