import React, { FC } from 'react';
import Teams from '../../../../assets/images/icons/integrations/ic-integration-teams.svg';
import Linear from '../../../../assets/images/icons/integrations/ic-integration-linear.svg';
import cx from 'classnames';
import styles from './index.module.css';

type Props = {
  mobileView?: boolean;
  name: string;
  icon: string;
  integrationName: string;
};

export const IntegrationIcon: FC<Props> = ({
  mobileView,
  name,
  icon,
  integrationName
}) => {
  return (
    <span
      className={cx(`integration-icon icon-${name}`, styles.integrationIcon, {
        [styles.mobileIconView]: mobileView
      })}
    >
      {integrationName === 'teams' ? (
        <Teams className={styles.teamsIcon} />
      ) : integrationName === 'linear' ? (
        <Linear className={styles.linearIcon} />
      ) : (
        icon && (
          <img
            className={styles.integrationIconImage}
            src={icon}
            alt={`icon-${integrationName}`}
          />
        )
      )}
    </span>
  );
};
