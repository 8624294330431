import React, { FC } from 'react';
import { Tour } from 'antd';
import styles from './index.module.css';
import projectFolderBarStyles from './project_folder_bar/styles.module.css';
import projectCardStyles from './card/style.module.css';
import cx from 'classnames';
import { CloseModal } from 'appJS/components/CloseModal';

type Props = {
  createdProjectFolder: boolean;
  addedProjectToFolder: boolean;
  completedProjectFolderTour: boolean;
  onCancel: () => Promise<void>;
  currentStep: number;
};

const style = { display: 'none' };

const tourSteps = [
  {
    title: `💡 Now, let's try moving a project to a folder`,
    description: 'Select the project(s) you wish to move',
    target: () => document.querySelector(`.${projectCardStyles.firstCheckbox}`),
    nextButtonProps: { style }
  },
  {
    title: 'Select project folder',
    description: 'Select the folder you wish to move the project(s) to',
    target: () =>
      document.querySelector(`.${projectFolderBarStyles.moveToFolderButton}`),
    nextButtonProps: { style },
    prevButtonProps: { style }
  },
  {
    title: 'Move projects into your folder',
    description: 'Click Update to add your project(s) to your folder',
    target: () =>
      document.querySelector(`.${projectFolderBarStyles.updateButton}`),
    nextButtonProps: { style },
    prevButtonProps: { style }
  }
];

export const ProjectFolderTour: FC<Props> = ({
  currentStep,
  createdProjectFolder,
  addedProjectToFolder,
  completedProjectFolderTour,
  onCancel
}) => {
  return (
    <Tour
      //@ts-expect-error
      steps={tourSteps}
      open={
        !completedProjectFolderTour &&
        createdProjectFolder &&
        !addedProjectToFolder
      }
      closeIcon={
        <CloseModal className={styles.tourCloseIcon} handleClick={onCancel} />
      }
      onClose={onCancel}
      current={currentStep}
      rootClassName={cx(styles.tourContainer, 'antd-container')}
    />
  );
};
