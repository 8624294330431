import React from 'react';
import {
  MousePointer,
  Settings,
  Zap,
  Archive,
  Tag,
  ChevronsLeft,
  UserPlus,
  CheckCircle,
  FolderUp,
  HelpCircleIcon,
  Home
} from 'lucide-react';
import styles from './styles.module.css';
import TaskBoard from './task-board.svg';
import Tags from './tags';
import Users from './users';
import cx from 'classnames';
import { NavItem, NavItemArgs, View } from './types';
const navItems = ({
  triageCount,
  kanbanCount,
  gettingStartedCount,
  tags,
  users,
  setView,
  hasNewOnboarding,
  canAssignGuests
}: NavItemArgs): NavItem[] => [
  // todo: add conditional for this item
  {
    label: 'Setup guide',
    key: View.GettingStarted,
    icon: <CheckCircle className={styles.navIcon} />,
    section: hasNewOnboarding ? 'design' : 'main',
    className: styles.gettingStarted,
    count: gettingStartedCount,
    showIndicator: true,
    withDivider: {
      mobile: hasNewOnboarding
    }
  },
  {
    label: 'Websites & Files',
    icon: <FolderUp className={styles.navIcon} />,
    key: View.Assets,
    section: hasNewOnboarding ? 'design' : 'main',
    withDivider: {
      mobile: hasNewOnboarding
    }
  },
  {
    label: 'Project Home',
    icon: <Home className={styles.navIcon} />,
    key: View.Home,
    section: 'design',
    withDivider: {
      mobile: true
    }
  },
  {
    label: 'Feedback',
    icon: <MousePointer className={styles.navIcon} />,
    count: triageCount,
    key: View.Triage,
    section: 'main'
  },
  {
    label: 'Task Board',
    icon: <TaskBoard className={styles.navIcon} />,
    count: kanbanCount,
    key: View.Kanban,
    section: 'main'
  },
  {
    label: 'Help',
    icon: <HelpCircleIcon className={styles.navIcon} />,
    key: View.Help,
    section: 'main'
  },
  {
    label: 'Archive',
    icon: <Archive className={styles.navIcon} />,
    withDivider: {
      mobile: true
    },
    key: View.Archive,
    section: 'main'
  },
  {
    label: 'Settings',
    icon: <Settings className={styles.navIcon} />,
    key: View.Settings,
    section: 'body'
  },
  {
    label: 'Integrations',
    icon: <Zap className={styles.navIcon} />,
    withDivider: {
      mobile: false
    },
    key: View.Integrations,
    section: 'body'
  },
  {
    label: 'Members',
    icon: <UserPlus className={styles.navIcon} />,
    component: users && (
      <Users
        key="members"
        title="Members"
        users={users.filter(
          user => user.role !== 'guest' && user.role !== 'collaborator'
        )}
      />
    ),
    key: View.Members,
    section: 'body'
  },
  {
    label: 'Guests',
    icon: <UserPlus className={styles.navIcon} />,
    component: users && (
      <Users
        key="guests"
        title="Guests"
        users={users.filter(user => user.role === 'guest')}
        canAssignGuests={canAssignGuests}
      />
    ),
    key: View.Guests,
    section: 'body'
  },
  {
    label: 'Tags',
    icon: <Tag className={styles.navIcon} />,
    component: tags && (
      <Tags key="tags" title="Tags" tags={tags} setView={setView} />
    ),
    key: View.Tags,
    section: 'body'
  },
  {
    label: '',
    icon: <ChevronsLeft className={cx(styles.navIcon, styles.hideIcon)} />,
    key: null,
    section: 'footer'
  }
];

export default navItems;
