export function useScroll({
  event,
  fetchMore,
  loading
}: {
  event: any;
  fetchMore: (isForward: boolean) => void;
  loading: boolean;
}) {
  event.persist();
  const target = event.target;
  if (
    !loading &&
    (target.scrollTop + target.offsetHeight === target.scrollHeight ||
      target.scrollTop < 1)
  ) {
    const fetchAhead = !(target.scrollTop < 1);
    fetchMore(fetchAhead);
  }
}
