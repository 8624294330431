import React, { useEffect, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import cx from 'classnames';
import ColumnName from '../column_name/ColumnName';
import styles from '../../column_header/index.module.css';

interface Column {
  name: string;
  color: string;
  position: number;
}

interface NewColumnHeaderProps {
  onAddColumn?: (column: Column) => void;
  insertAtIndex?: number;
  resetNewColumn?: () => void;
}

const NewColumnHeader: React.FC<NewColumnHeaderProps> = ({
  onAddColumn,
  insertAtIndex,
  resetNewColumn
}) => {
  const [showEditName, setShowEditName] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        scrollIntoView(containerRef.current, {
          behavior: 'smooth',
          inline: 'end',
          scrollMode: 'if-needed'
        });
      }
    }, 1);
  }, []);

  const saveNewColumn = (name: string) => {
    onAddColumn &&
      onAddColumn({
        name,
        position: insertAtIndex || 0,
        color: 'white'
      });
  };

  const cancelAddColumn = () => {
    setShowEditName(false);
    resetNewColumn && resetNewColumn();
  };

  return (
    <div
      className={cx({
        [styles.columnHeader]: true,
        [styles.whiteHeader]: true,
        [styles.draggableHeader]: true,
        ['dragHandler']: !showEditName
      })}
      ref={containerRef}
    >
      <ColumnName
        value={''}
        onCancel={cancelAddColumn}
        onSave={saveNewColumn}
        color="white"
        placeholder="New column name"
      />
    </div>
  );
};

export default NewColumnHeader;
