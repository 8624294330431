import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Progress, Upload, Image } from 'antd';

import styles from './index.module.css';
import cx from 'classnames';
import { Upload as UploadIcon } from 'lucide-react';
import * as translations from './strings';
import { getLangKey } from 'models/Language';
import pdfOrImageFeedback from './assets/pdf_or_image_feedback.png';
import { Project } from './types';
import { upLoadAssets } from './uploadAsset';

const strings = translations[getLangKey()];

const PdfOrImageFeedback = ({ project }: { project: Project }) => {
  document.title = 'Try BugHerd on a PDF or image | BugHerd';

  const {
    onUploadChange,
    isLoading,
    uploadProgress,
    isComplete
  } = upLoadAssets(project);

  const renderUploadContent = () => {
    if (isLoading && uploadProgress >= 0) {
      return (
        <Progress strokeLinecap="butt" type="circle" percent={uploadProgress} />
      );
    } else if (isComplete) {
      return (
        <div>
          <h3>Upload Complete!</h3>
          <p>Redirecting you to the next step...</p>
        </div>
      );
    } else {
      return (
        <>
          <UploadIcon className={styles.uploadIcon} />
          <p> {strings.dragAndDrop} </p>
          <p className={styles.greyText}> {strings.validTypes} </p>
          <div className={styles.smallLink}>{strings.fileBrowser}</div>
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.flexContainerWrapper}>
        <div className={styles.contentWrapper}>
          <p>{strings.stepOne}</p>
          <h2>{strings.TryBugherdOnPdfImage}</h2>
          <p>{strings.uploadPdfImage}</p>

          <Upload
            className={cx(styles.assetUpload, {
              [styles.assetIsUploading]: isLoading
            })}
            name="file"
            onChange={event => onUploadChange(event, false)}
            showUploadList={false}
            multiple
            customRequest={() => {}}
            accept="image/*, .pdf"
          >
            {renderUploadContent()}
          </Upload>
          <Link to="/try-bugherd-on" type="button">
            <Button type="text" className={styles.backButton}>
              {strings.back}
            </Button>
          </Link>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            placeholder
            loading="eager"
            src={pdfOrImageFeedback}
            alt="Give feedback on PDFs or images with BugHerd"
            preview={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PdfOrImageFeedback;
