import React, { FC } from 'react';
import { Table } from 'antd';
import { getLangKey } from 'models/Language';
import { getStatementsTableColumnsConfig } from './statementsTableConfig';
import styles from './index.module.css';
import * as translations from './strings';

type Statement = {
  id: string;
  number: string;
  issued_at: string;
  settled_at: string;
  total_in_cents: number;
};

type StatementsProps = {
  statements: Statement[];
  organizationId: string;
};

const Statements: FC<StatementsProps> = ({ statements, organizationId }) => {
  const strings = translations[getLangKey()];
  const columns = getStatementsTableColumnsConfig(strings, organizationId);

  return (
    <div className={styles.contentContainer}>
      <h3 className={styles.header}>{strings.statementsHeader}</h3>
      {statements && statements.length === 0 ? (
        <div className={styles.emptyState}>
          <p>{strings.statementsEmptyState}</p>
        </div>
      ) : (
        <Table
          className={styles.table}
          rowKey="id"
          dataSource={statements}
          columns={columns}
        />
      )}
    </div>
  );
};

export default Statements;
