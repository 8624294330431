import React, { FC, useEffect, useState, useRef } from 'react';
import Tour from 'reactour';
import Confetti from 'react-dom-confetti';
import { Button } from 'antd';
import { post } from 'utils/fetch';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';

const config = {
  angle: 45,
  spread: 130,
  startVelocity: 20,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 2000,
  stagger: 3,
  width: '5px',
  height: '5px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const taskElement =
  '[data-intercom-target="Task"]:not([data-rbd-drag-handle-draggable-id="null"])';
const taskDetailsElement = '#task_details_container';
const getExtensionElement = '#get-extension-container';
const integrationsElement = '#nav-item-integrations';
const gettingStartedElement = '#nav-item-getting-started';
const assetsElement = '#nav-item-assets';

type Props = {
  projectId: number | null;
  closeTask: () => void;
  setActiveTask: (activeTask: number) => void;
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  active: boolean;
  showButtons?: boolean;
};

const ProjectTour: FC<Props> = ({ projectId, closeTask, setActiveTask }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [activeSteps, setActiveSteps] = useState<Step[]>([]);
  const isMobileView = useMediaQuery('(max-width: 640px)');
  const tourStepRef = useRef(tourStep);
  const activeStepsRef = useRef(activeSteps);
  const hasGettingStartedStep = document.querySelector(gettingStartedElement);

  useEffect(() => {
    tourStepRef.current = tourStep;
  }, [tourStep]);

  useEffect(() => {
    activeStepsRef.current = activeSteps;
  }, [activeSteps]);

  const goToNextTourStep = () => {
    // if last step, close the tour
    if (tourStepRef.current === activeStepsRef.current.length - 1) {
      setIsTourOpen(false);
      return;
    }
    setTourStep(prevTourStep => prevTourStep + 1);
  };
  const goToPrevTourStep = () => setTourStep(prevTourStep => prevTourStep - 1);

  const isKanbanPage =
    window.location.pathname.includes('kanban') ||
    window.location.pathname.includes('tasks');

  const handleFinishTour = async () => {
    localStorage.setItem('finished_project_onboarding', 'true');
    try {
      await post(
        `/projects/${projectId}/complete_project_board_onboarding`,
        {}
      );
    } catch (error) {
      console.error({ error });
    }
  };

  const handleClickStep = node => {
    const handleOnClick = () => {
      goToNextTourStep();
      node.removeEventListener('click', handleOnClick);
    };
    node.addEventListener('click', handleOnClick);
  };

  const handleClickTask = node => {
    const handleShowTaskDetailsTour = e => {
      e.preventDefault();
      e.stopPropagation();
      setActiveTask(node.getAttribute('data-task-id'));
      goToNextTourStep();
      node.removeEventListener('click', handleShowTaskDetailsTour);
      node.removeEventListener('mousedown', handleShowTaskDetailsTour);
    };
    node.addEventListener('click', handleShowTaskDetailsTour);
    node.addEventListener('mousedown', handleShowTaskDetailsTour);
  };

  const getTourSteps = (openConfetti = false) => {
    return [
      {
        selector: taskElement,
        content: (
          <>
            <h2>💡 Here's your feedback!</h2>
            <p>
              It's now a task in your project board and includes a screenshot
              and other metadata captured by BugHerd.
            </p>
            <p>
              <strong>Click it to see more.</strong>
            </p>
          </>
        ),
        action: handleClickTask,
        active: !!document.querySelector(taskElement)
      },
      {
        selector: taskDetailsElement,
        content: (
          <>
            <h2>💡 View your feedback</h2>
            <p>
              BugHerd has captured a screenshot, the URL of the page, plus
              additional info like the browser details and operating system.
            </p>
          </>
        ),
        active: !!document.querySelector(taskDetailsElement),
        showButtons: true
      },
      {
        selector: getExtensionElement,
        content: (
          <>
            <h2>💡 Get the BugHerd extension</h2>
            <p>
              To use BugHerd on any website, you’ll need a browser extension.
            </p>
          </>
        ),
        action: handleClickStep,
        active: !!document.querySelector(getExtensionElement),
        showButtons: true
      },
      {
        selector: assetsElement,
        content: (
          <>
            <h2>
              💡 Websites & Files live in this section of your BugHerd project
            </h2>
            <p>
              Here you can add and manage websites, PDFs, images or Figma files.
            </p>
          </>
        ),
        action: handleClickStep,
        active: !!document.querySelector(assetsElement),
        stepInteraction: false,
        showButtons: true
      },
      {
        selector: integrationsElement,
        content: (
          <>
            <h2>💡 Prefer to manage feedback in another tool?</h2>
            <p>
              Send tasks and updates to Jira, Asana, ClickUp, Monday.com,
              Linear, Trello & more.
            </p>
            {!hasGettingStartedStep && (
              <p>
                <strong>Click on Integrations.</strong>
              </p>
            )}
          </>
        ),
        action: handleClickStep,
        active: !!document.querySelector(integrationsElement),
        stepInteraction: !hasGettingStartedStep,
        showButtons: hasGettingStartedStep
      },
      {
        selector: gettingStartedElement,
        content: (
          <>
            <h2>💡 And that's how easy leaving feedback is with BugHerd</h2>
            <p>What's next?</p>
            <p>
              Click the <strong>Setup guide</strong> to:
              <ul
                style={{
                  listStyle: 'disc',
                  margin: '1rem',
                  paddingLeft: '12px'
                }}
              >
                {' '}
                <li>Add team members</li>
                <li>Set up integrations, or</li>
                <li>Leave more feedback on your project.</li>
              </ul>
            </p>
          </>
        ),
        action: handleClickStep,
        active: !!document.querySelector(gettingStartedElement),
        stepInteraction: false,
        showButtons: true
      }
    ];
  };

  useEffect(() => {
    if (!isKanbanPage || isMobileView) return;

    const MAX_TRIES = 50;
    let tries = 0;

    const intervalId = setInterval(() => {
      if (tries >= MAX_TRIES) return clearInterval(intervalId);
      if (document.querySelector(taskElement)) {
        const initialActiveSteps = getTourSteps().filter(step => step.active);
        setActiveSteps(initialActiveSteps);
        setIsTourOpen(true);
        clearInterval(intervalId);
      }
      tries++;
    }, 200);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isTourOpen) return;

    const openConfetti = activeSteps.length === tourStep + 1;

    const initialActiveSteps = getTourSteps(openConfetti).filter(
      step => step.active
    );
    setActiveSteps(initialActiveSteps);
  }, [tourStep]);

  return (
    <Tour
      steps={activeSteps}
      isOpen={isTourOpen}
      onRequestClose={() => setIsTourOpen(false)}
      accentColor="#3f71e0"
      closeWithMask={false}
      disableDotsNavigation
      goToStep={tourStep}
      lastStepNextButton={<Button type="primary">Finish</Button>}
      rounded={12}
      scrollDuration={500}
      showNavigation={false}
      showCloseButton={false}
      showButtons={activeSteps[tourStep]?.showButtons || false}
      prevStep={goToPrevTourStep}
      onBeforeClose={handleFinishTour}
      nextButton={<Button type="primary">Next</Button>}
      prevButton={<></>}
      nextStep={() => {
        if (activeSteps[tourStep]?.selector === taskDetailsElement) {
          closeTask();
          goToNextTourStep();
        } else {
          goToNextTourStep();
        }
      }}
    />
  );
};

export default ProjectTour;
