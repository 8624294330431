import { format } from 'date-fns';

export const toDateTimeString = (dateTime: Date) => {
  const dateTimeFormat = 'MMM d h:mm aa';
  return format(dateTime, dateTimeFormat);
};

export const trimText = (text: string, maxCount: number) =>
  text.length > maxCount
    ? text.substr(0, maxCount).trimRight() + '...'
    : text;
