import * as React from 'react';
import { CheckOutlined as Check } from '@ant-design/icons';

const Feature: React.FC<{ name: string }> = ({ name }) => {
  return (
    <li>
      <span>
        <Check />
        {name}
      </span>
    </li>
  );
};

export default Feature;
