import React, { FC } from 'react';
import styles from './styles.module.css';
import { Tooltip } from 'antd';
import {
  Laptop as Desktop,
  TabletSmartphone as Tablet,
  Smartphone
} from 'lucide-react';
import cx from 'classnames';

type Props = {
  handleLayout: (layout: string) => void;
  layout: string;
};

const SizePicker: FC<Props> = ({ handleLayout, layout }) => {
  return (
    <div className={styles.controlsContainer}>
      <div className={styles.buttonGroup}>
        <Tooltip title="Smartphone layout">
          <Smartphone
            size={32}
            onClick={() => handleLayout('smartphone')}
            className={cx({
              [styles.activeIcon]: layout === 'smartphone'
            })}
          />
        </Tooltip>
        <Tooltip title="Tablet layout">
          <Tablet
            size={32}
            onClick={() => handleLayout('tablet')}
            className={cx({
              [styles.activeIcon]: layout === 'tablet'
            })}
          />
        </Tooltip>
        <Tooltip title="Desktop layout">
          <Desktop
            size={32}
            onClick={() => handleLayout('desktop')}
            className={cx({
              [styles.activeIcon]: layout === 'desktop'
            })}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default SizePicker;
