import * as React from 'react';
import styles from './index.module.css';
import cx from 'classnames';

type IframeProps = {
  className: string;
  src: string;
  frameBorder: string;
  webkitallowfullscreen: 'true';
  mozallowfullscreen: 'true';
  allowFullScreen: boolean;
};

const HelpVideo = ({ videoKey, className }: { videoKey: string; className?: string }) => {
  const iframeProps: IframeProps = {
    className: styles.helpVideoIframe,
    src: `https://www.loom.com/embed/${videoKey}?hide_owner=true&hide_speed=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`,
    frameBorder: '0',
    webkitallowfullscreen: 'true',
    mozallowfullscreen: 'true',
    allowFullScreen: true
  };

  return (
    <div className={cx(styles.helpVideoOuter, className)}>
      <iframe {...iframeProps} />
    </div>
  );
};

export default HelpVideo;