import React from 'react';
import cx from 'classnames';
import { Input, message } from 'antd';

import styles from './index.module.css';

type Props = {
    widgetCode: string
}

const EmbedCode: React.FC<Props> = props => {
  const { widgetCode } = props;
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(widgetCode);
  };

  return (
    <div className={styles.embedBox}>
      <Input.TextArea
        id="embedCode"
        className={styles.copyCode}
        value={widgetCode}
        readOnly
      />
      <div className={styles.embedCopyBox}>
        <span
          className={cx(styles.copySpan, styles.embedCopy)}
          onClick={() => {
            copyToClipBoard(widgetCode);
            message.info('Copied to clipboard!');
          }}
        >
          Copy
        </span>
      </div>
    </div>
  );
};

export default EmbedCode;
