import React, { FC } from 'react';
import styles from './styles.module.css';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from 'antd';
import cx from 'classnames';

type Props = {
  active: boolean;
  open: boolean;
  onClick: () => void;
  label: string;
  className?: string;
};

const ToolbarButton: FC<Props> = ({
  active,
  open,
  onClick,
  label,
  className
}) => {
  return (
    <Button
      type="primary"
      onClick={onClick}
      className={cx(styles.toolbarButton, className, {
        [styles.active]: active,
        [styles.open]: open
      })}
    >
      {label}
      {open ? (
        <ChevronUp className={styles.chevronIcon} />
      ) : (
        <ChevronDown className={styles.chevronIcon} />
      )}
    </Button>
  );
};

export default ToolbarButton;
