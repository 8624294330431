import React from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
const strings = translations[getLangKey()];
import SocialLogin from '../SocialLogin';
import Iframe from '../shared/Iframe';
import MobileHeader from '../shared/MobileHeader';

export default ({ authenticityToken }: { authenticityToken: string }) => {
  const location = useLocation();
  const queryString = location.search;

  return (
    <>
      <MobileHeader />
      <div className={styles.registerWrapper}>
        <div className={styles.formWebsiteWrapper}>
          <div className={styles.formWrapper}>
            <h1 className={styles.title}>
              {strings.freeTrialToday}
            </h1>

            <Link
              to={`/registrations/sign-up-email${queryString}`}
              type="button"
              className={styles.signUpButton}
            >
              {strings.signUpWithEmail}
            </Link>

            <div className={styles.or}>
              <span>{strings.or}</span>
            </div>

            <SocialLogin authenticityToken={authenticityToken} />

            <div className={styles.accountLink}>
              {strings.alreadyHaveAnAccount}
              <a href="/users/sign_in">Log in</a>
            </div>

            <div className={styles.accountLink}>
              {strings.joinExistingProject}
              <a href="https://bugherd.com/join-existing-team">Click here</a>
            </div>
          </div>
          <div className={cx(styles.backToWebsite, styles.hideOnMobile)}>
            <a href="https://bugherd.com">{strings.backToWebsite}</a>
          </div>
        </div>
        <Iframe />
      </div>
    </>
  );
};
