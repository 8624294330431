import { useEffect } from 'react';
import { get } from '../../utils/fetch';
import { message } from 'antd';
import { EmojiData } from './types';

declare global {
  interface Window {
    emojiData?: EmojiData;
    emojiDataLoading?: boolean;
  }
}

export const useInitEmojiData = ({
  setEmojiData,
  emojiData,
  apiDomain
}: {
  setEmojiData: (emojiData: EmojiData) => void;
  emojiData: EmojiData | null;
  apiDomain: string;
}) => {
  const shouldReturnEarly = (): boolean => {
    const { emojiData: windowEmojiData, emojiDataLoading } = window;
    if (!emojiData && windowEmojiData) setEmojiData(windowEmojiData);
    return !!(emojiData || windowEmojiData || emojiDataLoading);
  };

  const setEmojiDataLoading = (loading: boolean) => {
    window.emojiDataLoading = loading;
  };

  const _setEmojiData = (emojiData: any) => {
    window.emojiData = emojiData;
    setEmojiData(emojiData);
  };

  const fetchAndSetEmojiData = async () => {
    if (shouldReturnEarly()) return;
    setEmojiDataLoading(true);
    try {
      const _emojiData = await get(`${apiDomain}/emoji_data`);
      _setEmojiData(_emojiData);
      setEmojiDataLoading(false);
    } catch (error) {
      console.error('Failed to fetch emoji data', error);
      message.error('Failed to fetch emoji data');
    }
  };

  useEffect(() => {
    const initInterval = setInterval(() => {
      if (!emojiData) {
        fetchAndSetEmojiData();
      } else {
        clearInterval(initInterval);
      }
    });
  }, []);
};
