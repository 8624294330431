import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import { antdForm } from 'jsUtilities/propTypes';
import styles from './index.module.css';

class EmailForm extends React.Component {
  static propTypes = {
    emailPass: PropTypes.func.isRequired,
    form: antdForm
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('email', {
            initialValue: '',
            rules: [
              {
                type: 'email',
                message: 'This is not a valid email'
              }
            ],
            getValueFromEvent: event => {
              this.props.emailPass(event.target.value);
              return event.target.value;
            }
          })(
            <Input
              name="email"
              className={styles.emailInput}
              placeholder="person@company.com"
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(EmailForm);
