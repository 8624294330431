import React, { FC, ReactNode, useState } from 'react';
import { Button } from 'antd';
import cx from 'classnames';
// @ts-ignore
import styles from './styles.module.css';
import * as translations from './strings';
import { getLangKey } from '../../../../models/Language';
import DetailsLabel from '../DetailsLabel';
import { BrowserInfo, SelectorInfo } from '../../../../models/Task';

const strings = translations[getLangKey()];

type Props = {
  browserData: BrowserInfo;
  selector: SelectorInfo;
  absoluteUrl: string;
  canViewSelector: boolean;
  metadata?: {
    [key: string]: any;
  };
  isAdminView?: boolean;
  isRecording: boolean;
};

const AdditionalInfo: FC<Props> = ({
  browserData,
  selector,
  absoluteUrl,
  canViewSelector,
  metadata,
  isAdminView,
  isRecording
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  browserData = browserData || {};
  let selectorPath: string = '';

  if (selector && selector.path) {
    selectorPath = selector.path.split('>').join(' > ');
  }

  const TableElement = ({
    label,
    child
  }: {
    label: string;
    child: ReactNode;
  }) => (
    <tr className={styles.tableRow} key={label}>
      <th className={styles.tableHeader}>{label}</th>
      <td className={styles.tableData}>{child || strings.unknown}</td>
    </tr>
  );

  const getMetadata = () =>
    metadata && Object.keys(metadata)?.length
      ? Object.keys(metadata).map((_key: string, index: number) => (
          <TableElement
            label={_key}
            child={metadata[_key]}
            key={_key + index}
          />
        ))
      : null;

  const getTableContent = () => {
    if (typeof browserData !== 'object' || !Object.keys(browserData).length)
      return null;

    const { os, browser, resolution, colours, windowSize } = browserData;

    type Content = {
      value?: string;
      label: string;
    };

    const tableContent: Content[] = [
      { value: absoluteUrl, label: strings.taskLogged },
      { value: os, label: strings.operatingSystem },
      { value: browser, label: strings.browser },
      { value: resolution, label: strings.resolution },
      { value: windowSize, label: strings.browserWindow },
      { value: colours, label: strings.colorDepth }
    ];

    if (!isRecording) {
      tableContent.splice(3, 0, {
        value: selectorPath,
        label: strings.selector
      });
    }

    return tableContent.map(({ value, label }: Content, index: number) => {
      const isUrl: boolean = index === 0;
      const isSelector: boolean = index === 3;

      if (isUrl)
        return (
          <TableElement
            label={label}
            key={label + value}
            child={
              <a
                href={value}
                title={value}
                target="_blank"
                rel="noreferrer"
                className={cx({ [styles.absoluteUrl]: !isAdminView })}
              >
                {value}
              </a>
            }
          />
        );

      if (!isSelector || canViewSelector)
        return <TableElement label={label} child={value} key={label + value} />;
    });
  };

  return (
    <div className={styles.additionalInfoOuter}>
      <div className={styles.additionalInfoInner}>
        <DetailsLabel label={strings.additionalInfo} />
        <Button
          type="text"
          size="small"
          onClick={() => setExpanded(!expanded)}
          className={styles.toggleButton}
        >
          {!expanded ? strings.showDetails : strings.hideDetails}
        </Button>
      </div>
      <div
        className={cx(styles.tableContainer, { [styles.isExapnded]: expanded })}
      >
        {expanded && (
          <table className={cx(styles.infoTable, 'additionalInfoTable')}>
            <tbody>
              {getTableContent()}
              {getMetadata()}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
