import React, { FC } from 'react';
import styles from './styles.module.css';
import FilterAndSort from 'appJS/views/FilterAndSort';
import { View } from '../project_nav/types';
import cx from 'classnames';
import { OpenWebsite } from './OpenWebsite';
import { OpenWebsiteWithProxy } from './OpenWebsiteWithProxy';
import { AddTaskOrAssetButton } from './AddTaskOrAssetButton';
import AssetsHeader from '../../../../clients/design_assets/AssetsHeader';
import AssetsGuestHeader from '../../../../clients/design_assets/AssetsGuestHeader';
import { useOrganizationState } from '../../../../../clients/providers/Organization';

type Props = {
  projectSites: {
    url: string;
    id: number;
  }[];
  devurl: string;
  view: View | null;
  isMobileView: boolean;
  isReviewApp: boolean;
  isAdminUser: boolean;
  apiKey: string;
  project: any;
};

export const ProjectHeader: FC<Props> = ({
  projectSites,
  devurl,
  view,
  isMobileView,
  isReviewApp,
  isAdminUser,
  apiKey,
  project
}) => {
  const container = document.getElementById('project_header_container');
  const hasFilter =
    view === View.Kanban || view === View.Archive || view === View.Triage;

  const { hasNewOnboardingExperiment } = useOrganizationState();

  const hideActionsContainer = view === View.Assets || view === View.Home;

  if (view === View.GettingStarted) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.container, {
        [styles.hasFilter]: hasFilter,
        [styles.isMobileView]: isMobileView
      })}
    >
      {view === View.Assets && <AssetsHeader project={project} />}
      {view === View.Home && <AssetsGuestHeader project={project} />}
      <FilterAndSort container={container} isMobileView={isMobileView} />
      {!isMobileView && !hideActionsContainer && (
        <div className={styles.actions}>
          {hasNewOnboardingExperiment ? (
            <OpenWebsiteWithProxy
              container={container}
              isMobileView={isMobileView}
              project={project}
              projectSites={projectSites}
              devurl={devurl}
              isReviewApp={isReviewApp}
              isAdminUser={isAdminUser}
              apiKey={apiKey}
            />
          ) : (
            <OpenWebsite
              container={container}
              isMobileView={isMobileView}
              projectSites={projectSites}
              devurl={devurl}
              isReviewApp={isReviewApp}
              isAdminUser={isAdminUser}
              apiKey={apiKey}
            />
          )}
          <AddTaskOrAssetButton view={view} />
        </div>
      )}
    </div>
  );
};
