import React, { FC, useEffect, useState } from 'react';
import { MapPin } from 'lucide-react';
import { Tooltip } from 'antd';
import styles from './index.module.css';
import cx from 'classnames';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Language';

const strings = translations[getLangKey()];

type Props = {
  container: HTMLDivElement;
  openTasksCount?: number;
  triageTasksCount?: number;
};

const TaskCount: FC<Props> = ({
  container,
  openTasksCount,
  triageTasksCount
}) => {
  const [openCount, setOpenCount] = useState<number>(openTasksCount || 0);
  const [showTaskAdded, _setShowTaskAdded] = useState<boolean>(false);

  const setShowTaskAdded = () => {
    _setShowTaskAdded(true);
    setTimeout(() => _setShowTaskAdded(false), 1500);
  };

  useEffect(() => {
    if (openTasksCount) {
      if (openCount < openTasksCount) {
        setShowTaskAdded();
      }
      setOpenCount(openTasksCount);
    }
  }, [openTasksCount]);

  return typeof openTasksCount !== 'undefined' ? (
    <Tooltip
      getPopupContainer={() => container}
      title={
        <span className={styles.title}>
          {strings.openCount(openTasksCount) +
            strings.tasks(openCount) +
            strings.feedbackCount(triageTasksCount)}
        </span>
      }
    >
      <div className={styles.countContainer}>
        <span className={styles.openCount}>
          <MapPin className={styles.pinIcon} />
          {openTasksCount}
        </span>
        <span
          className={cx(styles.newTaskBubble, {
            [styles.showTaskAdded]: showTaskAdded
          })}
        >
          +
        </span>
      </div>
    </Tooltip>
  ) : null;
};

export default TaskCount;
