import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { Button, Switch, Alert, Radio, message } from 'antd';
import styles from './index.module.css';
import { trackPage } from 'appJS/utils/analytics';
import cx from 'classnames';

const ExportBugs = ({
  email,
  authenticityToken,
  projectId,
  organizationId
}) => {
  const [format, setFormat] = useState('csv');
  const [feedback, setFeedback] = useState(1);
  const [task_board, setTaskBoard] = useState(1);
  const [archive, setArchive] = useState(0);
  const [saving, setSaving] = useState(false);
  const exportEndpoint = `/projects/${projectId}/export`;
  const disableExport = !feedback && !task_board && !archive;
  
  useEffect(() => {
    trackPage('Export bugs page', {
      organizationId,
      projectId
    });
  }, []);

  const handleRadio = event => {
    setFormat(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setSaving(true);
    const formData = new FormData();
    const handleInputs = (key, value) => {
      if (value) formData.append(key, value);
    };
    formData.append('utf8', '✓');
    formData.append('commit', `email file to ${email}`);
    formData.append('authenticity_token', authenticityToken);
    formData.append('format', format);
    handleInputs('feedback', feedback);
    handleInputs('task_board', task_board);
    handleInputs('archive', archive);

    fetch(exportEndpoint, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    })
      .then(response => {
        if (response.status === 200 || response.status === 302) {
          message.success(`Your file will be delivered to ${email}`);
        } else {
          throw Error(response.statusText);
        }

        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
        message.error('Something went wrong, please try again');
      });
  };

  return (
    <>
      <Form
        layout="vertical"
        className={styles.form}
        hideRequiredMark
        onFinish={handleSubmit}
      >
        <Form.Item
          className={styles.radioGroupItem}
          label={<h3 className={styles.formatLabel} children="File format:" />}
        >
          <Radio.Group
            className={styles.radioGroup}
            value={format}
            onChange={event => handleRadio(event)}
          >
            <Radio.Button
              className={styles.radioButton}
              value="csv"
              children="CSV"
            />
            <Radio.Button
              className={styles.radioButton}
              value="xml"
              children="XML"
            />
            <Radio.Button
              className={styles.radioButton}
              value="json"
              children="JSON"
            />
          </Radio.Group>
        </Form.Item>
        <h3
          className={cx(styles.bugsHeader, styles.paddedHeader)}
          children="Include bugs from these tabs:"
        />
        <Form.Item className={styles.formItem}>
          <div className={styles.switchBox}>
            <Switch
              className={styles.switch}
              checked={!!feedback}
              onChange={checked => setFeedback(checked ? 1 : 0)}
            />
            <h3 className={styles.label} children="Feedback" />
          </div>
        </Form.Item>
        <Form.Item className={styles.formItem}>
          <div className={styles.switchBox}>
            <Switch
              className={styles.switch}
              checked={!!task_board}
              onChange={checked => setTaskBoard(checked ? 1 : 0)}
            />
            <h3 className={styles.label} children="Task Board" />
          </div>
        </Form.Item>
        <Form.Item className={styles.formItem}>
          <div className={styles.switchBox}>
            <Switch
              className={styles.switch}
              checked={!!archive}
              onChange={checked => setArchive(checked ? 1 : 0)}
            />
            <h3 className={styles.label} children="Archive" />
          </div>
        </Form.Item>
      </Form>
      <Alert
        className={styles.alert}
        message={`Export data will be emailed to ${email}`}
        type="info"
        showIcon
        closable={false}
      />
      <Button
        type="primary"
        onClick={handleSubmit}
        loading={saving}
        children="Export"
        className={styles.export}
        disabled={disableExport}
      />
    </>
  );
};

export default ExportBugs;
