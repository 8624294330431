// TODO look at deleting this file - Alex 31/3/2023
interface CustomEvent extends Event {
  type: string;
  detail: string;
}

const listen = (eventName: string, callback: Function) => {
  const topWindow = window.top || window;

  topWindow.addEventListener(eventName, ((event: CustomEvent) => {
    try {
      const data = JSON.parse(event.detail);
      callback(data);
    } catch (err) {
      console.warn(err);
    }
  }) as EventListener);
};

export const emit = (eventName: string, eventData: object = {}) => {
  const topWindow = window.top || window;
  const topDocument = topWindow.document;

  const event = topDocument.createEvent('CustomEvent');

  event.initCustomEvent(eventName, true, true, JSON.stringify(eventData));
  topDocument.dispatchEvent(event);
};

export default {
  listen,
  emit
};
