import { ProjectSite } from 'models/Project';

export const ERROR_SAVE_BEFORE_REMOVE = 2;
export const ERROR_NEED_ATLEAST_ONE_SITE_URL = 1;

export const validateUrlRemoval = (
  projectSites: ProjectSite[],
  index: number
) => {
  if (projectSites.length <= 1) return ERROR_NEED_ATLEAST_ONE_SITE_URL;
  if (projectSites[1].id === '') return ERROR_SAVE_BEFORE_REMOVE;
  return 0;
};
