import React from 'react';
import FirefoxLogo from '../../../../../assets/images/browser_logos/firefox.svg';
import ChromeLogo from '../../../../../assets/images/browser_logos/chrome.svg';
import edgeLogo from '../../../../../assets/images/browser_logos/edge.png';
import safariLogo from '../../../../../assets/images/browser_logos/safari.png';
import { handleRedirect } from '../../../components/onboarding/install_button/handleRedirect';
import styles from '../styles.module.css';
import { Button } from 'antd';
import { BrowserInfo, detect } from 'detect-browser';
import cx from 'classnames';
import { browserConfig as browserResources } from '../../../components/onboarding/extensionConfig';

const currentBrowser = detect() as BrowserInfo;
const currentBrowserName = currentBrowser?.name;

export const SharingInstall = ({}) => {
  const browserLogos = {
    Edge: edgeLogo,
    Safari: safariLogo
  };

  const currentBrowserConfig = browserResources.find(
    resource => resource.name === currentBrowserName
  );

  const BrowserLogo = ({ browserDisplay }: { browserDisplay: string }) => {
    switch (browserDisplay) {
      case 'Chrome':
        return <ChromeLogo className={styles.logo} />;
      case 'Firefox':
        return <FirefoxLogo className={styles.logo} />;
      default:
        return (
          <img
            src={browserLogos[browserDisplay]}
            alt={browserDisplay}
            className={styles.logo}
          />
        );
    }
  };

  return currentBrowserConfig ? (
    <Button
      type="primary"
      onClick={() => handleRedirect(currentBrowserConfig?.installLink || '')}
      className={styles.installButton}
      size="large"
    >
      <span className={styles.logoWrapper}>
        <BrowserLogo browserDisplay={currentBrowserConfig?.display || ''} />
      </span>
      Get extension
    </Button>
  ) : (
    <ul className={styles.browsers}>
      {browserResources.map(browser => (
        <li
          key={browser.name}
          className={cx(styles.extensionItem, {
            [styles.active]: browser.name === currentBrowserName
          })}
        >
          <a
            href={browser.installLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.browserLink}
          >
            <BrowserLogo browserDisplay={browser.display} />
            <Button type="primary">Add</Button>
          </a>
        </li>
      ))}
    </ul>
  );
};
