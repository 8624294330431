import React, { FC, useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { OrganizationMemberUser, Props, ShareProjectProps } from './types';
import { ManageAccess } from './manage_access/index';
import PublicFeedback from './public_feedback/index';
import attachListenerOnMultipleNodes from '../../utils/domListeners';
import styles from './index.module.css';
import { trackPage } from 'utils/analytics';
import { ProjectTeam } from './project_team';
import { ActiveTab, tabItems } from './tabItems';
import { post } from '../../../../javascript/utils/fetch';

declare global {
  interface Window {
    share_project_props: ShareProjectProps;
  }
}
const getModalTitle = (
  isShareDeliverable: boolean,
  isShareSite: boolean,
  isNoLoginGuestExperienceEnabled: boolean
) => {
  const shareProjectTitle = isNoLoginGuestExperienceEnabled
    ? 'Project Access'
    : 'Share your project';
  if (isShareDeliverable) {
    return 'Share your file';
  } else if (isShareSite) {
    return 'Share your website';
  } else {
    return shareProjectTitle;
  }
};

const constructShareLink = (token: string) => {
  if (!token) return '';
  return `${window.location.protocol}//${window.location.host}/share/${token}`;
};

export const ShareProject: FC<Props> = props => {
  const {
    organization,
    endpoints,
    authenticityToken,
    project,
    container,
    canAccess,
    isNoLoginGuestExperienceEnabled,
    isGuest,
    refreshOnClose,
    showAssetsPage
  } = props;

  const [show, setShow] = useState<boolean>(props.show);
  const [role, setRole] = useState<string>('member');
  const [siteId, setSiteId] = useState<number | null>(null);
  const [assetId, setAssetId] = useState<number | null>(null);
  const [activeKey, setActiveKey] = useState<ActiveTab>('access');
  const [assetGroupName, setAssetGroupName] = useState<string | null>(null);
  const [users, _setUsers] = useState<OrganizationMemberUser[]>(props.users);
  const [inviteUrl, _setInviteUrl] = useState<string | null>(
    project?.inviteUrl || null
  );
  const [usersOnProject, _setUsersOnProject] = useState<
    OrganizationMemberUser[]
  >(project.usersOnProject);
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    if (!(assetId || siteId)) return;
    (async () => {
      setShareLink('');
      const response = await post('/share_tokens', {
        asset_id: assetId,
        project_site_id: siteId
      });
      setShareLink(constructShareLink(response.token));
    })();
  }, [assetId, siteId]);

  const shareProjectProps: ShareProjectProps = {
    setShow,
    setRole,
    setSiteId,
    setAssetId,
    setAssetGroupName
  };

  useEffect(() => {
    attachListenerOnMultipleNodes("[data-trigger='share_project']", button => {
      button.addEventListener('click', () => {
        const presetRole = button.getAttribute('presetRole');
        setShow(true);
        setRole(presetRole);
        trackPage('Share project popup', {
          organization_id: organization.id,
          project_id: project.id
        });
      });
    });

    window.share_project_props = shareProjectProps;
  }, []);

  const setInviteUrl = (url: string | null) => {
    _setInviteUrl(url);
    // @ts-expect-error
    window.project_board_props.sharingProps.project.inviteUrl = url;
  };

  const setUsersOnProject = (users: OrganizationMemberUser[]) => {
    _setUsersOnProject(users);
    // @ts-expect-error
    window.project_board_props.sharingProps.project.usersOnProject = users;
  };

  const setUsers = (users: OrganizationMemberUser[]) => {
    _setUsers(users);
    // @ts-expect-error
    window.project_board_props.sharingProps.users = users;
  };

  const handleInvitedUser = (_user: OrganizationMemberUser) => {
    const existingOrgUser = users.find(user => user.id === _user.id);
    if (existingOrgUser) {
      setUsersOnProject([...usersOnProject, existingOrgUser]);
    } else {
      const newUser = Object.assign({ owner: false }, _user);
      setUsers([...users, newUser]);
      setUsersOnProject([...usersOnProject, newUser]);
    }
  };

  const handleRemovedUser = (id: number) => {
    setUsersOnProject(usersOnProject.filter(user => user.id !== id));
  };

  const handleGenerateInviteUrl = (clear: boolean) => {
    return fetch(endpoints.generateInviteUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': authenticityToken
      },
      body: clear ? JSON.stringify({ clear }) : '{}'
    })
      .then(res => res.json())
      .then(res => setInviteUrl(res.invite_url));
  };

  const handleCancel = () => {
    setShow(false);
    setSiteId(null);
    setAssetId(null);
    setAssetGroupName(null);
    setActiveKey('access');
    if (refreshOnClose && !isGuest) {
      window.location.replace(window.location.pathname);
    }
  };

  const isShareDeliverable = !!(assetId || assetGroupName);
  const isShareSite = !!siteId;

  const AccessComponent = () => (
    <ManageAccess
      authenticityToken={authenticityToken}
      container={() => container}
      endpoints={endpoints}
      membersCount={
        users.filter(
          user => user.role !== 'guest' && user.role !== 'collaborator'
        ).length
      }
      membersMax={organization.max_members}
      project={project}
      inviteUrl={inviteUrl}
      onInvitedUser={handleInvitedUser}
      onRemovedUser={handleRemovedUser}
      onRegenerateInviteUrl={() => handleGenerateInviteUrl(false)}
      onClearInviteUrl={() => handleGenerateInviteUrl(true)}
      organization={organization}
      role={role}
      users={users}
      usersOnProject={usersOnProject}
      canAccess={canAccess}
      shareLink={shareLink}
      isShareDeliverable={isShareDeliverable}
      isNoLoginGuestExperienceEnabled={isNoLoginGuestExperienceEnabled}
      isGuest={isGuest}
      siteId={siteId || undefined}
      assetId={assetId || undefined}
      isShareSite={isShareSite}
      showAssetsPage={() => {
        setShow(false);
        showAssetsPage();
      }}
    />
  );

  return (
    <Modal
      className={styles.shareProjModal}
      getContainer={container}
      footer={null}
      title={getModalTitle(
        isShareDeliverable,
        isShareSite,
        isNoLoginGuestExperienceEnabled
      )}
      open={show}
      onCancel={handleCancel}
      data-cy="shareProjectModal"
    >
      {isGuest ? (
        AccessComponent()
      ) : (
        <Tabs
          className={styles.tabsContainer}
          type="card"
          defaultActiveKey="access"
          activeKey={activeKey}
          onChange={_activeKey => setActiveKey(_activeKey as ActiveTab)}
          animated={false}
          items={tabItems({
            access: {
              label: isShareDeliverable ? 'Share' : 'Manage Access',
              show: true,
              children: AccessComponent()
            },
            feedback: {
              label: 'Public Feedback',
              show: !isShareDeliverable && !isGuest,
              children: (
                <PublicFeedback
                  {...project}
                  authenticityToken={authenticityToken}
                  endpoint={endpoints.updateProject}
                  onCancel={handleCancel}
                />
              )
            },
            team: {
              label: `Project team (${usersOnProject.length})`,
              show:
                (isNoLoginGuestExperienceEnabled || isShareDeliverable) &&
                !isGuest,
              children: (
                <ProjectTeam
                  authenticityToken={authenticityToken}
                  container={() => container}
                  project={project}
                  endpoints={endpoints}
                  onRemovedUser={handleRemovedUser}
                  canAccess={canAccess}
                  users={usersOnProject}
                />
              )
            }
          })}
        />
      )}
    </Modal>
  );
};
