import React, {
  FC,
  MutableRefObject,
  ReactNode,
  useState,
  useEffect
} from 'react';

import { newPins as pins } from '../../../javascript/utils/getBugPins';
import { Maximize } from 'lucide-react';
import { Coordinates } from '../../../clients/sidebar/views/CreateTask/create_task/types/task';
import styles from './index.module.css';
import cx from 'classnames';
import getBlobUrl from '../../../javascript/utils/getBlobUrl';
import getScreenshotStyles from '../../../javascript/utils/getScreenshotStyles';

const NOT_SET_ID = 0;

type Props = {
  coordinates: Coordinates;
  priorityId?: number;
  isCroppedView: boolean;
  url?: string;
  screenshotKey?: string | null;
  createTask?: () => ReactNode;
  containerWidth: number;
  containerHeight: number;
  containerRef: MutableRefObject<null | HTMLDivElement>;
  onShow?: () => void;
  dataURI?: any;
  showAnnotation?: boolean;
  noSelector?: boolean;
};

const ScreenshotPreview: FC<Props> = props => {
  const {
    coordinates,
    priorityId,
    isCroppedView,
    url,
    screenshotKey,
    createTask,
    containerWidth,
    containerHeight,
    containerRef,
    onShow,
    dataURI,
    showAnnotation,
    noSelector
  } = props;

  const blob = !!dataURI
    ? React.useMemo(() => getBlobUrl(dataURI), [dataURI])
    : null;

  const [blobUrl, setBlobUrl] = useState<string>(dataURI ? blob || '' : '');
  const pinUrl = pins[priorityId || NOT_SET_ID];

  useEffect(() => {
    const getPreview: boolean = dataURI && createTask;
    if (getPreview) {
      setBlobUrl(blob || '');
    }
  }, [dataURI, createTask]);

  const { screenshotStyles } = getScreenshotStyles({
    containerWidth,
    containerHeight,
    isCroppedView,
    coordinates,
    pinUrl,
    isScreenshotServer: !!url?.includes('mobile-screenshot')
  });

  const hasSelector = !noSelector;

  const {
    imageStyle,
    containerStyle,
    wrapperStyle,
    pinStyle
  } = screenshotStyles;

  return (
    <div
      className={cx(styles.previewOuter, {
        [styles.sceenshotBorder]: isCroppedView,
        [styles.notCroppedView]: !isCroppedView,
        [styles.createTaskView]: createTask
      })}
    >
      <a
        className={styles._croppedPinContainer}
        onClick={event => {
          event.preventDefault();
          if (onShow && !showAnnotation) {
            onShow();
          }
        }}
        data-key={screenshotKey}
      >
        <div
          className={styles._screenshotContainer}
          ref={containerRef}
          style={containerStyle}
        >
          <span className={styles._screenshotWrapper} style={wrapperStyle}>
            <img
              className={styles._screenshot}
              src={blobUrl || url}
              alt="screenshot"
              style={imageStyle}
            />
            {!isCroppedView && (hasSelector || coordinates) && (
              <span style={pinStyle} className={styles._pin} />
            )}
          </span>
        </div>
        {!createTask && (
          <div className={styles.overlayTextWrapper}>
            <div className={styles.overlayInner}>
              <Maximize className={styles.maximize} />
              <span className={styles.overlayText}>Click to enlarge</span>
            </div>
          </div>
        )}
        {isCroppedView && (hasSelector || coordinates) && (
          <span style={pinStyle} className={styles._pin} />
        )}
        {createTask && createTask()}
      </a>
    </div>
  );
};

export default ScreenshotPreview;
