import { getLangKey } from 'models/Language';
import * as translations from './strings';

const strings = translations[getLangKey()];

export enum ChecklistEnum {
  SETUP_PROJECT = 0,
  INSTALL_BH,
  WEBSITE,
  PERSONALIZE
}

enum StepStatusEnum {
  Pending = 0,
  InProgress,
  Done
}

type OnboardingStepsType = {
  step: ChecklistEnum;
  title: string;
  activeTitle: string;
  visibleStepTitle: string;
  status: StepStatusEnum;
  helpVideoKey?: string;
  guideMessage?: string;
  videoLength?: string;
};

export const OnboardingSteps: OnboardingStepsType[] = [
  {
    step: ChecklistEnum.SETUP_PROJECT,
    title: strings.setupProjectTitle,
    visibleStepTitle: strings.setupProjectVisibleStepTitle,
    activeTitle: '',
    status: StepStatusEnum.Pending,
    helpVideoKey: strings.createVideoKey,
    guideMessage: strings.createGuideMessage,
    videoLength: strings.createVideoLength
  },
  {
    step: ChecklistEnum.INSTALL_BH,
    title: strings.installBugHerdTitle,
    visibleStepTitle: strings.installBugHerdVisibleStepTitle,
    activeTitle: strings.installBugHerdTitleDone,
    status: StepStatusEnum.Pending,
    helpVideoKey: strings.installVideoKey,
    guideMessage: strings.installGuideMessage,
    videoLength: strings.installVideoLength
  },
  {
    step: ChecklistEnum.WEBSITE,
    title: strings.tryOnWebsiteTitle,
    visibleStepTitle: strings.tryOnWebsiteVisibleStepTitle,
    activeTitle: strings.tryOnWebsiteTitleDone,
    status: StepStatusEnum.Pending,
    helpVideoKey: strings.feedbackVideoKey,
    guideMessage: strings.feedbackGuideMessage,
    videoLength: strings.feedbackVideoLength
  },
  {
    step: ChecklistEnum.PERSONALIZE,
    title: strings.personalizeExperience,
    visibleStepTitle: strings.personalizeExperienceTitle,
    activeTitle: strings.personalizeExperience,
    status: StepStatusEnum.Pending
  }
];
