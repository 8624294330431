import { getScreenshotServerUrl } from '../models/Application';
import { taskErrorUrl } from '../routing/routes';

export const sendScreenshotRequest = ({
  polyfillData,
  s3Response,
  taskId,
  screenshotServerUrl,
  projectId,
  bugherdUrl,
  userId
}) =>
  window
    .fetch(screenshotServerUrl || getScreenshotServerUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({
        metadata: polyfillData,
        s3: s3Response,
        errorUrl: taskErrorUrl({ taskId, bugherdUrl, projectId }),
        taskId,
        userId
      })
    })
    .then(response => (response.ok ? Promise.resolve() : Promise.reject()));

type RegenerateScreenshotFromServer = {
  screenshotServerUrl: string;
  s3Response: unknown;
  taskId: number;
  projectId: number;
  userId?: number;
  bugherdUrl: string;
  organizationId: number;
  basicAuthUrl?: string;
  basicAuthPresent?: boolean;
};
export const regnerateScreenshotFromServer = async ({
  screenshotServerUrl,
  s3Response,
  taskId,
  bugherdUrl,
  projectId,
  userId,
  organizationId,
  basicAuthUrl,
  basicAuthPresent
}: RegenerateScreenshotFromServer): Promise<void> => {
  const regenerateUrl = `${screenshotServerUrl}regenerate`;

  const response = await fetch(regenerateUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      s3: s3Response,
      errorUrl: taskErrorUrl({ taskId, bugherdUrl, projectId }),
      projectId,
      organizationId,
      taskId,
      userId,
      basicAuthUrl,
      basicAuthPresent
    })
  });
  if (response.ok) {
    return;
  } else {
    throw new Error('Unable to regenerate screenshot from server');
  }
};
