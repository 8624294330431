import { ChecklistEnum } from './model';

type ProjectData = {
  name: string;
  url: string;
  isJavascriptInstalled: boolean;
  isExtensionInstalled: boolean;
  isWebsiteVisited: boolean;
  isPersonalized: boolean;
};

export const getOnboardingState: (
  currentProject: ProjectData
) => ChecklistEnum = ({
  name,
  url,
  isJavascriptInstalled,
  isExtensionInstalled,
  isWebsiteVisited,
  isPersonalized
}) => {
  if (url === null || name === null || url === '' || name === '') {
    return ChecklistEnum.SETUP_PROJECT;
  }

  if (!isJavascriptInstalled && !isExtensionInstalled) {
    return ChecklistEnum.INSTALL_BH;
  }

  if (!isWebsiteVisited) {
    return ChecklistEnum.WEBSITE;
  }

  return ChecklistEnum.PERSONALIZE;
};
