export const en = {
  members: 'Members',
  membersAndGuests: 'Members & Guests',
  visibleTo: 'VISIBLE TO',
  visibleToAllGuests:
    'Enabling will make this task visible to all guests in this project.',
  cancel: 'Cancel',
  enable: 'Enable',
  dontShowAgain: "Enable and don't show this again"
};

export type Translations = typeof en;
