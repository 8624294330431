import React, { FC } from 'react';
import { Button, Switch, Select, message } from 'antd';
import HelpArticleButton from 'jsUtilities/HelpArticleButton';
import styles from './index.module.css';
import GreenTick from '../../../views/integration_settings/images/ic-green-tick.svg';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
import { UserSettings } from '..';

const strings = translations[getLangKey()];

type Props = {
  authenticityToken: string;
  user: UserSettings;
  handleUpdateUser: (attributes: { [key: string]: any }) => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  isGuest: boolean;
};

export const Notifications: FC<Props> = ({
  authenticityToken,
  user,
  handleUpdateUser,
  saving,
  setSaving,
  isGuest
}) => {
  const {
    schedule,
    newsletter,
    enableTaskUpdates,
    enableProjectUpdates,
    enableTaskCommentUpdates,
    enableUnassignedTaskCommentUpdates,
    enableNewTaskUpdates,
    enableTaskDoneUpdates
  } = user;

  const formHandler = () => {
    const formData = new FormData();
    formData.append('utf8', '✓');
    formData.append('_method', 'patch');
    formData.append('user[notify_frequency]', schedule.toString());
    formData.append('user[newsletter]', newsletter.toString());
    formData.append('user[task_notify]', enableTaskUpdates.toString());
    formData.append(
      'user[task_notify_comment]',
      enableTaskCommentUpdates.toString()
    );
    formData.append(
      'user[task_notify_comment_unassigned]',
      enableUnassignedTaskCommentUpdates.toString()
    );
    formData.append('authenticity_token', authenticityToken);
    formData.append('user[new_task_notify]', enableNewTaskUpdates.toString());
    formData.append('user[task_notify_done]', enableTaskDoneUpdates.toString());
    formData.append(
      'user[enable_project_updates]',
      enableProjectUpdates.toString()
    );

    setSaving(true);

    fetch('/save_settings', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    })
      .then(response => {
        setSaving(false);

        if (response.status === 200) {
          message.success(strings.notificationSettingsSaved);
        } else {
          throw Error(response.statusText);
        }
      })
      .catch(error => message.error(strings.somethingWentWrong));
  };

  const handleProjectEnabled = (value: boolean) => {
    handleUpdateUser({ enableProjectUpdates: value, schedule: value ? 2 : 0 });
  };

  return (
    <div className={styles.container}>
      <h2>{strings.notificationPreferences}</h2>

      {!isGuest && (
        <section className={styles.section}>
          <header className={styles.header}>
            <h3>{strings.newTasksCreated}</h3>
            <Switch
              checked={enableNewTaskUpdates}
              onChange={checked =>
                handleUpdateUser({ enableNewTaskUpdates: checked })
              }
            />
          </header>
          <p>{strings.receiveEmail}</p>
        </section>
      )}

      <section className={styles.section}>
        <header className={styles.header}>
          <h3>{strings.taskUpdates}</h3>
          <Switch
            checked={enableTaskUpdates}
            onChange={checked =>
              handleUpdateUser({ enableTaskUpdates: checked })
            }
          />
        </header>
        <p>
          {strings.receiveEmails} <strong>{strings.instantly}</strong>{' '}
          {strings.whenThere}
        </p>
        <ul className={styles.list}>
          <li>
            <GreenTick className={styles.greenTickIcon} />
            &nbsp;&nbsp;{strings.taskAssignedToYou}
          </li>
          <li>
            <GreenTick className={styles.greenTickIcon} />
            &nbsp;&nbsp;{strings.commentOnATaskReported}
          </li>
          <li>
            <GreenTick className={styles.greenTickIcon} />
            &nbsp;&nbsp;{strings.commentOnATaskAssigned}
          </li>
          <li>
            <Switch
              size="small"
              checked={enableTaskCommentUpdates}
              onChange={checked =>
                handleUpdateUser({ enableTaskCommentUpdates: checked })
              }
            />
            &nbsp;{strings.commentOnATask}
          </li>
          <li>
            <Switch
              size="small"
              checked={enableUnassignedTaskCommentUpdates}
              onChange={checked =>
                handleUpdateUser({
                  enableUnassignedTaskCommentUpdates: checked
                })
              }
            />
            &nbsp;{strings.commentOnAnUnassignedTask}
          </li>
          {isGuest && (
            <li>
              <Switch
                size="small"
                checked={enableTaskDoneUpdates}
                onChange={checked =>
                  handleUpdateUser({
                    enableTaskDoneUpdates: checked
                  })
                }
              />
              &nbsp;{strings.taskFollowingCompleted}
            </li>
          )}
        </ul>
      </section>

      <section className={styles.section}>
        <header className={styles.header}>
          <h3>{strings.projectUpdates}</h3>
          <Switch
            checked={enableProjectUpdates}
            onChange={handleProjectEnabled}
          />
        </header>
        <p>
          {strings.aSummary}({strings.seeAn}
          <HelpArticleButton articleId="33888" style={{ color: '#3f71e0' }}>
            {strings.exampleHere}
          </HelpArticleButton>
          ).
        </p>
        <p>{strings.updateWillInclude}</p>
        <ul className={styles.list}>
          <li>{strings.newTasksCreated}</li>
          <li>{strings.comments}</li>
          <li>{strings.tasksMovedToDone}</li>
          <li>{strings.tasksClosed}</li>
        </ul>

        <div className={styles.scheduleContainer}>
          <span className={styles.scheduleLabel}>
            {strings.sendProjectUpdatesToMe}
          </span>
          <Select
            disabled={!enableProjectUpdates}
            value={schedule}
            style={{ width: 120 }}
            onChange={value => handleUpdateUser({ schedule: value })}
            getPopupContainer={() => {
              let container = document.getElementById('settings_container');
              if (!container) {
                container = document.createElement('div');
                container.id = 'settings_container';
                document.body.appendChild(container);
              }
              return container;
            }}
          >
            <Select.Option value={0}>{strings.never}</Select.Option>
            <Select.Option value={2}>{strings.hourly}</Select.Option>
            <Select.Option value={4}>{strings.daily}</Select.Option>
            <Select.Option value={5}>{strings.weekly}</Select.Option>
          </Select>
        </div>
      </section>

      <section className={styles.section}>
        <header className={styles.header}>
          <h3>{strings.subscribeToBugherd}</h3>
          <Switch
            checked={!!newsletter}
            onChange={checked =>
              handleUpdateUser({ newsletter: checked ? 1 : 0 })
            }
          />
        </header>
        <p>{strings.subscribeDescription}</p>
      </section>

      <Button type="primary" onClick={formHandler} loading={saving}>
        {strings.save}
      </Button>
    </div>
  );
};
