import * as React from 'react';
import * as translations from './strings';
import styles from './index.module.css';
import { UserOutlined as User } from '@ant-design/icons';
import { getPlanFeatures } from './PlanFeatures';
import PlanHeader from './PlanHeader';
import { PlanInterval } from '../types';
import SubscribeButton from './SubscribeButton';
import AdditionalMemberPrice from '../AdditionalMemberPrice';
import Feature from './Feature';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SubscriptionPlan, SubscriptionTrial } from '../query';

const strings = translations['en'];

const getPopupContainer = () =>
  document.getElementById('settings_container') as HTMLElement;

interface Props {
  plan: {
    id: string;
    name: string;
    price: number;
    handle: string;
    interval: PlanInterval;
    maxMembers: number;
  };
  organization: {
    id: string;
    numMembers: number;
    validPartnerCoupon: boolean;
    activePartnerCouponDescription: string;
    partnerCouponName: string | null;
    experiments: string[];
  };
  subscription: SubscriptionPlan | SubscriptionTrial;
}

function pluralize(count: number, word: string) {
  if (count > 1) {
    return `${count} ${word}s`;
  } else {
    return `${count} ${word}`;
  }
}

const Plan: React.FC<Props> = (props: Props) => {
  const { plan, organization, subscription } = props;
  const memberDiff = organization.numMembers - plan.maxMembers;
  const numAdditionalMembers = memberDiff > 0 ? memberDiff : 0;

  const showCouponLabel = () => {
    const freemiumCoupons = ['UPWORKFREE', 'CODEABLEFREE'];

    if (subscription.state !== 'trialing' || !organization.validPartnerCoupon)
      return false;
    if (
      organization.partnerCouponName &&
      freemiumCoupons.includes(organization.partnerCouponName)
    ) {
      return plan.handle === 'freemium';
    }
    return true;
  };

  return (
    <div className={styles.plan}>
      <PlanHeader plan={plan} numAdditionalMembers={numAdditionalMembers} />
      <div className={styles.featuresBox}>
        <ul>
          <li key="numberOfUsers">
            <span>
              <User />
              {!plan.maxMembers
                ? strings.unlimitedUsers
                : pluralize(plan.maxMembers, 'member')}
              {` included ($${
                AdditionalMemberPrice[plan.interval]
              } per additional member)`}
            </span>
          </li>
          {plan.maxMembers && plan.maxMembers > 50 && (
            <Feature
              name={strings.plusEverythingOnPremium}
              key={'maxMembers'}
            />
          )}
          {getPlanFeatures({ planHandle: plan.handle }).map(name => (
            <Feature key={name} name={name} />
          ))}
        </ul>
        <div className={styles.priceSelect}>
          <SubscribeButton
            plan={plan}
            organization={organization}
            subscription={subscription}
          />
          {plan.interval === 'Yearly' && (
            <em className={styles.saveAlert}>{strings.twoMonthsFree}</em>
          )}
          {showCouponLabel() && (
            <em className={styles.saveAlert}>
              {strings.couponToBeApplied}&nbsp;
              <Tooltip
                title={
                  organization.activePartnerCouponDescription +
                  ' will be applied when you subscribe.'
                }
                getPopupContainer={getPopupContainer}
                placement="bottomRight"
              >
                <span className={styles.helpIconOuter}>
                  <InfoCircleOutlined style={{ color: '#20c0e7' }} />
                </span>
              </Tooltip>
            </em>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plan;
