const localStorage = window.localStorage;

export const getData = (key: string) => {
  const data = localStorage.getItem(key);

  if (data) {
    return JSON.parse(data);
  } else {
    return data;
  }
};

export const setData = (key: string, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

export const UA_MY_TASK_ORDERBY = 'ua_my_task_orderBy';
export const UA_MY_TASK_PAGE_SIZE = 'ua_my_task_page_size';
export const UA_MY_COMMENT_PAGE_SIZE = 'ua_my_comment_page_size';
export const UP_MY_PROJECT_PAGE_SIZE = 'up_my_project_page_size';
export const UP_MY_ASSETS_PAGE_SIZE = 'up_my_assets_page_size';
